import { useCallback } from "react";
import { getQuotation_TIQ01 } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";

const useEstimateApi = () => {
  const { executeAsync: getQuotation } = useApiOperation(getQuotation_TIQ01);
  const getEstimateList = useCallback(
    async (params: any) => {
      return await getQuotation(params);
    },
    [getQuotation],
  );
  return { getEstimateList };
};
export default useEstimateApi;
