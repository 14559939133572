import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { BaseModal } from "src/components";
import useApiLoading from "src/hooks/useApiLoading";
import ScheduleInfoColumns from "src/pages/billing/columns/OverdueInfoColumns";
import { useSticky } from "react-table-sticky";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import ScrollShadowProvider from "src/components/ScrollShadowProvider";

const OverdueInfo = ({ callList, contractBaseInfo, basicInfo, overdueList, scheduleId, showBtn, messageModal, setMessageModal }: any) => {
  const params = useParams();
  const { isApiLoading } = useApiLoading();
  const [checkTax, setCheckTax] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  useEffect(() => {
    if (overdueList.length > 0) {
      overdueList.forEach((overdue: any) => {
        // overdue.taxAmount = 1;
        if (overdue.taxAmount && Number(overdue.taxAmount) > 0) {
          setCheckTax(true);
        }
      });
    }
  }, [overdueList]);

  // const navigate = useNavigate();
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable(
    {
      columns: ScheduleInfoColumns,
      data: overdueList,
      callList: callList,
      contractBaseInfo,
      showBtn: showBtn,
      basicInfo,
      scheduleId,
      type: "schedule",
      messageModal,
      setMessageModal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  // console.log("showBtn", showBtn);
  return (
    <article className="contents-container__wrap-article" style={{ maxWidth: "unset" }}>
      <div>
        <div className="contents-container__sub-title">
          <BaseSectionTitle title={"이용료 연체 현황"} />
        </div>
        <div>
          <ScrollShadowProvider tableRef={tableRef}>
            <div {...getTableProps()} ref={tableRef} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                          {/* <div className="ic_sort"></div> */}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="footer">
                {footerGroups.map((footerGroup) => (
                  <div {...footerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {footerGroup.headers.map((column) => (
                      <div {...column.getHeaderProps()} className="base-table__td base-table-footer">
                        {column.render("Footer")}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </ScrollShadowProvider>
        </div>
        <BaseModal isOpen={checkTax} btnRightTitle={"확인"} onClick={() => setCheckTax(false)} title={"관리자에게 문의하세요"}>
          <div>
            <p>연체현황에 부가세가 0이 아닌 데이터가 있습니다.[계약 ID: {params?.id}]</p>
          </div>
        </BaseModal>
      </div>
    </article>
  );
};

export default OverdueInfo;
