import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DetailTotalData } from "src/api/contract/contract-types";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton } from "src/components";
import Link from "src/components/PartnerLink";
import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseSection from "src/components/layout/BaseSection";
import pagePath from "src/pagePath.json";
import SearchSpaceProductPopup from "src/pages/commonPopup/SearchSpaceProductPopup";
import { globalPartnerState } from "src/recoil/partners/atom";
import { numberToStringWithComma } from "src/utils";
import useContactApi from "../../hooks/useContractApi";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";

interface ContractBasicProp {
  detailData?: DetailTotalData; //상품 상세 조회 정보
  saveObject: any;
  saveData: any;
  productDetail: any;
  setProductDetail: any;
  showYn?: boolean;
}
const ContractProductEdit = ({ detailData, saveData, saveObject, productDetail, setProductDetail, showYn }: ContractBasicProp) => {
  const { getProductData, getProviderData } = useContactApi();
  const [innerProductDetail, setInnerProductDetail] = useState<any>({});
  const partner = useRecoilValue(globalPartnerState);
  const [usePrice, setUsePrice] = useState("");
  const [providerDetail, setProviderDetail] = useState<ProviderDetailModel>();
  const [pflag, setPflag] = useState(false);
  const goProductDetail = (id: number) => {
    let path = "";
    if (id) {
      path = pagePath.product.detail.replace(":id", id!.toString());
    }
    return path;
  };

  const findProvider = useCallback(
    async (providerId: number) => {
      const pd = await getProviderData(providerId);
      setProviderDetail(pd);
    },
    [getProviderData],
  );

  const onChangeProduct = useCallback(
    async (product: any) => {
      // console.log("product", product);
      const findedProduct = await getProductData(product.id);
      setProductDetail(findedProduct);
      findProvider(findedProduct.providerId);
      const temp = _.cloneDeep(saveData);
      temp.productId = product!.id;
      saveObject(temp);
      setPflag(false);
    },
    [findProvider, getProductData, saveData, saveObject, setProductDetail],
  );

  useEffect(() => {
    if (productDetail) {
      setInnerProductDetail(productDetail);
      let price = "";
      if (
        productDetail &&
        Array.isArray(productDetail.rentalCostList) &&
        productDetail.rentalCostList.length > 0 &&
        productDetail!.rentalCostList[0]!.value1
      ) {
        if (productDetail && Array.isArray(productDetail.rentalCostList) && productDetail!.rentalCostList[0]!.value1) {
          price = numberToStringWithComma(Number(productDetail?.rentalCostList[0]!.value1!) + Number(productDetail?.rentalCostList[0]!.value1! / 10));
        } else {
          price = "0";
        }
      }
      setUsePrice(price);
    }
  }, [productDetail]);

  return (
    <ArticleSection
      title={"상품 정보"}
      noHashLink={showYn ? true : false}
      children={
        <>
          <div className="flex-center">
            <BaseSection
              title={"상품명"}
              children={
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start w-100 font14">
                    {innerProductDetail?.productName} (id:{" "}
                    <Link to={goProductDetail(innerProductDetail?.id)} className="text-underline" target="_blank" rel="noopener noreferrer">
                      {innerProductDetail?.id}
                    </Link>
                    )
                    <div className="minmax90 ml10">
                      <BaseButton
                        title={innerProductDetail?.id ? "변경" : "조회"}
                        className="color-white"
                        onClick={() => {
                          setPflag(true);
                        }}
                      />

                      <SearchSpaceProductPopup
                        isOpen={pflag}
                        partnerId={String(partner!.id)}
                        onClick={onChangeProduct}
                        onClose={() => setPflag(false)}
                      />
                    </div>
                  </div>
                </div>
              }
            />
            {productDetail.productType !== "TIME_COURT" && (
              <BaseSection
                title={"보증금"}
                children={
                  <div className="contents-container__grid-contents">
                    <p>{innerProductDetail?.deposit ? numberToStringWithComma(innerProductDetail?.deposit) + " 원" : "-"}</p>
                  </div>
                }
              />
            )}
          </div>
          <div className="flex-center">
            <BaseSection title={"상품유형"} children={<ProductTypeChangeLabel productType={innerProductDetail?.productType} />} />

            {productDetail.productType !== "TIME_COURT" && (
              <BaseSection
                title={"ㄴ 계약금"}
                children={
                  <div className="contents-container__grid-contents">
                    <p>{innerProductDetail?.earnest ? numberToStringWithComma(innerProductDetail?.earnest) + " 원" : "-"}</p>
                  </div>
                }
              />
            )}
          </div>
          <div className="flex-center">
            <BaseSection
              title={"프로바이더 명"}
              children={
                <div className="contents-container__grid-contents">
                  {innerProductDetail?.providerId ? (
                    <div>
                      <p>{providerDetail?.provider?.providerName}</p>
                    </div>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              }
            />

            {productDetail.productType !== "TIME_COURT" && (
              <BaseSection
                title={"ㄴ 잔금"}
                children={
                  <div className="contents-container__grid-contents">
                    <p>
                      {innerProductDetail?.deposit
                        ? numberToStringWithComma(Number(innerProductDetail?.deposit) - Number(innerProductDetail?.earnest)) + " 원"
                        : "-"}
                    </p>
                  </div>
                }
              />
            )}
          </div>
          <div className="flex-center">
            {/* 상품타입 */}
            <BaseSection title={""} children={""} />

            {productDetail.productType !== "TIME_COURT" && (
              <BaseSection
                titleChildren={
                  <div className="contents-container__grid-index">
                    <p>
                      월 이용료
                      <br />
                      (부가세 포함 / 원)
                    </p>
                  </div>
                }
                children={
                  <div className="contents-container__grid-contents">
                    <p>{usePrice + " 원"}</p>
                  </div>
                }
              />
            )}
          </div>
        </>
      }
    />
  );
};

export default ContractProductEdit;
