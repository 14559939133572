import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { getVocListAsync, submitVocItemAsync } from "src/api/voc/voc-api";
import { CsItemModel, VocModelUpdate, VocQueryParams } from "src/api/voc/voc-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useCsOptions } from "../../hooks/useCsOptions";
import { onChangedValues } from "../../voc-function";

type Props = {
  vocId?: string;
};

const BasicInfoForm = ({ vocId }: Props) => {
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [vocDetail, setVocDetail] = useState<CsItemModel>();

  const { handleSubmit, watch, setValue, reset, control, register } = useForm<VocModelUpdate>({
    defaultValues: {
      csTypeId: "",
      csItemName: "",
      cmdType: "C",
    },
  });

  const hasUpdate = vocDetail ? onChangedValues(vocDetail, watch()) : undefined;

  // Voc유형 목록
  const { options: vocOptions } = useCsOptions("VoC");

  // Voc 상세
  const { executeAsync: getList } = useApiOperation(getVocListAsync);

  // 등록/수정
  const { executeAsync: submitVocItem } = useApiOperation(submitVocItemAsync);

  useEffect(() => {
    const fetchVocList = async (passParams: VocQueryParams) => {
      const { data, status } = await getList(passParams);

      if (status >= 200 && status <= 299) {
        const detail = data.data.content[0];
        setValue("csItemName", detail.csItemName);
        setValue("csTypeId", detail.csTypeId);
        setValue("id", detail.id);
        setVocDetail(detail);
      }
    };

    if (vocId) {
      const params = {
        searchType: "id",
        keywordList: String(vocId),
      };
      fetchVocList(params);
    }
  }, [getList]);

  const handleSubmitVocItems = async (vocData: VocModelUpdate) => {
    const { data, status } = await submitVocItem(vocData);
    if (status >= 200 && status <= 299) {
      navigate(PagePath.voc.detail.replace(":id", String(data.data.content[0].id)));
    }
  };

  // 저장 시 validation 모달 확인
  const validationModal = useCallback(() => {
    // 수정 시 변경된 필드가 없을 경우
    if (hasUpdate && Object.keys(hasUpdate).length === 0) {
      setAlertModal({ isOpen: true, message: "변경된 사항이 없습니다." });
      return false;
    }
    // 등록/수정시 담당자 미지정일 경우
    return true;
  }, [hasUpdate]);

  // 저장 버튼 클릭시 실행
  const onSubmit = async (data: VocModelUpdate, e?: any) => {
    e.preventDefault();
    const isOk = validationModal();
    if (isOk) {
      if (Number(vocId)) {
        let editData = { ...data };
        editData = { ...editData, cmdType: "U" };
        console.log(`editData`, editData);
        setConfirmModal({ isOpen: true, title: "저장하시겠습니까?", payload: editData });
      } else {
        setConfirmModal({ isOpen: true, title: "저장하시겠습니까?", payload: data });
      }
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);

    return false;
  };

  const formValidation = useCallback(() => {
    const message = "필수입력 항목입니다.";
    register("csTypeId", { required: { value: true, message } });
    register("csItemName", { required: { value: true, message } });
  }, [register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap ">
            <div className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <h2 className="font18">VoC 정보</h2>
              </div>
              <div className="contents-container__1200">
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p className="required">유형</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax200">
                      <Controller
                        control={control}
                        name={"csTypeId"}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseSelect
                            className="mr8"
                            stateOptions={vocOptions}
                            setStateValue={(selected: string) => onChange(selected)}
                            value={value}
                            name={name}
                            errorText={error?.message}
                            placeholder="선택"
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>
              </div>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">항목</p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax500">
                    <Controller
                      control={control}
                      name={"csItemName"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          placeholder="최대 20자 작성 가능합니다."
                          onChange={(value: string) => onChange(value)}
                          value={value}
                          name={name}
                          errorText={error?.message}
                          maxLength={20}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            {vocId && hasUpdate && Object.keys(hasUpdate).length > 0 && (
              <div className="mr20">
                <p className="required font14 text-red">수정된 사항이 있으니 다시 한번 확인해주세요.</p>
              </div>
            )}
            {vocId && (
              <BaseButton
                type="button"
                title="수정취소"
                className="size-large color-white mr5"
                onClick={() => navigate(PagePath.voc.detail.replace(":id", String(vocId)))}
              />
            )}

            <BaseButton type="submit" title={vocId ? "저장" : "등록"} className="size-large" />
          </div>
        </div>
      </form>
      {/* 모달 */}

      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            title={confirmModal.title}
            onClick={() => {
              handleSubmitVocItems(confirmModal?.payload as any);
              setConfirmModal({ isOpen: false });
            }}
            onClose={() => setConfirmModal({ isOpen: false })}
          ></BaseModal>
        )}
        {alertModal.isOpen && (
          <BaseModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })} title={alertModal.message}></BaseModal>
        )}
      </>
    </>
  );
};

export default BasicInfoForm;
