import { PagePath } from "src/pages/product/details";

export const getVistorDetailPath = ({ visitApplyNumber, contractApplyNumber }: { visitApplyNumber: string; contractApplyNumber: string }) => {
  return PagePath.visitor.detail.replace(":visitApplyNumber", visitApplyNumber).replace(":contractApplyNumber", contractApplyNumber);
};

export const getVisitorListPath = () => {
  return PagePath.visitor.list;
};

export const getProductDetailPath = ({ productId }: { productId: string }) => {
  return PagePath.product.detail.replace(":id", productId);
};

export const getContractDetailPath = ({ contractId }: { contractId: string }) => {
  // console.log("contractId", contractId);
  return PagePath.contract.detail.replace(":id", contractId);
};

export const getVisitorDetailPath = ({ visitApplyNumber, contractApplyNumber }: { visitApplyNumber: string; contractApplyNumber: string }) => {
  return PagePath.visitor.detail.replace(":visitApplyNumber", visitApplyNumber).replace(":contractApplyNumber", contractApplyNumber);
};
export const getVisitorAccessInfoPath = ({
  contractVisitorId,
  contractManageId,
  contractApplyNumber,
  visitApplyNumber,
}: {
  contractVisitorId: string;
  contractManageId: string;
  contractApplyNumber: string;
  visitApplyNumber: string;
}) => {
  return PagePath.visitor.accessInfo
    .replace(":id", contractVisitorId)
    .replace(":contractManageId", contractManageId)
    .replace(":contractApplyNumber", contractApplyNumber)
    .replace(":visitApplyNumber", visitApplyNumber);
};
