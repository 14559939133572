import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { ADMIN_MEMO, Modal } from "src/api/public-types";
import { WorkOrderDetailModel } from "src/api/work-order/workorder-types";
import { BaseModal, ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import useWorkOrder from "../../hooks/useWorkOrder";
import { UnionWorkOrderStatus, workOrderStatus, workOrderTabs } from "../../workOrder-types";
import WorkOrderHistory from "../detail/components/WorkOrderHistory";
import CtrlWorkOrderForm from "./CtrlWorkOrderForm";
import WorkOrderAccess from "../detail/components/WorkOrderAccess";
import { PagePath } from "src/pages/product/details";

const WorkOrderFormAuthenticated = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [summary, setSummary] = useState(""); // 워크오더 서머리 타이틀바용
  // location search (url query parameter) 를 읽어서 object 로 변환

  const { getWorkOrderDetail, editWorkOrder } = useWorkOrder();
  const [workOrderDetail, setWorkorderDetail] = useState<WorkOrderDetailModel>();

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 워크오더 api
  const fetchWorkOrder = async (id: string) => {
    const { data, status } = await getWorkOrderDetail({ id: Number(id) });
    if (status >= 200 && status <= 299) {
      const workOrder = data.data.content;

      setWorkorderDetail(workOrder);
    }
  };

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  const workOrderId = useMemo(() => {
    return queryParams?.id ? String(queryParams.id) : undefined;
  }, [queryParams.id]);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return workOrderTabs.find((tab) => tab.value === queryParams?.tab);
    }
    return workOrderTabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // 기본정보 가 작성되지 않은 상태에서, 다른 탭으로 이동하려고 할 경우.
      if (tab.value !== "basicInfo" && !queryParams.id) {
        setModal({
          isOpen: true,
          title: "기본정보가 저장되지 않았습니다.",
        });
        return;
      }
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  const breadCrumbs = [{ value: "workOrder", label: "워크오더" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  useEffect(() => {
    if (workOrderId) {
      fetchWorkOrder(workOrderId);
    }
  }, [workOrderId]);

  // 상담관리 상태변경
  const onChangeWorkOrderStatus = async (id: string, status: UnionWorkOrderStatus) => {
    const response = await editWorkOrder({ id, workOrderSheet: { status } });
    if (response.status >= 200 && response.status <= 299) {
      const workorderStatus = response.data.data.content[0].status;
      if ((workOrderStatus && workorderStatus === "WORK_ORDER_CANCELED") || workorderStatus === "WORK_ORDER_CLOSED") {
        navigate(PagePath.workOrder.authenticated.detail.replace(":id", String(workOrderId)));
      }
      await fetchWorkOrder(String(workOrderId));
      setConfirmModal({ isOpen: false });
    }
  };

  const workSheetNo = useMemo(() => {
    if (workOrderDetail?.workOrderSheetNo) {
      return `${String(workOrderDetail.workOrderSheetNo).slice(0, 8)}-${String(workOrderDetail.workOrderSheetNo).slice(8, 13)}`;
    }
  }, [workOrderDetail?.workOrderSheetNo]);

  return (
    <div>
      <MetaTag subTitle={workOrderDetail?.summary || ""} isCreatePage={workOrderId === undefined} />
      <ContentsTitle
        title="워크오더"
        tabs={workOrderTabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[
          { title: "id", value: workOrderId ? workOrderId : "" },
          { title: `${workOrderId ? "업무 번호" : ""}`, value: !workOrderId ? "" : workSheetNo ? workSheetNo : "-" },
        ]}
        selectOptions={workOrderStatus.filter((status, idx) => idx !== 0)}
        selectValue={workOrderDetail?.status}
        selectDisabled={workOrderDetail?.status === "WORK_ORDER_CANCELED" || workOrderDetail?.status === "WORK_ORDER_CLOSED"}
        setSelectStateValue={(status: UnionWorkOrderStatus) => {
          setConfirmModal({ isOpen: true, title: "상태를 변경하시겠습니까?", payload: status });
        }}
      />
      {/* 탭 */}
      {/* 기본정보 */}
      {/* {activeTab?.value === "basicInfo" && <BasicInfoForm setSummary={setSummary} />} */}
      {activeTab?.value === "basicInfo" && <CtrlWorkOrderForm workOrderDetail={workOrderDetail} />}

      {/* 출입관리 */}
      {activeTab?.value === "access" && <WorkOrderAccess workOrder={workOrderDetail} />}

      {/* 변경내역 정보 */}
      {activeTab?.value === "history" && <WorkOrderHistory />}

      {modal.isOpen && (
        <BaseModal isOpen={true} btnRightTitle={"확인"} title={modal.title} onClick={() => setModal({ ...modal, isOpen: false })}></BaseModal>
      )}

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            onChangeWorkOrderStatus(String(queryParams.id), confirmModal.payload as UnionWorkOrderStatus);
            setConfirmModal({ isOpen: false });
          }}
          title={confirmModal.title}
        ></BaseModal>
      )}
    </div>
  );
};

export default WorkOrderFormAuthenticated;
