import React, { useEffect, useState } from "react";
import { Control, Controller, FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { PageType, VisitorAddForm, VisitorEditForm } from "../visitor-types";

import { isValidPhoneNumber } from "libphonenumber-js";
import { BaseInput, BaseSelect } from "src/components";
import { getFormErrorText } from "../utils";
import { formatPhoneNumber, validateEmailText } from "src/utils";

export interface VisitorDetailItem {
  key: string;
  render: ({
    control,
    index,
    pageType,
  }: {
    control: Control<VisitorEditForm | VisitorEditForm | any>;
    index: number;
    pageType: PageType;
  }) => React.ReactNode;
}

const VisitorInfo = ({
  pageType,
  control,
  formVisitorInfoList,
  append,
  remove,
  scrollContainer,
  useFormReturn,
}: {
  pageType: PageType;
  control: Control<VisitorAddForm | VisitorEditForm | any, any>;
  formVisitorInfoList: FieldArrayWithId<VisitorAddForm | VisitorEditForm | any, "visitorInfoList", "id">[];
  append: UseFieldArrayAppend<VisitorAddForm | VisitorEditForm | any, "visitorInfoList">;
  remove: UseFieldArrayRemove;
  scrollContainer: React.RefObject<HTMLDivElement>;
  useFormReturn: UseFormReturn<VisitorAddForm | VisitorEditForm | any>;
}) => {
  const {
    watch,
    resetField,
    formState: { errors },
  } = useFormReturn;
  const requiredMessage = "필수입력 항목입니다";

  const [isFocusList, setIsFocusList] = useState({
    index: 0,
    isFocus: false,
  });

  const handleClickAddButton = () => {
    append({ contractVisitorId: 0, invitationType: "phone" });
    scrollContainer.current?.scrollTo(0, scrollContainer.current?.scrollHeight);
  };
  const handleClickRemoveButton = (columnIndex: number) => remove(columnIndex);

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title">
        <h2>방문자 정보</h2>
      </div>
      <section className="visitor-list-warp">
        {formVisitorInfoList.map((visitorInfo, index) => (
          <div key={visitorInfo.id}>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">방문자{index + 1}</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row justify-contents-start">
                  <Controller
                    control={control}
                    name={`visitorInfoList.${index}.name`}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <div>
                          <BaseInput
                            placeholder="이름"
                            disabled={pageType === "edit"}
                            className="minmax200 mr10"
                            value={field.value}
                            onChange={field.onChange}
                            errorText={getFormErrorText(fieldState.error?.type!)}
                          />
                        </div>
                      );
                    }}
                  />

                  <div
                    className={`base-select-input ${pageType === "edit" ? "readonly" : ""} ${
                      isFocusList.index === index && isFocusList.isFocus ? "base-select-input__focus" : ""
                    }`}
                  >
                    <div>
                      <Controller
                        control={control}
                        name={`visitorInfoList.${index}.invitationType`}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => {
                          return (
                            <BaseSelect
                              isDisabled={pageType === "edit"}
                              onFocus={() =>
                                setIsFocusList({
                                  index: index,
                                  isFocus: true,
                                })
                              }
                              onBlur={() =>
                                setIsFocusList({
                                  index: index,
                                  isFocus: false,
                                })
                              }
                              value={field.value ?? "phone"}
                              placeholder="초대방법"
                              stateOptions={[
                                {
                                  value: "phone",
                                  label: "휴대폰",
                                },
                                {
                                  value: "email",
                                  label: "이메일",
                                },
                              ]}
                              setStateValue={(value: string) => {
                                field.onChange(value);
                                resetField(`visitorInfoList.${index}.phoneNumber`);
                                resetField(`visitorInfoList.${index}.visitorEmail`);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    {watch(`visitorInfoList.${index}.invitationType`) === "phone" ? (
                      <Controller
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: requiredMessage,
                          },
                          validate: (value) => (isValidPhoneNumber(value!, "KR") ? true : "유효한 휴대폰 번호를 입력해주세요"),
                        }}
                        name={`visitorInfoList.${index}.phoneNumber`}
                        render={({ field, fieldState }) => (
                          <BaseInput
                            name={`phoneNumber`}
                            type="phone"
                            placeholder="휴대폰 번호"
                            disabled={pageType === "edit"}
                            className="minmax200 mr10"
                            value={field.value}
                            onChange={field.onChange}
                            errorText={fieldState.error?.message!}
                            onFocus={() =>
                              setIsFocusList({
                                index: index,
                                isFocus: true,
                              })
                            }
                            onBlur={() =>
                              setIsFocusList({
                                index: index,
                                isFocus: false,
                              })
                            }
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        control={control}
                        rules={{ required: { value: true, message: requiredMessage }, validate: (value) => validateEmailText(value) }}
                        name={`visitorInfoList.${index}.visitorEmail`}
                        render={({ field, fieldState }) => (
                          <BaseInput
                            name={`visitorEmail`}
                            type="email"
                            placeholder="이메일 주소"
                            disabled={pageType === "edit"}
                            className="minmax200 mr10"
                            value={field.value}
                            onChange={field.onChange}
                            errorText={fieldState.error?.message}
                            onFocus={() =>
                              setIsFocusList({
                                index: index,
                                isFocus: true,
                              })
                            }
                            onBlur={() =>
                              setIsFocusList({
                                index: index,
                                isFocus: false,
                              })
                            }
                          />
                        )}
                      />
                    )}
                  </div>

                  {watch(`visitorInfoList.${index}.invitationType`) === "email" && (
                    <Controller
                      control={control}
                      defaultValue={"en"}
                      name={`visitorInfoList.${index}.visitorEmailLang`}
                      render={({ field }) => {
                        return (
                          <BaseSelect
                            isDisabled={pageType === "edit"}
                            className="minmax90 mr10"
                            value={field.value ?? "en"}
                            stateOptions={[
                              {
                                value: "ko",
                                label: "국문",
                              },
                              {
                                value: "en",
                                label: "영문",
                              },
                            ]}
                            setStateValue={(value: string) => {
                              field.onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  )}

                  <Controller
                    control={control}
                    name={`visitorInfoList.${index}.carNumber`}
                    render={({ field }) => (
                      <BaseInput
                        placeholder="주차 차량 번호 (선택)"
                        disabled={pageType === "edit"}
                        className="minmax200 mr10"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />

                  {pageType !== "edit" &&
                    (index + 1 === formVisitorInfoList.length ? (
                      <div className="flex-center-center h40">{<div className="plus-btn py10" onClick={handleClickAddButton}></div>}</div>
                    ) : (
                      <div className="flex-row flex-center-center h40">
                        <button className="base-trash-btn color-gray" onClick={() => handleClickRemoveButton(index)} type="button" />
                      </div>
                    ))}

                  {pageType === "edit" && (
                    <div className="flex-row flex-center-center h40">
                      <button className="base-trash-btn color-gray" onClick={() => handleClickRemoveButton(index)} type="button" />
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        ))}
      </section>
    </article>
  );
};

export default VisitorInfo;
