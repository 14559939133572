import PhoneNumber from "libphonenumber-js";
import moment from "moment";
import { ViewYmdFormat } from "src/utils";

export const formatVistorNums = (param: string, visitorNums: number) => {
  if (!param) return "-";

  if (visitorNums > 1) {
    return `${param} 외 ${visitorNums - 1}`;
  }

  return param;
};

export const formatVisitorMobileNumber = (visitorMobileNumber: string) => {
  if (!visitorMobileNumber) return "-";

  const pattern = /\+\d+/;

  const matches = visitorMobileNumber.match(pattern);

  if (matches) {
    const phoneNumber = PhoneNumber(matches[0], "KR");

    if (!phoneNumber) return visitorMobileNumber;

    let nationalPhoneNumber = phoneNumber?.formatNational();

    return visitorMobileNumber.replace(matches[0], nationalPhoneNumber);
  }

  return visitorMobileNumber;
};

export const checkTodayTimer = (value: Date) => {
  const today = moment().format(ViewYmdFormat.YYYY_MM_DD);
  const select = moment(value).format(ViewYmdFormat.YYYY_MM_DD);

  if (moment(today).isSame(select)) {
    let hh = Number(moment().format("HH"));
    const mm = moment().format("mm");
    let strsub1 = Number(mm.substring(0, 1));
    let strsub2 = Number(mm.substring(1, 2));

    if (strsub1 === 5) {
      hh = hh + 1;
      strsub1 = 0;
    } else {
      strsub1 = strsub1 + 1;
    }

    strsub2 = 0;

    let dateTime = today + " " + hh + ":" + strsub1 + strsub2;
    console.log("return ", dateTime);
    return moment(dateTime);
  } else {
    console.log("return ", select);
    return moment(select);
  }
};

export const getFormErrorText = (errorType: string) => {
  switch (errorType) {
    case "required":
      return "필수입력입니다.";
    case "validate":
      return "유효하지 않은 번호입니다.";
    default:
      return undefined;
  }
};
