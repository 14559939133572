import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { cardInfo, column_Last, columns_first, virtualAccountInfo, column_editable, ctid, column_edit_view } from "./PaymentListColumns";

import { useSticky } from "react-table-sticky";
import BaseScroll from "src/components/BaseScroll";
import { useCallback, useMemo } from "react";

interface DataBundle {
  paymentData: any;
  pgcode: string;
  editable?: boolean | false;
  callList: any;
  callPaymentList: any;
  firstSettings: any;
  scheduleId: string;
}
interface Props {
  dataBundle: DataBundle;
}

const PaymentTable = ({ dataBundle }: Props) => {
  const { paymentData, pgcode, editable, callList, callPaymentList, firstSettings, scheduleId } = dataBundle;

  const paymentColumns = useMemo(() => {
    let columns: any = [];

    if (pgcode === "creditcard") {
      if (editable) {
        cardInfo.forEach((col: any) => (col.width = 120));
        column_Last[0].width = 120;
        column_Last[1].width = 140;
        columns = columns_first.concat(cardInfo).concat(column_Last);
        // ctid.forEach((col: any) => (col.width = 360));
        ctid.forEach((col: any) => (col.width = 160));
        column_editable[0].width = 320;
        columns = columns.concat(ctid).concat(column_editable);
        // columns = columns.concat(ctid);
      } else {
        cardInfo.forEach((col: any) => (col.width = 160));
        column_Last[0].width = 160;
        column_Last[1].width = 160;
        columns = columns_first.concat(cardInfo).concat(column_Last);
        ctid.forEach((col: any) => (col.width = 160));
        column_edit_view[0].width = 220;
        columns = columns.concat(ctid).concat(column_edit_view);
        // columns = columns.concat(ctid);
      }
    }
    if (pgcode === "virtualaccount") {
      ctid.forEach((col: any) => (col.width = 200));
      column_Last[0].width = 180;
      column_Last[1].width = 200;
      columns = columns_first.concat(virtualAccountInfo).concat(column_Last).concat(ctid);
    }

    return columns;
  }, [pgcode]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, footerGroups } = useTable(
    {
      columns: paymentColumns,
      data: paymentData,
      editable,
      callList,
      callPaymentList,
      firstSettings,
      scheduleId,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    useRowSelect,
  );
  return (
    <div>
      <div {...getTableProps()} className="base-table sticky ">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th">
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <BaseScroll maxHeight={250}>
          <div {...getTableBodyProps()} className="body overflow-x-hidden">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </BaseScroll>
        {footerGroups.map((group) => {
          return (
            <div {...group.getFooterGroupProps()} className="overflow-x-hidden">
              {group.headers.map((column) => (
                <div {...column.getFooterProps()} className="base-table__td base-table-footer">
                  {column.render("Footer")}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentTable;
