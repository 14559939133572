import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderListQueryParams, ProviderModel } from "src/api/provider/provider-types";
import { PageMeta } from "src/api/public-types";
import { BaseInput, BasePagination, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import Link from "src/components/PartnerLink";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { PagePath } from "src/pages/product/details";

// type ProviderListQueryParams = {
//   page?: number;
//   size?: number;
//   sort?: Sort;
//   searchValue?: string;
//   supplyType?: string;
//   providerId?: string;
// };

type Props = {
  onSelected: (management: ProviderModel) => void;
  onClose: Function;
};

const ManagementListPopup = ({ onSelected, onClose }: Props) => {
  // 정산정보 목록
  const [providers, setProviders] = useState<Array<ProviderModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // pagination 숫자
  const [pageNum, setPageNum] = useState(0);

  const [selectedManagement, setSelectedManagement] = useState<ProviderModel>();

  const queryParams: ProviderListQueryParams = useMemo(() => {
    const _queryParams = {
      page: 0,
      size: 20,
      sort: {
        orders: [{ property: "providerId", direction: "DESC" }],
      },
      supplyType: "MNT",
    };

    return _queryParams;
  }, []);

  const [params, setParams] = useState<ProviderListQueryParams>({ ...queryParams });

  // 정산정보 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  // 접수자 팝업 테이블 Columns
  const ProviderColumns: any[] = useMemo(() => {
    return [
      {
        Header: "id",
        accessor: "id",
        width: 180,
        Cell: ({ row }: Cell<ProviderModel>) => {
          return (
            <div>
              <Link target="_blank" className="text-underline" to={PagePath.provider.detail.replace(":id", String(row.original.providerId))}>
                <span>{row?.original?.providerId}</span>
              </Link>
              <span className="ic-target-blank"></span>
            </div>
          );
        },
      },

      {
        Header: "법인명",
        accessor: "providerName",
        width: 400,
        Cell: ({ value }: Cell<ProviderModel>) => {
          return <BaseTooltip className="w-100 text-left" contents={value || "-"} />;
        },
      },
      {
        Header: "사업자 등록번호",
        accessor: "businessRegistrationNumber",
        width: 230,
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: ProviderColumns,
      data: providers,
    },
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 120,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    const selected = row.original;
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                    setSelectedManagement(selected);
                  }}
                  name="selectedOrg"
                  // value={}
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const getProviderListApi = useCallback(
    async (param: ProviderListQueryParams) => {
      const response = await getProviers(param);
      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviers],
  );

  useEffect(() => {
    getProviderListApi(queryParams);
  }, [getProviderListApi, queryParams]);

  const onSearchAndPagination = async (passParams: ProviderListQueryParams) => {
    const newPassParam = { ...params, ...(passParams || {}) };
    setPageNum(Number(newPassParam.page));

    const response = await getProviers(newPassParam);
    if (response.status >= 200 && response.status <= 299) {
      setProviders(response.data.data.content);
      setPageMeta(response.data.meta.pageMeta);
    }
  };

  return (
    <BaseAbstractModal isOpen={true} className="dialog-modal ">
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>정산정보 관리처 선택</h1>
        <div className="right-area">
          <div className="minmax200 mr10">
            <BaseInput
              placeholder="검색어를 입력하세요"
              onChange={(searchValue: string) => setParams({ ...params, searchValue })}
              value={params.searchValue}
              onKeyUp={() => {
                onSearchAndPagination({ page: 0 });
              }}
              onSearchClick={() => {
                onSearchAndPagination({ page: 0 });
              }}
            />
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table ">
          <div {...getTableProps()} className="base-table view-data-table sticky px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps(header.getSortByToggleProps())} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {providers.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    // <div {...row.getRowProps()} className="base-table__tr">
                    <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td ">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <p className="mt10">관리처가 없습니다.</p>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={pageNum || 0}
          totalPages={pageMeta?.totalPages || 0}
          totalElements={pageMeta?.totalElements || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size });
            onSearchAndPagination({ ...params, page: 0, size });
          }}
          goPage={(page: number) => {
            onSearchAndPagination({ page });
          }}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => onClose()}>
                취소
              </button>
              <button
                className="base-btn"
                onClick={() => {
                  onSelected(selectedManagement || {});
                }}
              >
                선택
              </button>
            </div>
          }
        />
      </section>
    </BaseAbstractModal>
  );
};

export default ManagementListPopup;
