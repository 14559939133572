import { AxiosInstance } from "axios";
import { useCallback } from "react";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { axiosInstance, axiosInstanceCourt, axiosInstanceNoAuth, axiosInstanceNoLoading, axiosInstanceWithCaptcha } from "src/utils/axios";

type ApiOperation<I, O> = (axios: AxiosInstance, params?: I) => Promise<O>;

type Options = {
  noAuthenticationRequired?: boolean; // 인증 필요없을 경우 true
  noHandleError?: boolean; // api operation 에서 에러 핸들하는것을 원하지 않을 경우 true
  noAuthenticationImageUPload?: boolean;
  noLoading?: boolean;
  useReCaptchaWithAction?: string; // 무인증 리캡차 사용하는 경우. 리캡차 action값
  externalApi?: boolean;
};

export default function useApiOperation<I, O>(apiOperation: ApiOperation<I, O>, options?: Options) {
  const { openErrorModal } = useErrorModal();

  const executeAsync: (params?: I) => Promise<O> = useCallback(
    async (params?: I) => {
      try {
        if (options?.externalApi) {
          return await apiOperation(axiosInstanceCourt, params);
        }
        if (options?.noAuthenticationRequired) {
          return await apiOperation(axiosInstanceNoAuth, params);
        }

        if (options?.noLoading) {
          return await apiOperation(axiosInstanceNoLoading, params);
        }

        if (options?.useReCaptchaWithAction) {
          const instance = await axiosInstanceWithCaptcha(options.useReCaptchaWithAction);
          return await apiOperation(instance, params);
        }

        return await apiOperation(axiosInstance, params);
      } catch (_error: any) {
        console.error("executeAsync error", _error);
        if (!options?.noHandleError) {
          // 에러
          let errorCode = "";
          let errorMessage = "";
          let statusCode = 0;

          if (_error?.response?.status) {
            statusCode = _error?.response?.status;
          }
          if (_error?.response?.data?.meta?.errorCode) {
            errorCode = _error?.response?.data?.meta?.errorCode;
          }
          //공통 Rule 에 따라 errorMessage 비노출 적용 - harry 수정
          // if (_error?.response?.data?.meta?.errorMessage) {
          //   errorMessage = _error?.response?.data?.meta?.errorMessage;
          // }

          // 에러 모달
          // openErrorModal({ errorCode, statusCode, errorMessage });
          openErrorModal({ errorCode, statusCode });
        }

        // throw _error;
        // error 발생 response 전달
        const errorResponse: O = _error.response;
        return errorResponse;
      }
    },

    [],
  );

  return { executeAsync };
}
