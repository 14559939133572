import { ProductBuildingCommonFacilitySaveListReq } from "src/api/product/product-types";
import { resData } from "src/pages/product/forms/commonSpaceForm/CommonSpace";

import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "src/utils";
import { mutationProductBuildingCommonFacility } from "src/api/product/product-api";
import { AxiosError } from "axios";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { ServiceUserGuide } from "src/api/guide/guide-types";
import { postGuideMappingAsync } from "src/api/guide/guide-api";

/** 이용안내 생성/수정/삭제 함수 */
const usePostGuideMapping = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: async (serviceUserGuideList: ServiceUserGuide[]) => await postGuideMappingAsync(axiosInstance, serviceUserGuideList),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getGuideMappingListAsync"],
      });
    },
  });

  return { ...query };
};

export default usePostGuideMapping;
