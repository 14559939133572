import React from "react";
import { useLocation } from "react-router-dom";
import { isPublicPage } from "src/utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function UserBaseModal(props: Props & React.HTMLAttributes<HTMLDivElement>) {
  const { pathname } = useLocation();

  return (
    <div className={isPublicPage(pathname) ? `dim mobile` : "dim"}>
      <div className="user-base-modal">
        <div className="user-base-modal-content">{props.children}</div>
      </div>
    </div>
  );
}
export default UserBaseModal;
