import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { DetailTotalData } from "src/api/contract/contract-types";
import { ArticleSection } from "src/components/layout/ArticleSection";
import pagePath from "src/pagePath.json";
import BillingScheduleDetail from "./detailComponent/BillingScheduleDetail";
import BillingScheduleEdit from "./editComponent/BillingScheduleEdit";
import BuildingManagementDetail from "./detailComponent/BuildingManagementDetail";
import BuildingManagementEdit from "./editComponent/BuildingManagementEdit";
import { useApiOperation } from "src/api/hooks";
import { getManagementBuildingsAsync } from "src/api/contract/contract-api";
import { getBuildingsAsync } from "src/api/building/building-api";

interface BasicProps {
  detailData: DetailTotalData;
  setData: Function;
}

const ContractBuildingManagement = ({ detailData }: BasicProps) => {
  const { partnerId } = useParams();
  const R = "RENTAL";
  const [editable, setEditable] = useState(false);
  const [saveFlags, setSaveFlags] = useState(false);

  const { contract, contractManage, scheduleList, provider } = detailData;

  const [textGrey] = useState("contents-container__grid ");
  const [rentalSchedule, setRentalSchedule] = useState<BillingDetail>();
  const [mntSchedule, setMntSchedule] = useState<BillingDetail[]>();
  const [popupState, setPopupState] = useState(false);
  const [editConfrim, setEditConfrim] = useState(false);
  const [managementBuildingData, setManagementBuildingData] = useState<any>([]);
  const [commonModals, setCommonModals] = useState<any>({
    message: "",
    title: "",
  });
  const { executeAsync: getManagementBuildings } = useApiOperation(getManagementBuildingsAsync);
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  useEffect(() => {
    if (detailData) {
      const schedule = scheduleList?.find((sd: BillingDetail) => sd.supplyType === R);
      const mntList = scheduleList?.filter((sd: BillingDetail) => sd.supplyType !== R);

      setRentalSchedule(schedule);

      setMntSchedule(mntList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);
  const fetchApi = useCallback(async () => {
    const { data, status } = await getManagementBuildings({ contractManageId: contract?.contractManageId! });
    if (status >= 200 && status < 300) {
      const buildingManageList = data?.data?.buildingManageList;
      const buildingIdArray = data?.data?.buildingManageList?.map((buildingManage) => buildingManage.buildingId);
      const { data: buildingsData, status: buildingsStatus } = await getBuildings({ page: 0, size: 9999, id: buildingIdArray.join(",") });
      // 건물 운영정보의 건물id로 건물 기본정보를 조회해와서 건물명, 주소를 viewTable 정보에 추가
      if (buildingsStatus >= 200 && status < 300) {
        const buildingBasicInfoArray = buildingsData.data.content;
        const viewTableBuildingManagementData = buildingManageList.map((buildingManage) => {
          const findBuildingDetail = buildingBasicInfoArray?.find(
            (buildingDetail) => Number(buildingDetail.id) === Number(buildingManage.buildingId),
          );
          return { ...buildingManage, buildingName: findBuildingDetail?.buildingName, address: findBuildingDetail?.address };
        });
        setManagementBuildingData(viewTableBuildingManagementData);
      }
    }
  }, []);
  useEffect(() => {
    if (contract && contract?.contractManageId) {
      fetchApi();
    }
  }, [contract]);

  return (
    <ArticleSection
      title={"건물 운영 관리"}
      isEditable={editable}
      setIsEditable={setEditable}
      onClickSave={() => setSaveFlags(true)}
      popupState={popupState}
      setPopupState={setPopupState}
      editConfrim={editConfrim}
      setEditConfrim={setEditConfrim}
      commonModals={commonModals}
      disabledState={[String(detailData?.contract?.contractStep), String(detailData?.product.productType)]}
      children={
        editable === false ? (
          <BuildingManagementDetail
            detailData={detailData}
            managementBuildingData={managementBuildingData}
            contract={contract!}
            fetchApi={fetchApi}
          />
        ) : (
          <BuildingManagementEdit
            detailData={detailData}
            saveFlags={saveFlags}
            contract={contract!}
            setSaveFlags={setSaveFlags}
            setEditable={setEditable}
            setData={setManagementBuildingData}
            popupState={popupState}
            setPopupState={setPopupState}
            setCommonModals={setCommonModals}
            setEditConfrim={setEditConfrim}
            managementBuildingData={managementBuildingData}
          />
        )
      }
    />
  );
};
export default ContractBuildingManagement;
