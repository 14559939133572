import { RefObject, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { AdminMemoAddModel, InsertAdminMemo, ServiceTypes, UnionServiceType, UpdateAdminMemo } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import useMediasUpdate from "src/hooks/useMediasUpdate";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { BaseButton } from "../BaseButton";
import { BaseTextarea } from "../BaseTextarea";
import BaseFileUpload from "../mediaFile/BaseFileUpload";
import { AdminMemoIntegrationFormData } from "./adminmemo-types";
import useAdminMemo from "./useAdminMemo";

type Props = {
  serviceId: number; //
  serviceType: UnionServiceType;
  memoId?: string;
  callList: () => Promise<void>;
  onClose: () => void;
  openAdminMemoDetail?: (serviceId: string, serviceType: UnionServiceType, memoId: string) => void;
};

const AdminMemoForm = ({ serviceId, serviceType, memoId, callList, onClose, openAdminMemoDetail }: Props) => {
  const [buttonRef, setButtonRef] = useState<RefObject<HTMLButtonElement>>();
  const { add, edit, getList } = useAdminMemo();

  const partner = useRecoilValue(globalPartnerState);

  const { openErrorModal } = useErrorModal();

  const {
    setValue,
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminMemoIntegrationFormData>({
    defaultValues: {
      id: "",
      memo: "",
      serviceId,
      serviceType,
    },
  });

  // 첨부파일 리스트
  const [medias, setMedias] = useState<MediaFile[]>([]);
  // 공통 미디어 파일 커스텀 훅
  const { requestMedias, updateMedias } = useMediasUpdate({ mediaList: medias });
  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync);

  //  첨부파일 등록
  const postMediaFiles = async (serviceId: string) => {
    const { data: mediaResponse, status: mediaStatus } = await updateMedias(serviceId);
    if (mediaStatus >= 200 && mediaStatus <= 299) {
      console.log(`미디어 성공`);
    } else {
      const errorCode = mediaResponse?.meta?.errorCode;
      errorCode && openErrorModal({ errorCode, statusCode: mediaStatus, errorMessage: "첨부파일 등록이 실패하였습니다.\n 재등록해 주세요." });
    }
  };

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: Array<{ serviceId: number; serviceType: UnionServiceType }>) => {
      const response = await getMediaFileList({ contentsList: data });

      if (response.status >= 200 && response.status <= 299) {
        setMedias(response.data.data.content);
      }
    },
    [getMediaFileList],
  );
  useEffect(() => {
    const findMemo = async () => {
      const memoList = await getList(Number(serviceId), serviceType);
      const find: AdminMemoAddModel = memoList.data.data.content.find((memo: AdminMemoAddModel) => memo.id === memoId);
      setValue("id", find.id);
      setValue("memo", find.memo || "");
    };
    if (serviceId && memoId) {
      findMemo();
      fetchMediaList([{ serviceId: Number(memoId), serviceType: ServiceTypes.SERVICE_ADMINMEMO }]);
    }
  }, [memoId, fetchMediaList, serviceId]);

  //  관리자 메모 등록/수정
  const onSubmit = useCallback(
    async (data: AdminMemoIntegrationFormData) => {
      let response;

      if (getValues("id") === "") {
        const addMemoData: InsertAdminMemo = {
          partnerId: String(partner?.id),
          serviceId: String(serviceId),
          memo: data.memo,
          serviceType,
        };
        response = await add(addMemoData);
      } else {
        const editMemoData: UpdateAdminMemo = {
          id: String(data.id),
          partnerId: String(partner?.id),
          serviceId: String(data.serviceId),
          memo: data.memo,
          serviceType,
        };

        response = await edit(editMemoData);
      }

      // 관리자 메모에 첨부파일이 있으면 첨부파일 api 실행 후 navigate
      if (requestMedias.length > 0 && response) {
        await postMediaFiles(String(response.data.data.content.id));
      }
      await callList();
      onClose();
      openAdminMemoDetail && openAdminMemoDetail(String(serviceId), serviceType, String(memoId) || "");
    },
    [getValues, partner?.id, serviceId, serviceType, postMediaFiles, openAdminMemoDetail],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 관리자 메모 input 유효성
  const formVaildation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("memo", {
      required: { value: true, message: requiredMessage },
    });
  }, [register]);

  useEffect(() => {
    formVaildation();
  }, [formVaildation]);
  return (
    <>
      <section className="base-abstract-modal__contents px30 overflow-x-hidden">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <section className="base-abstract-modal__title flex-center-between px0">
            <h2 className="font20 ">관리자 메모</h2>
          </section>
          <section className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p className="required">내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <Controller
                control={control}
                name="memo"
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <>
                    <BaseTextarea
                      height={144}
                      className="admin-memo mr8 "
                      name={name}
                      value={value}
                      placeholder="메모를 남길 수 있습니다."
                      maxLength={1000}
                      onChange={onChange}
                    />
                  </>
                )}
              ></Controller>
              {errors && errors.memo && <p className="validation-text">{errors.memo.message}</p>}
            </div>
          </section>
          <section className="contents-container__grid contents-container__1200 mb30">
            <div className="contents-container__grid-index">
              <p>{`첨부파일 (${medias.filter((item) => !item.isRemoved).length}/10)`}</p>
            </div>
            <BaseFileUpload
              limit={10}
              maxWidth={555}
              fileList={medias}
              setFiles={(files: MediaFile[]) => {
                const newFiles = files.map((file) => ({ ...file, serviceType: ServiceTypes.SERVICE_ADMINMEMO }));
                setMedias(newFiles);
              }}
            />
          </section>
          <BaseButton
            title="저장"
            type="submit" //
            className="d-none"
            setRef={setButtonRef}
          />
        </form>
      </section>
      <div className="h-100 flex-center-end pa30">
        <BaseButton title="취소" className="color-white  mr10  flex-center" onClick={onClose} />
        <BaseButton
          title="저장"
          type="button" //
          className=" flex-center "
          onClick={() => {
            buttonRef?.current?.click();
          }}
        />
      </div>
    </>
  );
};

export default AdminMemoForm;
