import "moment/locale/ko";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
// import {  useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getFileUploadPoliciesAsync } from "src/api/file/file-api";
import { FilePolicy } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { getMgmtOfficeAsync } from "src/api/mgmtOffice/mgmt-office-api";
import { MgmtOffice, Officer } from "src/api/mgmtOffice/mgmt-office.types";
import { getProviderBasicAsync } from "src/api/provider/provider-api";
import { ProviderBasicModel } from "src/api/provider/provider-types";
import { Modal, errorCode } from "src/api/public-types";
import { getExternalVisitorQrAsync } from "src/api/visitor/visitor-api";
import { ExternalQrResponse } from "src/api/visitor/visitor-types";
import { getWorkOrderDetailAsync, putWorkOrderAsync } from "src/api/work-order/workorder-api";
import { MediaServiceType, WorkOrderDetail as WorkOrder, WorkOrderSheetCost, WorkOrderStatus } from "src/api/work-order/workorder-types";
import { BaseButton } from "src/components";
import BaseConfirmModal from "src/components/BaseConfirmModal";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { changeCurrency } from "src/utils/common-util";
import ImagesList from "./components/ImagesList";
import MobileContents from "./components/MobileContents";
import MobileSummary from "./components/MobileSummary";
import { useToast } from "src/recoil/toast/hook";

/**
 * 워크오더 상세
 * workSheet의 복사본인 changedWorkSheet를 항시 비교
 */

const MIN_TEXTAREA_HEIGHT = 100;

const WorkOrderDetail = () => {
  const [workSheet, setWorkSheet] = useState<WorkOrder>(); // 워크시트 상세 조회
  const [changedWorkSheet, setChangedWorkSheet] = useState<WorkOrder>(); // 변경된 워크시트 상세 조회
  const [mgmtOffice, setMgmtOffice] = useState<MgmtOffice>(); // 관리처 조회
  const [providerBasic, setProviderBasic] = useState<ProviderBasicModel>(); // 정산정보 조회
  const [workSheetOfficer, setWorkSheetOfficer] = useState<Officer>(); // 해당 워크시트 메인 담당자
  const [submitData, setSubmitData] = useState<WorkOrder>(); // 수정시 전송할 데이터
  const [isOverDate, setIsOverDate] = useState<boolean>(false); // 워크시트 만료여부 (시작일로부터 30일 지난 워크시트)
  const [policy, setPolicy] = useState<FilePolicy>({}); // 무인증 파일 업로드 정책
  const [visitorQr, setVisitorQr] = useState<ExternalQrResponse>();
  const [isDropdownOneOpen, setIsDropdownOneOpen] = useState<boolean>(false);
  const [isDropdownTwoOpen, setIsDropdownTwoOpen] = useState<boolean>(false);
  const { executeAsync: getWorkOrderDetails } = useApiOperation(getWorkOrderDetailAsync, { noHandleError: true, noAuthenticationRequired: true }); // 워크오더 상세 조회
  const { executeAsync: getMgmtOffice } = useApiOperation(getMgmtOfficeAsync, { noAuthenticationRequired: true }); // 관리처 조회
  const { executeAsync: putWorkOrder } = useApiOperation(putWorkOrderAsync, { useReCaptchaWithAction: "ctrlroom/work_order/update" }); // 워크시트 수정
  const { executeAsync: getFileUploadPolicies } = useApiOperation(getFileUploadPoliciesAsync, { noAuthenticationRequired: true }); // 이미지 정책 조회
  const { executeAsync: getProviderBasic } = useApiOperation(getProviderBasicAsync, { noAuthenticationRequired: true }); // 정산정보 기본 조회
  const { executeAsync: getExternalVisitorQr } = useApiOperation(getExternalVisitorQrAsync, { noAuthenticationRequired: true }); // 방문초대내역 정보 조회

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<Modal>({ isOpen: false }); // 수정 api 데이터 전송 컨펌 모달

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { openToast } = useToast();
  const { workOrderSheetNo } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    register,
    // formState: { errors, isDirty },
  } = useForm<WorkOrder>({
    // mode: "onChange",
    defaultValues: {
      // workOrderSheetCostList:[],
      mediaList: [],
    },
  });
  const {
    fields: costList,
    append,
    remove,
    update,
  } = useFieldArray({
    control: control,
    name: `workOrderSheetCostList`,
  });

  const formProps = {
    control,
    setValue,
    watch,
    getValues,
    // errors,
    reset,
  };
  interface FrontWorkOrderSheetCost extends WorkOrderSheetCost {
    apiId?: string;
  }

  // 워크시트코스트가 빈배열일떄 초기 form을 보여주기위한 initial form
  const workOrderSheetCostInitialForm: WorkOrderSheetCost[] = useMemo(
    () => [
      {
        description: "",
        cost: "",
        cmdType: "C",
      },
    ],
    [],
  );

  // textarea 높이값 계산
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
    }
  }, [textareaRef.current, changedWorkSheet?.completionReport, isDropdownOneOpen]);

  // 해당 워크시트 담당자 찾기
  const findOfficer = useCallback((officerId?: string, officerList?: Array<Officer>): void => {
    if (officerId && officerList) {
      const mainOfficer = officerList.find((officer) => officer.id === officerId);
      setWorkSheetOfficer(mainOfficer);
    }
  }, []);

  // 워크시트, 관리처, 정산정보 조회
  const fetchApis = useCallback(
    async (workOrderSheetNo: string) => {
      const res = await getWorkOrderDetails({
        workOrderSheetNo,
      });
      if (res.status >= 200 && res.status <= 299) {
        const workOrderDetail = res.data?.data?.content;
        if (!!workOrderDetail.id) {
          setValue("status", workOrderDetail.status);
          setValue("completionReport", workOrderDetail.completionReport);
          setValue("mediaList", []);

          // 처리비용이 있으면, apiId라는 프로퍼티에 id 추가
          if (workOrderDetail.workOrderSheetCostList?.length === 0 || !workOrderDetail.workOrderSheetCostList) {
            setWorkSheet({ ...workOrderDetail, workOrderSheetCostList: workOrderSheetCostInitialForm });
            setChangedWorkSheet({ ...workOrderDetail, workOrderSheetCostList: workOrderSheetCostInitialForm });
            setValue("workOrderSheetCostList", workOrderSheetCostInitialForm);
          } else {
            const newCostList = workOrderDetail.workOrderSheetCostList?.map((item: WorkOrderSheetCost) => {
              return { ...item, apiId: item.id };
            });
            setValue("workOrderSheetCostList", newCostList);
            setWorkSheet({ ...workOrderDetail, workOrderSheetCostList: newCostList });
            setChangedWorkSheet({ ...workOrderDetail, workOrderSheetCostList: newCostList });
          }

          // 처리내용이 null로 내려올 경우
          if (workOrderDetail.completionReport === null) {
            setWorkSheet((prev: any) => {
              return {
                ...prev,
                completionReport: "",
              };
            });
            setChangedWorkSheet((prev: any) => {
              return {
                ...prev,
                completionReport: "",
              };
            });
          }

          // 관리처 조회
          const { data: mgmtData } = await getMgmtOffice({
            id: res.data?.data?.content.mgmtOfficeId, // 관리처 ID
          });
          if (mgmtData?.data?.content) {
            setMgmtOffice(mgmtData?.data?.content);
            findOfficer(res.data?.data?.content.mgmtOfficerId, mgmtData?.data?.content.officerList);
            // provider id로 정산정보 조회
            if (mgmtData?.data?.content.providerId) {
              const { data: providerBasic } = await getProviderBasic({ providerId: mgmtData?.data?.content.providerId });
              setProviderBasic(providerBasic?.data.provider);
            }
          }
        }
        const { data: policyData } = await getFileUploadPolicies({ policyName: "public-image-file" });
        setPolicy(policyData?.data?.policy);
      } else if (res.data?.meta?.errorCode === errorCode.ePR0557) {
        // 만료된 데이터
        setIsOverDate(true);
      }
    },
    [getWorkOrderDetails, getMgmtOffice, getFileUploadPolicies, findOfficer, setValue, getProviderBasic, workOrderSheetCostInitialForm],
  );

  const getPayload = (data: WorkOrder) => {
    let submit = { ...data };
    // 빈값일경우 프로퍼티 제거
    if (submit?.mediaList?.length === 0) {
      delete submit.mediaList;
    }
    if (!submit.completionReport) {
      delete submit.completionReport;
    }

    delete submit.mgmtOfficerId;

    // workOrderSheetCostList의 apiId가 있으면  id로 교체 후 기존 apiId값 제거, 빈값일경우 프로퍼티 제거
    const filterExistCostList = submit?.workOrderSheetCostList?.map((item: FrontWorkOrderSheetCost) => {
      if (item.apiId) {
        const newObj = { ...item, id: item.apiId };
        delete newObj.apiId;
        return newObj;
      } else return item;
    });

    // C D 타입이 아닌 처리비용 내용이 변동되었을 경우 U 값을 붙여서 리턴
    const filteredAddCmdTypeArray = filterExistCostList
      ?.filter((cost) =>
        workSheet?.workOrderSheetCostList?.every((item) => {
          if (cost.cmdType === "C" || cost.cmdType === "D") return true;
          return cost.description !== item.description || cost.cost !== item.cost;
        }),
      )
      .map((cost) => {
        if (cost.cmdType === "C" || cost.cmdType === "D") {
          return cost;
        } else {
          return { ...cost, cmdType: "U", cost: Number(cost.cost) };
        }
      });

    // 초기 빈값은 변동사항에 포함시키지않고, 코스트가 빈값일경우 0으로 전환
    const filteredCtypeNoId = filteredAddCmdTypeArray
      ?.filter((item) => !(!item.id && item.cmdType === "C" && !item.description && !item.cost))
      .map((item) => {
        if (item.cost === "") {
          return { ...item, cost: Number(item.cost) };
        } else return item;
      });

    // 처리비용이 빈값일 경우 프로퍼티제거
    if (filteredCtypeNoId?.length === 0) {
      delete submit.workOrderSheetCostList;
    } else {
      submit = { ...submit, workOrderSheetCostList: filteredCtypeNoId };
    }
    console.log("submit", submit);
    return submit;
  };

  // 임시저장 submit 될때 실행
  const onSubmit = useCallback(
    (data: WorkOrder, e: any) => {
      e.preventDefault();

      const submit = getPayload(data);

      setSubmitData(submit);
      fetchEditWorkOrder(submit, "temp");
    },
    [workSheet],
  );

  const handleFinishWork = (data: WorkOrder, e: any) => {
    e.preventDefault();
    const submit = getPayload(data);
    const newSubmit = { ...submit, status: WorkOrderStatus.COMPLETED };
    setSubmitData(newSubmit);
    fetchEditWorkOrder(newSubmit, "finishWork");
  };

  // 워크시트 수정
  const fetchEditWorkOrder = useCallback(
    async (data: WorkOrder, type?: string) => {
      if (!workOrderSheetNo) return null;
      setIsOpenConfirmModal({ isOpen: false });
      const res = await putWorkOrder({ workOrderSheetNo, workOrderSheet: data });
      if (res.status >= 200 && res.status <= 299) {
        type === "temp" && openToast({ content: "작성 중인 글이 임시 저장되었습니다.", isMobileToast: true });
        // type === "finishWork" && openToast({ content: "작성 중인 글이 저장되었습니다.", isSystemToast: true });
        fetchApis(workOrderSheetNo);
      }
    },
    [submitData, putWorkOrder, fetchApis],
  );

  const isRenderFullContents = () => {
    // summary를 제외한 영역의 렌더링 여부
    if (
      workSheet?.status === WorkOrderStatus.OPEN ||
      workSheet?.status === WorkOrderStatus.PROGRESS ||
      workSheet?.status === WorkOrderStatus.COMPLETED
    ) {
      return true;
    } else return false;
  };
  const isRenderExtraInfo = () => {
    // 특이사항과 처리비용 및 영수증 영역의 렌더링 여부
    if (
      workSheet?.status === WorkOrderStatus.PROGRESS ||
      workSheet?.status === WorkOrderStatus.COMPLETED ||
      workSheet?.status === WorkOrderStatus.CLOSED
    ) {
      return true;
    } else return false;
  };

  // useEffect
  useEffect(() => {
    if (workOrderSheetNo) {
      fetchApis(workOrderSheetNo);
    }
  }, [fetchApis, workOrderSheetNo]);

  if (!workOrderSheetNo) return null;

  return (
    <>
      <MetaTag subTitle="업무상세" />
      <div className="page-workorder-mobile">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MobileSummary workSheet={workSheet} workSheetOfficer={workSheetOfficer} mgmtOffice={mgmtOffice} providerBasic={providerBasic} />
          {isRenderFullContents() && (
            <>
              <MobileContents
                workSheet={workSheet}
                changedWorkSheet={changedWorkSheet}
                formProps={formProps}
                setChangedWorkSheet={setChangedWorkSheet}
                policy={policy}
              />

              {isRenderExtraInfo() && (
                <section className="page-workorder-mobile__contents py0 px24 divider-top-bold">
                  <div className="dropdown-wrap">
                    <button
                      type="button"
                      className={`dropdown-button ${isDropdownOneOpen ? "--active" : ""}`}
                      onClick={() => setIsDropdownOneOpen((prev) => !prev)}
                    >
                      특이사항 기록
                    </button>
                    <div className={`dropdown-contents ${isDropdownOneOpen ? "--active" : ""}`}>
                      {workSheet?.status === WorkOrderStatus.PROGRESS ? (
                        <Controller
                          name="completionReport"
                          control={control}
                          render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                            <textarea
                              name={name}
                              className="base-textarea-mobile auto-height mt20"
                              style={{ minHeight: MIN_TEXTAREA_HEIGHT }}
                              ref={textareaRef}
                              readOnly={!(workSheet?.status === WorkOrderStatus.PROGRESS || workSheet?.status === WorkOrderStatus.COMPLETED)}
                              id="completionReport"
                              value={
                                (changedWorkSheet?.status === WorkOrderStatus.CANCELED || changedWorkSheet?.status === WorkOrderStatus.CLOSED) &&
                                !value
                                  ? "처리 내용이 없습니다"
                                  : !value
                                  ? ""
                                  : value
                              }
                              placeholder="내용을 입력해주세요"
                              onChange={(e) => {
                                onChange(e);
                                changedWorkSheet && setChangedWorkSheet({ ...changedWorkSheet, completionReport: e.target.value });
                              }}
                              onBlur={onBlur}
                            ></textarea>
                          )}
                        />
                      ) : (
                        <p className="extra-info mt20 pre-formatted">내용 : {workSheet?.completionReport || "-"}</p>
                      )}

                      {changedWorkSheet && (
                        <ImagesList
                          isReadonly={!(workSheet?.status === WorkOrderStatus.PROGRESS)}
                          isHideAddBtn={!(workSheet?.status === WorkOrderStatus.PROGRESS)}
                          setChangedWorkSheet={setChangedWorkSheet}
                          changedWorkSheet={changedWorkSheet}
                          setValue={setValue}
                          getValues={getValues}
                          control={control}
                          policy={policy}
                          serviceType={MediaServiceType.MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT}
                          maxFiles={3}
                        />
                      )}
                    </div>
                  </div>
                  <div className="dropdown-wrap">
                    <button
                      type="button"
                      className={`dropdown-button ${isDropdownTwoOpen ? "--active" : ""}`}
                      onClick={() => setIsDropdownTwoOpen((prev) => !prev)}
                    >
                      처리비용 및 영수증
                    </button>
                    <div className={`dropdown-contents ${isDropdownTwoOpen ? "--active" : ""}`}>
                      {workSheet?.status === WorkOrderStatus.PROGRESS ? (
                        <>
                          {costList.map((cost: FrontWorkOrderSheetCost, idx: number) => {
                            return (
                              <div key={`workOrderSheetCostList.${idx}.description`} className={cost.cmdType === "D" ? "d-none" : ""}>
                                <div className="mt10">
                                  <Controller
                                    control={control}
                                    name={`workOrderSheetCostList.${idx}.description`}
                                    render={({ field: { onChange, value, name, onBlur }, fieldState: { error, isDirty } }) => {
                                      return (
                                        <input
                                          className="base-input-mobile"
                                          maxLength={200}
                                          type="text"
                                          onKeyDown={(e) => {
                                            return e.key === "Enter" ? e.preventDefault() : () => {};
                                          }}
                                          id={`workOrderSheetCostList.${idx}.description`}
                                          name={`workOrderSheetCostList.${idx}.description`}
                                          placeholder="비용 발생 사유를 입력해주세요"
                                          readOnly={
                                            !(
                                              changedWorkSheet?.status === WorkOrderStatus.PROGRESS ||
                                              changedWorkSheet?.status === WorkOrderStatus.COMPLETED
                                            )
                                          }
                                          value={value ? value : ""}
                                          onChange={(e) => {
                                            onChange(e);
                                            setChangedWorkSheet((prev) => {
                                              if (prev?.workOrderSheetCostList) {
                                                const newWorkOrderSheetCostList = [...prev.workOrderSheetCostList];
                                                newWorkOrderSheetCostList[idx] = {
                                                  ...newWorkOrderSheetCostList[idx],
                                                  description: e.target.value,
                                                };
                                                return {
                                                  ...prev,
                                                  workOrderSheetCostList: newWorkOrderSheetCostList,
                                                };
                                              }
                                            });
                                          }}
                                        ></input>
                                      );
                                    }}
                                  ></Controller>
                                  <div className="mt10">
                                    <Controller
                                      control={control}
                                      name={`workOrderSheetCostList.${idx}.cost`}
                                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                                        return (
                                          <>
                                            <input
                                              maxLength={12}
                                              placeholder="비용을 입력해주세요"
                                              type="text"
                                              onKeyDown={(e) => {
                                                return e.key === "Enter" ? e.preventDefault() : () => {};
                                              }}
                                              readOnly={
                                                !(
                                                  changedWorkSheet?.status === WorkOrderStatus.PROGRESS ||
                                                  changedWorkSheet?.status === WorkOrderStatus.COMPLETED
                                                )
                                              }
                                              onChange={(e) => {
                                                onChange(e);
                                                const inputValue = e.target.value;
                                                const changeValue: any = inputValue.replaceAll(",", ""); // NaN 일수있음
                                                if (isNaN(changeValue)) {
                                                  setValue(`workOrderSheetCostList.${idx}.cost`, "");
                                                  return;
                                                } else {
                                                  setValue(`workOrderSheetCostList.${idx}.cost`, changeValue);
                                                }

                                                setChangedWorkSheet((prev) => {
                                                  if (prev?.workOrderSheetCostList) {
                                                    const newWorkOrderSheetCostList = [...prev.workOrderSheetCostList];
                                                    newWorkOrderSheetCostList[idx] = {
                                                      ...newWorkOrderSheetCostList[idx],
                                                      cost: Number(inputValue.replaceAll(",", "")),
                                                    };
                                                    return {
                                                      ...prev,
                                                      workOrderSheetCostList: newWorkOrderSheetCostList,
                                                    };
                                                  }
                                                });
                                              }}
                                              value={value && changeCurrency(Number(value)!.toString())}
                                              className="base-input-mobile"
                                            ></input>
                                          </>
                                        );
                                      }}
                                    ></Controller>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <p className="extra-info mt20">내용 : {workSheet?.workOrderSheetCostList?.[0]?.description || "-"}</p>
                          <p className="extra-info mt10">
                            비용 :{" "}
                            {workSheet?.workOrderSheetCostList?.[0]?.cost
                              ? changeCurrency(Number(workSheet?.workOrderSheetCostList?.[0]?.cost)!.toString())
                              : "-"}
                          </p>
                        </>
                      )}

                      {changedWorkSheet && (
                        <ImagesList
                          isReadonly={!(workSheet?.status === WorkOrderStatus.PROGRESS)}
                          isHideAddBtn={!(workSheet?.status === WorkOrderStatus.PROGRESS)}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          policy={policy}
                          setChangedWorkSheet={setChangedWorkSheet}
                          changedWorkSheet={changedWorkSheet}
                          serviceType={MediaServiceType.MEDIA_WORK_ORDER_SHEET_COST}
                          maxFiles={1}
                        />
                      )}
                    </div>
                  </div>
                </section>
              )}
            </>
          )}

          {/* 종료 */}
          {workSheet?.status === WorkOrderStatus.CLOSED && (
            <section className="detail-empty-case">
              <h2>관리자 승인 후 종료된 업무입니다</h2>
            </section>
          )}
          {/* 취소 */}
          {workSheet?.status === WorkOrderStatus.CANCELED && (
            <section className="detail-empty-case">
              <h2>취소된 업무입니다</h2>
            </section>
          )}
          {/* 에러 */}
          {!workSheet && (
            <section className="detail-empty-case">
              <h2>
                담당 작업자만
                <br />
                업무에 접근할 수 있습니다
              </h2>
            </section>
          )}

          {/* 접수 - 업무 시작하기 */}
          {workSheet?.status === WorkOrderStatus.OPEN && (
            <section className="fixed-bottom-mobile">
              <BaseButton
                title="업무 시작하기"
                className="size-large w-100"
                width={"100%"}
                onClick={() => setIsOpenConfirmModal({ isOpen: true, type: "startWork", message: "업무를 시작하시겠습니까?" })}
              />
            </section>
          )}
          {/* 작업중 - 임시저장 및 업무완료 */}
          {workSheet?.status === WorkOrderStatus.PROGRESS && (
            <section className="fixed-bottom-mobile top-shadow">
              <button type="submit" name="temp" className="only-text-btn mr28">
                임시저장
              </button>
              <BaseButton
                title="업무 완료하기"
                className="size-large w-100"
                width={"100%"}
                name="finish"
                onClick={() => setIsOpenConfirmModal({ isOpen: true, type: "finishWork", message: "업무를 완료하시겠습니까?" })}
              />
            </section>
          )}
          {/* 완료 - 업무 완료 승인하기 */}
          {workSheet?.status === WorkOrderStatus.COMPLETED && (
            <section className="fixed-bottom-mobile">
              <BaseButton
                title="업무 완료 승인하기"
                className="size-large w-100"
                width={"100%"}
                onClick={() => setIsOpenConfirmModal({ isOpen: true, type: "closeWork", message: "작업자의 업무완료를 승인하시겠습니까?" })}
              />
            </section>
          )}
        </form>

        {/* 컨펌 모달 */}
        {workOrderSheetNo && isOpenConfirmModal.isOpen && (
          <BaseConfirmModal
            isOpen={true}
            btnLeftTitle={
              isOpenConfirmModal.type === "startWork" || isOpenConfirmModal.type === "finishWork" || isOpenConfirmModal.type === "closeWork"
                ? "아니오"
                : ""
            }
            btnRightTitle={isOpenConfirmModal.type === "startWork" ? "네" : "확인"}
            btnRightType="button"
            onClose={() => {
              setIsOpenConfirmModal({ isOpen: false });
            }}
            title={isOpenConfirmModal.message}
            onClick={
              isOpenConfirmModal.type === "finishWork"
                ? handleSubmit(handleFinishWork)
                : () => {
                    if (isOpenConfirmModal.type === "startWork") {
                      // 업무시작
                      fetchEditWorkOrder({ status: WorkOrderStatus.PROGRESS });
                    } else if (isOpenConfirmModal.type === "closeWork") {
                      //업무승인
                      fetchEditWorkOrder({ status: WorkOrderStatus.CLOSED });
                    } else {
                      //단순확인
                      setIsOpenConfirmModal({ isOpen: false });
                    }
                  }
            }
          >
            {isOpenConfirmModal.type === "finishWork" && <p className="font15">관리자에게 완료 요청 알림이 발송됩니다.</p>}
            {isOpenConfirmModal.type === "closeWork" && <p className="font15">승인시 업무가 종료됩니다.</p>}
          </BaseConfirmModal>
        )}
      </div>
    </>
  );
};
export default WorkOrderDetail;
