import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";

import { BaseButton, BaseInput, BaseRadio, BaseSelect } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";

import { CmdType, MediaFile, Modal } from "src/api/public-types";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { useQueryParams } from "src/hooks/useQueryParams";

import { useRecoilValue } from "recoil";
import { ServiceTypes, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { editGuideAsync, getGuideListAsync, postGuideAsync } from "src/api/guide/guide-api";
import { UserGuideModel, UserGuideTypes } from "src/api/guide/guide-types";
import { getProviderList } from "src/api/provider/provider-api";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import MarkdownEditor from "src/components/MarkdownEditor";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import useMediasUpdate from "src/hooks/useMediasUpdate";
import ProviderSelectModal from "src/pages/closedPeriod/components/ProviderSelectModal";
import { PagePath } from "src/pages/product/details";

import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useToast } from "src/recoil/toast/hook";
import { compareCUDData } from "src/utils";
import FreeInternetSection from "../../components/GuideWifiListSection";
import { guideTypeOptions } from "../../guide-types";

type SelectedProvider = {
  providerId: string;
  providerName: string;
};

// NEW 이용안내(1.19)
const BasicInfoFrom = () => {
  const navigate = useNavigate();
  const { setBaseModal } = useModal();
  const partner = useRecoilValue(globalPartnerState);
  const { queryParams } = useQueryParams<{ id?: string }>();
  const { openToast } = useToast();
  const [selectedProvider, setSelectedProvider] = useState<SelectedProvider[]>([]);
  // 상담관리 미디어 파일
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([]);
  const { requestMedias, updateMedias } = useMediasUpdate({ mediaList: mediaFiles });

  const [isProviderSelectModalOpen, setIsProviderSelectModalOpen] = useState<Modal>({ isOpen: false });

  //수정모드
  const isEdit = !!queryParams.id;

  // 프로바이더 목록
  const { executeAsync: getProvider } = useApiOperation(getProviderList);

  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync);

  //사용안내 목록(상세용)
  const { executeAsync: getGuideList } = useApiOperation(getGuideListAsync);

  // 사용안내 등록
  const { executeAsync: postGuide } = useApiOperation(postGuideAsync);

  // 사용안내 수정
  const { executeAsync: editGuide } = useApiOperation(editGuideAsync);

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: { serviceId: number; serviceType: UnionServiceType }) => {
      const contentsList = [{ serviceId: data.serviceId, serviceType: data?.serviceType }];

      const response = await getMediaFileList({ contentsList });
      if (response.status >= 200 && response.status <= 299) {
        setMediaFiles(response.data.data.content);
      }
    },
    [getMediaFileList],
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    formState: { errors, defaultValues },
  } = useForm<UserGuideModel>({
    defaultValues: {
      wifiList: [{ wifiName: "", wifiPassword: "" }],
      isOpened: false,
    },
  });

  const guideType = watch("type");

  useEffect(() => {
    const fetchApi = async () => {
      const response = await getGuideList({ page: 0, size: 1, idList: queryParams.id });
      const guide: UserGuideModel | undefined = response?.data?.data?.content?.[0];

      if (!guide) {
        return;
      }

      reset({
        id: guide.id,
        type: guide.type,
        providerId: guide.providerId,
        subject: guide.subject,
        description: guide.description,
        location: guide.location,
        isOpened: guide.isOpened,
        wifiList: guide.wifiList,
      });

      if (guide.type === "USER_GUIDE_ETC") {
        // 일반 타입에서만 첨부파일 실행
        fetchMediaList({ serviceId: Number(queryParams.id), serviceType: ServiceTypes.SERVICE_USER_GUIDE });
      }

      getProvider({ providerId: guide.providerId }).then((data) =>
        setSelectedProvider([
          {
            providerId: String(data?.data?.data?.content?.[0]?.providerId),
            providerName: data?.data?.data?.content?.[0]?.providerName ?? "-",
          },
        ]),
      );
    };

    if (queryParams.id) {
      // 수정 모드에서 실행
      fetchApi();
    }
  }, [queryParams.id]);

  const handleDeleteProvider = (id: string) => {
    const filteredDeleted = selectedProvider.filter((item) => item.providerId !== id);
    setValue("providerId", "", { shouldDirty: true });
    setSelectedProvider(filteredDeleted);
  };

  const onAddedProviderSelectModal = (provider: SelectedProvider[]) => {
    if (!provider) return;
    setSelectedProvider(provider);
    setValue("providerId", provider[0].providerId, { shouldDirty: true });
    setIsProviderSelectModalOpen({ isOpen: false });
  };

  //  유효성 확인 후 저장 진행
  const formValidation = () => {
    const requiredMessage = "필수입력 항목입니다";
    // 구분
    register(`type`, {
      required: { value: true, message: requiredMessage },
    });
    // 항목명
    register(`subject`, {
      required: { value: true, message: requiredMessage },
    });
    // providerId
    register(`providerId`, {
      required: { value: true, message: requiredMessage },
    });

    // +항목추가 / 주차 관련 안내 추가 일때 editor 유효성 검사
    if (guideType === "USER_GUIDE_PARKINGLOT" || guideType === "USER_GUIDE_ETC") {
      // 상세내용

      register("description", {
        validate: (value) => {
          return !value || value === " " ? requiredMessage : true;
        },
      });
    } else {
      // 네트워크 연결 유효성은 guideWifeList 에서 진행
      register(`description`, {
        validate: () => true,
      });
    }
  };

  formValidation();

  // validation 통과 후 submit 될때 실행
  const onSubmit = async (data: UserGuideModel, e?: any) => {
    const createdWifiList = compareCUDData(defaultValues?.wifiList as any, data.wifiList ?? []);

    const UserGuideModel: UserGuideModel = {
      //필수
      partnerId: partner?.id.toString(),
      providerId: data.providerId,
      subject: data.subject,
      type: data.type,
      description: data.description,
      cmdType: CmdType.C,
      wifiList: createdWifiList,
      location: data.location,
      isOpened: data.type === "USER_GUIDE_WIFI" ? data.isOpened : true,

      //수정일때
      ...(isEdit && { id: data.id, cmdType: CmdType.U }),
    };

    const resGuide = isEdit
      ? await editGuide(UserGuideModel).then((res) => res.data.data.content)
      : await postGuide(UserGuideModel).then((res) => res.data.data.content);
    const resGuideId = resGuide.id;

    if (requestMedias.length > 0) {
      await updateMedias(resGuideId);
    }

    if (resGuideId) {
      navigate(PagePath.guide.detail.replace(":id", resGuideId));

      isEdit ? openToast({ content: "정상적으로 수정되었습니다." }) : openToast({ content: "정상적으로 등록되었습니다." });
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);

    return false;
  }, []);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="contents-container__wrap-article">
              <BaseSectionTitle title={"이용안내 정보"} />
              <article className="contents-container__1200">
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p className="required">유형</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax200">
                      <Controller
                        control={control}
                        name={"type"}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseSelect
                            className="mr8"
                            stateOptions={guideTypeOptions.slice(1)}
                            setStateValue={(value: string) => {
                              onChange(value);
                            }}
                            value={value ?? ""}
                            name={name}
                            errorText={error?.message}
                            placeholder="선택해주세요"
                            isDisabled={queryParams.id ? true : false} // 수정페이지 수정불가
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>

                {guideType && (
                  <>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <div className="flex-center">
                          <p className="required">프로바이더</p>
                        </div>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="flex-row flex-center-start">
                          <BaseButton
                            type="button"
                            className="color-white size-medium"
                            title="선택"
                            disabled={queryParams.id ? true : false}
                            onClick={() => setIsProviderSelectModalOpen({ isOpen: true })}
                          />
                          <div className="flex-files ml10">
                            {selectedProvider?.map((item) => (
                              <div className="flex-files__wrap" key={item.providerId}>
                                <BaseNewTabLink
                                  path={`${PagePath.provider.detail.replace(":id", `${item.providerId}`)}`}
                                  value={item.providerName || "-"}
                                />
                                {!queryParams.id && <div className="delete-btn-x" onClick={() => handleDeleteProvider(item.providerId)}></div>}
                              </div>
                            ))}
                          </div>
                        </div>
                        {errors && errors.providerId && <p className="validation-text">{errors.providerId.message?.toString()}</p>}
                      </div>
                    </section>

                    {guideType === UserGuideTypes.USER_GUIDE_WIFI && (
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p className="required">적용 대상</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div className="">
                            <Controller
                              control={control}
                              name="isOpened"
                              render={({ field }) => {
                                return (
                                  <div className="flex-row flex-center-start pb18">
                                    {[
                                      { value: false, label: "일반 사용자용" },
                                      { value: true, label: "방문자용" },
                                    ].map((data, index) => (
                                      <BaseRadio
                                        key={index}
                                        id={data.label}
                                        name={"isOpened"}
                                        label={data.label}
                                        onChange={(e) => {
                                          if (e) {
                                            setValue("isOpened", data.value, { shouldValidate: true });
                                          }
                                        }}
                                        checked={field.value === data.value}
                                        className="mr16"
                                      />
                                    ))}
                                    {errors?.isOpened && <p className="validation-text">{errors?.isOpened?.message}</p>}
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </section>
                    )}

                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className="required">항목명</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="">
                          <Controller
                            control={control}
                            name="subject"
                            render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                              return (
                                <BaseInput
                                  placeholder="항목명은 최대 20자까지 입력 가능합니다."
                                  maxLength={20}
                                  type="text"
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  errorText={error?.message}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </div>
                    </section>

                    {/* +항목추가 / +주차 관련 안내 추가 일시 Editor form 노출 */}
                    {(guideType === UserGuideTypes.USER_GUIDE_ETC || guideType === UserGuideTypes.USER_GUIDE_PARKINGLOT) && (
                      <>
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p className="required">상세내용</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            {!getValues("description") && (
                              <div>
                                <Controller
                                  control={control}
                                  name="description"
                                  render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                                    return <MarkdownEditor value={value} onChange={onChange} errorText={error?.message} />;
                                  }}
                                ></Controller>
                              </div>
                            )}

                            {getValues("description") && (
                              <div>
                                <Controller
                                  control={control}
                                  name="description"
                                  render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                                    return <MarkdownEditor value={value} onChange={onChange} errorText={error?.message} />;
                                  }}
                                ></Controller>
                              </div>
                            )}
                          </div>
                        </section>

                        {guideType === UserGuideTypes.USER_GUIDE_ETC && (
                          <section className="contents-container__grid contents-container__1200">
                            <div className="contents-container__grid-index">
                              <p>첨부파일{mediaFiles && `(${mediaFiles.length}/10)`}</p>
                            </div>
                            <BaseFileUpload
                              limit={10}
                              maxSize={10}
                              fileList={mediaFiles}
                              setFiles={(files: any) => {
                                const newFiles = files.map((file: any) => ({ ...file, serviceType: ServiceTypes.SERVICE_USER_GUIDE }));
                                setMediaFiles(newFiles);
                              }}
                            />
                          </section>
                        )}
                      </>
                    )}

                    {/* +네트워크 연결 추가 section */}
                    {guideType === UserGuideTypes.USER_GUIDE_WIFI && <FreeInternetSection control={control} errors={errors} register={register} />}
                  </>
                )}
              </article>
            </div>
          </form>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>

        <div className="right-area">
          {queryParams.id && (
            <BaseButton
              title="취소"
              className="size-large color-white mr10"
              onClick={() => {
                navigate(PagePath.guide.detail.replace(":id", String(queryParams.id)));
              }}
            />
          )}

          <BaseButton
            title="저장"
            className="size-large"
            type="submit"
            onClick={async () => {
              const isRequiredCheck = await trigger();
              if (isRequiredCheck) {
                setBaseModal({
                  isOpen: true,
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                  onClick: () => {
                    handleSubmit(onSubmit, onError)();
                    setBaseModal({ isOpen: false });
                  },
                  title: "저장하시겠습니까?",
                });
              } else {
                console.log("error :>> ", errors);
              }
            }}
          />
        </div>
      </div>

      {isProviderSelectModalOpen.isOpen && (
        <ProviderSelectModal
          onCanceled={() => setIsProviderSelectModalOpen({ isOpen: false })}
          onAdded={onAddedProviderSelectModal}
          defaultChecked={selectedProvider ? selectedProvider : []}
        />
      )}
    </>
  );
};

export default BasicInfoFrom;
