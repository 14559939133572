import { useRef } from "react";
import { MediaFile } from "src/api/file/file-types";
import BaseFullModal from "src/components/BaseFullModal";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
/**
 * 워크오더 > 담당자 변경 풀모달
 */
type Props = {
  isOpen: boolean; // 모달 열림 여부
  imagesLIst: MediaFile[];
  setIsOpenImagesFullModal: Function;
};

const ImagesFullModal = ({ isOpen, setIsOpenImagesFullModal, imagesLIst }: Props) => {
  const s3URL = process.env.REACT_APP_S3_BASEURL;
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  SwiperCore.use([Navigation, Pagination]);

  return (
    <BaseFullModal isOpen={isOpen}>
      <div className="workorder-images-full-modal">
        <section className="floating-top">
          <p>{/* {index}/{imagesLIst.length} */}</p>
          <button
            className="top-close-btn"
            onClick={() => {
              setIsOpenImagesFullModal(false);
            }}
          ></button>
        </section>
        <div className="drag-slider" ref={containerRef}>
          <div className="drag-slider__wrap" ref={wrapRef}>
            <Swiper
              className="workorder-swiper"
              pagination={{
                type: "fraction",
              }}
            >
              {imagesLIst.map((image: MediaFile, idx: number) => (
                <SwiperSlide key={image.key}>
                  <img draggable="false" src={image.url ? image.url : s3URL + image.key!} alt={`워크시트 확대 이미지`}></img>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </BaseFullModal>
  );
};
export default ImagesFullModal;
