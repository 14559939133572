import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";
import { useBlockLayout, useRowSelect, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { Order, PageMeta } from "src/api/public-types";
import { BaseCheckbox, BaseInputWithSearch, BasePagination, BaseSelectState } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import TableRadioButton from "src/pages/product/components/TableRadioButton";

interface BaseSelectTableQueryParams {
  page: number;
  size: number;
  sort?: {
    orders: Array<Order>;
  };
  [key: string]: any;
}

// type Props<QueryParams extends DataObject, Data> = {

// };

const TableModal = <QueryParams extends BaseSelectTableQueryParams, Data>({
  pageMeta,
  columns,
  data = [],
  title,
  onClick,
  onClose,
  checkboxType,
  selectKey,
  defaultDisabledList,
  setParams,
  addSelects,
  searchInputProps,
}: {
  pageMeta?: PageMeta;
  columns: any;
  data: Data[];
  title?: string;
  onClick: (list: any[]) => void;
  onClose: () => void;
  checkboxType?: "checkbox" | "radio";
  selectKey: string;
  defaultDisabledList?: string[];
  setParams: Dispatch<SetStateAction<QueryParams>>;
  addSelects?: ReactNode;
  searchInputProps?: {
    placeholder?: string;
    onSearch?: (value: string, option: string) => void; // 검색에서 setParams를 무시합니다.
    selectOptions?: BaseSelectState["stateOptions"];
    onSearchOption?: (value: string) => void; // 검색에서 setParams를 무시합니다.
    selectWidth?: string;
  };
}) => {
  const [selectedItems, setSelectedItems] = useState<Array<any>>([]);
  const [keyword, setKeyword] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data as object[],
      disabledList: defaultDisabledList,
    },
    useBlockLayout,
    useSticky,
    useRowSelect,
    (hooks) => {
      checkboxType &&
        hooks.visibleColumns.push((columns: Array<any>) => [
          {
            width: 50,
            Header: "선택",
            accessor: "checked",
            Cell: ({ row, toggleAllRowsSelected, toggleRowSelected, disabledList }: any) => {
              let currentState = row.getToggleRowSelectedProps();
              return (
                <>
                  {checkboxType === "checkbox" ? (
                    <BaseCheckbox
                      id={row.id}
                      name={row.id}
                      checked={row.original.checked}
                      onChange={(checked: boolean) => {
                        setSelectedItems((prev) =>
                          checked
                            ? [...prev, row.original]
                            : prev.filter((item) => {
                                return item.memberNo ? item.memberNo !== row.original.memberNo : item.id !== row.original.id;
                              }),
                        );
                      }}
                      disabled={disabledList?.includes(String(row.original[selectKey]))}
                    />
                  ) : (
                    <TableRadioButton
                      id={row.id}
                      disabled={disabledList?.includes(String(row.original[selectKey]))}
                      onClick={() => {
                        toggleAllRowsSelected(false);
                        toggleRowSelected(row.id, true);
                        setSelectedItems([row.original]);
                      }}
                      name="selectedProvider"
                      {...currentState}
                    />
                  )}
                </>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  console.log("selectedOption", selectedOption);
  return (
    <BaseAbstractModal size="xlarge" isOpen={true}>
      <section className="base-abstract-modal__contents">
        <section className="base-abstract-modal__title flex-center-between ">
          <h1>{title ? title : "항목 선택"}</h1>

          <div className="right-area">
            <div className="d-flex">
              {addSelects && addSelects}
              {searchInputProps && (
                <BaseInputWithSearch
                  type="text"
                  selectValue={selectedOption}
                  inputValue={keyword}
                  onChange={(keyword: string) => setKeyword(keyword)}
                  onKeyUp={() => {
                    if (searchInputProps.onSearch) {
                      searchInputProps.onSearch(keyword, selectedOption);
                    } else {
                      setParams((prev) => ({ ...prev, page: 0, searchValue: keyword, searchType: selectedOption }));
                    }
                  }}
                  onSearchClick={() => {
                    if (searchInputProps.onSearch) {
                      searchInputProps.onSearch(keyword, selectedOption);
                    } else {
                      setParams((prev) => ({ ...prev, page: 0, searchValue: keyword, searchType: selectedOption }));
                    }
                  }}
                  stateOptions={searchInputProps.selectOptions || []}
                  setStateValue={(value: string) => {
                    setSelectedOption(value);
                    // setParams((prev) => ({ ...prev, searchType: value }));
                  }}
                />
              )}
            </div>
          </div>
        </section>

        <div {...getTableProps()} className="base-table view-data-table sticky px30">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {pageMeta ? (
        <section className="px30">
          <BasePagination
            pageIndex={pageMeta.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={pageMeta.pageRequest.size || 20}
            sizeOption={(size) => {
              setParams((prev) => ({ ...prev, size, page: 0 }));
              // currentSize && currentSize(sizeValue);
            }}
            totalElements={pageMeta?.totalElements || 0}
            goPage={(page: number) => {
              setParams((prev) => ({ ...prev, page }));

              // goToPage && goToPage(page);
            }}
            children={
              <div className="flex-center">
                <button
                  className="base-btn color-white mr10"
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  취소
                </button>
                <button
                  disabled={selectedItems.length > 0 ? false : true}
                  className="base-btn"
                  onClick={() => {
                    onClick(selectedItems);
                    onClose();
                  }}
                >
                  선택
                </button>
              </div>
            }
          />
        </section>
      ) : (
        <section className="pa30  flex-center-end w-100">
          <div className="flex-center">
            <button
              className="base-btn minmax80 color-white mr10"
              onClick={() => {
                onClose && onClose();
              }}
            >
              취소
            </button>
            <button
              disabled={selectedItems.length > 0 ? false : true}
              className="base-btn minmax80 "
              onClick={() => {
                onClick(selectedItems);
                onClose();
              }}
            >
              선택
            </button>
          </div>
        </section>
      )}
    </BaseAbstractModal>
  );
};

export default TableModal;
