import { ContractStep } from "src/api/contract/contract-types";

export const INVITEABLE_CONTRACT_STEP = [
  // ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.TERMINATE_RECEIVED,
];

// 방문타입
export const USER_STATUS_TYPE_MAP = {
  MEMBER_INVITE: "초대",
  MEMBER_USE: "사용",
  MEMBER_DELETE: "삭제",
  MEMBER_CLOSE: "이용종료",
  MEMBER_EXPIRE: "초대만료",
};
