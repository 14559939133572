import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";

/* 
  공지사항 > 상세
 */
const tabs = [{ value: "basicInfo", label: "기본정보" }];

const PriceDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  return (
    <div className="">
      <ContentsTitle
        title="공용공간 요금"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[{ title: "id", value: params.id ? params.id : "" }]}
      />
      {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
    </div>
  );
};
export default PriceDetail;
