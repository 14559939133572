import { MediaFile } from "src/api/public-types";
import { BaseRadio } from "./BaseRadio";
import { BaseTooltip } from "./BaseTooltip";
import { ResizedImage } from "./ResizedImage";

type Props = {
  images?: Array<MediaFile>; // 이미지 목록
  isUsedRepresentative?: boolean; // 대표 사용여부
  isUsedSelfDescription?: boolean; // 직적입력 설명 사용여부
  isUsedDescription?: boolean; // 설명 사용여부
  type?: string;
};

const s3Url = process.env.REACT_APP_S3_BASEURL;

/* 
  이미지 상세 공용 컴포넌트
*/
const ImagesDetail = ({ images, isUsedRepresentative, isUsedSelfDescription, isUsedDescription, type }: Props) => {
  if (!images || images.length === 0) return null;

  return (
    <div className="flex-row justify-contents-start align-items-center overflow-scroll">
      <div className="image-scroll">
        {images.map((image: MediaFile, idx: number) => {
          return (
            <div
              key={idx}
              className={`image-container detail`}
              onClick={() => {
                // 이미지 원본 링크 새창 열기
                if (image.url) {
                  window.open(image.url, "_blank");
                }
              }}
            >
              <div className="image-container__img-wrap">
                <span className="index-number">{image.orderNums}</span>
                {/* 이미지 */}
                <ResizedImage url={s3Url + (image?.key || "")} />
                {/* 대표 사용여부 true 일 경우에만 노출 */}
                {isUsedRepresentative && image.isPrimary && (
                  <BaseRadio
                    id={type ? `${type}${idx.toString()}` : idx.toString()}
                    name={type ? type : "president"}
                    className="chip-case"
                    checked={true}
                    label="대표"
                  />
                )}
              </div>
              {/* 직접설명 사용여부 true 일 경우에만 노출 */}
              {isUsedSelfDescription && image.category1 && (
                <div className="base-chip mt8 flex-center">
                  <p className="font11">{image.category1}</p>
                </div>
              )}
              {/* 설명 사용여부 true 일 경우에만 노출 */}
              {isUsedDescription && image.description && (
                <div className="desc-wrap">
                  <div className="font13 mt8">
                    <BaseTooltip contents={image.description} />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ImagesDetail;
