import { useEffect, useState } from "react";
import { Toast } from "src/recoil/toast/atom";

/**
 * 토스트 아이템
 */
function ToastItem({ id, type, content, duration, isSystemToast, isMobileToast }: Toast) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const setExistTimeout = setTimeout(() => {
      setIsClosing(true);
      clearTimeout(setExistTimeout);
    }, duration ?? 3000);
  }, []);

  if (isClosing) return null;

  return (
    <>
      {isSystemToast ? (
        <div className="base-system-toast">
          <div className="base-system-toast__contents">
            <p>{content}</p>
          </div>
        </div>
      ) : isMobileToast ? (
        <div className="base-mobile-toast">
          <div className="base-mobile-toast__contents">
            <p>{content}</p>
          </div>
        </div>
      ) : (
        <div className="base-toast">
          <button className="base-toast__close" onClick={() => setIsClosing(true)}></button>
          <div className="base-toast__contents">
            <span className={type === "FAIL" ? "text-red" : "text-green"}>{type === "FAIL" ? "Fail" : "Success"}</span>
            <p>{content}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default ToastItem;
