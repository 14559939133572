import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { deleteNoticeAsync, getNoticeDetailAsync, postAppPushNotificationAsync } from "src/api/notice/notice-api";
import { NoticeModel } from "src/api/notice/notice-types";
import { BaseButton, BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import MarkdownViewer from "src/components/MarkdownViewer";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat } from "src/utils";
import { Modal, noticeCategoryToText } from "../../notice-types";
import MetaTag from "src/components/layout/MetaTag";
import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

/* 
  공지사항 상세
 */
const BasicInfoDetail = () => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const location = useLocation();
  const { id } = useParams();
  // const { openToast } = useToast();
  const { isAuthority } = usePartnerAuthority();
  const [noticeDetail, setNoticeDetail] = useState<
    (NoticeModel & { contractSpaceProductNames: { name: string; id: string }[]; buildingNames: { name: string; id: string }[] }) | null
  >(null);
  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const { executeAsync: getNoticeDetail } = useApiOperation(getNoticeDetailAsync);
  const { executeAsync: deleteNoticeDetail } = useApiOperation(deleteNoticeAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  const { executeAsync: postAppNotification } = useApiOperation(postAppPushNotificationAsync, { noHandleError: true });

  useEffect(() => {
    const fetchApi = async (id: string) => {
      const { data } = await getNoticeDetail({ id });
      if (data.data) {
        let tempNoticeDetail = data.data.notice;
        let contractSpaceProductNames: { name: string; id: string }[] = [];
        let buildingNames: { name: string; id: string }[] = [];
        if (tempNoticeDetail.contracts && tempNoticeDetail.contracts.length > 0) {
          const contractList = tempNoticeDetail.contracts.map((item) => item.contractId);
          const { data: contractData } = await getContractList({ page: 0, size: 99, contractIds: contractList?.join(",") });
          contractSpaceProductNames = contractData.data.content.map((contract) => {
            return { name: contract.spaceProductName, id: String(contract.contractId) };
          });
        }
        if (tempNoticeDetail.buildings && tempNoticeDetail.buildings.length > 0) {
          const buildingList = tempNoticeDetail.buildings.map((item) => item.buildingId);
          const { data: buildingData } = await getBuildingList({ page: 0, size: 99, id: buildingList?.join(",") });
          buildingNames = buildingData.data.content.map((building) => {
            return { name: building.buildingName, id: String(building.id) };
          });
        }
        const newData = { ...tempNoticeDetail, contractSpaceProductNames, buildingNames };
        setNoticeDetail(newData);
      }
    };
    if (id) {
      fetchApi(id);
    }
  }, [id, getContractList, getBuildingList, getNoticeDetail]);

  // 공지사항 삭제
  const fetchDeleteNoticeDetail = async () => {
    if (id) {
      const { data } = await deleteNoticeDetail({ id });
      if (data.data) {
        openToast({ content: "정상적으로 삭제 되었습니다" });
        navigate(`${PagePath.notice.list}`);
      }
    }
    setConfirmModal({ isOpen: false });
  };

  const fetchPushAlarm = async () => {
    if (id) {
      setConfirmModal({ isOpen: false });
      const response = await postAppNotification({ id });
      if (response.status >= 200 && response.status <= 299) {
        setAlertModal({ isOpen: true, title: "Push 알림 발송이 완료되었습니다" });
      } else {
        if (response?.data?.meta?.errorCode === "eET104") {
          setAlertModal({ isOpen: true, title: "알림을 수신할 이용자가 없습니다" });
        } else {
          const errorCode = response?.data?.meta?.errorCode;
          setAlertModal({ isOpen: true, title: "잘못된 요청입니다", message: `[${errorCode}]` });
        }
      }
    }
  };

  const checkPushAlarmEnabled = () => {
    if (noticeDetail?.displayStartDate && noticeDetail?.displayEndDate) {
      const today = moment();
      const startDate = moment(noticeDetail.displayStartDate);
      const endDate = moment(noticeDetail.displayEndDate);

      // 오늘 날짜가 displayStartDate와 displayEndDate 사이에 있는지 확인
      const isTodayBetweenStartAndEnd = today.isBetween(startDate, endDate, null, "[]");

      if (noticeDetail?.isDisplayed && isTodayBetweenStartAndEnd) {
        return false;
      } else return true;
    }
  };
  return (
    <>
      <MetaTag subTitle={noticeDetail?.title || ""} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <ArticleSection title={"기본 정보"}>
            {/* 기본정보 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">신청/계약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="flex-files">
                    {noticeDetail?.contractSpaceProductNames && noticeDetail?.contractSpaceProductNames.length > 0 ? (
                      noticeDetail?.contractSpaceProductNames.map((contractSpaceProduct, index) => {
                        const detailPath = PagePath.contract.detail.replace(":id", contractSpaceProduct.id);
                        return (
                          <div className="flex-files__wrap" key={detailPath}>
                            <BaseNewTabLink path={detailPath} value={contractSpaceProduct.name} className="w-100 text-left" />
                            {index < noticeDetail?.contractSpaceProductNames.length - 1 && <span>,</span>}
                          </div>
                        );
                      })
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">건물</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="flex-files">
                    {noticeDetail?.buildingNames && noticeDetail?.buildingNames.length > 0 ? (
                      noticeDetail?.buildingNames.map((building, index) => {
                        const detailPath = PagePath.building.detail.replace(":id", building.id);
                        return (
                          <div className="flex-files__wrap">
                            <BaseNewTabLink path={detailPath} value={building.name} className="w-100 text-left" />
                            {index < noticeDetail?.buildingNames.length - 1 && <span>,</span>}
                          </div>
                        );
                      })
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">카테고리</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p className="font14">{noticeCategoryToText(noticeDetail?.noticeCategory || "-")}</p>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">제목</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p className="font14">{noticeDetail?.title || "-"}</p>
                </div>
              </div>
            </section>
            <section className="contents-container__grid contents-container__1200">
              <div className="contents-container__grid-index">
                <p className="pt4">내용</p>
              </div>
              <div className="contents-container__grid-contents">
                {noticeDetail?.content ? (
                  <MarkdownViewer value={noticeDetail.content} />
                ) : (
                  <div className="flex-row flex-center-start">
                    <p>-</p>
                  </div>
                )}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">공개여부</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="font14 pre-formatted">
                    {noticeDetail?.isDisplayed !== undefined ? (
                      noticeDetail?.isDisplayed ? (
                        <p className="chip green">공개</p>
                      ) : (
                        <p className="chip red">비공개</p>
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">공개기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p className="font14 pre-formatted">
                    {noticeDetail?.displayStartDate && moment(noticeDetail?.displayStartDate).format(ViewYmdFormat.FULL)}
                    <span className={noticeDetail?.displayStartDate && noticeDetail?.displayEndDate ? "px4" : ""}>-</span>
                    {noticeDetail?.displayEndDate && moment(noticeDetail?.displayEndDate).format(ViewYmdFormat.FULL)}
                  </p>
                </div>
              </div>
            </section>
            {isAuthority("w") && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">Push 알림</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <BaseButton
                      disabled={checkPushAlarmEnabled()}
                      title={"발송하기"}
                      className="color-white"
                      onClick={() => setConfirmModal({ isOpen: true, message: "알림을 발송할까요?", type: "ALARM" })}
                    />
                  </div>
                </div>
              </section>
            )}
          </ArticleSection>
          <ArticleSection title={"등록 정보"}>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">생성일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">
                      {noticeDetail?.createdDate ? moment(noticeDetail?.createdDate).format(ViewYmdFormat.FULL) : "-"}
                    </p>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">최초 등록자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">{noticeDetail?.createdBy ? noticeDetail?.createdBy : "-"}</p>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">최종 수정일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">
                      {noticeDetail?.lastModifiedDate ? moment(noticeDetail?.lastModifiedDate).format(ViewYmdFormat.FULL) : "-"}
                    </p>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">최종 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">{noticeDetail?.lastModifiedBy || "-"}</p>
                  </div>
                </div>
              </section>
            </div>
          </ArticleSection>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle={confirmModal.type === "ALARM" ? "아니오" : "취소"}
            btnRightTitle={confirmModal.type === "ALARM" ? "네" : "확인"}
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              confirmModal.type === "DELETE" && fetchDeleteNoticeDetail();
              confirmModal.type === "ALARM" && fetchPushAlarm();
            }}
            title={confirmModal.message}
          >
            {confirmModal.type === "ALARM" && <p>Taap을 통해 신규 공지사항 알림이 발송됩니다.</p>}
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal title={alertModal.title} isOpen={alertModal.isOpen} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
            {alertModal.message && alertModal.message}
          </BaseModal>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="left-area d-flex">
          {isAuthority("d") && (
            <BaseButton
              title="삭제"
              className="color-white size-large mr10"
              onClick={() => {
                setConfirmModal({ isOpen: true, message: "삭제 하시겠습니까?", type: "DELETE" });
              }}
            />
          )}
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.notice.form}?id=${id}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;

//여기
