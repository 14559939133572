import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";
import { errorModalState } from "src/recoil/errorModal/atom";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { BaseModal } from ".";
import { useRedirectMain } from "src/hooks/useRedirectMain";
const HTTP_STATUS_MAP = {
  400: "잘못된 요청입니다",
  401: "계정 인증이 만료되었습니다",
  403: "권한이 없습니다",
  404: "존재하지 않는 리소스입니다",
  "4xx": "요청 오류입니다",
  "5xx": "오류가 발생하였습니다",
};

export const getStatusMessage = (errorCode: number) => {
  switch (errorCode) {
    case 400:
      return HTTP_STATUS_MAP[400];
    case 401:
      return HTTP_STATUS_MAP[401];
    case 403:
      return HTTP_STATUS_MAP[403];
    case 404:
      return HTTP_STATUS_MAP[404];
    default:
      if (errorCode >= 400 && errorCode < 500) {
        return HTTP_STATUS_MAP["4xx"];
      }
      if (errorCode >= 500 && errorCode < 600) {
        return HTTP_STATUS_MAP["5xx"];
      }
      return "알 수 없는 에러가 발생했습니다";
  }
};

/**
 * recoil errorModalState 를 사용하는
 * api 에러발생시 띄우는 에러 모달 컴포넌트
 */
const ErrorModal = () => {
  const errorModals = useRecoilValue(errorModalState);
  const { closeAllErrorModal } = useErrorModal();
  const { logout } = useAuth0();
  const { setLocalstorageUrl } = useRedirectMain();
  // if (errorModals.length > 0) {
  const firstErrorModal = errorModals[0];
  return (
    <BaseModal
      isOpen={firstErrorModal.isOpen}
      btnRightTitle={"확인"}
      onClick={() => {
        if (firstErrorModal.statusCode === Number(401)) {
          closeAllErrorModal();
          setLocalstorageUrl();
          logout({ logoutParams: { returnTo: window.location.origin } });
        } else {
          closeAllErrorModal(); // 모두 닫기
        }
      }}
    >
      <div style={{ wordBreak: "break-all" }}>
        <p className="font18 font-weight-semibold text-gray900">{getStatusMessage(firstErrorModal.statusCode || 0)}</p>
        {firstErrorModal.errorMessage && <div className="font14 text-primary3 mt15 pre-formatted keep-all">{firstErrorModal.errorMessage} </div>}

        {/* 에러 리스트 전달하면 불릿으로 표시 */}
        {firstErrorModal.errorList && (
          <div className="w-100 text-left py10 px15 mt20 overflow-scroll" style={{ backgroundColor: "#F2F4F6", maxHeight: 250 }}>
            <ul style={{ listStyle: "none" }}>
              {firstErrorModal.errorList.map((item, idx) => (
                <li key={idx} className="mb5">
                  • {item}
                </li>
              ))}
            </ul>
            {firstErrorModal.errorSubList && (
              <ul className="ml10" style={{ listStyle: "none" }}>
                {firstErrorModal.errorSubList.map((item, idx) => (
                  <li key={idx} className="mb5">
                    • {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {firstErrorModal.errorCode && <p className="font14 text-primary3 mt15">[{firstErrorModal.errorCode}]</p>}
        {firstErrorModal.statusCode === Number(401) && <p className="font14 text-primary3 mt15">로그인 화면으로 이동합니다</p>}
      </div>
    </BaseModal>
  );
  // }

  // return null;
};

export default ErrorModal;
