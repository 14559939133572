import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { DetailTotalData } from "src/api/contract/contract-types";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import RegistrationInformation from "src/components/RegistrationInformation";
import ContractBuildingRooms from "src/pages/contract/commonComponents/ContractBuilngRooms";
import ContractorInfo from "src/pages/contract/commonComponents/ContractorInfo";
import ContractProductInfo from "src/pages/contract/commonComponents/ContractProductInfo";
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}
const BasicInfo = ({ detailData }: ContractBasicProp) => {
  const { contract } = detailData;
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {/* <ContractBasicInfo detailData={detailData!} isAuthorityReadonly={isAuthorityReadonly} /> */}
          <ContractProductInfo detailData={detailData!} />
          <ContractorInfo detailData={detailData!} detailFlag={true} />
          <ContractBuildingRooms detailData={detailData!} />
          <RegistrationInformation data={contract} />

          <AdminMemoV3 serviceId={Number(detailData?.contract?.contractId)} serviceType={ServiceTypes.SERVICE_CONTRACT} />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default BasicInfo;
