import { useAuth0 } from "@auth0/auth0-react";
import { AxiosInstance, AxiosResponse } from "axios";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { uploadFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { SaveMediaDto } from "src/api/organization/org-types";
import { ApiResponse, Modal } from "src/api/public-types";
import onErrorImage from "src/assets/images/onerror_preview.svg";
import { BaseButton, BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useModal } from "src/recoil/modalState/hook";

interface DragDropState {
  draggedFrom: number | null;
  draggedTo: number | null;
  isDragging: boolean;
  originalOrder: SaveMediaDto[];
  updatedOrder: SaveMediaDto[];
}
interface ImagesUpdateProps {
  isOnlyView?: boolean; // detail일 경우 true
  urlKey?: string; // url 로 사용하는 key 명칭. 없으면 기본 'key'
  images?: SaveMediaDto[]; // 부모에 저장된 image 전달
  setImages?: React.Dispatch<React.SetStateAction<SaveMediaDto[]>>; // 부모에서 이미지 저장할 setState
  className?: string;
  categoryName?: string; // 여러 ImageUpdate 컴포넌트 사용시 대표 radio 구분
  fileMaxLength?: number; // 이미지 최대 저장 length
  accept?: string; // 파일 확장자 범위, 컴마로 구분 ex).png,.jpg
  type: "LOGO" | "PROOF";
  serviceTag?: string;
  disabled?: boolean;
}

/**
 * v1.11 개선사항 적용 (24.3.8.)
 */
const s3Url = process.env.REACT_APP_S3_BASEURL;

export async function getFileDownload(axios: AxiosInstance, param: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const response = await axios.get<ApiResponse<any>>(param?.url, { responseType: "blob" });
  return response;
}

const ContractImageUpload = memo(({ setImages, images, serviceTag, type, fileMaxLength = 10, isOnlyView, accept, disabled }: ImagesUpdateProps) => {
  const [previewImage, setPreviewImage] = useState<SaveMediaDto[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);

  const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync);
  const [modalOpen, setModalOpen] = useState<Modal>({ isOpen: false });
  const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1); // pdf 개별 페이지
  const [numPages, setNumPages] = useState<number>(0);
  const [focusFullScreenImageIndex, setFocusFullScreenImageIndex] = useState(0);
  const { executeAsync: getFile } = useApiOperation(getFileDownload, { noAuthenticationRequired: true, noHandleError: true });
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const { setBaseModal } = useModal();

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const maxSize = 10;
  const fileValidationText = `한 파일당 업로드 용량은 ${maxSize}MB, 최대 ${fileMaxLength}개까지만 가능합니다.`;

  // 드래그로 변경될 내용들을 담아줄 객체
  const [dragAndDrop, setDragAndDrop] = useState<DragDropState>({
    draggedFrom: null, //드래그가 시작하는 index
    draggedTo: null, // 변경될 드래그 index
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  });
  const [token, setToken] = useState("");

  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getAccessTokenSilently, getIdTokenClaims, getToken, token]);

  useEffect(() => {
    if (images && Array.isArray(images)) {
      setPreviewImage(images!);
    }
  }, [images, setPreviewImage]);

  const isFileValidatiaon = (fileArray: FileList) => {
    const sizeLimit = 10 * 1024 * 1024;
    let validationStatus = true; // false일 경우 validation에 걸림
    if (fileArray) {
      // 파일의 사이즈 체크
      for (let i = 0; i < fileArray.length; i++) {
        if (fileArray[i].size > sizeLimit) {
          // ====================
          setBaseModal({
            isOpen: true,
            title: "첨부파일 업로드가 실패했습니다.",
            children: <p>{fileValidationText}</p>,
            btnRightTitle: "확인",
            onClick: () => setBaseModal({ isOpen: false }),
          });
          // =======================
          // setModalOpen({ isOpen: true, message: fileValidationText });
          validationStatus = false;
          break;
        }
      }
      // 파일 갯수 체크
      const inputCount = fileArray.length;
      const sum = inputCount + previewImage.length;
      if (fileMaxLength && fileMaxLength < sum) {
        setModalOpen({ isOpen: true, message: fileValidationText });
        validationStatus = false;
      }
    }

    return validationStatus;
  };
  // 이미지 업로드시 s3 저장
  const s3ImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileArray = event.target.files!;
      if (!isFileValidatiaon(fileArray)) return;

      const inputCount = fileArray.length;
      let s3Array: Array<any> = [];

      if (fileArray.length > 0) {
        for (let i = 0; i < inputCount; i++) {
          let file = fileArray[i];
          // 이미지 s3 저장소로 자동저장
          const formData = new FormData();

          formData.append("file", file);

          const res = await postImageFile({ file: formData, type: type === "PROOF" ? "private" : "public" });

          if (res && res.status >= 200 && res.status <= 299) {
            const data = JSON.parse(JSON.stringify({ ...res.data.data.media, ...{ type, serviceTag, isDeleted: false } }));

            const readAsDataURL = (file: Blob) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
              });
            };

            try {
              const blobUrl = await readAsDataURL(file);
              s3Array.push({ ...data, blobUrl });
              // s3Array.unshift(blobUrl);
            } catch (error) {
              console.error("Error reading file:", error);
            }
          }

          const newArra: any = [...s3Array, ...images!];

          if (setImages) setImages(newArra);
          setPreviewImage(newArra);
        }
      }
      event.target.value = "";
    },
    [previewImage, fileMaxLength, postImageFile, images, setImages],
  );

  // 이미지 삭제
  const onImageClear = (passFile: any, e: any) => {
    e.stopPropagation();
    let newArr = [];

    if (!passFile.id) {
      newArr = previewImage.filter((item: any) => item.key !== passFile.key);
    } else {
      newArr = previewImage.map((file: any) => {
        if (file.id === passFile.id) {
          file = { ...file, isRemoved: true, isDeleted: true, cmdType: "D" };
        }
        return file;
      });
    }
    setPreviewImage(newArr);
    if (setImages) setImages(newArr);
  };

  // 드래그하려고 시작할 때
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.opacity = "0.4";
    const initialPosition = Number(event.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      originalOrder: previewImage,
    });
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom; // 드래그 되는 항목의 인덱스(시작)
    const draggedTo = Number(event.currentTarget.dataset.position); // 놓을 수 있는 영역의 인덱스(끝)
    const itemDragged = newList[draggedFrom || 0]; //

    const remainingItems = newList.filter((item, idx) => idx !== draggedFrom); //draggedFrom(시작) 항목 제외한 배열 목록

    newList = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)];
    // 놓을 수 있는 영역이 변경 되면 객체를 변경해줌
    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };

  // onDrop 잡은 Item을 적절한 곳에 놓았을 때 발생하는 이벤트 (다른 item이랑 겹쳐졌을 때)
  const onDrop = () => {
    setPreviewImage(dragAndDrop.updatedOrder);
    if (setImages) setImages(dragAndDrop.updatedOrder);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
    });
  };

  // onDragLeave 범위를 벗어나면 발생하는 이벤트
  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove("over");
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  // 다른 item이랑 겹쳐졌을 때 발생하는 이벤트 (1번만)
  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.add("over");
  };

  // 잡은 Item을 놓았을 때 발생하는 이벤트 (다른 item이랑 겹치지 않아도 발생함)
  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.opacity = "1";

    const array = [...previewImage];

    const listItems = document.querySelectorAll(".draggable");
    listItems.forEach((item) => {
      item.classList.remove("over");
    });

    array.map((t, i) => (t.id = i));
    setPreviewImage(array);
  };

  // 풀스크린 이미지 열기
  const handleOpenFullImage = (idx: number) => {
    setFocusFullScreenImageIndex(idx);
    setIsFullScreenImageOpen((prev) => !prev);
  };
  // 풀스크린 좌우 버튼 핸들러
  const handleFullScreenImage = (position: "left" | "right") => {
    if (position === "left") {
      setFocusFullScreenImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
    }
    if (position === "right") {
      setFocusFullScreenImageIndex((prev) => (prev < previewImage.length - 1 ? prev + 1 : prev));
    }
  };

  const findImageURL = (file: any) => {
    if (file.blobUrl) {
      return file.blobUrl;
    } else if (file.type === "LOGO") {
      return file.url;
    } else if (file.type === "PROOF") {
      return file.url + "?access_token=" + token;
    } else if (!file.type && file.url) {
      return file.url + "?access_token=" + token;
    } else if (file.key?.includes("http")) {
      return file.key;
    } else if (!file.key?.includes("http") && file.key) {
      return s3Url + file?.key!;
    } else return "";
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePdfLeft = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const handlePdfRight = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const renderFileView = (file: any) => {
    const fileUrlWithToken = file.blobUrl
      ? file.blobUrl
      : file.url
      ? file.url + "?access_token=" + token
      : s3Url + file.key + "?access_token=" + token;
    const fileName = file.filename;
    const fileExtension = file.filename.slice(file.filename.lastIndexOf(".") + 1);
    const availableFileExtensionArray = ["jpg", "jpeg", "gif", "png", "svg", "bmp"];

    // 이미지
    if (availableFileExtensionArray?.includes(fileExtension.toLowerCase())) {
      return (
        <>
          <img
            src={findImageURL(file)}
            alt={fileName || "이미지를 불러오는데 실패했습니다."}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = onErrorImage;
            }}
          />
          <p className="file-name">{fileName}</p>
        </>
      );
    }

    // 이미지가 아닐경우
    switch (fileExtension) {
      case "pdf":
        return (
          <div className="file-viewer-pdf">
            <Document className="file-viewer-pdf__document" file={fileUrlWithToken ? fileUrlWithToken : ""} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} renderAnnotationLayer={false} loading={"loading..."} onLoadError={console.error} />
            </Document>
            <div className="button-wrap">
              <button className="left" disabled={pageNumber === 1} onClick={handlePdfLeft}></button>
              <button className="right" disabled={pageNumber === numPages} onClick={handlePdfRight}></button>
            </div>
          </div>
        );
      default:
        return (
          <div className="no-preview-available">
            <p>미리보기를 제공하지 않는 파일 형식입니다.</p>
            <p>다운로드 후 확인해 주세요.</p>
            <p className="file-name">{fileName}</p>
          </div>
        );
    }
  };

  useEffect(() => {
    const escapeKeyDownHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Enter") {
        e.preventDefault();
        setIsFullScreenImageOpen(false);
      }
    };

    window.addEventListener("keydown", escapeKeyDownHandler);

    return () => {
      window.removeEventListener("keydown", escapeKeyDownHandler);
    };
  }, []);

  // response의 headers에 있는 content-disposition을 찾아서 디코딩 한다.
  const forceDownload = useCallback(
    async (url: string, filename: string) => {
      const gf: any = await getFile({ url });
      const blob = new Blob([gf.data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.style.display = "none";

      link.download = filename;

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(fileUrl);
    },
    [getFile],
  );

  const sliceTitle = (fileName: string) => {
    const dotIndex = fileName.lastIndexOf(".");
    const title = fileName.slice(0, dotIndex);
    const extension = fileName.slice(dotIndex + 1);
    return { title, extension };
  };

  return (
    <div className="base-image-wrap minmax380">
      {!isOnlyView && (
        <>
          <input
            ref={fileRef}
            type="file"
            style={{ display: "none" }}
            accept={accept ? accept : "*"}
            onChange={s3ImageUpload}
            // onChange={onSaveImages}
            multiple
          />
          <BaseButton
            title={"파일찾기"}
            className="color-white"
            disabled={(fileMaxLength && previewImage.length >= fileMaxLength ? true : false) || disabled === true}
            onClick={() => fileRef.current?.click()}
          />
          {previewImage && previewImage.length === 0 && (
            <ul className="base-list mt10">
              <li>문서를 카메라로 찍거나 스캔하여 업로드해주세요.</li>
              <li>업로드 용량은 개당 10MB까지 가능합니다.</li>
            </ul>
          )}
        </>
      )}
      {previewImage && previewImage.length > 0 ? (
        <div className="base-image ">
          {previewImage.map((image, idx) => {
            return (
              <div
                className={`base-image__wrap ${isOnlyView ? "detail" : "form"}`}
                key={idx}
                draggable={true}
                data-position={idx}
                // onDragStart={(e) => !isOnlyView && onDragStart(e)}
                // onDragOver={(e) => !isOnlyView && onDragOver(e)}
                // onDragLeave={(e) => !isOnlyView && onDragLeave(e)}
                // onDrop={(e) => !isOnlyView && onDrop()}
                // onDragEnter={(e) => !isOnlyView && onDragEnter(e)}
                // onDragEnd={(e) => !isOnlyView && onDragEnd(e)}
                onClick={() => handleOpenFullImage(idx)}
              >
                <img
                  src={findImageURL(image)}
                  alt="preview-images"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = onErrorImage;
                  }}
                />
                {!isOnlyView && <button type="button" onClick={(e) => onImageClear(image, e)} />}

                <div className="title-area">
                  <div className="not-hover">
                    <p>{sliceTitle(image.filename).title}</p>
                    <span>.{sliceTitle(image.filename).extension}</span>
                  </div>
                  <div className="active-hover">
                    <p>{sliceTitle(image.filename).title}</p>
                    <span>.{sliceTitle(image.filename).extension}</span>
                    {isOnlyView && (
                      <button
                        type="button"
                        className="detail-download"
                        onClick={(e) => {
                          e.stopPropagation();
                          forceDownload(image.url! + "?access_token=" + token, image.filename);
                        }}
                      ></button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {isFullScreenImageOpen && (
            <BaseAbstractModal isNotUseWhiteBoard isOpen={true} opacity={0.75}>
              <div className="base-image__full-screen">
                {renderFileView(previewImage[focusFullScreenImageIndex])}
                <div className="img-button-wrap">
                  <div className="flicker">
                    <button className="left" onClick={() => handleFullScreenImage("left")}></button>
                    <p>
                      {focusFullScreenImageIndex + 1} / {previewImage.length}
                    </p>
                    <button className="right" onClick={() => handleFullScreenImage("right")}></button>
                  </div>
                  <a
                    target="_self"
                    className="download"
                    href={" "}
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      forceDownload(
                        previewImage[focusFullScreenImageIndex].url + "?access_token=" + token + "&forceDownload",
                        previewImage[focusFullScreenImageIndex].filename,
                      );
                    }}
                  >
                    {""}
                  </a>
                  <button className="close" onClick={() => setIsFullScreenImageOpen(false)}>
                    닫기
                  </button>
                </div>
              </div>
            </BaseAbstractModal>
          )}

          {modalOpen.isOpen && (
            <BaseModal isOpen={true} title={`첨부파일 업로드가 실패했습니다.`} btnRightTitle="확인" onClick={() => setModalOpen({ isOpen: false })}>
              <p>{modalOpen.message}</p>
            </BaseModal>
          )}
        </div>
      ) : (
        isOnlyView && <p>-</p>
      )}
    </div>
  );
});

export default React.memo(ContractImageUpload);
