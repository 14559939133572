import { useAuth0 } from "@auth0/auth0-react";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, Modal } from "src/api/public-types";
import { editQuestionStatusAsync, getQuestionDetailAsync } from "src/api/question/qeustion-api";
import { QuestionDetailModel, QuestionStatus, QuestionStatusUnionType } from "src/api/question/question-type";
import { ContentsTitle } from "src/components";
import { BaseModal } from "src/components/BaseModal";
import MetaTag from "src/components/layout/MetaTag";
import { getFilteredStatusOptionsAndIsDisableSelectButton } from "../libs";
import { statusOptions } from "../question-types";
import BasicInfoForm from "./BasicInfoForm";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  // { value: ADMIN_MEMO, label: "관리자 메모" },
];

const QuestionForm = () => {
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 상담관리 상세
  const [questionDetail, setQuestionDetail] = useState<QuestionDetailModel>();

  // 상담관리 미디어 파일
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([]);

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  const [token, setToken] = useState("");
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  // 상담관리 상세
  const { executeAsync: getQuestionDetail } = useApiOperation(getQuestionDetailAsync, {});

  // 상담관리 상태 변경
  const { executeAsync: editQuestionStatus } = useApiOperation(editQuestionStatusAsync);

  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync);

  // 상태변경 select options
  const filteredStatus = useMemo(() => {
    const _statusOptions = statusOptions.filter((item, idx) => idx !== 0);

    let newStatusOptions: any = [];

    switch (questionDetail?.status) {
      case QuestionStatus.COMPLETE: {
        newStatusOptions = _statusOptions.filter((status) => status.value !== QuestionStatus.HOLD);
        break;
      }
      case QuestionStatus.HOLD: {
        newStatusOptions = _statusOptions.filter((status) => status.value !== QuestionStatus.COMPLETE);
        break;
      }
      default: {
        return _statusOptions;
      }
    }
    return newStatusOptions;
  }, [questionDetail?.status]);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: { serviceId: number; serviceType: UnionServiceType }) => {
      const contentsList = [{ serviceId: data.serviceId, serviceType: data?.serviceType }];

      const response = await getMediaFileList({ contentsList });
      if (response.status >= 200 && response.status <= 299) {
        setMediaFiles(response.data.data.content);
      }
    },
    [getMediaFileList],
  );

  // 상담관리 상세

  const fetchQuestionDetail = useCallback(
    async (id: number) => {
      const { data } = await getQuestionDetail({ id });
      const result: QuestionDetailModel = data.data.content;
      if (result) {
        setQuestionDetail(result);
        // setAnswerList(result.answerList || []);
        // 미디어 파일
        const mediaData: { serviceId: number; serviceType: UnionServiceType } = {
          serviceId: Number(result?.id),
          // mediaServiceType: "MEDIA_QUESTION",
          serviceType: "SERVICE_QUESTION",
        };
        fetchMediaList(mediaData);
      }
    },
    [fetchMediaList, getQuestionDetail],
  );

  // 토근 가져오기
  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  // 상세
  useEffect(() => {
    if (queryParams.id) {
      fetchQuestionDetail(Number(queryParams.id));
      getToken();
    }
  }, [queryParams]);

  // 상담관리 상태변경
  const onChangeQuestionStatus = async (id: number, status: QuestionStatusUnionType) => {
    console.log("status :>> ", status);
    console.log("questionDetail?.site :>> ", questionDetail?.site);
    const answerList = questionDetail?.answerList;
    if (questionDetail?.site === "SITE_TAAP" && questionDetail.status === "QUESTION_CANCELED") {
      setAlertModal({ isOpen: true, title: "taap 인입건이 취소상태인 경우 다른 상태로 변경할 수 없습니다" });
      return;
    }

    if (questionDetail?.site === "SITE_TAAP" && status === "QUESTION_CANCELED") {
      setAlertModal({ isOpen: true, title: "taap 인입건의 경우 취소 상태로 수동으로 변경할 수 없으며, 회원에 의해 변경할 수 있습니다" });
      return;
    }

    if (!answerList && status === QuestionStatus.COMPLETE) {
      setAlertModal({ isOpen: true, title: "등록된 답변이 없습니다." });
      return;
    }

    const response = await editQuestionStatus({ id, status });
    if (response.status >= 200 && response.status <= 299) {
      console.log(`respons`, response.data.data);
      await fetchQuestionDetail(Number(queryParams.id));
      setConfirmModal({ isOpen: false });
    }
  };

  return (
    <div>
      <MetaTag subTitle={questionDetail?.id} isCreatePage={questionDetail?.id === undefined} />

      <ContentsTitle
        title="상담관리"
        tabs={tabs}
        activeTab={activeTab}
        detailInfo={(queryParams.id && [{ title: "id", value: String(queryParams.id) || "" }]) || []}
        selectDisabled={getFilteredStatusOptionsAndIsDisableSelectButton(questionDetail).isDisableSelectButton} // taap 유입은 수동으로 상태 변경 불가
        selectOptions={getFilteredStatusOptionsAndIsDisableSelectButton(questionDetail).filteredStatusOptions}
        selectValue={questionDetail?.status}
        setSelectStateValue={(status: QuestionStatusUnionType) => {
          setConfirmModal({ isOpen: true, title: "변경하시겠습니까?", payload: status });
        }}
      />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab?.value === "basicInfo" && ( //
        <BasicInfoForm questionDetail={questionDetail} mediaFiles={mediaFiles} />
      )}
      {/* {activeTab?.value === ADMIN_MEMO && (
        <AdminMemoIntegration serviceId={Number(queryParams.id)} serviceType={ServiceTypes.SERVICE_QUESTION} viewOnly={false} />
      )} */}
      <>
        {modal.isOpen && (
          <BaseModal isOpen={true} btnRightTitle={"확인"} onClick={() => setModal({ ...modal, isOpen: false })}>
            <p className="font18  text-gray900">{modal.message}</p>
          </BaseModal>
        )}
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              onChangeQuestionStatus(Number(queryParams.id), confirmModal.payload as QuestionStatusUnionType);
              setConfirmModal({ isOpen: false });
            }}
            title={confirmModal.title}
          ></BaseModal>
        )}
        {alertModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            onClick={() => {
              setAlertModal({ isOpen: false });
            }}
            title={alertModal.title}
          ></BaseModal>
        )}
      </>
    </div>
  );
};

export default QuestionForm;
