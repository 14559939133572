import { useAuth0 } from "@auth0/auth0-react";
import { objectKeys } from "@toss/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, UnionCmdType } from "src/api/public-types";
import { editQuestionAsync, getConnectedInfoListAsync, postConnectedInfoListAsync, postQuestionAsync } from "src/api/question/qeustion-api";
import {
  ConnectedType,
  CsCategoryTypeUnionType,
  QuestionConnectedInfoListRequest,
  QuestionDetailModel,
  QuestionSite,
  QuestionSiteUnionType,
} from "src/api/question/question-type";
import { BaseButton, BaseCheckbox, BaseInput, BaseRadio, BaseSelect, BaseTextarea } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { BaseTooltip } from "src/components";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import SelectBuildingPopup from "src/components/selectPopup/SelectBuildingPopup";
import SelectContractPopup from "src/components/selectPopup/SelectContractPopup";
import SelectCsServiceItemPopup from "src/components/selectPopup/SelectCsServiceItemPopup";
import SelectQuestionManagerPopup from "src/components/selectPopup/SelectQuestionManagerPopup";
import SelectWorkOrderPopup from "src/components/selectPopup/SelectWorkOrderPopup";
import useMediasUpdate from "src/hooks/useMediasUpdate";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import { PagePath } from "src/pages/product/details";
import { useCsOptions } from "src/pages/voc/hooks/useCsOptions";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { formatPhoneNumber, generateNumberArrayFromAtoB, onlyNumber, parsedPhoneNumber } from "src/utils";
import { MIN_TEXTAREA_HEIGHT, answerGubunOptions, csCategoryOptions, siteOptions } from "../question-types";
import { isNumber } from "lodash";

type Props = {
  questionDetail?: QuestionDetailModel;
  mediaFiles?: MediaFile[];
};

/**상담관리 등록 타입 */
export interface QuestionForm {
  id?: string;
  csCategoryType: "VoC" | "문의"; // 구분: VoC | 문의
  site: QuestionSiteUnionType; // 유입경로: Y | N
  siteSummary?: string; // 세부유입경로 (선택 사항)
  buildingId?: string; // 건물ID (선택 사항, csCategoryType이 VOC 인 경우 필수)
  buildingName?: string;
  floorNum?: number; // 건물-층 (선택 사항)
  csTypeId: string; // VoC유형ID
  csTypeName: string; // VoC유형명
  csItemId?: string; // VoC항목ID (선택 사항)
  summary: string; // 제목 (VoC항목명을 넣어주세요)
  contentType: "text/plain" | "text/html"; // 문의내용 MIME Type (text/plain 또는 text/html)
  memberNo?: string; // 회원번호 (선택 사항)
  reporterName: string; // 접수자 이름
  reporterPhone: string; // 접수자 연락처 (+821012345678 형식)
  reporterEmail: string; // 접수자 이메일
  description: string; // 문의내용
  isSendAlarm: boolean; // 알림톡발송여부
  assigneeEmail: string; // 담당자 Email
  cost?: number; // 매출 (선택 사항)
  referenceEmail?: string; // 참조자 Email (콤마로 구분, 선택 사항)
  expense?: number; // 지출 (선택 사항)
  answerGubun?: "1" | "2" | "3"; // 답변방식: 1 (이메일), 2 (유선), 3 (답변 제외, 기록용)
  workOrder?: { id: string; connectedInfoId?: string }[];
  contract?: { id: string; connectedInfoId?: string }[];
}

const BasicInfoForm = ({ questionDetail, mediaFiles }: Props) => {
  const navigate = useNavigate();
  const { setBaseModal, setAbstractModalZ1 } = useModal();
  const { queryParams } = useQueryParams();
  const [floorNumOption, setFloorNumOption] = useState<number[]>([]);
  // 상담관리 ID
  const questionId = useMemo(() => {
    return queryParams?.id ? String(queryParams.id) : undefined;
  }, [queryParams.id]);

  // Taap 유입건인지 확인
  const isFromTaap = questionDetail?.site === QuestionSite.TAAP;
  const isFromTaapSpace = questionDetail?.site === QuestionSite.SITE_TAAP_SPACE;
  const isFromOutside = isFromTaap || isFromTaapSpace;

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    register,
    getValues,
    reset,
    trigger,
    formState: { errors, defaultValues, isDirty, dirtyFields },
  } = useForm<QuestionForm>({
    defaultValues: {
      contentType: "text/plain",
      isSendAlarm: false,
      answerGubun: "1",
    },
  });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  // 첨부파일
  const [mediasData, setMediasData] = useState<MediaFile[]>([]);
  const { openToast } = useToast();

  // 공통 미디어 파일 커스텀 훅
  const { requestMedias, updateMedias } = useMediasUpdate({ mediaList: mediasData });
  const { user } = useAuth0();

  // ================ API ==============
  // voc 유형
  const { options: csTypeOptions } = useCsOptions(watch("csCategoryType") as CsCategoryTypeUnionType);
  // 상담관리 등록
  const { executeAsync: postQuestion } = useApiOperation(postQuestionAsync);
  // 상담관리 수정
  const { executeAsync: editQuestion } = useApiOperation(editQuestionAsync);
  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);
  // 상담관리 연결정보 등록/삭제
  const { executeAsync: postConnectedInfoList } = useApiOperation(postConnectedInfoListAsync, { noHandleError: true });
  // 상담관리 연결정보 조회
  const { executeAsync: getConnectedInfoList } = useApiOperation(getConnectedInfoListAsync);

  useEffect(() => {
    setMediasData(mediaFiles || []);
  }, [mediaFiles]);

  const getFloorNumOption = (undergroundFloorNums?: number, floorNums?: number) => {
    if (!(isNumber(undergroundFloorNums) && isNumber(floorNums))) {
      return [];
    }

    // 0층은 제거 , undergroundFloorNums 음수 처리 , 내림차순 정렬
    return generateNumberArrayFromAtoB(undergroundFloorNums * -1, floorNums)
      .filter((value) => value !== 0)
      .sort(function (a, b) {
        return b - a;
      });
  };

  useEffect(() => {
    // 수정페이지 일때 기본값 세팅
    const fetchApi = async (questionDetail: QuestionDetailModel) => {
      let buildingName: string = "";
      let workOrder: QuestionForm["workOrder"] = undefined;
      let contract: QuestionForm["contract"] = undefined;

      if (questionDetail.buildingId) {
        const { data } = await getBuilding({ buildingId: +questionDetail.buildingId });

        const building: BuildingModel | undefined = data?.data?.content?.building;
        buildingName = building?.buildingName;

        setFloorNumOption(getFloorNumOption(building?.undergroundFloorNums, building?.floorNums));
      }

      const { data, status } = await getConnectedInfoList({ questionId: questionDetail.id });
      if (status >= 200 && status <= 299) {
        const connectedInfoList = data?.data?.content;

        workOrder = connectedInfoList
          ?.filter((data) => data.connectedType === "CS_CONNECTED_WORKORDER")
          ?.map((connectedInfo) => ({ id: connectedInfo.connectedId, connectedInfoId: connectedInfo.id }));
        contract = connectedInfoList
          ?.filter((data) => data.connectedType === "CS_CONNECTED_CONTRACT")
          ?.map((connectedInfo) => ({ id: connectedInfo.connectedId, connectedInfoId: connectedInfo.id }));
      }

      reset({
        id: questionDetail.id,
        csCategoryType: questionDetail.csCategoryType as QuestionForm["csCategoryType"],
        site: questionDetail.site,
        siteSummary: questionDetail.siteSummary,
        buildingId: questionDetail.buildingId ?? "",
        buildingName: buildingName ?? "",
        floorNum: questionDetail.floorNum ?? undefined,
        csTypeId: questionDetail.csTypeId,
        csTypeName: questionDetail.csTypeName,
        csItemId: questionDetail.csItemId ?? undefined,
        summary: questionDetail.summary,
        contentType: questionDetail.contentType ?? "text/plain",
        memberNo: questionDetail.memberNo,
        reporterName: questionDetail.reporterName,
        reporterPhone: questionDetail.reporterPhone ? formatPhoneNumber(questionDetail.reporterPhone) : undefined,
        reporterEmail: questionDetail.reporterEmail,
        description: questionDetail.description,
        isSendAlarm: questionDetail.isSendAlarm,
        assigneeEmail: questionDetail.assigneeEmail,
        cost: questionDetail.cost,
        referenceEmail: questionDetail.referenceEmail,
        expense: questionDetail.expense,
        answerGubun: questionDetail.answerGubun,
        workOrder: workOrder,
        contract: contract,
      });
    };

    if (questionDetail) {
      fetchApi(questionDetail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionDetail]);

  const formValidation = () => {
    const requiredMessage = "필수입력 항목입니다";
    // ================== 조건부 validation =====================
    register("buildingId", { required: { value: getValues("csCategoryType") === "VoC", message: requiredMessage } });
    register("buildingName", { required: { value: getValues("csCategoryType") === "VoC", message: requiredMessage } });
    register("csItemId", { required: { value: getValues("csCategoryType") === "VoC", message: requiredMessage } }); // voc항목ID
    register("summary", { required: { value: getValues("csCategoryType") === "VoC", message: requiredMessage } }); // voc항목명

    if (!isFromOutside) {
      register("answerGubun", {
        required: { value: getValues("csCategoryType") === "문의" || isFromOutside, message: requiredMessage },
      }); // 답변방식
    }

    if (!questionDetail) {
      // 등록시 필수
      register("description", { required: { value: true, message: requiredMessage } });
    }
    // ================== default validation =====================
    register("assigneeEmail", {
      required: { value: true, message: requiredMessage }, // 구분
    });

    register("csCategoryType", {
      required: { value: true, message: requiredMessage }, // 구분
    });
    register("csTypeId", {
      required: { value: true, message: requiredMessage }, // 유형
    });
    register("csTypeName", { required: { value: true, message: requiredMessage } }); // 유형명
    register("site", {
      required: { value: true, message: requiredMessage }, // 유입경로
    });
    register("reporterName", {
      required: { value: true, message: requiredMessage },
    });
    register("reporterPhone", {
      required: {
        value: true,
        message: requiredMessage,
      },
      pattern: {
        value: /^(010|011|016|017|018|019)-?\d{4}-?\d{4}$/,
        message: "유효한 휴대폰 번호를 입력해주세요",
      },
    });
    register("reporterEmail", {
      pattern: {
        value: /^\S+@\S+$/i,
        message: "유효한 이메일을 입력해주세요",
      },
    });
  };
  formValidation();

  /**
   * 생성 또는 삭제 할 connectedInfoList를 생성 후 리턴합니다.
   * 생성된 데이터가 없으면 [] 빈배열을 리턴합니다.
   */
  const createConnectedInfoRequestData = ({
    questionId,
    connectedType,
    updatedArray,
    originalArray,
  }: {
    questionId: string;
    connectedType: ConnectedType;
    updatedArray?: QuestionForm["workOrder"];
    originalArray?: QuestionForm["workOrder"];
  }): QuestionConnectedInfoListRequest["connectedInfoList"] => {
    const addedElements =
      updatedArray
        ?.filter((updatedItem) => (originalArray ? originalArray.every((originalItem) => originalItem.id !== updatedItem.id) : true))
        .map((data) => ({
          questionId: questionId,
          connectedType: connectedType,
          connectedId: data.id,
          cmdType: "C" as UnionCmdType,
        })) ?? [];

    const removedElements =
      originalArray
        ?.filter((originalItem) => (updatedArray ? updatedArray.every((updatedItem) => updatedItem.id !== originalItem.id) : true))
        .map((data) => ({
          id: data.connectedInfoId,
          questionId: questionId,
          connectedType: connectedType,
          connectedId: data.id,
          cmdType: "D" as UnionCmdType,
        })) ?? [];

    return [...addedElements, ...removedElements];
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  const onSubmit = async (data: QuestionForm) => {
    console.log("QuestionForm data :>> ", data);

    let reqData: Partial<QuestionForm> = {};

    if (questionDetail) {
      // 수정페이지
      objectKeys(dirtyFields).forEach((key) => {
        if (key === "reporterPhone") {
          return (reqData[key] = parsedPhoneNumber(data.reporterPhone));
        }
        if (key === "buildingId" && data[key] === "") {
          return (reqData[key] = "-1" as any);
        }
        if (key === "floorNum" && !data[key]) {
          return (reqData[key] = 0 as any);
        }
        reqData[key] = data[key] as any;
      });
    } else {
      //등록페이지
      reqData = {
        ...data,
        reporterPhone: parsedPhoneNumber(data.reporterPhone),
      };
    }

    console.log("변경 사항이 있는 Data :>> ", reqData);

    const { workOrder, contract, ...reqQuestion } = reqData;

    const response = questionDetail
      ? await editQuestion({ id: Number(questionId), question: reqQuestion })
      : await postQuestion({ question: reqQuestion });

    const resQuestionId = response?.data?.data?.content?.id;

    if (!resQuestionId) return; // resQuestionId가 없으면 함수종료 * 공통 에러핸들러에서 처리

    //================================= CS 생성/수정 후 후처리 ==================================

    let errors: string[] = [];

    if (requestMedias.length > 0) {
      await updateMedias(resQuestionId).then((res) => res.status !== 200 && errors.push("첨부파일"));
    }

    if (workOrder) {
      const workOrderReqBody = createConnectedInfoRequestData({
        questionId: resQuestionId,
        connectedType: "CS_CONNECTED_WORKORDER",
        updatedArray: data.workOrder,
        originalArray: defaultValues?.workOrder as any,
      });

      await postConnectedInfoList(workOrderReqBody).then((res) => res.status !== 200 && errors.push("워크오더"));
    }

    if (contract) {
      const contractReqBody = createConnectedInfoRequestData({
        questionId: resQuestionId,
        connectedType: "CS_CONNECTED_CONTRACT",
        updatedArray: data.contract,
        originalArray: defaultValues?.contract as any,
      });

      await postConnectedInfoList(contractReqBody).then((res) => res.status !== 200 && errors.push("신청/계약"));
    }

    if (errors.length > 0) {
      return setBaseModal({
        isOpen: true,
        title: "오류로 인해 아래 항목 등록을 실패했습니다\n재등록해 주세요",
        children: <div>{errors.join(",")}</div>,
        btnRightTitle: "확인",
        onClick: () => {
          navigate(PagePath.question.detail.replace(":id", resQuestionId));
          setBaseModal({ isOpen: false });
        },
      });
    }

    //==================================== 실패 케이스 모두 통과시 실행 =========================================
    navigate(PagePath.question.detail.replace(":id", resQuestionId));
    openToast({ content: "정상적으로 등록되었습니다." });
  };

  if (questionDetail && !getValues("csCategoryType")) {
    return null;
  }

  return (
    <>
      <form>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap ">
            <div className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <h2 className="font18">문의 정보</h2>
              </div>
              <div className="contents-container__1200">
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p className="required">구분</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax200">
                      <Controller
                        control={control}
                        name={"csCategoryType"}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <BaseSelect
                            className="mr8"
                            stateOptions={csCategoryOptions.filter((item, idx) => idx !== 0)}
                            setStateValue={(value: string) => {
                              onChange(value);
                            }}
                            value={value}
                            name={name}
                            errorText={error?.message}
                            placeholder="선택해주세요"
                            isDisabled={questionId ? true : false}
                          />
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>

                {getValues("csCategoryType") && (
                  <>
                    <div className="flex-center">
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p className={watch("csCategoryType") === "VoC" ? "required" : ""}>건물</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div>
                            <Controller
                              control={control}
                              name={"buildingId"}
                              render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                                <div className="flex-row flex-center">
                                  <BaseButton
                                    disabled={isFromOutside}
                                    title="선택"
                                    className="color-white"
                                    onClick={() =>
                                      setAbstractModalZ1({
                                        isOpen: true,
                                        size: "xlarge",
                                        children: (
                                          <SelectBuildingPopup
                                            defaultCheckedList={value ? [value] : undefined}
                                            onClick={(building) => {
                                              setValue("buildingId", building.id.toString(), { shouldDirty: true, shouldValidate: true });
                                              setValue("buildingName", building.buildingName, { shouldDirty: true, shouldValidate: true });
                                              setValue("floorNum", undefined, { shouldDirty: true, shouldValidate: true });

                                              setFloorNumOption(getFloorNumOption(building?.undergroundFloorNums, building?.floorNums));
                                            }}
                                            onClose={() => setAbstractModalZ1({ isOpen: false })}
                                          />
                                        ),
                                      })
                                    }
                                  />

                                  {value && watch("buildingName") && (
                                    <>
                                      <BaseNewTabLink
                                        path={PagePath.building.detail.replace(":id", value)}
                                        value={watch("buildingName")!}
                                        ellipsis
                                        className="ml16 mr4"
                                      />
                                      {isFromOutside === false && (
                                        <div
                                          className="delete-btn-x"
                                          onClick={() => {
                                            setValue("buildingId", "", { shouldDirty: true, shouldValidate: true });
                                            setValue("buildingName", "", { shouldDirty: true, shouldValidate: true });
                                            setValue("floorNum", undefined, { shouldDirty: true, shouldValidate: true });
                                            setFloorNumOption([]);
                                          }}
                                        ></div>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            ></Controller>
                            {errors.buildingId?.message && <p className="validation-text">{errors.buildingId?.message}</p>}
                          </div>
                        </div>
                      </section>
                      {isFromTaap === false && (
                        <section className="contents-container__grid ">
                          <div className="contents-container__grid-index">
                            <p>세부 위치</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            <div className="minmax200">
                              <Controller
                                control={control}
                                name={"floorNum"}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                  <BaseSelect
                                    stateOptions={floorNumOption.map((floor) => {
                                      return {
                                        value: floor,
                                        label: floor > 0 ? `지상${floor}층` : `지하${Math.abs(floor)}층`,
                                      };
                                    })}
                                    setStateValue={(value: any) => {
                                      onChange(value);
                                    }}
                                    value={value}
                                    name={name}
                                    errorText={error?.message}
                                    placeholder="선택해주세요"
                                    isDisabled={isFromOutside}
                                  />
                                )}
                              ></Controller>
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                    <section className="contents-container__grid ">
                      <div className="contents-container__grid-index">
                        <p className="required">{watch("csCategoryType") === "문의" ? "유형" : "유형/항목"}</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <>
                          <Controller
                            control={control}
                            name={"csTypeId"}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return watch("csCategoryType") === "문의" ? (
                                <div className="minmax200">
                                  <BaseSelect
                                    isDisabled={csTypeOptions.length === 0 || isFromOutside}
                                    className="mr8"
                                    stateOptions={csTypeOptions}
                                    setStateValue={(value: string) => {
                                      console.log("csTypeOptions :>> ", csTypeOptions);
                                      onChange(value);
                                      setValue("csTypeName", csTypeOptions.find((option) => option.value === value)?.label!, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                      });
                                    }}
                                    value={value}
                                    name={name}
                                    placeholder="선택해주세요"
                                  />
                                </div>
                              ) : (
                                <div className="flex-row flex-center-start">
                                  <BaseButton
                                    disabled={!watch("buildingId") || isFromOutside}
                                    title="선택"
                                    className="color-white"
                                    onClick={() =>
                                      setAbstractModalZ1({
                                        isOpen: true,
                                        size: "xlarge",
                                        children: (
                                          <SelectCsServiceItemPopup
                                            serviceId={watch("buildingId")}
                                            serviceType="SERVICE_BUILDING"
                                            defaultCheckedList={value ? [value] : undefined}
                                            onClick={(csServiceItem) => {
                                              setValue("csTypeId", csServiceItem.csTypeId ?? "", { shouldDirty: true, shouldValidate: true }); // 유형
                                              setValue("csTypeName", csServiceItem.csTypeName ?? "", { shouldDirty: true, shouldValidate: true }); // 유형명
                                              setValue("csItemId", csServiceItem.csItemId ?? "", { shouldDirty: true, shouldValidate: true }); // 항목
                                              setValue("summary", csServiceItem.csItemName ?? "", { shouldDirty: true, shouldValidate: true }); // 항목명
                                            }}
                                            onClose={() => setAbstractModalZ1({ isOpen: false })}
                                          />
                                        ),
                                      })
                                    }
                                  />
                                  {watch("csTypeName") && watch("summary") && watch("csItemId") && (
                                    <>
                                      <BaseNewTabLink
                                        path={PagePath.voc.detail.replace(":id", watch("csItemId")!)}
                                        value={`${watch("csTypeName")} / ${watch("summary")}`}
                                        ellipsis
                                        className="ml16 mr4"
                                      />
                                      {isFromOutside === false && (
                                        <>
                                          <div
                                            className="delete-btn-x"
                                            onClick={() => {
                                              setValue("csTypeId", "", { shouldDirty: true, shouldValidate: true }); // 유형
                                              setValue("csTypeName", "", { shouldDirty: true, shouldValidate: true }); // 유형명
                                              setValue("csItemId", "", { shouldDirty: true, shouldValidate: true }); // 항목
                                              setValue("summary", "", { shouldDirty: true, shouldValidate: true }); // 항목명
                                            }}
                                          ></div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            }}
                          ></Controller>
                          {errors?.csTypeId?.message && <p className="validation-text">{errors?.csTypeId?.message}</p>}
                        </>
                      </div>
                    </section>

                    <section className="contents-container__grid ">
                      <div className="contents-container__grid-index">
                        <p className="required">유입경로</p>
                      </div>
                      <div className="contents-container__grid-contents flex-center-start align-items-start gap8">
                        <div className="minmax200">
                          <Controller
                            control={control}
                            name={"site"}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                              <BaseSelect
                                className="mr8"
                                stateOptions={
                                  questionDetail
                                    ? siteOptions
                                    : siteOptions.filter(
                                        (option, idx) =>
                                          idx !== 0 && option.value !== QuestionSite.SITE_TAAP_SPACE && option.value !== QuestionSite.TAAP,
                                      )
                                }
                                setStateValue={(value: string) => onChange(value)}
                                value={value}
                                name={name}
                                errorText={error?.message}
                                placeholder="선택해주세요"
                                isDisabled={isFromOutside}
                              />
                            )}
                          ></Controller>
                        </div>
                        {isFromOutside === false && (
                          <div className="minmax200">
                            <Controller
                              control={control}
                              name={"siteSummary"}
                              render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                                <BaseInput
                                  disabled={isFromOutside}
                                  placeholder="상세 유입경로를 입력해주세요"
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  errorText={error?.message}
                                />
                              )}
                            ></Controller>
                          </div>
                        )}
                      </div>
                    </section>
                    <div className="flex-center">
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p className="required">이름/닉네임</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div className="minmax200">
                            <Controller
                              control={control}
                              name={"reporterName"}
                              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <BaseInput
                                  placeholder={"이름 / 닉네임을 입력해주세요"}
                                  onChange={(value: string) => onChange(value)}
                                  value={value}
                                  name={name}
                                  errorText={error?.message}
                                  disabled={isFromOutside}
                                />
                              )}
                            ></Controller>
                          </div>
                        </div>
                      </section>
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>이메일</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          <div className="minmax200">
                            <Controller
                              control={control}
                              name={"reporterEmail"}
                              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <BaseInput
                                  placeholder={"이메일을 입력해주세요"}
                                  onChange={(value: string) => onChange(value)}
                                  value={value}
                                  name={name}
                                  errorText={error?.message}
                                  disabled={isFromOutside}
                                />
                              )}
                            ></Controller>
                          </div>
                        </div>
                      </section>
                    </div>
                    <section className="contents-container__grid">
                      <div className="contents-container__grid-index">
                        <p className="required">휴대폰 번호</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="minmax200">
                          <Controller
                            control={control}
                            name={"reporterPhone"}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                              <BaseInput
                                type="phone"
                                placeholder={"휴대폰 번호를 입력해주세요"}
                                onChange={(value: string) => onChange(value)}
                                value={value || ""}
                                name={name}
                                maxLength={45}
                                disabled={isFromOutside}
                                errorText={error?.message}
                              />
                            )}
                          ></Controller>
                        </div>
                      </div>
                    </section>
                    {/* 답변 방식 */}

                    {watch("csCategoryType") === "문의" && !isFromOutside && (
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p className="required">답변 방식</p>
                        </div>
                        <div className="flex-row flex-center-start">
                          {answerGubunOptions.map((option) => {
                            return (
                              <div key={option.value} className="flex-row flex-center-start">
                                <Controller
                                  control={control}
                                  name={"answerGubun"}
                                  shouldUnregister={watch("csCategoryType") !== "문의"}
                                  render={({ field: { onChange, value, name } }) => (
                                    <BaseRadio
                                      className="mr16"
                                      name={name}
                                      value={value}
                                      id={option.value + name}
                                      label={option.label}
                                      checked={option.value === value || false}
                                      onChange={() => {
                                        onChange(option.value);
                                      }}
                                    />
                                  )}
                                ></Controller>
                              </div>
                            );
                          })}
                          {errors?.answerGubun?.message && <p className="validation-text">{errors?.answerGubun?.message}</p>}
                        </div>
                      </section>
                    )}

                    <section className="contents-container__grid contents-container__1200">
                      <div className="contents-container__grid-index">
                        <p className="required">문의 내용</p>
                      </div>
                      <div className="contents-container__grid-contents">
                        <div className="">
                          <Controller
                            control={control}
                            name={"description"}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                              <BaseTextarea
                                height={MIN_TEXTAREA_HEIGHT}
                                className="overflow-y-hidden auto-height"
                                inputRef={textAreaRef}
                                maxLength={1000}
                                placeholder={"상세내용을 입력해주세요"}
                                onChange={onChange}
                                value={value || ""}
                                name={name}
                                errorText={error?.message}
                                disabled={questionDetail ? true : false}
                                adjustHeight={true}
                              />
                            )}
                          ></Controller>
                        </div>
                      </div>
                    </section>
                    <section className="contents-container__grid contents-container__1200">
                      <div className="contents-container__grid-index">
                        <p>첨부파일{mediasData && `(${mediasData.length}/3)`}</p>
                      </div>
                      {questionDetail && mediasData.length === 0 ? (
                        <div className="contents-container__grid-index">
                          <p>-</p>
                        </div>
                      ) : (
                        <BaseFileUpload
                          limit={3}
                          maxSize={10}
                          fileList={mediasData}
                          disabled={isFromOutside}
                          isOnlyView={questionId ? true : false}
                          setFiles={(files: any) => {
                            const newFiles = files.map((file: any) => ({ ...file, serviceType: "SERVICE_QUESTION" }));
                            setMediasData(newFiles);
                          }}
                        />
                      )}
                    </section>
                    {/* 접수 완료 알림톡 */}
                    {isFromOutside === false && (
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <div className="flex-center">
                            <p>접수 완료 알림톡</p>
                            <BaseTooltip
                              touchIcon={"QUESTION"}
                              className="ml4 mt1"
                              size={16}
                              tooltip="등록한 문의자의 휴대폰 번호로 접수 완료 안내 알림톡을 발송합니다"
                            />
                          </div>
                        </div>
                        <div className="contents-container__grid-contents">
                          {questionDetail ? (
                            <p>{questionDetail.isSendAlarm ? "발송" : "미발송"}</p>
                          ) : (
                            <div className="">
                              <Controller
                                control={control}
                                name={"isSendAlarm"}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                  <BaseCheckbox onChange={onChange} checked={value} id={name} name={name} label="발송" />
                                )}
                              ></Controller>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <h2 className="font18">담당자 정보</h2>
              </div>

              {/* 담당자 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index flex-start">
                  <p className="required">담당자</p>
                  <BaseTooltip touchIcon={"QUESTION"} className="ml4 mt1" size={16} tooltip="답변 이메일에 담당자 정보가 표시됩니다" />
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <Controller
                      control={control}
                      name={"assigneeEmail"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <div className="flex-row flex-center">
                          <BaseButton
                            title={"선택"}
                            className="color-white"
                            onClick={() =>
                              setAbstractModalZ1({
                                isOpen: true,
                                children: (
                                  <SelectQuestionManagerPopup
                                    checkboxType="radio"
                                    selectKey={"email"}
                                    title={"담당자 선택"}
                                    defaultCheckedList={value ? [value] : undefined}
                                    onClick={(paPartnerMemberList) =>
                                      setValue("assigneeEmail", paPartnerMemberList[0], { shouldDirty: true, shouldValidate: true })
                                    }
                                    onClose={() => setAbstractModalZ1({ isOpen: false })}
                                  />
                                ),
                              })
                            }
                          />

                          {value && (
                            <>
                              <p className="ml16 mr4">{value || ""}</p>
                              <div
                                className="delete-btn-x"
                                onClick={() => {
                                  setValue("assigneeEmail", "", { shouldDirty: true, shouldValidate: true });
                                }}
                              ></div>
                            </>
                          )}
                          {!watch("assigneeEmail") && (
                            <div
                              className="cursor-pointer ml16"
                              onClick={() => {
                                setValue("assigneeEmail", user?.email!, { shouldDirty: true, shouldValidate: true });
                              }}
                            >
                              <p className="text-underline">본인으로 지정</p>
                            </div>
                          )}
                        </div>
                      )}
                    ></Controller>
                    {errors.assigneeEmail?.message && <p className="validation-text">{errors.assigneeEmail?.message}</p>}
                  </div>
                </div>
              </section>
              {/* 참조자 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index flex-start">
                  <p className="">참조</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <Controller
                      control={control}
                      name={"referenceEmail"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <div className="flex-row flex-center">
                          <BaseButton
                            title={"선택"}
                            className="color-white"
                            onClick={() =>
                              setAbstractModalZ1({
                                isOpen: true,
                                children: (
                                  <SelectQuestionManagerPopup
                                    checkboxType="checkbox"
                                    selectKey={"email"}
                                    title={"참조자 선택"}
                                    defaultCheckedList={value ? value.split(",") : undefined}
                                    onClick={(paPartnerMemberEmailList) => {
                                      console.log("paPartnerMemberList :>> ", paPartnerMemberEmailList);
                                      setValue("referenceEmail", paPartnerMemberEmailList?.join(","), {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                      });
                                    }}
                                    onClose={() => setAbstractModalZ1({ isOpen: false })}
                                    min={1}
                                    max={3}
                                  />
                                ),
                              })
                            }
                          />

                          {/* {value && (
                            <>
                              <p className="ml16 mr4">{value ? value.split(",").join(" , ") : "-"}</p>
                              <div
                                className="delete-btn-x"
                                onClick={() => {
                                  setValue("referenceEmail", "", { shouldDirty: true, shouldValidate: true });
                                }}
                              ></div>
                            </>
                          )} */}

                          {value &&
                            value.split(",")?.map((referenceEmail: string) => (
                              <div key={referenceEmail} className="d-flex flex-row">
                                <p className="ml16 mr4">{referenceEmail}</p>
                                <div
                                  className="delete-btn-x"
                                  onClick={() => {
                                    console.log("sss", value);
                                    setValue(
                                      "referenceEmail",
                                      value
                                        .split(",")
                                        .filter((str) => str !== referenceEmail)
                                        .join(","),
                                      { shouldDirty: true, shouldValidate: true },
                                    );
                                  }}
                                ></div>
                              </div>
                            ))}

                          {!watch("referenceEmail") && (
                            <div
                              className="cursor-pointer ml16"
                              onClick={() => {
                                setValue("referenceEmail", user?.email!, { shouldDirty: true, shouldValidate: true });
                              }}
                            >
                              <p className="text-underline">본인으로 지정</p>
                            </div>
                          )}
                        </div>
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>

              {/* 매출 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>매출</p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax240">
                    <Controller
                      control={control}
                      name={"cost"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          placeholder="발생한 매출을 입력해주세요"
                          onChange={(value: string) => {
                            onChange(+onlyNumber(value));
                          }}
                          value={value}
                          name={name}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
              {/* 지출 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>지출</p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax240">
                    <Controller
                      control={control}
                      name={"expense"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          placeholder="발생한 지출을 입력해주세요"
                          onChange={(value: string) => {
                            onChange(+onlyNumber(value));
                          }}
                          value={value}
                          name={name}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>
            <div className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <h2 className="font18">연결 정보</h2>
              </div>
              <div className="contents-container__1200">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>신청/계약</p>
                  </div>
                  <div className="contents-container__grid-contents ">
                    <div>
                      <div className="flex-row flex-center">
                        <BaseButton
                          title="선택"
                          className="color-white"
                          onClick={() =>
                            setAbstractModalZ1({
                              isOpen: true,
                              size: "xlarge",
                              children: (
                                <SelectContractPopup
                                  defaultCheckedList={watch("contract")?.map((contract) => contract.id.toString() ?? "")}
                                  onClick={(data) =>
                                    setValue(
                                      "contract",
                                      data.map((contract) => ({ ...contract, id: contract.contractId.toString() })),
                                      { shouldDirty: true },
                                    )
                                  }
                                  onClose={() => setAbstractModalZ1({ isOpen: false })}
                                />
                              ),
                            })
                          }
                        />
                        {watch("contract")?.map((data) => (
                          <div key={data.id} className="flex-row flex-center">
                            <BaseNewTabLink
                              path={PagePath.contract.detail.replace(":id", data.id.toString())}
                              value={data.id.toString() ?? "-"}
                              ellipsis
                              className="ml16 mr4"
                            />
                            <div
                              className="delete-btn-x"
                              onClick={() => {
                                const filteredContract = watch("contract")?.filter((prevData) => prevData.id !== data.id);
                                setValue("contract", filteredContract, { shouldDirty: true });
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>워크오더</p>
                  </div>
                  <div className="contents-container__grid-contents ">
                    <div>
                      <div className="flex-row flex-center">
                        <BaseButton
                          title="선택"
                          className="color-white"
                          onClick={() =>
                            setAbstractModalZ1({
                              isOpen: true,
                              size: "xlarge",
                              children: (
                                <SelectWorkOrderPopup
                                  defaultCheckedList={watch("workOrder")?.map((workOrder) => workOrder.id ?? "")}
                                  onClick={(data) =>
                                    setValue(
                                      "workOrder",
                                      data.map((workOrderId) => ({ id: workOrderId })),
                                      { shouldDirty: true },
                                    )
                                  }
                                  onClose={() => setAbstractModalZ1({ isOpen: false })}
                                />
                              ),
                            })
                          }
                        />
                        {watch("workOrder")?.map((data) => (
                          <div className="flex-row flex-center" key={data.id}>
                            <BaseNewTabLink
                              path={PagePath.workOrder.authenticated.detail.replace(":id", data.id)}
                              value={data.id ?? "-"}
                              ellipsis
                              className="ml16 mr4"
                            />
                            <div
                              className="delete-btn-x"
                              onClick={() => {
                                const filteredWorkOrder = watch("workOrder")?.filter((prevData) => prevData.id !== data.id);
                                setValue("workOrder", filteredWorkOrder, { shouldDirty: true });
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            {isDirty && questionDetail && (
              <div className="mr20">
                <p className="required font14 text-red">수정된 사항이 있으니 다시 한번 확인해주세요.</p>
              </div>
            )}
            {questionId && (
              <BaseButton
                type="button"
                title="취소"
                className="size-large color-white mr5"
                onClick={() => navigate(PagePath.question.detail.replace(":id", String(questionId)))}
              />
            )}

            <BaseButton
              type="button"
              title={"저장"}
              className="size-large"
              onClick={async () => {
                const isRequiredCheck = await trigger();
                if (isRequiredCheck) {
                  setBaseModal({
                    isOpen: true,
                    btnLeftTitle: "취소",
                    btnRightTitle: "확인",
                    onClick: () => {
                      handleSubmit(onSubmit, onError)();
                      setBaseModal({ isOpen: false });
                    },
                    title: "저장하시겠습니까?",
                  });
                }
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default BasicInfoForm;
