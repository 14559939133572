import { Control, Controller } from "react-hook-form";
import { BaseSelect, BaseToggle } from "src/components";
import { BasicInfoFormData, SelectOption } from "src/pages/building/building-types";

type Props = {
  control: Control<BasicInfoFormData>;
  passengerElevOptions: Array<SelectOption>;
  freightElevOptions: Array<SelectOption>;
};

// 엘리베이터 컴포넌트
const Elevator = ({ control, passengerElevOptions, freightElevOptions }: Props) => {
  return (
    <Controller
      control={control}
      name="isUsedElevator"
      render={({ field: { onChange, value, name } }) => (
        <>
          <div>
            <BaseToggle checked={value} onChange={onChange} />
          </div>
          {value && (
            <>
              <div className="minmax250 flex-center mb10">
                <div className="minmax50 font14 no-wrap mr10">승객용</div>
                <Controller
                  control={control}
                  name="passengerElev"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      stateOptions={passengerElevOptions}
                      setStateValue={onChange}
                      value={value}
                      name={name}
                    />
                  )}
                ></Controller>
              </div>
              <div className="minmax250 flex-center">
                <div className="minmax50 font14 no-wrap mr10">화물용</div>
                <Controller
                  control={control}
                  name="freightElev"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseSelect
                      stateOptions={freightElevOptions}
                      setStateValue={onChange}
                      value={value}
                      name={name}
                    />
                  )}
                ></Controller>
              </div>
            </>
          )}
        </>
      )}
    ></Controller>
  );
};

export default Elevator;
