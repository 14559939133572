type PropValues = { 
  title: string;
  value: any;
  classname?: string;
}

const TextAttribute = ({title, value, classname}: PropValues) => {
  
  return (
    <>
      <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>{title} </p>
          </div>
          <div className="contents-container__grid-contents">
              <p>{value || '-'}</p>
          </div>
        </section>
    </>
  )
}

export default TextAttribute