import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { MgmtOffice } from "./mgmt-office.types";

/**
 * RPO04. (무인증) 관리처 조회
 */
export async function getMgmtOfficeAsync(
  axios: AxiosInstance,
  params?: {
    id?: string;
    buildingId?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: MgmtOffice }>>> {
  if (!params?.id && !params?.buildingId) {
    throw Error("id or buildingId is not found.");
  }
  return await axios.get(`/api/ctrl/pr/public/mgmt-office`, {
    params: {
      id: params?.id,
      buildingId: params?.buildingId,
    },
  });
}
