import { DefaultOptions, useQuery } from "@tanstack/react-query";

import { getUnitReservationTimeAsync, UnitReservationTimeListParams } from "src/api/product/product-api";
import { axiosInstance } from "src/utils";

/**
 * //예약 가능 기간
 * @param param defaultOptions:DefaultOptions["queries"]
 * @returns UnitReservationTime[]
 */
const useGetUnitReservationTime = ({
  unitReservationTimeListParams,
  defaultOptions,
}: {
  unitReservationTimeListParams: UnitReservationTimeListParams;
  defaultOptions?: DefaultOptions["queries"];
}) => {
  const _defaultOptions = defaultOptions as unknown as Object;
  const query = useQuery({
    queryKey: ["getUnitReservationTimeAsync", unitReservationTimeListParams],
    queryFn: () => getUnitReservationTimeAsync(axiosInstance, unitReservationTimeListParams).then((res) => res?.data?.data?.content),

    enabled: !!unitReservationTimeListParams,
    retryOnMount: false,
    staleTime: 1000,
    // refetchOnMount: false,
    ...(_defaultOptions && _defaultOptions),
  });

  return { ...query };
};

export default useGetUnitReservationTime;
