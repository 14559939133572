import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserAccessSection from "./UserAccessSection";
import VisitorAccessSection from "./VisitorAccessSection";
import { useApiOperation } from "src/api/hooks";
import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { getContractOperationInfo, saveContractOperation } from "src/api/contract/contract-api";
import { useLocation, useParams } from "react-router-dom";
import { useToast } from "src/recoil/toast/hook";
import qs from "qs";
import { PagePath } from "src/pages/product/details";
import useContractFacilityApi from "src/pages/contract/hooks/useContractFacilityApi";
import _ from "lodash";
import { checkDataNMake } from "src/pages/contract/hooks/useContractApi";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

type Props = {
  detailData: any;
  contractApplyNumber: string;
};

const UserAndVisitorAccess = ({ detailData, contractApplyNumber }: Props) => {
  const { id } = useParams();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { openToast } = useToast();
  const { isAuthority } = usePartnerAuthority();

  const [productManage, setProductManage] = useState<ContractOperationProductManage>();
  const [originalProductManage, setOriginalProductManage] = useState<ContractOperationProductManage>();

  /**RCA31. 계약 - 운영정보 상세 조회*/
  const { executeAsync: getContractOperation } = useApiOperation(getContractOperationInfo);

  /**RCA33. 계약 - 운영정보 수정   */

  const { setContractOperation } = useContractFacilityApi();

  const contractId = useMemo(() => {
    return queryParams?.id ? String(queryParams.id) : id;
  }, [id, queryParams.id]);
  // console.log(`contractId`, contractId);

  // 운영정보 상세 - 최대인원 멤버 및 현재 이용자 수 확인
  const fetchContractOperation = async (id: string) => {
    const response = await getContractOperation({ id: Number(id) });
    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.content.productManage;
      setProductManage(result);
      setOriginalProductManage(_.cloneDeep(result));
    }
  };

  useEffect(() => {
    if (contractId) {
      fetchContractOperation(contractId);
    }
  }, []);

  const moveUserList = useMemo(() => {
    return PagePath.user.list + "?contractApplyNumber=" + contractApplyNumber + "&contractId=" + contractId;
  }, [contractApplyNumber, contractId]);

  const moveVisitorList = useMemo(() => {
    return PagePath.visitor.list + "?contractApplyNumber=" + contractApplyNumber + "&contractId=" + contractId;
  }, [contractApplyNumber, contractId]);

  // 이용자 관리 - 최대 이용자수 변경
  const editProductManageData = async (data: ContractOperationProductManage) => {
    if (data) {
      let rtnValidation = { validationPass: false, message: "", response: {} };
      let rtnData: any = {};
      let response: any = checkDataNMake(rtnValidation, originalProductManage, data, rtnData);

      if (response.rtnData.validationPass) {
        const res = await setContractOperation({ id: Number(contractId), productManage: response.makeData });
        if (res.status >= 200 && res.status <= 299) {
          fetchContractOperation(String(contractId));
          openToast({ content: "정상적으로 수정되었습니다." });
        }
      }
    }
  };

  return (
    <div className="flex-center">
      {/* 이용자 관리 */}
      <UserAccessSection
        productManage={productManage || {}}
        goToList={moveUserList}
        setData={(data: ContractOperationProductManage) => {
          if (data) {
            editProductManageData(data);
          }
        }}
        isAuthority={isAuthority("w")}
      />
      {/* 방문자 관리 */}
      <VisitorAccessSection
        detailData={detailData}
        productManage={productManage || {}}
        goToList={moveVisitorList}
        setData={(data: ContractOperationProductManage) => {
          if (data) {
            editProductManageData(data);
          }
        }}
        isAuthority={isAuthority("w")}
      />
    </div>
  );
};

export default UserAndVisitorAccess;
