import { useCallback, useMemo, useState } from "react";
import { BuildingCommonFacilityDeskGroupModel, BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { EslTempProduct } from "src/api/esl/esl-types";
import { MediaFile } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { DeskSpace, SelectOption, floorOptions, sortingDeskGroupList } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import Esl from "./Esl";
import { Cell } from "react-table";

type Props = {
  building: BuildingModel | null;
};

interface EslLabel extends EslTempProduct {
  locationCode: string; // 공간유형 채번
}

/* 
  좌석 컴포넌트
 */
const DeskSection = ({ building }: Props) => {
  const [deskGroupMedias, setDeskGroupMedias] = useState<MediaFile[]>([]);

  // UI 에서 사용할 좌석목록 배열
  const deskSpaces: Array<DeskSpace> = useMemo(() => {
    // UI 에서 사용할 좌석목록 배열
    const deskSpaces: Array<DeskSpace> = [];

    // 건물 상세조회 api 에서 내려준 좌석 목록 (정렬후)
    const deskGroupList: Array<BuildingCommonFacilityDeskGroupModel> = sortingDeskGroupList(
      building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
    );

    if (deskGroupList.length > 0) {
      for (let i = 0; i < deskGroupList.length; i++) {
        const commonfacility: BuildingCommonFacilityDeskGroupModel = deskGroupList[i];

        const isGround: boolean = commonfacility?.isGround === true; // 지상여부
        const floorNum: number = commonfacility?.floorNum || 1; // 층
        const floor = `${isGround ? "" : "-"}${floorNum}`;

        // 좌석 그룹
        const deskGroup: DeskSpace = {
          type: "GROUP",
          deskGroupId: commonfacility?.id,
          floor,
          deskGroupName: commonfacility.groupName,
          deskGroupDescription: commonfacility.description,
          isGray: true,
          mediaList: commonfacility.mediaList,
        };
        deskSpaces.push(deskGroup);

        // 좌석
        (commonfacility?.deskList || []).forEach((commonFacilityDesk: CommonFacilityModel) => {
          const desk: DeskSpace = {
            type: "DESK",
            deskGroupId: deskGroup.deskGroupId,
            deskId: Number(commonFacilityDesk.id),
            deskName: commonFacilityDesk.facilityName,
            locationCode: commonFacilityDesk.locationCode,
          };
          deskSpaces.push(desk);
        });
      }
    }
    return deskSpaces;
  }, [building?.buildingCommonFacility?.deskSpace?.deskGroupList]);

  // 배치도 배열
  const deskLayoutMedias: Array<MediaFile> = useMemo(() => {
    return building?.buildingCommonFacility?.deskSpace?.mediaList || [];
  }, [building?.buildingCommonFacility?.deskSpace?.mediaList]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "deskGroupId",
        width: 60,
        Cell: ({ row, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          const deskId = row?.original?.deskId || "";
          return isGroupRow ? value : deskId;
        },
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 80,
        Cell: ({ value }: any) => {
          const floor = floorOptions.find((option: SelectOption) => option.value === value);
          return floor?.label || "";
        },
      },
      {
        Header: "좌석 그룹 명",
        accessor: "deskGroupName",
        width: 130,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "설명",
        accessor: "deskGroupDescription",
        width: 150,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "좌석 수량",
        accessor: "deskCount",
        width: 90,
        Cell: ({ row, data }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          if (isGroupRow) {
            // 데스크 그룹의 경우, 데스크 합계 갯수를 보여준다
            const deskList = (data || []).filter((d: DeskSpace) => d.type === "DESK" && d.deskGroupId === row?.original?.deskGroupId);
            return <div>{deskList.length}</div>;
          } else {
            return <div className="ic_ho"></div>;
          }
        },
      },
      {
        Header: "좌석 명",
        accessor: "deskName",
        width: 130,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 160,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 80,
        Cell: ({ row }: any) => <Esl row={row} />,
      },
      {
        Header: "이미지",
        accessor: "mediaList",
        width: 60,
        Cell: ({ row }: Cell<any>) => {
          const imageCount = (row?.original?.mediaList || []).length;
          const isGroup = row.original.type === "GROUP";
          return (
            <>
              {isGroup && (
                <a className="text-underline cursor-pointer" onClick={() => setDeskGroupMedias(row.original.mediaList || [])}>
                  {imageCount}
                </a>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  console.log("desk", deskGroupMedias);

  return (
    <section className="">
      {deskSpaces.length > 0 || deskLayoutMedias.length > 0 ? (
        <>
          <ViewDataTable columns={columns} data={deskSpaces} />
          <div className="mt20">
            <ImagesDetail isUsedRepresentative={true} isUsedDescription={true} images={deskGroupMedias} />
          </div>
          {/* 배치도 */}
          <div className="contents-container__grid mt20">
            <div className="contents-container__grid-index">
              <p className="">배치도</p>
            </div>
          </div>
          <div className="mt20">
            <ImagesDetail images={deskLayoutMedias} isUsedDescription={true} />
          </div>
        </>
      ) : (
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">데이터가 없습니다.</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default DeskSection;
