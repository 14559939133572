import React, { memo, useCallback, useEffect, useState } from "react";

interface BaseTextareaState {
  inputRef?: any;
  placeholder?: string;
  readonly?: boolean;
  onChange?: Function;
  className?: string;
  disabled?: boolean;
  value?: string;
  name?: string;
  errorText?: string;
  maxLength?: number;
  height?: number;
  adjustHeight?: boolean;
  onClick?: () => void;
}

export const BaseTextarea = memo((props: BaseTextareaState) => {
  const [text, setText] = useState("");
  const onChangeValues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(e.currentTarget.value, e);
      setText(e.target.value);
    }
  };

  const adjustHeight = useCallback(() => {
    if (props.inputRef.current) {
      props.inputRef.current.style.height = "0px"; // Reset height to auto to calculate actual content height //초기 사이즈 이슈 수정
      props.inputRef.current.scrollTop = 0; // Scroll to the top to measure actual content height
      const scrollHeight = props.inputRef.current.scrollHeight;
      const clientHeight = props.inputRef.current.clientHeight;
      props.inputRef.current.style.height = `${Math.max(scrollHeight, clientHeight, props.height || 0)}px`;
    }
  }, [text]);

  useEffect(() => {
    if (props.adjustHeight) {
      adjustHeight();
    }
  });

  return (
    <>
      <div className={`base-textarea ${props.className ? props.className : ""}`}>
        <textarea
          ref={props.inputRef}
          name={props.name}
          className={props.className}
          placeholder={props.placeholder}
          onChange={onChangeValues}
          value={props.value}
          maxLength={props.maxLength}
          style={{ height: `${props.height}px` }}
          readOnly={props.readonly ? true : false}
          disabled={props.disabled ? true : false}
          onClick={props.onClick}
        />
        {props.maxLength && (
          <div className="now-max-length">
            <span className={`${(props?.value?.length || 0) > props.maxLength ? "text-red900" : ""}`}>{props?.value ? props.value.length : 0}</span>
            <span className="mx2">/</span>
            <span>{props.maxLength}</span>
          </div>
        )}
      </div>
      {props.errorText && !text && <p className="validation-text">{props.errorText}</p>}
    </>
  );
});
