interface Props extends React.HTMLAttributes<HTMLButtonElement> {}

function ExcelDownloadButton({ children, ...props }: Props) {
  return (
    <button {...props} className="base-btn color-white icon-excel">
      {children}
    </button>
  );
}
export default ExcelDownloadButton;
