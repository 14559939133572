import _ from "lodash";
import moment from "moment";
import { BaseDatePicker, BaseSelect } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import { YmdFormat } from "src/utils/common-util";

const FOType = ({ saveData, setSaveData, productDetail, selectedPeriodOption }: any) => {
  return (
    <>
      <article className="contents-container__wrap-article">
        <div className="contents-container__sub-title">
          <h2>신청기간</h2>
        </div>
        <div className="flex-center">
          {/* 이용 시작 */}
          <BaseSection
            title="이용 신청 시작"
            required={true}
            children={
              <div className="minmax160">
                <BaseDatePicker
                  name={"useStartTime"}
                  type={"date"}
                  selectedDate={saveData.useStartTime ? moment(saveData.useStartTime).toDate() : null}
                  readonly={saveData.applicantMemberNo === undefined || productDetail === undefined || productDetail?.productType === "TIME_COURT"}
                  setDate={(date: string) => {
                    const temp = _.cloneDeep(saveData);
                    if (productDetail?.productType !== "TIME_COURT") {
                      const period = temp.useTerm;
                      const start = moment(date).format(YmdFormat.YYYY_MM_DD);
                      temp.useStartTime = moment(start).format(YmdFormat.WITH_TIME_ZONE);
                      if (Number(period) !== 0) {
                        const end = moment(start).add(period, "M").subtract(1, "days").format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999";
                        temp.useEndTime = moment(end).format(YmdFormat.WITH_TIME_ZONE);
                      }
                    }
                    setSaveData(temp);
                  }}
                />
              </div>
            }
          />

          <BaseSection
            title="신청 기간(MM)"
            required={true}
            children={
              <div className="minmax120">
                <BaseSelect
                  placeholder="선택"
                  value={saveData.useTerm === 0 ? "" : Number(saveData.useTerm)}
                  name={"useTerm"}
                  isDisabled={saveData.applicantMemberNo === undefined || productDetail === undefined}
                  stateOptions={selectedPeriodOption}
                  setStateValue={(value: number) => {
                    const temp = _.cloneDeep(saveData);
                    const date = temp.useStartTime;
                    if (date !== "") {
                      const start = moment(date).format(YmdFormat.YYYY_MM_DD);
                      const end = moment(start).add(value, "M").subtract(1, "days").format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999";
                      temp.useEndTime = moment(end).format(YmdFormat.WITH_TIME_ZONE);
                    }
                    temp.useTerm = String(value);
                    setSaveData(temp);
                  }}
                />
              </div>
            }
          />
        </div>
        <div className="flex-center">
          <BaseSection
            title="이용 신청 종료"
            required={true}
            children={
              <div className="minmax160">
                <BaseDatePicker
                  type="date"
                  name={"useEndTime"}
                  readonly={true}
                  selectedDate={saveData.useEndTime ? moment(saveData.useEndTime).toDate() : null}
                  setDate={(date: string) => {
                    const temp = _.cloneDeep(saveData);
                    if (productDetail?.productType !== "TIME_COURT") {
                      const end = moment(date).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999";

                      temp.useEndTime = moment(end).format(YmdFormat.WITH_TIME_ZONE);
                    }
                    setSaveData(temp);
                  }}
                />
              </div>
            }
          />
        </div>
      </article>
    </>
  );
};

export default FOType;
