import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Order } from "src/api/public-types";
import { VocQueryParams } from "src/api/voc/voc-types";
import { BaseButton, BaseInputWithSearch, BaseTable } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useCsOptions } from "../hooks/useCsOptions";
import { useVocList } from "../hooks/useVocList";
import { vocSearchType } from "../voc-options";
import VocColumns from "./columns/VocColumns";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const VocList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams: VocQueryParams = useMemo(() => {
    const query: VocQueryParams = qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true });
    if (!query.page) {
      query.page = 0;
    }
    if (!query.size) {
      query.size = 20;
    }

    if (!query?.sort) {
      query.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (query?.keywordList) {
      query.keywordList = decodeURIComponent(query.keywordList);
    }

    return query;
  }, [location]);

  const [params, setParams] = useState<VocQueryParams>();

  // voc 리스트
  const { list: vocList, pageMeta } = useVocList(queryParams);

  // voc 유형
  const { options: vocOptions } = useCsOptions("VoC");

  const { isAuthority } = usePartnerAuthority();

  useEffect(() => {
    setParams(queryParams);
  }, [queryParams]);

  const navigateWithQueryParams = useCallback(
    (passParams?: VocQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      if (newQueryParams.frontCsTypeIdList) {
        delete newQueryParams.frontCsTypeIdList;
      }
      if (newQueryParams.csTypeIdList === "") {
        delete newQueryParams.csTypeIdList;
      }

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });

      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [location, params, queryParams],
  );

  return (
    <>
      <div className="page-product-list">
        <div className="contents-container__table">
          <div className="contents-container__search-wrap">
            <div className="left-area">
              <section>
                <div className="left-area__index">
                  <span>조건검색</span>
                </div>
                <div className="left-area__contents">
                  <div className="minmax140 mr8">
                    <BaseMultiSelect
                      stateOptions={[{ label: "전체", value: "" }, ...vocOptions]}
                      value={(queryParams?.csTypeIdList?.split(",").map(String) as string[]) || []}
                      setStateValue={(csTypeIdList: Array<{ value: string; label: string }>) => {
                        console.log(`csTypeIdList.map((item) => item.value).join(",")`, csTypeIdList.map((item) => item.value).join(","));
                        navigateWithQueryParams({
                          ...params, //
                          csTypeIdList: csTypeIdList.map((item) => item.value).join(","),
                          page: 0,
                        });
                      }}
                      placeholder="유형"
                    />
                  </div>

                  <BaseInputWithSearch
                    type="text"
                    selectValue={params?.searchType}
                    inputValue={params?.keywordList ? String(params?.keywordList) : ""}
                    stateOptions={vocSearchType}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onChange={(keywordList: string) => {
                      setParams({ ...params, keywordList: keywordList });
                    }}
                    onClearClick={() => {
                      const editParams = { ...params };
                      delete editParams.keywordList;
                      setParams(editParams);
                      navigateWithQueryParams({ ...params, page: 0, keywordList: "" });
                    }}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                  />
                </div>
              </section>
            </div>
            <div className="right-area">
              {isAuthority("w") && <BaseButton className="ml20" title="+ 항목 등록" onClick={() => navigate(PagePath.voc.form)} />}
            </div>
          </div>
          <BaseTable
            data={vocList}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
            }}
            pageIndex={Number(queryParams?.page || 0)}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            orders={queryParams?.sort?.orders}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } }, "search");
              }
            }}
            disabledSortHeaders={["csTypeName"]}
            columns={VocColumns}
          />
        </div>
      </div>
    </>
  );
};

export default VocList;
