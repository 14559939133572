import { ReactNode, RefObject, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import useModalObserver from "src/recoil/modal-observer/hooks";
import { isPublicPage } from "src/utils/common-util";
import { BaseButton } from "../components";
interface state {
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  btnLeftTitle?: string;
  btnRightTitle?: string;
  type?: string;
  btnRightType?: "button" | "submit" | "reset" | undefined;
  className?: string;
  title?: string;
  btnRightDisable?: boolean;
}

export const BaseModal = (props: state) => {
  const el = document.getElementById("modal");
  const { pathname } = useLocation();

  const { generateId, subscribe, unsubscribe } = useModalObserver();
  const [modalId] = useState(() => generateId());
  // const [firstButtonRef, setFirstButtonRef] = useState<RefObject<HTMLButtonElement>>();

  useEffect(() => {
    subscribe(modalId, {
      Escape: () => props.onClose && props.onClose({} as React.MouseEvent<HTMLButtonElement>),
      // Enter: () => {
      //   firstButtonRef?.current?.focus();
      // },
    });

    return () => {
      unsubscribe(modalId);
    };
  }, []);

  if (!props.isOpen) return null;

  return createPortal(
    <>
      <div className={isPublicPage(pathname) ? `dim mobile` : "dim"}>
        <div className={`base-modal ${props.className ? props.className : ""}`}>
          {props.title && (
            <div className="base-modal__title keep-all pre-formatted">
              <p>{props.title}</p>
            </div>
          )}
          {props.children && (
            <div className={`${props.title ? "base-modal__contents pt20" : "base-modal__contents"} keep-all`}>
              {props.children && <>{props.children}</>}
            </div>
          )}
          {(props.btnLeftTitle !== undefined || props.btnRightTitle !== undefined) && (
            <div className="base-modal__btn-wrap">
              {props.btnLeftTitle && <BaseButton title={props.btnLeftTitle} onClick={props.onClose} className="color-white mr10" />}
              {props.btnRightTitle && (
                <BaseButton
                  title={props.btnRightTitle}
                  type={props.btnRightType ? props.btnRightType : undefined}
                  onClick={props.onClick}
                  className=""
                  disabled={props.btnRightDisable}
                  // setRef={setFirstButtonRef}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>,
    el!,
  );
};
