import { useEffect, useMemo, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { CsCategoryTypeUnionType } from "src/api/question/question-type";
import { getVocOptionList } from "src/api/voc/voc-api";
import { VocOptionsType } from "src/api/voc/voc-types";

export function useCsOptions(value?: CsCategoryTypeUnionType) {
  // const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [list, setList] = useState<VocOptionsType[]>([]);

  const { executeAsync: getVocOptions } = useApiOperation(getVocOptionList);

  const options: { label: string; value: string }[] = useMemo(() => {
    const _options = list.map((item) => {
      return { label: item.csTypeName, value: String(item.id) };
    });
    return _options;
  }, [list]);

  useEffect(() => {
    const fetchOptions = async () => {
      let result;

      result = await getVocOptions({ csCategoryType: value || "" });
      setList(result.data.data.content);
    };

    fetchOptions(); //TODO:value가 없을 때 전체 목록을 불러오기 위해 수정함
  }, [getVocOptions, value]);

  return { options };
}
