import { Cell } from "react-table";

const BuildingFacilityColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "시설",
    accessor: "commonFacilityType",
    width: 240,
    Cell: ({ row, value }: Cell<any>) => {
      const type = value === "REFRESH_ROOM" ? "편의시설" : value === "DESK" ? "좌석" : "회의실";
      const deskGroupName = row.original.groupName;
      return <span>{`${deskGroupName ? deskGroupName + "/" : ""} ${type}`}</span>;
    },
  },
  {
    Header: "공용공간명",
    accessor: "facilityName",
    width: 320,
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 220,
    Cell: ({ row, value }: Cell<any>) => {
      const notGround = row.original.isGround;
      const floorNum = String(row.original.floorNum).replace("-", "");
      return <span>{notGround ? ` 지상 ${floorNum}층` : `지하 ${floorNum}층`}</span>;
    },
  },
];
export default BuildingFacilityColumns;
