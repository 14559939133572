import { IMenuDoubleSection } from "../types";
import PagePath from "src/pagePath.json";
import { ReservationState } from "src/pages/reservation/reservation-types";

const BASE_PATH = `${PagePath.reservation.list}?`;
const statusPath = (reservationStatus: string) => {
  return `statusCode=${reservationStatus}`;
};
const RESERVATION_MENU_LIST: IMenuDoubleSection[] = [
  {
    type: "bar",
    element: [
      {
        title: "접수",
        count: -1,
        path: BASE_PATH + statusPath(ReservationState.ACCEPTED),
        status: ReservationState.ACCEPTED,
      },
      {
        title: "점유",
        count: -1,
        path: BASE_PATH + statusPath(ReservationState.ACKNOWLEDGED),
        status: ReservationState.ACKNOWLEDGED,
      },
      {
        title: "불완전",
        count: -1,
        path: BASE_PATH + statusPath(ReservationState.INCOMPLETE),
        status: ReservationState.INCOMPLETE,
      },
      {
        title: "취소",
        count: -1,
        path: BASE_PATH + statusPath(ReservationState.CANCELLED),
        status: ReservationState.CANCELLED,
      },
    ],
  },
];

export { RESERVATION_MENU_LIST };
