import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPrImageAsync } from "src/api/product/product-api";
import { MediaFile, MediaServiceType } from "src/api/public-types";
import { BaseButton } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { buildingMediaServiceType } from "../../building-types";
import ImageCategory from "./components/ImageCategory";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const mediaServiceTypes: MediaServiceType[] = buildingMediaServiceType;

const ImageForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //

  const [images, setImages] = useState<Array<MediaFile>>([]);

  // 이미지 불러오기
  const { executeAsync: getBuildingImages } = useApiOperation(getPrImageAsync);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 건물 id
  const buildingId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 이미지 불러오기
  const getBuildingImagesApi = useCallback(
    async (serviceId: number) => {
      //

      const data = {
        serviceId,
        mediaServiceTypes: buildingMediaServiceType as Array<MediaServiceType>,
      };

      const response = await getBuildingImages(data);
      if (response.status >= 200 || response.status < 300) {
        const result = response.data.data.content;

        setImages(result || []);
      }

      //
    },
    [getBuildingImages],
  );

  useEffect(() => {
    if (buildingId) {
      getBuildingImagesApi(buildingId);
    }
  }, [getBuildingImagesApi, buildingId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 건물 / 사무공간 */}
            {mediaServiceTypes &&
              mediaServiceTypes.map((type) => (
                <ImageCategory key={type} type={type} images={images} buildingId={buildingId || 0} getBuildingImagesApi={getBuildingImagesApi} />
              ))}
          </article>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          <BaseButton
            title={"수정취소"}
            className="color-white size-large"
            onClick={() => {
              navigate(`${PagePath.building.detail.replace(":id", String(buildingId))}?tab=image`);
            }}
          />{" "}
        </div>
      </div>
    </>
  );
};
export default ImageForm;
