import { ProductStatusLabels, ProductStatusT } from "src/pages/product/product-types";

const ProductStatusLabel = ({ status }: { status: ProductStatusT }) => {
  return (
    <div>
      <span>{ProductStatusLabels[status]}</span>
    </div>
  );
};
export default ProductStatusLabel;
