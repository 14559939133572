import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { BaseButton, BaseInput, BaseRadio } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";

type Props = {
  overdueChargeStandard: string;
  overdueList: any;
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  viewOnly?: boolean;
  scheduleType?: string;
};

const OverdueFeePopup = (props: Props) => {
  const [type, setType] = useState("");

  const [single, setSingle] = useState<any>([]);
  const [period, setPeriod] = useState<any>([]);

  const clickBtn = useCallback(() => {
    let check: any = [];
    if (type === "PERIOD") {
      check = period.filter((pe: any) => pe.overdueRates !== "" && pe.overdueDays !== "");
    } else {
      check = period.filter((pe: any) => pe.overdueRates !== "" && pe.overdueDays !== "" && pe.overdueId !== "");
    }

    if (props.onClick) {
      const rtn: any = { overdueChargeStandard: type, overdueList: [...single, ...check] };
      props.onClick(rtn);
    }
  }, [period, props, single, type]);

  useEffect(() => {
    if (props.isOpen && props.overdueList) {
      console.log("props.overdueChargeStandard", props.overdueChargeStandard);
      setType(props.overdueChargeStandard);

      const sg: any = props.overdueList.filter((overdue: any) => overdue.overdueChargeStandard === "SINGLE");
      const pr: any = props.overdueList.filter((overdue: any) => overdue.overdueChargeStandard !== "SINGLE");
      if (sg.length === 0) {
        const addObj = {
          isDeleted: false,
          overdueChargeStandard: "SINGLE",
          overdueDays: 0,

          overdueRates: 0,
          cmdType: "C",
        };
        sg.push(addObj);
      }
      setSingle(sg);
      setPeriod(pr);
      console.log(sg, pr, "type: ", type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <BaseAbstractModal size={"medium"} isOpen={props.isOpen}>
      <section className="base-abstract-modal__title">
        <h1>연체 요율 관리</h1>
      </section>

      <section className="px30 pt30 pb20 flex-center-between">
        <p className="font20 font-weight-semibold">부과기준</p>
        <div className="base-square-radio">
          <BaseRadio
            id="temp"
            name="tempName"
            label="일할"
            value="SINGLE"
            checked={type === "SINGLE" ? true : false}
            onChange={(value: boolean) => {
              if (value) {
                setType("SINGLE");
              }
            }}
            disabled={props?.viewOnly}
          />
          {(props?.scheduleType === undefined || props?.scheduleType !== "mnt") && (
            <BaseRadio
              id="temp2"
              name="tempName"
              label="기간별"
              value="PERIOD"
              checked={type === "PERIOD" ? true : false}
              onChange={(value: boolean) => {
                if (value) {
                  setType("PERIOD");

                  if (period.length === 0) {
                    console.log("period === 0");
                    const temp = _.cloneDeep(period);

                    const addObj = {
                      isDeleted: false,
                      overdueChargeStandard: "PERIOD",
                      overdueDays: "",

                      overdueRates: "",
                      cmdType: "C",
                    };
                    temp.push(addObj);
                    console.log("temp", temp);
                    setPeriod(temp);
                  }
                }
              }}
              disabled={props?.viewOnly}
            />
          )}
        </div>
      </section>

      <section className="base-abstract-modal__contents px30">
        {type === "SINGLE" && (
          <section className="pt30 pb20 flex-center-between">
            <p className="font20 font-weight-semibold">연체 요율(%)</p>
            <div className="minmax240">
              <BaseInput
                type="number"
                className="text-center"
                value={single?.[0]?.overdueRates}
                onChange={(value: string) => {
                  const sg = _.cloneDeep(single[0]);
                  sg.overdueRates = value;
                  if (sg.overdueId === undefined || Number(sg.overdueId) === 0) {
                    sg.cmdType = "C";
                  } else {
                    sg.cmdType = "U";
                  }

                  setSingle([sg]);
                }}
                onBlur={() => {
                  const sg = _.cloneDeep(single[0]);

                  if (Number(sg.overdueRates) > 0) {
                    const textArr = sg.overdueRates.split(".");
                    if (textArr.length > 1) {
                      console.log("text", textArr);
                      sg.overdueRates = textArr[0] + "." + textArr[1].substring(0, 2);
                    } else {
                      sg.overdueRates = textArr[0];
                    }
                  }

                  setSingle([sg]);
                }}
                readonly={props?.viewOnly}
              />
            </div>
          </section>
        )}

        {(props.scheduleType !== "mnt" || props.scheduleType === undefined) && type === "PERIOD" && (
          <section className="pt30">
            <div className="flex-center-start pb10">
              <p className="font20 font-weight-semibold ">연체 요율(%)</p>
              <p className="ml10 text-primary3">최대 선택 가능한 일자는 365일입니다.</p>
            </div>
            <div>
              {period.map((of: any, index: number) => {
                if (of.isDeleted === false) {
                  return (
                    <div className="flex-center mt10" key={index}>
                      <div className="w-100">
                        <BaseInput
                          type="number"
                          maxLength={365}
                          className="text-center"
                          // readonly={true}
                          placeholder="연체일수를 입력하세요"
                          name={"overdueDays_" + index}
                          value={String(of?.overdueDays)}
                          onChange={(value: string) => {
                            const temp: any = _.cloneDeep(period);

                            const newTemp: any = temp.map((overdue: any, idx: number) => {
                              if (index === idx) {
                                overdue.overdueDays = value;
                                if (overdue.overdueId === undefined || Number(overdue.overdueId) === 0) {
                                  overdue.cmdType = "C";
                                } else {
                                  overdue.cmdType = "U";
                                }
                              }
                              return overdue;
                            });
                            setPeriod(newTemp);
                          }}
                          disabled={props.viewOnly}
                        />
                      </div>
                      <div className="w-100 ml10">
                        <BaseInput
                          type="number"
                          // readonly={true}
                          className="text-center"
                          placeholder="연체요율을 입력하세요"
                          name={"overdueRates_" + index}
                          value={String(of?.overdueRates)}
                          onChange={(value: string) => {
                            const temp: any = _.cloneDeep(period);
                            const newTemp: any = temp.map((overdue: any, idx: number) => {
                              if (index === idx) {
                                overdue.overdueRates = value;
                                if (overdue.overdueId === undefined || Number(overdue.overdueId) === 0) {
                                  overdue.cmdType = "C";
                                } else {
                                  overdue.cmdType = "U";
                                }
                              }
                              return overdue;
                            });
                            setPeriod(newTemp);
                          }}
                          onBlur={() => {
                            const temp: any = _.cloneDeep(period);
                            const newTemp: any = temp.map((overdue: any, idx: number) => {
                              if (index === idx) {
                                if (Number(overdue.overdueRates) > 0) {
                                  const textArr = overdue.overdueRates.split(".");
                                  if (textArr.length > 1) {
                                    console.log("text", textArr);
                                    overdue.overdueRates = textArr[0] + "." + textArr[1].substring(0, 2);
                                  } else {
                                    overdue.overdueRates = textArr[0];
                                  }
                                }
                              }
                              return overdue;
                            });
                            setPeriod(newTemp);
                          }}
                          disabled={props.viewOnly}
                        />
                      </div>
                      {index === 0 && props?.viewOnly === false && (
                        <button
                          className="base-add-btn ml10"
                          onClick={() => {
                            const temp = _.cloneDeep(period);
                            console.log("period", period);

                            const addObj = {
                              isDeleted: false,
                              overdueChargeStandard: "PERIOD",
                              overdueDays: "",
                              overdueRates: "",
                              // scheduleId: single[0].scheduleId,
                              cmdType: "C",
                            };
                            temp.push(addObj);

                            setPeriod(temp);
                          }}
                        />
                      )}
                      {index !== 0 && props?.viewOnly === false && (
                        <button
                          className="base-trash-btn color-gray ml10"
                          onClick={() => {
                            const temp: any = _.cloneDeep(period);
                            let newTemp: any = [];
                            const find = temp.find((ov: any, idx2: number) => index === idx2);
                            if (find) {
                              if (find.overdueId !== undefined) {
                                newTemp = temp.map((overdue: any, idx: number) => {
                                  if (index === idx) {
                                    overdue.isDeleted = true;
                                    overdue.cmdType = "D";
                                  }
                                  return overdue;
                                });
                              } else {
                                newTemp = temp.filter((ov: any, idx: number) => index !== idx);
                              }
                            }

                            setPeriod(newTemp);
                          }}
                        />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </section>
        )}
      </section>
      <section className="base-abstract-modal__btn-wrap">
        {props.viewOnly !== true && (
          <>
            <BaseButton title={"취소"} className="color-white" onClick={props.onClose} />
            <BaseButton title={"확인"} onClick={() => clickBtn()} />
          </>
        )}
        {props.viewOnly === true && (
          <>
            <BaseButton title={"확인"} onClick={props.onClose} />
          </>
        )}
      </section>
    </BaseAbstractModal>
  );
};

export default OverdueFeePopup;
