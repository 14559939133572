import _ from "lodash";
import { useMemo } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import BaseScroll from "src/components/BaseScroll";
import useApiLoading from "src/hooks/useApiLoading";
import { columns as depositColumns } from "./DepositHistoryColumns";

interface Datas {
  tdData: any;
  setReceiveData: any;
  receiveList: any;
  row: any;
  showBtn: boolean;
  delReceiveList: any;
  setDelReceiveList: any;
  setReceiveList: any;
  contractBaseInfo: any;
}
interface PropObj {
  dataBundle: Datas;
}

const DepositHistoryTable = (props: PropObj) => {
  const { isApiLoading } = useApiLoading();
  const { contractBaseInfo, tdData, setReceiveData, receiveList, row, showBtn, delReceiveList, setDelReceiveList, setReceiveList } = props.dataBundle;

  const checkBalancePrice = useMemo(() => {
    const dump: any = _.cloneDeep(tdData);
    return dump;
  }, [tdData]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: depositColumns,
      data: checkBalancePrice,
      accountList: tdData,
      setReceiveData,
      receiveList,
      mainRow: row,
      showBtn,
      delReceiveList,
      setDelReceiveList,
      setReceiveList,
      contractBaseInfo,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    useRowSelect,
  );
  return (
    <div>
      <div {...getTableProps()} className="base-table sticky ">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th">
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <BaseScroll maxHeight={250}>
          <div {...getTableBodyProps()} className="body overflow-x-hidden">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset">
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{!isApiLoading() && <span className="text-gray500">이 기간동안 입금된 내역이 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </BaseScroll>
      </div>
    </div>
  );
};

export default DepositHistoryTable;
