import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList, ContractStep } from "src/api/contract/contract-types";
import useApiOperation from "src/api/hooks/api-operation";
import { BaseCheckbox, BaseInput, BaseSelect } from "src/components";
import Link from "src/components/PartnerLink";
import TableSelectModal from "src/components/TableSelectModal";
import ContractStatusChip from "src/components/contract/ContractStatusChip";
import { getContractDetailPath } from "src/utils/route-util";

interface Contract {
  contractId: number;
  contractApplyNumber: string;
  contractStep?: ContractStep;
  contractStepFilter?: any;
  spaceProductName: string;
}

interface Props {
  onCanceled: () => void;
  onAdded: (selected: Contract[]) => void;
  defaultChecked: Contract[];
  contractStepFilter?: ContractStep[];
}

interface CellProps extends Cell<Contract> {
  checked: boolean;
}

const CONTRACT_TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const contractApplyNumber = props.row.cells[2].value;
      const finded = props?.row?.original?.contractStepFilter.find((step: string) => step === props.row.original.contractStep);
      let disabled = false;

      if (finded === undefined) {
        disabled = true;
      }
      return (
        <div data-data-id={contractApplyNumber} data-checked={props.value} className="checkbox" data-disabled={disabled}>
          <BaseCheckbox id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "contractId",
    width: 80,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 150,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;

      return (
        <Link to={getContractDetailPath({ contractId: id })} className="text-underline" target="_blank" rel="noreferrer">
          {props.value}
        </Link>
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 225,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 205,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 120,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
  {
    Header: "상태",
    accessor: "contractStep",
    width: 80,
    Cell: (props: CellProps) => <ContractStatusChip status={props.value} />,
  },
];

function ContractSelectModal({ onCanceled, onAdded, defaultChecked, contractStepFilter }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [contractList, setContractList] = useState<ContractManageList[]>([]);
  const [selectedContractList, setSelectedContractList] = useState<Contract[]>(defaultChecked);
  const { handleSubmit, getValues, control } = useForm<{ searchWord: string; availableFilterList: string }>();
  const [size, setSize] = useState(20)
  const searchFormRef = useRef<HTMLFormElement>(null);

  const contractListTableData = contractList.map((contract) => {
    const selectedIdList = selectedContractList?.map((contract) => contract.contractApplyNumber);

    return {
      checked: selectedIdList.includes(contract.contractApplyNumber),
      ...contract,
      contractStepFilter,
    };
  });

  const { executeAsync: getContractApplyAsync } = useApiOperation(getContractApply);

  const search = ({ searchWord, contractStep, page, size }: { page: number; size: number; searchWord?: string; contractStep?: string }) => {
    fetchContractList({ page, searchWord, size, contractStep });
    setSize(size);
  };

  const onSubmit = ({ searchWord }: { searchWord: string }) => search({ page: 0, searchWord, size, contractStep: getValues("availableFilterList") });
  const goPage = (nextPage: number) =>
    search({ page: nextPage, size, searchWord: getValues("searchWord"), contractStep: getValues("availableFilterList") });

    const fetchContractList = async ({ searchWord, contractStep, page, size }: { page: number; size?: number; searchWord?: string; contractStep?: string }) => {
      const result = await getContractApplyAsync({
        page,
        searchValue: searchWord,
        size,
        contractStep,
        sort: {
          orders: [
            {
              property: "contractManageId",
              direction: "DESC",
            },
          ],
        },
      });

    setContractList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const selectContract = (contractApplyNumber: string) => {
    const selectedContract = contractList.find((contract) => String(contract.contractApplyNumber) === contractApplyNumber);
    setSelectedContractList([
      ...selectedContractList,
      {
        contractId: selectedContract?.contractId || 0,
        contractApplyNumber: selectedContract?.contractApplyNumber || "",
        spaceProductName: selectedContract?.spaceProductName || "",
        contractStep: selectedContract?.contractStep as ContractStep,
      },
    ]);
  };
  const unSelectContract = (contractApplyNumber: string) => {
    const filteredContractList = selectedContractList.filter((contract) => String(contract.contractApplyNumber) !== contractApplyNumber);
    setSelectedContractList(filteredContractList);
  };
  const _onAdded = () => onAdded(selectedContractList);

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchContractList({
      page: 0,
      size,
      contractStep: "",
      searchWord: "",
    });
  }, []);

  return (
    <TableSelectModal<ContractManageList>
      className=""
      onCanceled={onCanceled}
      onAdded={_onAdded}
      goPage={goPage}
      page={page}
      columns={CONTRACT_TABLE_COLUMNS}
      items={contractListTableData}
      title={"신청/계약"}
      select={selectContract}
      unSelect={unSelectContract}
      sizeOption={(size) => {
        setSize(size);
        search({ page: 0, size: size, searchWord: getValues("searchWord"), contractStep: getValues("availableFilterList") });
      }}
      currentSize={size}
      titleFormRender={() => {
        return (
          <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef} className="flex-center-center">
            <Controller
              name="availableFilterList"
              control={control}
              render={({ field }) => (
                <BaseSelect
                  className="mr8 minmax140"
                  value={field.value}
                  stateOptions={[
                    {
                      label: "전체",
                      value: "",
                    },
                    {
                      label: "사용가능",
                      value: contractStepFilter?.join(",") || "",
                    },
                  ]}
                  setStateValue={(value: string) => {
                    search({ page: 0, size, contractStep: value, searchWord: getValues("searchWord") });
                    field.onChange(value);
                  }}
                />
              )}
            />
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                  onKeyUp={handleSearchClick}
                  clearable
                />
              )}
            />
          </form>
        );
      }}
    />
  );
}
export default ContractSelectModal;
