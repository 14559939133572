import { useCallback, useMemo } from "react";
import { Cell } from "react-table";
import { MgmtOfficeModel, MgmtOfficerType } from "src/api/building/building-types";
import { BaseButton, BaseTooltip } from "src/components";
import InnerTable from "src/components/InnerTable";
import { officerTypeList } from "src/pages/building/forms/mgmtOffice/management-type";
import { useModal } from "src/recoil/modalState/hook";
import { formatPhoneNumber } from "src/utils";
import { MgmtSectionType } from "../MgmtOfficeDetail";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  managementDetail: MgmtOfficeModel;
  handleOpenSectionForm: (type: MgmtSectionType, detail?: MgmtOfficeModel) => void;
  handleDeleteTableRow: (type: MgmtSectionType, body: MgmtOfficerType[]) => Promise<void>;
  isAuthority: { editable: boolean; deletable: boolean };
};

const MgmtOfficerSection = ({ managementDetail, handleOpenSectionForm, handleDeleteTableRow, isAuthority }: Props) => {
  const { setBaseModal } = useModal();

  const filteredOfficer = useMemo(
    () => managementDetail?.officerList?.filter((item) => item.isDeleted === false) || [],

    [managementDetail?.officerList],
  );

  const getOfficeWorkType = useCallback((workTypes: string[]) => {
    if (workTypes) {
      const changeValue = workTypes.map((type) => {
        officerTypeList.forEach((item) => {
          if (item.value === type) {
            type = item.label;
          }
          if (type === "OFFICER_UNRECOGNIZED") {
            type = "-";
          }
        });

        return type;
      });
      return changeValue;
    }
  }, []);

  const columns: any = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 50,
      },
      {
        Header: "이름(닉네임)",
        accessor: "name",
        width: 170,
      },
      {
        Header: "휴대폰 번호",
        accessor: "phone",
        width: 120,
        Cell: ({ row, value }: Cell<MgmtOfficerType>) => {
          return formatPhoneNumber(value);
        },
      },
      {
        Header: "매니저",
        accessor: "isPrimary",
        width: 90,
        Cell: ({ row, value }: Cell<MgmtOfficerType>) => {
          const isManager = row.original.roleType === "ROLE_MANAGER";
          return <span>{value && isManager ? "대표, 매니저" : !value && isManager ? "매니저" : "-"}</span>;
        },
      },
      {
        Header: "업무파트",
        accessor: "officerTypeList",
        width: 260,
        Cell: ({ value }: Cell<MgmtOfficerType>) => {
          return getOfficeWorkType(value)?.join(", ");
        },
      },
      {
        Header: "세부 업무내용",
        accessor: "description",
        width: 220,
        Cell: ({ value }: Cell<MgmtOfficerType>) => {
          return <BaseTooltip className="text-left w-100" isSingleLine contents={value || "-"} />;
        },
      },
      {
        Header: "수정",
        width: 80,
        Cell: ({ row }: Cell<MgmtOfficerType>) => {
          return <BaseButton className="size-small color-white" title={"수정"} onClick={() => handleOpenSectionForm("officer", row.original)} />;
        },
      },
      {
        Header: "삭제",
        width: 80,
        Cell: ({ row }: Cell<MgmtOfficerType>) => {
          return (
            <BaseButton
              title=""
              className="base-trash-btn color-gray border-none"
              onClick={() => {
                const deleteData: MgmtOfficerType = { ...row.original, cmdType: "D" };
                const primaryLength = filteredOfficer.filter((item: MgmtOfficerType) => item.isPrimary === true).length;
                if (primaryLength === 1 && deleteData.isPrimary === true) {
                  setBaseModal({
                    isOpen: true,
                    title: "선택하신 담당자를 삭제할 수 없습니다.",
                    btnRightTitle: "확인",
                    children: <p>1명 이상의 대표 담당자가 필요합니다.</p>,
                    onClick: () => setBaseModal({ isOpen: false }),
                  });
                  return;
                }

                setBaseModal({
                  isOpen: true,
                  title: "담당자를 삭제하시겠습니까?",
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                  onClick: () => {
                    handleDeleteTableRow("officer", [deleteData]);
                    setBaseModal({ isOpen: false });
                  },
                  onClose: () => setBaseModal({ isOpen: false }),
                });
              }}
            />
          );
        },
      },
    ],
    [filteredOfficer, getOfficeWorkType, handleDeleteTableRow, handleOpenSectionForm, setBaseModal],
  );

  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title={"담당자"} />
        {isAuthority.editable && (
          <BaseButton title="등록" disabled={!managementDetail.id && true} className="size-small" onClick={() => handleOpenSectionForm("officer")} />
        )}
      </div>
      <InnerTable
        data={filteredOfficer}
        columns={columns.filter((column: any) => {
          let returnColumn = column;
          if (!isAuthority.editable) {
            returnColumn = column.Header !== "수정";
          }
          if (!isAuthority.deletable) {
            returnColumn = column.Header !== "삭제";
          }
          if (!isAuthority.editable && !isAuthority.deletable) {
            returnColumn = column.Header !== "수정" && column.Header !== "삭제";
          }
          return returnColumn;
        })}
        className="overflow-x-hidden"
      />
    </article>
  );
};

export default MgmtOfficerSection;
