import { ProviderContactModel } from "src/api/provider/provider-types";

export interface ProviderContactFormType {
  customerContact: ProviderContactModel;
  contractContactList: Array<ProviderContactModel>;
  billingContact: ProviderContactModel;
}

export const ProviderTypeOptions = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "RENTAL",
    label: "프로바이더",
  },
  {
    value: "MNT",
    label: "관리처",
  },
];
