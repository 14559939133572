import _ from "lodash";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { editGroupItem } from "src/api/chargeItems/chargeItems-api";
import { getCCM23, getCCM25, saveCCM24 } from "src/api/chargeMnt/chargeMnt-api";
import { ctMntChartTotal } from "src/api/chargeMnt/chargeMnt-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { columns } from "src/pages/chargeMnt/form/components/columns/OverallColumns";
import { PagePath } from "src/pages/product/details";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import * as xlsx from "xlsx";

const Overall = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openErrorModal } = useErrorModal();
  const { openToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");

  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  const [stateOptions2, setStateOptions2] = useState<any>([]);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );
  const fileRef = useRef<HTMLInputElement>(null);
  let id = queryParams.id ? queryParams.id : useParams.prototype.id;

  const { executeAsync: getGroupDetail } = useApiOperation(getCCM23);
  const { executeAsync: getGroupItems } = useApiOperation(getCCM25);
  const { executeAsync: addItemOrUpdate } = useApiOperation(saveCCM24);
  const { executeAsync: editItem } = useApiOperation(editGroupItem);

  const groupDetail = useCallback(
    async (chartId: number) => {
      const response: any = await getGroupDetail({ chartId });
      console.log(response.data.data.ctMntChartBundle);
      if (response.status >= 200 && response.status <= 299) {
        setTitle(response.data.data.ctMntChartBundle.groupName);
        const st =
          response.data.data.ctMntChartBundle.ctMntChart.mntStatus === "READY"
            ? "대기"
            : response.data.data.ctMntChartBundle.ctMntChart.mntStatus === "PROGRESS"
            ? "진행"
            : "완료";
        setStatus(st);
      }
    },
    [getGroupDetail, openErrorModal],
  );
  const [formData, setFormData] = useState<ctMntChartTotal>({
    itemId: "",
    itemName: "",
    chartItemId: "",
    priceUnitName: "",
    roundingMode: "",
    roundingModeName: "",
    level1: "",
    level1Name: "",
    level2: "",
    level2Name: "",
    diffMonthlyAmount: "",
    ctMntChartItem: {},
    editable: false,
    isEdited: false,
  });
  const [tableData, setTableData] = useState<any>([]);

  const itemList = useCallback(async () => {
    const response: any = await getGroupItems({ chartId: id });

    for (let index = 0; index < response.data.data.ctMntChartItemBundleList.length; index++) {
      const element = response.data.data.ctMntChartItemBundleList[index];
      element.successFlag = false;
      element.loadingFlag = false;
      element.failFlag = false;
    }
    setTableData(response.data.data.ctMntChartItemBundleList);
  }, [getGroupItems, id]);

  useEffect(() => {
    if (id) {
      itemList();
      groupDetail(id);
    }
  }, [id]);

  const moveDetail = useCallback(() => {
    const path =
      PagePath.chargeMnt.detail.replace(":id", id) + "?" + decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [id, navigate, queryParams]);

  const clearForm = useCallback(
    (itemId?: number) => {
      setStateOptions2([]);
      const temp = _.cloneDeep(tableData);
      const changed = temp.map((data: any) => {
        if (itemId && itemId === data.itemId) {
          data.isEdited = true;
        } else {
          data.isEdited = false;
        }

        return data;
      });
      console.log();
      setTableData(changed);
      setFormData({});
      setEditAlert(false);
    },
    [tableData],
  );

  const setFlagChange = useCallback(
    (itemId: string, loadingFlag: boolean, successFlag: boolean, failFlag: boolean, time?: number) => {
      setTimeout(() => {
        const temp = _.cloneDeep(tableData);
        let i = 0;
        let saveObj: any = {};
        for (let index = 0; index < temp.length; index++) {
          let element = _.cloneDeep(temp[index]);
          if (element.itemId === itemId) {
            i = index;
            element.loadingFlag = loadingFlag;
            element.successFlag = successFlag;
            element.failFlag = failFlag;
            saveObj = element;
          }
        }
        temp[i] = saveObj;

        setTableData(temp);
      }, time);
    },
    [tableData, setTableData],
  );

  const insertOrUpdate = useCallback(
    async (formData: ctMntChartTotal) => {
      return await addItemOrUpdate({
        chartId: id,
        ctMntChartItemList: [formData.ctMntChartItem],
      });
    },
    [addItemOrUpdate, id],
  );

  const checkFormData = useCallback((formData: ctMntChartTotal) => {
    let checker = false;
    console.log("");
    if (formData.itemId === undefined || formData.ctMntChartItem?.occurSupplyAmount === "") {
      checker = true;
    }
    return checker;
  }, []);

  const saveItem = useCallback(
    async (formData: any) => {
      setIsOpen(false);
      if (checkFormData(formData) === true) {
        console.log("작성 안한 내용이 있다.");
      } else {
        clearForm();
        setFlagChange(String(formData.itemId), true, false, false, 1000);
        const response: any = await insertOrUpdate(formData);
        if (response.status > 199 && response.status < 300) {
          openToast({ content: "정상적으로 저장 되었습니다." });
          setFlagChange(String(formData.itemId), false, true, false, 1000);
        }
        setTimeout(async () => setFlagChange(String(formData.itemId), false, false, false), 3000);
        itemList();
        //
      }
    },
    [checkFormData, clearForm, insertOrUpdate, itemList, openToast, setFlagChange],
  );

  const saveAllItem = useCallback(
    async (tableData: ctMntChartTotal[]) => {
      setIsOpen2(false);

      let cl = _.cloneDeep(tableData);
      let sorted = _.sortBy(cl, "itemId");

      for (let index = 0; index < sorted.length; index++) {
        const element = sorted[index];
        element.loadingFlag = true;
      }

      setTableData(sorted);

      for (let index = 0; index < tableData.length; index++) {
        let element = sorted[index];

        const response: any = await insertOrUpdate(element);
        if (response.status > 199 && response.status < 300) {
          openToast({ content: "정상적으로 저장 되었습니다." });

          console.log("success", String(element.itemId));
          setFlagChange(String(element.itemId), false, true, false, 1000);

          setFlagChange(String(element.itemId), false, false, true, 1000);
        }

        setFlagChange(String(element.itemId), false, false, false, 3000);
      }

      // itemList();
      clearForm();
    },
    [clearForm, insertOrUpdate, openToast, setFlagChange],
  );
  // console.log("table", tableData);
  const moveList = useCallback(() => {
    setIsOpen4(false);
    const path = PagePath.chargeItems.list;
    navigate(path);
  }, [navigate]);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let arrayValues: any = [];
        console.log("json", json);
        const temp = _.cloneDeep(tableData);

        json.map((obj: any, index: number) => {
          arrayValues.push({
            id: obj.id,
            ctMntChartItem: { occurSupplyAmount: obj["당월_발생금액"] },
          });
        });
        let changeList: any = [];

        temp.map((td: any) => {
          const finded: any = arrayValues.find((obj: any) => obj.id === td.itemId);
          let change = _.cloneDeep(td);
          if (finded) {
            change.ctMntChartItem.occurSupplyAmount = finded.ctMntChartItem.occurSupplyAmount;
            change.isEdited = true;
          }
          changeList.push(change);
          return change;
        });
        console.log("changeList", changeList);

        setTableData(changeList);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const download = useCallback(() => {
    const book = xlsx.utils.book_new();
    const header = {
      A: "id",
      B: "부과항목",
      C: "부과기준(부과방식/산정기준)",
      D: "부과방법(단위범위/반올림)",
      E: "당월_발생금액",
      F: "당월_부과금액",
      G: "당월_차액",
      H: "전월_발생금액",
      I: "전월_부과금액",
      J: "전월_차액",
      K: "월간차액(단위범위/반올림)",
    };
    const downloadData: any = [header];
    tableData.map((data: any) => {
      const dl: any = {
        A: data.itemId,
        B: data.itemName,
        C: data.priceUnitName + " / " + data.roundingModeName,
        D: data.level1Name + " / " + data.level2Name,
        E: data.ctMntChartItem.occurSupplyAmount,
        F: data.ctMntChartItem.impsSupplyAmount,
        G: data.ctMntChartItem.diffSupplyAmount,
        H: data.ctPrevMntChartItem.occurSupplyAmount,
        I: data.ctPrevMntChartItem.impsSupplyAmount,
        J: data.ctPrevMntChartItem.diffSupplyAmount,
        K: data.diffMonthlyAmount,
      };
      downloadData.push(dl);
    });

    const nurses = xlsx.utils.json_to_sheet(downloadData, {
      header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
      skipHeader: true,
    });

    xlsx.utils.book_append_sheet(book, nurses, "DOCTOR");
    xlsx.writeFile(book, "dramatis_personae.xlsx");
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 1000 },
      formData,
      setFormData,
      setTableData,
      itemList,

      clearForm,
      chartId: id,
      setFlagChange,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  const footerGroups = headerGroups.slice().reverse();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let checker = false;
    tableData.map((data: any) => {
      if (data.isEdited) {
        checker = true;
      }
    });
    setEditAlert(checker);
  }, [tableData]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 세부 부과항목 */}
            <section className="">
              {/* 첫째줄 폼 */}
              <table className="inner-table mb20" width="940">
                <thead>
                  <tr>
                    <th>
                      <div>부과항목</div>
                    </th>
                    <th>
                      <div>부과 기준</div>
                    </th>
                    <th>
                      <div>부과방법</div>
                    </th>
                    <th>
                      <div>당월발생금액</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <BaseInput type="text" readonly value={formData?.itemName} />
                    </td>
                    <td>
                      <BaseInput
                        type="text"
                        readonly
                        value={
                          formData?.priceUnitName
                            ? formData?.roundingModeName && formData?.roundingModeName !== "-"
                              ? formData?.priceUnitName + " / " + formData?.roundingModeName
                              : formData?.priceUnitName
                            : ""
                        }
                      />
                    </td>
                    <td>
                      <BaseInput
                        type="text"
                        readonly
                        value={
                          formData?.level1Name
                            ? formData?.level2Name && formData?.level2Name !== "-"
                              ? formData?.level1Name + " / " + formData?.level2Name
                              : formData?.level1Name
                            : ""
                        }
                      />
                    </td>
                    <td>
                      <BaseInput
                        type="number"
                        value={formData?.ctMntChartItem?.occurSupplyAmount}
                        onChange={(value: number) => {
                          const temp = _.cloneDeep(formData);
                          if (temp.ctMntChartItem) {
                            temp.ctMntChartItem.occurSupplyAmount = String(value);
                          }
                          setFormData(temp);
                        }}
                      />
                    </td>

                    <td width="100">
                      <div className="flex-center">
                        <BaseButton title="저장" className="mr2" onClick={() => setIsOpen(true)} />
                        <BaseButton title="취소" className="color-white" onClick={() => clearForm()} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <section className="py16 flex-center-between contents-container__1200">
                <div className="flex-center font12">
                  <div className="base-index-dot color-blue mr16">
                    <p>수정한 항목</p>
                  </div>
                  <div className="base-index-dot color-red mr20">
                    <p>삭제한 항목</p>
                  </div>
                  {editAlert && <p className="text-red900">* 수정 후 저장하지 않은 항목이 있습니다</p>}
                </div>
                <div className="flex-center">
                  <button type="button" className="base-refresh-btn pa20" onClick={() => itemList()} />
                  <button type="button" className="base-file-btn pa20" onClick={() => fileRef.current?.click()} />
                  <input
                    type="file"
                    name="upload"
                    id="upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={fileRef}
                    onChange={readUploadFile}
                    style={{ display: "none" }}
                  />
                  <button type="button" className="base-file-btn pa20 ic-down" onClick={() => download()} />
                </div>
              </section>

              {/* 2depth 테이블 */}
              <div className="inner-tab-table two-depth-header">
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header: any) => {
                          // sticky 기준을 맞추기 위해 rowspan 처럼 보이는 헤더는 무조건 columns를 1개 가지고 있어야함.
                          // 스티키가 아닌 rowspan 헤더 케이스
                          if (!header.parent && header.depth === 0 && header.columns?.length === 1 && !header.sticky) {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                <div className="w-100 no-parent" style={{ zIndex: 2 }}>
                                  {header.render("Header")}
                                </div>
                              </div>
                            );
                          } else if (header.sticky === "left" || header.sticky === "right") {
                            // 스티키 상태인 rowspan 케이스
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th" style={{ ...header.getHeaderProps().style, zIndex: 4 }}>
                                <div className="w-100 sticky-parent" style={{ zIndex: 2 }}>
                                  {header.render("Header")}
                                </div>
                                {/* <div className="ic_sort"></div> */}
                              </div>
                            );
                          } else {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                <div className=" w-100 ">{header.render("Header")}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {page.map((row: any) => {
                      prepareRow(row);

                      return (
                        <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""} `}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div
                                {...cell.getCellProps()}
                                className={`base-table__td ${row.original.isEdited ? "bg-blue200" : ""}${
                                  row.original.ctMntChartItem.isDeleted ? "bg-red100" : ""
                                }`}
                              >
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <div className="footer">
                    {footerGroups.map((footerGroup: any, index: number) => {
                      if (index === 0) {
                        return (
                          <div {...footerGroup.getFooterGroupProps()} className="base-table__tr">
                            {footerGroup.headers.map((column: any) => (
                              <div {...column.getFooterProps()} className="sticky base-table__td base-table-footer">
                                {column.render("Footer")}
                              </div>
                            ))}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </section>
          </article>
          <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => saveItem(formData)} onClose={() => setIsOpen(false)}>
            <p>저장 하시겠습니까?</p>
          </BaseModal>

          <BaseModal
            isOpen={isOpen2}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => saveAllItem(tableData)}
            onClose={() => setIsOpen2(false)}
          >
            <p>저장 하시겠습니까?</p>
          </BaseModal>

          <BaseModal isOpen={isOpen4} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => moveList()} onClose={() => setIsOpen4(false)}>
            <p>목록으로 이동합니다. 미 저장 시 정보가 초기화 되어 저장됩니다.</p>
          </BaseModal>
          <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => moveDetail()} onClose={() => setIsOpen3(false)}>
            <p>상세페이지로 이동하시겠습니까?</p>
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => setIsOpen4(true)} />
        </div>
        <div className="right-area">
          <BaseButton title="수정 취소" className="color-white size-large" onClick={() => setIsOpen3(true)} />
          <BaseButton title="저장" className=" size-large ml10" onClick={() => setIsOpen2(true)} />
        </div>
      </div>
    </>
  );
};
export default Overall;
