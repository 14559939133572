import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { ContractDetail } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { PagePath } from "src/pages/product/details";
import { calculatePyeong, calculateRateOfUse, numberToStringWithComma } from "src/utils";
import BaseSection from "../../../../components/layout/BaseSection";
import BuildingManagementTable from "./BuildingManagementTable";

interface BasicProps {
  detailData: any;
  contract: ContractDetail;
  managementBuildingData: any;
  fetchApi: Function;
}

export const columns: Array<any> = [
  {
    Header: "대표",
    accessor: "isPrimary",
    Cell: ({ value }: any) => {
      return <div>{value ? "대표" : "-"}</div>;
    },
    width: 80,
  },
  {
    Header: "건물 ID",
    accessor: "buildingId",

    width: 120,
  },
  {
    Header: "건물 명",
    accessor: "buildingName",
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);
      //새탭열기+튤팁+말줄임+2줄
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
    width: 280,
  },
  {
    Header: "주소",
    accessor: "address",
    Cell: ({ value, row }: Cell<any>) => {
      return <p>{value}</p>;
    },
    width: 380,
  },
  // {
  //   Header: "호실",
  //   accessor: "roomNum",
  //   width: 90,
  //   Cell: ({ value }: any) => {
  //     if (value < 0) {
  //       return <span>{String(value).replace("-", "")}</span>;
  //     } else {
  //       return <span>{value}</span>;
  //     }
  //   },
  // },
  // {
  //   Header: "층",
  //   accessor: "floorNum",
  //   width: 90,
  //   Cell: ({ value }: any) => {
  //     if (value < 0) {
  //       return <span>지하 {String(value).replace("-", "")}</span>;
  //     } else {
  //       return <span>지상 {value}</span>;
  //     }
  //   },
  // },
];

const BuildingManagementDetail = ({ detailData, contract, managementBuildingData, fetchApi }: BasicProps) => {
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <div>
      <div className="flex-center">
        <BaseSection title={"건물"} children={<></>} />
      </div>
      <BuildingManagementTable
        onSelectedRowsChange={setSelectedRow}
        selectedRow={selectedRow}
        readOnly={true}
        detailColumn={columns}
        viewTable={managementBuildingData.filter((building: any) => !building.isDeleted) || []}
      />
    </div>
  );
};
export default BuildingManagementDetail;
