import React, { useCallback, useEffect, useRef, useState } from "react";
import { DeskItem } from "src/pages/building/building-types";

type Props = {
  previewContainerRef: React.RefObject<HTMLDivElement>;
  item: DeskItem;
  setDeskItem: (deskItem: DeskItem) => void;
};

/* 
  건물 > 등록 or 수정 > 좌석 배치도 > 좌석 아이템
 */
const DeskItemComponent = ({ previewContainerRef, item, setDeskItem }: Props) => {
  const dragComponentRef = useRef<HTMLDivElement>(null);
  const [originPos, setOriginPos] = useState({ x: 0, y: 0 }); // 드래그 전 XY값 (e.target.offset 상대 위치)
  const [clientPos, setClientPos] = useState({ x: 0, y: 0 }); // 실시간 커서위치인 e.client를 갱신하는값
  const [pos, setPos] = useState({ left: item.left || 0, top: item.top || 0 }); // 실제 desc 컴포넌트가 위치하는 포지션값

  useEffect(() => {
    if (item) {
      setPos({ left: item.left || 0, top: item.top || 0 });
    }
  }, [item]);

  const compWidth = dragComponentRef.current?.offsetWidth;
  const compHeight = dragComponentRef.current?.offsetHeight;

  // 드래그 시작
  const dragStartHandler = (e: any) => {
    const blankCanvas: any = document.createElement("canvas");
    blankCanvas.classList.add("canvas");
    e.dataTransfer?.setDragImage(blankCanvas, 0, 0);
    document.body?.appendChild(blankCanvas); // 드래그시 크롬 글로벌 아이콘 제거
    document.body.style.overflow = "hidden";
    e.dataTransfer.effectAllowed = "move"; // 기본 고스트 그린+아이콘 제거
    const originPosTemp = { ...originPos };
    originPosTemp["x"] = e.target.offsetLeft;
    originPosTemp["y"] = e.target.offsetTop;
    setOriginPos(originPosTemp); //드래그 시작할때 드래그 전 위치값을 저장

    const clientPosTemp = { ...clientPos };
    clientPosTemp["x"] = e.clientX;
    clientPosTemp["y"] = e.clientY;
    setClientPos(clientPosTemp);
  };

  // 드래그 중
  const dragHandler = useCallback(
    (e: any) => {
      const posTemp = { ...pos };
      posTemp["left"] = e.target.offsetLeft + e.clientX - clientPos.x;
      posTemp["top"] = e.target.offsetTop + e.clientY - clientPos.y;
      setPos(posTemp);

      const clientPosTemp = { ...clientPos };
      clientPosTemp["x"] = e.clientX;
      clientPosTemp["y"] = e.clientY;
      setClientPos(clientPosTemp);
    },
    [pos, clientPos],
  );

  // 드래그 겹칠때
  const dragOverHandler = (e: any) => {
    e.preventDefault(); // 마우스 뗐을때 고스트이미지 날아가는 잔상 제거
  };

  const isInsideDragArea = (e: any) => {
    const draggableArea = previewContainerRef.current?.getBoundingClientRect()!;
    const rectComponent = dragComponentRef.current?.getBoundingClientRect()!;
    let shiftXLeft = 0; // 커서 클릭 위치와 dragComponentRef x 좌측 사이값  (XLeft + Xright = 컴포넌트 가로사이즈)
    let shiftXRight = 0; // 커서 클릭 위치와 dragComponentRef x 우측 사이값
    let shiftYTop = 0; // 커서 클릭 위치와 dragComponentRef y 위 사이값 (YTop + YBottom = 컴포넌트 세로사이즈)
    let shiftYBottom = 0; // 커서 클릭 위치와 dragComponentRef y 아래 사이값
    shiftXLeft = e.clientX - rectComponent.left;
    shiftXRight = rectComponent.right - e.clientX;
    shiftYTop = e.clientY - rectComponent.top;
    shiftYBottom = rectComponent.bottom - e.clientY;
    if (compWidth !== null && compHeight !== null) {
      if (
        draggableArea.left < e.clientX - shiftXLeft &&
        e.clientX + shiftXRight < draggableArea.right &&
        draggableArea.top < e.clientY - shiftYTop &&
        e.clientY + shiftYBottom < draggableArea.bottom
      ) {
        return true;
      } else return false;
    }
  };

  // 드래그 끝
  const dragEndHandler = useCallback(
    (e: any) => {
      let posTemp = { ...pos };
      if (!isInsideDragArea(e)) {
        // 박스영역 밖 드래그 시도시 이전값으로
        posTemp["left"] = originPos.x;
        posTemp["top"] = originPos.y;
        setPos(posTemp);
      }
      // 드래그 시작했을때 글로벌 아이콘 숨기려고 생성한 가상의 캔버스 제거
      const canvases = document.getElementsByClassName("canvas");
      for (let i = 0; i < canvases.length; i++) {
        let canvas = canvases[i];
        canvas.parentNode?.removeChild(canvas);
      }
      document.body.removeAttribute("style");
      // 부모 컴포넌트에 전달
      setDeskItem({ ...item, ...{ left: posTemp.left, top: posTemp.top } });
    },
    [pos, clientPos, item],
  );

  return (
    <div
      className="desc-comp"
      ref={dragComponentRef}
      draggable
      onDragStart={(e) => dragStartHandler(e)}
      onDrag={(e) => dragHandler(e)}
      onDragOver={(e) => dragOverHandler(e)}
      onDragEnd={(e) => dragEndHandler(e)}
      style={{ left: pos.left, top: pos.top }}
    >
      {item.name}
    </div>
  );
};
export default DeskItemComponent;
