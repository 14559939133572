import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { checkPayletterApiConfirm } from "src/api/provider/provider-api";
import { ProviderDetailModel, ProviderPayletterAddModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BaseToggle } from "src/components";
import { Modal } from "src/pages/product/product-types";
import { useToast } from "src/recoil/toast/hook";

type Props = {
  providerId: number;
  providerDetail?: ProviderDetailModel;
  providerDetailApi: Function;
  postProviderPayletter: Function;
  editProviderPayletter: Function;
};

const BasicPayletter = ({ providerId, providerDetail, providerDetailApi, postProviderPayletter, editProviderPayletter }: Props) => {
  // 토스트
  const { openToast } = useToast();

  const [isBasicPayletter, setIsBasicPayletter] = useState(false);

  const [submitData, setSubmitData] = useState<ProviderPayletterAddModel>();

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 경고창 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 페이레터 api 검증

  const { executeAsync: checkPayletterApi } = useApiOperation(checkPayletterApiConfirm);

  // useForm default
  const defaultValues = useMemo(() => {
    const providerPostData: ProviderPayletterAddModel = {
      providerPayletter: {
        providerId,
        clientId: "",
        paymentKey: "",
        searchKey: "",
        autoClientId: "",
        autoPaymentKey: "",
        autoSearchKey: "",
        isVerifiedAutoPaymentKey: false,
        isVerifiedAutoSearchKey: false,
        isVerifiedPaymentKey: false,
        isVerifiedSearchKey: false,
      },
    };

    return providerPostData;
  }, [providerId, providerDetail?.providerPayletter]);

  const { control, setValue, register, handleSubmit } = useForm<ProviderPayletterAddModel>({
    defaultValues,
  });

  // 프로바이더 상세에서 페이레터 정보 setValue
  const onSetValuePayletter = useCallback(() => {
    if (providerDetail?.providerPayletter?.clientId) {
      setIsBasicPayletter(true);
    } else {
      setIsBasicPayletter(false);
    }
    setValue("providerPayletter", providerDetail?.providerPayletter);
  }, [providerDetail?.providerPayletter, setValue]);

  useEffect(() => {
    onSetValuePayletter();
  }, [onSetValuePayletter]);

  // 페이레터 유효성 검사
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다.";

    // 카드결제 지원 (payletter)
    if (isBasicPayletter) {
      register("providerPayletter.clientId", {
        required: { value: true, message: requiredMessage },
      });
      register("providerPayletter.paymentKey", {
        required: { value: true, message: requiredMessage },
      });
      register("providerPayletter.searchKey", {
        required: { value: true, message: requiredMessage },
      });
    }
  }, [isBasicPayletter, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 프로바이더 페이레터 등록/수정 api
  const updatePayletterApi = useCallback(
    async (data?: ProviderPayletterAddModel, reset?: boolean) => {
      const checkProviderId = providerDetail?.providerPayletter?.providerId;
      let basicPayletterData: ProviderPayletterAddModel = {};

      // checkProviderId === "0" 이면 신규등록
      if (String(checkProviderId) !== "0") {
        //
        if (reset === true) {
          //reset - 자동결제 데이터가 있는데 토글 닫을 시 등록된 데이터 초기화
          basicPayletterData = defaultValues;
          //
        } else {
          basicPayletterData = {
            providerPayletter: {
              ...data?.providerPayletter,
              autoClientId: providerDetail?.providerPayletter?.autoClientId,
              autoPaymentKey: providerDetail?.providerPayletter?.autoPaymentKey,
              autoSearchKey: providerDetail?.providerPayletter?.autoSearchKey,
            },
          };
        }

        const response = await editProviderPayletter(basicPayletterData);
        if (response.status >= 200 || response.status <= 299) {
          providerDetailApi(providerId);
          setConfirmModal({ isOpen: false });
          openToast({ content: `정상적으로 수정되었습니다.` });
        }
      } else {
        delete data?.providerPayletter?.providerId;

        const newPayletterData = { providerId, ...data };

        const response = await postProviderPayletter(newPayletterData);
        if (response.status >= 200 || response.status <= 299) {
          providerDetailApi(providerId);
          setConfirmModal({ isOpen: false });
          openToast({ content: `정상적으로 저장되었습니다.` });
        }
      }
    },
    [
      defaultValues,
      editProviderPayletter,
      isBasicPayletter,
      openToast,
      postProviderPayletter,
      providerDetail?.providerPayletter?.providerId,
      providerDetailApi,
      providerId,
    ],
  );

  //  유효성 검사후 저장
  const onSubmit = useCallback(async (data?: ProviderPayletterAddModel, e?: any) => {
    console.log("data", data);
    e.preventDefault();
    setSubmitData(data);
    setConfirmModal({ isOpen: true, message: "저장하시겠습니까?" });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  // 페이레터 검증
  const payletterApiCheck = useCallback(
    async (id: number) => {
      const clientId = providerDetail?.providerPayletter?.clientId;
      const paymentKey = providerDetail?.providerPayletter?.paymentKey;
      const searchKey = providerDetail?.providerPayletter?.searchKey;

      if (!clientId || !paymentKey || !searchKey) {
        setAlertModal({ isOpen: true, message: "페이레터 정보를 저장하세요." });
        return;
      }

      if (id) {
        const response = await checkPayletterApi({ providerId: id });

        if (response.status >= 200 || response.status <= 299) {
          const result = response.data.data;
          console.log("result", result);
          //
          // 에러 메세지 있을 시 팝업 여부 확인 필요
          if (result.verifiedError.verifiedPaymentKeyMessage) {
            setAlertModal({
              isOpen: true,
              message: result.verifiedError.verifiedPaymentKeyMessage,
              payload: "error",
            });
          }

          if (result.verifiedError.verifiedSearchKeyMessage) {
            setAlertModal({
              isOpen: true, //
              message: result.verifiedError.verifiedSearchKeyMessage,
              payload: "error",
            });
          }

          providerDetailApi(providerId);
        }
      }
    },
    [providerDetail?.providerPayletter],
  );

  return (
    <section className=" mb50">
      <div className="contents-container__sub-title flex-column">
        <div className="flex-center">
          <h2 className="mr30">카드 일반결제 지원</h2>
          <BaseToggle
            onChange={(checked: boolean) => {
              const clientId = providerDetail?.providerPayletter?.clientId;

              if (clientId && !checked) {
                setConfirmModal({
                  isOpen: true,
                  message: "등록된 카드 일반결제 및 자동결제를 해제하시겠습니까?",
                  payload: true,
                });
                return;
              }

              setIsBasicPayletter(checked);
            }}
            checked={isBasicPayletter}
          />
        </div>

        {/* 가이드메시지 수정에서만 노출. 상세에선 노출하지않음 */}
        <p className="contents-container__sub-title-info mt10">
          카드결제를 지원하기 위해서는 프로바이더가
          <a href="https://www.payletter.com/ko/service/domestic" target={"_blank"} className="text-underline px5" rel="noreferrer">
            페이레터
          </a>
          의 전자결제서비스에 직접 가입해야합니다.
        </p>
      </div>
      {isBasicPayletter && (
        <>
          <div className="mb10">
            <div className="index-tab">
              <span>일반 결제 페이레터 정보 (필수)</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className="required font13 text-gray600">가맹점 아이디</span>
                  </div>
                  <Controller
                    control={control}
                    name={`providerPayletter.clientId`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} errorText={error && error.message} />;
                    }}
                  ></Controller>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className="required font13 text-gray600">API Key (PAYMENT)</span>
                  </div>
                  <Controller
                    control={control}
                    name={`providerPayletter.paymentKey`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} errorText={error && error.message} type="password" />;
                    }}
                  ></Controller>
                </section>
                <section className="flex-center">
                  <div className="minmax123">
                    <span className="required font13 text-gray600">API Key (SEARCH)</span>
                  </div>
                  <Controller
                    control={control}
                    name={`providerPayletter.searchKey`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} errorText={error && error.message} type="password" />;
                    }}
                  ></Controller>
                </section>
                <div className="flex-center-end mt10">
                  <BaseButton type="submit" title="저장" />
                </div>
              </form>
            </div>
          </div>

          <div className="mb10">
            <div className="index-tab">
              <span>일반 결제 페이레터 정보 검증</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="flex-center mb10">
                <div className="minmax123">
                  <BaseButton title="검증" onClick={() => payletterApiCheck(providerId)} />
                </div>
              </section>
              <section className="flex-center">
                <div className="minmax200 d-flex">
                  <span className="font13 text-gray600">1. Payment API Key: </span>
                  <span className=" font13 ml10">{providerDetail?.providerPayletter?.isVerifiedPaymentKey ? "성공" : "실패"}</span>
                </div>

                <div className="minmax200">
                  <span className="font13 text-gray600">2. Search API Key 조회 API: </span>
                  <span className=" font13 ml10">{providerDetail?.providerPayletter?.isVerifiedSearchKey ? "성공" : "실패"}</span>
                </div>
              </section>
            </div>
          </div>
        </>
      )}

      <BaseModal
        isOpen={confirmModal.isOpen}
        btnRightTitle="확인"
        btnLeftTitle="취소"
        title={confirmModal.message}
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => {
          (submitData || confirmModal.payload) && updatePayletterApi(submitData, confirmModal.payload);
        }}
      ></BaseModal>
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        title={!alertModal.payload ? alertModal.message : ""}
        onClick={() => {
          setAlertModal({ isOpen: false, payload: "" });
        }}
      >
        {alertModal.payload === "error" && (
          <div>
            <p className="font18 font-weight-semibold text-gray900">결제 검증에 실패하였습니다.</p>
            <p className="my20">검증 중 문제가 발생하였습니다.</p>
            <p className="pa8 text-left" style={{ backgroundColor: "#F2F4F6", maxHeight: 250, overflow: "hidden", textOverflow: "ellipsis" }}>
              {alertModal.message}
            </p>
          </div>
        )}
      </BaseModal>
    </section>
  );
};

export default BasicPayletter;
