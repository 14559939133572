import { useEffect, useRef, useState } from "react";
import { Cell } from "react-table";
import useApiOperation from "src/api/hooks/api-operation";
import { getPartners } from "src/api/partner";
import { BaseCheckbox, BaseInput } from "src/components";
import { Partner } from "src/types/partner";
import TableSelectModal from "../TableSelectModal";
import { Controller, useForm } from "react-hook-form";

interface Props {
  onCanceled: () => void;
  onAdded: (partnerList: Partner[]) => void;
  defaultValues: Partner[];
  multiSelect?: boolean;
}

interface CellProps extends Cell<Partner> {
  checked: boolean;
}

const STATUS_MAP = {
  STANDBY: {
    name: "대기",
    color: "gray",
  },
  ACTIVE: {
    name: "활성",
    color: "green",
  },
  SHUTDOWN: {
    name: "종료",
    color: "red",
  },
};

const PARTNER_TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 100,
    Cell: (props: CellProps) => {
      const partnerId = props.row.cells[1].value;

      return (
        <div data-data-id={partnerId} data-checked={props.value} className="checkbox">
          <BaseCheckbox id={""} name={""} checked={props.value} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "파트너 코드",
    accessor: "code",
    width: 250,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "파트너 이름",
    accessor: "name",
    width: 250,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "상태",
    accessor: "status",
    width: 100,
    Cell: (props: CellProps) => {
      const status = STATUS_MAP[props.value as keyof typeof STATUS_MAP];

      return <div className={`color-chip ${status.color}`}>{status.name}</div>;
    },
  },
];

function PartnerSelectModal({ onCanceled, onAdded, defaultValues, multiSelect }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [partnerList, setPartnerList] = useState<Partner[]>([]);
  const [selectedPartnerList, setSelectedPartnerList] = useState<Partner[]>(defaultValues);
  const searchFormRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control } = useForm<{ searchWord: string }>();

  const partnerListTableData = partnerList.map((partner) => {
    const selectedIdList = selectedPartnerList.map((partner) => partner.id);

    return {
      checked: selectedIdList.includes(partner.id),
      ...partner,
    };
  });

  const { executeAsync: getPartnersAsync } = useApiOperation(getPartners);

  const onSearch = (searchWord: string) => fetchPartnerList(searchWord);
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });
  const onSubmit = ({ searchWord }: { searchWord: string }) => onSearch(searchWord);

  const fetchPartnerList = async (searchWord?: string) => {
    const result = await getPartnersAsync({
      search002: searchWord,
      page: page.current,
      size: 20,
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    });

    setPartnerList(result.data.data.content);

    setPage({
      ...page,
      total: result.data.meta.pageMeta?.totalPages || 0,
    });
  };

  const selectPartner = (partnerId: string) => {
    const selectedPartner = partnerList.find((partner) => partner.id === partnerId);

    if (multiSelect) {
      setSelectedPartnerList([...selectedPartnerList, selectedPartner!]);
    } else {
      setSelectedPartnerList([selectedPartner!]);
    }
  };

  const unSelectPartner = (partnerId: string) => {
    const filteredPartnerList = selectedPartnerList.filter((partner) => partner.id !== partnerId);
    setSelectedPartnerList(filteredPartnerList);
  };

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchPartnerList();
  }, [page.current]);

  return (
    <TableSelectModal<Partner>
      onCanceled={onCanceled}
      onAdded={onAdded}
      goPage={goPage}
      page={page}
      columns={PARTNER_TABLE_COLUMNS}
      items={partnerListTableData}
      multiSelect={multiSelect}
      title={"파트너"}
      select={selectPartner}
      unSelect={unSelectPartner}
      titleFormRender={() => {
        return (
          <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                  clearable
                />
              )}
            />
          </form>
        );
      }}
    />
  );
}
export default PartnerSelectModal;
