import React from "react";
import { useRecoilState } from "recoil";
import { baseAbstractModalStateZ1, baseAbstractModalStateZ2, baseModalState } from "src/recoil/modalState/atom";
import { BaseModal } from "./BaseModal";
import { BaseAbstractModal } from "./BaseAbstractModal";

const ModalList = () => {
  const [baseModal, setBaseModal] = useRecoilState(baseModalState);
  const [baseAbstractModalZ1] = useRecoilState(baseAbstractModalStateZ1);
  const [baseAbstractModalZ2] = useRecoilState(baseAbstractModalStateZ2);

  return (
    <div>
      {baseModal.isOpen && (
        <BaseModal
          onClick={() => {
            return baseModal.onClick ? baseModal.onClick : setBaseModal({ isOpen: false });
          }}
          onClose={() => {
            return baseModal.onClose ? baseModal.onClose : setBaseModal({ isOpen: false });
          }}
          {...baseModal}
        ></BaseModal>
      )}
      {baseAbstractModalZ1.isOpen && <BaseAbstractModal {...baseAbstractModalZ1} />}
      {baseAbstractModalZ2.isOpen && <BaseAbstractModal {...baseAbstractModalZ2} />}
    </div>
  );
};

export default ModalList;
