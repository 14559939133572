import { ApiResponse } from "src/api/public-types";
import { AxiosInstance, AxiosResponse } from "axios";
import { MgmtOfficeModel } from "./building-types";
import { ApiPath } from "..";

// CPO01. 관리처 등록
export const postBuildingManagementAsync = async (
  axios: AxiosInstance,
  params?: {
    office: MgmtOfficeModel;
  },
): Promise<AxiosResponse<ApiResponse<{ content: MgmtOfficeModel }>>> => {
  return await axios.post(ApiPath.building.management.add, {
    office: params?.office,
  });
};

// CPO02. 관리처 수정
export const editBuildingManagementAsync = async (
  axios: AxiosInstance,
  params?: {
    id: string;
    office: MgmtOfficeModel;
  },
): Promise<AxiosResponse<ApiResponse<{ content: MgmtOfficeModel }>>> => {
  return await axios.put(ApiPath.building.management.edit.replace("{id}", String(params?.id)), {
    office: params?.office,
  });
};

//CPO03. 관리처 삭제
export const deleteBuildingManagementAsync = async (
  axios: AxiosInstance,
  params?: {
    ids: Array<string>;
  },
): Promise<AxiosResponse<ApiResponse<{ content: number }>>> => {
  return await axios.delete(ApiPath.building.management.delete, {
    data: {
      ids: params?.ids,
    },
  });
};

// ========== 위에는 cetral api -> 오마르 api 바꾸지않음

// RPO04. 관리처 조회
export const getBuildingManagementAsync = async (
  axios: AxiosInstance,
  params?: {
    id?: string;
    buildingId?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: MgmtOfficeModel }>>> => {
  return await axios.get(ApiPath.building.management.detail, {
    params: {
      id: params?.id,
      buildingId: params?.buildingId,
    },
  });
};
