import { range } from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingFloorModel, BuildingModel, BuildingRoomModel, DeleteType } from "src/api/building/building-types";
import { createPropertyAsync, deletePropertyAsync, getPropertiesAsync, updatePropertyAsync } from "src/api/building/property-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal, BaseTooltip, ContentsIdSection } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { floorOptions, Modal, Property, SelectOption, sortingProperties, sumByFloor } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useToast } from "src/recoil/toast/hook";
import { calculatePyeong, calculatePyeongNum, numberToStringWithComma, roundToTwo } from "src/utils/common-util";
import { SaveFloorHoTable } from "./components";
import MetaTag from "src/components/layout/MetaTag";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

/* 
  건물 > 등록 or 수정 > 프로퍼티
 */
const PropertyForm = () => {
  // 로딩바
  //

  // 토스트
  const { openToast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const buildingId: number = Number(queryParams.id);

  // 건물 기본정보
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  // 건물과 층과 호실정보 테이블 목록
  const [properties, setProperties] = useState<Array<Property>>([]);

  // 건물 상세 조회 (수정일 경우)
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 프로퍼티 목록 조회 (수정일 경우)
  const { executeAsync: getProperties } = useApiOperation(getPropertiesAsync);

  // 건물 프로퍼티 등록
  const { executeAsync: createProperty } = useApiOperation(createPropertyAsync);

  // 건물 프로퍼티 수정
  const { executeAsync: updateProperty } = useApiOperation(updatePropertyAsync);

  // 건물 프로퍼티 삭제
  const { executeAsync: deleteProperty } = useApiOperation(deletePropertyAsync);

  // 건물의 프로퍼티 목록을 가져와서 setProperties 처리
  const fetchProperties = useCallback(
    async (buildingId: number) => {
      const { data: getPropertiesData } = await getProperties({ buildingId });
      const buildingFloors = getPropertiesData?.data?.content || [];

      const properties: Array<Property> = [];
      for (let i = 0; i < buildingFloors.length; i++) {
        const bf: BuildingFloorModel = buildingFloors[i];

        // 층
        const floorProperty: Property = {
          type: "FLOOR",
          id: bf?.id,
          floor: String(bf?.floorNum || ""),
          floorName: bf.floorName,
          isGray: true,
        };
        properties.push(floorProperty);

        // 호
        (bf?.buildingRoomList || []).forEach((br: BuildingRoomModel) => {
          const hoProperty: Property = {
            type: "HO",
            id: floorProperty.id,
            floor: floorProperty.floor,
            hoId: br?.id,
            ho: Number(br?.roomNum || 0),
            hoName: br.roomName,
            dedicatedArea: Number(br?.leasableAreaNet || 0),
            contractArea: Number(br?.leasableArea || 0),
            rent: br?.rent,
            ownerId: br.ownerId,
            ownerName: br.ownerName,
          };
          properties.push(hoProperty);
        });
      }

      setProperties(sortingProperties(properties));
    },
    [getProperties],
  );

  useEffect(() => {
    async function fetchBuilding(buildingId: number) {
      //
      const { data: getBuildingData } = await getBuilding({ buildingId });
      setBuilding(getBuildingData?.data?.content?.building || null);
      await fetchProperties(buildingId);
      //
    }
    fetchBuilding(buildingId);
  }, [buildingId, fetchProperties, getBuilding]);

  // title 에 프로퍼티명 바인딩
  let buildingName;
  if (buildingId && building?.buildingName) {
    buildingName = building?.buildingName;
  }

  // 프로퍼티 입력 정보
  const [property, setProperty] = useState<Property>({
    type: "FLOOR",
    floor: "1",
    floorName: "",
    ho: 0,
    hoName: "",
    dedicatedArea: undefined,
    contractArea: undefined,
    rent: undefined,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 수정이나 삭제로 선택한 프로퍼티
  const [selectedProperty, setSelectedProperty] = useState<Property>();

  // 건물 > 기본정보 > 규모 범위 내의 층 (위치) 옵션
  const rangeFloorOptions: Array<SelectOption> = useMemo(() => {
    // 건물 > 기본정보 > 규모 범위
    const floorRange = range(
      Number(building?.undergroundFloorNums || 0) === 0 ? 0 : Number(building?.undergroundFloorNums || 0) * -1,
      Number(building?.floorNums || 0) + 1,
    );
    const _rangeFloorOptions: Array<SelectOption> = [];
    for (let i = 0; i < floorRange.length; i++) {
      const floor: number = floorRange[i];
      if (floor !== 0) {
        _rangeFloorOptions.push({
          value: String(floor || ""),
          label: floorOptions.find((v: SelectOption) => v.value === String(floor || ""))?.label || "",
        });
      }
    }
    if (_rangeFloorOptions.length === 0) {
      return floorOptions;
    } else {
      return _rangeFloorOptions.sort((a: SelectOption, b: SelectOption) => Number(b.value || 0) - Number(a.value || 0));
    }
  }, [building]);

  // 수정 버튼 클릭
  const clickEdit = (rowData: Property) => {
    setProperty(rowData);
  };

  // 삭제 버튼 클릭
  const clickDelete = (rowData: Property, isFloorRow: boolean, data: Array<Property>) => {
    // 삭제 가능여부
    let enabledDelete = true;
    if (isFloorRow) {
      // 해당 층 하위에 호가 있을 경우 층은 삭제 불가능
      const hoList = data.filter((d: Property) => d.floor === rowData.floor && !!d.ho);
      enabledDelete = hoList.length === 0;
    }

    if (enabledDelete) {
      setSelectedProperty(rowData);
      setConfirmModal({ title: "삭제하시겠습니까?", isOpen: true });
    } else {
      setAlertModal({ title: "해당 층에 호가 있습니다.", isOpen: true });
    }
  };

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    setConfirmModal({ isOpen: false });

    try {
      if (!selectedProperty) {
        throw Error("선택한 프로퍼티 정보 없음");
      }

      //

      let deletePropertyParams: {
        buildingId: number;
        deleteType: DeleteType;
        ids: Array<number>; // 삭제타입 대상 id
      } = {
        buildingId,
        deleteType: "DELETE_BUILDING_FLOOR",
        ids: [],
      };
      if (selectedProperty.type === "FLOOR") {
        // 층 삭제
        if (!selectedProperty?.id) throw Error("selectedProperty?.id is undefined");

        deletePropertyParams.deleteType = "DELETE_BUILDING_FLOOR";
        deletePropertyParams.ids.push(selectedProperty.id);
      } else {
        // 호 삭제
        if (!selectedProperty?.hoId) throw Error("selectedProperty?.hoId is undefined");

        deletePropertyParams.deleteType = "DELETE_BUILDING_ROOM";
        deletePropertyParams.ids.push(selectedProperty.hoId);
      }

      await deleteProperty(deletePropertyParams);
      openToast({ content: "삭제되었습니다." });
      await fetchProperties(buildingId);
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  };

  // 저장 버튼 클릭
  const saveProperty = async (property: Property) => {
    try {
      //
      const buildingFloorList: Array<BuildingFloorModel> = [];

      if (property.type === "FLOOR") {
        // 층
        if (!property?.id) {
          // 층 id 없으면 층 등록
          const buildingFloorModel: BuildingFloorModel = {
            floorNum: Number(property.floor),
            floorName: property.floorName,
          };
          buildingFloorList.push(buildingFloorModel);

          await createProperty({
            buildingId,
            buildingFloorList,
          });
        } else {
          // 층 id 있으면 층 수정
          const buildingFloorModel: BuildingFloorModel = {
            id: property.id,
            floorNum: Number(property.floor),
            floorName: property.floorName,
          };
          buildingFloorList.push(buildingFloorModel);

          await updateProperty({
            buildingId,
            buildingFloorList,
          });
        }

        // 구분 층으로 form 초기화
        setProperty({
          type: "FLOOR",
          id: undefined,
          floor: "1",
          floorName: "",
          hoId: undefined,
          ho: 0,
          hoName: "",
          dedicatedArea: undefined,
          dedicatedAreaPyeong: undefined,
          contractArea: undefined,
          contractAreaPyeong: undefined,
          rent: undefined,
          ownerId: undefined,
          ownerName: undefined,
        });
      } else {
        // 호
        // 호는 부모 층의 정보도 같이 전달
        const findParentFloor: Property | undefined = properties.find((p: Property) => p.floor === property.floor && p.type === "FLOOR");
        if (!findParentFloor) {
          throw Error("부모 층을 찾지 못했습니다.");
        }
        const buildingFloorModel: BuildingFloorModel = {
          id: findParentFloor.id,
          floorNum: Number(findParentFloor.floor),
          floorName: findParentFloor.floorName,
          buildingRoomList: [],
        };

        if (!property?.hoId) {
          // 호 id 없으면 호 등록
          const buildingRoomList: Array<BuildingRoomModel> = [
            {
              roomNum: property.ho,
              roomName: property.hoName,
              leasableAreaNet: String(property.dedicatedArea || ""),
              leasableArea: String(property.contractArea || ""),
              rent: property.rent,
            },
          ];
          buildingFloorModel.buildingRoomList = buildingRoomList;
          buildingFloorList.push(buildingFloorModel);

          await createProperty({
            buildingId,
            buildingFloorList,
          });
        } else {
          // 호 수정
          const buildingRoomList: Array<BuildingRoomModel> = [
            {
              id: property.hoId,
              roomNum: property.ho,
              roomName: property.hoName,
              leasableAreaNet: String(property.dedicatedArea || ""),
              leasableArea: String(property.contractArea || ""),
              rent: property.rent,
              ownerId: property.ownerId,
              ownerName: property.ownerName,
            },
          ];
          buildingFloorModel.buildingRoomList = buildingRoomList;
          buildingFloorList.push(buildingFloorModel);

          await updateProperty({
            buildingId,
            buildingFloorList,
          });
        }
        // 구분 호로 form 초기화
        setProperty({
          type: "HO",
          id: undefined,
          floor: findParentFloor.floor,
          floorName: "",
          hoId: undefined,
          ho: 0,
          hoName: "",
          dedicatedArea: undefined,
          dedicatedAreaPyeong: undefined,
          contractArea: undefined,
          contractAreaPyeong: undefined,
          rent: undefined,
          ownerId: undefined,
          ownerName: undefined,
        });
      }

      // openToast("저장되었습니다.");
      await fetchProperties(buildingId);
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  };

  // 건물의 층과 호실정보 테이블 컬럼과 셀
  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "층",
        accessor: "floor",
        Cell: ({ value, row }: any) => {
          if (row?.original?.type === "FLOOR") {
            const floor = floorOptions.find((option: SelectOption) => option.value === value);
            return floor?.label || "";
          } else {
            return <div className="ic_ho"></div>;
          }
        },
        width: 100,
      },
      {
        Header: "층 명칭",
        accessor: "floorName",
        width: 80,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "호",
        accessor: "ho",
        width: 70,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "호 명칭",
        accessor: "hoName",
        width: 90,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "전용면적(m2)",
        accessor: "dedicatedArea",
        width: 110,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 전용면적 값을 모두 더한 값을 보여준다.
            const sum = sumByFloor(row?.original?.floor, "dedicatedArea", data);
            return <div>{sum}</div>;
          } else {
            return <div>{numberToStringWithComma(value, "0,0.00")}</div>;
          }
        },
      },
      {
        Header: "전용면적(평)",
        accessor: "dedicatedAreaPyeong",
        width: 110,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 전용면적(평) 값을 모두 더한 값을 보여준다.
            const hoList = (data || []).filter((d: Property) => d.type === "HO" && d.floor === row?.original?.floor);
            const sum = hoList.reduce((acc: number, cur: any) => acc + calculatePyeongNum(Number(cur.dedicatedArea || 0)), 0);
            return <div>{numberToStringWithComma(roundToTwo(sum), "0,0.00")}</div>;
          } else {
            return <div>{calculatePyeong(Number(row?.original?.dedicatedArea || 0))}</div>;
          }
        },
      },
      {
        Header: "임대면적(m2)",
        accessor: "contractArea",
        width: 110,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 임대면적 값을 모두 더한 값을 보여준다.
            const sum = sumByFloor(row?.original?.floor, "contractArea", data);
            return <div>{sum}</div>;
          } else {
            return <div>{numberToStringWithComma(value, "0,0.00")}</div>;
          }
        },
      },
      {
        Header: "임대면적(평)",
        accessor: "contractAreaPyeong",
        width: 110,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 임대면적(평) 값을 모두 더한 값을 보여준다.
            const hoList = (data || []).filter((d: Property) => d.type === "HO" && d.floor === row?.original?.floor);
            const sum = hoList.reduce((acc: number, cur: any) => acc + calculatePyeongNum(Number(cur.contractArea || 0)), 0);
            return <div>{numberToStringWithComma(roundToTwo(sum), "0,0.00")}</div>;
          } else {
            return <div>{calculatePyeong(Number(row?.original?.contractArea || 0))}</div>;
          }
        },
      },
      {
        Header: "임대료(원)",
        accessor: "rent",
        width: 120,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            // 층의 경우, 호의 임대료 값을 모두 더한 값을 보여준다.
            const sum = sumByFloor(row?.original?.floor, "rent", data, "0,0");
            return <div>{sum}</div>;
          } else {
            return <div>{numberToStringWithComma(value)}</div>;
          }
        },
      },
      {
        Header: "호실",
        accessor: "hosil",
        width: 50,
        Cell: ({ row, data, value }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";
          if (isFloorRow) {
            /// 층의 경우, 호실 합계 갯수를 보여준다
            const hoList = (data || []).filter((d: Property) => d.type === "HO" && d.floor === row?.original?.floor);
            return <div>{hoList.length}</div>;
          } else {
            return <div></div>;
          }
        },
      },
      {
        Header: "소유주",
        accessor: "ownerName",
        width: 170,
        Cell: ({ value }: any) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "수정/삭제",
        accessor: "buttons",
        Cell: ({ row, data }: any) => {
          const isFloorRow = row?.original?.type === "FLOOR";

          return (
            <div className="minmax50 d-flex justify-contents-end">
              <button className="base-edit-btn mr4" onClick={() => clickEdit(row?.original)}></button>
              <button className="base-trash-btn" onClick={() => clickDelete(row?.original, isFloorRow, data)}></button>
            </div>
          );
        },
        width: 80,
      },
    ],
    [],
  );

  return (
    <>
      <MetaTag subTitle={buildingName} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {/* 층 호실 저장 테이블 */}
          <article className="contents-container__wrap-article">
            <div className="contents-container__sub-title">
              <h2>층/호실 등록하기</h2>
            </div>
            <SaveFloorHoTable
              property={property}
              saveProperty={saveProperty}
              setProperty={setProperty}
              properties={properties}
              rangeFloorOptions={rangeFloorOptions}
            />
          </article>
          {/* 층 호실 저장된 목록 테이블 */}
          <article className="contents-container__wrap-article">
            <div className="contents-container__sub-title">
              <h2>층/호실 정보</h2>
            </div>
            <ViewDataTable columns={columns} data={properties} />
          </article>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            title={confirmModal.title}
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            {/* <p>{confirmModal.message}</p> */}
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal isOpen={alertModal.isOpen} title={alertModal.title} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
            {/* <p>{alertModal.message}</p> */}
          </BaseModal>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          <BaseButton
            title={"수정취소"}
            className="color-white size-large"
            onClick={() => {
              navigate(`${PagePath.building.detail.replace(":id", String(buildingId))}?tab=property`);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PropertyForm;
