import React, { useEffect, useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { BaseButton } from "../BaseButton";

interface AddressProps {
  setAddress: any;
}

const id = "daum-postcode"; // script가 이미 rending 되어 있는지 확인하기 위한 ID
const src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

export const DaumAddress = ({ setAddress }: AddressProps) => {
  const postcodeRef = useRef<HTMLDivElement | null>(null);

  const loadLayout = () => {
    if (window?.daum as any) {
      (window?.daum as any).postcode.load(() => {
        const postcode = new (window?.daum as any).Postcode({
          oncomplete: function (data: any) {
            setAddress(data);
          },
        });
        postcode.open();
      });
    }
  };

  useEffect(() => {
    const isAlready = document.getElementById(id);

    if (!isAlready) {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      document.body.append(script);
    }
  }, []);
  return (
    <div>
      <BaseButton title="우편번호" className="mr8" onClick={loadLayout} />
      {/* <button onClick={loadLayout}>주소찾기</button> */}
      <div ref={postcodeRef}></div>
    </div>
  );
};

export default DaumAddress;
