import { useMemo } from "react";
import { BuildingModel, BuildingParkingLotModel } from "src/api/building/building-types";
import { numberToStringWithComma } from "src/utils/common-util";

type Props = {
  building: BuildingModel | null;
};

// 주차 컴포넌트
const ParkingSection = ({ building }: Props) => {
  // 건물 상세조회에서 얻어온 주차 정보
  const parking: BuildingParkingLotModel | null = useMemo(() => {
    const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
    return parkingLotList.length > 0 ? parkingLotList[0] : null;
  }, [building]);

  return (
    <section className="contents-container__grid">
      {parking ? (
        <>
          <div className="contents-container__grid-index">
            <p className="">주차</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {`자주식${parking?.selfPropelledParkingLotNums || 0} (차량 ${numberToStringWithComma(
                  parking?.selfPropelledParkingLotTotalNums || 0,
                )}대), 기계식${parking?.mechanicalParkingLotNums || 0} (차량 ${numberToStringWithComma(
                  parking?.mechanicalParkingLotTotalNums || 0,
                )}대)`}
              </span>
            </div>
            <div className="minmax400">
              <span className="font14 pre-formatted">{parking?.description}</span>
            </div>
          </div>
        </>
      ) : (
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">데이터가 없습니다.</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default ParkingSection;
