import { decommaizeNumber } from "@toss/utils";
import { useState } from "react";
import { DetailTotalData } from "src/api/contract/contract-types";
import useEditContractOperation from "src/hooks/contract/useEditContractOperation";
import useGetContractOperation from "src/hooks/contract/useGetContractOperation";
import { SpaceTypeReqDataMap } from "src/pages/product/forms/commonSpaceForm/modal/AllSpaceUsageLimitModal";
import LimitFrom from "src/pages/product/forms/commonSpaceForm/modal/LimitFrom";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";

type Props = {
  detailData: DetailTotalData;
  spaceType: SpaceTypeT;
  isEditable?: boolean;
};

//신청계약 이용한도 view
const ContractAllSpaceUsageLimitModal = ({ detailData, spaceType, isEditable }: Props) => {
  const { setAbstractModalZ1 } = useModal();
  const { setBaseModal } = useModal();
  const { openToast } = useToast();
  // 공용공간 운영정보 상세
  const { data: contractOperationDetail } = useGetContractOperation({
    contractId: detailData?.contract?.contractId ? +detailData?.contract?.contractId : undefined,
  });

  const { mutate } = useEditContractOperation();

  const [editObj, setEditObj] = useState({
    //무제한 여부
    isUnLimitReservation: contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].isUnLimitReservation],
    //월 이용한도 체크여부
    isMonthlyUnLimit: contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit],
    //일 이용한도 체크여부
    isDailyUnLimit: contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].isDailyUnLimit],
    //월 시간
    monthlyTime:
      contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].contractTotalTime] === -1
        ? ""
        : decommaizeNumber(contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].contractTotalTime]?.toString()!), //contractTotalTime 사용
    //일 시간
    dailyTime:
      contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].contractDailyTime] === -1
        ? ""
        : decommaizeNumber(contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].contractDailyTime]?.toString()!), //contractTotalTime 사용
    //초과 예약 여부
    isAllowOverReservation: contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].isAllowOverReservation],
  });

  const handleSubmit = () => {
    const productManage = {
      //무제한 여부
      [SpaceTypeReqDataMap[spaceType].isUnLimitReservation]: editObj.isUnLimitReservation,
      //월 이용한도
      [SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit]: editObj.isMonthlyUnLimit,
      //일 이용한도
      [SpaceTypeReqDataMap[spaceType].isDailyUnLimit]: editObj.isDailyUnLimit,
      //월시간
      [SpaceTypeReqDataMap[spaceType].contractTotalTime]:
        editObj.monthlyTime.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime.toString()),
      //일시간
      [SpaceTypeReqDataMap[spaceType].contractDailyTime]: editObj.dailyTime.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime.toString()),
      //초과 예약 여부
      [SpaceTypeReqDataMap[spaceType].isAllowOverReservation]: editObj.isAllowOverReservation,
    };

    mutate(
      { contractId: String(detailData?.contract?.contractId), productManage: productManage },
      { onSuccess: () => openToast({ content: "저장 되었습니다.", isSystemToast: true }) },
    );
  };

  return (
    <>
      <LimitFrom
        spaceType={spaceType}
        editObj={editObj as any}
        setEditObj={setEditObj as any}
        onClose={() => {
          setAbstractModalZ1({ isOpen: false });
        }}
        handleSubmit={handleSubmit}
        isEditable={isEditable}
      />
    </>
  );
};

export default ContractAllSpaceUsageLimitModal;
