import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Memo, ServiceTypes, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseButton } from "src/components";
import { baseAbstractModalStateZ1 } from "src/recoil/modalState/atom";
import GoToListButton from "../GoToListButton";
import AdminMemoForm from "./AdminMemoForm";
import AdminMemoTable from "./AdminMemoTable";
import useAdminMemo from "./useAdminMemo";

/**
 * 관리자 메모 공통 사용방법 정의
 * <AdminMemoIntegration
 * serviceId={Number(detailData?.contract?.contractId)}  // 해당 서비스 ID로 BE와 논의 된 ID 사용 ex> 계약의 경우 contractId
 * serviceType={ServiceTypes.SERVICE_CONTRACT} // 서비스 타입 지정 ServiceTypes enum 참조할 것
 * viewOnly={true}  // 기본정보에서 최근 3건만 수정/삭제 모드 없이 사용 , 관리자 메모 페이지에서 사용
 * />
 */

type Props = {
  serviceId: number;
  serviceType: UnionServiceType;
  viewOnly?: boolean;
};

const AdminMemoIntegration = ({ serviceId, serviceType, viewOnly }: Props) => {
  const { getList, remove } = useAdminMemo();

  // 메모 리스트
  const [memoList, setMemoList] = useState<Array<Memo>>([]);

  // 첨부파일 리스트
  const [medias, setMedias] = useState<MediaFile[]>([]);

  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync);

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: Array<{ serviceId: number; serviceType: UnionServiceType }>) => {
      const response = await getMediaFileList({ contentsList: data });
      if (response.status >= 200 && response.status <= 299) {
        setMedias(response.data.data.content);
      }
    },
    [getMediaFileList],
  );

  //API에서 페이징 처리가 안되기 때문에 viewOnly일 경우 내려온 데이터에서 임의로 3개로 제한함
  const callList = useCallback(async () => {
    const list = await getList(Number(serviceId), serviceType, viewOnly);
    if (list.status > 199 && list.status < 300) {
      if (viewOnly === undefined || viewOnly === false) {
        setMemoList(list.data.data.content);
      } else {
        setMemoList(list.data.data.content.slice(0, 3));
      }
    }

    const memoList = list.data.data.content;

    // 미디어 가져오기
    if (memoList.length > 0) {
      const mediasRequest = memoList.map((item: Memo) => {
        return { serviceId: item.id, serviceType: ServiceTypes.SERVICE_ADMINMEMO };
      });
      fetchMediaList(mediasRequest);
    }
  }, [getList, serviceId, serviceType, viewOnly, fetchMediaList]);

  useEffect(() => {
    if (serviceId) {
      callList();
    }
  }, [serviceId, viewOnly]);

  const setAbstractModalZ1 = useSetRecoilState(baseAbstractModalStateZ1);

  return (
    <>
      {!viewOnly && (
        <div className="contents-container__scroll">
          <div className="contents-container__wrap">
            <article className="contents-container__wrap-article">
              <>
                <div className="contents-container__sub-title">
                  <div className="flex-center-between w-100">
                    <h2>전체</h2>
                    <BaseButton
                      title={"등록하기+"}
                      onClick={() => {
                        setAbstractModalZ1({
                          isOpen: true,
                          size: "large",
                          children: (
                            <AdminMemoForm
                              serviceId={serviceId}
                              serviceType={serviceType}
                              callList={callList}
                              onClose={() => setAbstractModalZ1({ isOpen: false })}
                            />
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
                <AdminMemoTable data={memoList} callList={callList} remove={remove} viewOnly={viewOnly} medias={medias} />
              </>
            </article>
          </div>
        </div>
      )}
      {(viewOnly === undefined || viewOnly === false) && (
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>
        </div>
      )}

      {/* 기본정보 3개 줄만 보이는 영역 */}
      {viewOnly === true && (
        <section>
          <div className="contents-container__sub-title">
            <h2>관리자 메모</h2>
          </div>
          <AdminMemoTable data={memoList} viewOnly={viewOnly} medias={medias} />
        </section>
      )}
    </>
  );
};
export default AdminMemoIntegration;
