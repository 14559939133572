import React, { FocusEventHandler, HTMLInputTypeAttribute, KeyboardEventHandler } from "react";
import { formatPhoneNumber, isPhoneNumber, onlyNumberAndSign } from "src/utils";

interface BaseInputState {
  placeholder?: string;
  type?: HTMLInputTypeAttribute | "phone";
  readonly?: boolean;
  onChange?: Function;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  name?: string;
  errorText?: string;
  onKeyUp?: Function;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  validation?: boolean;
  onSearchClick?: Function;
  onIconClick?: Function;
  rightIconClassName?: string;
  onClearClick?: Function;
  clearable?: boolean;
  inputRef?: any;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  clearHidden?: boolean;
}

export const BaseInput = (props: BaseInputState) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const targetValue = e.target.value;
      if (props.type === "number" && props.maxLength) {
        // type number 일때 maxLength 안먹혀서 추가
        if (targetValue.length > props.maxLength) {
          const sliceValue = targetValue.slice(0, props.maxLength);
          props.onChange(sliceValue, e);
        } else {
          props.onChange(targetValue, e);
        }
      }
      // type = 전화번호 형식이면 number , + -  만 입력
      else if (props.type === "phone") {
        props.onChange(onlyNumberAndSign(e.target.value), e);
      } else {
        props.onChange(targetValue, e);
      }
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (props.onKeyUp) {
        props.onKeyUp();
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    }
  };

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onSearchClick) {
      props.onSearchClick();
    }
  };

  const onIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onIconClick) {
      props.onIconClick();
    }
  };

  const onClearClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onClearClick) {
      props.onClearClick();
    }

    if (props.clearable && props.onChange) {
      props.onChange("");
    }
  };

  const onInputClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div
      className={`base-input ${props.className ? props.className : ""} ${props.onSearchClick ? "base-search" : ""} ${
        props.onIconClick ? "base-right-icon" : ""
      }`}
    >
      <input
        ref={props?.inputRef}
        type={props.type}
        name={props.name}
        className={props.onClick ? "pr30" : ""}
        placeholder={props.placeholder}
        onChange={onChangeValue}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onClick={(e: any) => onInputClick(e)}
        onBlur={(e) => {
          const targetValue = e.target.value.trim();
          props.onChange && props.onChange(targetValue, e);
          props.onBlur && props.onBlur(e);

          if (props.onChange && props.type === "phone") {
            if (isPhoneNumber(targetValue)) {
              props.onChange(formatPhoneNumber(targetValue));
            } else {
              props.onChange(targetValue);
            }
          }
        }}
        value={props.value || ""}
        readOnly={props.readonly ? true : false}
        maxLength={props.maxLength || 100}
        disabled={props.disabled ? true : false}
        onFocus={props.onFocus}
      />
      {props.type !== "number" && String(props?.value || "").length > 0 && (props.onClearClick || props.clearable) && (
        <>{!props.clearHidden && <button className="base-clear-btn" onClick={onClearClick}></button>}</>
      )}
      {props.onSearchClick && <button className="base-search-btn" onClick={onSearchClick}></button>}
      {props.onIconClick && (
        <button className={`base-icon-btn ${props.rightIconClassName ? props.rightIconClassName : ""}`} onClick={onIconClick}></button>
      )}
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
