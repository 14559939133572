import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { useRecoilValue } from "recoil";
import { BaseButton } from "src/components";
import useContractFacilityApi from "src/pages/contract/hooks/useContractFacilityApi";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { numberToStringWithComma } from "src/utils";

type Props = {
  contractId: string | number;
  spaceType: SpaceTypeT;
};
const cols = [
  {
    Header: "",
    accessor: "facilityName",
    // sticky: "left",
    width: 200,
  },
  {
    Header: "사용 시간",
    accessor: "usageMinutes",
    // sticky: "left",
    width: 140,
    Cell: ({ value }: any) => {
      return <p>{value && Number(value) / 60}</p>;
    },
  },
];
const cols_over = [
  {
    Header: "초과 시간",
    accessor: "excessMinutes",
    // sticky: "left",
    width: 140,
    Cell: ({ value }: any) => {
      return <p>{value && Number(value) / 60}</p>;
    },
  },
  {
    Header: "초과 요금(부가세 별도)",
    accessor: "excessAmount",
    // sticky: "left",
    width: 200,
    Cell: ({ value }: any) => {
      return <p>{numberToStringWithComma(value) + " 원"}</p>;
    },
  },
];
const UsageMonthlyTimeModal = ({ spaceType, contractId }: Props) => {
  const partner: any = useRecoilValue(globalPartnerState);
  const { searchFacilityUsage } = useContractFacilityApi();
  const [typeString, setTypeString] = useState("");
  const [monthData, setMonthData] = useState<any>({ thisMonth: "", beforeOneMonth: "" });
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const { setAbstractModalZ1 } = useModal();
  useEffect(() => {
    const partnerFeatures = partner.features;

    const postPayment: any = partnerFeatures.find((feature: any) => feature.name === "FEATURE_RESERVATION_POST_PAYMENT");

    switch (spaceType) {
      case "MEETING_ROOM":
        setTypeString("회의실");
        break;
      case "DESK":
        setTypeString("좌석");
        break;
      case "REFRESH_ROOM":
        setTypeString("편의시설");
        break;
    }
  }, [partner, spaceType]);

  const makeColunms = useMemo(() => {
    let colMake: any = [];
    // if (totalOverCharge) {
    colMake = _.cloneDeep(cols).concat(cols_over);
    colMake[0].width = 200;
    colMake[1].width = 140;
    // } else {
    //   colMake = _.cloneDeep(cols);
    //   colMake[0].width = 200 + 140;
    //   colMake[1].width = 140 + 200;
    // }

    colMake.forEach((col: any) => {
      if (col.accessor === "facilityName") {
        col.Header = typeString + "명";
      }
    });
    return colMake;
  }, [typeString]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: makeColunms,
      data: data,
      typeString,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headerGroups: headerGroups2,
    prepareRow: prepareRow2,
    rows: rows2,
  } = useTable(
    {
      columns: makeColunms,
      data: data2,
      typeString,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const findUsageData = useCallback(async () => {
    const thisMonth = moment().format("YYYYMM");
    const beforeOneMonth = moment().subtract(1, "M").format("YYYYMM");

    setMonthData({ thisMonth, beforeOneMonth });
    const monthList = [thisMonth, beforeOneMonth];
    const response: any = await searchFacilityUsage(String(contractId), spaceType, monthList);

    setData(response.filter((res: any) => res.yearMonth === thisMonth));
    setData2(response.filter((res: any) => res.yearMonth === beforeOneMonth));
  }, [searchFacilityUsage, contractId, spaceType]);

  useEffect(() => {
    findUsageData();
  }, [findUsageData]);

  return (
    <>
      <section className="base-abstract-modal__title border-bottom">
        <div>
          <h1>{typeString} 월 사용 시간 확인</h1>
          <ul className="base-list mt20">
            <li className="font14">월 사용 시간 별 초과요금 산정 기준은 월 단위입니다. (1일부터 말일까지)</li>
            <li className="font14">당월 사용 시간은 현재일을 기준으로 전날까지의 사용 시간입니다.</li>
          </ul>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <section className="flex-center-start px30">
          <h2 className="font17 py22">
            {"당월 "}
            {monthData.thisMonth && moment(monthData.thisMonth).format("YYYY/MM")}
          </h2>
        </section>

        <section className="base-abstract-modal__contents px30 h300">
          <table {...getTableProps()} className="base-table overflow-visible sticky">
            <thead className="header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <td {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()} className="body">
              {rows.length > 0 &&
                rows.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={`base-table__tr`}>
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              {rows.length === 0 && (
                <tr className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <td className="base-table__td w-100 text-center">
                    <div className="w-100">
                      <span>데이터가 없습니다.</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

        <section className="flex-center-start px30">
          <h2 className="font17 py22">
            {"전월 "}
            {monthData.beforeOneMonth && moment(monthData.beforeOneMonth).format("YYYY/MM")}
          </h2>
        </section>

        <section className="base-abstract-modal__contents px30 h300">
          <table {...getTableProps2()} className="base-table overflow-visible sticky">
            <thead className="header">
              {headerGroups2.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <td {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps2()} className="body">
              {rows2.length > 0 &&
                rows2.map((row: any, i: number) => {
                  prepareRow2(row);
                  return (
                    <tr {...row.getRowProps()} className={`base-table__tr`}>
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              {rows2.length === 0 && (
                <tr className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <td className="base-table__td w-100 text-center">
                    <div className="w-100">
                      <span>데이터가 없습니다.</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"확인"} onClick={() => setAbstractModalZ1({ isOpen: false })} />
      </section>
    </>
  );
};

export default UsageMonthlyTimeModal;
