import { useCallback } from "react";

const useProductFacilityApi = () => {
  const makeSpaceList = useCallback(async (product: any) => {
    const tableData: any = [];

    if (product.isMeetingRoom) {
      product?.buildingList?.forEach((data: any) => {
        data.buildingCommonFacility?.meetingRoomList?.forEach((space: any) => {
          let returnDt = { ...space, buildingName: data.buildingName, isSelected: false, isSelectedId: "isSelected_" + space.id };
          tableData.push(returnDt);
        });
      });
    }

    if (product.isRefreshRoom) {
      product?.buildingList?.forEach((data: any) => {
        data.buildingCommonFacility?.refreshRoomList?.forEach((space: any) => {
          let returnDt = { ...space, buildingName: data.buildingName, isSelected: false, isSelectedId: "isSelected_" + space.id };
          tableData.push(returnDt);
        });
      });
    }

    if (product.isDesk) {
      product?.buildingList?.forEach((data: any) => {
        data.buildingCommonFacility?.deskSpace?.deskGroupList?.forEach((deskGroup: any) =>
          deskGroup.deskList?.forEach((desk: any) => {
            let returnDt = {
              ...desk,
              groupName: deskGroup.groupName,
              buildingName: data.buildingName,
              isSelected: false,
              isSelectedId: "isSelected_" + desk.id,
            };
            tableData.push(returnDt);
          }),
        );
      });
    }

    return tableData;
  }, []);
  return { makeSpaceList };
};
export default useProductFacilityApi;
