import React, { useState } from "react";

interface BaseInputState {
  placeholder?: string;
  type?: string;
  readonly?: boolean;
  onChange?: Function;
  onBlur?: Function;
  className?: string;
  disable?: boolean;
  value?: string | number;
  name?: string;
  errorText?: string;
  onKeyUp?: Function;
  onKeyDown?: Function;
  maxLength?: number;
  validation?: boolean;
  onSearchClick?: Function;
  inputRef?: any;
}

export const BaseInputNumberFormat = (props: BaseInputState) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e.target.value, e);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (props.onKeyUp) {
        props.onKeyUp();
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {};

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onSearchClick) {
      props.onSearchClick();
    }
  };

  return (
    <div className={`base-input ${props.className ? props.className : ""} ${props.onSearchClick ? "base-search" : ""}`}>
      <input
        ref={props?.inputRef}
        type={props.type}
        name={props.name}
        className={``}
        placeholder={props.placeholder}
        onChange={onChangeValue}
        onKeyUp={onKeyPress}
        onKeyDown={onKeyDown}
        onBlur={(e) => {
          props.onChange && props.onChange(e.target.value.trim(), e);
          props.onBlur && props.onBlur(e);
        }}
        value={props.value || ""}
        readOnly={props.readonly ? true : false}
        maxLength={props.maxLength || 100}
        disabled={props.disable}
      />
      {props.onSearchClick && <button className="base-search-btn" onClick={onSearchClick}></button>}
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
