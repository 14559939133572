import moment from "moment";
import { useEffect, useState } from "react";
import { Contractor, DetailTotalData } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import { ArticleSection } from "src/components/layout/ArticleSection";
import { ViewYmdFormat, calculateDate, formatPhoneNumber } from "src/utils";
import BaseSection from "../../../../components/layout/BaseSection";
import BaseNewTabLink from "src/components/BaseNewTabLink";
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}
const ContractorInfoDetail = ({ detailData }: ContractBasicProp) => {
  const { contract, contractManage } = detailData;
  const [constractor, setConstractor] = useState<Contractor>({});
  const [calDate, setCalDate] = useState("");
  useEffect(() => {
    if (contractManage && contractManage.contractorList && contractManage.contractorList.length > 0) {
      setConstractor(contractManage.contractorList.find((user: Contractor) => user.isContractor)!);
      let returnText = calculateDate(contract?.contractStartTime!, contract?.contractEndTime!, contractManage?.spaceProductType!);
      if (returnText !== "") {
        setCalDate(returnText);
      }
    }
  }, [contract, contractManage]);

  return (
    <ArticleSection
      title={"신청자 정보"}
      children={
        <>
          <div className="flex-center">
            <BaseSection
              title={"신청자명"}
              children={
                <div className="flex-row flex-center-start w-100 font14">
                  <div className={contract && contract?.applicantName !== undefined && contract?.applicantName?.length > 15 ? "" : "mr5"}>
                    {contract?.applicantName !== undefined && contract?.applicantName !== "" && contract?.applicantName?.length > 15 ? (
                      <div className={"flex-center-start"}>
                        <p>{contract?.applicantName!.substring(0, 15) + "..."}</p>
                        <BaseTooltip contents={""} tooltip={contract?.applicantName} touchIcon={"QUESTION"} />
                      </div>
                    ) : (
                      contract?.applicantName!
                    )}{" "}
                  </div>
                </div>
              }
            />
            <BaseSection title={"휴대폰"} value={formatPhoneNumber(String(constractor?.memberPhoneNumber))} />
          </div>
          <div className="flex-center">
            <BaseSection title={"이메일"} value={constractor?.memberEmail} />
            <BaseSection title={"회원번호"} value={constractor?.memberNo} />
          </div>

          <div className="flex-center">
            <BaseSection
              title={"신청 기간"}
              children={
                detailData.product && detailData?.product?.productType === "TIME_COURT" ? (
                  <p>
                    {moment(contract?.useStartTime).format(ViewYmdFormat.YYYY_MM_DD_HH_MM)} ~{" "}
                    {moment(contract?.useEndTime).format(ViewYmdFormat.HH_MM)} ({calDate ? calDate : "-"})
                  </p>
                ) : (
                  <p>
                    {moment(contract?.useStartTime).format(ViewYmdFormat.YYYY_MM_DD)} ~{" "}
                    {moment(contract?.useEndTime).format(ViewYmdFormat.YYYY_MM_DD)}({calDate ? calDate : "-"})
                  </p>
                )
              }
            />
          </div>
        </>
      }
    />
  );
};

export default ContractorInfoDetail;
