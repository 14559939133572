import { BaseTooltip } from "src/components";
import { numberToStringWithComma } from "src/utils";

type PriceInfoProps = {
  spaceProductType: string;
  row: any;
  nowSum: number;
  makeSum: number;
  totalSum: number;
};
const PriceInfo = ({ spaceProductType, row, nowSum, makeSum, totalSum }: PriceInfoProps) => {
  return (
    <>
      {spaceProductType !== "TIME_COURT" ? (
        <section className="summary-contents">
          <div className="summary-contents__gray-box first-item">
            <div className="w-100">
              <div className="flex-center-between ">
                <div className="font14 flex-center">
                  <span>현재 잔액</span>
                  <BaseTooltip touchIcon={"QUESTION"} className="ml4" contents={`수납처리 전 잔액기준으로 수납처리금액을 제한금액입니다.`} />
                </div>
                <p className="font18 font-weight-semibold">{nowSum > 0 ? "-" + numberToStringWithComma(nowSum) : 0}원</p>
              </div>
              <div className="flex-center-between text-primary3 font14">
                <p>ㄴ 현재 잔액의 연체료</p>
                <p>
                  {row.original.receiptBundle.realtimeOverdueAmount ? numberToStringWithComma(row.original.receiptBundle.realtimeOverdueAmount) : 0}원
                </p>
              </div>
            </div>
            <div className="w-100">
              <div className="">
                <ul>
                  <li>
                    <span>수납처리 전 잔액</span>
                    <span className="text-primary">
                      {row.original.receiptBundle.billTotalAmount ? "-" + numberToStringWithComma(row.original.receiptBundle.billTotalAmount) : 0} 원
                    </span>
                  </li>
                  <li>
                    <span>수납처리 합계</span>
                    <span className="text-primary">{numberToStringWithComma(makeSum) + " 원"}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="summary-contents__gray-box second-item">
            <div className="flex-center-between ">
              <div className="font14 flex-center">
                <span>전체 연체료</span>
                <BaseTooltip touchIcon={"QUESTION"} className="ml4" tooltip={`수납처리 금액의 연체료와 현재잔액의 연체료의 합계금액입니다.`} />
              </div>
              <p className="font15 font-weight-semibold">{numberToStringWithComma(totalSum) + " 원"}</p>
            </div>
            <div className="flex-center-between text-primary3 font14">
              <p>ㄴ 전체 연체기간</p>
              <p>{row.original.receiptBundle.totalOverdueDays ? row.original.receiptBundle.totalOverdueDays : 0} 일</p>
            </div>
          </div>
        </section>
      ) : (
        <section className="summary-contents">
          <div className="summary-contents__gray-box2 first-item">
            <div className="w-100">
              <div className="flex-center-between ">
                <div className="font14 flex-center">
                  <span>현재 잔액</span>
                  <BaseTooltip touchIcon={"QUESTION"} className="ml4" tooltip={`수납처리 전 잔액기준으로 수납처리금액을 제한금액입니다.`} />
                </div>
                <p className="font18 font-weight-semibold">{nowSum > 0 ? "-" + numberToStringWithComma(nowSum) : 0}원</p>
              </div>
            </div>
            <div className="w-100">
              <div className="">
                <ul>
                  <li>
                    <span>수납처리 전 잔액</span>
                    <span className="text-primary">
                      {row.original.receiptBundle.billTotalAmount ? "-" + numberToStringWithComma(row.original.receiptBundle.billTotalAmount) : 0} 원
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="summary-contents__gray-box2 second-item">
            <div className="flex-center-between ">
              <div className="font14 flex-center">
                <span>수납처리 합계</span>
              </div>
              <p className="font15 font-weight-semibold">{numberToStringWithComma(makeSum) + " 원"}</p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default PriceInfo;
