import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { ContractDetail, CtBuildingManage } from "src/api/contract/contract-types";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import ProductBuildingListModal from "src/pages/product/components/ProductBuildingListModal";
import { isPrimaryBuildingChecked } from "src/pages/product/forms/basicInfoForm/libs";
import { BuildingFloorAndHo } from "src/pages/product/product-types";
import { useToast } from "src/recoil/toast/hook";
import BuildingManagementTable from "../detailComponent/BuildingManagementTable";
import { useApiOperation } from "src/api/hooks";
import { postManagementBuildingsAsync } from "src/api/contract/contract-api";

type FormData = {
  buildingManageList: CtBuildingManage[];
};
interface BasicProps {
  detailData: any;
  contract: ContractDetail;
  saveFlags: boolean;
  setEditable: any;
  setSaveFlags: any;
  setData: Function;
  popupState: boolean;
  setPopupState: any;
  setCommonModals: any;
  setEditConfrim: any;
  managementBuildingData: any;
}
const BuildingManagementEdit = ({
  detailData,
  saveFlags,
  setEditable,
  setSaveFlags,
  setData,
  contract,
  setCommonModals,
  setPopupState,
  setEditConfrim,
  managementBuildingData,
}: BasicProps) => {
  const { openToast } = useToast();
  const { partnerId } = useParams();
  const [buildingModal, setBuildingModal] = useState<Modal>({ isOpen: false }); //빌딩 팝업 모달
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedBuilding, setSelectedBuilding] = useState<BuildingModel | null>(null); // 모달에서 선택한 빌딩
  const [previewManagementBuildingData, setPreviewOriginManagementBuildingData] = useState(managementBuildingData);
  const { executeAsync: postManagementBuildings } = useApiOperation(postManagementBuildingsAsync);

  useEffect(() => {
    if (saveFlags === true) {
      // 초기에 조회해온 값(managementBuildingData)과 미리보기(previewManagementBuildingData)비교
      // 1: managementBuildingData 에는 있는데 previewManagementBuildingData 에는 없는 경우 == 삭제처리
      // 2: managementBuildingData 에는 없는데 previewManagementBuildingData는 있는 경우 == 등록처리
      // 3: isPrimary가 변경된 경우 == 변경 정보 처리

      const tableViewList = previewManagementBuildingData.filter((building: CtBuildingManage) => !building.isDeleted);
      const originIds = managementBuildingData.map((item: CtBuildingManage) => item.buildingId);
      const tableViewIds = tableViewList.map((item: CtBuildingManage) => item.buildingId);

      const deletedManagementBuildings = managementBuildingData
        .filter((building: CtBuildingManage) => !tableViewIds.includes(building.buildingId))
        .map((item: CtBuildingManage) => {
          return { buildingId: item.buildingId, isDeleted: true };
        });

      const addedManagementBuildings = tableViewList
        .filter((building: CtBuildingManage) => !originIds.includes(building.buildingId))
        .map((item: CtBuildingManage) => {
          return { buildingId: item.buildingId, isPrimary: item.isPrimary ? true : false, isDeleted: false };
        });

      const changedPrimaryManagementBuildings = tableViewList
        .filter((tableViewBuilding: CtBuildingManage) => {
          const originItem = managementBuildingData.find((item: CtBuildingManage) => item.buildingId === tableViewBuilding.buildingId);
          return originItem && originItem.isPrimary !== tableViewBuilding.isPrimary;
        })
        .map((building: CtBuildingManage) => {
          return { buildingId: building.buildingId, isPrimary: building.isPrimary };
        });

      const payload = [...deletedManagementBuildings, ...addedManagementBuildings, ...changedPrimaryManagementBuildings];

      (async () => {
        const { data, status } = await postManagementBuildings({
          contractManageId: contract.contractManageId!,
          buildingManageList: payload,
        });
        if (status >= 200 && status < 300) {
          setEditable(false);
          setCommonModals({
            message: "저장 되었습니다.",
            title: "건물 운영 정보 변경",
          });
        } else {
          setCommonModals({ isOpen: false });
        }
      })();
      setSaveFlags(false);
      setPopupState(true); // true가 되면 commonModal이 열림
      setEditConfrim(false); // '저장하시겠습니까?'를 출력하는 모달 닫기
    }
  }, [saveFlags]);

  useEffect(() => {
    const findPrimaryManageBuilding = previewManagementBuildingData.find((building: CtBuildingManage) => building.isPrimary);
    setSelectedRow(findPrimaryManageBuilding);
  }, []);

  return (
    <div>
      <div className="flex-center">
        <BaseSection
          title={"건물"}
          children={<BaseButton title="선택" className="color-white" onClick={() => setBuildingModal({ isOpen: true })} />}
        />
      </div>
      <div>
        <BuildingManagementTable
          viewTable={previewManagementBuildingData.filter((building: any) => !building.isDeleted) || []}
          setViewTable={setPreviewOriginManagementBuildingData}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          onSelectedRowsChange={setSelectedRow}
        />

        {buildingModal.isOpen && (
          <ProductBuildingListModal
            setSelectedBuilding={setSelectedBuilding}
            partnerId={partnerId!}
            initialArray={previewManagementBuildingData}
            onClose={() => setBuildingModal({ isOpen: false })}
            onConfirm={() => {
              let changeToManagementBuildingData = {
                address: selectedBuilding?.address,
                buildingId: Number(selectedBuilding?.id),
                buildingName: selectedBuilding?.buildingName,
                isPrimary: false,
                isDeleted: selectedBuilding?.isDeleted,
              };
              setPreviewOriginManagementBuildingData([...previewManagementBuildingData, changeToManagementBuildingData]);
              setBuildingModal({ isOpen: false });
            }}
          />
        )}
      </div>
    </div>
  );
};
export default BuildingManagementEdit;
