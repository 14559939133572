export const iotErrors = [
  { errorCode: "duplicated_building_devices", message: "동일한 기기가 선택되었습니다.\n중복되지 않는 기기를 선택해주세요." },
  {
    errorCode: "already_exists_product_devices",
    // message: "선택하신 기기는 \n 이미 등록된 기기입니다.\n 다른 기기를 선택해주세요.",
    message: "아래의 기기는 이미 등록된 기기로 다른 기기를 선택해주세요.",
  },
  // {
  //   errorCode: "not_found_contract",
  //   message: "계약정보를 찾을 수 없습니다. \n 계약체결 / 이용승인 / 이용중 / 해지접수\n상태 일 때  등록이 가능합니다.",
  // },
  {
    errorCode: "already_exists_contract_devices",
    // message: "선택하신 기기는 이미 등록된 기기입니다.\n 다른 기기를 선택해주세요.",
    message: "아래의 기기는 이미 등록된 기기로 다른 기기를 선택해주세요.",
  },
  {
    errorCode: "not_contract_buildings",
    // message: "선택하신 기기는 현재 계약된 상품에 연결된 건물 디바이스가 아닙니다.",
    message: "아래의 기기는 이미 등록된 기기로 다른 기기를 선택해주세요.",
  },
];
