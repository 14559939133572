import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { HookFormProps, MediaFile, MediaServiceType } from "src/api/public-types";
import { WorkOrderFormType, WorkOrderSheetCostType } from "src/api/work-order/workorder-types";
import { BaseInput, BaseTextarea } from "src/components";
import PublicImageUpdate from "src/components/PublicImageUpdate";
import { WorkOrderMediaType, WorkOrderStatus } from "src/pages/workOrder/workOrder-types";
import { onlyNumber } from "src/utils/common-util";

type Props = {
  formProps: HookFormProps<WorkOrderFormType>;
  workOrderCompletedMedias?: Array<MediaFile>;
  workOrderCostMedias?: Array<MediaFile>;
  workOrderDetail?: WorkOrderFormType;
  setWorkOrderCostMedias: Dispatch<SetStateAction<Array<MediaFile>>>;
  setWorkOrderCompletedMedias: Dispatch<SetStateAction<Array<MediaFile>>>;
};

const WorkOrderEditSection = ({
  formProps, //
  workOrderCompletedMedias,
  workOrderCostMedias,
  setWorkOrderCostMedias,
  setWorkOrderCompletedMedias,
}: Props) => {
  //
  const { control, setValue, watch } = formProps;

  const {
    fields: workOrderCostList,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "workOrderSheetCostList",
  });

  const isCustomerDisabled = useCallback((reporterName: string, customerNotification: string) => {
    if (reporterName === "") return true;
    if (customerNotification !== "") return true;

    return false;
  }, []);
  return (
    <article className="contents-container__wrap-article">
      {watch("status") !== WorkOrderStatus.OPEN && //접수중
        watch("status") !== WorkOrderStatus.CANCELED && ( //취소
          <>
            <div className="contents-container__sub-title">
              <h2>업무 처리내역</h2>
            </div>
            <section className="contents-container__grid ">
              <div className="contents-container__grid-index">
                <p>특이사항</p>
              </div>
              <div className="contents-container__grid-contents">
                <div
                  className="" //
                >
                  <Controller
                    control={control}
                    name="completionReport"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseTextarea
                        className="mr8"
                        maxLength={1000}
                        placeholder="상세 내용을 입력하세요."
                        onChange={(value: string) => onChange(value)}
                        value={value || ""}
                        name={name}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>

            {/* 처리내용 이미지 등록 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>{`이미지 (${workOrderCompletedMedias?.length}/3)`}</p>
              </div>
              <PublicImageUpdate
                fileMaxLength={3}
                mediaFiles={workOrderCompletedMedias}
                onChangeMediaFiles={setWorkOrderCompletedMedias}
                categoryName={WorkOrderMediaType.COMPLETED}
                mediaServiceType={WorkOrderMediaType.COMPLETED as MediaServiceType}
              />
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>처리 비용</p>
              </div>
              <table className="cost-inner-table" width="940">
                <tbody>
                  {workOrderCostList.map((cost: WorkOrderSheetCostType & { id: string }, costIdx: number) => (
                    <tr key={cost.id} className={`${cost.cmdType === "D" ? "d-none" : ""} border-none`}>
                      <td width={500}>
                        <div className="flex-center-center mr10">
                          <Controller
                            control={control}
                            name={`workOrderSheetCostList.${costIdx}.description`}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return (
                                <BaseInput
                                  onChange={(text: string) => {
                                    onChange(text);
                                  }}
                                  value={value || ""}
                                  name={name}
                                  placeholder="지출 내용을 입력하세요."
                                  errorText={error && error.message}
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </td>
                      <td width={160}>
                        <div className="flex-center-center">
                          <Controller
                            control={control}
                            name={`workOrderSheetCostList.${costIdx}.cost`}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return (
                                <BaseInput
                                  onChange={(text: string) => {
                                    onChange(onlyNumber(text));
                                  }}
                                  value={value || ""}
                                  name={name}
                                  className="text-right"
                                  placeholder="금액을 입력하세요."
                                  errorText={error && error.message}
                                  maxLength={10}
                                />
                              );
                            }}
                          ></Controller>
                          <span className="ml10">원</span>
                        </div>
                      </td>
                      {/* v.11 워크오더 - 추가 버튼 제외 요청 */}
                      {/* <td width="25px">
                        <div className="flex-center-center">
                          {costIdx < 1 ? (
                            <button
                              className="base-add-btn"
                              onClick={(e) => {
                                e.preventDefault();

                                append({
                                  description: "",
                                  cost: 0,
                                  cmdType: "C",
                                });
                              }}
                            />
                          ) : (
                            <button
                              className="base-trash-btn color-gray"
                              onClick={(e) => {
                                e.preventDefault();
                                if (cost.cmdType === "C") {
                                  remove(costIdx);
                                } else {
                                  update(costIdx, { ...cost, id: cost.id, cmdType: "D" });
                                }
                              }}
                            />
                          )}
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>{`증빙 사진(${workOrderCostMedias?.length}/3)`}</p>
              </div>
              <PublicImageUpdate
                fileMaxLength={3}
                mediaFiles={workOrderCostMedias}
                onChangeMediaFiles={setWorkOrderCostMedias}
                categoryName={WorkOrderMediaType.COST}
                mediaServiceType="MEDIA_WORK_ORDER_SHEET_COST"
              />
            </section>
          </>
        )}

      {/* {(watch("status") === WorkOrderStatus.COMPLETED || watch("status") === WorkOrderStatus.CLOSED) && (
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>접수 고객답변</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="">
              <Controller
                control={control}
                name={"customerNotification"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseTextarea
                    className="mr8"
                    maxLength={1000}
                    placeholder="답변을 입력하세요."
                    onChange={(value: string) => onChange(value)}
                    value={value || ""}
                    name={name}
                    errorText={error?.message}
                    // disabled={isCustomerDisabled(watch("reporterName") || "", workOrderDetail?.customerNotification || "")}
                  />
                )}
              ></Controller>
            </div>
            <ul className="list-marker ml10">
              <li className="mt10 text-primary3 font12">
                <span> 접수자를 지정하지 않은 경우 입력할 수 없습니다.</span>
              </li>
              <li className="ml16 mt4">
                <span>입력한 내용으로 접수자에게 알림톡이 발송되며, 최초 발송 후 수정할 수 없습니다.</span>
              </li>
            </ul>
          </div>
        </section>
      )} */}
    </article>
  );
};

export default WorkOrderEditSection;
