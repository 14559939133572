import _ from "lodash";
import moment from "moment";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { editCCM22, getCCM16, getCCM23, saveCCM22 } from "src/api/chargeMnt/chargeMnt-api";
import { ChartBasicDetail } from "src/api/chargeMnt/chargeMnt-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat } from "src/utils";

const BasicInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { openErrorModal } = useErrorModal();
  const { openToast } = useToast();
  const [status, setStatus] = useState("");

  const { executeAsync: getBuildingCharge } = useApiOperation(getCCM16);
  const { executeAsync: getChartDetail } = useApiOperation(getCCM23);

  const { executeAsync: addGroupItem } = useApiOperation(saveCCM22);
  const { executeAsync: editGroupItem } = useApiOperation(editCCM22);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [ctMntGroupList, setCtMntGroupList] = useState<any>([]);

  const [ctMntGroup, setCtMntGroup] = useState<ChartBasicDetail>({
    ctMntChart: {
      groupId: "",
      yearMonth: "",
      calcStartDate: "",
      calcEndDate: "",
      billIssueDate: "",
      billCloseDate: "",
      noticeMemo: "",
      isDeleted: false,
    },
  });

  const onChangeBuilding = useCallback(
    async (building: any) => {
      const temp: ChartBasicDetail = _.cloneDeep(ctMntGroup);

      temp.buildingId = building.id;
      temp.buildingName = building.buildingName;

      setCtMntGroup(temp);
      const response: any = await getBuildingCharge({ buildingId: temp.buildingId });

      if (response.status > 199 && response.status < 300) {
        const buildingChargeGroup = response.data.data.ctMntGroupList;
        const selectList = buildingChargeGroup.map((chargeGroup: any) => {
          return { label: chargeGroup.groupName, value: chargeGroup.groupId };
        });
        setCtMntGroupList(selectList);
      }
      setIsOpen(false);
    },
    [ctMntGroup, getBuildingCharge],
  );

  const moveDetail = useCallback(() => {
    const path =
      PagePath.chargeMnt.detail.replace(":id", String(id)) + "?" + decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [id, navigate, queryParams]);

  const groupDetail = useCallback(
    async (chartId: number) => {
      const response: any = await getChartDetail({ chartId });
      console.log("form detail", response);
      if (response.status >= 200 && response.status <= 299) {
        const building = {
          id: response.data.data.ctMntChartBundle.buildingId,
          buildingName: response.data.data.ctMntChartBundle.buildingName,
        };
        onChangeBuilding(building);
        setCtMntGroup(response.data.data.ctMntChartBundle);
        const st =
          response.data.data.ctMntChartBundle.ctMntChart.mntStatus === "READY"
            ? "대기"
            : response.data.data.ctMntChartBundle.ctMntChart.mntStatus === "PROGRESS"
            ? "진행"
            : "완료";
        setStatus(st);
        // setTitle(response.data.data.content.groupName);
      }
    },
    [getChartDetail, onChangeBuilding],
  );

  const editGI = useCallback(async () => {
    const temp = _.cloneDeep(ctMntGroup);
    if (temp.ctMntChart) {
      temp.ctMntChart.calcStartDate = moment(temp.ctMntChart.calcStartDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.calcEndDate = moment(temp.ctMntChart.calcEndDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.billIssueDate = moment(temp.ctMntChart?.billIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.billCloseDate = moment(temp.ctMntChart?.billCloseDate).format(YmdFormat.WITH_TIME_ZONE);
    }

    const response: any = await editGroupItem(temp.ctMntChart);
    console.log("editGi", response);
    if (response.status >= 200 && response.status < 300) {
      const path = PagePath.chargeMnt.detail.replace(":id", response.data.data.ctMntChart.chartId);
      navigate(path);
      openToast({ content: "정상적으로 저장 되었습니다." });
    }
  }, [ctMntGroup, editGroupItem, navigate, openToast]);

  const onSave = useCallback(async () => {
    setIsOpen3(false);
    if (ctMntGroup.buildingId && ctMntGroup.buildingName) {
      if (ctMntGroup.ctMntChart?.chartId) {
        editGI();
      }
    } else {
      setIsOpen2(true);
    }
  }, [ctMntGroup.buildingId, ctMntGroup.buildingName, ctMntGroup.ctMntChart?.chartId, editGI]);

  const goEditBasic = useCallback(() => {
    const path = PagePath.chargeMnt.form + "?id=" + id + "&tab=basicInfo";
    navigate(path);
  }, [id, navigate]);

  useEffect(() => {
    if (id) {
      groupDetail(Number(id));
    }
  }, [id]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 건물 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">건물</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <p>
                    [{ctMntGroup?.buildingId}]{ctMntGroup?.buildingName}
                  </p>
                </div>
              </div>
            </section>
            {/* 부과항목 그룹 명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">부과항목 그룹</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <p>{ctMntGroup?.groupName}</p>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">회차(년월)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <p>{moment(ctMntGroup?.ctMntChart?.yearMonth).format(ViewYmdFormat.YYYY_MM)}</p>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">산출 기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div>
                    <p>시작일 {moment(ctMntGroup?.ctMntChart?.calcStartDate).format(ViewYmdFormat.FULL)}</p>
                  </div>
                  <div className="ml10">
                    <p>종료일 {moment(ctMntGroup?.ctMntChart?.calcEndDate).format(ViewYmdFormat.FULL)}</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">납부 기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div>
                    <p>청구일 {moment(ctMntGroup?.ctMntChart?.billIssueDate).format(ViewYmdFormat.FULL)}</p>
                  </div>
                  <div className="ml10">
                    <p>마감일 {moment(ctMntGroup?.ctMntChart?.billCloseDate).format(ViewYmdFormat.FULL)}</p>
                  </div>
                  <div className="ml10">
                    <p></p>
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">공지사항</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <p>{ctMntGroup?.ctMntChart?.noticeMemo}</p>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>생성일시</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <div className="font14">
                    {ctMntGroup?.ctMntChart?.createdDate ? moment(ctMntGroup?.ctMntChart.createdDate).format(ViewYmdFormat.FULL) : "-"}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>등록 ID</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <div className="font14">{ctMntGroup?.ctMntChart?.createdBy}</div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>최종 수정일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <div className="font14">
                    {ctMntGroup?.ctMntChart?.modifiedDate ? moment(ctMntGroup?.ctMntChart.modifiedDate).format(ViewYmdFormat.FULL) : "-"}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>최종 작성자</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <div className="font14">{ctMntGroup?.ctMntChart?.modifiedBy}</div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>진행상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax120">
                    <div className="font14">
                      {ctMntGroup?.ctMntChart?.mntStatus === "READY" && "대기"}
                      {ctMntGroup?.ctMntChart?.mntStatus === "PROGRESS" && "진행"}
                      {ctMntGroup?.ctMntChart?.mntStatus === "COMPLETE" && "완료"}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
          <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => onSave()} onClose={() => setIsOpen3(false)}>
            <p>저장 하시겠습니까?</p>
          </BaseModal>
          <BaseModal isOpen={isOpen2} btnRightTitle="확인" onClick={() => setIsOpen2(false)}>
            <p>건물은 필수 선택 요소입니다.</p>
          </BaseModal>
          {/* <BaseModal
        isOpen={isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={(e?: any) => {}}
        onClose={() => setIsOpen(false)}
      >
        <p>"사용안함"으로 전환하시겠습니까?</p>
                    </BaseModal> */}
          <BaseModal isOpen={isOpen4} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => moveDetail()} onClose={() => setIsOpen4(false)}>
            <p>상세페이지로 이동하시겠습니까?</p>
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => navigate(PagePath.chargeMnt.list)} />
          <BaseButton title="삭제" onClick={() => setIsOpen2(true)} className="no-outline color-white size-large text-red900 ml10" />
        </div>
        <div className="right-area">
          <BaseButton title="수정" className=" size-large" onClick={goEditBasic} />
        </div>
      </div>
    </>
  );
};
export default BasicInfo;
