import { atom } from "recoil";

/*
  이 atom은 API 요청 횟수를 관리하는 atom입니다.
  API 요청 횟수가 0이면, 로딩바를 숨깁니다.
  API 요청 횟수가 1 이상이면, 로딩바를 보여줍니다.
*/
export const apiCallCountState = atom<number>({
  key: "apiCallCountState",
  default: 0,
});
