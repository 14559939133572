import { DetailTotalData } from "src/api/contract/contract-types";
import { ArticleSection } from "src/components/layout/ArticleSection";
import ContractProductDetail from "./detailComponent/ContractProductDetail";

/**
 * 상품정보 영역
 * Props
 * { productDetail,
 *   providerDetail }
 */
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  viewOnly?: boolean;
}

const ContractProductInfo = ({ detailData, viewOnly }: ContractBasicProp) => {
  return (
    <ArticleSection
      title={"상품 정보"}
      children={
        <>
          {(viewOnly === undefined || viewOnly === true) && <ContractProductDetail detailData={detailData!} />}
          {/* {viewOnly === false && <ContractProductEdit detailData={detailData!} saveObject={undefined} productDetail={undefined} setProductDetail={undefined} />} */}
        </>
      }
    />
  );
};

export default ContractProductInfo;
