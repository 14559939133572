import React, { Dispatch, SetStateAction, memo, useCallback, useEffect, useRef, useState } from "react";
import { uploadFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { Document, Page, pdfjs } from "react-pdf";
import { SaveMediaDto } from "src/api/organization/org-types";
import { BaseButton, BaseModal, ResizedImage } from "src/components";
import { BaseAbstractModal } from "./BaseAbstractModal";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, Modal } from "src/api/public-types";
import { useAuth0 } from "@auth0/auth0-react";
import onErrorImage from "src/assets/images/onerror_preview.svg";
import useCheckOffset from "src/hooks/useCheckOffset";
import { uniqueId } from "lodash";

export interface DragDropState {
  draggedFrom: number | null;
  draggedTo: number | null;
  isDragging: boolean;
  originalOrder: SaveMediaDto[];
  updatedOrder: SaveMediaDto[];
}
interface ImagesUpdateProps {
  isOnlyView?: boolean; // detail일 경우 true
  urlKey?: string; // url 로 사용하는 key 명칭. 없으면 기본 'key'
  images?: SaveMediaDto[]; // 부모에 저장된 image 전달
  setImages?: React.Dispatch<React.SetStateAction<SaveMediaDto[]>>; // 부모에서 이미지 저장할 setState
  className?: string;
  categoryName?: string; // 여러 ImageUpdate 컴포넌트 사용시 대표 radio 구분
  fileMaxLength?: number; // 이미지 최대 저장 length
  accept?: string; // 파일 확장자 범위, 컴마로 구분 ex).png,.jpg
  type: "LOGO" | "PROOF";
  serviceTag?: string;
  maxWidth?: number;
}

/**
 * v1.11 개선사항 적용 (24.3.8.)
 */
const s3Url = process.env.REACT_APP_S3_BASEURL;

export async function getFileDownload(axios: AxiosInstance, param: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const response = await axios.get<ApiResponse<any>>(param?.url, { responseType: "blob" });
  return response;
}

const BaseImageUpload = memo(
  ({ setImages, images, serviceTag, type, fileMaxLength = 10, isOnlyView, accept, maxWidth = 1070 }: ImagesUpdateProps) => {
    const [previewImage, setPreviewImage] = useState<SaveMediaDto[]>([...(images || [])]);
    const fileRef = useRef<HTMLInputElement>(null);
    const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync);
    const [modalOpen, setModalOpen] = useState<Modal>({ isOpen: false });
    const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState<number>(1); // pdf 개별 페이지
    const [numPages, setNumPages] = useState<number>(0);
    const [focusFullScreenImageIndex, setFocusFullScreenImageIndex] = useState(0);
    const { executeAsync: getFile } = useApiOperation(getFileDownload, { noAuthenticationRequired: true, noHandleError: true });
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const maxSize = 10;
    const fileValidationText = `한 파일당 업로드 용량은 ${maxSize}MB, 최대 ${fileMaxLength}개까지만 가능합니다.`;

    const [token, setToken] = useState("");

    const getToken = useCallback(async () => {
      await getAccessTokenSilently();
      const response = await getIdTokenClaims();
      const idToken = response?.__raw;
      setToken(idToken!);
    }, [getAccessTokenSilently, getIdTokenClaims]);

    useEffect(() => {
      if (token === "") {
        getToken();
      }
    }, [getAccessTokenSilently, getIdTokenClaims, getToken, token]);

    useEffect(() => {
      if (previewImage.length !== images?.length) {
        setPreviewImage(images!);
      }
    }, [images, previewImage]);

    const isFileValidatiaon = (fileArray: FileList) => {
      const sizeLimit = 10 * 1024 * 1024;
      let validationStatus = true; // false일 경우 validation에 걸림
      if (fileArray) {
        // 파일의 사이즈 체크
        for (let i = 0; i < fileArray.length; i++) {
          if (fileArray[i].size > sizeLimit) {
            setModalOpen({ isOpen: true, message: fileValidationText });
            validationStatus = false;
            break;
          }
        }
        // 파일 갯수 체크
        const inputCount = fileArray.length;
        const sum = inputCount + previewImage.length;
        if (fileMaxLength && fileMaxLength < sum) {
          setModalOpen({ isOpen: true, message: fileValidationText });
          validationStatus = false;
        }
      }
      return validationStatus;
    };

    // 이미지 업로드시 s3 저장
    const s3ImageUpload = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileArray = event.target.files!;

        if (!isFileValidatiaon(fileArray)) return;

        const inputCount = fileArray.length;
        let s3Array: Array<any> = [];

        if (fileArray) {
          for (let i = 0; i < inputCount; i++) {
            let file = fileArray[i];
            // 이미지 s3 저장소로 자동저장
            const formData = new FormData();

            formData.append("file", file);

            const res = await postImageFile({ file: formData, type: type === "LOGO" ? "public" : "private" });

            if (res && res.status >= 200 && res.status <= 299) {
              const data = JSON.parse(JSON.stringify({ ...res.data.data.media, ...{ type, serviceTag, isDeleted: false } }));
              // setFiles(s3Array);
              const readAsDataURL = (file: Blob) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    resolve(reader.result);
                  };
                  reader.onerror = reject;
                  reader.readAsDataURL(file);
                });
              };

              try {
                const blobUrl = await readAsDataURL(file);
                s3Array.push({ ...data, blobUrl });
                // s3Array.unshift(blobUrl);
              } catch (error) {
                console.error("Error reading file:", error);
              }
            } else {
            }

            const newArra: any = [...s3Array, ...images!];

            if (setImages) setImages(newArra);

            setPreviewImage(newArra);
          }
        }
        event.target.value = "";
      },
      [previewImage.length, fileMaxLength, postImageFile, images, setImages],
    );

    // 이미지 삭제
    const onImageClear = (idx: number, e: any) => {
      e.stopPropagation();
      const removeFile = JSON.parse(JSON.stringify([...previewImage]));
      removeFile.splice(idx, 1);
      setPreviewImage(removeFile);
      if (setImages) setImages(removeFile);
    };

    // 풀스크린 이미지 열기
    const handleOpenFullImage = (idx: number) => {
      setFocusFullScreenImageIndex(idx);
      setIsFullScreenImageOpen((prev) => !prev);
    };
    // 풀스크린 좌우 버튼 핸들러
    const handleFullScreenImage = (position: "left" | "right") => {
      if (position === "left") {
        setFocusFullScreenImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
      }
      if (position === "right") {
        setFocusFullScreenImageIndex((prev) => (prev < previewImage.length - 1 ? prev + 1 : prev));
      }
    };

    const findImageURL = (file: any) => {
      console.log("file>>", file);
      if (file.blobUrl) {
        return file.blobUrl;
      } else if (file.url) {
        return file.type === "LOGO" ? file.url : file.url + "?access_token=" + token;
      } else if (!file.url && file.key?.includes("http")) {
        return file.key;
      } else if (!file.key?.includes("http") && file.key) {
        return s3Url + file?.key!;
      } else return "";
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages);
    }

    const handlePdfLeft = () => {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    };
    const handlePdfRight = () => {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      }
    };

    const renderFileView = (file: any) => {
      const fileUrlWithToken = file.blobUrl
        ? file.blobUrl
        : file.url
        ? file.url + "?access_token=" + token
        : s3Url + file.key + "?access_token=" + token;
      const fileName = file.filename;
      const fileExtension = file.filename.slice(file.filename.lastIndexOf(".") + 1);
      const availableFileExtensionArray = ["jpg", "jpeg", "gif", "png", "svg", "bmp"];

      // 이미지
      if (availableFileExtensionArray?.includes(fileExtension.toLowerCase())) {
        return (
          <>
            <img
              src={findImageURL(file)}
              alt={fileName || "이미지를 불러오는데 실패했습니다."}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = onErrorImage;
              }}
            />
            <p className="file-name">{fileName}</p>
          </>
        );
      }

      // 이미지가 아닐경우
      switch (fileExtension) {
        case "pdf":
          return (
            <div className="file-viewer-pdf">
              <Document className="file-viewer-pdf__document" file={fileUrlWithToken ? fileUrlWithToken : ""} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} renderAnnotationLayer={false} loading={"loading..."} onLoadError={console.error} />
              </Document>
              <div className="button-wrap">
                <button className="left" disabled={pageNumber === 1} onClick={handlePdfLeft}></button>
                <button className="right" disabled={pageNumber === numPages} onClick={handlePdfRight}></button>
              </div>
            </div>
          );
        default:
          return (
            <div className="no-preview-available">
              <p>미리보기를 제공하지 않는 파일 형식입니다.</p>
              <p>다운로드 후 확인해 주세요.</p>
              <p className="file-name">{fileName}</p>
            </div>
          );
      }
    };

    useEffect(() => {
      const escapeKeyHandler = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          e.preventDefault();
          setIsFullScreenImageOpen(false);
        }
      };

      window.addEventListener("keyup", escapeKeyHandler);

      return () => {
        window.removeEventListener("keyup", escapeKeyHandler);
      };
    }, []);

    // response의 headers에 있는 content-disposition을 찾아서 디코딩 한다.
    // const forceDownload = useCallback(
    //   async (url: string, filename: string) => {
    //     const gf: any = await getFile({ url });
    //     const blob = new Blob([gf.data]);
    //     const fileUrl = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = fileUrl;
    //     link.style.display = "none";

    //     link.download = filename;

    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();

    //     window.URL.revokeObjectURL(fileUrl);
    //   },
    //   [getFile],
    // );
    const handleImageDownload = async (filename: string, url?: string) => {
      try {
        const response = await axios.get(url + "?access_token=" + token, { responseType: "blob" });
        const blob = new Blob([response.data]);
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = filename;
        link.click();
      } catch (error) {
        console.log("다운로드실패", error);
      }
    };

    const sliceTitle = (fileName: string) => {
      const dotIndex = fileName.lastIndexOf(".");
      const title = fileName.slice(0, dotIndex);
      const extension = fileName.slice(dotIndex + 1);
      return { title, extension };
    };

    return (
      <div className="base-image-wrap" style={{ width: maxWidth }}>
        {!isOnlyView && (
          <>
            <input
              ref={fileRef}
              type="file"
              style={{ display: "none" }}
              accept={accept ? accept : "*"}
              onChange={s3ImageUpload}
              // onChange={onSaveImages}
              multiple
            />
            <BaseButton
              title={"파일찾기"}
              className="color-white"
              disabled={fileMaxLength && previewImage.length >= fileMaxLength ? true : false}
              onClick={() => {
                console.log(fileRef.current);
                fileRef.current?.click();
              }}
            />
            {previewImage.length === 0 && (
              <ul className="base-list mt10">
                <li>문서를 카메라로 찍거나 스캔하여 업로드해주세요.</li>
                <li>업로드 용량은 개당 10MB까지 가능합니다.</li>
              </ul>
            )}
          </>
        )}
        <div className="base-image" style={{ overflowX: "auto" }}>
          {previewImage &&
            previewImage.map((image, idx) => {
              return (
                <div
                  className={`base-image__wrap ${isOnlyView ? "detail" : "form"}`}
                  key={idx}
                  draggable={true}
                  data-position={idx}
                  onClick={() => handleOpenFullImage(idx)}
                >
                  <img
                    src={findImageURL(image)}
                    alt="preview-images"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = onErrorImage;
                    }}
                  />
                  {!isOnlyView && <button type="button" onClick={(e) => onImageClear(idx, e)} />}

                  <div className="title-area">
                    <div className="not-hover">
                      <p>{sliceTitle(image.filename).title}</p>
                      <span>.{sliceTitle(image.filename).extension}</span>
                    </div>
                    <div className="active-hover">
                      <p>{sliceTitle(image.filename).title}</p>
                      <span>.{sliceTitle(image.filename).extension}</span>
                      {isOnlyView && (
                        <button
                          type="button"
                          className="detail-download"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageDownload(previewImage[idx].filename, previewImage[idx].url);
                          }}
                        ></button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {isFullScreenImageOpen && (
            <BaseAbstractModal isNotUseWhiteBoard isOpen={true} opacity={0.75}>
              <div className="base-image__full-screen">
                {renderFileView(previewImage[focusFullScreenImageIndex])}
                <div className="img-button-wrap">
                  <div className="flicker">
                    <button className="left" onClick={() => handleFullScreenImage("left")}></button>
                    <p>
                      {focusFullScreenImageIndex + 1} / {previewImage.length}
                    </p>
                    <button className="right" onClick={() => handleFullScreenImage("right")}></button>
                  </div>
                  <a
                    target="_self"
                    className="download"
                    href={" "}
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleImageDownload(previewImage[focusFullScreenImageIndex].filename, previewImage[focusFullScreenImageIndex].url);
                    }}
                  >
                    {""}
                  </a>
                  <button className="close" onClick={() => setIsFullScreenImageOpen(false)}>
                    닫기
                  </button>
                </div>
              </div>
            </BaseAbstractModal>
          )}

          {modalOpen.isOpen && (
            <BaseModal isOpen={true} title={`첨부파일 업로드가 실패했습니다.`} btnRightTitle="확인" onClick={() => setModalOpen({ isOpen: false })}>
              <p>{modalOpen.message}</p>
            </BaseModal>
          )}
        </div>
      </div>
    );
  },
);

export default React.memo(BaseImageUpload);
