import { BaseCheckbox, BaseInput } from "src/components";
import { ViewYmdFormat, numberToStringWithComma, onlyNumber } from "src/utils";
import produce from "immer";
import { CellValue, Row } from "react-table";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { SpecificTableData } from "../modal/Price";
import { Dispatch, SetStateAction } from "react";
import { PriceRulesModel } from "src/api/price/price-types";
import moment from "moment";
import _ from "lodash";
import { PagePath } from "src/pages/product/details";
import BaseNewTabLink from "src/components/BaseNewTabLink";

interface CellProps<V = any> {
  value: CellValue<V>;
  space: CommonFacilityListModel;
  row: Row<SpecificTableData>;
  isEditable: boolean;
  setSpecificTableData: Dispatch<SetStateAction<SpecificTableData[]>>;
}

export const base_cols = [
  {
    Header: "정책 id",
    accessor: "id",

    width: 88,
  },
  {
    Header: "기본 요금 유형",
    accessor: "priceRuleType",

    width: 200,
    Cell: ({ value }: { value: PriceRulesModel["priceRuleType"] }) => {
      return (
        <p>
          {value === "PRICE_RULE_PROVIDER" && "프로바이더 기본 요금"}
          {value === "PRICE_RULE_FACILITY" && "공용공간 기본 요금"}
          {value === "PRICE_RULE_SPECIFIC" && "프로바이더 기본 요금"}
          {value === "PRICE_RULE_UNRECOGNIZED" && "정의되지 않은 상태"}
        </p>
      );
    },
  },
  {
    Header: "공용공간",
    accessor: "buildingCommonFacilityId",
    width: 190,
    Cell: ({ value, space }: CellProps) => {
      return <p className="w100 flex-center-start ellipsis2">{space?.id === value ? space?.facilityName : "-"}</p>;
    },
  },
];
export const id_viewer = [
  {
    Header: "정책 id",
    accessor: "id",
    width: 80,
    Cell: ({ value }: CellProps) => {
      return <p>{value ?? "-"}</p>;
    },
  },
];
export const id_selector = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 80,
    Cell: ({ row, setSpecificTableData }: CellProps) => {
      const isBeforeDate = moment(row.original.endDate).isBefore(moment());
      return (
        <div className="flex-row flex-center">
          <BaseCheckbox
            disabled={isBeforeDate}
            id={row.original.id + "_isSelected"}
            name={row.original.id + "_isSelected"}
            checked={row.original.isSelected}
            onChange={(checked: boolean) => {
              setSpecificTableData(
                produce((draftArr) => {
                  draftArr.forEach((draftObj) => {
                    if (draftObj.id === row.original.id) {
                      draftObj.isSelected = checked;
                      if (!checked) {
                        draftObj.priority = "";
                      }
                    }
                  });
                }),
              );
            }}
          />
        </div>
      );
    },
  },
];
export const specific_cols = [
  {
    Header: "적용 기간",
    accessor: "startDate",
    // sticky: "left",
    width: 224,
    Cell: ({ row }: CellProps) => {
      return (
        <p className="text_primary3">
          {moment(row.original.startDate).format(ViewYmdFormat.YYYY_MM_DD) + " ~ " + moment(row.original.endDate).format(ViewYmdFormat.YYYY_MM_DD)}
        </p>
      );
    },
  },
  {
    Header: "적용 시간",
    accessor: "startTime",
    // sticky: "left",
    width: 140,
    Cell: ({ row }: CellProps) => {
      return <p>{row.original.startTime + " ~ " + row.original.endTime}</p>;
    },
  },
];
export const price_name_cols = {
  Header: "요금 정책명",
  accessor: "name",
  // sticky: "left",
  width: 245,
  Cell: ({ value, row }: CellProps) => {
    return <BaseNewTabLink tooltip path={PagePath.price.detail.replace(":id", String(row.original.id))} value={value || ""} />;
  },
};
export const cost_cols = {
  Header: "원가 (원/30분)",
  accessor: "cost",
  // sticky: "left",
  width: 100,
  Cell: ({ value }: CellProps) => {
    return (
      <div className="w-100 text-right">
        <p>{numberToStringWithComma(value)}</p>
      </div>
    );
  },
};
export const price_cols = {
  Header: "판매가 (원/30분)",
  accessor: "price",
  // sticky: "left",
  width: 110,
  Cell: ({ value }: CellProps) => {
    // 무료
    return (
      <div className="w-100 text-right">
        <p>{value === 0 ? "무료" : numberToStringWithComma(value)}</p>
      </div>
    );
  },
};

export const priority_cols: any[] = [
  {
    Header: "가중치",
    accessor: "priority",
    width: 100,
    Cell: ({ row, isEditable, setSpecificTableData }: CellProps) => {
      const isBeforeDate = moment(row.original.endDate).isBefore(moment());
      return (
        <div className="minmax80 text-center">
          {isEditable ? (
            <BaseInput
              readonly={isBeforeDate}
              type="text"
              // className="text-center"
              name={"priority_" + row.original.id}
              onChange={(value: string) => {
                setSpecificTableData(
                  produce((draftArr) => {
                    draftArr.forEach((draft) => {
                      if (draft.id === row.original.id) {
                        draft.priority = onlyNumber(value);
                      }
                    });
                  }),
                );
              }}
              value={row.original.priority}
              disabled={!row.original.isSelected}
            />
          ) : row.original.priority ? (
            row.original.priority
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
];
