import { ContractStep } from "src/api/contract/contract-types";
import { WorkOrderStatus } from "src/api/work-order/workorder-types";
import PagePath from "src/pagePath.json";
import { IMenuDoubleSection } from "../types";

const BASE_PATH = `${PagePath.workOrder.authenticated.list}?&statusList=`;
const sortPath = (sortProperty: string) => `&sort.orders[0].property=${sortProperty}&sort.orders[0].direction=ASC`;
const WORKORDER_MENU_LIST: IMenuDoubleSection[] = [
  {
    type: "bar",
    element: [
      {
        title: "접수",
        count: -1,
        path: BASE_PATH + WorkOrderStatus.OPEN,
        status: WorkOrderStatus.OPEN,
      },
      // {
      //   title: "확인",
      //   count: -1,
      //   path: BASE_PATH + WorkOrderStatus.WAITING,
      //   status: WorkOrderStatus.WAITING,
      // },
      {
        title: "작업중",
        count: -1,
        path: BASE_PATH + WorkOrderStatus.PROGRESS,
        status: WorkOrderStatus.PROGRESS,
      },
      {
        title: "완료",
        count: -1,
        path: BASE_PATH + WorkOrderStatus.COMPLETED,
        status: WorkOrderStatus.COMPLETED,
      },
      {
        title: "종료",
        count: -1,
        path: BASE_PATH + WorkOrderStatus.CLOSED,
        status: WorkOrderStatus.CLOSED,
      },
      {
        title: "취소",
        count: -1,
        path: BASE_PATH + WorkOrderStatus.CANCELED,
        status: WorkOrderStatus.CANCELED,
      },
    ],
  },
  {
    type: "detail",
    element: [
      {
        title: "지연",
        count: -1,
        path:
          BASE_PATH +
          WorkOrderStatus.OPEN +
          "," +
          WorkOrderStatus.WAITING +
          "," +
          WorkOrderStatus.PROGRESS +
          "&isOverdue=true" +
          sortPath("startDate"),
        status: [WorkOrderStatus.OPEN, WorkOrderStatus.WAITING, WorkOrderStatus.PROGRESS],
        showDateProperty: "startDate",
      },
    ],
  },
];

export { WORKORDER_MENU_LIST };
