import { useApiOperation } from "src/api/hooks";
import { buildingsDetails } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { SpaceType, SpaceTypeT } from "src/pages/product/product-types";

/**
 * 프로덕트 API hook
 */

export function useProductApi() {
  // 빌딩 상세 리스트 호출
  const { executeAsync: getBuildingsDetails } = useApiOperation(buildingsDetails);

  /** 상품에 등록할 수 있는 공용공간 리스트 생성 */
  const makeAddableSpaceList = async (product: ProductModel, spaceType: SpaceTypeT) => {
    const buildingIds = product.buildingList?.map((building) => building.id.toString());

    if (!buildingIds) {
      return;
    }

    const result = await getBuildingsDetails({ building: { ids: buildingIds } });

    const tableData = result.data.data.content
      ?.map((data) => {
        if (spaceType === SpaceType.MEETING_ROOM) {
          return data.building.buildingCommonFacility?.meetingRoomList?.map((space) => {
            return { ...space, buildingName: data.building.buildingName };
          });
        }

        if (spaceType === SpaceType.REFRESH_ROOM) {
          return data.building.buildingCommonFacility?.refreshRoomList?.map((space) => {
            return { ...space, buildingName: data.building.buildingName };
          });
        }

        if (spaceType === SpaceType.DESK) {
          return data.building.buildingCommonFacility?.deskSpace?.deskGroupList
            ?.map((deskGroup) =>
              deskGroup.deskList?.map((desk) => {
                return { ...desk, groupName: deskGroup.groupName, buildingName: data.building.buildingName };
              }),
            )
            .flat();
        }
      })

      .flat();

    return tableData;
  };
  // return { product, setProduct, getProduct, postProduct, postCommonFacility, makeAddableSpaceList };

  return { makeAddableSpaceList };
}
