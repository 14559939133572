import { useCallback, useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { deleteProductsAsync } from "src/api/product/product-api";
import { ProductAddModel, ProductModel } from "src/api/product/product-types";
import { BaseButton, BaseModal } from "src/components";

import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { useToast } from "src/recoil/toast/hook";

import { getProviderList } from "src/api/provider/provider-api";
import { isString } from "src/utils";
import BuildingAndHoTable from "../../modalPopup/table/BuildingAndHoTable";
import { BuildingFloorAndHo, Modal, ProductStatusLabels, ProductTypeLabels, ProductTypes } from "../../product-types";

import { getProviderName, isPrimaryBuildingChecked, makeBuilding } from "../../forms/basicInfoForm/libs";

import moment from "moment";
import GoToListButton from "src/components/GoToListButton";
import { columns } from "./columns";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import Link from "src/components/PartnerLink";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

export type ProductFormData = {
  providerId: ProductAddModel["providerId"];
  productName: ProductAddModel["productName"];
  introduce: ProductAddModel["introduce"];
  buildingList: Array<BuildingFloorAndHo>;
  productType: ProductAddModel["productType"];
  status?: ProductAddModel["status"];
};

interface IProps {
  product: ProductModel;
  productId: string;
}

/* 
  공간상품 등록 > 상세 > 기본 정보
 */
const BasicInfoForm = ({ product: productDetail, productId }: IProps) => {
  const { isAuthority } = usePartnerAuthority();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const isEdit = isString(productId);

  const [selectedRow, setSelectedRow] = useState({});
  const [buildingAndHo, setBuildingAndHo] = useState<Array<BuildingFloorAndHo>>([]); // 테이블용

  // 모달 useState
  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  const [selectedProvider, setSelectedProvider] = useState<{
    providerId: string;
    uid: string;
    providerName: string;
  }>();

  // 공간상품 상세 삭제 api
  const { executeAsync: deleteProductDetail } = useApiOperation(deleteProductsAsync);

  //  프로바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  /** 수정모드에서 디폴트 벨류 초기화, 기본 정보 셋팅 */
  useEffect(() => {
    if (isEdit && productDetail?.buildingList) {
      const newBuilding = makeBuilding(productDetail["buildingList"]);

      setBuildingAndHo(newBuilding);
      isPrimaryBuildingChecked(newBuilding, setSelectedRow, productId);
    }
  }, [productDetail, isEdit]);

  /** 수정모드에서 프로바이더명 조회 후 setState */
  useEffect(() => {
    if (isEdit && productDetail?.providerId) {
      getProviderName(productDetail.providerId, getProviers).then((data) => setSelectedProvider(data));
    }
  }, [productDetail, isEdit]);

  const onDeleteProductDetail = useCallback(async () => {
    if (deleteModal.type === "PRODUCT_DELETE") {
      // 공간상품 삭제
      const response = await deleteProductDetail({ productId });
      if (response.status >= 200 && response.status <= 299) {
        openToast({
          content: `정상적으로 삭제 되었습니다.`,
        });
        navigate(PagePath.product.list);
      }
    }
  }, [deleteModal.type, deleteProductDetail, navigate, openToast, productId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <BaseSectionTitle title={"기본정보"} />

            {/* 프로바이더 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">프로바이더</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <Link
                    to={PagePath.provider.detail.replace(":id", isEdit ? productDetail?.providerId! : selectedProvider?.providerId!)}
                    target="_blank"
                    className={"text-underline"}
                  >
                    <span className="">{selectedProvider?.providerName}</span>
                    <span className="ic-target-blank"></span>
                  </Link>
                </div>
              </div>
            </section>

            {/* 상품 유형 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">상품 유형</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{ProductTypeLabels[productDetail.productType] ?? "-"}</span>
                </div>
              </div>
            </section>
            {productDetail.productType !== ProductTypes.DIGITAL_PRODUCT && (
              <>
                {/* 건물/호실 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">건물/호실</p>
                  </div>
                </section>
                {/*기본정보 페이지 내에 건물/호실 테이블 */}
                <div className="contents-container__grid-contents">
                  <BuildingAndHoTable
                    viewTable={buildingAndHo ?? []}
                    onSelectedRowsChange={setSelectedRow}
                    selectedRow={selectedRow}
                    readOnly={true}
                    detailColumn={columns}
                  />
                </div>
              </>
            )}

            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">상품명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{productDetail.productName ?? "-"}</span>
                </div>
              </div>
            </div>

            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">1줄 소개</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{productDetail.introduce ?? "-"}</span>
                </div>
              </div>
            </div>
            {isEdit && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">공개 여부</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span className="font14">{ProductStatusLabels[productDetail.status!] ?? "-"}</span>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
        <RegistrationInformation data={productDetail} />

        <AdminMemoV3 serviceId={Number(productId)} serviceType={ServiceTypes.SERVICE_PRODUCT}></AdminMemoV3>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
          {isAuthority("d") && (
            <BaseButton
              title="삭제"
              className="no-outline color-white size-large text-red900 ml10"
              onClick={() =>
                setDeleteModal({
                  isOpen: true,
                  message: "정말 삭제 처리하시겠습니까?",
                  type: "PRODUCT_DELETE",
                })
              }
            />
          )}
        </div>
        <div className="right-area d-flex">
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.product.form}?id=${productId}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>

      <BaseModal
        isOpen={deleteModal.isOpen}
        btnLeftTitle={"취소"}
        btnRightTitle="확인"
        onClose={() => setDeleteModal({ isOpen: false })}
        onClick={() => onDeleteProductDetail()}
      >
        {deleteModal.message}
      </BaseModal>
    </>
  );
};

export default BasicInfoForm;
