import { ContractStep } from "src/api/contract/contract-types";
import PagePath from "src/pagePath.json";
import { IMenuDoubleSection } from "../types";

const BASE_PATH = `${PagePath.contract.list}?&contractStep=`;
const sortPath = (sortProperty:string, direction:string) => `&sort.orders[0].property=${sortProperty}&sort.orders[0].direction=${direction}`

const CONTRACT_MENU_LIST: IMenuDoubleSection[] = [
  {
    type: "bar",
    element: [
      {
        title: "신청확인",
        count: -1,
        path: BASE_PATH + ContractStep.APPLY_CONFIRM,
        status: ContractStep.APPLY_CONFIRM,
      },
      {
        title: "신청취소",
        count: -1,
        path: BASE_PATH + ContractStep.APPLY_CANCEL,
        status: ContractStep.APPLY_CANCEL,
      },
      {
        title: "이용승인",
        count: -1,
        path: BASE_PATH + ContractStep.USE_APPROVAL,
        status: ContractStep.USE_APPROVAL,
      },
      {
        title: "이용중",
        count: -1,
        path: BASE_PATH + ContractStep.USE_PROGRESS,
        status: ContractStep.USE_PROGRESS,
      },
      {
        title: "이용완료",
        count: -1,
        path: BASE_PATH + ContractStep.USE_COMPLETE,
        status: ContractStep.USE_COMPLETE,
      },
      {
        title: "해지접수",
        count: -1,
        path: BASE_PATH + ContractStep.TERMINATE_RECEIVED,
        status: ContractStep.TERMINATE_RECEIVED,
      },
      {
        title: "해지완료",
        count: -1,
        path: BASE_PATH + ContractStep.TERMINATE_COMPLETE,
        status: ContractStep.TERMINATE_COMPLETE,
      },
    ],
  },
  {
    type: "detail",
    element: [
      {
        title: "계약예정",
        count: -1,
        path: BASE_PATH + ContractStep.CONTRACT_PLAN + sortPath("contractDate","DESC"),
        status: ContractStep.CONTRACT_PLAN,
        showDateProperty: "contractDate"
      },
      {
        title: "계약체결",
        count: -1,
        path: BASE_PATH + ContractStep.CONTRACT_ACCEPT + sortPath("contractStartTime","DESC"),
        status: ContractStep.CONTRACT_ACCEPT,
        showDateProperty: "contractStartTime"
      },

      {
        title: "완료예정",
        count: -1,
        path: BASE_PATH + ContractStep.USE_PROGRESS + "," + ContractStep.TERMINATE_RECEIVED + sortPath("contractEndTime","ASC"),
        status: [ContractStep.USE_PROGRESS, ContractStep.TERMINATE_RECEIVED],
        showDateProperty: "contractEndTime"
      },
    ],
  },
];

export { CONTRACT_MENU_LIST };
