import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "src/api/public-types";
import { BaseModal, ContentsTitle } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import BasicInfoForm from "./components/BasicInfoForm";

const tabs = [{ value: "basicInfo", label: "기본정보" }];

const VocForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // 기본정보 가 작성되지 않은 상태에서, 다른 탭으로 이동하려고 할 경우.
      if (tab.value !== "basicInfo" && !queryParams.id) {
        setModal({
          isOpen: true,
          message: "기본정보가 저장되지 않았습니다.",
        });
        return;
      }
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  return (
    <div>
      <ContentsTitle
        title="Voc 항목"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={(queryParams.id && [{ title: "신청id", value: String(queryParams.id) || "" }]) || []}
      />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab?.value === "basicInfo" && ( //
        <BasicInfoForm vocId={queryParams?.id ? String(queryParams.id) : ""} />
      )}

      <>
        {modal.isOpen && (
          <BaseModal isOpen={true} btnRightTitle={"확인"} onClick={() => setModal({ ...modal, isOpen: false })}>
            <p className="font18  text-gray900">{modal.message}</p>
          </BaseModal>
        )}
      </>
    </div>
  );
};

export default VocForm;
