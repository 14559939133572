import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getAccessGroupDeviceAsync, getAccessGroupListAsync, getVisitorAccessGroupListAsync } from "src/api/access/ac-api";
import { useApiOperation } from "src/api/hooks";
import { deleteVisitorGroup, getVisitorParkingPreDiscountListAsync } from "src/api/visitor/visitor-api";
import { BaseButton, BaseModal, ContentsTitle } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import AccessGroupDetailPopup from "src/pages/visitor/detail/components/AccessGroupDetailModal";
import { ViewYmdFormat, YmdFormat, formatPhoneNumber } from "src/utils";
import { getContractDetailPath, getProductDetailPath, getVisitorAccessInfoPath, getVisitorListPath } from "src/utils/route-util";
import { VISITOR_STATUS_MAP, VISIT_PURPOSE_MAP, VISIT_TYPE_MAP } from "../constants";
import useVisitor from "../hooks/useVisitor";
import { InvitationDetailItem, VisitorDetailItem } from "../visitor-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import qs from "qs";
import { PreDiscountModal } from "src/api/visitor/visitor-types";
import { some } from "lodash";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { useQueryParams } from "src/hooks/useQueryParams";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const VISITOR_ITEMS: VisitorDetailItem[] = [
  {
    key: "name",
    render: ({ name }) => <p>{name}</p>,
  },
  {
    key: "phoneNumber",
    render: ({ phoneNumber }) => <p>{formatPhoneNumber(phoneNumber) || "-"}</p>,
  },
  {
    key: "visitorEmail",
    render: ({ visitorEmail, visitorEmailLang }) => {
      if (visitorEmail) {
        return (
          <p>
            {visitorEmail} {visitorEmailLang === "ko" ? "(국문)" : "(영문)"}
          </p>
        );
      } else {
        return <p>-</p>;
      }
    },
  },
  {
    key: "carNumber",
    render: (visitorInfo, preDiscountList) => (
      <div className="flex-center-center flex-column">
        <p>{visitorInfo?.carNumber || "-"}</p>

        {preDiscountList?.some((preDiscountList) => preDiscountList?.preDiscountId === visitorInfo?.preDiscountId) && (
          <span className="text-primary3 font14">주차 혜택 적용</span>
        )}
      </div>
    ),
  },

  {
    key: "inviteTime",
    render: ({ createdAt }) => <p>{moment(createdAt).format(ViewYmdFormat.FULL)}</p>,
  },
  {
    key: "invitee",
    render: ({ createdBy }) => <p>{createdBy || "-"}</p>,
  },
  {
    key: "contractVisitorId",
    render: ({ contractVisitorId, visitApplyNumber, contractManageId, contractApplyNumber }) => {
      return (
        <div className="flex-center-center">
          <BaseNewTabLink
            path={getVisitorAccessInfoPath({
              contractVisitorId: String(contractVisitorId),
              contractManageId: String(contractManageId),
              contractApplyNumber: String(contractApplyNumber),
              visitApplyNumber: String(visitApplyNumber),
            })}
            value={"확인하기"}
            className="w-100 text-left"
          />
        </div>
      );
    },
  },
  {
    key: "QRLink",
    render: ({ QRLink, visitorEmailLang }) => {
      // console.log("QRLink :>> ", QRLink);
      return (
        <div className="flex-center-center">
          <BaseNewTabLink path={QRLink + `?lang=${visitorEmailLang}`} value={"바로가기"} className="w-100 text-left" />
        </div>
      );
    },
  },
];

const SELF_VISITOR_ITEMS = VISITOR_ITEMS.filter(({ key }) => key === "QRLink" || key === "phoneNumber" || key === "carNumber");

function VisitorDetail() {
  const { isAuthority } = usePartnerAuthority();
  const { visitApplyNumber, contractApplyNumber } = useParams();
  const [preDiscountList, setPreDiscountList] = useState<PreDiscountModal[]>();

  const location = useLocation();
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const tabs = [
    {
      label: "상세정보",
      value: "basic",
      disabled: false,
    },
  ];

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0], [queryParams]);
  // 탭 클릭시 callback 함수
  const clickTab = (tab: { value: string; label: string }) => {
    const newQueryParamStr = qs.stringify({ tab: tab.value, id: queryParams.id }, { allowDots: true });
    navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
  };

  const { invitationInfo, visitorInfoList } = useVisitor({ visitApplyNumber, contractApplyNumber });

  const { executeAsync: getVisitorParkingPreDiscountList } = useApiOperation(getVisitorParkingPreDiscountListAsync);

  useEffect(() => {
    const fetchApi = async () => {
      if (invitationInfo && visitorInfoList?.[0]?.preDiscountId && invitationInfo.contractId) {
        if (visitorInfoList[0].preDiscountId === "0") return;

        const preDiscountIds = visitorInfoList.map((data) => data.preDiscountId).join(",");

        const getVisitorParkingPreDiscountListData = await getVisitorParkingPreDiscountList({
          contractId: invitationInfo.contractId,
          preDiscountIds: preDiscountIds,
        }).then((data) => data?.data?.data?.preDiscount);

        setPreDiscountList(getVisitorParkingPreDiscountListData);
      }
    };

    fetchApi();
  }, [invitationInfo, visitorInfoList, getVisitorParkingPreDiscountList]);

  const [modal, setModal] = useState({
    deleteConfirmModal: {
      isOpen: false,
      title: "삭제하시겠습니까?",
      content: "",
    },
    deleteOkModal: {
      isOpen: false,
      title: "삭제되었습니다.",
      content: "",
    },
    deleteFailModal: {
      isOpen: false,
      title: "삭제에 실패했습니다.",
      content: "",
    },
  });
  const { executeAsync: deleteVisitorGroupAsync } = useApiOperation(deleteVisitorGroup);

  // 방문자액세스 정보 api hook
  const { executeAsync: getUserAccess } = useApiOperation(getVisitorAccessGroupListAsync);
  const { executeAsync: getUserAccessByIds } = useApiOperation(getAccessGroupListAsync);
  const { executeAsync: getDeviceAccessByIds } = useApiOperation(getAccessGroupDeviceAsync);
  // 방문자액세스 정보
  const [userAccess, setUserAccess] = useState<any>();
  const [accessDetail, setAccessDetail] = useState<any>();
  const [accessGroupName, setAccessGroupName] = useState<any>("");

  // 출입그룹 상세 모달
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  const openModal = (modalName: string) => {
    setModal({
      ...modal,
      [modalName]: {
        ...modal[modalName as keyof typeof modal],
        isOpen: true,
      },
    });
  };

  const closeModal = (modalName: string) => {
    setModal({
      ...modal,
      [modalName]: {
        ...modal[modalName as keyof typeof modal],
        isOpen: false,
      },
    });
  };

  const handleClickDeleteModalOkButton = async () => {
    if (!visitApplyNumber) return;

    const result = await deleteVisitorGroupAsync({ visitApplyNumber });
    if (result.status >= 200 && result.status < 300) {
      openModal("deleteOkModal");
    } else {
      openModal("deleteFailModal");
    }
  };

  const fetchUserAccess = useCallback(
    async (visitorId: string) => {
      const response: any = await getUserAccess({ contractManageId: visitorId });
      // console.log("response", response);

      let prs = "";
      response.data?.data?.accessGroup.map((obj: any) => {
        if (prs === "") {
          prs = obj.accessGroupId;
        } else {
          prs = prs + "," + obj.accessGroupId;
        }
      });

      console.log("prs", prs);
      if (prs !== "") {
        const res2 = await getUserAccessByIds({ ids: prs });
        setUserAccess(res2?.data?.data?.content || null);
      }
    },
    [getUserAccess, getUserAccessByIds],
  );

  useEffect(() => {
    // console.info("visitorInfoList[0].contractManageId", visitorInfoList[0].contractManageId);
    if (visitorInfoList.length > 0 && visitorInfoList[0].contractManageId) {
      fetchUserAccess(visitorInfoList[0].contractManageId);
    }
  }, [fetchUserAccess, visitorInfoList]);

  return (
    <div>
      <MetaTag subTitle={visitApplyNumber} />
      <ContentsTitle
        title="방문자 초대"
        tabs={tabs}
        clickTab={clickTab}
        activeTab={activeTab}
        detailInfo={[{ title: "방문신청번호", value: visitApplyNumber || "Error" }]}
      />
      {activeTab!.value === "basic" && (
        <>
          <div className="contents-container__scroll">
            <div className="contents-container__wrap page-visitor">
              <article className="contents-container__wrap-article">
                <BaseSectionTitle title={"초대 정보"} />

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>신청/계약 번호</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>
                      <BaseNewTabLink
                        path={getContractDetailPath({
                          contractId: invitationInfo.contractId!,
                        })}
                        value={invitationInfo.contractApplyNumber ?? "-"}
                      />
                    </p>
                  </div>
                </section>
                <div className="flex-start">
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>공간상품 id</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>
                        <BaseNewTabLink
                          path={getProductDetailPath({
                            productId: invitationInfo.productId,
                          })}
                          value={invitationInfo.productId ?? "-"}
                        />
                      </p>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>공간상품명</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{invitationInfo.productName ?? "-"}</p>
                    </div>
                  </section>
                </div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>건물 주소</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{invitationInfo.buildingAddress ?? "-"}</p>
                  </div>
                </section>
                <div className="flex-start">
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>방문 시작일시</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{moment(invitationInfo.visitStartTime).format(ViewYmdFormat.FULL)}</p>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>방문 종료일시</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{moment(invitationInfo.visitEndTime).format(ViewYmdFormat.FULL)}</p>
                    </div>
                  </section>
                </div>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>만남 장소</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{invitationInfo.meetingPlace === "" ? "-" : invitationInfo.meetingPlace ?? "-"}</p>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>상태</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{VISITOR_STATUS_MAP[invitationInfo.visitStatus as keyof typeof VISITOR_STATUS_MAP] ?? "-"}</p>
                  </div>
                </section>
              </article>

              <article className="contents-container__wrap-article">
                <BaseSectionTitle title={"출입그룹"} />

                <section>
                  <table className="visitor-table w-full">
                    <thead>
                      <tr>
                        <th className="minmax400">ID</th>
                        <th className="minmax280">출입그룹명</th>
                        <th className="minmax294">방문자 허용</th>
                        <th className="minmax96">상세정보</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userAccess ? (
                        userAccess.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="flex-center-center">{item.id}</div>
                              </td>
                              <td>
                                <div className="flex-center-center">{item.accessGroupName}</div>
                              </td>

                              <td>
                                <div className="flex-center-center">
                                  <p>{item.visitorAccessYn ? "O" : "N"}</p>
                                </div>
                              </td>
                              <td className="flex-center-center">
                                <BaseButton
                                  title="상세 정보"
                                  className="color-white size-small font-weight-normal"
                                  onClick={async () => {
                                    const details: any = await getDeviceAccessByIds({ accessGroupId: item.id });
                                    setAccessGroupName(item.accessGroupName);
                                    setAccessDetail(details.data.data.content || []);
                                    setShowDetailModal(true);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </section>
              </article>
              <article className="contents-container__wrap-article">
                <BaseSectionTitle title={"방문자 정보"} />

                <section>
                  {invitationInfo.visitType === "INVITE_REGIST" && (
                    <table className="visitor-table text-center w-full">
                      <thead>
                        <tr>
                          <th>이름</th>
                          <th>방문자 연락처</th>
                          <th>방문자 이메일</th>
                          <th>차량</th>
                          <th>초대일시</th>
                          <th>초대자</th>
                          <th>엑세스 권한</th>
                          <th>QR 링크</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visitorInfoList.map((visitorInfo, index) => (
                          <tr key={index}>
                            {VISITOR_ITEMS.map(({ key, render }) => (
                              <td key={key}>{render(visitorInfo, preDiscountList)}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {invitationInfo.visitType === "SELF_REGIST" && (
                    <table className="inner-table text-center" width="400">
                      <thead>
                        <tr>
                          <th>휴대폰 번호</th>
                          <th>차량</th>
                          <th>QR 링크</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visitorInfoList.map((visitorInfo, index) => (
                          <tr key={index}>
                            {SELF_VISITOR_ITEMS.map(({ key, render }) => (
                              <td key={key}>{render(visitorInfo, preDiscountList)}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </section>
              </article>
              <RegistrationInformation data={invitationInfo} />

              <AdminMemoV3 serviceId={Number(visitApplyNumber)} serviceType={"SERVICE_INVITED_VISITOR_GROUP"} />
            </div>
          </div>

          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <GoToListButton />
              {isAuthority("d") && (
                <BaseButton
                  title="삭제"
                  className="color-white no-outline size-large ml8 text-red900"
                  onClick={() => {
                    openModal("deleteConfirmModal");
                  }}
                />
              )}
            </div>
            <div className="right-area">
              {isAuthority("w") && invitationInfo.visitType !== "SELF_REGIST" && invitationInfo.visitStatus !== "VISIT_CLOSE" && (
                <BaseButton
                  title="수정"
                  className="size-large"
                  onClick={() => {
                    const visitorFormPath = PagePath.visitor.edit
                      .replace(":visitApplyNumber", visitApplyNumber!)
                      .replace(":contractApplyNumber", contractApplyNumber!);

                    navigate(visitorFormPath);
                  }}
                />
              )}
            </div>
          </div>
          {modal.deleteConfirmModal.isOpen && (
            <BaseModal
              isOpen={true}
              title={modal.deleteConfirmModal.title}
              btnRightTitle="확인"
              btnLeftTitle="취소"
              onClick={handleClickDeleteModalOkButton}
              onClose={() => {
                closeModal("deleteConfirmModal");
              }}
            >
              {modal.deleteConfirmModal.content}
            </BaseModal>
          )}

          {modal.deleteOkModal.isOpen && (
            <BaseModal isOpen={true} title={modal.deleteOkModal.title} btnRightTitle="확인" onClick={() => navigate(getVisitorListPath())}>
              {modal.deleteOkModal.content}
            </BaseModal>
          )}

          {modal.deleteFailModal.isOpen && (
            <BaseModal isOpen={true} title={modal.deleteFailModal.title} btnRightTitle="확인" onClick={() => navigate(getVisitorListPath())}>
              {modal.deleteFailModal.content}
            </BaseModal>
          )}

          <AccessGroupDetailPopup
            isOpen={showDetailModal}
            accessGroupName={accessGroupName}
            data={accessDetail}
            close={() => setShowDetailModal(false)}
          />
        </>
      )}
      {activeTab!.value === "AdminMemo" && (
        <AdminMemoIntegration serviceId={Number(visitApplyNumber)} serviceType={"SERVICE_INVITED_VISITOR_GROUP"} viewOnly={false} />
      )}
    </div>
  );
}
export default VisitorDetail;
