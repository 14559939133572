import moment from "moment";
import { ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat } from "src/utils/common-util";

const useSpaceProductColumns: any = [
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 150,
    Cell: (props: any) => {
      const path = PagePath.contract.detail.replace(":id", props.row.original.contractId);
      return (
          <BaseNewTabLink path={path} value={props.value} className="w-100 text-left" />
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 365,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} />;
    },
  },
  {
    Header: "이용시작",
    accessor: "contractStartTime",
    width: 245,
    Cell: ({ row }: any) => {
      const start = row.original.contractStartTime;
      const end = row.original.contractEndTime;
      return (
        <>
          {start ? moment(start).format(ViewYmdFormat.YYYY_MM_DD) : "-"} - {end ? moment(end).format(ViewYmdFormat.YYYY_MM_DD) : "-"}
        </>
      );
    },
  },
  {
    Header: "이용 상태",
    accessor: "contractStep",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        //visible 녹색
        //invisible 빨간색
        //draft = 회색
        <div
          className={`${(value === ContractStep.APPLY_RECEIVED && "chip draft") ||
            (value === ContractStep.APPLY_CANCEL && "chip invisible") ||
            (value === ContractStep.APPLY_CONFIRM && "chip draft") ||
            (value === ContractStep.CONTRACT_PLAN && "chip draft") ||
            (value === ContractStep.CONTRACT_ACCEPT && "chip draft") ||
            (value === ContractStep.USE_APPROVAL && "chip visible") ||
            (value === ContractStep.USE_PROGRESS && "chip visible") ||
            (value === ContractStep.USE_COMPLETE && "chip invisible") ||
            (value === ContractStep.TERMINATE_RECEIVED && "chip invisible") ||
            (value === ContractStep.TERMINATE_COMPLETE && "chip invisible") ||
            (value === "DISABLED" && "chip invisible") ||
            (value === "DRAFT" && "chip draft")
            }`}
        >
          {(value === ContractStep.APPLY_RECEIVED && "신청접수") ||
            (value === ContractStep.APPLY_CANCEL && "신청취소") ||
            (value === ContractStep.APPLY_CONFIRM && "신청확인") ||
            (value === ContractStep.CONTRACT_PLAN && "계약예정") ||
            (value === ContractStep.CONTRACT_ACCEPT && "계약체결") ||
            (value === ContractStep.USE_APPROVAL && "이용승인") ||
            (value === ContractStep.USE_PROGRESS && "이용중") ||
            (value === ContractStep.USE_COMPLETE && "이용완료") ||
            (value === ContractStep.TERMINATE_RECEIVED && "해지접수") ||
            (value === ContractStep.TERMINATE_COMPLETE && "해지완료")}
        </div>
      );
    },
  },
  {
    Header: "이용자",
    accessor: "userList",
    width: 150,
    Cell: (props: any) => {
      const contractApplyNumber = props.row.original.contractApplyNumber;
      const path = PagePath.user.list + "?contractApplyNumber=" + contractApplyNumber;
      return (
        <BaseNewTabLink path={path} value={'바로가기'} className="w-100 text-left" />
      );
    },
  },
];
export default useSpaceProductColumns;
