import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { BaseInput, BasePagination, BaseRadio, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import useApiLoading from "src/hooks/useApiLoading";

const columnHeader: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 100,
    Cell: ({ row, setSelectedOrg }: any) => {
      const changeTrigger = useCallback(() => {
        setSelectedOrg(row.original);
      }, [row.original, setSelectedOrg]);

      return <BaseRadio id={`selector${row.original.id}`} name={"isSelected"} onChange={changeTrigger} value={row.original.isSeleted} />;
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 100,
  },
  {
    Header: "법인명/상호",
    accessor: "name",
    width: 180,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "대표자/성명",
    accessor: "ceoName",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "사업자 등록번호",
    accessor: "bizRegNo",
    width: 140,
  },
  {
    Header: "최초 등록일",
    accessor: "createdDate",
    width: 140,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 140,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
];
type Props = {
  isOpen: boolean;
  name?: string;
  columns?: any;
  partnerId?: string;
  placeholder?: string;
  rightBtnName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchOrgPopup = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const [selectedOrg, setSelectedOrg] = useState<any>();
  const [pageMeta, setPageMeta] = useState<any>({});
  const [search001, setSearch001] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    search001: "",
    sort: {
      orders: [{ property: "id", direction: "DESC" }],
    },
  });
  const { isApiLoading } = useApiLoading();
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: props.columns ? props.columns : columnHeader,
      data: data,
      setSelectedOrg,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getOrgList, {
    noHandleError: true,
  });

  const onClickData = useCallback(() => {
    if (props.onClick) {
      props.onClick(selectedOrg);
    }
  }, [props, selectedOrg]);

  const closeData = useCallback(async () => {
    if (props.onClose) props.onClose(selectedOrg);
  }, [props]);

  const fetchData = useCallback(
    async (params: any) => {
      const response: any = await executeAsyncAll(params);
      if (response.status >= 200 && response.status <= 299) {
        console.log(response);
        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          // response.data.data.content[i].isSelected = false;
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
        }
        setData(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      } else {
      }
    },
    [executeAsyncAll],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.page = 0;
      // prs.search001 = props.name ? decodeURIComponent(props.name) : "";
      // props.name ? setSearch001(decodeURIComponent(props.name)) : setSearch001("");
      setSearch001("");
      if (props.partnerId) {
        prs.partnerId = props.partnerId;
      }
      fetchData(prs);
      setParams(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={props.isOpen}>
      <>
        <section className="base-abstract-modal__title flex-center-between">
          <h1>사업자 선택</h1>

          <div className="minmax240 text-left">
            <BaseInput
              type="text"
              value={search001 || ""}
              placeholder={props.placeholder ? props.placeholder : "법인명/상호 또는 사업자번호"}
              onChange={(value: string) => {
                setSearch001(value);
              }}
              onKeyUp={() => {
                setParams({ ...params, ...{ search001 } });
                fetchData({ ...params, ...{ search001 }, ...{ page: 0 } });
              }}
              onSearchClick={() => {
                setParams({ ...params, ...{ search001 } });
                fetchData({ ...params, ...{ search001 }, ...{ page: 0 } });
              }}
              onClearClick={() => {
                setSearch001("");
                setParams({ ...params, ...{ search001: "" }, ...{ page: 0 } });
              }}
            />
          </div>
        </section>
        {/* table */}
        <section className="base-abstract-modal__contents">
          <div {...getTableProps()} className="base-table view-data-table sticky  px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            pageIndex={params.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={Number(params.size) || 20}
            sizeOption={(sizeValue) => {
              setParams({ ...params, size: sizeValue });
              fetchData({ ...params, size: sizeValue });
            }}
            totalElements={pageMeta?.totalElements || 0}
            goPage={(page: number) => {
              setParams({ ...params, ...{ page } });
              fetchData({ ...params, ...{ page } });
            }}
            children={
              <div className="flex-center">
                <button className="base-btn color-white mr10" onClick={() => closeData()}>
                  취소
                </button>
                <button className="base-btn" onClick={() => onClickData()}>
                  {props.rightBtnName ? props.rightBtnName : "선택"}
                </button>
              </div>
            }
          />
        </section>
      </>
    </BaseAbstractModal>
  );
};

export default SearchOrgPopup;
