import _, { uniqueId } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { getCreditRelation } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseCheckbox, BaseModal } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils";

export const columns: any = [
  {
    Header: "선택",
    accessor: "acctCredit.isBillCredit",
    // sticky: "left",
    Cell: ({
      value,
      row,
      rows,
      accountList,
      receiveList,
      mainRow,
      showBtn,
      delReceiveList,
      setDelReceiveList,
      setReceiveList,
      setReceiveData,
    }: any) => {
      const { openToast } = useToast();

      const checkBoxNumberCount = useCallback(
        (value: boolean) => {
          let checkCounter = 0;
          const temp = _.cloneDeep(rows);

          temp.map((td: any) => {
            if (td.original.acctCredit.isBillCredit) {
              checkCounter += 1;
            }
          });

          if (value) {
            checkCounter += 1;
          } else {
            checkCounter -= 1;
          }
          // console.log("checkCounter", checkCounter);
          if (checkCounter > 1) {
            openToast({ content: "2개 이상 선택시 가장 최근 입금일시로 기록됩니다.", isSystemToast: true });
          }
        },
        [openToast, rows],
      );

      return (
        <div>
          <BaseCheckbox
            id={uniqueId("isBillCredit_")}
            name={"isBillCredit"}
            disabled={mainRow.original.bill.billPayStatus === "PAYMENT_SUCCESS" || !showBtn}
            checked={value}
            onChange={(value: boolean) => {
              let total = 0;
              receiveList.map((re: any) => {
                total += Number(re.receiptAmount);
              });

              if (Number(mainRow.original.receiptBundle.billTotalAmount) < total) {
                openToast({ content: "수납처리 전 잔액 보다 입금처리 금액이 많을 수 없습니다.", isSystemToast: true });
                return;
              }

              if (value) {
                if (Number(mainRow.original.receiptBundle.billTotalAmount) === total) {
                  openToast({ content: "수납처리 합계가 청구금액과 동일하여 입금 거래를 추가 선택할 수 없습니다.", isSystemToast: true });
                  return;
                }
                console.info("//선택 하였을 때");
                let trDateTime = moment(row.original.credit.trDateTime, "YYYYMMDDHHmmss").format(ViewYmdFormat.FULL);
                let temp = _.cloneDeep(receiveList);

                let findedDelete: any = delReceiveList.find((receipt: any) => {
                  if (receipt.acctCreditId === row.original.acctCredit.acctCreditId && receipt.receiptId !== 0) {
                    return receipt;
                  }
                });
                console.info("//기존 등록된 수납내역 중 삭제 처리된 것이 있는지 찾는다.");

                if (findedDelete) {
                  console.info("//기존 등록된 수납내역 중 삭제 처리된 것", findedDelete);
                  findedDelete.isDeleted = "false";

                  let temp2 = _.cloneDeep(delReceiveList);
                  const temp3: any = temp2.filter((re: any) => {
                    if (re.acctCreditId !== row.original.acctCredit.acctCreditId) {
                      return re;
                    }
                  });
                  setDelReceiveList([...temp3]);
                  console.info("//미삭제로 flag 바꿈", findedDelete);
                  temp.push(findedDelete);

                  setReceiveList(temp);
                  const receiveDataTemp = _.cloneDeep(accountList);

                  const newTemp: any = receiveDataTemp.map((rd: any) => {
                    if (findedDelete.acctCreditId === rd.acctCredit.acctCreditId) {
                      // console.log(Number(rd.credit.creditBalance), Number(findedDelete.receiptAmount));
                      rd.credit.creditBalance = String(Number(rd.credit.creditBalance) - Number(findedDelete.receiptAmount));
                      mainRow.original.receiptBundle.receiptTotalAmount =
                        Number(mainRow.original.receiptBundle.receiptTotalAmount) + Number(findedDelete.receiptAmount);

                      mainRow.original.receiptBundle.billUnpaidAmount =
                        Number(mainRow.original.receiptBundle.billUnpaidAmount) - Number(findedDelete.receiptAmount);

                      rd.acctCredit.isBillCredit = value;
                    }

                    return rd;
                  });

                  setReceiveData(newTemp);
                } else {
                  if (Number(row.original.credit.creditBalance) <= 0) {
                    openToast({ content: "입금잔액이 0보다 커야 선택할 수 있습니다.", isSystemToast: true });
                    return;
                  }

                  console.info("//기존 등록된 수납내역 중 삭제 처리된 것이 없다", "신규로 추가");
                  let check = total + Number(row.original.credit.creditBalance);
                  let price = 0;

                  if (Number(mainRow.original.receiptBundle.billTotalAmount) < Number(check)) {
                    price = Number(mainRow.original.receiptBundle.billTotalAmount) - total;
                  } else {
                    price = Number(row.original.credit.creditBalance);
                  }

                  const receiveDataTemp = _.cloneDeep(accountList);

                  const newTemp: any = receiveDataTemp.map((rd: any) => {
                    if (row.original.acctCredit.acctCreditId === rd.acctCredit.acctCreditId) {
                      // console.info("rd.credit.creditBalance, price", rd.credit.creditBalance, price);
                      rd.credit.creditBalance = String(Number(rd.credit.creditBalance) - Number(price));
                      // console.log("change balance", rd.credit.creditBalance);
                      mainRow.original.receiptBundle.receiptTotalAmount = Number(mainRow.original.receiptBundle.receiptTotalAmount) + Number(price);

                      mainRow.original.receiptBundle.billUnpaidAmount = Number(mainRow.original.receiptBundle.billUnpaidAmount) - Number(price);
                      rd.acctCredit.isBillCredit = value;
                    }

                    return rd;
                  });

                  setReceiveData(newTemp);

                  console.log("row.original.acctCredit", row.original.acctCredit);
                  if (price > 0) {
                    const insertReceipt: any = {
                      receiptId: 0,
                      billId: mainRow.original.bill.billId,
                      acctCreditId: row.original.acctCredit.acctCreditId,
                      pgcode: mainRow.original.bill.pgcode,
                      paymentId: 0,
                      receiptAmount: price,
                      trDateTime: trDateTime,
                      isDeleted: "false",
                    };

                    temp.push(insertReceipt);
                  }

                  setReceiveList(temp);
                }
              } else {
                console.info("//해제 하였을 때");
                const temp = _.cloneDeep(receiveList);
                console.info("//수납 내역에서 체크해제 된것이 있는지 확인한다.");
                const find = temp.find((rl: any) => {
                  if (Number(rl.receiptId) !== 0 && rl.acctCreditId === row.original.acctCredit.acctCreditId) {
                    return rl;
                  }
                });

                if (find) {
                  console.info("//수납 내역에서 체크해제 된것", find);
                  const temp = _.cloneDeep(receiveList);
                  find.isDeleted = "true";

                  console.info("//삭제로 flag 바꿈", find);
                  setDelReceiveList([...delReceiveList, ...[find]]);
                  let temp2 = temp.filter((receipt: any) => {
                    if (Number(receipt.receiptId) !== 0 && receipt.acctCreditId !== find.acctCreditId) {
                      return receipt;
                    }
                  });
                  setReceiveList(temp2);

                  const receiveDataTemp = _.cloneDeep(rows);

                  const newR = receiveDataTemp.map((rd: any) => {
                    if (_.isEqual(find.acctCreditId, rd.original.acctCredit.acctCreditId)) {
                      rd.original.credit.creditBalance = Number(rd.original.credit.creditBalance) + Number(find.receiptAmount);
                      mainRow.original.receiptBundle.receiptTotalAmount =
                        Number(mainRow.original.receiptBundle.receiptTotalAmount) - Number(find.receiptAmount);
                      let reCalculate = Number(mainRow.original.receiptBundle.billUnpaidAmount) + Number(find.receiptAmount);
                      rd.original.acctCredit.isBillCredit = value;

                      if (Number(reCalculate) > Number(mainRow.original.receiptBundle.billTotalAmount)) {
                        mainRow.original.receiptBundle.billUnpaidAmount = Number(mainRow.original.receiptBundle.billTotalAmount);
                      } else {
                        mainRow.original.receiptBundle.billUnpaidAmount = reCalculate;
                      }
                    }

                    return rd.original;
                  });

                  setReceiveData(newR);
                } else {
                  console.info("//수납 내역에서 체크해제 된것이 아니다.", " 신규로 넣었다가 뺐다. 걍 삭제 처리한다.");
                  const temp = _.cloneDeep(receiveList);
                  // console.log("?????", temp);

                  const finded = temp.find((receipt: any) => Number(receipt.acctCreditId) === Number(row.original.acctCredit.acctCreditId));

                  const filtered = temp.filter(
                    (receipt: any, idx5: number) => Number(receipt.acctCreditId) !== Number(row.original.acctCredit.acctCreditId),
                  );

                  const receiveDataTemp = _.cloneDeep(accountList);

                  const newTemp: any = receiveDataTemp.map((rd: any) => {
                    if (row.original.acctCredit.acctCreditId === rd.acctCredit.acctCreditId) {
                      // console.log("rd.credit.creditBalance, price", rd.credit.creditBalance, finded.receiptAmount);
                      rd.credit.creditBalance = String(Number(rd.credit.creditBalance) + Number(finded.receiptAmount));
                      // console.log("change balance", rd.credit.creditBalance);
                      mainRow.original.receiptBundle.receiptTotalAmount =
                        Number(mainRow.original.receiptBundle.receiptTotalAmount) + Number(finded.receiptAmount);

                      mainRow.original.receiptBundle.billUnpaidAmount =
                        Number(mainRow.original.receiptBundle.billUnpaidAmount) + Number(finded.receiptAmount);
                      rd.acctCredit.isBillCredit = value;
                    }

                    return rd;
                  });

                  setReceiveData(newTemp);
                  setReceiveList(filtered);
                  // changeFlag(value, row);
                }
              }
              checkBoxNumberCount(value);
            }}
          />
        </div>
      );
    },
    width: 56,
  },
  {
    Header: "입금일시",
    accessor: "credit.trDateTime",
    Cell: ({ value }: any) => {
      let dateForm = moment(value, "YYYYMMDDHHmmss").format(ViewYmdFormat.YYYY_MM_DD);
      let timeForm = moment(value, "YYYYMMDDHHmmss").format(ViewYmdFormat.TIME);
      return (
        <div className="text-center">
          <p>{dateForm}</p>
          <p>{timeForm}</p>
        </div>
      );
    },
    width: 112,
  },
  {
    Header: "입금자",
    accessor: "credit.remark1",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <div className="minmax85 overflow-x-hidden">
          <p>{value}</p>
        </div>
      );
    },
  },
  {
    Header: "입금액",
    accessor: "credit.accountIn",
    width: 124,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-right pr10">
          <p>{numberToStringWithComma(value) + " 원"}</p>
        </div>
      );
    },
  },

  {
    Header: "수납 처리후 잔액",
    accessor: "credit.creditBalance",
    width: 124,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-right pr10">
          <p>{numberToStringWithComma(value) + " 원"}</p>
        </div>
      );
    },
  },
  {
    Header: "연계 내역",
    accessor: "acctCredit.isAnyBillCredit",
    width: 88,
    Cell: ({ value, row, receiveData, setReceiveData }: any) => {
      const { executeAsync: getCredit } = useApiOperation(getCreditRelation);
      const [relation, setRelation] = useState<any>([]);
      const [openModal, setOpenModal] = useState(false);
      const callRelationList = useCallback(async () => {
        const rl: any = await getCredit({ tid: row.original.credit.tid });
        if (rl.status > 199 && rl.status < 300) {
          setOpenModal(true);
          setRelation(rl.data.data.content.creditRelationBundle);
        }
      }, [getCredit, row.original.credit.tid]);

      return (
        <>
          {value && (
            <Link
              className="text-underline"
              to={"#"}
              onClick={(e: any) => {
                e.preventDefault();
                callRelationList();
              }}
            >
              보기
            </Link>
          )}

          <BaseModal
            isOpen={openModal}
            className="two-depth"
            btnRightTitle="확인"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <div className="modal-title">
              <div className="left-area align-items-center">
                <span>입금연계수납</span>
              </div>
              <div className="right-area"></div>
            </div>
            <div className="contents-scroll">
              <table className="inner-table" width="100%">
                <thead>
                  <tr>
                    <th>계약Id</th>
                    <th>구분</th>
                    <th>청구회차</th>
                    <th>청구일</th>
                    <th>연결금액</th>
                  </tr>
                </thead>

                <tbody>
                  {relation.length > 0 ? (
                    relation.map((contract: any, idx2: number) => {
                      return (
                        <tr key={idx2}>
                          <td>{contract.contractId}</td>

                          <td>{contract.bill.supplyType === "RENTAL" ? "이용료" : "관리비"}</td>
                          <td>{contract.bill.billOrder}</td>
                          <td>{moment(contract.bill.billIssueDate).format(ViewYmdFormat.FULL)}</td>
                          <td>{numberToStringWithComma(contract.creditRelationAmount) + " 원"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>데이터가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </BaseModal>
        </>
      );
    },
  },
  {
    Header: "은행",
    accessor: "providerAccount.bankName",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <>
          <p>{value}</p>
        </>
      );
    },
  },
  {
    Header: "계좌정보",
    accessor: "providerAccount.accountNumber",
    width: 176,
    Cell: ({ value, row }: any) => {
      return (
        <div className="text-center">
          <p>{value}</p>
          <p className="text-primary3">{row.original.providerAccount.accountHolder}</p>
        </div>
      );
    },
  },
];
