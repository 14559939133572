import { useMemo, useState } from "react";
import { useTable, useBlockLayout, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect } from "react-table";
import { useSticky } from "react-table-sticky";
import { detailCols, editCol } from "./columns/BillDetailListColumns";
import BillTotalList from "./BillTotalList";
export type BillDetail = {
  billId: string;
  dtlOrder: number;
  supplyAmount: number;
  taxAmount: number;
  totalAmount: number;
  adjustAmount?: number;
  baseAmount?: number;
  dtlId?: number;
  isDeleted?: boolean;
  itemName?: string;
  description?: string;
  trDateTime?: string;
  checkTaxAmount: string;
  isAutoTaxUse: boolean;
};

const BillDetailListInfo = ({ bill, setBill, showBtn, detailList, setDetailList, supplyTotal, taxTotal, priceTotal }: any) => {
  const cols = useMemo(() => {
    if (showBtn) {
      detailCols[detailCols.length - 1].width = 220;
      return [...detailCols, ...editCol];
    } else {
      detailCols[detailCols.length - 1].width = 280;
      return detailCols;
    }
  }, [showBtn]);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable(
    {
      columns: cols,
      data: detailList,
      initialState: { pageSize: 1000 },
      supplyTotal,
      taxTotal,
      priceTotal,
      showBtn,
      setDetailList,
      bill,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <div>
      <section className="base-abstract-modal__title flex-center-between">
        <h1>세부 내역</h1>

        <p className="pr30 font14 text-gray400">* 세부 항목을 입력 후 저장하시면, 총 합계가 여기에 표시됩니다.</p>
      </section>
      <div className="px30">
        {/* 기본정보 */}
        <BillTotalList supplyTotal={supplyTotal} taxTotal={taxTotal} priceTotal={priceTotal} />

        {detailList !== undefined && (
          <div {...getTableProps()} className="base-table sticky mb30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                        {/* <div className="ic_sort"></div> */}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                if (row.original.isDeleted === false) {
                  return (
                    <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
            <div className="footer">
              {footerGroups.map((footerGroup) => (
                <div {...footerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {footerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps()} className="base-table__td base-table-footer">
                      {column.render("Footer")}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BillDetailListInfo;
