import { Cell } from "react-table";
import { BuildingModel } from "src/api/building/building-types";
import { BaseTooltip } from "src/components";
import BaseEmail from "src/components/BaseEmail";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { BuildingDateCell } from "./BuildingDateCell";

/* 
  건물 테이블 columns
*/
const BuildingColumns = [
  {
    Header: "id",
    accessor: "id",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<BuildingModel>) => {
      const detailPath = `${PagePath.building.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div>
          <DetailLink to={detailPath} className="text-underline">
            <BaseTooltip contents={props?.value || ""} />
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    sticky: "left",
    width: 280,
    Cell: (props: Cell<BuildingModel>) => {
      const detailPath = `${PagePath.building.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div className="text-left w-100">
          <DetailLink to={detailPath} className="text-underline">
            <BaseTooltip contents={props?.value || ""} />
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "주소",
    accessor: "address",
    sticky: "left",
    width: 360,
    Cell: ({ value }: Cell<BuildingModel>) => (
      <div className="w-100 text-left">
        <BaseTooltip contents={value} />
      </div>
    ),
  },
  {
    Header: "규모",
    accessor: "size",
    width: 160,
    Cell: (props: Cell<BuildingModel>) => {
      return <div>{`지하${props.row.original.undergroundFloorNums}층 / 지상${props.row.original.floorNums}층`}</div>;
    },
  },
  {
    Header: "공개여부",
    accessor: "status",
    width: 80,
    Cell: ({ value }: Cell<BuildingModel>) => {
      return <div>{value === "ENABLED" ? "공개중" : "비공개"}</div>;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: (props: Cell<BuildingModel>) => <BuildingDateCell {...props} />,
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 120,
    Cell: ({ value }: Cell<BuildingModel>) => {
      return <BaseTooltip contents={value} isSingleLine={true} />;
    },
  },
];

export default BuildingColumns;
