import _, { uniqueId } from "lodash";
import { useSetRecoilState } from "recoil";
import { ErrorModalState, errorModalState } from "./atom";
import { contract_bill_user_errors, building_product_errors } from "src/api/public-types";

export type ErrorModal = {
  errorCode?: string;
  errorMessage?: string;
  errorData?: any;
  statusCode?: number;
  errorList?: string[];
  errorSubList?: string[];
};

const findErrorCodeMessages = (errorCode: string | undefined) => {
  let errorMessage = "";
  if (errorCode !== undefined) {
    const findBuildingNProduct = building_product_errors.find((error: any) => error.errorCode === errorCode);
    if (findBuildingNProduct) {
      errorMessage = findBuildingNProduct.message;
    }
    const findedContractnBiiling = contract_bill_user_errors.find((error: any) => error.errorCode === errorCode);
    if (findedContractnBiiling) {
      errorMessage = findedContractnBiiling.message;
    }
  }

  return errorMessage;
};

export const useErrorModal = () => {
  const setErrorModals = useSetRecoilState<ErrorModalState[]>(errorModalState);

  // 에러모달 열기
  const openErrorModal = (openErrorModal: ErrorModal) => {
    let temp = _.cloneDeep(openErrorModal);
    if (temp.errorMessage === undefined) {
      temp.errorMessage = findErrorCodeMessages(temp.errorCode);
    }
    if (temp.errorCode) {
      let message = "";
      message = findErrorCodeMessages(temp.errorCode);

      if (message !== "") {
        temp.errorMessage = message;
      }
    }

    const newErrorModal = {
      id: uniqueId("error_modal_unique_id_"),
      isOpen: true,
      errorCode: temp.errorCode,
      errorMessage: temp.errorMessage,
      errorData: temp.errorData,
      statusCode: temp.statusCode,
      errorList: temp.errorList,
      errorSubList: temp.errorSubList,
    };
    setErrorModals((errorModals: ErrorModalState[]) => {
      return [...errorModals, newErrorModal];
    });

    return newErrorModal;
  };

  // 마지막 에러모달 닫기
  const closeLastErrorModal = () => {
    setErrorModals((errorModals: ErrorModalState[]) => {
      const newErrorModals = [...errorModals];

      newErrorModals.pop();
      return newErrorModals;
    });
  };

  // 에러모달 닫기
  const closeErrorModal = (errorModalId: string) =>
    setErrorModals((errorModals: ErrorModalState[]) => errorModals.filter((errorModal) => errorModal.id !== errorModalId));

  // 에러모달 모두 닫기
  const closeAllErrorModal = () => setErrorModals(() => []);

  return { openErrorModal, closeErrorModal, closeAllErrorModal, closeLastErrorModal };
};
