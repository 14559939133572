import { useState } from "react";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { ServiceUserGuide } from "src/api/guide/guide-types";
import { BaseButton, BaseRadio } from "src/components";
import usePostGuideMapping from "src/hooks/guide/usePostGuideMapping";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";

const SettingModal = ({
  serviceUserGuide,
  serviceId,
  serviceType,
}: {
  serviceUserGuide: ServiceUserGuide;
  serviceId: string;
  serviceType: UnionServiceType;
}) => {
  const { mutate: postGuideMapping } = usePostGuideMapping();
  const { openToast } = useToast();
  const { setBaseModal, setAbstractModalZ1 } = useModal();
  const [isDisplayed, setIsDisplayed] = useState(serviceUserGuide.isDisplayed);

  return (
    <>
      <section className="base-abstract-modal__title">
        <h1>{serviceUserGuide?.userGuide?.subject ?? "-"} 설정</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <section className="base-abstract-modal__contents-subtitle h40 my20">
          <h2 className="required">노출 여부</h2>
        </section>
        <div className="flex-center pb30">
          <div className="flex-center minmax220">
            <BaseRadio
              id={"true"}
              name={"isDisplayed"}
              checked={isDisplayed === true}
              label="노출"
              onChange={() => setIsDisplayed(true)}
              className={"mr10"}
            />
            <BaseRadio id={"false"} name={"isDisplayed"} checked={isDisplayed === false} label="미노출" onChange={() => setIsDisplayed(false)} />
          </div>
        </div>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"취소"} className="color-white" onClick={() => setAbstractModalZ1({ isOpen: false })} />

        <BaseButton
          title={"저장"}
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnRightTitle: "확인",
              btnLeftTitle: "취소",
              title: "저장하시겠습니까?",
              onClick: () => {
                postGuideMapping(
                  [
                    {
                      serviceId: serviceId,
                      serviceType: serviceType,
                      id: serviceUserGuide?.id,
                      isDisplayed: isDisplayed,
                      cmdType: "U",
                    },
                  ],
                  {
                    onSuccess: () => {
                      openToast({
                        type: "SUCCESS",
                        content: "저장되었습니다.",
                      });
                      setBaseModal({ isOpen: false });
                      setAbstractModalZ1({ isOpen: false });
                    },
                  },
                );
              },
            });
          }}
        />
      </section>
    </>
  );
};

export default SettingModal;
