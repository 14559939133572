import { formatPhoneNumber } from "@toss/utils";
import _ from "lodash";
import { useState, useMemo, useCallback } from "react";
import { useTable, useBlockLayout, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect } from "react-table";
import { useSticky } from "react-table-sticky";
import { useApiOperation } from "src/api/hooks";
import { getTaapMembers } from "src/api/member/member-api";
import { BaseInput, BaseButton } from "src/components";
import BaseEmail from "src/components/BaseEmail";
import useApiLoading from "src/hooks/useApiLoading";
import { validatePhoneNumberReturnBoolean, parsedPhoneNumber, validateEmail } from "src/utils";

const columnHeader: any = [
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 220,
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 320,
    Cell: ({ value }: any) => {
      return <p>{formatPhoneNumber(value)}</p>;
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 400,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left">
          <BaseEmail value={value} />
        </div>
      );
    },
  },
];
const columnHeaderMedium: any = [
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 112,
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 148,
    Cell: ({ value }: any) => {
      return <p>{formatPhoneNumber(value)}</p>;
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 220,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left">
          <BaseEmail value={value} />
        </div>
      );
    },
  },
];

type Props = {
  isOpen: boolean;
  memberInfo?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  size: string;
};
const TaapMemberFind = (props: Props) => {
  const activeHeader = useMemo(() => {
    if (props.size === "medium") {
      return columnHeaderMedium;
    } else {
      return columnHeader;
    }
  }, [props]);
  const { isApiLoading } = useApiLoading();
  const [data, setData] = useState<any>([]);
  const [memberInfo, setMemberInfo] = useState<any>();
  const [searchMemberInfo, setSearchMemberInfo] = useState<any>();

  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    search001: "",
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: activeHeader,
      data: data,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  //조회
  const { executeAsync: executeAsyncAll } = useApiOperation(getTaapMembers);

  const onClickUser = useCallback(() => {
    if (props.onClick) {
      props.onClick(memberInfo);
    }
  }, [props, memberInfo]);

  const getMemberList = useCallback(
    async (params: any) => {
      const response: any = await executeAsyncAll(params);
      if (response.status > 199 && response.status < 300) {
        if (response?.data?.data?.taapUsers) {
          for (let i = 0; i < response?.data?.data?.taapUsers.length; i++) {
            response.data.data.taapUsers[i] = Object.assign(response.data.data.taapUsers[i], {
              isSelected: false,
            });
          }
          setData(response?.data?.data?.taapUsers);
          if (response?.data?.data?.taapUsers.length > 0) {
            setMemberInfo(response?.data?.data?.taapUsers[0]);
            setMemberInfo(response?.data?.data?.taapUsers[0]);
          }
          // setPageMeta(response?.data?.meta?.pageMeta);
        }
      } else {
      }
    },
    [executeAsyncAll],
  );

  const onSearchMember = useCallback(() => {
    let prs = _.cloneDeep(params);
    prs.notEmptyMemberNo = true;
    prs.accountStateStatus = "AVAILABLE";
    prs.sort = {
      orders: [{ property: "id", direction: "DESC" }],
    };
    prs.search001 = "";
    prs.page = 0;
    let check1 = false;
    let check2 = false;
    console.log("searchMemberInfo.phoneNumber", formatPhoneNumber(searchMemberInfo.phoneNumber));
    if (!validatePhoneNumberReturnBoolean(String(parsedPhoneNumber(searchMemberInfo.phoneNumber)))) {
      check1 = true;
    } else {
      setErrorPhone("");
    }
    if (!validateEmail(searchMemberInfo?.email)) {
      check2 = true;
    } else {
      setErrorEmail("");
    }

    if (check1) {
      setErrorPhone("올바르지 않은 휴대폰 번호입니다.");
    }

    if (check2) {
      setErrorEmail("올바르지 않은 이메일 형식입니다.");
    }

    if (check1 || check2) {
      return;
    }

    if (searchMemberInfo) {
      if (searchMemberInfo.phoneNumber) {
        prs.phoneNumber = parsedPhoneNumber(searchMemberInfo.phoneNumber);
      }
      if (searchMemberInfo.email) {
        prs.email = searchMemberInfo.email;
      }
    }

    setParams({ ...prs });
    getMemberList({ ...prs });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMemberInfo]);

  return (
    <>
      <section className="base-abstract-modal__title pb20">
        <h1>Taap 회원 조회</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <ul className="base-list mb20">
          <li>휴대폰 번호와 이메일을 모두 입력해 주세요.</li>
          <li>Taap에서 이메일 인증을 완료한 회원만 조회 가능합니다.</li>
          <li>현재 이용자가 아닌 Taap 회원을 계약자로 지정하는 경우, 기존의 계약자는 이용자에서 삭제됩니다.</li>
        </ul>
        <section className="flex-start h-input-validation">
          <div className="minmax184 mr10">
            <BaseInput
              type="phone"
              placeholder="휴대폰 번호"
              name={"phoneNumber"}
              value={searchMemberInfo?.phoneNumber !== undefined ? decodeURIComponent(String(searchMemberInfo?.phoneNumber)) : ""}
              onChange={(value: string) => {
                console.log("value", value);
                setSearchMemberInfo({ ...searchMemberInfo, ...{ phoneNumber: value } });
              }}
              errorText={errorPhone}
            />
          </div>
          <div className={`mr10 ${props.size === "xlarge" ? "minmax264" : "w-100"}`}>
            <BaseInput
              placeholder="이메일"
              name={"email"}
              value={searchMemberInfo?.email !== undefined ? decodeURIComponent(String(searchMemberInfo?.email)) : ""}
              onChange={(value: string) => {
                if (validateEmail(value)) {
                  setErrorEmail("");
                }
                setSearchMemberInfo({ ...searchMemberInfo, ...{ email: value } });
              }}
              errorText={errorEmail}
            />
          </div>
          <BaseButton
            title={"검색"}
            disabled={
              searchMemberInfo?.phoneNumber === undefined ||
              searchMemberInfo?.phoneNumber === "" ||
              searchMemberInfo?.email === undefined ||
              searchMemberInfo?.email === ""
            }
            onClick={() => onSearchMember()}
          />
        </section>
        <section className="base-abstract-modal__contents-subtitle mt0">
          <h2>검색 결과</h2>
        </section>
        <section className="base-abstract-modal__contents">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <BasePagination
              pageIndex={Number(params.page) || 0}
              totalPages={Number(pageMeta.totalPages) || 0}
              goPage={(page: number) => {
                setParams({ ...params, ...{ page } });
                getMemberList({ ...params, ...{ page } });
              }}
            /> */}
        </section>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"닫기"} className="color-white" onClick={props.onClose} />
        <BaseButton title={"선택"} disabled={memberInfo?.memberNo === undefined} onClick={() => onClickUser()} />
      </section>
    </>
  );
};
export default TaapMemberFind;
