import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { BaseInput } from "./BaseInput";
import { createPortal } from "react-dom";

type Props = {
  setDate: (date: string) => void; // selectBox 에서 선택한 시간 setState
  timeIntervals?: number; // 몇분 단위로 시간 노출?
  selectedTime?: string; // 선택된 시간 or 저장된 시간
  minTime?: string; // 최소시간 이후의 time만 선택되도록 적용
  midnight?: boolean; // 24:00 표시를 해야한다면 prop 전달
  errorText?: string; // validation 텍스트
  className?: string;
  placeholder?: string;
  name?: string;
};

const CustomTimePicker = ({ timeIntervals = 30, setDate, className, placeholder, selectedTime, errorText, minTime, midnight }: Props) => {
  const listRef = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  //SelectBox 오픈
  const [showOptions, setShowOptions] = useState(false);

  // 시간 options 셋팅 > 00:00 ~ 24:00
  const times = useMemo(() => {
    const timeOptions = [];

    for (let i = 0; i <= 24; i++) {
      if (i === 24 && midnight) {
        timeOptions.push("24:00");
      } else if (i < 24) {
        for (let j = 0; j < 60; j += timeIntervals) {
          const hour = i.toString().padStart(2, "0");
          const minute = j.toString().padStart(2, "0");
          timeOptions.push(`${hour}:${minute}`);
        }
      }
    }
    return timeOptions;
  }, [timeIntervals]);

  //자동스크롤 적용
  useEffect(() => {
    if (showOptions) {
      if (listRef.current) {
        listRef.current.scrollIntoView({ behavior: "auto", block: "center" });
      }
    }
  }, [showOptions]);

  // 현재시간 / 선택된 시간 비교하여 listRef 리턴 (자동스크롤을 위함)
  const onSetRef = (time: string, idx: number) => {
    // 선택된 시간 or 저장된 시간이 없을 때,
    if (!selectedTime) {
      // 시간 목록의 데이터 00:00 ~ 24:00
      const compareTime = moment(time, "HH:mm");
      // 현재의 시간
      const currentTime = moment().subtract(timeIntervals, "minutes");

      if (compareTime.isBefore(currentTime)) {
        return listRef;
      }
    }

    // 저장된 시간이 있을 때
    if (time === selectedTime) {
      return listRef;
    }
  };

  const disabledTime = (timeValue: string) => {
    if (timeValue) {
      const minTimeWithInterval = moment(minTime, "HH:mm").add("m", timeIntervals);
      const unableTime = minTime && moment(timeValue, "HH:mm").isBefore(minTimeWithInterval);

      return unableTime;
    }
  };

  // 시간 저장 & SelectBox 닫기
  const onSetTimeAndCloseSelect = (timeValue?: string) => {
    // minTime이 있는경우, 이전의 시간 클릭 불가
    if (disabledTime(timeValue ?? "")) return;

    if (timeValue) {
      // setClickTime(timeValue);
      setDate(timeValue);
    }
    setShowOptions(false);
  };

  // 시간 목록 classNmae 적용
  const onSetLiClassName = (time: string) => {
    const defaultClassName = "time-option";

    // 선택된 select 효과 css 적용 > 선택된 시간 - 검은색 box + text-white 처리
    const selectedTimeClassName = time === selectedTime ? "selected-time" : defaultClassName;

    //클릭 불가 disabled class 적용
    const disabled = disabledTime(time) ? "time-picker-disabled" : ""; // minTime에 대한 선택불가 class 적용

    return `${defaultClassName} ${selectedTimeClassName} ${disabled}`;
  };

  return (
    <div className={`base-input ${className || ""}`} ref={containerRef}>
      <div className="position-relative" ref={inputRef}>
        <BaseInput
          placeholder={placeholder || "시간을 선택해주세요."}
          value={selectedTime}
          onClick={() => setShowOptions(true)}
          onBlur={() => onSetTimeAndCloseSelect()}
        />
        {showOptions &&
          createPortal(
            <div
              className="custom-picker-wrap mt10"
              style={{
                position: "absolute",
                top: inputRef?.current?.offsetHeight || 0,
                left: 0,
                zIndex: 100,
              }}
            >
              <div className="custom-timepicker-header">Time</div>
              <ul className="custom-datepicker">
                {times.map((time, idx) => (
                  <li
                    key={time}
                    ref={onSetRef(time, idx)}
                    className={onSetLiClassName(time)}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      onSetTimeAndCloseSelect(time);
                    }}
                  >
                    <span>{time}</span>
                  </li>
                ))}
              </ul>
            </div>,
            // document.body,
            // document.getElementById("calendar")!,
            containerRef.current!,
          )}
      </div>
      {errorText && !selectedTime && <p className="validation-text">{errorText}</p>}
    </div>
    // <div className={`base-input ${className ? className : ""}`}>
    //   <div className="position-relative">
    //     <BaseInput
    //       placeholder={placeholder || "시간을 선택해주세요."}
    //       value={selectedTime ? selectedTime : ""}
    //       onClick={() => {
    //         setShowOptions(true);
    //       }}
    //       onBlur={() => onSetTimeAndCloseSelect()}
    //     />
    //     {showOptions && (
    //       <div className="custom-picker-wrap">
    //         <div className="custom-timepicker-header">Time</div>

    //         <ul className="custom-datepicker">
    //           {times.map((time, idx) => (
    //             <li
    //               ref={onSetRef(time, idx)}
    //               key={time}
    //               className={onSetLiClassName(time)}
    //               onMouseDown={(e) => {
    //                 e.preventDefault(); // 이벤트 기본 동작 방지
    //                 onSetTimeAndCloseSelect(time);
    //               }}
    //             >
    //               <span>{time}</span>
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //     )}
    //   </div>
    //   {errorText && !selectedTime && <p className="validation-text">{errorText}</p>}
    // </div>
  );
};

export default CustomTimePicker;
