import { AxiosInstance, AxiosResponse } from "axios";
import { ProductListData, ProductListParams } from "../product/product-types";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  QuestionAddType,
  QuestionAnswerAddType,
  QuestionAnswerModel,
  QuestionConnectedInfoListQuery,
  QuestionConnectedInfoListRequest,
  QuestionConnectedInfoListResponse,
  QuestionDetailModel,
  QuestionListResponse,
  QuestionNotifyMessageType,
  QuestionQueryParams,
  QuestionStatusUnionType,
} from "./question-type";

// RPP01. 공간상품 기본정보 목록
export async function getQuestionListAsync(
  axios: AxiosInstance,
  params?: QuestionQueryParams,
): Promise<AxiosResponse<ApiResponse<QuestionListResponse>>> {
  return await axios.get<ApiResponse<QuestionListResponse>>(ApiPath.question.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * REQ17. 상담관리 상세 목록
 * https://pnpt.atlassian.net/wiki/x/AYAnJQ
 */
export async function getQuestionDetailListAsync(
  axios: AxiosInstance,
  params?: QuestionQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel[] }>>> {
  return await axios.get(ApiPath.question.details, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
  - REQ02. 상담관리 상세 목록
  - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/315228180/REQ02.
 */ export async function getQuestionDetailAsync(
  axios: AxiosInstance,
  params?: { id: number },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.get(ApiPath.question.detail.replace("{id}", String(params?.id)));
}

/**
- REQ03. 상담관리 등록
- https: export async function postQuestionAsync(
 */
export async function postQuestionAsync(
  axios: AxiosInstance,
  params?: { question: QuestionAddType },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.post(ApiPath.question.add.form, { question: params?.question });
}

/**
- REQ04. 상담관리 수정
- https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/315260998/REQ04. */

// 상담관리 수정
export async function editQuestionAsync(
  axios: AxiosInstance,
  params?: { id: number; question: QuestionAddType },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.put(ApiPath.question.edit.form.replace("{id}", String(params?.id)), { question: params?.question });
}

/** 
  - REQ05. 상담관리 삭제
  - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/290881713/REQ05.
  */

export async function deleteQuestionAsync(axios: AxiosInstance, parmas?: { ids: Array<string> }): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.delete(ApiPath.question.delete, { params: parmas?.ids?.join(",") });
}

/** 
  - REQ06. 상담관리 답변 등록
  - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/290881713/REQ05.
  */
export async function postAnswerQuestionAsync(
  axios: AxiosInstance,
  params?: { id: string; data: QuestionAnswerAddType },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionAnswerModel }>>> {
  return await axios.post(ApiPath.question.add.answer.replace("{id}", String(params?.id)), { answer: params?.data });
}

/**
 - REQ07. 상담관리 알림 발송 요청 
 - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/315261032/REQ07.
 */

export const questionNotifyAsync = (
  axios: AxiosInstance,
  params?: {
    id: number;
    answerId: string;
    messageType: QuestionNotifyMessageType;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axios.get(`/api/ctrl/partners/{partnerId}/et/question/${String(params?.id)}/${String(params?.answerId)}/${params?.messageType}`);
};

/** 
 * REQ08. 상담관리 상태 변경
https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/316243969/REQ08.
 * */
export async function editQuestionStatusAsync(
  axios: AxiosInstance,
  params?: { id: number; status: QuestionStatusUnionType },
): Promise<AxiosResponse<ApiResponse<QuestionDetailModel>>> {
  return await axios.patch(ApiPath.question.edit.status.replace("{id}", String(params?.id)), { question: { status: params?.status } });
}

/**
 * REQ15. 상담관리 연결정보 조회
 * https://pnpt.atlassian.net/wiki/x/AYBlGw
 */
export async function getConnectedInfoListAsync(
  axios: AxiosInstance,
  params?: QuestionConnectedInfoListQuery,
): Promise<AxiosResponse<ApiResponse<QuestionConnectedInfoListResponse>>> {
  return await axios.get<ApiResponse<QuestionConnectedInfoListResponse>>(ApiPath.question.connectedInfo.get, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * REQ14. 상담관리 연결정보 등록/삭제
 * https://pnpt.atlassian.net/wiki/x/AgBiGw
 */
export async function postConnectedInfoListAsync(
  axios: AxiosInstance,
  params?: QuestionConnectedInfoListRequest["connectedInfoList"],
): Promise<AxiosResponse<ApiResponse<QuestionConnectedInfoListResponse>>> {
  return await axios.post(ApiPath.question.connectedInfo.post, { connectedInfoList: params });
}
