import { useMemo, useState } from "react";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { ADMIN_MEMO } from "src/api/public-types";
import { BaseModal, ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import MetaTag from "src/components/layout/MetaTag";
import { useQueryParams } from "src/hooks/useQueryParams";
import BasicInfoForm from "./basicInfo/BasicInfoForm";

const GuideForm = () => {
  const { queryParams } = useQueryParams();

  const [modalOpen, setModalOpen] = useState(false);
  const tabs = [{ value: "basicInfo", label: "기본정보" }];

  const id: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <>
      {id ? <MetaTag /> : <MetaTag isCreatePage />}
      {modalOpen && (
        <BaseModal isOpen={modalOpen} btnRightTitle="확인" onClick={() => setModalOpen(false)} title="기본정보가 저장되지 않았습니다"></BaseModal>
      )}
      <div>
        <ContentsTitle title="이용안내" tabs={tabs} activeTab={activeTab} detailInfo={[{ title: "id", value: id || "" }]} />
        {activeTab?.value === "basicInfo" && <BasicInfoForm />}
      </div>
    </>
  );
};

export default GuideForm;
