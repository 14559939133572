import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { BillingDetail, ScheduleNoti } from "src/api/billingSchedule/billing-type";
import { DetailTotalData, monthDays } from "src/api/contract/contract-types";
import { BaseCheckbox, BaseRadio, BaseSelect, BaseTooltip } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import { useToast } from "src/recoil/toast/hook";
import useContactApi from "../../hooks/useContractApi";

interface BasicProps {
  detailData: DetailTotalData;
  saveFlags: boolean;
  setEditable: any;
  setSaveFlags: any;
  setData: Function;
  popupState: boolean;
  setPopupState: any;
  setCommonModals: any;
  setEditConfrim: any;
}
const ContractBillingScheduleNotiEdit = ({
  detailData,
  popupState,
  saveFlags,
  setCommonModals,
  setData,
  setEditConfrim,
  setEditable,
  setPopupState,
  setSaveFlags,
}: BasicProps) => {
  const { openToast } = useToast();
  const { saveContractBillData } = useContactApi();
  const R = "RENTAL";
  const KAKAOTALK = "KAKAOTALK";
  const { contract, scheduleList, provider } = detailData;

  const [rentalSchedule, setRentalSchedule] = useState<BillingDetail>();
  const [editRentalSchedule, setEditRentalSchedule] = useState<BillingDetail>();
  const [mntSchedule, setMntSchedule] = useState<BillingDetail[]>();
  const [original_K, setOriginal_K] = useState<Array<ScheduleNoti>>([]);
  const [original_E, setOriginal_E] = useState<Array<ScheduleNoti>>([]);
  const [scheduleMessageList_K, setScheduleMessageList_K] = useState<Array<ScheduleNoti>>([]);
  const [scheduleMessageList_E, setScheduleMessageList_E] = useState<Array<ScheduleNoti>>([]);

  const onSaveBillingsData = useCallback(async () => {
    let result: any;
    if (_.isEqual(original_E, scheduleMessageList_E) === false || _.isEqual(original_K, scheduleMessageList_K) === false) {
      const temp = _.cloneDeep(editRentalSchedule!);
      temp.scheduleMessageList = scheduleMessageList_K.concat(scheduleMessageList_E);
      result = await saveContractBillData(rentalSchedule!, temp!);
    } else {
      result = await saveContractBillData(rentalSchedule!, editRentalSchedule!);
    }
    // console.log("result", result);

    if (result.response.status > 199 && result.response.status < 300) {
      setCommonModals({
        message: "저장 되었습니다.",
        title: "청구 정보 수신 변경",
      });
      setPopupState(true);
      setEditConfrim(false);
      setSaveFlags(false);
      setEditable(false);
      setData();
      return;
    }
    if (result.validationPass === false) {
      setCommonModals({
        message: result.message,
        title: "청구 정보 수신 변경",
      });
      setPopupState(true);
      setSaveFlags(false);
      // setEditable(false);
      setEditConfrim(false);
      return;
    }
  }, [
    original_E,
    scheduleMessageList_E,
    original_K,
    scheduleMessageList_K,
    editRentalSchedule,
    saveContractBillData,
    rentalSchedule,
    setCommonModals,
    setPopupState,
    setEditConfrim,
    setSaveFlags,
    setEditable,
    setData,
  ]);

  useEffect(() => {
    if (saveFlags === true) {
      onSaveBillingsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFlags]);
  const makeMessageList = useCallback((schedule: BillingDetail, type?: string) => {
    const rtn: any = { kakao: [], emails: [] };
    if (type === "email") {
      let kakaos: Array<ScheduleNoti> = schedule?.scheduleMessageList!.filter((ml: ScheduleNoti) => ml.messageMedia === KAKAOTALK);
      setScheduleMessageList_K(kakaos);
      rtn.kakaos = kakaos;
      return rtn;
    }
    if (type === "email") {
      let emails: Array<ScheduleNoti> = schedule?.scheduleMessageList!.filter((ml: ScheduleNoti) => ml.messageMedia !== KAKAOTALK);
      setScheduleMessageList_E(emails);
      rtn.kakaos = emails;
      return rtn;
    }
    if (type === "ALL") {
      let kakaos: Array<ScheduleNoti> = schedule?.scheduleMessageList!.filter((ml: ScheduleNoti) => ml.messageMedia === KAKAOTALK);
      setOriginal_K(_.cloneDeep(kakaos));
      setScheduleMessageList_K(_.cloneDeep(kakaos));
      let emails: Array<ScheduleNoti> = schedule?.scheduleMessageList!.filter((ml: ScheduleNoti) => ml.messageMedia !== KAKAOTALK);
      setOriginal_E(_.cloneDeep(emails));
      setScheduleMessageList_E(_.cloneDeep(emails));
      rtn.kakaos = kakaos;
      rtn.emails = emails;
      return rtn;
    }
  }, []);

  const initDatas = useCallback(() => {
    if (scheduleList) {
      const schedule = _.cloneDeep(scheduleList?.find((sd: BillingDetail) => sd.supplyType === R));
      const mntList = scheduleList?.filter((sd: BillingDetail) => sd.supplyType !== R);

      setRentalSchedule(_.cloneDeep(schedule));
      setEditRentalSchedule(_.cloneDeep(schedule));
      makeMessageList(schedule!, "ALL");
      setMntSchedule(mntList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleList]);

  useEffect(() => {
    initDatas();
  }, [initDatas]);

  return (
    <>
      <div className="fles-row flex-center">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"공문 종류"}
            children={
              <div className="contents-container__grid-contents">
                <p>{scheduleMessageList_E.length === 0 && "-"}</p>
                {scheduleMessageList_E.map((noti: ScheduleNoti, key: number) => {
                  return (
                    <div className="flex-center" key={key}>
                      <BaseCheckbox
                        className={`minmax155 mb5 mr10`}
                        name={"scheduleMessageListEmail"}
                        checked={String(noti.isSend) === "true" ? true : false}
                        id={"paybBllMessageListE_" + key}
                        label={noti.messageLabel}
                        onChange={(value: boolean) => {
                          const emails = _.cloneDeep(scheduleMessageList_E!);
                          for (let i = 0; i <= emails.length - 1; i++) {
                            if (emails[i].messageId === noti.messageId) {
                              emails[i].isSend = value;
                              emails[i].cmdType = "U";
                            }
                          }

                          setScheduleMessageList_E(emails);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"알림톡 종류"}
            children={
              <div>
                <p>{scheduleMessageList_K.length === 0 && "-"}</p>

                {scheduleMessageList_K.map((noti: ScheduleNoti, key: number) => {
                  return (
                    <div className="flex-center" key={key}>
                      <BaseCheckbox
                        className={`mb5 mr10`}
                        name={"scheduleMessageListKakao"}
                        checked={noti.isSend}
                        id={"paybBllMessageListK_" + key}
                        label={noti.messageLabel}
                        onChange={(value: boolean) => {
                          const kakaos = _.cloneDeep(scheduleMessageList_K!);
                          for (let i = 0; i <= kakaos.length - 1; i++) {
                            if (kakaos[i].messageId === noti.messageId) {
                              kakaos[i].isSend = value;
                              kakaos[i].cmdType = "U";
                            }
                          }

                          setScheduleMessageList_K(kakaos);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
      </div>

      <div className="flex-center">
        <BaseSection
          title={"세금계산서 자동 발행"}
          children={
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <BaseRadio
                  id={"isTaxInvoiceIssue_true"}
                  disabled={
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "TERMINATE_COMPLETE" ||
                    contract?.contractStep === "APPLY_CONFIRM" ||
                    contract?.contractStep === "USE_COMPLETE"
                  }
                  name={"isTaxInvoiceIssue"}
                  label="발행"
                  value="true"
                  className="mr16"
                  checked={String(editRentalSchedule?.isTaxInvoiceIssue) === "true" ? true : false}
                  onChange={() => {
                    if (provider.provider.isVerifiedTaxInvoice === false) {
                      openToast({
                        content: "해당 상품의 정산정보(프로바이더)는 세금계산서 자동발행을 지원하지 않습니다.",
                        isSystemToast: true,
                      });
                      return;
                    }
                    const temp = _.cloneDeep(editRentalSchedule!);
                    temp.isTaxInvoiceIssue = true;
                    setEditRentalSchedule(temp);
                  }}
                />
                <BaseRadio
                  disabled={
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "TERMINATE_COMPLETE" ||
                    contract?.contractStep === "APPLY_CONFIRM" ||
                    contract?.contractStep === "USE_COMPLETE"
                  }
                  id={"isTaxInvoiceIssue_false"}
                  name={"isTaxInvoiceIssue"}
                  label="발행 안함"
                  value="false"
                  checked={String(editRentalSchedule?.isTaxInvoiceIssue) === "false" ? true : false}
                  onChange={() => {
                    const temp = _.cloneDeep(editRentalSchedule!);
                    temp.isTaxInvoiceIssue = false;
                    setEditRentalSchedule(temp);
                  }}
                />
              </div>
            </div>
          }
        />
        <BaseSection
          titleChildren={
            <div className="flex-center font14">
              세금계산서 발행일
              <BaseTooltip
                contents={""}
                tooltip={"세금계산서 발행일 변경 시, 10일 이내인 경우 다음달부터 변경일로 적용됩니다."}
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          }
          children={
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax100">
                  <BaseSelect
                    isDisabled={
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "TERMINATE_COMPLETE" ||
                      contract?.contractStep === "APPLY_CONFIRM" ||
                      contract?.contractStep === "USE_COMPLETE"
                    }
                    name={"taxInvoiceIssueDay"}
                    placeholder={""}
                    value={Number(editRentalSchedule?.taxInvoiceIssueDay)}
                    stateOptions={monthDays}
                    setStateValue={(value: number) => {
                      const temp = _.cloneDeep(editRentalSchedule!);
                      temp.taxInvoiceIssueDay = value;
                      setEditRentalSchedule(temp);
                    }}
                  />
                </div>
                <div className="ml10 minmax140">
                  <BaseSelect
                    isDisabled={
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "TERMINATE_COMPLETE" ||
                      contract?.contractStep === "APPLY_CONFIRM" ||
                      contract?.contractStep === "USE_COMPLETE"
                    }
                    name={"taxInvoiceIssueMonth"}
                    placeholder={""}
                    value={editRentalSchedule?.taxInvoiceIssueMonth}
                    stateOptions={[
                      { label: "전월 기준", value: "PREV_MONTH" },
                      { label: "당월 기준", value: "CURR_MONTH" },
                      { label: "익월 기준", value: "NEXT_MONTH" },
                    ]}
                    setStateValue={(value: string) => {
                      const temp = _.cloneDeep(editRentalSchedule!);
                      temp.taxInvoiceIssueMonth = value;
                      setEditRentalSchedule(temp);
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
export default ContractBillingScheduleNotiEdit;
