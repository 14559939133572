import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, Sort } from "../public-types";
import qs from "qs";
import { ApiPath } from "..";
import {
  PriceRulesModel,
  PriceListParams,
  BuildingCommonFacilityPriceRulesModel,
  BuildingCommonFacilityPriceRulesParam,
  AppliedFacilityListParams,
} from "./price-types";

/* 
  RIP01. 요금 룰 목록
*/
export async function getPriceRulesListAsync(
  axios: AxiosInstance,
  params?: PriceListParams,
): Promise<AxiosResponse<ApiResponse<{ content: PriceRulesModel[] }>>> {
  return await axios.get(ApiPath.price.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RIP02. 요금 룰 등록
*/
export async function postPriceRulesAsync(
  axios: AxiosInstance,
  params?: PriceRulesModel,
): Promise<AxiosResponse<ApiResponse<{ priceRule: PriceRulesModel[] }>>> {
  return await axios.post(ApiPath.price.add, {
    priceRule: params,
  });
}
/* 
  RIP03. 요금 룰 수정
*/
export async function editPriceRulesAsync(
  axios: AxiosInstance,
  params?: { id: number; payload: PriceRulesModel },
): Promise<AxiosResponse<ApiResponse<{ priceRule: PriceRulesModel[] }>>> {
  if (!params?.id) {
    throw Error("id is not found");
  }
  return await axios.put(ApiPath.price.edit.replace("{id}", params.id.toString()), {
    priceRule: params.payload,
  });
}
/* 
  RIP04. 요금 룰 삭제
*/
export async function deletePriceRulesAsync(
  axios: AxiosInstance,
  params?: {
    idList: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: string }>>> {
  return await axios.delete(ApiPath.price.delete, { params });
}

/*
  RIP05. 상품공용공간별 현재 적용된 기준요금 목록 
*/
export async function getAppliedPriceRulesListAsync(
  axios: AxiosInstance,
  params?: AppliedFacilityListParams,
): Promise<AxiosResponse<ApiResponse<{ content: PriceRulesModel[] }>>> {
  return await axios.get(ApiPath.price.appliedFacilityList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RIP06. 건물-공용공간 요금 룰 목록
*/
export async function getBuildingCommonFacilityPriceRulesAsync(
  axios: AxiosInstance,
  params?: BuildingCommonFacilityPriceRulesParam,
): Promise<AxiosResponse<ApiResponse<{ content: BuildingCommonFacilityPriceRulesModel[] }>>> {
  return await axios.get(ApiPath.price.buildingFacilityList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * RIP07. 건물-공용공간 요금 룰 등록/수정/삭제
 * POST
 * * serviceId = contractId, serviceType = 'SERVICE_CONTRACT, buildingCommonFacilityPriceRuleList cmdType: C, U, D
 * @param axios
 * @param body
 * @returns
 */
export const postBuildingCommonFacilityPriceRulesAsync = (
  axios: AxiosInstance,
  params?: BuildingCommonFacilityPriceRulesModel[],
): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axios.post<ApiResponse<any>>(ApiPath.price.buildingFacilityList, {
    buildingCommonFacilityPriceRuleList: params,
  });
};
