import { useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { PrivilegeCodeMenus, UnionPartnerPrivilegeCode } from "src/types/partner";
interface BaseSelectState {
  placeholder?: string;
  selectOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
    color?: string;
  }>;
  value?: string;
  name?: string;
  setStateValue: Function;
  className?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
  colorType?: string; // 필수는 아니지만 subtitle이 존재한다면 scss에 정의되어있어야함.
  positionLeft?: boolean; // 클릭했을때 메뉴위치 :: 기본값 은 우측정렬
}

/**
 * color-gray , color-blue, color-green, color-red
 */
export const BaseStateSelect = (props: BaseSelectState) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let selectedValue = null; // null 로 초기화 해야 선택안함이 정상적으로 초기화됨
  if (props.value) {
    selectedValue = props.selectOptions.find((option) => String(option.value) === String(props.value));
  }

  const findSelectColor = (selectValue: string) => {
    const selectedOption = props.selectOptions?.find((option) => String(option.value) === String(selectValue));
    return selectedOption?.color || "";
  };

  const { fetchAuthorityReadonly, partnerPrivileges } = usePartnerAuthority();

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // 수정 권한 ("w") 유무 판단 후 select 접근
  const isAuthorityEditable = () => {
    const currentMenu = location.pathname.split("/")[5];
    //현재 페이지의 권한 코드 > "BASIC" | "BUILDING" | "PRODUCT" |"CS" |"CONTRACT" 등 return
    let currentPagePrivilegeCode = "";

    for (let key in PrivilegeCodeMenus) {
      const menus = PrivilegeCodeMenus[key as UnionPartnerPrivilegeCode]; //[main, monitoring,provider,building,product,workorder] 등 메뉴들
      if (menus.includes(currentMenu)) {
        currentPagePrivilegeCode = key; // BASIC, WORKORDER,PRODUCT, BUILDING 등 리턴
        break;
      }
    }

    // 파트너 권한 확인
    const partnerAuthority = partnerPrivileges.find((privilege) => privilege.code === currentPagePrivilegeCode); // {code: "WORKORDER", permissions: ["r","w","d"];} 와 같이 리턴

    //  edit 가능여부
    const editable = partnerAuthority && partnerAuthority.permissions.includes("w");
    if (editable) {
      setIsMenuOpen(true);
    } else {
      fetchAuthorityReadonly(true);
    }
  };

  return (
    <div
      className={`w-100 base-select state-select ${props.value && findSelectColor(props.value)} ${props.colorType || ""} ${
        props.positionLeft ? "position-left" : ""
      }`}
    >
      <Select
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props?.placeholder || "선택해주세요"}
        isSearchable={false}
        value={selectedValue}
        className={`react-select-container w-100 `}
        classNamePrefix="react-select"
        onChange={(e) => {
          e && props.setStateValue(e.value);
        }}
        onMenuOpen={isAuthorityEditable}
        onMenuClose={handleMenuClose}
        menuIsOpen={isMenuOpen}
        onInputChange={toggleMenu} // Select를 클릭했을 때 이벤트 핸들러
        options={props.selectOptions}
        isDisabled={props.isDisabled}
      />
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
