import { useState } from "react";
import { Cell, Row } from "react-table";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { ServiceUserGuide, UserGuideType, UserGuideTypeMap } from "src/api/guide/guide-types";
import { BaseButton, BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import usePostGuideMapping from "src/hooks/guide/usePostGuideMapping";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import SettingModal from "./SettingModal";
import BaseNewTabLink from "src/components/BaseNewTabLink";

export const guideMappingListCol = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ row }: Cell<ServiceUserGuide>) => {
      return <div className="text-center-center">{row.original.id}</div>;
    },
  },

  {
    Header: "유형",
    accessor: "type",
    Cell: ({ row }: Cell<ServiceUserGuide>) => (
      <div className="flex-center-center">
        <span>{UserGuideTypeMap[row?.original?.userGuide?.type as UserGuideType] || "-"}</span>
      </div>
    ),

    width: 200,
  },
  {
    Header: "항목명",
    accessor: "subject",
    width: 330,
    Cell: ({ row }: Cell<ServiceUserGuide>) => {
      const detailPath = `${PagePath.guide.detail.replace(":id", `${row?.original?.userGuide?.id}`)}`;
      return <BaseNewTabLink path={detailPath} value={row?.original?.userGuide?.subject || ""} tooltip></BaseNewTabLink>;
    },
  },
  {
    Header: "노출여부",
    accessor: "isDisplayed",
    width: 200,
    Cell: ({ row }: Cell<ServiceUserGuide>) => {
      return (
        <div className="flex-center-center">
          <span>{row?.original?.isDisplayed ? "노출" : "비노출"}</span>
        </div>
      );
    },
  },
  {
    Header: "정렬순서",
    accessor: "orderNums",
    width: 100,
    Cell: ({ row }: Cell<ServiceUserGuide>) => {
      return (
        <div className="flex-center-center">
          <span>{row?.original?.orderNums ?? "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "설정",
    accessor: "edit",
    width: 80,
    Cell: ({ row, serviceId, serviceType }: { row: Row<ServiceUserGuide>; serviceId: string; serviceType: UnionServiceType }) => {
      const { setAbstractModalZ1 } = useModal();

      return (
        <div className="flex-row flex-center">
          <BaseButton
            title={"설정"}
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                size: "medium",
                children: <SettingModal serviceUserGuide={row.original} serviceId={serviceId} serviceType={serviceType}></SettingModal>,
              })
            }
          />
        </div>
      );
    },
  },
  {
    Header: "삭제",
    accessor: "btn",
    width: 48,
    Cell: ({ row, serviceId, serviceType }: any) => {
      const { setBaseModal } = useModal();
      const { mutate } = usePostGuideMapping();
      const { openToast } = useToast();
      return (
        <button
          className="base-trash-btn color-red"
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnLeftTitle: "취소",
              btnRightTitle: "확인",
              title: `이용안내를 삭제하시겠습니까?`,
              onClick: () => {
                mutate(
                  [
                    {
                      id: row?.original?.id,
                      serviceId: serviceId,
                      serviceType: serviceType,
                      cmdType: "D",
                    },
                  ],
                  {
                    onSuccess: () => openToast({ content: `삭제하였습니다.` }),
                  },
                );
                setBaseModal({ isOpen: false });
              },
            });
          }}
        ></button>
      );
    },
  },
];
