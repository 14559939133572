import moment from "moment";
import { Cell } from "react-table";
import DetailLink from "src/components/DetailLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat, numberToStringWithComma } from "src/utils";
import { findPriceRuleOptionToText, findPriceRuleToText } from "../../price-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { BaseTooltip } from "src/components";
import { PriceRules } from "src/api/price/price-types";

/* 
  공용공간 예약 목록 columns
*/
const priceListColumn = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<any>) => {
      const detailPath = `${PagePath.price.detail.replace(":id", `${props.value}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}`}>
            {props.value}
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "프로바이더",
    accessor: "providerName",
    width: 240,
    Cell: (props: Cell<any>) => {
      return <BaseTooltip className="w-100 text-left" contents={props.value || "-"} />;
    },
  },
  {
    Header: () => {
      return <p className="no-wrap">요금유형</p>;
    },
    accessor: "priceRuleType",
    width: 80,
    Cell: ({ value }: Cell<any>) => {
      return <p>{findPriceRuleToText(value)}</p>;
    },
  },
  {
    Header: "요금 정책명",
    accessor: "name",
    width: 160,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    // Header: "",
    Header: () => {
      return <p className="no-wrap">판매가 (원/30분)</p>;
    },
    accessor: "price",
    width: 120,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="text-right w-100">
          <p>{value.toString() === "0" ? "무료" : numberToStringWithComma(value)}</p>
        </div>
      );
    },
  },
  {
    Header: "원가 (원/30분)",
    accessor: "cost",
    width: 120,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}</p>
        </div>
      );
    },
  },
  {
    Header: "적용 대상",
    accessor: "facility",
    width: 120,
    Cell: ({ row }: Cell<any>) => {
      const ruleType = row.original.priceRuleType;
      const facility = row.original.facilityName;
      return (
        <div className="text-right w-100 ">
          {ruleType === PriceRules.PRICE_RULE_PROVIDER ? (
            <p className="w-100 text-left">전체 공용공간</p>
          ) : ruleType === PriceRules.PRICE_RULE_FACILITY ? (
            <BaseTooltip className="w-100 text-left" contents={facility || "-"} />
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
  {
    Header: "적용 기간",
    accessor: "startDate",
    width: 200,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD) : ""} -
          {props.row.original.endDate ? ` ${moment(props.row.original.endDate).format(ViewYmdFormat.YYYY_MM_DD)}` : ""}
        </div>
      );
    },
  },
  {
    Header: "적용 시간",
    accessor: "endTime",
    width: 120,
    Cell: (props: Cell<any>) => {
      return (
        <div>
          {props.row.original.startTime ? props.row.original.startTime : ""} -{props.value ? props.value : ""}
        </div>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    Cell: (props: Cell<any>) => <BaseTooltip contents={props?.value || "-"} type="date" />,
    width: 120,
  },
  {
    Header: "최종 작성자",
    accessor: "modifiedBy",
    Cell: (props: Cell<any>) => <BaseTooltip contents={props?.value || "-"} isSingleLine />,
    width: 120,
  },
];

export default priceListColumn;
