import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import { BillingDetail, BillingDetailWrap } from "./billing-type";

const getScheduleList = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.list;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

const getScheduleDetail = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.scheduleDetail.replace("{contractId}", params!.contractId!.toString());
  return axios.get<ApiResponse<any>>(path, { params });
};

const updateScheduleDetail = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    body: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.scheduleDetail.replace("{contractId}", String(params!.contractId));
  return axios.patch<ApiResponse<any>>(path, params?.body);
};
//
const getScheduleRoundList = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    isAll?: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.roundList.replace("{scheduleId}", params!.scheduleId!.toString());
  return axios.get<ApiResponse<any>>(path, { params });
};
const getScheduleRoundNOverdueList = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    buildingId?: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.roundNOverdueList.replace("{scheduleId}", String(params!.scheduleId));
  return axios.get<ApiResponse<any>>(path, { params });
};

const editScheduleRoundList = (
  axios: AxiosInstance,
  body?: {
    scheduleId: number;
    billList: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  //console.log("body", body);
  const path = ApiPath.billing.roundList.replace("{scheduleId}", String(body?.scheduleId));
  return axios.patch<ApiResponse<any>>(path, { billList: body?.billList });
};

const editScheduleStop = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const path = ApiPath.billing.scheduleStop.replace("{scheduleId}", String(body!.scheduleId));
  return axios.patch<ApiResponse<any>>(path, { ...body.schedule });
};
// const getScheduleStop = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
//   // console.log("body", body);
//   const path = ApiPath.billing.scheduleStop.replace("{scheduleId}", String(body!.scheduleId));
//   return axios.get<ApiResponse<any>>(path);
// };

const getCreditRelation = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("body", body);
  const path = ApiPath.billing.creaditRelation.replace("{tid}", String(body!.tid));
  return axios.get<ApiResponse<any>>(path);
};

const editTaxInvoiceNumber = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const path = ApiPath.billing.changeTaxInvoiceNumber.replace("{scheduleId}", String(body!.scheduleId));
  return axios.patch<ApiResponse<any>>(path, { ...body.data });
};

/**
 * 관리자 메모 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractMemoList = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const changeId = ApiPath.contract.detail.memo.list.replace("{serviceId}", params!.id!.toString());
  const path = changeId.replace("{serviceType}", "CT_CONTRACT");
  return axios.get<ApiResponse<any>>(path, { params });
};

const addContractMemo = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = { memoContent: body.contractMemo };
  const changeId = ApiPath.contract.detail.memo.add.replace("{serviceId}", body!.contractManageId!.toString());
  const path = changeId.replace("{serviceType}", "CT_CONTRACT");
  return axios.post<ApiResponse<any>>(path, { contractMemo: { ...send } });
};

const editContractMemo = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = {
    contractMemoId: body.contractMemoId,
    memoContent: body.contractMemo,
    isDeleted: "false",
  };
  const path = ApiPath.contract.detail.memo.edit.replace("{memoId}", body!.contractMemoId!.toString());
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};

/**
 * 회원의 관리자 메모 삭제
 * @param axios
 * @param body
 * @returns
 */
const deleteContractMemo = async (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const send = {
    id: body.contractMemoId,
    isDeleted: "true",
  };
  const path = ApiPath.contract.detail.memo.edit.replace("{memoId}", body!.contractMemoId!.toString());
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};
const retryPayment = (
  axios: AxiosInstance,
  params?: {
    billId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("params", params);
  const path = ApiPath.billing.retryPayment.replace("{billId}", String(params?.billId));

  return axios.post<ApiResponse<any>>(path, {});
};
const changeRecieve = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    body: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("params", params);
  const path = ApiPath.billing.receipt.replace("{scheduleId}", String(params?.scheduleId));

  return axios.patch<ApiResponse<any>>(path, { receiptList: params?.body });
};

const connectReceive = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log("params", params);
  const path = ApiPath.billing.creaditConfirm.replace("{contractId}", String(params?.contractId));

  return axios.patch<ApiResponse<any>>(path, [...params?.body.saveCreadits]);
};

/**
 * 2023-09-07 이후 변경된 API대응을 위한 type 정의
 * 상세 정보 조회를 뒤한 API 추가
 * ========================================
 */
export const getScheduleDetail_RCB01 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    supplyType?: string;
    buildingId?: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.getBillingSchedule.replace("{contractId}", params!.contractId!.toString());
  return axios.get<ApiResponse<any>>(path, { params });
};

export const patchScheduleDetail_RC002 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    scheduleList: Array<BillingDetail>;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.getBillingSchedule.replace("{contractId}", params!.contractId!.toString());
  return axios.patch(path, { scheduleList: params?.scheduleList });
};

/** RCB41. 청구 페이레터 결제내역 조회 */
export const getPaymentNCancelList = (
  axios: AxiosInstance,
  params?: {
    billId: string;
    pgcode: string;
    isSuccessOnly: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.getPaymentList.replace("{billId}", params!.billId!);
  return axios.get<ApiResponse<any>>(path, { params });
};

/** RCB93. 페이레터 결제 취소 */
export const paymentCancelRequest = (
  axios: AxiosInstance,
  params?: {
    paymentId: string;
    cancelAmount?: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.paymentCancel.replace("{paymentId}", params!.paymentId!);
  return axios.post<ApiResponse<any>>(path, { cancelAmount: params!.cancelAmount! });
};
/**
 * RCZ12. 계산방식 별 공급가/부가세 계산
 * calculateType : basisTotalAmount, basisSupplyAmount
 * amount : number
 */
export const mntCalAmountTax = (
  axios: AxiosInstance,
  params?: {
    calculateType: string;
    amount?: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.mntCalculateAmountTax;
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/** RCB22. 청구서 등록 */
export const mntBillAdd = (
  axios: AxiosInstance,
  params?: {
    scheduleId: string;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.mntAddOrUpdate_RCB22.replace("{scheduleId}", String(params?.scheduleId));
  return axios.post<ApiResponse<any>>(path, { billList: [params!.body] });
};
/** RCB22. 청구서 수정 */
export const mntBillUpdate = (
  axios: AxiosInstance,
  params?: {
    scheduleId: string;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.mntAddOrUpdate_RCB22.replace("{scheduleId}", String(params?.scheduleId));
  return axios.patch<ApiResponse<any>>(path, { billList: [params!.body] });
};

/** RCB52. 관리비 청구서 이메일 발송 */
export const mntBillSendEmail = (
  axios: AxiosInstance,
  params?: {
    billId: string;
    isSendNow: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.mntSendEmail_RCB52.replace("{billId}", String(params?.billId));
  return axios.post<ApiResponse<any>>(path, { isSendNow: params!.isSendNow! });
};

/** RCB61. 국세청 전자세금계산서 발행 */
export const mntSendNts = (
  axios: AxiosInstance,
  params?: {
    billId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.mntTaxInvoiceNts_RCB61.replace("{billId}", String(params?.billId));
  return axios.post<ApiResponse<any>>(path);
};
//mntWeekdayCheck_RCB13
export const mntGetWeekDay = (
  axios: AxiosInstance,
  params?: {
    checkDate: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.billing.mntWeekdayCheck_RCZ13;
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};
export {
  getScheduleList,
  getScheduleDetail,
  updateScheduleDetail,
  getScheduleRoundList,
  getScheduleRoundNOverdueList,
  editScheduleRoundList,
  editScheduleStop,
  getContractMemoList,
  addContractMemo,
  editContractMemo,
  deleteContractMemo,
  retryPayment,
  changeRecieve,
  connectReceive,
  getCreditRelation,
  editTaxInvoiceNumber,
};
