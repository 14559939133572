import { useAuth0 } from "@auth0/auth0-react";
import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "src/api/public-types";
import { BaseModal } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "../../pagePath.json";

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const [loginModal, setLoginModal] = useState<Modal>({ isOpen: false });

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  useEffect(() => {
    if (query?.code) {
      setLoginModal({ isOpen: true, payload: true });
    }

    if (query?.error) {
      setLoginModal({ isOpen: true, payload: false });
      return;
    }
  }, [query.code, query.error]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`${PagePath.init}?partnerCode=${query.partnerCode || ""}`);
    }
  }, [isAuthenticated, location, query.partnerCode]);

  return (
    <>
      <MetaTag subTitle={"로그인"} />
      <div className="page-login">
        <div className="page-login__content">
          <div className="page-login__logo"></div>
          <h1>로그인</h1>
          <p>회사 구글 계정을 통해 로그인해주세요.</p>
          <button className="login-btn" onClick={() => loginWithRedirect()}>
            Login
          </button>
          {/* <button onClick={() => logout()}>logout</button> */}
        </div>
        <p className="page-login__sub">
          <span>이용에 어려움이 있으신가요?</span> 계약 담당자에게 권한을 요청해 주세요
        </p>
      </div>
      {loginModal.isOpen && (
        <BaseModal
          isOpen={true}
          onClick={() => {
            setLoginModal({ isOpen: false });
          }}
          btnRightTitle={query.error && "확인"}
        >
          {loginModal.payload && (
            <div>
              <h2 className="mb20">로그인 중입니다</h2>
              <p>파트너 관리자로 이동합니다.</p>
              <p>잠시만 기다려주세요.</p>
            </div>
          )}
          {!loginModal.payload && (
            <div>
              <h2 className="mb20">로그인에 실패하였습니다</h2>
              <p className="mb8">{`${query?.error}`}</p>
              <p>{`${query?.error_description}`}</p>
            </div>
          )}
        </BaseModal>
      )}
    </>
  );
};

export default Login;
