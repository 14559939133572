import { ContractStep } from "src/api/contract/contract-types";

interface BasePros {
  status: string;
}

export const BaseContractStatus = (props: BasePros) => {
  const value = props.status;

  if (!props.status) return null;

  return (
    <div
          className={`${
            (value === ContractStep.APPLY_RECEIVED && "status A") ||
            (value === ContractStep.APPLY_CANCEL && "status D") ||
            (value === ContractStep.APPLY_CONFIRM && "status A") ||
            (value === ContractStep.CONTRACT_PLAN && "status A") ||
            (value === ContractStep.CONTRACT_ACCEPT && "status C") ||
            (value === ContractStep.USE_APPROVAL && "status A") ||
            (value === ContractStep.USE_PROGRESS && "status B") ||
            (value === ContractStep.USE_COMPLETE && "status D") ||
            (value === ContractStep.TERMINATE_RECEIVED && "status C") ||
            (value === ContractStep.TERMINATE_COMPLETE && "status D")
          }`}
        >
          {(value === ContractStep.APPLY_RECEIVED && "신청접수") ||
            (value === ContractStep.APPLY_CANCEL && "신청취소") ||
            (value === ContractStep.APPLY_CONFIRM && "신청확인") ||
            (value === ContractStep.CONTRACT_PLAN && "계약예정") ||
            (value === ContractStep.CONTRACT_ACCEPT && "계약체결") ||
            (value === ContractStep.USE_APPROVAL && "이용승인") ||
            (value === ContractStep.USE_PROGRESS && "이용중") ||
            (value === ContractStep.USE_COMPLETE && "이용완료") ||
            (value === ContractStep.TERMINATE_RECEIVED && "해지접수") ||
            (value === ContractStep.TERMINATE_COMPLETE && "해지완료")}
        </div>
  )
};
