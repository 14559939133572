import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderAddModel } from "src/api/provider/provider-types";
import { ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import AccountInfoDetail from "./accountInfoDetail/AccountInfoDetail";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";
import ContractInfoDetail from "./contactInfoDetail/ContractInfoDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "contactInfo", label: "연락처 정보" },
  { value: "accountInfo", label: "연동 정보" },
];

const ProviderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: providerId } = useParams();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  const breadCrumbs = [{ value: "provider", label: "프로바이더" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  // 프로바이더 상세
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  let providerName;
  if (providerDetail?.provider?.providerName) {
    providerName = providerDetail?.provider?.providerName;
  }

  const providerDetailApi = useCallback(
    async (id: string) => {
      const response = await getProviderDetail({ providerId: id });
      if (response.status >= 200 || response.status <= 299) {
        const result = response.data.data;
        setProviderDetail(result);
      }
    },
    [getProviderDetail],
  );

  useEffect(() => {
    if (providerId) {
      providerDetailApi(providerId);
    }
  }, [providerDetailApi, providerId]);

  return (
    <div className="page-product-access">
      <MetaTag subTitle={providerDetail?.provider?.providerName} />
      <ContentsTitle title="정산정보" tabs={tabs} activeTab={activeTab} clickTab={clickTab} detailInfo={[{ title: "id", value: providerId || "" }]} />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoDetail providerDetail={providerDetail!} />}
        {activeTab?.value === "contactInfo" && <ContractInfoDetail />}
        {activeTab?.value === "accountInfo" && <AccountInfoDetail />}
      </div>
    </div>
  );
};

export default ProviderDetail;
