import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { Ac2ProductAccessGroupModel, ProductAccessGroupModel } from "src/api/access/productac-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import TableCheckbox from "src/pages/product/components/TableCheckbox";
import { SelectProductAccessGroupColumn } from "../columns/SelectProductAccessGroupColumn";
import { ContractAccessModel } from "src/api/access/contractac-types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalSelectItem, modalSelectedOptions } from "src/pages/product/hooks/atom";

type Props = {
  setShowModal: Function;
  onSelectedAccessGroups: Function;
  access: ProductAccessGroupModel[] | ContractAccessModel[];
};

const SelectContractAccessGroupModal = ({ setShowModal, onSelectedAccessGroups, access }: Props) => {
  // 액세스그룹
  const [accessGroups, setAccessGroups] = useState<Ac2ProductAccessGroupModel[]>([]);

  const selectedOptions = useRecoilValue(modalSelectedOptions);

  const setAccessSelect = useSetRecoilState(modalSelectItem);

  // 선택된 액세스그룹
  // const [selectedAccessGroups, setSelectedAccessGroups] = useState<Ac2ProductAccessGroupModel[]>([]);

  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useState<any>({
    sort: {
      orders: [
        {
          property: "name",
          direction: "ASC",
        },
      ],
    },
  });

  const columns: any = useMemo(() => SelectProductAccessGroupColumn, []);
  // const data = useMemo(() => accessGroups, [accessGroups]);

  const data = useMemo(
    () =>
      accessGroups.map((item) => {
        const selects = selectedOptions.map((select: any) => Number(select.id));

        return {
          checked: selects.includes(Number(item.id)),
          ...item,
        };
      }),
    [accessGroups, selectedOptions],
  );

  const onChangeKeyword = useCallback((value: string) => {
    setKeyword(value);
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, selectedFlatRows, rows } = useTable(
    {
      columns: columns,
      data: data,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          width: 100,
          Header: "선택",
          Cell: ({ row }: any) => {
            let newRow = { ...row, disabled: false };
            access.forEach((item) => {
              if (Number(item.accessGroupId) === Number(row.original.id)) newRow.disabled = true;
            });

            let currentState = row.getToggleRowSelectedProps();

            return (
              <div>
                <BaseCheckbox
                  id={`${row.original.id}`}
                  name={`${row.original.id}`}
                  disabled={newRow.disabled}
                  checked={row.original.checked}
                  onChange={(checked) => {
                    row.original.checked = checked;
                    if (checked) {
                      setAccessSelect((prev: any) => [...prev, row.original]);
                    } else {
                      setAccessSelect((prev: any) => prev.filter((item: any) => item.id !== row.original.id));
                      // row.isSelected = false;
                    }
                  }}
                />
                {/* <TableCheckbox id={row.id} {...currentState} disabled={newRow.disabled} /> */}
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );
  // 선택된 row
  // useEffect(() => {
  //   if (selectedFlatRows.length > 0) {
  //     const selectRows = selectedFlatRows.map((item) => item.original);
  //     setSelectedAccessGroups(selectRows);
  //   }
  // }, [selectedFlatRows, setSelectedAccessGroups]);

  // 액세스그룹 조회

  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);
  const getAccessGroupsList = useCallback(
    async (passParams?: any) => {
      const { data } = await getAccessList({ searchValue: passParams.searchValue, page: passParams.page, size: passParams.size || 20 });
      setAccessGroups(data?.data.content || null);
      setPageMeta(data?.meta.pageMeta);
    },
    [getAccessList],
  );

  useEffect(() => {
    const queryParams = {
      page: 0,
      size: 20,
      sort: {
        orders: [
          {
            property: "name",
            direction: "ASC",
          },
        ],
      },
    };
    getAccessGroupsList(queryParams);
  }, [getAccessGroupsList]);

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <div>
      <BaseAbstractModal className="dialog-modal " isOpen={true}>
        <section className="base-abstract-modal__title flex-center-between ">
          <h1>출입그룹 추가</h1>
          <div className="right-area">
            <div className="minmax260">
              <BaseInput
                name="keyword"
                type="text"
                placeholder="검색어를 입력해주세요"
                value={params.searchValue}
                onChange={(value: string) => {
                  // onChangeKeyword(value);
                  setParams({ ...params, searchValue: value });
                }}
                onKeyUp={() => {
                  getAccessGroupsList({ ...params, page: 0 });
                }}
                onSearchClick={() => {
                  getAccessGroupsList({ ...params, page: 0 });
                }}
              />
            </div>
          </div>
        </section>
        {/*table */}
        <section className="base-abstract-modal__contents ">
          <div className="contents-container__table px30 ">
            <div {...getTableProps()} className="base-table view-data-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.length > 0 ? (
                  rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              setParams({ ...params, ...{ page } });
              getAccessGroupsList({ ...params, page });
            }}
            sizeOption={(size) => {
              setParams({ ...params, page: 0, size });
              getAccessGroupsList({ ...params, page: 0, size });
            }}
            currentSize={Number(params.size) || 20}
            //
            children={
              <>
                <BaseButton
                  title="취소"
                  onClick={() => {
                    setShowModal(false);
                    setAccessSelect([]);
                  }}
                  className="color-white mr10"
                />
                <BaseButton
                  title="선택"
                  onClick={() => {
                    setShowModal(false);
                    onSelectedAccessGroups(selectedOptions);
                    setAccessSelect([]);

                    // onSelectedAccessGroups(selectedAccessGroups);
                  }}
                />
              </>
            }
          />
        </section>
      </BaseAbstractModal>
    </div>
  );
};

export default SelectContractAccessGroupModal;
