import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell, useBlockLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { BizType, MbOrg, OrgListParams } from "src/api/organization/org-types";
import { Modal, PageMeta, Sort } from "src/api/public-types";
import { BaseInput, BasePagination, BaseSelect, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import BaseScroll from "src/components/BaseScroll";
import TableRadioButton from "src/pages/building/components/TableRadioButton";
import { autoHypenCorp, companyRegistrationHypen } from "src/utils";
//

type Props = {
  selectedOwner: (owner: MbOrg) => void;
  setOwnerModal: Dispatch<React.SetStateAction<Modal>>;
};

type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  keyword?: string;
  sort?: Sort;
  search001?: string;
  classification?: string;
};

const selectOptions = [
  { value: "OWNER", label: "선택 가능만" },
  { value: "", label: "전체" },
];

const OrganizationListPopup = ({ selectedOwner, setOwnerModal }: Props) => {
  // 관리처 건물 팝업 columns
  const columns: any[] = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ row, value }: Cell<MbOrg>) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "법인명/상호",
        accessor: "name",
        width: 350,
        Cell: (props: Cell<MbOrg>) => {
          return <BaseTooltip className="text-left w-100" contents={props.value} />;
        },
      },
      {
        Header: "사업 형태",
        accessor: "bizType",
        width: 130,
        Cell: (props: Cell<MbOrg>) => {
          return (
            <>
              <div>{props.value === BizType.CORP ? "법인사업자" : "개인사업자"}</div>
            </>
          );
        },
      },
      {
        Header: "사업자 등록번호",
        accessor: "bizRegNo",
        width: 150,
        Cell: (props: Cell<MbOrg>) => {
          return (
            <>
              <div>{companyRegistrationHypen(props.value) || "-"}</div>
            </>
          );
        },
      },
      {
        Header: "법인 등록번호",
        accessor: "corRegNo",
        width: 150,
        Cell: (props: Cell<MbOrg>) => {
          return (
            <>
              <div>{autoHypenCorp(props.value) || "-"}</div>
            </>
          );
        },
      },
    ],

    [],
  );

  const { executeAsync: getOrgListApi } = useApiOperation(getOrgList);
  const [organizations, setOrganizations] = useState<MbOrg[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const query: QueryParams = useMemo(() => {
    const _query = {
      page: 0,
      size: 20,
      sort: { orders: [{ property: "id", direction: "DESC" }] },
      classification: "OWNER",
    };
    return _query;
  }, []);

  const { control, getValues, setValue } = useForm<QueryParams>({
    defaultValues: query,
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = useTable(
    {
      columns,
      data: organizations || [],
    },
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 80,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            const isOwner = row.original.classifications.find((type: string) => type === "OWNER");

            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  disabled={isOwner ? false : true}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedOrg"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    const getOrganizationList = async (orgtListParams: OrgListParams) => {
      //
      const { data } = await getOrgListApi(orgtListParams);
      setOrganizations(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);
      //
    };

    getOrganizationList(getValues());
  }, [getValues]);

  // 검색 / 페이징 처리

  // 검색 / 페이징 처리
  const onPagenationAndSearchList = useCallback(
    async (passParams?: QueryParams, type?: "search" | "page") => {
      const testParmas = getValues();
      let newQueryParams;
      // 검색일 시  params 사용
      newQueryParams = { ...testParmas, ...(passParams || {}) };

      // }

      const response = await getOrgListApi(newQueryParams);
      if (response.status >= 200 && response.status <= 299) {
        setOrganizations(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getOrgListApi, getValues],
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <BaseAbstractModal isOpen={true} className="dialog-modal">
      <section className="base-abstract-modal__title flex-center-between">
        <h1>소유주</h1>
        <div className="right-area d-flex">
          <div className="minmax140 mr8">
            <Controller
              control={control}
              name="classification"
              render={({ field: { onChange, value, name } }) => (
                <BaseSelect
                  stateOptions={selectOptions}
                  setStateValue={(selected: string) => {
                    onChange(selected);
                    onPagenationAndSearchList({ classification: selected });
                  }}
                  value={value}
                />
              )}
            ></Controller>
          </div>
          <div className="minmax260 ">
            <Controller
              control={control}
              name="search001"
              render={({ field: { onChange, value, name } }) => (
                <BaseInput
                  className=""
                  placeholder="검색어를 입력하세요"
                  onChange={(search001: string) => onChange(search001)}
                  value={value}
                  onSearchClick={() => onPagenationAndSearchList({ page: 0 }, "search")}
                  onKeyUp={() => onPagenationAndSearchList({ page: 0 }, "search")}
                />
              )}
            ></Controller>
          </div>
        </div>
      </section>
      {/* 테이블 */}
      <section className="base-abstract-modal__contents">
        <div className="contents-container__table px30">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps(header.getSortByToggleProps())} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {organizations.length && organizations.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="px30">
        <BasePagination
          pageIndex={pageMeta?.pageRequest.page || 0}
          totalPages={pageMeta?.totalPages || 0}
          sizeOption={(size) => {
            setValue("size", size);
            onPagenationAndSearchList({ ...getValues(), page: 0, size }, "search");
          }}
          currentSize={getValues("size") || 20}
          totalElements={pageMeta?.totalElements || 0}
          goPage={(page: number) => {
            onPagenationAndSearchList({ page }, "page");
          }}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => setOwnerModal({ isOpen: false })}>
                취소
              </button>
              <button
                className="base-btn"
                onClick={() => {
                  selectedOwner(selectedFlatRows[0].original);
                }}
              >
                완료
              </button>
            </div>
          }
        />
      </div>
    </BaseAbstractModal>
  );
};

export default OrganizationListPopup;
