import BaseChip from "src/components/BaseChip";
import { ChipOptions, DashboardChip } from "./types";

interface Props {
  selected: DashboardChip;
  onSelected?: (selected: DashboardChip) => void;
}

function DashboardDatepickerChip({ selected, onSelected }: Props) {
  const NEW_SELECTED = { today: false, sevenDays: false, sevenDaysAfter: false, oneMonth: false, oneMonthAfter: false };
  const onClick = (key: ChipOptions) => onSelected && onSelected({ ...NEW_SELECTED, [key]: true });

  return (
    <div className="flex-center-center ml20 gap8">
      <BaseChip title="오늘" selected={selected.today} onClick={() => onClick("today")}/>
      <BaseChip title="1주일 전" selected={selected.sevenDays} onClick={() => onClick("sevenDays")}/>
      <BaseChip title="1주일 후" selected={selected.sevenDaysAfter} onClick={() => onClick("sevenDaysAfter")}/>
      <BaseChip title="1개월 전" selected={selected.oneMonth} onClick={() => onClick("oneMonth")}/>
      <BaseChip title ="1개월 후" selected={selected.oneMonthAfter} onClick={() => onClick("oneMonthAfter")}/>
    </div>
  );
}
export default DashboardDatepickerChip;
