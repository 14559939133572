import { useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { getVocListAsync } from "src/api/voc/voc-api";
import { CsItemModel, VocQueryParams } from "src/api/voc/voc-types";

export function useVocList(query: VocQueryParams) {
  const [list, setList] = useState<CsItemModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const { executeAsync: getList } = useApiOperation(getVocListAsync);

  useEffect(() => {
    const fetchVocList = async (params: VocQueryParams) => {
      const { data, status } = await getList(params);

      if (status >= 200 && status <= 299) {
        setList(data.data.content);
        setPageMeta(data.meta.pageMeta);
      }
    };

    fetchVocList(query);
  }, [getList, query]);

  return { list, pageMeta };
}
