import moment from "moment";
import { Cell } from "react-table";
import { EventModel } from "src/api/access/ac-types";
import { BaseTooltip } from "src/components";

export const UserEventColumns: any = [
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 80,
  },
  {
    Header: "상태",
    accessor: "eventCode",
    Cell: ({ value }: any) => {
      return (
        <>
          <div
            className={`${
              (value.substring(0, 2) === "41" && "chip green") ||
              (value.substring(0, 2) === "43" && "chip gray") ||
              (value.substring(0, 2) === "64" && "chip gray")
            }`}
          >
            {
              (value.substring(0, 2) === "41" && "인증 성공") ||
              (value.substring(0, 2) === "43" && "인증 실패") ||
              (value.substring(0, 2) === "64" && "인증 실패")
            }
          </div>
        </>
      );
    },
    width: 100,
  },
  {
    Header: "이벤트명",
    accessor: "eventName",
    width: 180,
  },
  {
    Header: "출입시간",
    accessor: "eventTime",
    Cell: ({ value }: any) => <>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</>, 
    width: 180,
  },
  {
    Header: "출입장치명",
    accessor: "deviceName",
    width: 250,
    Cell: (props: Cell<EventModel>) => {
      return (
        <>
          <BaseTooltip contents={props.value} isSingleLine={true} />
        </>
      )
    }    
  },
];
