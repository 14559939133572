import { useCallback, useEffect, useState } from "react";
import { BillingDetail, ScheduleNoti } from "src/api/billingSchedule/billing-type";
import { DetailTotalData } from "src/api/contract/contract-types";
import { BaseCheckbox, BaseTooltip } from "src/components";
import BaseSection from "src/components/layout/BaseSection";

interface BasicProps {
  detailData: DetailTotalData;
}
const ContractBillingScheduleNotiDetail = ({ detailData }: BasicProps) => {
  const R = "RENTAL";
  const KAKAOTALK = "KAKAOTALK";
  const { scheduleList } = detailData;

  const [rentalSchedule, setRentalSchedule] = useState<BillingDetail>();
  const [mntSchedule, setMntSchedule] = useState<BillingDetail[]>();
  const [scheduleMessageList_K, setScheduleMessageList_K] = useState<Array<ScheduleNoti>>([]);
  const [scheduleMessageList_E, setScheduleMessageList_E] = useState<Array<ScheduleNoti>>([]);

  const initDatas = useCallback(() => {
    if (scheduleList) {
      const schedule = scheduleList?.find((sd: BillingDetail) => sd.supplyType === R);
      const mntList = scheduleList?.filter((sd: BillingDetail) => sd.supplyType !== R);

      setRentalSchedule(schedule);
      setMntSchedule(mntList);

      if (schedule) {
        let kakaos: Array<ScheduleNoti> = schedule?.scheduleMessageList!.filter((ml: ScheduleNoti) => ml.messageMedia === KAKAOTALK);
        let emails: Array<ScheduleNoti> = schedule?.scheduleMessageList!.filter((ml: ScheduleNoti) => ml.messageMedia !== KAKAOTALK);

        setScheduleMessageList_K(kakaos);
        setScheduleMessageList_E(emails);
      }
    }
  }, [scheduleList]);

  useEffect(() => {
    initDatas();
  }, [initDatas]);

  return (
    <>
      <div className="d-flex">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"공문 종류"}
            children={
              <div className="contents-container__grid-contents">
                <p>{scheduleMessageList_E!?.length === 0 && "-"}</p>
                {scheduleMessageList_E?.map((noti: any, key: number) => {
                  return (
                    <div className="flex-center" key={key}>
                      <BaseCheckbox
                        className={`minmax155 mb5 mr10`}
                        name={"paybBllMessageList"}
                        checked={noti.isSend}
                        id={"paybBllMessageListE_" + noti.messageId}
                        label={noti.messageLabel}
                        disabled={true}
                      />
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"알림톡 종류"}
            children={
              <div>
                <p>{scheduleMessageList_K.length === 0 && "-"}</p>

                {scheduleMessageList_K.map((noti: any, key: number) => {
                  return (
                    <div className="flex-center" key={key}>
                      <BaseCheckbox
                        className={`mb5 mr10`}
                        name={"paybBllMessageList"}
                        checked={noti.isSend}
                        id={"paybBllMessageListK_" + noti.messageId}
                        label={noti.messageLabel}
                        disabled={true}
                      />
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
      </div>

      <div className="flex-center">
        <BaseSection
          titleChildren={
            <div className="flex-center font14">
              세금계산서
              <BaseTooltip
                contents={""}
                tooltip={"세금계산서 발행일 변경 시, 10일 이내인 경우 다음달부터 변경일로 적용됩니다."}
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          }
          children={
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start ">
                <div
                  className={
                    "font14 " + rentalSchedule?.isTaxInvoiceIssue !== undefined
                      ? String(rentalSchedule?.isTaxInvoiceIssue) === "true"
                        ? "chip green"
                        : "chip red"
                      : "chip red"
                  }
                >
                  {rentalSchedule?.isTaxInvoiceIssue !== undefined
                    ? String(rentalSchedule?.isTaxInvoiceIssue) === "true"
                      ? "자동 발행중"
                      : "발행 안함"
                    : "발행 안함"}{" "}
                </div>
                <div className="ml5 font14">
                  ({rentalSchedule?.taxInvoiceIssueMonth === "PREV_MONTH" && "전월 기준"}
                  {rentalSchedule?.taxInvoiceIssueMonth === "CURR_MONTH" && "당월 기준"}
                  {rentalSchedule?.taxInvoiceIssueMonth === "NEXT_MONTH" && "익월 기준"}
                  {rentalSchedule?.taxInvoiceIssueDay ? " / " + rentalSchedule?.taxInvoiceIssueDay + "일" : "-"} )
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
export default ContractBillingScheduleNotiDetail;
