import { useRecoilState } from "recoil";
import { apiCallCountState } from "src/recoil/api-call-count/atom";

/* 
  이 훅은 진행중인 API 호출 수를 관리하는 훅입니다.
  API 호출이 진행 중일 때 로딩 UI를 보여주기 위해 사용됩니다.
*/

function useApiLoading() {
  const [count, setCount] = useRecoilState(apiCallCountState);

  const isApiLoading = () => count > 0;

  const startApiLoading = () => setCount((prevCount) => prevCount + 1);
  const stopApiLoading = () => setCount((prevCount) => prevCount - 1);

  return { isApiLoading, startApiLoading, stopApiLoading };
}

export default useApiLoading;
