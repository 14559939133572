import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderListQueryParams, ProviderModel } from "src/api/provider/provider-types";
import { Order, PageMeta, Status } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { qsParse } from "src/utils";
import { ProviderTypeOptions } from "../provider-types";
import ProviderColumns from "./columns/ProviderColumns";

const ProviderList = () => {
  const [providers, setProviders] = useState<Array<ProviderModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthority } = usePartnerAuthority();

  // 프롤바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  const queryParams: ProviderListQueryParams = useMemo(() => {
    const _queryParams: ProviderListQueryParams = qsParse(location.search);

    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }

    if (!_queryParams.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "providerId", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const getProviderListApi = useCallback(
    async (param: ProviderListQueryParams) => {
      const response = await getProviers(param);
      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviers, location.search],
  );
  const [params, setParams] = useState<ProviderListQueryParams>({ ...queryParams });

  useEffect(() => {
    setParams({ ...queryParams });

    getProviderListApi(queryParams);
  }, [getProviderListApi, queryParams]);

  const navigateWithQueryParams = useCallback(
    (passParams?: ProviderListQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }
      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120">
                  <BaseSelect
                    placeholder="구분"
                    stateOptions={ProviderTypeOptions}
                    value={params.supplyType}
                    setStateValue={(supplyType: Status) => {
                      setParams({ ...params, supplyType });
                      navigateWithQueryParams({ ...params, page: 0, supplyType }, "search");
                    }}
                  />
                </div>
                <div>
                  <BaseInput
                    value={params?.searchValue || ""}
                    // className="mr16"
                    type="text"
                    placeholder="검색어를 입력해주세요."
                    onChange={(searchValue: string) => setParams({ ...params, searchValue })}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onClearClick={() => {
                      const editParams = { ...params };
                      delete editParams.searchValue;
                      // setParams(editParams);
                      navigateWithQueryParams({ ...params, page: 0, searchValue: "" });
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">
            {isAuthority("w") && <BaseButton title="+ 정산정보 등록" onClick={() => navigate(PagePath.provider.form)} />}
          </div>
        </div>
        {providers && (
          <BaseTable
            data={providers}
            columns={ProviderColumns}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 }, "search");
            }}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            totalElements={pageMeta?.totalElements}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            orders={params?.sort?.orders}
            disabledSortHeaders={["rentalCost", "image"]}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } }, "search");
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProviderList;
