import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { mntCalAmountTax } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseInput, BaseSelect, BaseTooltip } from "src/components";
import { numberToStringWithComma, onlyNumber } from "src/utils";
import { BillDetail } from "../BillDetailListInfo";

export const detailCols: any = [
  {
    Header: () => {
      return <div className="flex-center font14 required">과세여부</div>;
    },
    accessor: "checkTaxAmount",
    sticky: "left",
    width: 120,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      const options = [
        { value: "tax", label: "과세" },
        { value: "nontax", label: "비과세" },
      ];
      const handleChangeOption = (val: string) => {
        const temp = _.cloneDeep(data);
        temp.forEach((tp: any) => {
          if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
            tp.checkTaxAmount = val;
            if (val !== "tax") {
              tp.taxAmount = 0;
              tp.totalAmount = tp.supplyAmount + tp.taxAmount;
            }
          }
        });
        setDetailList(temp);
      };
      useEffect(() => {
        if (bill.isOverdueBill) {
          row.original.checkTaxAmount = "nontax";
        }
      }, [bill, row]);

      return (
        <BaseSelect
          menuPosition="fixed"
          menuPortalTarget={document.body}
          stateOptions={options}
          value={row.original.checkTaxAmount}
          setStateValue={handleChangeOption}
          isDisabled={bill?.isConfirmed || !showBtn || bill.isOverdueBill}
        />
      );
    },
  },
  {
    Header: () => {
      return <div className="flex-center font14 required">항목명</div>;
    },
    accessor: "itemName",
    sticky: "left",
    width: 160,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      const [itemName, setItemName] = useState(value);
      const handleChange = (val: string) => {
        const temp = _.cloneDeep(data);
        temp.forEach((tp: any) => {
          if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
            tp.itemName = val;
          }
        });

        setDetailList(temp);
        setItemName(val);
      };

      return <BaseInput type="text" value={itemName} onChange={handleChange} disabled={bill?.isConfirmed || !showBtn} />;
    },
    Footer: ({ rows }: any) => {
      return <p>합계 : </p>;
    },
  },
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center font14">
          공급가
          <BaseTooltip
            contents={""}
            tooltip={"'공급가'와 '부가세'를 먼저 입력하면 '소계'가 자동 계산됩니다. 소계에서 부가세를 뺀 금액입니다. (소계 / 1.1, 소수점 이하 절사)"}
            type={"normal"}
            className={"tooltip__trigger-icon"}
          />
        </div>
      );
    },
    accessor: "supplyAmount",

    width: 120,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      const handleChange = (val: string) => {
        const reVal = onlyNumber(val);
        const temp = _.cloneDeep(data);
        temp.forEach((tp: any) => {
          if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
            tp.supplyAmount = Number(reVal);
            tp.totalAmount = Number(tp.supplyAmount) + Number(tp.taxAmount);
            tp.isAutoTaxUse = false;
          }
        });
        setDetailList(temp);
      };

      return (
        <div className="flex-center-between">
          <BaseInput
            type="text"
            className="text-right"
            value={numberToStringWithComma(value)}
            onChange={handleChange}
            disabled={bill?.isConfirmed || !showBtn}
          />
          <p className="ml10">원</p>
        </div>
      );
    },
    Footer: ({ rows, supplyTotal }: any) => {
      return (
        <div className="flex-center-between">
          <p>{numberToStringWithComma(supplyTotal)}</p>
          <p className="ml10">원</p>
        </div>
      );
    },
  },
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center font14">
          부가세
          <BaseTooltip
            contents={""}
            tooltip={"'공급가'와 '부가세'를 먼저 입력하면 '소계'가 자동 계산됩니다. 소계에서 공급가를 뺀 금액입니다. (소계 / 11, 소수점 이하 절상)"}
            type={"normal"}
            className={"tooltip__trigger-icon"}
          />
        </div>
      );
    },
    accessor: "taxAmount",

    width: 120,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      const handleChange = (val: string) => {
        const reVal = onlyNumber(val);
        const temp = _.cloneDeep(data);
        temp.forEach((tp: any) => {
          if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
            tp.taxAmount = Number(reVal);
            tp.totalAmount = Number(tp.supplyAmount) + Number(tp.taxAmount);
          }
        });
        setDetailList(temp);
      };
      const readOnly = useMemo(() => {
        let rtn = true;
        if (row.original.checkTaxAmount === "tax") {
          rtn = false;
        }
        return rtn;
      }, [row]);

      return (
        <div className="flex-center-between">
          <BaseInput
            type="text"
            className="text-right"
            value={numberToStringWithComma(Number(value))}
            onChange={handleChange}
            disabled={readOnly || bill?.isConfirmed || !showBtn || bill?.isOverdueBill}
          />
          <p className="ml10">원</p>
        </div>
      );
    },
    Footer: ({ rows, taxTotal }: any) => {
      return (
        <div className="flex-center-between">
          <p>{numberToStringWithComma(taxTotal)}</p>
          <p className="ml10">원</p>
        </div>
      );
    },
  },
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center font14">
          소계
          <BaseTooltip
            contents={""}
            tooltip={"항목의 '소계'를 먼저 입력하면 '공급가'와 '부가세'가 자동 계산됩니다. 공급가와 부가세를 합한 금액입니다. (공급가 + 부가세)"}
            type={"normal"}
            className={"tooltip__trigger-icon"}
          />
        </div>
      );
    },
    accessor: "totalAmount",
    width: 120,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      const { executeAsync: calculate } = useApiOperation(mntCalAmountTax, { noLoading: true });
      const [amount, setAmount] = useState(0);

      const getCalculateAmount = useCallback(
        async (totalAmount: number) => {
          const params = { calculateType: "basisTotalAmount", amount: totalAmount };

          const rtn = await calculate(params);
          if (rtn.status >= 200 && rtn.status <= 400) {
            const temp = _.cloneDeep(data);
            temp.forEach((tp: any) => {
              if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
                tp.supplyAmount = Number(rtn.data.data.content.supplyAmount);
                tp.taxAmount = Number(rtn.data.data.content.taxAmount);
              }
            });
            setDetailList(temp);
          }
        },
        [calculate, data, row.original.dtlId, row.original.dtlOrder, setDetailList],
      );

      const handleChange = (val: string) => {
        const reVal = onlyNumber(val);
        const temp = _.cloneDeep(data);
        temp.forEach((tp: any) => {
          if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
            tp.totalAmount = Number(reVal);
          }
        });
        setDetailList(temp);
        setAmount(Number(reVal));
      };

      const checkAmountsTax = () => {
        console.log('console.log("reVal", reVal) amount;', amount);
        const reVal = onlyNumber(String(amount));
        console.log("reVal", reVal);
        if (row.original.checkTaxAmount === "tax" && row.original.isAutoTaxUse) {
          getCalculateAmount(Number(reVal));
        }
        if (row.original.checkTaxAmount === "nontax") {
          const temp = _.cloneDeep(data);
          temp.forEach((tp: any) => {
            if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
              tp.supplyAmount = Number(reVal);
            }
          });
          setDetailList(temp);
        }
      };

      return (
        <div className="flex-center-between">
          <BaseInput
            type="text"
            className="text-right"
            value={numberToStringWithComma(Number(value))}
            onChange={handleChange}
            onBlur={checkAmountsTax}
            disabled={bill?.isConfirmed || !showBtn}
          />
          <p className="ml10">원</p>
        </div>
      );
    },
    Footer: ({ rows, priceTotal }: any) => {
      return (
        <div className="flex-center-between">
          <p>{numberToStringWithComma(priceTotal)}</p>
          <p className="ml10">원</p>
        </div>
      );
    },
  },
  {
    Header: "비고",
    accessor: "description",

    width: 220,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      const handleChange = (val: string) => {
        const temp = _.cloneDeep(data);
        temp.forEach((tp: any) => {
          if (tp.dtlOrder === row.original.dtlOrder && tp.dtlId === row.original.dtlId) {
            tp.description = val;
          }
        });
        setDetailList(temp);
      };
      return <BaseInput type="text" value={value} onChange={handleChange} disabled={bill?.isConfirmed || !showBtn} placeholder={"비고 입력(선택)"} />;
    },
  },
];
export const editCol: any = [
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center-start">
          {bill?.isConfirmed === false && showBtn && (
            <button
              className="base-add-btn"
              onClick={() => {
                console.log("test click");
                let temp: any = _.cloneDeep(data);
                let orderCnt = 1;
                temp.map((tp: any) => {
                  orderCnt = tp.dtlOrder + 1;
                });
                const addModel: BillDetail = {
                  checkTaxAmount: "tax",
                  billId: "0",
                  dtlOrder: orderCnt,
                  supplyAmount: 0,
                  taxAmount: 0,
                  totalAmount: 0,
                  isAutoTaxUse: true,
                  isDeleted: false,
                };
                temp.push(addModel);
                console.log("temp", temp);
                setDetailList(temp);
              }}
            />
          )}
        </div>
      );
    },
    accessor: "editable",
    sticky: "right",
    width: 50,
    Cell: ({ value, row, data, setDetailList, bill, showBtn }: any) => {
      // console.log("row", row.original);

      return (
        <div className="flex-center-start">
          {bill?.isConfirmed === false && showBtn && (
            <button
              onClick={() => {
                let rm: any = _.cloneDeep(data);

                const idx = rm.findIndex(function (arr: any) {
                  return arr.dtlOrder === row.original.dtlOrder && arr.dtlId === row.original.dtlId;
                });

                const find = rm[idx];
                // console.log("find", find);

                if (find && find.dtlId !== 0) {
                  rm.forEach((dt: any, index: number) => {
                    if (idx === index) {
                      dt.isDeleted = true;
                    }
                  });
                } else {
                  rm.splice(idx, 1);
                }
                setDetailList(rm);
              }}
              className="base-erase-btn size-large"
            />
          )}
        </div>
      );
    },
  },
];
