import { AxiosInstance, AxiosResponse } from "axios";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse, Sort } from "../public-types";
import { BuildingFloorModel, BuildingPropertyListData, BuildingRoomsByOwnerListData, BuildingUpdateResponseData, DeleteType } from "./building-types";

/* 
  RPB07. 건물 프로퍼티 목록
*/
export async function getPropertiesAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
  },
): Promise<AxiosResponse<ApiResponse<BuildingPropertyListData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  const path = ApiPath.building.property.replace("{id}", params.buildingId.toString());
  return await axios.get<ApiResponse<BuildingPropertyListData>>(path);
}

/* 
  CPB08. 건물 프로퍼티 등록
*/
export async function createPropertyAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    buildingFloorList: Array<BuildingFloorModel>;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  const { buildingId, buildingFloorList } = params || {};
  if (!buildingId) {
    throw Error("buildingId is not found.");
  }
  if ((buildingFloorList || []).length === 0) {
    throw Error("buildingFloorList is not found.");
  }
  const path = ApiPath.building.property.replace("{id}", buildingId.toString());
  return await axios.post(path, {
    building: {
      buildingFloorList,
    },
  });
}

/* 
  CPB09. 건물 프로퍼티 수정
*/
export async function updatePropertyAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    buildingFloorList: Array<BuildingFloorModel>;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  const { buildingId, buildingFloorList } = params || {};
  if (!buildingId) {
    throw Error("buildingId is not found.");
  }
  if ((buildingFloorList || []).length === 0) {
    throw Error("buildingFloorList is not found.");
  }
  const path = ApiPath.building.property.replace("{id}", buildingId.toString());
  return await axios.put(path, {
    building: {
      buildingFloorList,
    },
  });
}

/* 
  CPB10. 건물 프로퍼티 삭제
*/
export async function deletePropertyAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    deleteType: DeleteType;
    ids: Array<number>; // 삭제타입 대상 id
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.deleteType) {
    throw Error("deleteType is not found.");
  }
  if ((params?.ids || []).length === 0) {
    throw Error("roomIds is not found.");
  }
  const path = ApiPath.building.property.replace("{id}", params?.buildingId.toString());

  return await axios.delete(path, {
    data: {
      deleteType: params.deleteType,
      ids: params.ids,
    },
  });
}

/* 
  RPB19. 소유주별 보유 호실 목록
*/
export async function getRoomsByOwnerAsync(
  axios: AxiosInstance,
  params?: {
    ownerId: string;
    page?: number;
    size?: number;
    sort?: Sort;
  },
): Promise<AxiosResponse<ApiResponse<BuildingRoomsByOwnerListData>>> {
  if (!params?.ownerId) {
    throw Error("ownerId is not found.");
  }

  const path = ApiPath.building.room.list.replace("{ownerId}", params.ownerId.toString());
  return await axios.get<ApiResponse<BuildingRoomsByOwnerListData>>(path, {
    params,
  });
}
