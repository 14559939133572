import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { CommonFacilityType } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { getReservationListAsync, getReservationUsageListAsync } from "src/api/reservation/reservation-api";
import { ReservationListModel, ReservationListParams, ReservationUsageParams } from "src/api/reservation/reservation-types";
import { BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import RangeDatepicker from "src/components/RangeDatepicker";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import useNavigate from "src/hooks/usePartnerNavigate";
import { Partner } from "src/types/partner";
import { YmdFormat, downloadExcel, qsParse } from "src/utils/common-util";
import {
  ExternalServiceType,
  Modal,
  ReservationExternalTypes,
  ReservationState,
  getStatusText,
  reservationHeaders,
  reservationUsageHeaders,
} from "../reservation-types";
import { reservationColumns } from "./columns";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  start?: string;
  end?: string;
  searchType?: string;
  keyword?: string; // 인풋 value로 사용
  search001?: string;
  statusCode?: string;
  inboundChannel?: ReservationExternalTypes;
  facilityType?: CommonFacilityType;
  sort?: Sort;
  parentId?: string;
  partnerId?: string;
  partnerName?: string;
  id?: string;
  containsFacilityName?: string;
  containsBuildingName?: string;
  containsOrganizerMemberNo?: string;
  containsContractApplyNumber?: string;
  [key: string]: string | number | ReservationExternalTypes | CommonFacilityType | Sort | undefined | unknown; // 문자열 인덱스 시그니처 추가
};

// 검색 대상
const searchTypes = [
  { value: "ALL", label: "전체" },
  { value: "containsFacilityName", label: "공간 명" },
  { value: "containsBuildingName", label: "건물 명" },
  { value: "containsOrganizerMemberNo", label: "회원번호" },
  { value: "id", label: "ID" },
  { value: "parentId", label: "그룹 ID" },
  { value: "containsContractApplyNumber", label: "신청번호" },
];

const searchTypeKey = [
  //검색조건
  "id",
  "parentId",
  "containsFacilityName",
  "containsBuildingName",
  "containsOrganizerMemberNo",
  "containsContractApplyNumber",
];

// 공간 타입
const facilityType = [
  { value: "", label: "전체" },
  { value: "MEETING_ROOM", label: "회의실" },
  { value: "DESK", label: "좌석" },
  { value: "REFRESH_ROOM", label: "편의시설" },
];

// 상태 - 예약구분이 정상일 경우
const statuses = [
  { value: "", label: "전체" },
  { value: ReservationState.ACCEPTED, label: "접수" },
  { value: ReservationState.ACKNOWLEDGED, label: "점유" },
  { value: ReservationState.INCOMPLETE, label: "불완전" },
  { value: ReservationState.CANCELLED, label: "취소" },
];

// 상태
const inboundChannel = [
  { value: "", label: "전체" },
  { value: ExternalServiceType.TAAP, label: "TAAP" },
  { value: ExternalServiceType.GC, label: "GC" },
  { value: ExternalServiceType.OC, label: "OC" },
];

/**
 * 공용공간 예약 > 목록 화면
 */
const ReservationList = () => {
  // 로딩바

  const navigate = useNavigate();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC", nullHandling: "NULLS_LAST" }],
      };
    }
    if (_queryParams?.sort) {
      _queryParams.sort = {
        orders: _queryParams.sort.orders?.map((order) => ({
          ...order,
          nullHandling: "NULLS_LAST",
        })),
      };
    }
    if (!_queryParams?.keyword) {
      _queryParams.keyword = "";
    }
    const queryParamsToDecode = [
      "start",
      "end",
      "keyword",
      "search001",
      "id",
      "parentId",
      "containsFacilityName",
      "containsBuildingName",
      "containsOrganizerMemberNo",
      "containsContractApplyNumber",
    ];

    for (const key of queryParamsToDecode) {
      if (_queryParams?.[key]) {
        _queryParams[key] = decodeURIComponent(_queryParams[key] as string);
      }
    }

    const dump = _.cloneDeep(dateRange);
    if (_queryParams?.start !== undefined && _queryParams?.end !== "") {
      // _queryParams.startDate = moment().subtract(7, "days").format("YYYY-MM-DD") + "T00:00:00+09:00";
      dump[0] = moment(_queryParams?.start).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.end !== undefined && _queryParams?.end !== "") {
      // _queryParams.end = moment().format("YYYY-MM-DD") + "T23:59:59.999+09:00";
      dump[1] = moment(_queryParams?.end).toDate();
    } else {
      dump[1] = null;
    }
    setDateRange(dump);

    return _queryParams;
  }, [location]);
  // 레인지 데이트 피커

  const [startDate, endDate] = dateRange;
  // location search (url query parameter) 를 읽어서 object 로 변환

  const [reservationList, setReservationList] = useState<Array<ReservationListModel>>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // 공용공간 엑셀 목록
  const [csvData, setCsvData] = useState<ReservationListModel[]>([]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]); // 선택된 파트너
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState<boolean>(false);

  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);
  const { executeAsync: getReservationUsageList } = useApiOperation(getReservationUsageListAsync); // 예약 소진시간 목록 조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner); // 파트너 조회

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRange;
    setDateRange(dateRange);
    if (!startDate || !endDate) return;

    let start = moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000+09:00";
    let end = moment(endDate).format("YYYY-MM-DD") + "T23:59:59.999+09:00";

    navigateWithQueryParams({ ...queryParams, start: String(start), end: String(end), page: 0 });
  };
  // 파트너 조회
  const fetchPartner = async (id: string) => {
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setSelectedPartners([partner]);
    }
  };
  // 공용공간 예약 리스트 api
  const getReservationListApi = useCallback(
    async (reservationParams: ReservationListParams) => {
      if (reservationParams.keyword === "") {
        delete reservationParams.keyword;
      }
      if (reservationParams.search001 !== "" && reservationParams.search001 !== undefined) {
        reservationParams.search001 = decodeURIComponent(reservationParams.search001 || "");
      }
      const response = await getReservationList(reservationParams);
      if (params.partnerId) {
        await fetchPartner(String(params.partnerId));
      }
      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setReservationList(result);
        setPageMeta(response.data.meta.pageMeta);
      } else {
        return;
      }
    },
    [getReservationList, location],
  );

  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
    setParams({
      ...params,
      partnerId: String(partners[0].id),
      partnerName: decodeURIComponent(String(partners[0].name)),
    });
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams & { ALL?: string }, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }
      let newQueryParams = { ...data, ...(passParams || {}) };
      // delete newQueryParams.keyword;
      if (includeSearchType() !== "") {
        delete newQueryParams.search001;
      } else {
        newQueryParams = { ...newQueryParams, search001: newQueryParams.keyword };
      }
      delete newQueryParams.ALL;

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  /** 공용공간 예약목록 엑셀 다운로드 */
  const onDownloadReservations = async (passParams: QueryParams) => {
    if (!reservationList) return;
    const newParams = { ...passParams };
    let excelData: any = [];
    const defaultSize = 1000;
    const reservationListParams: ReservationListParams = {
      ...params,
      ...{
        statusCode: newParams.statusCode,
      },
      search001: decodeURIComponent(newParams?.search001 || ""),
      page: 0,
      size: defaultSize,
    };

    const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);

    for (let i = 0; i < totalPages; i++) {
      const { data } = await getReservationList(reservationListParams);
      reservationListParams.page = i + 1;
      excelData = [...excelData, ...data.data.content];
    }

    // 목록이 나오면 다운받을 엑셀 형태를 수정
    if (excelData.length > 0) {
      // 한글 데이터로 변경
      const newData = excelData?.map((item: any) => {
        if (item.endDate && item.startDate) {
        }
        //reservationHeaders
        item = {
          id: item.id,
          reservationGroupId: item.parentId,
          productId: item.facility.building?.id,
          contract: item.contract?.applyNumber,
          inboundChannel: item.inboundChannel,
          buildingName: item.facility?.building?.name,
          buildingCommonFacilityFloorNum: Math.abs(item.facility.floor),
          buildingCommonFacilityIsGround: item.facility.floor > 0 ? "지상" : "지하",
          buildingCommonFacilityId: item.facility.id,
          buildingCommonFacilityLocationCode: item.facility.locationCode,
          facility: item.facility.type === "DESK" ? "좌석" : item.facility.type === "MEETING_ROOM" ? "회의실" : "편의시설", //공간타입
          buildingCommonFacilityName: item.facility.name,
          start: moment(item.start).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시)
          end: moment(item.end).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (종료일시)
          memberNo: item.organizer.memberNo, // 회원번호
          memberEmail: item.organizer.userEmail,
          summary: item.summary,
          createdDate: moment(item.createdDate).format(YmdFormat.YYYY_MM_DD_HH_MM), // 생성일자
          status: getStatusText(item.status.code), //상태
        };

        return item;
      });

      try {
        const fileName =
          startDate && endDate
            ? `ctrl.room_공용공간예약_${moment(startDate).format("YYYYMMDD")}~${moment(endDate).format("YYYYMMDD")}_${moment().format(
                "YYYYMMDDHHmm",
              )}`
            : `ctrl.room_공용공간예약_전체_${moment().format("YYYYMMDDHHmm")}`;
        await downloadExcel({
          data: newData,
          fileName,
          header: reservationHeaders.map((header) => header.label),
        });
        setAlertModal({ isOpen: true, title: "엑셀 다운로드가 완료되었습니다." });
      } catch (error) {
        setAlertModal({ isOpen: true, title: "엑셀 다운로드에 실패하였습니다.", message: String(error) });
      }
    }
  };
  // 소진시간 목록 엑셀 다운로드
  const onDownloadReservationUsages = async (passParams: QueryParams) => {
    if (!reservationList) return;
    const { sort, page, size, ...rest } = passParams;
    let excelData: any = [];
    const defaultSize = 1000;
    const reservationUsageParams: ReservationUsageParams = {
      reservation: {
        ...rest,
      },
      sort: {
        orders: [
          { property: "reservationId", direction: "DESC", nullHandling: "NULLS_LAST" },
          { property: "order", direction: "ASC", nullHandling: "NULLS_LAST" },
        ],
      },
      page: 0,
      size: defaultSize,
    };

    const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);

    for (let i = 0; i < totalPages; i++) {
      const { data } = await getReservationUsageList(reservationUsageParams);
      reservationUsageParams.page = i + 1;
      excelData = [...excelData, ...data.data.content];
    }

    // 목록이 나오면 다운받을 엑셀 형태를 수정
    if (excelData.length > 0) {
      // 한글 데이터로 변경
      const newData = excelData?.map((item: any) => {
        if (item.endDate && item.startDate) {
        }
        //reservationUsageHeaders
        item = {
          reservationId: item.reservationId, // id
          contractApplyNumber: item.contract.applyNumber, // 신청/계약 번호
          facilityBuildingName: item.facility.building.name, //건물명
          facilityType: item.facility.type, //공간타입
          facilityId: item.facility.id, // 공간id
          facilityName: item.facility.name, //공간명
          start: moment(item.start).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시),
          end: moment(item.end).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시),
          duration: item.duration, // 소진시간
          organizerMemberNo: item.organizer.memberNo, // 예약자 (회원번호)
          organizerPhoneNumber: item.organizer.phoneNumber, //예약자 (휴대폰번호)
          organizerUserEmail: item.organizer.userEmail, //예약자 (이메일)
        };

        return item;
      });

      try {
        const fileName =
          startDate && endDate
            ? `ctrl.room_공용공간예약_소진시간_${moment(startDate).format("YYYYMMDD")}~${moment(endDate).format("YYYYMMDD")}_${moment().format(
                "YYYYMMDDHHmm",
              )}`
            : `ctrl.room_공용공간예약_소진시간_전체_${moment().format("YYYYMMDDHHmm")}`;
        await downloadExcel({
          data: newData,
          fileName,
          header: reservationUsageHeaders.map((header: any) => header.label),
        });
        setAlertModal({ isOpen: true, title: "엑셀 다운로드가 완료되었습니다." });
      } catch (error) {
        setAlertModal({ isOpen: true, title: "엑셀 다운로드에 실패하였습니다.", message: String(error) });
      }
    }
  };

  const returnStatusCodes = useMemo(() => {
    let result: any = [];
    if (params.statusCode !== undefined && params.statusCode !== "") {
      result = params.statusCode.split(",");
    }

    return result;
  }, [params.statusCode]);

  const includeSearchType = () => {
    return searchTypeKey.find((item) => item in params) || "";
  };

  const removeOtherSearchType = (searchType: string) => {
    const removedOtherSearchTypeArray = searchTypeKey.filter((item) => item !== searchType);
    let newParams: any = { ...params };
    for (const key in params) {
      if (removedOtherSearchTypeArray.includes(key)) {
        delete newParams[key];
        delete newParams.ALL;
      }
    }
    return newParams;
  };
  useEffect(() => {
    setParams({ ...queryParams }); // input 초기화
    const _queryParams = { ...queryParams };
    const reservationListParams: ReservationListParams = {
      ..._queryParams,
    };

    getReservationListApi(reservationListParams);
  }, [getReservationListApi, queryParams]);
  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* section 태그가 지정검색, 조건검색, 영역이고 반드시 필요함 */}
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div>
                  <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                </div>
                <div className="minmax120">
                  <BaseSelect
                    placeholder="공간 타입"
                    value={params.facilityType}
                    className="pre-wrap"
                    stateOptions={facilityType}
                    setStateValue={(facilityType: CommonFacilityType) => {
                      setParams({ ...params, facilityType });
                      navigateWithQueryParams({ ...params, page: 0, facilityType }, "search");
                    }}
                  />
                </div>
                <div className="minmax120">
                  <BaseSelect
                    placeholder="예약경로"
                    value={params.inboundChannel}
                    stateOptions={inboundChannel}
                    setStateValue={(inboundChannel: ReservationExternalTypes) => {
                      setParams({ ...params, inboundChannel });
                      navigateWithQueryParams({ ...params, page: 0, inboundChannel }, "search");
                    }}
                  />
                </div>
                <div className="minmax160">
                  <BaseMultiSelect
                    placeholder="예약상태"
                    stateOptions={statuses}
                    value={returnStatusCodes || []}
                    setStateValue={(options: Array<{ value: string; label: string }>) => {
                      const statusCode = options.map((obj: { value: string; label: string }) => obj.value).join(",");
                      setParams({ ...params, statusCode });
                      navigateWithQueryParams({ ...params, statusCode, page: 0 });
                    }}
                  />
                </div>
                <BaseInputWithSearch
                  type="text"
                  selectValue={includeSearchType()}
                  inputValue={decodeURIComponent(String(params?.keyword) || "")}
                  stateOptions={searchTypes}
                  setStateValue={(searchType: string) => {
                    if (searchType !== "") {
                      const type = { [searchType]: params?.keyword };
                      const newParams = removeOtherSearchType(searchType);
                      setParams({ ...newParams, ...type });
                    } else {
                      setParams({ ...params, keyword: params?.keyword });
                    }
                  }}
                  onClearClick={() => {
                    const findOptionName = Object.keys(params).find((key) => searchTypeKey.includes(key));
                    if (findOptionName) {
                      navigateWithQueryParams({ page: 0, keyword: "", [findOptionName]: "" }, "search");
                    } else {
                      navigateWithQueryParams({ page: 0, keyword: "" }, "search");
                    }
                  }}
                  onChange={(keyword: string) => {
                    if (includeSearchType() !== "" && includeSearchType() !== "ALL") {
                      const type = { [includeSearchType()]: decodeURIComponent(keyword), keyword: decodeURIComponent(keyword) };
                      setParams({ ...params, ...type });
                    } else {
                      setParams({ ...params, keyword: decodeURIComponent(keyword) });
                    }
                  }}
                  onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                  onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                />
              </div>
            </section>
          </div>
          <div className="right-area"></div>
        </div>
        {isPartnerModalOpen && (
          <PartnerSelectModal
            onCanceled={() => {
              setIsPartnerModalOpen(false);
            }}
            onAdded={onAddSelectPartners}
            defaultValues={selectedPartners || []}
          />
        )}
        {reservationList && (
          <BaseTable
            data={reservationList}
            columns={reservationColumns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number }, "search");
            }}
            currentSize={Number(params.size)}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={queryParams?.sort?.orders}
            children={
              <>
                <button
                  className="base-btn color-white icon-excel"
                  onClick={() => {
                    onDownloadReservations(params);
                  }}
                >
                  예약내역 엑셀받기
                </button>
                <button
                  className="base-btn color-white icon-excel"
                  onClick={() => {
                    onDownloadReservationUsages(params);
                  }}
                >
                  소진시간내역 엑셀받기
                </button>
              </>
            }
            disabledSortHeaders={[
              "memberNo",
              "memberEmail",
              "end",
              "inboundChannel",
              "facility",
              "facility.floor",
              "organizer.memberNo",
              "organizer.userEmail",
              "organizer.phoneNumber",
              "organizer.displayName",
            ]}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ ...params, sort: { orders } });
              }
            }}
          />
        )}
      </div>
      {/* 확인버튼만 있는 alert 모달 */}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
          onClose={() => setAlertModal({ isOpen: false })}
          title={alertModal.title}
        />
      )}
    </div>
  );
};
export default ReservationList;
