import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { DetailTotalData, ESignListDetail, EsignType } from "src/api/contract/contract-types";
import { BaseButton, BaseInput, BaseModal, BaseRadio } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { ViewYmdFormat } from "src/utils";
import useEsignApi from "../../hooks/useEsignApi";
import MakeEsignInfo from "../modal/MakeEsignInfo";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setEditable?: any;
  viewOnly?: boolean;
  setData: any;
  initMainData?: any;
  setIsEsign?: any;
  data?: any;
}
const columns: any = [
  {
    Header: "문서 이름",
    accessor: "workflowName",
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100"}>
          <p className="text-left">{value && value}</p>
        </div>
      );
    },
    width: 250,
  },
  {
    Header: "상태",
    accessor: "status",
    Cell: ({ value, row, setData, viewOnly, setEditable }: any) => {
      const { cancelEsignData } = useEsignApi();
      const [isDelete, setIsDelete] = useState(false);
      const [reason, setReason] = useState("");
      const [message, setMessage] = useState("");
      const [title, setTitle] = useState("");
      const [isPop, setIsPop] = useState(false);
      const [reasonFlag, setReasonFlag] = useState(true);

      useEffect(() => {
        if (row.original.status) {
          if (row.original.status === EsignType.Playing) {
            setTitle("취소");
          }
          if (row.original.status === EsignType.Canceled) {
            setTitle("삭제");
          }
          if (row.original.status === EsignType.Truncate) {
            setTitle("폐기");
          }
          setReason("내용 오류로 인한 " + title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [row, setTitle, setReason]);

      const cancelDocument = useCallback(
        async (reason: string) => {
          let nextStatus = "";
          if (value === EsignType.Playing) {
            nextStatus = "CANCEL";
          }
          if (value === EsignType.Canceled) {
            nextStatus = "DELETE";
          }
          if (value === EsignType.Truncate) {
            nextStatus = "DISPOSAL";
          }
          // console.log("row", row.original);
          const sendData = { contractId: row.original.contractId, workflowId: row.original.workflowId, commandType: nextStatus, comment: reason };
          // console.log("sendData", sendData);
          const response = await cancelEsignData(sendData);
          if (response.data.data.message) {
            setMessage("");
            setIsPop(true);
            setEditable(false);
            setData();
          }
          setIsDelete(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cancelEsignData, row, setData, value],
      );
      return (
        <>
          {isPop && (
            <BaseModal isOpen={isPop} title="문서 상태가 변경되었습니다." btnRightTitle="확인" onClick={() => setIsPop(false)}>
              {message ? <p>{message}</p> : ""}
            </BaseModal>
          )}
          <div>
            {value === EsignType.Complete && <span className={`${value === EsignType.Complete && "chip green"}`}>완료</span>}
            {value === EsignType.Canceled && (
              <div className="flex-row flex-center-start">
                <span className={`${value === EsignType.Canceled && "chip gray"}`}>취소</span>
              </div>
            )}
            {value === EsignType.Disposal && (
              <div className="flex-row flex-center-start">
                <span className={`${value === EsignType.Disposal && "chip red"}`}>폐기</span>
              </div>
            )}
            {value === EsignType.Playing && (
              <div className="flex-row flex-center-start">
                <span className={`${value === EsignType.Playing && "chip blue"}`}>진행중</span>
                {viewOnly === false && (
                  <BaseButton
                    className="color-white ml15"
                    title={"문서 " + title}
                    onClick={() => {
                      setIsDelete(true);
                    }}
                    disabled={value !== EsignType.Playing}
                  />
                )}
              </div>
            )}
            {value === EsignType.Truncate && (
              <div className="flex-row flex-center-start">
                <span className={`${value === EsignType.Truncate && "chip red"}`}>완전삭제</span>
              </div>
            )}
          </div>
          {isDelete && (
            <BaseAbstractModal isOpen={isDelete} size={"medium"} className="dialog-modal ">
              <section className="base-abstract-modal__title flex-center-between ">
                <h1>문서 {title}</h1>
              </section>
              <section className="base-abstract-modal__contents mx30">
                <ul className="base-list">
                  <li>문서를 {title}하시면 복구할 수 없습니다.</li>
                </ul>
                <div className="contents-container mt20">
                  <section className="contents-container__grid my0">
                    <div className="contents-container__grid-index">
                      <p>문서명</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <BaseInput disabled value={row.original.workflowName} />
                    </div>
                  </section>

                  <section className="contents-container__grid mb0">
                    <div className="contents-container__grid-index">
                      <p className="">{title} 사유</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <BaseRadio
                        id="reasonType1"
                        name="reasonType"
                        className="mr10"
                        value={"true"}
                        label={"내용 오류로 인한 " + title}
                        checked={String(reasonFlag) === "true" ? true : false}
                        onChange={() => {
                          setReasonFlag(true);
                          setReason("내용 오류로 인한 " + title);
                        }}
                      ></BaseRadio>
                      <div className="minmax300 font14">
                        <BaseRadio
                          id="reasonType2"
                          name="reasonType"
                          value={"false"}
                          label="직접 입력"
                          checked={String(reasonFlag) === "false" ? true : false}
                          onChange={() => {
                            setReasonFlag(false);
                            setReason("");
                          }}
                        ></BaseRadio>
                        {reasonFlag === false && (
                          <BaseInput
                            maxLength={250}
                            name="reason"
                            className="mt10"
                            placeholder={title + " 사유를 입력하세요(250자 이내)"}
                            value={reason}
                            onChange={(value: string) => {
                              setReason(value);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <section className="base-abstract-modal__btn-wrap">
                <BaseButton
                  title={title + " 안함"}
                  className="color-white"
                  onClick={() => {
                    setIsDelete(false);
                  }}
                />
                <BaseButton
                  title={"문서 " + title}
                  onClick={() => {
                    cancelDocument(reason);
                  }}
                />
              </section>
            </BaseAbstractModal>
          )}
        </>
      );
    },
    width: 180,
  },

  {
    Header: "수정일시",
    accessor: "modifiedDate",
    width: 220,
    Cell: ({ value, row }: any) => {
      return <>{value && moment(value).subtract(9, "hours").format(ViewYmdFormat.FULL)}</>;
    },
  },
  {
    Header: "문서 내용 확인",
    accessor: "edit",
    width: 300,
    Cell: ({ row, detailData, getDetail }: any) => {
      const [, setDetail] = useState<ESignListDetail>();
      const clickPlayingDocument = useCallback(async () => {
        const dt = await getDetail(detailData.contract.contractId, row.original.workflowId);
        if (dt) {
          setDetail(dt);
          window.open(dt.downloadUrl, "_blank");
        }
      }, [detailData.contract.contractId, getDetail, row.original.workflowId]);
      const clickCertDocument = useCallback(async () => {
        const dt = await getDetail(detailData.contract.contractId, row.original.workflowId);

        if (dt) {
          setDetail(dt);
          window.open(dt.certificateUrl, "_blank");
        }
      }, [detailData.contract.contractId, getDetail, row.original.workflowId]);

      return (
        <>
          <div className="flex-row flex-center-start">
            <BaseButton
              className="color-white mr10"
              title="문서"
              onClick={() => clickPlayingDocument()}
              disabled={EsignType.Complete !== row.original.status}
            />
            <BaseButton
              className="color-white"
              title="이력 인증서"
              onClick={() => clickCertDocument()}
              disabled={EsignType.Complete !== row.original.status}
            />
          </div>
        </>
      );
    },
  },
];
const EsignOnLink = ({ detailData, viewOnly, setData, setEditable, initMainData, setIsEsign, data }: ContractBasicProp) => {
  const { getList, getDetail } = useEsignApi();
  const [eSignData, setEsignData] = useState<Array<ESignListDetail>>([]);
  const [eSignflags, setESignFlags] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [origin, setOrigin] = useState<any>();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: eSignData,
      initialState: { pageSize: 1000 },
      detailData,
      getDetail,
      setData,
      viewOnly,
      setEditable,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const initDatas = useCallback(async () => {
    if (detailData?.contract?.contractId) {
      const list = await getList(Number(detailData?.contract?.contractId)!);
      let rv = _.sortBy(list, "modifiedDate").reverse();
      setEsignData(rv);
      if (data) {
        setOrigin(data);
      }
    }
  }, [detailData, getList, data]);

  useEffect(() => {
    if (eSignflags === false && setData && _.isEqual(data, origin)) {
      setData(origin);
    }
  }, [eSignflags, setData]);

  useEffect(() => {
    if (detailData) {
      initDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);
  return (
    <>
      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={viewOnly ? "" : "required"}>계약서</p>
          </div>
          {viewOnly === false && (
            <div className="contents-container__grid-contents">
              <BaseButton className="color-white" title="전자서명 바로가기" onClick={() => setESignFlags(true)} />
              {eSignflags && (
                <MakeEsignInfo
                  setEditable={setEditable}
                  setIsSaved={setIsSaved}
                  flags={eSignflags}
                  setESignFlags={setESignFlags}
                  initDatas={initDatas}
                  initMainData={initMainData}
                  setIsEsign={setIsEsign}
                  setData={setData}
                />
              )}
            </div>
          )}
        </section>
      </div>
      <div className="flex-row flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index"></div>
          <div className="contents-container__grid-contents">
            <div className="flex-row d-flex flex-center-start">
              <div {...getTableProps()} className="base-table sticky">
                <div className="header">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                      {headerGroup.headers.map((header) => {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            {header.render("Header")}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row: any) => {
                    prepareRow(row);

                    return (
                      <div {...row.getRowProps()} className={`base-table__tr`}>
                        {row.cells &&
                          row.cells.map((cell: any, index: number) => {
                            // console.log(row.original, user);
                            // const visibleFlag = row.original.createdBy === user?.email ? false : true;

                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                  <div>
                    {rows.length === 0 && (
                      <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {isSaved && (
        <BaseModal
          isOpen={isSaved}
          btnRightTitle="확인"
          title={"전송 되었습니다."}
          onClick={() => {
            setIsSaved(false);
          }}
        ></BaseModal>
      )}
    </>
  );
};
export default EsignOnLink;
