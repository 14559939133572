import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateProductAsync } from "src/api/product/product-api";
import { ProductEditModel } from "src/api/product/product-types";
import { axiosInstance } from "src/utils";

const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: async (product: ProductEditModel) => await updateProductAsync(axiosInstance, { product }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getProductDetailAsync"],
      });
    },
  });

  return { ...query };
};

export default useUpdateProduct;
