import { Cell } from "react-table";
import { VisitorListModel } from "src/api/visitor/visitor-types";
import DetailLink from "src/components/DetailLink";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";

export const VisitorLinkCell = (props: Cell<VisitorListModel>) => {
  let detailPath = `${PagePath.visitor.detail.replace(":contractApplyNumber", `${props.row.original.contractApplyNumber}`)}`;

  detailPath = `${detailPath.replace(":visitApplyNumber", `${props.row.original.visitApplyNumber}`)}`;

  return (
    <div>
      {props.value ? (
        <DetailLink to={detailPath} className="text-underline">
          {props.value}
        </DetailLink>
      ) : (
        "-"
      )}
    </div>
  );
};
