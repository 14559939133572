import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSetRecoilState } from "recoil";
import { AdminMemoAddModel, Memo, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { MediaFile, Modal } from "src/api/public-types";
import { BaseModal, BaseTooltip } from "src/components";
import { baseAbstractModalStateZ1 } from "src/recoil/modalState/atom";
import AdminMemoDetail from "./AdminMemoDetail";
import AdminMemoForm from "./AdminMemoForm";

type Props = {
  data: Array<Memo>;
  remove?: any;
  callList?: any;
  viewOnly?: boolean;
  medias?: MediaFile[];
};

// 공간상품 기본정보 form (건물/호실) 부분 테이블
const AdminMemoTable = ({ data, remove, callList, viewOnly, medias }: Props) => {
  const { user } = useAuth0();
  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false, payload: 0 });
  const setAbstractModalZ1 = useSetRecoilState(baseAbstractModalStateZ1);

  // 관리자메모  form 열기
  const openAdminMemoForm = (serviceId: string, serviceType: UnionServiceType, memoId: string) => {
    return setAbstractModalZ1({
      isOpen: true,
      size: "large",
      children: (
        <AdminMemoForm
          serviceId={Number(serviceId)}
          serviceType={serviceType}
          memoId={memoId || ""}
          callList={callList}
          onClose={() => setAbstractModalZ1({ isOpen: false })}
          openAdminMemoDetail={openAdminMemoDetail}
        />
      ),
    });
  };

  // 관리자메모 상세 열기
  const openAdminMemoDetail = (serviceId: string, serviceType: UnionServiceType, memoId: string) => {
    return setAbstractModalZ1({
      isOpen: true,
      size: "large",
      children: (
        <AdminMemoDetail
          serviceId={Number(serviceId)}
          serviceType={serviceType || ""}
          memoId={memoId || ""}
          callList={callList}
          onClose={() => setAbstractModalZ1({ isOpen: false })}
          openAdminMemoForm={openAdminMemoForm}
          viewOnly={viewOnly}
        />
      ),
    });
  };
  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 120,
        Cell: ({ row, value }: Cell<AdminMemoAddModel>) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: "내용",
        accessor: "memo",
        width: 490,
        Cell: ({ row, value }: Cell<AdminMemoAddModel>) => {
          let hasMedia = false;
          if (medias && medias?.length > 0) {
            hasMedia = medias.some((media) => String(media.serviceIdList![0]) === row.original.id);
          }
          return (
            <div className="flex-center-start w-100">
              <span
                className="text-underline cursor-pointer text-blue ellipsis"
                onClick={() => {
                  const memoId = String(row.original.id);
                  const serviceId = String(row.original.serviceId);
                  const serviceType = row.original.serviceType as UnionServiceType;
                  openAdminMemoDetail(serviceId, serviceType, memoId);
                }}
              >
                {value}
              </span>
              <div className={`${hasMedia ? "ic-file ml4" : ""} `}></div>
            </div>
          );
        },
      },
      {
        Header: "등록 일시",
        accessor: "modifiedDate",
        width: 150,
        Cell: ({ value }: any) => <BaseTooltip contents={value || ""} type="date" />,
      },
      {
        Header: "작성자",
        accessor: "modifiedBy",
        width: 200,
      },
      {
        Header: "삭제",
        accessor: "action",
        width: 110,
        Cell: ({ row, setDeleteModal }: any) => {
          const adminId = row.original.id;
          const isDisabled = user?.email === row.original?.createdBy;
          return (
            <button
              className="base-trash-btn color-gray"
              disabled={!isDisabled}
              onClick={() => {
                setDeleteModal({ isOpen: true, payload: adminId });
              }}
            ></button>
          );
        },
      },
    ],
    [medias, user],
  );

  //가변헤더 사용 viewOnly로 수정/삭제 컬럼 제어
  const settingHeader = useMemo(() => {
    let head = _.cloneDeep(columns);
    if (viewOnly !== undefined && viewOnly === true) {
      head[1].width = 600;
      head = head.slice(0, head.length - 1);
    } else {
      head[1].width = 490;
    }
    return head;
  }, [columns, viewOnly]);

  const { getTableProps, getTableBodyProps, headerGroups, page, rows, prepareRow } = useTable(
    {
      columns: settingHeader,
      data: data,
      setDeleteModal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  // 관리자 메모 삭제
  const onDeleteAdminMemoApi = useCallback(async (memoId: number) => {
    if (memoId !== 0) {
      let idList: Array<number> = [];
      idList.push(Number(memoId));

      const response = await remove(idList);
      if (response.status >= 200 && response.status <= 299) {
        callList();
      }
      setDeleteModal({ isOpen: false, payload: 0 });
    }
  }, []);

  return (
    <section className="inner-tab-table">
      <div className="">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {rows.length === 0 && (
            <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {deleteModal.isOpen && (
        <BaseModal
          isOpen={deleteModal.isOpen}
          title={"정말로 삭제하시겠습니까?"}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            onDeleteAdminMemoApi(Number(deleteModal.payload));
          }}
          onClose={() => setDeleteModal({ isOpen: false })}
        ></BaseModal>
      )}
    </section>
  );
};

export default AdminMemoTable;
