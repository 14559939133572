import _ from "lodash";
import moment from "moment";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { editCCM22, getCCM16, getCCM23, saveCCM22 } from "src/api/chargeMnt/chargeMnt-api";
import { ChartBasicDetail } from "src/api/chargeMnt/chargeMnt-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseDatePicker, BaseModal, BaseSelect, BaseTextarea } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import { PagePath } from "src/pages/product/details";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat } from "src/utils";

interface Props {
  setTitle: Function;
}
const BasicInfo = ({ setTitle }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openErrorModal } = useErrorModal();
  const { openToast } = useToast();

  const { executeAsync: getBuildingCharge } = useApiOperation(getCCM16);
  const { executeAsync: getChartDetail } = useApiOperation(getCCM23);

  const { executeAsync: addGroupItem } = useApiOperation(saveCCM22);
  const { executeAsync: editGroupItem } = useApiOperation(editCCM22);

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  let id = queryParams.id ? queryParams.id : useParams.prototype.id;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [ctMntGroupList, setCtMntGroupList] = useState<any>([]);

  const [ctMntGroup, setCtMntGroup] = useState<ChartBasicDetail>({
    ctMntChart: {
      groupId: "",
      yearMonth: "",
      calcStartDate: "",
      calcEndDate: "",
      billIssueDate: "",
      billCloseDate: "",
      noticeMemo: "",
      isDeleted: false,
    },
  });

  const onChangeBuilding = useCallback(
    async (building: any) => {
      const temp: ChartBasicDetail = _.cloneDeep(ctMntGroup);

      temp.buildingId = building.id;
      temp.buildingName = building.buildingName;

      setCtMntGroup(temp);
      const response: any = await getBuildingCharge({ buildingId: temp.buildingId });

      if (response.status > 199 && response.status < 300) {
        const buildingChargeGroup = response.data.data.ctMntGroupList;
        const selectList = buildingChargeGroup.map((chargeGroup: any) => {
          return { label: chargeGroup.groupName, value: chargeGroup.groupId };
        });
        setCtMntGroupList(selectList);
      }
      setIsOpen(false);
    },
    [ctMntGroup, getBuildingCharge],
  );

  const moveDetail = useCallback(() => {
    const path =
      PagePath.chargeMnt.detail.replace(":id", id) + "?" + decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));
    navigate(path);
  }, [id, navigate, queryParams]);

  const groupDetail = useCallback(
    async (chartId: number) => {
      const response: any = await getChartDetail({ chartId });
      console.log("form detail", response);
      if (response.status >= 200 && response.status <= 299) {
        const building = {
          id: response.data.data.ctMntChartBundle.buildingId,
          buildingName: response.data.data.ctMntChartBundle.buildingName,
        };
        onChangeBuilding(building);
        setCtMntGroup(response.data.data.ctMntChartBundle);
        setTitle(response.data.data.ctMntChartBundle.groupName);
      }
    },
    [getChartDetail, setTitle],
  );

  const addGI = useCallback(async () => {
    console.log("addGi request ctMntGroup", ctMntGroup);
    const temp = _.cloneDeep(ctMntGroup);
    if (temp.ctMntChart) {
      temp.ctMntChart.calcStartDate = moment(temp.ctMntChart.calcStartDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.calcEndDate = moment(temp.ctMntChart.calcEndDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.billIssueDate = moment(temp.ctMntChart?.billIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.billCloseDate = moment(temp.ctMntChart?.billCloseDate).format(YmdFormat.WITH_TIME_ZONE);
    }

    const response: any = await addGroupItem(temp.ctMntChart);

    console.log("addGi", response);
    if (response.status >= 200 && response.status < 300) {
      const path = PagePath.chargeMnt.detail.replace(":id", response.data.data.ctMntChart.chartId);
      navigate(path);
      openToast({ content: "정상적으로 저장 되었습니다." });
    }
  }, [addGroupItem, ctMntGroup, navigate, openToast]);

  const editGI = useCallback(async () => {
    const temp = _.cloneDeep(ctMntGroup);
    if (temp.ctMntChart) {
      temp.ctMntChart.calcStartDate = moment(temp.ctMntChart.calcStartDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.calcEndDate = moment(temp.ctMntChart.calcEndDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.billIssueDate = moment(temp.ctMntChart?.billIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      temp.ctMntChart.billCloseDate = moment(temp.ctMntChart?.billCloseDate).format(YmdFormat.WITH_TIME_ZONE);
    }

    const response: any = await editGroupItem(temp.ctMntChart);
    console.log("editGi", response);
    if (response.status >= 200 && response.status < 300) {
      const path = PagePath.chargeMnt.detail.replace(":id", response.data.data.ctMntChart.chartId);
      navigate(path);
      openToast({ content: "정상적으로 저장 되었습니다." });
    }
  }, [ctMntGroup, editGroupItem, navigate, openToast]);

  const onSave = useCallback(async () => {
    setIsOpen3(false);
    if (ctMntGroup.buildingId && ctMntGroup.buildingName) {
      if (ctMntGroup.ctMntChart?.chartId) {
        editGI();
      } else {
        addGI();
      }
    } else {
      setIsOpen2(true);
    }
  }, [addGI, ctMntGroup.buildingId, ctMntGroup.buildingName, ctMntGroup.ctMntChart?.chartId, editGI]);

  useEffect(() => {
    if (id) {
      groupDetail(Number(id));
    }
  }, [id, groupDetail]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 건물 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">건물</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  {ctMntGroup.buildingId && (
                    <div className="base-chip flex-center mr8">
                      <>
                        <span className="ml6">
                          [{ctMntGroup?.buildingId}]{ctMntGroup?.buildingName}
                        </span>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="base-remove bg-gray ml2"
                        />
                      </>
                    </div>
                  )}
                  <BaseButton title="선택" className="color-white" onClick={() => setIsOpen(true)} />
                  <SearchBuildingPopup buildingName={""} isOpen={isOpen} onClick={onChangeBuilding} onClose={() => setIsOpen(false)} />
                </div>
              </div>
            </section>
            {/* 부과항목 그룹 명 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">부과항목 그룹</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <BaseSelect
                    value={String(ctMntGroup?.ctMntChart!.groupId)}
                    stateOptions={ctMntGroupList}
                    setStateValue={(value: string) => {
                      const temp = _.cloneDeep(ctMntGroup);
                      if (temp.ctMntChart !== undefined) {
                        temp.ctMntChart = Object.assign(temp.ctMntChart, { groupId: value }, {});
                      } else {
                        temp.ctMntChart = {
                          groupId: value,
                        };
                      }
                      console.log("temp", temp);

                      setCtMntGroup(temp);
                    }}
                    // errorText={formData?.isApplyAdjustError}
                  />
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">회차(년월)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <BaseDatePicker
                    type="month"
                    dateFormat={"yyyy.MM"}
                    setDate={(date: string) => {
                      console.log(moment(date).format("YYYYMM"));
                      const temp = _.cloneDeep(ctMntGroup);
                      if (temp.ctMntChart !== undefined) {
                        temp.ctMntChart.yearMonth = moment(date).format("YYYYMM");
                        temp.ctMntChart.calcStartDate = moment(date).startOf("month").format(ViewYmdFormat.YYYY_MM_DD);
                        temp.ctMntChart.calcEndDate = moment(date).endOf("month").format(ViewYmdFormat.YYYY_MM_DD);
                      } else {
                        temp.ctMntChart = {
                          yearMonth: moment(date).format("YYYYMM"),
                          calcStartDate: moment(date).startOf("month").format(ViewYmdFormat.YYYY_MM_DD),
                          calcEndDate: moment(date).endOf("month").format(ViewYmdFormat.YYYY_MM_DD),
                        };
                      }

                      setCtMntGroup(temp);
                    }}
                    selectedDate={ctMntGroup?.ctMntChart?.yearMonth ? moment(ctMntGroup.ctMntChart.yearMonth).toDate() : null}
                    placeholder="회차(년월)"
                  />
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">산출 기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="ml10">
                    <p>시작일</p>
                  </div>
                  <div className="minmax200">
                    <BaseDatePicker
                      type="date"
                      readonly={true}
                      setDate={(date: string) => {
                        const temp = _.cloneDeep(ctMntGroup);
                        if (temp.ctMntChart !== undefined) {
                          temp.ctMntChart.calcStartDate = moment(date).format(ViewYmdFormat.YYYY_MM_DD);
                        } else {
                          temp.ctMntChart = {
                            calcStartDate: moment(date).format(ViewYmdFormat.YYYY_MM_DD),
                          };
                        }

                        setCtMntGroup(temp);
                      }}
                      selectedDate={ctMntGroup?.ctMntChart?.calcStartDate ? moment(ctMntGroup.ctMntChart.calcStartDate).toDate() : null}
                      placeholder="시작일"
                    />
                  </div>
                  <div className="ml10">
                    <p>종료일</p>
                  </div>
                  <div className="ml10minmax200">
                    <BaseDatePicker
                      type="date"
                      readonly={true}
                      setDate={(date: string) => {
                        const temp = _.cloneDeep(ctMntGroup);
                        if (temp.ctMntChart !== undefined) {
                          temp.ctMntChart.calcEndDate = moment(date).format(ViewYmdFormat.YYYY_MM_DD);
                        } else {
                          temp.ctMntChart = {
                            calcEndDate: moment(date).format(ViewYmdFormat.YYYY_MM_DD),
                          };
                        }

                        setCtMntGroup(temp);
                      }}
                      selectedDate={ctMntGroup?.ctMntChart?.calcEndDate ? moment(ctMntGroup.ctMntChart.calcEndDate).toDate() : null}
                      placeholder="종료일"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">납부 기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <div className="ml10">
                    <p>청구일</p>
                  </div>
                  <div className="minmax200">
                    <BaseDatePicker
                      type="date"
                      setDate={(date: string) => {
                        const temp = _.cloneDeep(ctMntGroup);
                        if (temp.ctMntChart !== undefined) {
                          temp.ctMntChart.billIssueDate = moment(date).format(ViewYmdFormat.YYYY_MM_DD);
                          temp.ctMntChart.billCloseDate = "";
                        } else {
                          temp.ctMntChart = {
                            billIssueDate: moment(date).format(ViewYmdFormat.YYYY_MM_DD),
                            billCloseDate: "",
                          };
                        }

                        setCtMntGroup(temp);
                      }}
                      selectedDate={ctMntGroup?.ctMntChart?.billIssueDate ? moment(ctMntGroup.ctMntChart.billIssueDate).toDate() : null}
                      placeholder="청구일"
                      minDate={moment(ctMntGroup.ctMntChart!.calcEndDate).add(1, "days").toDate() || null}
                    />
                  </div>
                  <div className="ml10">
                    <p>마감일</p>
                  </div>
                  <div className="minmax200">
                    <BaseDatePicker
                      type="date"
                      setDate={(date: string) => {
                        const temp = _.cloneDeep(ctMntGroup);
                        if (temp.ctMntChart !== undefined) {
                          temp.ctMntChart.billCloseDate = moment(date).format(ViewYmdFormat.YYYY_MM_DD);
                        } else {
                          temp.ctMntChart = {
                            billCloseDate: moment(date).format(ViewYmdFormat.YYYY_MM_DD),
                          };
                        }

                        setCtMntGroup(temp);
                      }}
                      selectedDate={ctMntGroup?.ctMntChart?.billCloseDate ? moment(ctMntGroup.ctMntChart.billCloseDate).toDate() : null}
                      placeholder="마감일"
                      minDate={moment(ctMntGroup.ctMntChart!.billIssueDate).add(1, "days").toDate() || null}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">공지사항</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <BaseTextarea
                    className="admin-memo mr8"
                    name={"name"}
                    value={ctMntGroup?.ctMntChart?.noticeMemo}
                    placeholder="메모를 남길 수 있습니다."
                    maxLength={1000}
                    onChange={(value: string) => {
                      const temp = _.cloneDeep(ctMntGroup);
                      if (temp.ctMntChart !== undefined) {
                        temp.ctMntChart.noticeMemo = value;
                      } else {
                        temp.ctMntChart = {
                          noticeMemo: value,
                        };
                      }
                      setCtMntGroup(temp);
                    }}
                    // errorText={error?.message}
                  />
                </div>
              </div>
            </section>
          </article>
          <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => onSave()} onClose={() => setIsOpen3(false)}>
            <p>저장 하시겠습니까?</p>
          </BaseModal>
          <BaseModal isOpen={isOpen2} btnRightTitle="확인" onClick={() => setIsOpen2(false)}>
            <p>건물은 필수 선택 요소입니다.</p>
          </BaseModal>
          {/* <BaseModal
        isOpen={isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={(e?: any) => {}}
        onClose={() => setIsOpen(false)}
      >
        <p>"사용안함"으로 전환하시겠습니까?</p>
                    </BaseModal> */}
          <BaseModal isOpen={isOpen4} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => moveDetail()} onClose={() => setIsOpen4(false)}>
            <p>상세페이지로 이동하시겠습니까?</p>
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => navigate(PagePath.chargeMnt.list)} />
        </div>
        <div className="right-area">
          {id && <BaseButton title="수정 취소" className="color-white size-large" onClick={() => setIsOpen4(true)} />}
          <BaseButton title="저장" className=" size-large" onClick={() => setIsOpen3(true)} />
        </div>
      </div>
    </>
  );
};
export default BasicInfo;
