import { Cell } from "react-table";
import { DeviceModel } from "src/api/iot/iot-types";

const RegisteredDeviceColumns: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "적용",
    accessor: "",
    width: 80,
    Cell: (props: Cell<DeviceModel> & { addedList: number[] }) => {
      const row = props.row.original;
      if (props.addedList) {
        const isRegistered = props.addedList.some((id) => id === row.id);
        return <span className={isRegistered ? "status D" : ""}>{isRegistered ? "적용됨" : ""}</span>;
      }
    },
  },
  {
    Header: "IoT 구분",
    accessor: "platformDevice.platformAccount.platform",
    width: 120,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return <span>{value === "ST" ? "SmartThings" : "b.IoT"}</span>;
    },
  },
  {
    Header: "기기명",
    accessor: "internalDisplayName",
    width: 260,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return <span className="w-100 text-left">{value}</span>;
    },
  },
  {
    Header: "Device ID",
    accessor: "platformDevice.id",
    width: 350,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return <span className="w-100 text-left">{value}</span>;
    },
  },
];

export default RegisteredDeviceColumns;
