import React from "react";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  selected?: boolean;
  disabled?: boolean;
}

function BaseChip(props: Props) {
  return (
    <button
      {...props}
      disabled={props.disabled}
      className={`new-base-chip ${props.className && props.className} ${props.selected && "selected"}`}
      onMouseUp={(event) => {
        if (event.button === 1) {
          // 마우스의 휠 버튼 클릭 여부 확인
          event.currentTarget.click();
        }
      }}
    >
      {props.title}
    </button>
  );
}
export default BaseChip;
