import { useRecoilValue } from "recoil";
import { BaseButton } from "src/components";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";

import { useQuery } from "@tanstack/react-query";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { ContractFacility, ContractOperationDetail, DetailTotalData } from "src/api/contract/contract-types";
import { getAvailableReservationTimeAsync, getReservationPolicyClosedPeriodAsync, getUnitReservationTimeAsync } from "src/api/product/product-api";
import useGetCommonFacilityList from "src/hooks/contract/useGetCommonFacilityList";
import useGetReservationPolicyMode from "src/hooks/product/useGetReservationPolicyMode";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import ReservationPolicyView from "src/pages/product/forms/commonSpaceForm/components/ReservationPolicyView";
import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import { axiosInstance } from "src/utils";

import {
  delete_cols,
  desk_cols,
  duplicate_cols,
  edit_cols,
  meeting_cols,
  refresh_cols,
} from "src/pages/product/forms/commonSpaceForm/columns/SpaceColumns";
import SpaceAddModal from "../modal/SpaceAddModal";

export interface ContractFacilityTableData extends Omit<ContractFacility, "id">, Omit<CommonFacilityListModel, "id"> {
  id?: string | number;
}

type Props = {
  contractOperationDetail: ContractOperationDetail;
  spaceType: SpaceTypeT;
  contractManageId: string;
  detailData: DetailTotalData;
};

/**
 * 공용공간
 * 토글 활성화시
 */
const ContractSpaceContents = ({ contractOperationDetail, spaceType, contractManageId, detailData }: Props) => {
  const serviceType: UnionServiceType = "SERVICE_CONTRACT_MANAGE";
  const serviceId = contractManageId;
  const { setAbstractModalZ1 } = useModal();
  const { isAuthority } = usePartnerAuthority();
  const partner = useRecoilValue(globalPartnerState);

  /** 파트너의 공용공간 예약의 후불정산 기능 활성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;

  //isPostPaymentReservation 가 false 면 duplicate_cols컬럼에서 isAllowedOverReservation 제거
  const newDuplicate_cols = isPostPaymentReservation ? duplicate_cols : duplicate_cols.filter((data) => data.accessor !== "isAllowedOverReservation");
  // const newEdit_cols = isAuthority("W") ? edit_cols : [];
  const newDelete_cols = isAuthority("d") ? delete_cols : [];

  /** 공간별 이용한도, 테이블 컬럼 세팅,  */
  const tableColumns = {
    MEETING_ROOM: [
      ...meeting_cols,
      ...newDuplicate_cols, //
      // ...newEdit_cols,
      ...edit_cols,
      ...newDelete_cols,
    ],
    DESK: [
      ...desk_cols,
      ...newDuplicate_cols, //
      // ...newEdit_cols,
      ...edit_cols,
      ...newDelete_cols,
    ],
    REFRESH_ROOM: [
      ...refresh_cols,
      ...newDuplicate_cols, //
      // ...newEdit_cols,
      ...edit_cols,
      ...newDelete_cols,
    ],
  };

  //예약 가능 기간
  const { data: availableReservationTimeList } = useQuery({
    queryKey: ["getAvailableReservationTimeAsync", serviceType, serviceId, spaceType],
    queryFn: () =>
      getAvailableReservationTimeAsync(axiosInstance, {
        serviceId,
        serviceType,
        commonFacilityType: spaceType,
      }).then((res) => res?.data?.data?.content),
  });

  //예약 가능 단위
  const { data: unitReservationTimeList } = useQuery({
    queryKey: ["getUnitReservationTimeAsync", serviceType, serviceId, spaceType],
    queryFn: () =>
      getUnitReservationTimeAsync(axiosInstance, {
        serviceId,
        serviceType,
        commonFacilityType: spaceType,
      }).then((res) => res?.data?.data?.content),
  });

  // 휴무
  const { data: reservationPolicyClosedPeriodList } = useQuery({
    queryKey: ["getReservationPolicyClosedPeriodAsync", serviceType, serviceId, spaceType],
    queryFn: () =>
      getReservationPolicyClosedPeriodAsync(axiosInstance, {
        serviceId,
        serviceType,
        commonFacilityType: spaceType,
      }).then((res) => res?.data?.data?.content),
  });

  const { data: reservationPolicyModeList } = useGetReservationPolicyMode({
    reservationPolicyModeListParams: {
      serviceType,
      serviceId,
      commonFacilityType: spaceType,
      buildingCommonFacilityId: contractOperationDetail?.productFacility?.map((data) => data.id).join(","),
    },
  });

  /** 신청계약의 공용공간 id로 빌딩의 공용공간 리스트를 호출*/
  const { data: commonFacilityList } = useGetCommonFacilityList({
    commonFacilityListParams: {
      searchType: "ID",
      commonFacilityTypeList: spaceType,
      size: 9999,
      keywordList: contractOperationDetail?.productFacility?.map((data) => data.id).join(","),
    },
  });

  /** 신청계약에 추가된 테이블 데이터 생성 함수 */
  const makeSpaceTableData = (data: { contractOperationDetail: ContractOperationDetail; commonFacilityList: CommonFacilityListModel[] }) => {
    let resultTableData: ContractFacilityTableData[] = [];

    //건물 공용공간에 신청계약 공용공간을 데이터를 오버라이딩해서 리턴한다
    data.commonFacilityList.forEach((commonFacility) => {
      //신청계약 공용공간 id와 건물 공용공간 id가 같으면 리턴
      const finedContractFacility = data.contractOperationDetail.productFacility?.find(
        (contractFacility) => contractFacility.id === commonFacility.id,
      );

      if (finedContractFacility) {
        resultTableData.push({
          ...commonFacility,
          ...finedContractFacility,
        });
      }
    });

    return resultTableData;
  };

  const tableData = commonFacilityList && makeSpaceTableData({ contractOperationDetail, commonFacilityList });

  if (!(availableReservationTimeList && unitReservationTimeList && reservationPolicyClosedPeriodList && reservationPolicyModeList)) {
    return null;
  }

  return (
    <>
      <ReservationPolicyView
        isEditable={isAuthority("w")}
        spaceType={spaceType}
        serviceType={serviceType!}
        serviceId={serviceId!}
        detailData={detailData}
        contractOperationDetail={contractOperationDetail}
      ></ReservationPolicyView>
      {/* 공간 추가 버튼 */}
      <div className="flex-center-end mt20">
        {isAuthority("w") && (
          <BaseButton
            title={"+ " + SpaceTypeLabel[spaceType] + "추가"}
            className="ml10"
            disabled={
              detailData?.contract?.contractStep === "APPLY_CANCEL" ||
              detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
              detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
              detailData?.contract?.contractStep === "USE_COMPLETE"
            }
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                isOpen: true,
                children: (
                  <SpaceAddModal
                    addedSpaceIds={tableData?.map((data) => data?.id) as any as string[]} //이미 추가된 공용공간 표시용
                    spaceType={spaceType}
                    contractManageId={contractManageId}
                    contractId={String(detailData?.contract?.contractId)}
                  />
                ),
              });
            }}
          />
        )}
      </div>

      {/* 추가된 공간 테이블 */}
      <div className="my20">
        <SpaceBaseTable
          columns={tableColumns[spaceType]}
          availableReservationTimeList={availableReservationTimeList}
          unitReservationTimeList={unitReservationTimeList}
          reservationPolicyClosedPeriodList={reservationPolicyClosedPeriodList}
          reservationPolicyModeList={reservationPolicyModeList}
          serviceType={serviceType}
          serviceId={serviceId}
          //아래부터 신청계약 전용
          data={tableData}
          detailData={detailData}
        />
      </div>
    </>
  );
};

export default ContractSpaceContents;
