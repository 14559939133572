import { RefObject, useEffect, useState } from "react";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import useModalObserver from "src/recoil/modal-observer/hooks";
import { BaseAbstractModal } from "./BaseAbstractModal";
import { BaseButton } from "./BaseButton";
import { BasePagination } from "./BasePagination";

interface Props<T> {
  onCanceled: () => void;
  onAdded: (itemList: T[]) => void;
  goPage: (nextPage: number) => void;
  select: (selectedDataId: string) => void;
  unSelect: (selectedDataId: string) => void;
  titleFormRender: () => JSX.Element;
  page: { current: number; total: number; totalElements: number };
  items: T[];
  multiSelect?: boolean;
  columns: any[];
  title: string;
  className?: string;
  sizeOption?: (number: number) => void;
  currentSize?:number;
}

/*
  테이블에서 항목을 선택하는 모달입니다.
*/

function TableSelectModal<T>({
  onCanceled,
  onAdded,
  goPage,
  titleFormRender,
  page,
  items,
  select,
  sizeOption,
  className,
  unSelect,
  columns,
  title,
  currentSize,
}: Props<T>) {
  const { generateId, subscribe, unsubscribe } = useModalObserver();
  const [modalId] = useState(() => generateId());
  const [firstButtonRef, setFirstButtonRef] = useState<RefObject<HTMLButtonElement>>();

  useEffect(() => {
    subscribe(modalId, {
      Escape: onCanceled,
    });

    return () => {
      unsubscribe(modalId);
    };
  }, [firstButtonRef]);

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const dataId = checkbox.dataset.dataId;
    const disabled = Boolean(String(checkbox.dataset.disabled) === "true");
    const checked = Boolean(checkbox.dataset.checked === "true");
    if (disabled !== undefined && !disabled) {
      if (!checked) {
        select(dataId!);
      } else {
        unSelect(dataId!);
      }
    }
  };

  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    const checkbox = target.closest(".checkbox") as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };

  return (
    <BaseAbstractModal isOpen={true} className="dialog-modal">
      <section className="base-abstract-modal__title flex-center-between">
        <h1>{title} 선택</h1>
        <div>{titleFormRender()}</div>
      </section>
      <section className="base-abstract-modal__contents px30">
        <div className={className ? `${className} table-select-modal__contents` : "table-select-modal__contents"} onClick={handleModalContentsClick}>
          <ViewDataTable columns={columns} data={items} />
        </div>
      </section>
      <section className="px30">
        <div className="page">
          <BasePagination
            pageIndex={page.current}
            totalPages={page.total}
            goPage={goPage}
            sizeOption={sizeOption}
            currentSize={currentSize}
            totalElements={page.totalElements}
            children={
              <>
                <BaseButton title={"취소"} className="color-white" onClick={onCanceled} />
                <BaseButton title={"선택"} onClick={onAdded} setRef={setFirstButtonRef} />
              </>
            }
          />
        </div>
      </section>
    </BaseAbstractModal>
  );
}
export default TableSelectModal;
