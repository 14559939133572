import { Order, Sort } from "../public-types";

export interface OrgListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  containsCorRegNo?: string; // 법인 등록번호
  containsBizRegNo?: string; // 사업자 등록번호
  containsName?: string; // 법인명/상호
  search001?: string; // 전체검색
  keyword?: string;
  classification?: string;
  bizType?: string;
}

enum TaxType {
  GENERAL = "GENERAL",
  SIMPLIFIED_A = "SIMPLIFIED_A",
  SIMPLIFIED_B = "SIMPLIFIED_B",
  FREE = "FREE",
  ETC = "ETC",
}

enum BizType {
  SP = "SP",
  CORP = "CORP",
}

enum MediaType {
  PROOF = "PROOF",
  LOGO = "LOGO",
}

interface RequestInfo {
  id?: string;
  containsName?: string;
  containsBizRegNo?: string;
  containsCorRegNo?: string;
  containsPhoneNumber?: string;
  containsEmail?: string;
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  keyword?: string;
  search001?: string;
}

interface SendBody {
  organization: SaveOrgDto;
  mediaList: SaveMediaDto[];
}

interface SaveOrgDto {
  id?: number;
  bizType: BizType;
  name: string;
  bizRegNo: string;
  corRegNo: string;
  ceoName: string;
  bizCondition: string;
  bizItem: string;
  taxType: TaxType;
  address: string;
  email?: string | null;
  phoneNumber?: string | null;
  classifications?: string[];
  partnerId?: string;
}

interface SaveMediaDto {
  id?: number;
  fileStorageType: string;
  key: string;
  url?: string;
  filename: string;
  contentType: string;
  fileSize: number;
  type: MediaType;
  orderNums?: number;
}

interface MbOrg {
  id?: number;
  name: string;
  bizType: BizType;
  bizRegNo: string;
  corRegNo: string;
  ceoName: string;
  bizCondition: string;
  bizItem: string;
  taxType: TaxType;
  address: string;
  email: string;
  phoneNumber: string;
  regDate: string;
  createdDate: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  classifications: string[];
  partnerId: string;
  createdBy: string;
}

interface MbOrgMedia {
  id: number;
  fileStorageType: string;
  key: string;
  filename: string;
  contentType: string;
  fileSize: number;
  type: MediaType;
  name: string;
}

interface MbOrgDetail {
  mediaList?: MbOrgMedia[];
  organization: MbOrg;
}

// 관리자메모 쿼리파람
export interface OrgAdminMemoListParams {
  page?: number;
  size?: number;
  sort?: Sort;
}

export interface OrgAdminNoteType {
  id?: number;
  content?: string;
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
}

// 사업자 관리자메모 목록 타입
export interface OrgAdminMemoModel {
  adminNote: OrgAdminNoteType;
  orgId: number;
}

export interface OrgAdminMemoListResponse {
  content: Array<OrgAdminMemoModel>;
}

export type { RequestInfo, MbOrgMedia, MbOrgDetail, MbOrg, SaveOrgDto, SaveMediaDto, SendBody };
export { BizType, TaxType, MediaType };
