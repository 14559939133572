import React, { forwardRef, useEffect, useRef } from "react";

interface CheckboxProps {
  indeterminate?: boolean;
  id?: any;
  name?: string;
  disabled?: boolean;
}

const TableRadioButton = forwardRef<HTMLInputElement, CheckboxProps>(({ indeterminate, id, disabled, name, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>();
  const resolvedRef = ref || (defaultRef as any);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className={`d-flex`}>
      <input id={`building${id}`} type="radio" name={name} disabled={disabled} ref={resolvedRef} {...rest}></input>
      <label htmlFor={`building${id}`} className="d-flex">
        <div className="base-radio"></div>
      </label>
    </div>
  );
});

export default TableRadioButton;
