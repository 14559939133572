import moment from "moment";
import { useState } from "react";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { ContractOperationDetail, DetailTotalData } from "src/api/contract/contract-types";
import { ReservationPolicyClosedPeriod } from "src/api/product/product-api";
import { BaseButton } from "src/components";
import useGetAvailableReservationTime from "src/hooks/product/useGetAvailableReservationTime";
import useGetProduct from "src/hooks/product/useGetProduct";
import useGetReservationPolicyClosedPeriod from "src/hooks/product/useGetReservationPolicyClosedPeriod";
import useGetUnitReservationTime from "src/hooks/product/useGetUnitReservationTime";

import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { ViewYmdFormat } from "src/utils";
import { convertMinutesToDHMS, MaxReservTimeDayMap, MaxReservTimeMonthMap, renderTime } from "../libs";
import { SpaceTypeReqDataMap } from "../modal/AllSpaceUsageLimitModal";
import DefaultSettingModal from "../modal/DefaultSettingModal";
import UsageMonthlyTimeModal from "../modal/UsageMonthlyTimeModal";

export const ContractTimeMonthMap = {
  MEETING_ROOM: "meetingRoomTotalTime",
  DESK: "deskTotalTime",
  REFRESH_ROOM: "refreshRoomTotalTime",
} as const;

export const ContractTimeDayMap = {
  MEETING_ROOM: "meetingRoomDailyTime",
  DESK: "deskDailyTime",
  REFRESH_ROOM: "refreshRoomDailyTime",
} as const;

interface Props {
  spaceType: SpaceTypeT;
  serviceType: UnionServiceType;
  serviceId: string;
  isEditable: boolean;

  reservationPolicyClosedPeriodList?: ReservationPolicyClosedPeriod[];

  //아래부터 신청계약 전용
  detailData?: DetailTotalData;
  contractOperationDetail?: ContractOperationDetail;
}

const ReservationPolicyView = ({ spaceType, serviceType, serviceId, isEditable, detailData, contractOperationDetail }: Props) => {
  //기본설정만 보여주는 화면
  const { setAbstractModalZ1, setBaseModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);

  const { data: product } = useGetProduct({ productId: serviceType === "SERVICE_PRODUCT" ? serviceId : undefined });

  const { data: availableReservationTimeList } = useGetAvailableReservationTime({
    AvailableReservationTimeListParams: { isDefault: true, serviceId, serviceType, commonFacilityType: spaceType },
  });

  const { data: unitReservationTimeList } = useGetUnitReservationTime({
    unitReservationTimeListParams: { isDefault: true, serviceId, serviceType, commonFacilityType: spaceType },
  });

  const { data: closedPeriodList } = useGetReservationPolicyClosedPeriod({
    reservationPolicyClosedPeriodListParams: { isDefault: true, serviceId: serviceId, serviceType: serviceType, commonFacilityType: spaceType },
  });

  const TimeStart = convertMinutesToDHMS(availableReservationTimeList?.[0]?.availableReservationTimeStart);
  const TimeEnd = convertMinutesToDHMS(availableReservationTimeList?.[0]?.availableReservationTimeEnd);

  const minTime = convertMinutesToDHMS(unitReservationTimeList?.[0]?.minimumReservationTime);
  const maxTime = convertMinutesToDHMS(unitReservationTimeList?.[0]?.maximumReservationTime);

  return (
    <div>
      {/* 공간 추가 버튼 */}
      <div className="flex-center-between mt20">
        <div className="d-flex flex-column gap10 pt20">
          <p className="font-weight-semibold font15">{SpaceTypeLabel[spaceType]} 예약 기본 정책</p>
          <p className="font14 text-primary3">• 기본 정책은 모든 회의실에 적용됩니다. 아래 목록에서 각 회의실별로 개별 설정을 관리할 수 있습니다.</p>
        </div>
        <div className="flex-center">
          {serviceType !== "SERVICE_PRODUCT" && (
            //신청계약만
            <BaseButton
              title={"월 사용 시간 확인"}
              className="ml10 color-white"
              onClick={() => {
                setAbstractModalZ1({
                  size: "large",
                  isOpen: true,
                  children: <UsageMonthlyTimeModal spaceType={spaceType} contractId={detailData?.contract?.contractId!}></UsageMonthlyTimeModal>,
                });
              }}
            />
          )}
          <BaseButton
            disabled={
              detailData?.contract?.contractStep === "APPLY_CANCEL" ||
              detailData?.contract?.contractStep === "TERMINATE_COMPLETE" ||
              detailData?.contract?.contractStep === "APPLY_CONFIRM" ||
              detailData?.contract?.contractStep === "USE_COMPLETE"
            }
            title={"기본 정책 설정"}
            className="ml10 color-white"
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                isOpen: true,
                children: (
                  <DefaultSettingModal
                    spaceType={spaceType}
                    serviceType={serviceType}
                    serviceId={serviceId}
                    // isEditable={isEditable}
                    isDefault={true}
                    detailData={detailData}
                  ></DefaultSettingModal>
                ),
              });
            }}
          />
        </div>
      </div>

      <div className="gray-board-default">
        <div className="wrap">
          <div className="title">예약 가능 기간</div>
          <div className="text">
            {!availableReservationTimeList?.[0]?.isLimitedAvailableReservationTime ? (
              "무제한"
            ) : (
              <div>
                {TimeStart && <span>{TimeStart} 이후</span>}
                {TimeStart && TimeEnd && " - "}
                {TimeEnd && <span>{TimeEnd} 이내</span>}
              </div>
            )}
          </div>
        </div>
        <div className="wrap">
          <div className="title">예약 시간 단위</div>
          <div className="text">
            {!unitReservationTimeList?.[0]?.isLimitedUnitReservationTime ? (
              "무제한"
            ) : (
              <div>
                {minTime && <span>최소{minTime}</span>}
                {minTime && maxTime && " - "}
                {maxTime && <span>최대{maxTime}</span>}
              </div>
            )}
          </div>
        </div>
        <div className="wrap">
          <div className="title">휴무일</div>
          {isOpen === false &&
            (closedPeriodList && closedPeriodList?.length > 0
              ? closedPeriodList?.slice(0, 3)?.map((data) => {
                  const startDate = moment(data?.startDate).format(ViewYmdFormat.YYYY_MM_DD);
                  const endDate = moment(data?.endDate).format(ViewYmdFormat.YYYY_MM_DD);
                  const closedStartTime = data?.startTime;
                  const closedEndTime = data?.endTime;

                  return (
                    <div key={data.id} className="text">
                      <span>
                        {startDate ?? ""} - {endDate ?? ""} ({closedStartTime ?? ""} - {closedEndTime ?? ""})
                      </span>
                    </div>
                  );
                })
              : "-")}
          {isOpen === true &&
            closedPeriodList?.map((data) => {
              const startDate = moment(data?.startDate).format(ViewYmdFormat.YYYY_MM_DD);
              const endDate = moment(data?.endDate).format(ViewYmdFormat.YYYY_MM_DD);
              const closedStartTime = data?.startTime;
              const closedEndTime = data?.endTime;

              return (
                <div key={data.id} className="text">
                  <span>
                    {startDate ?? ""} - {endDate ?? ""} ({closedStartTime ?? ""} - {closedEndTime ?? ""})
                  </span>
                </div>
              );
            })}
          {closedPeriodList && closedPeriodList.length > 3 && (
            <div onClick={() => setIsOpen((prev) => !prev)} className={`curser-pointer  ${isOpen ? "ic_chevron_up_b" : "ic_chevron_down_b"}`}></div>
          )}
        </div>
        <div className="wrap">
          <div className="title">이용한도(월 이용한도 / 일 이용한도 / 초과예약)</div>
          {product && (
            <div className="text d-flex gap5">
              <span>{renderTime("USED", product[MaxReservTimeMonthMap[spaceType]], product[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit])}</span>
              <span>/</span>

              <span>{renderTime("USED", product[MaxReservTimeDayMap[spaceType]], product[SpaceTypeReqDataMap[spaceType].isDailyUnLimit])}</span>
              <span>/</span>

              <span>{product[SpaceTypeReqDataMap[spaceType]?.isAllowOverReservation] ? "허용" : "비허용"}</span>
            </div>
          )}
          {serviceType !== "SERVICE_PRODUCT" && contractOperationDetail && (
            <div className="text d-flex gap5">
              <span>
                {renderTime(
                  "USED",
                  contractOperationDetail?.productManage?.[ContractTimeMonthMap[spaceType]],
                  contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit]!,
                )}
              </span>
              <span>/</span>

              <span>
                {renderTime(
                  "USED",
                  contractOperationDetail?.productManage?.[ContractTimeDayMap[spaceType]],
                  contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType].isDailyUnLimit]!,
                )}
              </span>
              <span>/</span>

              <span>{contractOperationDetail?.productManage?.[SpaceTypeReqDataMap[spaceType]?.isAllowOverReservation] ? "허용" : "비허용"}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReservationPolicyView;
