import { ContractStep } from "src/api/contract/contract-types";

export const INVITEABLE_CONTRACT_STEP = [
  ContractStep.CONTRACT_ACCEPT, // 계약체결
  ContractStep.USE_APPROVAL, // 이용승인
  ContractStep.USE_PROGRESS, // 이용중
  ContractStep.TERMINATE_RECEIVED, // 해지접수
];

export const VISITOR_STATUS_MAP = {
  VISIT_USE: "사용",
  VISIT_PAUSE: "일시정지",
  VISIT_DELETE: "삭제",
  VISIT_CLOSE: "종료",
};

// 방문목적
export const VISIT_PURPOSE_MAP = {
  TOUR_COUNSELING: "투어/상담",
  FRIEND_VISIT: "지인방문",
  PACKAGE_DELIVERY: "택배",
  MAIL_DELIVERY: "우편",
  ETC_DELIVERY: "배달",
};

// 방문타입
export const VISIT_TYPE_MAP = {
  INVITE_REGIST: "초대",
  SELF_REGIST: "셀프등록",
};
