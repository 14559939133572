import { useEffect, useMemo, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { getUsers } from "src/api/user/user-api";
import { GetUsersParams, UserListModel } from "src/api/user/user-types";
import { BaseInput } from "src/components";
import IotCommonTableModal from "src/pages/building/forms/iotDevice/components/IotCommonTableModal";
import DeviceControlMembersColumn from "../columns/DeviceControlMembersColumn";
import { PageMeta } from "src/api/public-types";

type Props = {
  onClose: () => void;
  contractApplyNumber: string;
  addMember: (selected: UserListModel[]) => Promise<void>;
  executeMembers: string[];
};

const AddMemberListModal = ({ onClose, contractApplyNumber, addMember, executeMembers }: Props) => {
  const [members, setMembers] = useState<UserListModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useState<GetUsersParams>({
    page: 0,
    size: 20,
    contractApplyNumber,
    sort: { orders: [{ property: "inviteDate", direction: "DESC" }] },
  });

  // 이용자 목록
  const { executeAsync: getUsersAsync } = useApiOperation(getUsers);

  useEffect(() => {
    const fetchUsers = async () => {
      // const { data, status } = await getUsersAsync({ page: 0, size: 20, contractApplyNumber });
      const { data, status } = await getUsersAsync({ ...params });

      if (status >= 200 && status <= 299) {
        const result = data.data.filter((item) => item.memberStatus === "MEMBER_USE");
        setMembers(result);
        setPageMeta(data.meta.pageMeta);
      }
    };
    fetchUsers();
  }, [params]);

  return (
    <IotCommonTableModal
      columns={DeviceControlMembersColumn}
      data={members}
      addedList={executeMembers}
      onClick={(selected: UserListModel[]) => {
        addMember(selected);
      }}
      checkboxType="checkbox"
      title="이용자 선택"
      onClose={onClose}
      pageMeta={pageMeta}
      currentSize={(size) => setParams({ ...params, size })}
      goToPage={(page) => setParams({ ...params, page })}
    >
      <div className="right-area">
        <div className="d-flex">
          <BaseInput
            type="text"
            value={keyword}
            onChange={(keyword: string) => setKeyword(keyword)}
            onKeyUp={() => {
              setParams({ ...params, page: 0, searchValue: keyword });
            }}
            placeholder="검색어를 입력해주세요"
            onSearchClick={() => {
              setParams({ ...params, page: 0, searchValue: keyword });
            }}
          />
        </div>
      </div>{" "}
    </IotCommonTableModal>
  );
};

export default AddMemberListModal;
