import { useRef } from "react";

/**
 * useIntersectionObserver Hook 무한스크롤에 사용
 * 참고자료
 * https://heropy.blog/2019/10/27/intersection-observer/
 * @param callback 옵저버가 노출될 때 실행되는 함수
 * @param options IntersectionObserverInit
 * @param isUnobsrve 콜백 함수 실행 후 옵저버 중단 여부
 * @returns observe(div): 옵저버 등록, unobserve(div) 옵저버 중단
 */
export default function useIntersectionObserver(callback: () => void, options: IntersectionObserverInit = {}, isUnobsrve: boolean = true) {
  // IntersectionObserver 콜백 함수
  const intersectionCallback: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      // 가시성의 변화가 있으면 관찰 대상 전체에 대한 콜백이 실행되므로,
      // 관찰 대상의 교차 상태가 false일(보이지 않는) 경우 실행하지 않음.
      if (!entry.isIntersecting) {
        return;
      }
      // callback 실행
      if (entry.isIntersecting) {
        callback();
      }
      if (isUnobsrve) {
        // 기본값 true
        // 콜백을 실행을 처리하고(1회) 관찰 중지
        observer.unobserve(entry.target);
      }
    });
  };

  // IntersectionObserver 인스턴스 생성
  const observerRef = useRef<IntersectionObserver>(new IntersectionObserver(intersectionCallback, options));

  const observe = (element: HTMLElement | null) => {
    if (element && observerRef.current) {
      observerRef.current.observe(element);
    }
  };

  const unobserve = (element: HTMLElement | null) => {
    if (element && observerRef.current) {
      observerRef.current.unobserve(element);
    }
  };

  return { observe, unobserve };
}
