import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import {
  MemberListData,
  MemberModel,
  QueryParams,
  MemberAccountStateList,
  MemberRestrictionList,
  MemberRestrictionAdd,
  MemberRestrictionEdit,
  MemberRestriction,
  MbMemberNoteList,
  MbMemberNote,
  MbMemberNoteAdd,
  MbMemberNoteEdit,
} from "./member-types";
import ApiPath from "src/api/apiPath.json";
import qs from "qs";

/**
 * 회원 목록 조회
 * @param axios
 * @param params
 * @returns
 */
const getMembers = async (axios: AxiosInstance, params?: QueryParams): Promise<AxiosResponse<ApiResponse<MemberListData>>> => {
  return await axios.get<ApiResponse<MemberListData>>(ApiPath.members.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * 파트너 Taap 멤버 조회
 * @param axios
 * @param params
 * @returns
 */

const getTaapMembers = async (axios: AxiosInstance, params?: QueryParams): Promise<AxiosResponse<ApiResponse<any>>> => {
  return await axios.get<ApiResponse<any>>(ApiPath.members.taapList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * CMB02. 회원 상세 조회
 * @param axios
 * @param params(id)
 * @returns
 */
const getMemberDetail = async (
  axios: AxiosInstance,
  params?: {
    id: any;
  },
): Promise<AxiosResponse<ApiResponse<{ member: MemberModel; login: any }>>> => {
  if (!params?.id) {
    throw Error("id is not found.");
  }
  const path = ApiPath.members.detail.replace("{member_uid}", params.id.toString());

  return await axios.get<ApiResponse<{ member: MemberModel; login: any }>>(path, { params });
};
/**
 * 사용자 계정 변경 히스토리 조회
 * @param axios
 * @param params(memberNo)
 * @returns
 */
const getMemberAccountStates = async (
  axios: AxiosInstance,
  params?: {
    memberNo: string;
  },
): Promise<AxiosResponse<ApiResponse<MemberAccountStateList>>> => {
  // console.log('params',params);
  if (!params?.memberNo) {
    throw Error("memberNo is not found.");
  }
  const path = ApiPath.members.accountStates.replace("{key}", params.memberNo.toString());
  return await axios.get<ApiResponse<MemberAccountStateList>>(path, { params });
};

/**
 * 이용제한내역 조회
 * @param axios
 * @param params
 * @returns
 */
const getMemberRestrictionList = async (
  axios: AxiosInstance,
  params?: {
    memberId: number;
    size?: number;
  },
): Promise<AxiosResponse<ApiResponse<MemberRestrictionList>>> => {
  // console.log('params',params);
  if (!params?.memberId) {
    throw Error("memberId is not found.");
  }
  const path = ApiPath.members.restrictions.replace("{memberId}", params.memberId.toString());
  return await axios.get<ApiResponse<MemberRestrictionList>>(path, { params });
};

/**
 * 이용제한 상세 조회
 * @param axios
 * @param params
 * @returns
 */
const getMemberRestriction = async (
  axios: AxiosInstance,
  params?: {
    memberId: number;
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<MemberRestriction>>> => {
  // console.log('params',params);
  if (!params?.memberId) {
    throw Error("memberId is not found.");
  }
  const insertMemberId = ApiPath.members.restrictionDetail.replace("{memberId}", params.memberId.toString());
  const path = insertMemberId.replace("{id}", params.id.toString());
  return await axios.get<ApiResponse<MemberRestriction>>(path, { params });
};

/**
 * 이용제한 내역 등록
 * @param axios
 * @param params
 * @returns
 */
const memberRestrictionAdd = async (
  axios: AxiosInstance,
  params?: MemberRestrictionEdit,
): Promise<AxiosResponse<ApiResponse<MemberRestrictionEdit>>> => {
  // console.log(params);

  let path = ApiPath.members.restrictionAdd.replace("{memberId}", params!.memberId!.toString());
  return await axios.post<ApiResponse<MemberRestrictionEdit>>(path, params);
};

/**
 * 이용제한 내역 수정
 * @param axios
 * @param params
 * @returns
 */
const memberRestrictionEdit = async (
  axios: AxiosInstance,
  params?: MemberRestrictionEdit,
): Promise<AxiosResponse<ApiResponse<MemberRestrictionEdit>>> => {
  const updateMemberId = ApiPath.members.restrictionDetail.replace("{memberId}", params!.memberId!.toString());
  const path = updateMemberId.replace("{id}", params!.id!.toString());
  return await axios.put<ApiResponse<MemberRestrictionEdit>>(path, params);
};

/**
 * 회원의 관리자 메모를 조회
 * @param axios
 * @param params
 * @returns
 */
const getNoteList = async (
  axios: AxiosInstance,
  params?: {
    memberId?: number;
  },
): Promise<AxiosResponse<ApiResponse<MbMemberNoteList>>> => {
  const path = ApiPath.members.adminMemoList.replace("{memberId}", params!.memberId!.toString());
  return axios.get<ApiResponse<MbMemberNoteList>>(path, { params });
};

/**
 * 회원의 관리자 메모 생성
 * @param axios
 * @param body
 * @returns
 */
const memberNoteAdd = async (axios: AxiosInstance, body?: MbMemberNoteAdd): Promise<AxiosResponse<ApiResponse<MbMemberNote>>> => {
  const path = ApiPath.members.adminMemoAdd.replace("{memberId}", body!.memberId!.toString());
  return axios.post<ApiResponse<MbMemberNote>>(path, body);
};

/**
 * 회원의 관리자 메모 수정
 * @param axios
 * @param body
 * @returns
 */
const memberNoteEdit = async (axios: AxiosInstance, body?: MbMemberNoteEdit): Promise<AxiosResponse<ApiResponse<MbMemberNote>>> => {
  const updateMemberId = ApiPath.members.adminMemoEdit.replace("{memberId}", body!.memberId!.toString());
  const path = updateMemberId.replace("{id}", body!.id!.toString());
  return axios.put<ApiResponse<MbMemberNote>>(path, body);
};

/**
 * 회원의 관리자 메모 삭제
 * @param axios
 * @param body
 * @returns
 */
const memberNoteDelete = async (axios: AxiosInstance, body?: MbMemberNoteEdit): Promise<AxiosResponse<ApiResponse<MbMemberNote>>> => {
  const updateMemberId = ApiPath.members.adminMemoDelete.replace("{memberId}", body!.memberId!.toString());
  const path = updateMemberId.replace("{id}", body!.id!.toString());
  return axios.delete<ApiResponse<MbMemberNote>>(path);
};

/**
회원 이메일 수정
 */

const memberEmailUpdateAsync = async (
  axios: AxiosInstance,
  body?: {
    key: string;
    email: string;
  },
): Promise<AxiosResponse<ApiResponse<{ member: MemberModel }>>> => {
  const path = `/api/central/mb/members/${body?.key}/email`;
  return await axios.patch(path, { email: body?.email });
};

export {
  getTaapMembers,
  getMembers,
  getMemberDetail,
  getMemberAccountStates,
  getMemberRestrictionList,
  memberRestrictionAdd,
  getMemberRestriction,
  memberRestrictionEdit,
  getNoteList,
  memberNoteAdd,
  memberNoteEdit,
  memberNoteDelete,
  memberEmailUpdateAsync,
};
