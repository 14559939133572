import { ProductBuildingCommonFacilitySaveListReq } from "src/api/product/product-types";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { mutationProductBuildingCommonFacility } from "src/api/product/product-api";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { axiosInstance } from "src/utils";

export interface PostCommonFacilityReq {
  productBuildingCommonFacilitySaveList: ProductBuildingCommonFacilitySaveListReq;
  productId: string;
}
/** 공용공간 생성/수정/삭제 함수 */
const usePostCommonFacility = () => {
  const { openErrorModal } = useErrorModal();
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: async (data: PostCommonFacilityReq) => await mutationProductBuildingCommonFacility(axiosInstance, data),
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.data?.meta?.errorCode === "ePR0517") {
          const contractIds = JSON.parse(error.response?.data.meta.errorData).contractId.map(String).join(",");

          const errorMessage = `사용중인 공용공간은 삭제할 수 없습니다.\n신청계약 ID:${contractIds}`;
          openErrorModal({
            statusCode: error.response?.status,
            errorCode: error.response?.data?.meta?.errorCode,
            errorMessage: errorMessage,
          });
        } else {
          console.log("useMutation :>> ");
          openErrorModal({ statusCode: error.response?.status, errorCode: error.response?.data?.meta?.errorCode });
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getProductDetailAsync"],
      });
    },
  });

  return { ...query };
};

export default usePostCommonFacility;
