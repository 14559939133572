import React from "react";

interface state {
  id?: string;
  name?: string;
  onChange?: Function;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
}

export const BaseToggle = (props: state) => {
  return (
    <>
      <label className={`base-toggle ${props.className ? props.className : ""}`}>
        <input
          id={props.id}
          type="checkbox"
          checked={props.checked}
          name={props.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange && props.onChange(e.target.checked, e)
          }
          disabled={props.disabled ? true : false}
        />
        <div className="base-toggle__slider"></div>
      </label>
    </>
  );
};
