import React from "react";
import { useBlockLayout, useTable } from "react-table";
import { ValidationType } from "../modal/ClosedPeriodRegister";

interface Props {
  data?: Array<any>;
  columns: Array<any>;
  [key: string]: any;
  validations?: ValidationType[];
}

const ClosedPeriodTable = ({ data, columns, validations, ...props }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data ?? [],
      validations,
      ...props,
    },
    useBlockLayout,
  );

  return (
    <section className="inner-tab-table-closed-period">
      {/* 추가된 공간 테이블 */}
      <div className="">
        <div {...getTableProps()} className="base-table">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  // if (! && header.id === "isAllowedOverReservation") return null;
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell) => {
                    // if (! && cell.column.id === "isAllowedOverReservation") return null;
                    const isMultiLine = (row.original as any)?.times?.length > 1;
                    return (
                      <div {...cell.getCellProps()} className={`base-table__td ${isMultiLine && "multi-line"}`}>
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

          {rows.length === 0 && (
            <div className="base-table__tr table-cursor-unset">
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* {rows.length > 0 && (
          <div className="w-100 position-relative flex-center">
            {validations?.some((item) => item.feild === "date") && (
              <p className="validation-text">{validations?.find((item) => item.feild === "date")?.message}</p>
            )}
            {validations?.some((item) => item.feild === "time") && (
              <p className="validation-text position-absolute" style={{ right: 290, top: 5 }}>
                {validations?.find((item) => item.feild === "time")?.message}
              </p>
            )}
          </div>
        )} */}
      </div>
    </section>
  );
};

export default ClosedPeriodTable;
