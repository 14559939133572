import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { checkPayletterApiConfirm } from "src/api/provider/provider-api";
import { ProviderDetailModel, ProviderPayletterAddModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BaseToggle } from "src/components";
import { Modal } from "src/pages/product/product-types";
import { useToast } from "src/recoil/toast/hook";

type Props = {
  providerId: number;
  providerDetail?: ProviderDetailModel;
  providerDetailApi: Function;
  editProviderPayletter: Function;
};

const AutoPayletter = ({ providerId, providerDetail, providerDetailApi, editProviderPayletter }: Props) => {
  // 로딩바

  // 토스트
  const { openToast } = useToast();

  // 카드 자동결제 토글버튼
  const [isAutoPayletter, setIsAutoPayletter] = useState(false);

  // 페이레터 등록/수정 api data
  const [submitData, setSubmitData] = useState<ProviderPayletterAddModel>();

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 경고창 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 페이레터 api 검증

  const { executeAsync: checkPayletterApi } = useApiOperation(checkPayletterApiConfirm);

  const defaultValues = useMemo(() => {
    const providerPostData: ProviderPayletterAddModel = {
      providerPayletter: {
        providerId,
        clientId: "",
        paymentKey: "",
        searchKey: "",
        autoClientId: "",
        autoPaymentKey: "",
        autoSearchKey: "",
        isVerifiedAutoPaymentKey: false,
        isVerifiedAutoSearchKey: false,
        isVerifiedPaymentKey: false,
        isVerifiedSearchKey: false,
      },
    };

    return providerPostData;
  }, [providerId, providerDetail?.providerPayletter]);

  const { control, setValue, register, handleSubmit } = useForm<ProviderPayletterAddModel>({
    defaultValues,
  });

  //
  // 프로바이더 상세에서 페이레터 정보 setValue
  const onSetValuePayletter = useCallback(() => {
    const autoClientId = providerDetail?.providerPayletter?.autoClientId;
    const autoPaymentKey = providerDetail?.providerPayletter?.autoPaymentKey;
    const autoSearchKey = providerDetail?.providerPayletter?.autoSearchKey;

    if (autoClientId && autoPaymentKey && autoSearchKey) {
      setIsAutoPayletter(true);
    } else {
      setIsAutoPayletter(false);
    }
    setValue("providerPayletter", providerDetail?.providerPayletter);
  }, [providerDetail?.providerPayletter, setValue]);

  useEffect(() => {
    onSetValuePayletter();
  }, [onSetValuePayletter]);

  // 페이레터 검증
  const payletterApiCheck = useCallback(
    async (id: number) => {
      const autoClientId = providerDetail?.providerPayletter?.autoClientId;
      const autoPaymentKey = providerDetail?.providerPayletter?.autoPaymentKey;
      const autoSearchKey = providerDetail?.providerPayletter?.autoSearchKey;

      if (!autoClientId || !autoPaymentKey || !autoSearchKey) {
        setAlertModal({ isOpen: true, message: "페이레터 정보를 저장하세요." });
        return;
      }

      if (id) {
        const response = await checkPayletterApi({ providerId: id });

        if (response.status >= 200 || response.status <= 299) {
          const result = response.data.data;

          // 에러 메세지 있을 시 팝업 여부 확인 필요

          if (result.verifiedError.verifiedAutoPaymentKeyMessage) {
            setAlertModal({
              isOpen: true,
              message: result.verifiedError.verifiedAutoPaymentKeyMessage,
              payload: "error",
            });
          }

          if (result.verifiedError.verifiedAutoSearchKeyMessage) {
            setAlertModal({
              isOpen: true,
              message: result.verifiedError.verifiedAutoSearchKeyMessage,
              payload: "error",
            });
          }
          providerDetailApi(providerId);
        }
      }
    },
    [providerDetail?.providerPayletter],
  );

  //
  // 페이레터 유효성 검사
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다.";

    // 카드결제 지원 (payletter)
    if (isAutoPayletter) {
      register("providerPayletter.autoClientId", {
        required: { value: true, message: requiredMessage },
      });
      register("providerPayletter.autoPaymentKey", {
        required: { value: true, message: requiredMessage },
      });
      register("providerPayletter.autoSearchKey", {
        required: { value: true, message: requiredMessage },
      });
    }
  }, [isAutoPayletter, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  //
  // 페이레터 자동결제 수정 api
  const updateAutoPayletterApi = useCallback(
    async (data?: ProviderPayletterAddModel, reset?: boolean) => {
      let autoPayletterData = {};

      //reset - 자동결제 데이터가 있는데 토글 닫을 시 등록된 데이터 초기화
      if (reset === true) {
        autoPayletterData = {
          providerPayletter: {
            ...defaultValues.providerPayletter,
            clientId: providerDetail?.providerPayletter?.clientId,
            paymentKey: providerDetail?.providerPayletter?.paymentKey,
            searchKey: providerDetail?.providerPayletter?.searchKey,
            isVerifiedPaymentKey: providerDetail?.providerPayletter?.isVerifiedPaymentKey,
            isVerifiedSearchKey: providerDetail?.providerPayletter?.isVerifiedSearchKey,
          },
        };
      } else {
        // 일반 결제 페이레터 정보는 그대로 가져와서 set
        autoPayletterData = {
          providerPayletter: {
            ...data?.providerPayletter,
            clientId: providerDetail?.providerPayletter?.clientId,
            paymentKey: providerDetail?.providerPayletter?.paymentKey,
            searchKey: providerDetail?.providerPayletter?.searchKey,
          },
        };
      }

      const response = await editProviderPayletter(autoPayletterData);
      if (response.status >= 200 || response.status <= 299) {
        providerDetailApi(response.data.data.providerId);
        setConfirmModal({ isOpen: false });
        openToast({ content: `정상적으로 수정되었습니다.` });
      }
    },
    [defaultValues, editProviderPayletter, providerDetailApi, providerDetail?.providerPayletter],
  );

  //  유효성 검사후 저장
  const onSubmit = useCallback(async (data?: ProviderPayletterAddModel, e?: any) => {
    console.log("data", data);
    e.preventDefault();
    setSubmitData(data);
    setConfirmModal({ isOpen: true, message: "저장하시겠습니까?" });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  //
  // 카드 자동결제 토글 클릭시 작동
  const handleToggleFunction = useCallback(
    (checked: boolean) => {
      const autoClientId = providerDetail?.providerPayletter?.autoClientId;
      const clientId = providerDetail?.providerPayletter?.clientId;

      // 일반결제 데이터가 없는데 토글 on 일때

      if (!clientId) {
        setAlertModal({
          isOpen: true,
          message: "카드 일반결제 지원 정보를\n 먼저 등록하세요.",
        });
        return;
      }

      // 데이터가 있는데 토클 닫을 시,

      if (autoClientId && !checked) {
        setConfirmModal({
          isOpen: true,
          message: "등록된 카드 자동결제를 해제하시겠습니까?",
          payload: true,
        });
        return;
      }
      setIsAutoPayletter(checked);
    },
    [providerDetail?.providerPayletter],
  );

  return (
    <section>
      <div className="contents-container__sub-title flex-column">
        <div className="flex-center">
          <h2 className="mr30">카드 자동결제 지원</h2>
          <BaseToggle
            onChange={(checked: boolean) => {
              handleToggleFunction(checked);
            }}
            checked={isAutoPayletter}
          />
        </div>

        {/* 가이드메시지 수정에서만 노출. 상세에선 노출하지않음 */}
        <p className="contents-container__sub-title-info mt10">
          매월 자동결제(신용카드)를 지원하기 위해서는 추가로{" "}
          <a href="https://www.payletter.com/ko/service/domestic" target={"_blank"} className="text-underline" rel="noreferrer">
            페이레터
          </a>
          로부터 자동결제ID 및 APIKey발급이 필요합니다.
        </p>
      </div>
      {isAutoPayletter && (
        <>
          <div className="mb10">
            <div className="index-tab">
              <span>자동 결제 페이레터 정보</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className="font13 text-gray600 required">가맹점 아이디</span>
                  </div>
                  <Controller
                    control={control}
                    name={`providerPayletter.autoClientId`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} errorText={error?.message} />;
                    }}
                  ></Controller>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className="font13 text-gray600 required">API Key (PAYMENT)</span>
                  </div>
                  <Controller
                    control={control}
                    name={`providerPayletter.autoPaymentKey`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} type="password" errorText={error?.message} />;
                    }}
                  ></Controller>
                </section>
                <section className="flex-center">
                  <div className="minmax123">
                    <span className="font13 text-gray600 required">API Key (SEARCH)</span>
                  </div>
                  <Controller
                    control={control}
                    name={`providerPayletter.autoSearchKey`}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput onChange={onChange} value={value} name={name} type="password" errorText={error?.message} />;
                    }}
                  ></Controller>
                </section>
                <div className="flex-center-end mt10">
                  <BaseButton type="submit" title="저장" />
                </div>
              </form>
            </div>
          </div>

          <div className="mb10">
            <div className="index-tab">
              <span>자동 결제 페이레터 정보 검증</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="flex-center mb10">
                <div className="minmax123">
                  <BaseButton title="검증" onClick={() => payletterApiCheck(providerId)} />
                </div>
              </section>
              <section className="flex-center">
                <div className="minmax200 d-flex">
                  <span className="font13 text-gray600">1. Payment API Key: </span>
                  <span className=" font13 ml10"> {providerDetail?.providerPayletter?.isVerifiedAutoPaymentKey ? "성공" : "실패"}</span>
                </div>

                <div className="minmax200">
                  <span className="font13 text-gray600">2. Search API Key 조회 API: </span>
                  <span className=" font13 ml10"> {providerDetail?.providerPayletter?.isVerifiedAutoSearchKey ? "성공" : "실패"}</span>
                </div>
              </section>
            </div>
          </div>
        </>
      )}

      <BaseModal
        isOpen={confirmModal.isOpen}
        btnRightTitle="확인"
        btnLeftTitle="취소"
        className="pa20"
        title={confirmModal.message}
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => {
          (submitData || confirmModal.payload) && updateAutoPayletterApi(submitData, confirmModal.payload);
        }}
      ></BaseModal>
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        title={!alertModal.payload ? alertModal.message : ""}
        onClick={() => {
          setAlertModal({ isOpen: false, payload: "" });
        }}
      >
        {alertModal.payload === "error" && (
          <div>
            <p className="font18 font-weight-semibold text-gray900">결제 검증에 실패하였습니다.</p>
            <p className="my20">검증 중 문제가 발생하였습니다.</p>
            <p className="pa8 text-left" style={{ backgroundColor: "#F2F4F6", maxHeight: 250, overflow: "hidden", textOverflow: "ellipsis" }}>
              {alertModal.message}
            </p>
          </div>
        )}
      </BaseModal>
    </section>
  );
};

export default AutoPayletter;
