import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import {
  DeleteVisitorGroupParams,
  DeleteVisitorGroupResponse,
  ExternalQrResponse,
  GetContractVisitorsResponse,
  PatchContractVisitorsParams,
  PatchVisitorResponseData,
  PostVisitorResponseData,
  PostVisitorsParams,
  PreDiscountResponse,
  VisitorListModel,
  VisitorListQueryParams,
  VisitorModel,
} from "./visitor-types";

/**
 * RCA50b. 방문자 목록 페이지 조회
 * @param axios
 * @param params
 * @returns
 */
export async function getVisitorsAsync(
  axios: AxiosInstance,
  params?: VisitorListQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content?: Array<VisitorListModel> }>>> {
  return await axios.get<ApiResponse<{ content?: Array<VisitorListModel> }>>(ApiPath.visitor.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * RCV50. 방문자 목록 페이지 조회
 * @param axios
 * @param params
 * @returns
 */
export async function findDeleteVisitorsAsync(
  axios: AxiosInstance,
  params?: VisitorListQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content?: Array<VisitorListModel> }>>> {
  const path = ApiPath.visitor.list_RCV50.replace("{contractManageId}", params!.contractManageId!);
  delete params?.contractManageId;
  return await axios.get<ApiResponse<{ content?: Array<VisitorListModel> }>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// CCA51b. 방문자 그룹 상세 조회
export async function getContractVisitors(
  axios: AxiosInstance,
  params?: {
    visitApplyNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<GetContractVisitorsResponse>>> {
  const visitApplyNumber = params?.visitApplyNumber;
  const path = ApiPath.visitor.detail.replace("{visitApplyNumber}", visitApplyNumber!);
  return axios.get(path);
}

// TCT58. (무인증)방문 초대내역 정보조회 // 코트
export async function getExternalVisitorAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey: string; // visitApplyNumber + contractVisitorId
  },
): Promise<AxiosResponse<ApiResponse<GetContractVisitorsResponse>>> {
  const path = `${ApiPath.visitor.external}?visitorKey=${params?.visitorKey}`;
  return axios.get(path);
}

/**
 * RCA52. 방문자 등록
 * @param axios
 * @param params
 * @returns
 */
export async function postVisitors(axios: AxiosInstance, params?: PostVisitorsParams): Promise<AxiosResponse<ApiResponse<PostVisitorResponseData>>> {
  const contractManageId = params?.contractManageId;
  const partnerId = params?.partnerId;
  const contractVisitorList = params?.contractVisitorList;

  const path = ApiPath.visitor.add.replace("{contractManageId}", String(contractManageId));
  return await axios.post(path, {
    contractVisitorList,
    partnerId,
  });
}

/**
 * RCA53b. 방문자 수정
 * @param axios
 * @param params
 * @returns
 */
export async function patchVisitors(
  axios: AxiosInstance,
  params?: PatchContractVisitorsParams,
): Promise<AxiosResponse<ApiResponse<PatchVisitorResponseData>>> {
  const visitApplyNumber = params?.visitApplyNumber;
  const path = ApiPath.visitor.edit.replace("{visitApplyNumber}", visitApplyNumber!);

  return await axios.patch(path, {
    contractVisitorList: params?.contractVisitorList || [],
    partnerId: params?.partnerId,
  });
}

export async function deleteVisitorGroup(
  axios: AxiosInstance,
  params?: DeleteVisitorGroupParams,
): Promise<AxiosResponse<ApiResponse<DeleteVisitorGroupResponse>>> {
  const visitApplyNumber = params?.visitApplyNumber;
  const path = ApiPath.visitor.delete.replace("{visitApplyNumber}", visitApplyNumber!);

  return await axios.patch(path);
}

/**
 * RAD52. 방문자에 대한 출입로그 조회
 * @param axios
 * @param params
 * @returns
 */
export async function getAccessLog(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const visitorId = params?.visitorId;
  const path = ApiPath.visitor.ac.getAccesslog.replace("{contractVisitorId}", visitorId!);

  return await axios.get(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// TAC11. 방문자 QR 조회 // 코트
export async function getExternalVisitorQrAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey: string; // visitApplyNumber + contractVisitorId
  },
): Promise<AxiosResponse<ApiResponse<{ qr: ExternalQrResponse }>>> {
  if (!params?.visitorKey) {
    throw Error("visitorKey is not found");
  }
  const path = ApiPath.visitor.externalQr.replace("{visitorKey}", params.visitorKey);
  return axios.get(path);
}

//RCK20. 주차장 사전할인 리스트 조회 (KPS)
export async function getVisitorParkingPreDiscountListAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
    preDiscountIds?: string; // "202201010131212, 202201010131132, 202201010131082"
  },
): Promise<AxiosResponse<ApiResponse<PreDiscountResponse>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found");
  }
  const path = ApiPath.visitor.parking.list.replace("{contractId}", params.contractId);
  return axios.get(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}
/**
 * RCV53. 방문자 삭제 (초대자)
 * @param axios
 * @param params
 * @returns
 */
export async function deleteVisitorByInviter(
  axios: AxiosInstance,
  params?: {
    inviteeMemberNo: string;
    contractVisitorIds: string; //문서에는 []형태지만 전송 데이터는 string 에 , 구분으로 전송
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.visitor.deleteByInviter.replace("{inviteeMemberNo}", params!.inviteeMemberNo!);

  return await axios.patch(path, params!);
}
