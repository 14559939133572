import moment from "moment";
import { useMemo, useState } from "react";
import { MediaFile, Modal } from "src/api/public-types";
import { QuestionAnswerModel, QuestionDetailModel, QuestionNotifyMessageType } from "src/api/question/question-type";
import { BaseButton, BaseCheckbox, BaseModal } from "src/components";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

type Props = {
  questionDetail: QuestionDetailModel;

  answer: QuestionAnswerModel;
  answerMedias: MediaFile[];
  token: string;
  sendNotification: (messageTypes: QuestionNotifyMessageType[], answerId?: string) => any;
};

const ReplyDetailSection = ({ questionDetail, answer, sendNotification, answerMedias, token }: Props) => {
  const { isAuthority } = usePartnerAuthority();
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [messageTypes, setMessageTypes] = useState<QuestionNotifyMessageType[]>(["MESSAGE_EMAIL"]);

  const mediaFiles = useMemo(() => answerMedias.filter((media) => media.serviceIdList?.includes(String(answer.id))), [answer.id, answerMedias]);

  const isEmail = questionDetail.reporterEmail;
  const isFromTaap = questionDetail.site === "SITE_TAAP";

  //  등록버튼 -> 저장 확인 팝업 - 저장 선택시 실행
  const onClickConfirmModal = async (data: QuestionNotifyMessageType[]) => {
    // 답변등록 api
    await sendNotification(data, answer.id);
    setConfirmModal({ isOpen: false });
  };

  return (
    <div className="contents-container__1070 ">
      <div className="contents-question__answer ">
        <div className="pa20">
          <section className="contents-container__grid ml10 pb10">
            <div className="contents-container__grid-index">
              <p>답변내용</p>
            </div>
            <div className="contents-container__grid-contents">
              <p className="pre-formatted pt7">{answer.description ?? "-"}</p>
            </div>
          </section>

          <section className="contents-container__grid ml10 pb10">
            <div className="contents-container__grid-index">
              <p>첨부파일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="user-info-container">
                {mediaFiles.length > 0 ? <BaseFileUpload isOnlyView fileList={mediaFiles} /> : <span>-</span>}
              </div>
            </div>
          </section>

          <div className="flex-start">
            <section className="contents-container__grid ml10 pb10">
              <div className="contents-container__grid-index">
                <p>작성일</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="pre-formatted pt7">{moment(answer.createdDate).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) ?? "-"}</p>
              </div>
            </section>
            <section className="contents-container__grid ml10 pb10">
              <div className="contents-container__grid-index">
                <p>작성자</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="pre-formatted pt7">{answer.createdBy ?? "-"}</p>
              </div>
            </section>
          </div>
          {isEmail && !isFromTaap && (
            // 이메일이 있고 탭 유입이 아닌 경우
            <div className="flex-start">
              <section className="contents-container__grid ml10 pb10">
                <div className="contents-container__grid-index">
                  <p>이메일 발송</p>
                </div>
                <div className="contents-container__grid-contents flex-start">
                  <p className="mr18">{(answer.sendEmailDate && moment(answer.sendEmailDate).format(YmdFormat.YYYY_MM_DD_HH_MM)) ?? "-"}</p>
                  {isAuthority("w") && (
                    <BaseButton
                      title="재발송"
                      className="color-white"
                      onClick={() => {
                        if (messageTypes.length > 0) {
                          setConfirmModal({
                            isOpen: true,
                            payload: messageTypes,
                            title: "이메일을 재발송 하시겠습니까?",
                            // message: "이메일 발송 선택후 등록시 \n문의자에게 답변이 발송됩니다.",
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </section>
              <section className="contents-container__grid ml10 pb10">
                <div className="contents-container__grid-index">
                  <p>알림톡 발송</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{(answer.sendKakaotalkDate && moment(answer.sendKakaotalkDate).format(YmdFormat.YYYY_MM_DD_HH_MM)) || "-"}</p>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          title={confirmModal.title}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            onClickConfirmModal(confirmModal.payload! as QuestionNotifyMessageType[]);
          }}
          onClose={() => setConfirmModal({ isOpen: false })}
        ></BaseModal>
      )}

      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
          title={alertModal.title}
        ></BaseModal>
      )}
    </div>
  );
};

export default ReplyDetailSection;
