import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { BuildingFloorModel, BuildingRoomModel } from "src/api/building/building-types";
import { getPropertiesAsync } from "src/api/building/property-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { FloorHoColumn } from "../modalPopup/columns/FloorHoColumn";
import { BuildingFloorAndHo } from "../product-types";
import GlobalFilter from "./GlobalFilter";
import TableCheckbox from "./TableCheckbox";

// 공간상품 등록 > 건물/호실 팝업 > 호실 타입
type ViewsPopupType = {
  buildingId: number;
  floorId: number | undefined;
  floorNum: number | undefined;
  isPrimary: boolean | undefined;
  leasableArea: string | undefined;
  leasableAreaNet: string | undefined;
  roomId: number | undefined;
  roomNum: number | undefined;
};

type Props = {
  selectedBuildingId?: number;
  // setSelectedRooms?: Function;
  floorHoTable?: Array<BuildingFloorAndHo>;
  selectOptions: (Propertys: ViewsPopupType[]) => void;
  onClose: () => void;
};

const BuildingFloorHoModal = ({
  selectedBuildingId, //
  // setSelectedRooms,
  floorHoTable,
  selectOptions,
  onClose,
}: Props) => {
  const [viewProperty, setViewsProperty] = useState<Array<ViewsPopupType>>([]); // ui용 층/호실 data
  const [checkedRow, setCheckedRow] = useState({});
  const [selectOption, setSelectOption] = useState<Array<{ value: string; label: string }>>([]);

  const columns: any = useMemo(() => FloorHoColumn, []);

  const data = useMemo(() => {
    const orderbyHo = _.orderBy(viewProperty, ["floorNum", "roomNum"], ["desc", "asc"]);

    return orderbyHo;
  }, [viewProperty]); //테이블 data

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows,
    setGlobalFilter,
    setFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns: columns,
      data: data,

      initialState: {
        selectedRowIds: checkedRow,
      },
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          Header: "선택",
          Cell: ({ row }: any) => {
            let currentState = row.getToggleRowSelectedProps();

            const checkedRoom = floorHoTable?.some((item) => item.roomId === row.original.roomId);

            return (
              <div>
                <TableCheckbox id={row.id} name="buiding" {...currentState} disabled={row.original.managementUsingCount > 0 || checkedRoom} />
              </div>
            );
          },
          width: 50,
        },
        ...columns,
      ]);
    },
  );

  // 건물의 층/호실 property 목록 api
  const { executeAsync: getBuildingProperties } = useApiOperation(getPropertiesAsync);

  // 건물의 층/호실 property 목록 api
  const getPropertiesListApi = useCallback(async () => {
    if (selectedBuildingId) {
      const response = await getBuildingProperties({ buildingId: selectedBuildingId });

      if (response.status >= 200 && response.status <= 299) {
        const buildingProperty = response.data?.data.content;

        const convertProperties: ViewsPopupType[] = []; // 팝업 view arr

        //층/호실 팝업 ui 에 보여질 테이블로 가공
        // 층;

        buildingProperty.forEach((floor: BuildingFloorModel) => {
          floor.buildingRoomList?.forEach((room: BuildingRoomModel) => {
            const floorProperty = {
              buildingId: Number(selectedBuildingId),
              floorId: floor.id,
              floorNum: floor.floorNum,
              roomId: room.id,
              roomNum: room.roomNum,
              isPrimary: room.isPrimary,
              leasableArea: room.leasableArea, // 계약면적
              leasableAreaNet: room.leasableAreaNet, // 전용면적
              ownerId: room.ownerId,
              ownerName: room.ownerName,
              managementUsingCount: room.managementUsingCount,
            };

            convertProperties.push(floorProperty);
          });
        });

        // 이미 선택된 리스트 체크박스 유지
        let checkedRowObj = {};

        // 건물 api 에서 내려온 건물의 층/호실
        const sortProperties = _.orderBy(convertProperties, ["floorNum", "roomNum"], ["desc", "asc"]);

        sortProperties.forEach((property, idx: number) => {
          floorHoTable?.forEach((room) => {
            if (Number(property.buildingId) === Number(room.buildingId)) {
              if (Number(property.roomId) === Number(room.roomId)) {
                checkedRowObj = Object.assign(checkedRowObj, { [idx]: true });
              }
            }
          });
        });

        // select 층수 option 만들기
        const options = convertProperties.map((item) => item.floorNum);
        const filtered = [...new Set(options)];

        const getOptions = filtered.map((floor) => {
          if (floor && floor < 0) {
            const data = {
              value: `${floor}`,
              label: `지하${floor}`.replace("-", ""),
            };
            return data;
          } else {
            const data = {
              value: `${floor}`,
              label: `지상${floor}`,
            };
            return data;
          }
        });

        getOptions.unshift({ value: "", label: "전체" });

        const optionsOrderBy = _.orderBy(getOptions, ["label"], ["desc"]).reverse();

        setSelectOption(optionsOrderBy);
        setCheckedRow(checkedRowObj);

        setViewsProperty(convertProperties);
      } else {
        throw Error("건물 층/호실을 불러올 수 없습니다");
      }
    }
  }, [selectedBuildingId, getBuildingProperties]);

  useEffect(() => {
    getPropertiesListApi();
  }, [getPropertiesListApi]);

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <BaseAbstractModal isOpen={true} className="dialog-modal" isTwoDepth={true}>
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>호실선택</h1>
        <div className="right-area d-flex">
          <div className="w-100 base-select minmax120 mr8">
            <Select
              placeholder={"층수"}
              isSearchable={false}
              className={`react-select-container  w-100 `}
              classNamePrefix="react-select"
              name={"floorNum"}
              options={selectOption}
              onChange={(e: any) => {
                setFilter("floorNum", e.value);
              }}
            />
          </div>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeHolder="호실을 입력해주세요." />
        </div>
      </section>

      {/*table */}

      <section className="base-abstract-modal__contents px30">
        <section className="contents-container__table ">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {/* <BaseScroll maxHeight={300}> */}
            <div {...getTableBodyProps()} className="body">
              {viewProperty.length > 0 ? (
                rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">
                      <span>등록된 호실 정보가 없습니다.</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* </BaseScroll> */}
          </div>
        </section>
      </section>
      <div className="flex-center-end px30 py20">
        <BaseButton
          title="취소"
          onClick={() => {
            onClose();
          }}
          className="color-white mr5"
        />
        <BaseButton
          title="선택"
          onClick={() => {
            if (selectedFlatRows.length > 0) {
              const selectRows = selectedFlatRows.map((item) => item.original);
              selectOptions(selectRows);
            }
          }}
        />
      </div>
    </BaseAbstractModal>
  );
};

export default BuildingFloorHoModal;
