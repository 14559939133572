import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import useApiOperation from "src/api/hooks/api-operation";
import { uploadOrgPrivateFilesAsync } from "src/api/organization/org-api";
import { BaseButton } from "src/components/BaseButton";
import onErrorImage from "src/assets/images/onerror_preview.svg";
import axios from "axios";
import _ from "lodash";
import { BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";

interface Prop {
  privateFiles: any;
  isOnlyView?: boolean;
  setPrivateFiles?: any;
  errorText?: string;
  limit?: number;
  maxSize?: number;
  disabled?: boolean;
  serviceTag?: string;
}
const s3Url = process.env.REACT_APP_S3_BASEURL;
interface Message {
  title: string;
  message: string;
}

const ContractUpload = ({ setPrivateFiles, privateFiles, errorText, isOnlyView, limit, maxSize, disabled, serviceTag }: Prop) => {
  const [isOpen, setIsOpen] = useState(false);
  const mb = 1024 * 1024;
  const fileRef = useRef<HTMLInputElement>(null);
  const [token, setToken] = useState("");
  const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
  const [focusFullScreenImageIndex, setFocusFullScreenImageIndex] = useState(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [message, setMessage] = useState<Message>({ title: "", message: "" });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const { executeAsync: postImageFiles } = useApiOperation(uploadOrgPrivateFilesAsync);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const sizeNlimitText = `한 파일당 업로드 용량은 ${maxSize}MB, 최대 ${limit}개까지만 가능합니다.`;
  const sizeText = `파일은 최대 ${limit}개까지만 업로드 가능합니다.`;
  const limitText = `한 파일단 업로드 용량은 ${maxSize}MB 까지 가능합니다.`;

  const errorPopup = (title: string, message: string) => {
    setMessage({ title, message });
    setIsOpen(true);
  };
  const sizeCheck = (files: any, sizeLimit: number) => {
    let checker = true;
    for (let i = 0; i < files.length; i++) {
      // console.log(e.target.files[i].size, sizeLimit);
      if (files[i].size > sizeLimit) {
        checker = false;
        break;
      }
    }
    return checker;
  };

  const limitCheck = (files: any, privateFiles: any, limit: number) => {
    let checker = true;
    const nd: any = privateFiles.filter((obj: any) => obj.isDeleted === false);
    let limitChecker = files.length + nd.length;

    // console.log("limitChecker", limit < limitChecker);
    if (limit < limitChecker) {
      checker = false;
    }

    return checker;
  };

  const isLimiedFileValidatiaon = (files: any, privateFiles: any, maxSize?: number, limit?: number) => {
    // console.log("maxSize, limit", maxSize, limit);
    let checker = true;
    let sizeLimit = mb;

    //파일의 사이즈 체크
    if (maxSize !== undefined && limit === undefined) {
      // console.log("사이즈");
      sizeLimit = maxSize * mb;
      if (sizeCheck(files, sizeLimit)) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeText);
        checker = false;
        return;
      }
    }
    if (limit !== undefined && maxSize === undefined) {
      // console.log("limit");
      if (limitCheck(files, privateFiles, limit)) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeText);
        checker = false;
        return;
      }
    }

    if (limit !== undefined && maxSize !== undefined) {
      // console.log("둘다 있다.");
      sizeLimit = maxSize * mb;
      if (sizeCheck(files, sizeLimit) === false || limitCheck(files, privateFiles, limit) === false) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeNlimitText);
        checker = false;
        return;
      }
    }
    return checker;
  };

  const onChange = async (e: any, privateFiles: any, maxSize?: number, limit?: number) => {
    const uploaded: any = [];

    if (isLimiedFileValidatiaon(e.target.files, privateFiles, maxSize, limit) && e.target.files && e.target.files.length) {
      const arrFiles = Array.from(e.target.files);

      const files: any = arrFiles.map((file, index) => {
        const src = window.URL.createObjectURL(file as any);
        return { file, id: index, src };
      });

      for (const i in files) {
        const formData = new FormData();
        formData.append("file", files[i].file);
        const res = await postImageFiles(formData);
        if (res && res.status >= 200 && res.status <= 299) {
          const mediaFile = JSON.parse(JSON.stringify({ ...res.data.data.media, ...{ type: "PROOF", serviceTag, isDeleted: false } }));
          const file = e.target.files[i];
          const readAsDataURL = (file: Blob) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          };

          try {
            const blobUrl = await readAsDataURL(file);
            uploaded.push({ ...mediaFile, blobUrl });
          } catch (error) {
            console.error("Error reading file:", error);
          }
        }
      }
    }

    setPrivateFiles([...privateFiles, ...uploaded]);
  };

  const getToken = useCallback(async () => {
    await getAccessTokenSilently();
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  const handleOpenFullImage = (idx: number) => {
    setFocusFullScreenImageIndex(idx);
    setIsFullScreenImageOpen((prev) => !prev);
  };

  const handleFullScreenImage = (position: "left" | "right") => {
    if (position === "left") {
      if (privateFiles.length > 1) {
        setFocusFullScreenImageIndex(1);

        // setFocusFullScreenImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
      }
      setPageNumber(1);
    }
    if (position === "right") {
      if (privateFiles.length > 1) {
        setFocusFullScreenImageIndex((prev) => (prev < privateFiles.filter((item: any) => item.isDeleted !== true).length - 1 ? prev + 1 : prev));
      } else {
        setFocusFullScreenImageIndex((prev) => (prev < privateFiles.length - 1 ? prev + 1 : prev));
      }
      setPageNumber(1);
    }
  };

  const handleDeleteFile = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      e.stopPropagation();
      let newArr: any = _.cloneDeep(privateFiles);
      const finded: any = newArr.find((obj: any, i: number) => {
        if (i === index) {
          return obj;
        }
      });
      console.log(`finded`, finded);
      if (finded && finded.id) {
        for (let i = 0; i < newArr.length; i++) {
          if (i === index) {
            const obj: any = newArr[i];
            obj.isDeleted = true;
          }
        }
      } else {
        newArr = newArr.filter((obj: any, i: number) => i !== index);
      }

      setPrivateFiles(newArr);
    },
    [privateFiles],
  );

  const findFileName = (file: any) => {
    if (file.filename) {
      const lastIndex = file.filename.lastIndexOf(".");
      const result = file.filename.slice(0, lastIndex);
      return result + ".";
    }
  };

  const findFileExtension = (file: any) => {
    if (file.filename) {
      const lastIndex = file.filename.lastIndexOf(".");
      const result = file.filename.slice(lastIndex + 1);
      return result;
    }
  };

  const findImageURL = (file: any) => {
    if (file.blobUrl) {
      return file.blobUrl;
    } else if (file.type === "LOGO") {
      return file.url;
    } else if (file.type === "PROOF") {
      return file.url + "?access_token=" + token;
    } else if (!file.type && file.url) {
      return file.url + "?access_token=" + token;
    } else if (file.key?.includes("http")) {
      return file.key;
    } else if (!file.key?.includes("http") && file.key) {
      return s3Url + file?.key!;
    } else return "";
  };

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getToken, privateFiles, setPrivateFiles, getAccessTokenSilently, getIdTokenClaims, token]);

  // useEffect(() => {
  //   setPrivateFiles(privateFiles.filter((item: any) => item.isDeleted === false));
  // }, [privateFiles]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePdfLeft = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const handlePdfRight = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleImageDownload = async (filename: string, url: string) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = filename;
      link.click();
    } catch (error) {
      console.log("다운로드실패", error);
    }
  };

  const renderFileView = (file: any) => {
    const fileUrlWithToken = file.url + "?access_token=" + token;
    const fileName = file.filename;
    const fileExtension = file.filename.slice(file.filename.lastIndexOf(".") + 1);
    const availableFileExtensionArray = ["jpg", "jpeg", "gif", "png", "svg", "bmp"];

    // 이미지
    if (availableFileExtensionArray?.includes(fileExtension.toLowerCase())) {
      return <img src={findImageURL(file)} alt={fileName || "이미지를 불러오는데 실패했습니다."} />;
    }

    // 이미지가 아닐경우
    switch (fileExtension) {
      case "pdf":
        return (
          <div className="file-viewer-pdf">
            <Document className="file-viewer-pdf__document" file={fileUrlWithToken ? fileUrlWithToken : ""} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} renderAnnotationLayer={false} loading={"loading..."} onLoadError={console.error} />
            </Document>
            <div className="button-wrap">
              <button className="left" disabled={pageNumber === 1} onClick={handlePdfLeft}></button>
              <button className="right" disabled={pageNumber === numPages} onClick={handlePdfRight}></button>
            </div>
          </div>
        );
      default:
        return (
          <div className="no-preview-available">
            <p>미리보기를 제공하지 않는 파일 형식입니다.</p>
            <p>다운로드 후 확인해 주세요.</p>
            <p>{fileName}</p>
          </div>
        );
    }
  };

  const sliceTitle = (fileName: string) => {
    const dotIndex = fileName.lastIndexOf(".");
    const title = fileName.slice(0, dotIndex);
    const extension = fileName.slice(dotIndex + 1);
    return { title, extension };
  };
  console.log("privateFiles", privateFiles);
  return (
    <div className="contents-container__grid-contents">
      <div className="base-file">
        <input
          type="file"
          name="img-loader-input"
          multiple
          ref={fileRef}
          onChange={(e: any) => onChange(e, privateFiles, maxSize, limit)}
          style={{ display: "none" }}
        />
        {!isOnlyView && (
          <BaseButton
            title="파일찾기"
            disabled={privateFiles.filter((item: any) => !item.isDeleted).length > 0}
            className={`color-white ${privateFiles.length === 1 ? "mb10" : ""}`}
            onClick={() => fileRef.current?.click()}
          />
        )}
        <>
          <div className={`${Array.isArray(privateFiles) && privateFiles.length > 1 ? "fit-start-line" : ""} `}>
            {privateFiles.map((file: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={`base-image__wrap ${isOnlyView ? "detail" : "form"} ${idx > 0 ? "mt10" : ""} ${file.isDeleted === true && "d-none"}`}
                  onClick={() => handleOpenFullImage(idx)}
                >
                  <img
                    src={findImageURL(file)}
                    alt="preview-images"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = onErrorImage;
                    }}
                  />
                  {!isOnlyView && <button type="button" onClick={(e) => handleDeleteFile(e, idx)} />}

                  <div className="title-area">
                    <div className="not-hover">
                      <p>{sliceTitle(String(file.filename || "")).title}</p>
                      <span>.{sliceTitle(String(file.filename || "")).extension}</span>
                    </div>
                    <div className="active-hover">
                      <p>{sliceTitle(String(file.filename || "")).title}</p>
                      <span>.{sliceTitle(String(file.filename || "")).extension}</span>
                      {isOnlyView && (
                        <button
                          type="button"
                          className="detail-download"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageDownload(file.filename, file.url + "?access_token=" + token);
                          }}
                        ></button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {isFullScreenImageOpen && (
            <BaseAbstractModal isNotUseWhiteBoard isOpen={true} opacity={0.75}>
              <div className="base-image__full-screen">
                {renderFileView(privateFiles[focusFullScreenImageIndex])}
                <div className="img-button-wrap">
                  <div className="flicker">
                    <button className="left" onClick={() => handleFullScreenImage("left")}></button>
                    <p>
                      {1} / {privateFiles.filter((item: any) => item.isDeleted !== true).length}
                    </p>
                    <button className="right" onClick={() => handleFullScreenImage("right")}></button>
                  </div>
                  {privateFiles[focusFullScreenImageIndex].id && (
                    <span
                      className="download"
                      onClick={() =>
                        handleImageDownload(
                          privateFiles[focusFullScreenImageIndex].filename,
                          privateFiles[focusFullScreenImageIndex].url + "?access_token=" + token,
                        )
                      }
                    ></span>
                  )}

                  <button className="close" onClick={() => setIsFullScreenImageOpen(false)}>
                    닫기
                  </button>
                </div>
              </div>
            </BaseAbstractModal>
          )}
        </>
        {isOpen && (
          <BaseModal isOpen={isOpen} btnRightTitle={"확인"} onClick={() => setIsOpen(false)} title={message.title}>
            <p>{message.message}</p>
          </BaseModal>
        )}
        {errorText && <p className="validation-text">{errorText}</p>}
        {!isOnlyView && privateFiles.filter((item: any) => !item.isDeleted).length === 0 && (
          <ul className="base-list mt10">
            <li>문서를 카메라로 찍거나 스캔하여 업로드해주세요.</li>
            <li>업로드 용량은 개당 10MB까지 가능합니다.</li>
          </ul>
        )}
      </div>
    </div>
  );
};
export default ContractUpload;
