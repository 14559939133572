import _ from "lodash";
import { VocModelUpdate } from "src/api/voc/voc-types";

export const onChangedValues = (original: VocModelUpdate, data: VocModelUpdate) => {
  let editData = {};
  // 유형
  const isCsTypeId = _.isEqual(original?.csTypeId, data.csTypeId);
  // 항목
  const isCsItemName = _.isEqual(original?.csItemName, data.csItemName);

  // 비용
  if (!isCsTypeId) editData = { ...editData, csTypeId: data.csTypeId };

  // 유입경로
  if (!isCsItemName) editData = { ...editData, csItemName: data.csItemName };

  return editData;
};
