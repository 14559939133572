import { useEffect, useRef, useState } from "react";
import { BaseButton, BaseInput } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { onlyNumber } from "src/utils";

type Props = {
  onClose: () => void;
  onSubmit: (members: number) => void;
  currentMembers: number;
};

type Error = {
  type?: string;
  message?: string;
};

const ProductMemberModal = ({ onClose, onSubmit, currentMembers }: Props) => {
  const [memberNum, setMemberNum] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const [error, setErrorError] = useState<Error>();

  useEffect(() => {
    inputRef && inputRef.current?.focus();
  }, []);

  return (
    <BaseAbstractModal size="medium" isOpen={true}>
      <section className="base-abstract-modal__contents">
        <section className="base-abstract-modal__title">
          <h1>최대 인원 설정</h1>
        </section>
        <section className="base-abstract-modal__contents px30">
          <div className="mb20">
            <span className="font18">최대 이용자</span>
          </div>
          <div className="flex-center">
            <BaseInput
              inputRef={inputRef}
              maxLength={error?.type ? 1 : 8}
              className="minmax220"
              onChange={(value: string) => {
                if (value !== "" && Number(value) < 1) {
                  setErrorError({ type: "moreMember", message: "한 명 이상 입력 주세요." });
                } else {
                  setErrorError({ type: "", message: "" });
                }
                setMemberNum(onlyNumber(value));
              }}
              value={memberNum}
            />
            <span className="font18 ml5">명</span>
          </div>
          {error && <p className="validation-text mt5">{error.message}</p>}
        </section>

        <section className="base-abstract-modal__btn-wrap">
          <BaseButton title={"취소"} className="color-white" onClick={onClose} />
          <BaseButton
            title="적용"
            onClick={() => {
              if (Number(currentMembers) > Number(memberNum)) {
                setErrorError({ message: "현재 이용자 수 이상을 입력해 주세요." });
              } else {
                setErrorError({ type: "", message: "" });
                onSubmit(Number(memberNum));
              }
            }}
          />
        </section>
      </section>
    </BaseAbstractModal>
  );
};

export default ProductMemberModal;
