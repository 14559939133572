import moment from "moment";
import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MgmtOffice, Officer } from "src/api/mgmtOffice/mgmt-office.types";
import { ProviderBasicModel } from "src/api/provider/provider-types";
import QRCode from "react-qr-code";
import {
  VisitStatusEnum,
  WorkOrderDetail,
  WorkOrderStatus,
  getWorkOrderStatusColor,
  getWorkOrderStatusText,
} from "src/api/work-order/workorder-types";
import useNavigate from "src/hooks/usePartnerNavigate";
import { ViewYmdFormat, YmdFormat, workSheetNoWithHyphen } from "src/utils";
import { VisitorQr } from "src/api/visitor/visitor-types";
import { useApiOperation } from "src/api/hooks";
import { getExternalVisitorQrAsync } from "src/api/visitor/visitor-api";
import { QR_STATUS } from "../workOrder-types";

type Props = {
  workSheet?: WorkOrderDetail;
  workSheetOfficer?: Officer;
  providerBasic?: ProviderBasicModel;
  mgmtOffice?: MgmtOffice;
};
const MobileSummary = ({ workSheet, workSheetOfficer, providerBasic, mgmtOffice }: Props) => {
  const [visitorQr, setVisitorQr] = useState<VisitorQr>();
  const { workOrderSheetNo } = useParams();
  const navigate = useNavigate();
  const { executeAsync: getExternalVisitorQr } = useApiOperation(getExternalVisitorQrAsync, { noAuthenticationRequired: true, externalApi: true }); // 방문초대내역 정보 조회
  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (mgmtOfficerId?: string) => {
      const passParam = {
        mgmtOfficeId: mgmtOffice?.id,
      };
      const newQueryParams = { ...passParam };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      const path = `/mng/ctrl/public/workOrder/mgmtOfficer/${mgmtOfficerId}?${decodeURIComponent(newQueryParamStr)}`;
      navigate(path);
    },
    [navigate, mgmtOffice],
  );

  // 현재 또는 도래할 정상 방문 리스트
  const findAccessList = () => {
    const visitUseContractList = workSheet?.contractVisitorList?.filter((contractVisitor) => contractVisitor.visitStatus === "VISIT_USE");
    const sortedTimeContractVisitorList = visitUseContractList?.sort((a, b) => {
      return moment(a.visitStartTime).isBefore(moment(b.visitStartTime)) ? -1 : 1;
    });
    const filteredList = sortedTimeContractVisitorList?.filter((contractVisitor) => {
      const currentDateTime = moment();
      if (
        moment().isBetween(moment(contractVisitor.visitStartTime), moment(contractVisitor.visitEndTime)) ||
        currentDateTime.isBefore(moment(contractVisitor.visitEndTime))
      ) {
        return true;
      }
      if (currentDateTime.isBefore(moment(contractVisitor.visitStartTime))) {
        return true;
      }
      return false;
    });
    return filteredList;
  };

  // 출입허용시간 계산
  const findAllowedTime = () => {
    const filteredList = findAccessList();
    if (filteredList && filteredList.length > 0) {
      const contractVisitor = filteredList[0];
      let startTime;
      let endTime;
      if (moment().format(YmdFormat.YYYY_MM_DD) === moment(contractVisitor.visitStartTime).format(YmdFormat.YYYY_MM_DD)) {
        startTime = moment(contractVisitor.visitStartTime).format(ViewYmdFormat.HH_MM);
      } else {
        startTime = moment(contractVisitor.visitStartTime).format("YYYY.MM.DD(ddd) HH:mm");
      }

      if (moment(contractVisitor.visitEndTime).format(YmdFormat.YYYY_MM_DD) === moment(contractVisitor.visitStartTime).format(YmdFormat.YYYY_MM_DD)) {
        endTime = moment(contractVisitor.visitEndTime).format(ViewYmdFormat.HH_MM);
      } else {
        endTime = moment(contractVisitor.visitEndTime).format("YYYY.MM.DD(ddd) HH:mm");
      }
      return `${startTime} - ${endTime}`;
    } else {
      return "-";
    }
  };
  //상품명
  const findBuildingName = () => {
    const todayContractVisitor = workSheet?.contractVisitorList?.find((contractVisitor) => {
      const today = moment().format(ViewYmdFormat.YYYY_MM_DD);
      return moment(contractVisitor.visitStartTime).format(ViewYmdFormat.YYYY_MM_DD) === today;
    });
    if (!todayContractVisitor) return null;
    return todayContractVisitor.productName;
  };

  // QR상태 판단
  const findAccessStatus = useCallback(() => {
    const filteredList = findAccessList();
    if (filteredList && filteredList.length > 0) {
      // 도래할 정상 방문초대가 1개라도 있으면
      if (moment().isBetween(moment(filteredList[0].visitStartTime), moment(filteredList[0].visitEndTime))) return QR_STATUS.ACCESS;
      if (moment().isBefore(moment(filteredList[0].visitStartTime))) return QR_STATUS.CANT_ACCESS;
    } else {
      return QR_STATUS.NOT_CONTRACT_VISITOR;
    }
  }, [workSheet?.contractVisitorList, workSheet?.status]);

  // qr조회
  useEffect(() => {
    const getQr = async () => {
      const filteredList = findAccessList();
      const findVisit = filteredList?.find((visitor) => {
        return moment().isBetween(moment(visitor.visitStartTime), moment(visitor.visitEndTime));
      });
      if (findVisit) {
        const applyNumber = String(findVisit.visitApplyNumber) + String(findVisit.contractVisitorId);
        const { data, status } = await getExternalVisitorQr({ visitorKey: applyNumber });
        if (status >= 200 && status < 300) {
          setVisitorQr(data.data.qr);
        }
      }
    };
    if (findAccessStatus() === "ACCESS") {
      getQr();
    }
  }, [workSheet, getExternalVisitorQr, findAccessStatus]);

  return (
    <section className="page-workorder-mobile__summary">
      <div className="page-workorder-mobile__head">
        <div className="flex-center">
          {workSheet?.status && (
            <div className={`base-chip-mobile ${getWorkOrderStatusColor(getWorkOrderStatusText(workSheet.status))}`}>
              <span>{getWorkOrderStatusText(workSheet?.status)}</span>
            </div>
          )}
          {(workSheet?.status === WorkOrderStatus.OPEN || workSheet?.status === WorkOrderStatus.PROGRESS) && (
            <>
              {moment(moment().format("YYYY-MM-DD")).isAfter(workSheet?.endDate) ? (
                <div className="base-chip-mobile color-red">
                  <span>지연</span>
                </div>
              ) : null}
            </>
          )}
          <p className="ml8">{(workSheet && workSheetNoWithHyphen(String(workOrderSheetNo))) || ""}</p>
        </div>
        {workSheet && !workSheetOfficer?.isDeleted && (
          <button
            onClick={() => {
              navigateWithQueryParams(workSheet?.mgmtOfficerId);
            }}
          >
            업무 목록
          </button>
        )}
      </div>
      <div className="page-workorder-mobile__title-area">
        <div className="left-area">
          <h1>{workSheet?.summary || "-"}</h1>
          <p>{findBuildingName()}</p>
          {workSheet && findAccessStatus() === QR_STATUS.NOT_CONTRACT_VISITOR && <p className="text-red">[관리자 문의] 출입 권한이 없습니다.</p>}
          {workSheet && findAccessStatus() === QR_STATUS.CANT_ACCESS && <p className="text-white font14">현재는 출입 불가합니다.</p>}
        </div>
        <div className="right-area">
          <div className={`${visitorQr ? "qr-wrap --active" : "qr-wrap"}`}>
            {visitorQr ? <QRCode value={visitorQr?.cardSerialNumber!} size={118} /> : <></>}
          </div>
        </div>
      </div>
      <div className="page-workorder-mobile__info-area">
        <ul>
          <li>
            <p className="label">업무 기간</p>
            <p className="value">
              {workSheet?.startDate ? (
                <>
                  {" "}
                  {workSheet?.startDate ? moment(workSheet?.startDate).format("YYYY.MM.DD(dd)") : ""}
                  <span className="mx4">-</span>
                  {workSheet?.endDate ? moment(workSheet?.endDate).format("YYYY.MM.DD(dd)") : ""}
                </>
              ) : (
                "-"
              )}
            </p>
          </li>
          <li>
            <p className="label">출입 가능 시간</p>
            <p className="value">{findAllowedTime()}</p>
          </li>

          <li>
            <p className="label">작업자</p>
            <p className="value">{workSheetOfficer?.name || "-"}</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MobileSummary;
