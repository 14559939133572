import { RefObject, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import useModalObserver from "src/recoil/modal-observer/hooks";
import { isPublicPage } from "src/utils/common-util";
interface Props {
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  title?: string;
  btnLeftTitle?: string;
  btnRightTitle?: string;
  type?: string;
  btnRightType?: "button" | "submit" | "reset" | undefined;
  className?: string;
}
/*
  BaseConfirmModal  
  취소 혹은 확인 버튼이 하단에 있는 모달
*/
const BaseConfirmModal = ({ isOpen, onClose, onClick, children, btnLeftTitle, btnRightTitle, title, type, btnRightType, className }: Props) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const { generateId, subscribe, unsubscribe } = useModalObserver();
  const [modalId] = useState(() => generateId());
  const firstButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    subscribe(modalId, {
      Escape: () => onClose && onClose({} as React.MouseEvent<HTMLButtonElement>),
      Enter: () => {
        firstButtonRef?.current?.focus();
      },
    });

    return () => {
      unsubscribe(modalId);
    };
  }, [firstButtonRef]);

  if (!isOpen) return null;

  return mounted
    ? createPortal(
        <>
          <div className={isPublicPage(pathname) ? `dim mobile` : "dim"}>
            <div className={`confirm-modal ${className ? className : ""}`}>
              {title && (
                <div className={`${children ? "confirm-modal__title keep-all pb20" : "confirm-modal__title keep-all pb30"}`}>
                  <p>{title}</p>
                </div>
              )}
              {children && <div className="confirm-modal__contents keep-all">{children}</div>}
              

              {/* {children && (
                <div className={`${title ? "base-modal__contents keep-all pb30" : "base-modal__contents pt20"} keep-all`}>
                  {children && <>{children}</>}
                </div>
              )} */}

              <div className="confirm-modal__btn-wrap">
                {btnLeftTitle && (
                  <button onClick={onClose} className="base-btn-mobile color-white mr10">
                    {btnLeftTitle}
                  </button>
                )}
                {btnRightTitle && (
                  <button onClick={onClick} className="base-btn-mobile" ref={firstButtonRef}>
                    {btnRightTitle}
                  </button>
                )}
              </div>
            </div>
          </div>
        </>,
        ref.current!,
      )
    : null;
};

export default BaseConfirmModal;
