import { atom } from "recoil";

export interface Toast {
  id?: string;
  type?: "SUCCESS" | "FAIL";
  content: string;
  duration?: number;
  isSystemToast?: boolean;
  isMobileToast?: boolean;
}

/**
 * 토스트
 */
export const toastState = atom<Toast[]>({
  key: "toastState",
  default: [],
});
