import moment from "moment";
import { useCallback } from "react";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { changeContractStatus } from "src/api/contract/contract-api";
import { ContractStep, DetailTotalData, EsignType } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { ViewYmdFormat } from "src/utils";

export type SelectOption = {
  value: string;
  label: string;
  index?: number;
  subOptions?: Array<SelectOption>;
  color?: string;
};
export interface SelectValidation {
  key: string;
  validation: any;
}

export interface ContractStatusSelectData {
  rtnOptionArr: Array<SelectOption>;
  validator: Array<SelectValidation>;
}

const useContractStatusChange = () => {
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);
  const { executeAsync: changeCs } = useApiOperation(changeContractStatus);

  const changeStatus = useCallback(
    async (status: string, contractId: number) => {
      const res: any = await changeCs({ contractId, contractStep: status });
      // console.log(res);

      return res;
    },
    [changeCs],
  );

  const checkApplyDate = (contract: DetailTotalData) => {
    // console.log(" contract?.contract?.contractDate", contract?.contract?.contractDate);
    //TODO 계약일시 입력 상태인지 체크
    const value = contract?.contract?.contractDate;
    if (value !== undefined && value !== "" && value !== null) {
      return true;
    } else {
      return false;
    }
  };

  const checkContractApply = (contract: DetailTotalData) => {
    // 이용 기간과 빌링 기간의 체크
    const contractStartTime = contract.contract?.contractStartTime;
    const contractEndTime = contract.contract?.contractEndTime;
    let dateTimeCheck = false;
    const rentalSchedule = contract.scheduleList?.find((schedule: BillingDetail) => schedule.supplyType === "RENTAL");
    if (rentalSchedule) {
      const scheduleStartTime = rentalSchedule.scheduleStartTime;
      const scheduleEndTime = rentalSchedule.scheduleEndTime;
      if (moment(scheduleStartTime).isSameOrAfter(moment(contractStartTime))) {
        dateTimeCheck = true;
      }

      if (moment(scheduleEndTime).isSameOrBefore(moment(contractEndTime))) {
        dateTimeCheck = true;
      }
    }

    let documentChecker = false;
    // 전자서명 목록이 있고 진행중이나 완료 건이 1건이라도 있는 경우
    if (Array.isArray(contract.contract?.eSignList) && contract.contract!.eSignList!.length > 0) {
      const eSignCheckList = contract.contract?.eSignList?.filter((esign: any) => esign.status === EsignType.Playing)!;
      const eSignCheckList2 = contract.contract?.eSignList?.filter((esign: any) => esign.status === EsignType.Complete)!;
      if (eSignCheckList?.length > 0 || eSignCheckList2?.length > 0) {
        documentChecker = true;
      }
    } else {
      //전자서명이 없는 경우 계약서 첨부파일 목록 중 1개라도 등록이 되어 있다면
      if (Array.isArray(contract?.contract?.mediaList) && contract?.contract?.mediaList?.length !== 0) {
        documentChecker = true;
      }
    }

    // 계약금 납부 상태 체크
    if (contract?.contract?.earnestPayStatus !== "PAY_WAIT" && dateTimeCheck && documentChecker) {
      return true;
    } else {
      return false;
    }
  };

  const changeStatusUseConfirm = (spaceProductType: string, contract: DetailTotalData) => {
    if (spaceProductType !== "TIME_COURT") {
      // 입력 상태인지 체크
      if (contract?.contract?.balancePayStatus === "PAY_WAIT" || contract?.contract?.earnestPayStatus === "PAY_WAIT") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const changeStatusTerminateConfirm = useCallback(
    async (contract: DetailTotalData) => {
      let checkOverdueBill = [];
      let payCheck = [];
      //TODO 빌링 상태 체크
      const filteredSchedule = contract?.scheduleList!.find((schedule: BillingDetail) => schedule.supplyType === "RENTAL");

      if (filteredSchedule) {
        const res: any = await getRoundList({ scheduleId: Number(filteredSchedule.scheduleId) });
        if (res.status >= 200 && res.status <= 299) {
          const billRounds = res.data.data.content;
          // console.log("billRounds", billRounds);
          const today = moment().format(ViewYmdFormat.YYYY_MM_DD);
          if (billRounds) {
            checkOverdueBill = billRounds.filter((round: any) => round.bill.isOverdueBill);
            payCheck = billRounds.filter(
              (round: any) =>
                round.bill.isOverdueBill === false && moment(moment(round.bill.billIssueDate).format(ViewYmdFormat.YYYY_MM_DD)).isBefore(today),
            );
          }
        }
      }

      let pay = false;
      payCheck.map((bill: any) => {
        if (bill.bill.billPayStatus === "PAYMENT_READY") {
          pay = true;
        }
      });
      let overdue = false;
      checkOverdueBill.map((bill: any) => {
        if (bill.bill.billPayStatus === "PAYMENT_READY") {
          overdue = true;
        }
      });

      //TODO 계약일시 입력 상태인지 체크
      if (pay || overdue) {
        return false;
      } else {
        return true;
      }
    },
    [getRoundList],
  );

  //해지완료 변경 시 체크
  const changeStatusTerminateComplete = useCallback(
    async (contract: DetailTotalData) => {
      const filteredSchedule: any = contract?.scheduleList!.find((schedule: BillingDetail) => schedule.supplyType === "RENTAL");
      let billRounds: any = [];
      if (filteredSchedule) {
        const res: any = await getRoundList({ scheduleId: Number(filteredSchedule.scheduleId) });

        if (res.status >= 200 && res.status <= 299) {
          billRounds = res.data.data.content;
          console.log("billRounds", billRounds);
        }

        // 빌링스케줄 중지여부 확인

        console.log("stopCheck", filteredSchedule.isContractCancel);

        //중지일 경우 오늘 부터 중지 전까지 회차가 모두 수납완료인지 확인
        if (String(filteredSchedule.isContractCancel) === "true") {
          console.log("중지상태입니다.", billRounds);
          // setBillingCheck(true);
          const checkContractCancelStartOrder = billRounds.filter(
            (round: any) =>
              Number(round.bill.billOrder) < Number(filteredSchedule.contractCancelStartOrder) && round.bill.billPayStatus === "PAYMENT_READY",
          );
          console.log("미납 체크", checkContractCancelStartOrder);
          if (checkContractCancelStartOrder.length > 0) {
            return { type: "overdue", validationPass: false };
          } else {
            return { type: "", validationPass: true };
          }
        } else {
          return { type: "stop", validationPass: false };
        }
      }
    },
    [getRoundList],
  );

  //이용중 변경 시 이용 시작일 체크
  const checkStartDate = useCallback((contract: DetailTotalData) => {
    // console.log(basicInfo, application, contract);
    const today = moment();

    if (moment(contract?.contract?.contractStartTime).isSameOrBefore(today)) {
      // console.log("contract.contractStartTime", moment(contract.contractStartTime).format(ViewYmdFormat.FULL), moment().format(ViewYmdFormat.FULL));
      return true;
    } else {
      return false;
    }
  }, []);

  const makeApplyConfirm = (contract: any, setMessage: any, step: string, serviceType: string) => {
    let ms = {};
    let checker = checkApplyDate(contract);

    if (serviceType !== "TIME_COURT") {
      if (checker) {
        ms = {
          successStatus: true,
          message: "",
          title: "계약예정으로 변경하시겠습니까?",
          step,
        };
      } else {
        ms = {
          failStatus: true,
          message: "먼저 계약탭에서 계약일시를 등록해 주세요.",
          title: "계약예정으로 변경할 수 없습니다.",
          step,
        };
      }
    } else {
      ms = {
        successStatus: true,
        message: "계약자에게 등록하신 계약 일시가 알림톡으로 발신됩니다.",
        title: "계약예정으로 변경하시겠습니까?",
        step,
      };
    }
    return setMessage({
      ...ms,
    });
  };

  const makeContractAccept = (contract: any, setMessage: any, step: string) => {
    let ms = {};
    const result = checkContractApply(contract);

    if (result) {
      ms = {
        successStatus: true,
        message: "청구 수신정보에서 계약체결 안내 알림톡을 선택하셨다면, 계약자에게 계약체결 완료 알림톡이 발신됩니다.",
        title: "계약체결로 변경하시겠습니까?",
        step,
      };
    } else {
      ms = {
        failStatus: true,
        message: "먼저 계약탭에서 계약정보의 계약금을 결제완료로 설정 및 계약서를 등록해 주세요.",
        title: "계약체결로 변경할 수 없습니다.",
        step,
      };
    }
    return setMessage({
      ...ms,
    });
  };
  const makeS2ContractAccept = (spaceProductType: string, contract: any, setMessage: any, step: string) => {
    let ms: any = {
      successStatus: true,
      title: "계약체결로 변경하시겠습니까?",
      step,
    };
    if (spaceProductType === "TIME_COURT") {
      ms.message = "계약자에게 결제요청 알림톡이 발신됩니다.";
    } else {
      ms.message = "계약자에게 계약체결 완료 알림톡이 발신됩니다.";
    }
    return setMessage({
      ...ms,
    });
  };
  const makeUseApproval = (spaceProductType: string, contract: any, setMessage: any, step: string) => {
    let ms: any = {};
    const result = changeStatusUseConfirm(spaceProductType, contract);
    if (result === true) {
      ms = {
        successStatus: true,
        title: "이용승인으로 변경하시겠습니까?",
        step,
      };

      if (spaceProductType === "TIME_COURT") {
        ms.message = "계약자에게 예약완료 알림톡이 발신됩니다.";
      } else {
        ms.message = "계약자에게 이용승인 완료 알림톡이 발신됩니다.";
      }
    } else {
      ms = {
        failStatus: true,
        message: "먼저 계약탭에서 계약정보의 보증금의 잔금을 결제완료로 설정해 주세요.",
        title: "이용승인으로 변경할 수 없습니다.",
        step,
      };
    }

    return setMessage({
      ...ms,
    });
  };

  const makeS2UseApproval = (spaceProductType: string, contract: any, setMessage: any, step: string) => {
    let ms: any = {
      successStatus: true,
      message: "계약자에게 이용승인 완료 알림톡이 발신됩니다.",
      title: "이용승인으로 변경하시겠습니까?",
      step,
    };
    if (spaceProductType === "TIME_COURT") {
      ms.message = "계약자에게 예약완료 알림톡이 발신됩니다.";
    } else {
      ms.message = "계약자에게 이용승인 완료 알림톡이 발신됩니다.";
    }
    return setMessage({
      ...ms,
    });
  };
  const makeUseComplete = (contact: any, setMessage: any, step: string) => {
    let ms = {
      successStatus: true,
      message: "계약자에게 이용 완료 알림톡이 발신됩니다.",
      title: "이용완료로 변경하시겠습니까?",
      step: ContractStep.USE_COMPLETE,
    };

    return setMessage({
      ...ms,
    });
  };

  const makeUseProgress = (contract: any, setMessage: any, step: string) => {
    let ms = {};
    const result = checkStartDate(contract);
    console.log();
    if (result) {
      ms = {
        successStatus: true,
        message: "",
        title: "이용중으로 즉시 변경하시겠습니까?",
        step,
      };
    } else {
      ms = {
        failStatus: true,
        message: "이용시작일이 도래하지 않았습니다.",
        title: "이용중으로 변경할 수 없습니다.",
        step,
      };
    }
    return setMessage({
      ...ms,
    });
  };

  const makeS2UseProgress = (contract: any, setMessage: any, step: string) => {
    let ms = {
      successStatus: true,
      message: "",
      title: "이용중으로 즉시 변경하시겠습니까?",
      step,
    };

    return setMessage({
      ...ms,
    });
  };

  const makeTerminateReceived = useCallback(
    async (spaceProductType: string, contract: any, setMessage: any, step: string) => {
      let ms: any = {};
      if ((await changeStatusTerminateConfirm(contract)) === true) {
        ms = {
          successStatus: true,
          title: "해지접수로 변경하시겠습니까?",
          step,
        };
        if (spaceProductType === "TIME_COURT") {
          ms.message = "계약자에게 취소접수 알림톡이 발신됩니다.";
        } else {
          ms.message = "계약자에게 계약 해지접수 알림톡이 발신됩니다.";
        }
      } else {
        ms = {
          failStatus: true,
          message: "빌링스케줄의 빌링/연체현황에 미납이 있습니다. 먼저 미납에 대해 결제 요청 및 수납처리를 진행해 주세요.",
          title: "해지접수로 변경할 수 없습니다.",
          step,
        };
      }
      return setMessage({
        ...ms,
      });
    },
    [changeStatusTerminateConfirm],
  );

  const makeGoBackUseProgress = (setMessage: any, step: string) => {
    let ms = {
      successStatus: true,
      message: "이용중으로 변경 시 빌링스케줄의 중지 처리가 모두 해제됩니다.",
      title: "이용중으로 변경 하시겠습니까?",
      step,
    };

    return setMessage({
      ...ms,
    });
  };

  const makeTerminateComplete = useCallback(
    async (spaceProductType: string, contract: any, setMessage: any, step: string) => {
      const result: any = await changeStatusTerminateComplete(contract);
      console.log("result", result);
      let ms: any = {};
      if (result && result.validationPass === true) {
        ms = {
          successStatus: true,
          title: "해지완료로 변경하시겠습니까?",
          step,
        };
        if (spaceProductType === "TIME_COURT") {
          ms.message = "계약자에게 취소완료 알림톡이 발신됩니다.";
        } else {
          ms.message = "계약자에게 해지완료 알림톡이 발신됩니다.";
        }
      } else {
        if (result.type === "stop") {
          ms = {
            failStatus: true,
            message: "빌링스케줄의 빌링/연체현황에서 해당 회차의 상태를 중지로 변경해 주세요.",
            title: "해지완료로 변경할 수 없습니다.",
            step,
          };
        }
        if (result.type === "overdue") {
          ms = {
            failStatus: true,
            message: "빌링스케줄의 빌링/연체현황에 미납이 있습니다. 먼저 미납에 대해 결제 요청 및 수납처리를 진행해 주세요.",
            title: "해지완료로 변경할 수 없습니다.",
            step,
          };
        }
      }
      return setMessage({
        ...ms,
      });
    },
    [changeStatusTerminateComplete],
  );

  /**
 * 신청 접수 : 신청취소, 신청확인
  취소 : 없음
  신청확인 : 신청취소, 계약예정
  계약예정 : 신청취소, 계약체결
  계약체결 : 신청취소, 이용승인
  이용승인 : 신청취소,
  이용중: 해지접수
  해지접수 : 이용중, 해지완료
  해지완료: 없음
  이용완료: 없음
 * @param status 
 * @returns 
 */
  const statusBtnShow = useCallback((status: string, spaceProductType: string, contract: DetailTotalData, setMessage: any) => {
    let rtnOptionArr: any = [];
    let validator: any = [];

    const cancel = {
      key: ContractStep.APPLY_CANCEL,
      validation: () => true,
      messages: () =>
        setMessage({
          ...{
            successStatus: true,
            message: "취소로 변경 전, 계약자에게 계약금 환불 정책에 대해 안내해 주세요.",
            title: "신청취소 하시겠습니까?",
            step: ContractStep.APPLY_CANCEL,
          },
        }),
    };
    const s2Cancel = {
      key: ContractStep.APPLY_CANCEL,
      validation: () => true,
      messages: () =>
        setMessage({
          ...{
            successStatus: true,
            message: "계약자에게 신청취소 알림톡이 발신됩니다.",
            title: "신청취소로 변경하시겠습니까?",
            step: ContractStep.APPLY_CANCEL,
          },
        }),
    };

    if (spaceProductType === "TIME_COURT") {
      switch (status) {
        case ContractStep.APPLY_RECEIVED:
          rtnOptionArr.push({ label: "신청접수", value: ContractStep.APPLY_RECEIVED, color: "color-blue" });
          break;
        case ContractStep.APPLY_CANCEL:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL, color: "color-gray" });
          break;
        case ContractStep.APPLY_CONFIRM:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "신청확인", value: ContractStep.APPLY_CONFIRM, color: "color-blue" });
          rtnOptionArr.push({ label: "이용승인", value: ContractStep.USE_APPROVAL });
          validator.push(s2Cancel);
          validator.push({
            key: ContractStep.APPLY_CONFIRM,
            validation: () => true,
            messages: () => makeApplyConfirm(contract, setMessage, ContractStep.CONTRACT_PLAN, String(contract.contractManage?.spaceProductType)),
          });
          break;
        case ContractStep.CONTRACT_PLAN:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "계약예정", value: ContractStep.CONTRACT_PLAN, color: "color-blue" });
          rtnOptionArr.push({ label: "계약체결", value: ContractStep.CONTRACT_ACCEPT });
          validator.push(s2Cancel);

          validator.push({
            key: ContractStep.CONTRACT_ACCEPT,
            validation: () => true,
            messages: () =>
              makeS2ContractAccept(String(contract.contractManage?.spaceProductType), contract, setMessage, ContractStep.CONTRACT_ACCEPT),
          });
          break;
        case ContractStep.CONTRACT_ACCEPT:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "계약체결", value: ContractStep.CONTRACT_ACCEPT, color: "color-red" });
          rtnOptionArr.push({ label: "이용승인", value: ContractStep.USE_APPROVAL });
          validator.push(s2Cancel);
          validator.push({
            key: ContractStep.USE_APPROVAL,
            validation: () => true,
            messages: () => makeS2UseApproval(spaceProductType, contract, setMessage, ContractStep.USE_APPROVAL),
          });
          break;
        case ContractStep.USE_APPROVAL:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "이용승인", value: ContractStep.USE_APPROVAL, color: "color-green" });
          rtnOptionArr.push({ label: "이용중", value: ContractStep.USE_PROGRESS });
          validator.push(s2Cancel);
          validator.push({
            key: ContractStep.USE_PROGRESS,
            validation: () => true,
            messages: () => makeS2UseProgress(contract, setMessage, ContractStep.USE_PROGRESS),
          });
          break;
        case ContractStep.USE_PROGRESS:
          rtnOptionArr.push({ label: "이용중", value: ContractStep.USE_PROGRESS, color: "color-green" });

          break;
        case ContractStep.USE_COMPLETE:
          rtnOptionArr.push({ label: "이용완료", value: ContractStep.USE_COMPLETE, color: "color-gray" });
          break;
        case ContractStep.TERMINATE_RECEIVED:
          // rtnOptionArr.push({ label: "해지접수", value: ContractStep.TERMINATE_RECEIVED, color: "color-red" });
          break;
        case ContractStep.TERMINATE_COMPLETE:
          // rtnOptionArr.push({ label: "해지완료", value: ContractStep.TERMINATE_COMPLETE, color: "color-gray" });
          break;
      }
    } else {
      // console.log("FO", status);
      switch (status) {
        case ContractStep.APPLY_RECEIVED:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "신청접수", value: ContractStep.APPLY_RECEIVED, color: "color-blue" });
          rtnOptionArr.push({ label: "신청확인", value: ContractStep.APPLY_CONFIRM });
          validator.push(cancel);
          validator.push({
            key: ContractStep.APPLY_CONFIRM,
            validation: () => true,
            messages: () =>
              setMessage({
                ...{
                  successStatus: true,
                  message: "",
                  title: "신청확인으로 변경 하시겠습니까?",
                  step: ContractStep.APPLY_CONFIRM,
                },
              }),
          });
          break;
        case ContractStep.APPLY_CANCEL:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL, color: "color-gray" });
          break;
        case ContractStep.APPLY_CONFIRM:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "신청확인", value: ContractStep.APPLY_CONFIRM, color: "color-blue" });
          rtnOptionArr.push({ label: "계약예정", value: ContractStep.CONTRACT_PLAN });
          validator.push(cancel);
          validator.push({
            key: ContractStep.CONTRACT_PLAN,
            validation: () => checkApplyDate(contract),
            messages: () => makeApplyConfirm(contract, setMessage, ContractStep.CONTRACT_PLAN, String(contract.contractManage?.spaceProductType)),
          });
          break;
        case ContractStep.CONTRACT_PLAN:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "계약예정", value: ContractStep.CONTRACT_PLAN, color: "color-blue" });
          rtnOptionArr.push({ label: "계약체결", value: ContractStep.CONTRACT_ACCEPT });
          validator.push(cancel);

          validator.push({
            key: ContractStep.CONTRACT_ACCEPT,
            validation: () => checkContractApply(contract),
            messages: () => makeContractAccept(contract, setMessage, ContractStep.CONTRACT_ACCEPT),
          });
          break;
        case ContractStep.CONTRACT_ACCEPT:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "계약체결", value: ContractStep.CONTRACT_ACCEPT, color: "color-red" });
          rtnOptionArr.push({ label: "이용승인", value: ContractStep.USE_APPROVAL });
          validator.push(cancel);
          validator.push({
            key: ContractStep.USE_APPROVAL,
            validation: () => changeStatusUseConfirm(spaceProductType, contract),
            messages: () => makeUseApproval(spaceProductType, contract, setMessage, ContractStep.USE_APPROVAL),
          });
          break;
        case ContractStep.USE_APPROVAL:
          rtnOptionArr.push({ label: "신청취소", value: ContractStep.APPLY_CANCEL });
          rtnOptionArr.push({ label: "이용승인", value: ContractStep.USE_APPROVAL, color: "color-green" });
          rtnOptionArr.push({ label: "이용중", value: ContractStep.USE_PROGRESS });
          validator.push(cancel);
          validator.push({
            key: ContractStep.USE_PROGRESS,
            validation: () => checkStartDate(contract),
            messages: () => makeUseProgress(contract, setMessage, ContractStep.USE_PROGRESS),
          });
          break;
        case ContractStep.USE_PROGRESS:
          rtnOptionArr.push({ label: "이용중", value: ContractStep.USE_PROGRESS, color: "color-green" });
          rtnOptionArr.push({ label: "해지접수", value: ContractStep.TERMINATE_RECEIVED });
          validator.push({
            key: ContractStep.TERMINATE_RECEIVED,
            validation: async () => await changeStatusTerminateConfirm(contract),
            messages: () =>
              makeTerminateReceived(String(contract.contractManage?.spaceProductType), contract, setMessage, ContractStep.TERMINATE_RECEIVED),
          });

          break;
        case ContractStep.USE_COMPLETE:
          rtnOptionArr.push({ label: "이용완료", value: ContractStep.USE_COMPLETE, color: "color-gray" });

          break;
        case ContractStep.TERMINATE_RECEIVED:
          rtnOptionArr.push({ label: "이용중", value: ContractStep.USE_PROGRESS });
          rtnOptionArr.push({ label: "해지접수", value: ContractStep.TERMINATE_RECEIVED, color: "color-red" });
          rtnOptionArr.push({ label: "해지완료", value: ContractStep.TERMINATE_COMPLETE });
          validator.push({
            key: ContractStep.USE_PROGRESS,
            validation: () => true,
            messages: () => makeGoBackUseProgress(setMessage, ContractStep.USE_PROGRESS),
          });
          validator.push({
            key: ContractStep.TERMINATE_COMPLETE,
            validation: async () => {
              const result: any = await changeStatusTerminateComplete(contract);
              return result.validation;
            },
            messages: () =>
              makeTerminateComplete(String(contract.contractManage?.spaceProductType), contract, setMessage, ContractStep.TERMINATE_COMPLETE),
          });
          break;
        case ContractStep.TERMINATE_COMPLETE:
          rtnOptionArr.push({ label: "해지완료", value: ContractStep.TERMINATE_COMPLETE, color: "color-gray" });
          break;
      }
    }

    // console.log(rtnOptionArr, validator);
    return { rtnOptionArr, validator };
  }, []);
  return { statusBtnShow, changeStatus };
};

export default useContractStatusChange;
