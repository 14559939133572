import { useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { UnionCmdType } from "src/api/public-types";
import { CsServiceItemModel } from "src/api/voc/voc-types";
import { BaseButton, BaseCheckbox } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useToast } from "src/recoil/toast/hook";
import { FaqColumns } from "./columns/FaqColumns";

type Props = {
  list: CsServiceItemModel[];
  onClose: () => void;
  onSubmit: (passData: CsServiceItemModel[]) => Promise<void>;
};

const FaqSetting = ({ list, onClose, onSubmit }: Props) => {
  const [selected, setSelected] = useState<Array<CsServiceItemModel & { checked?: boolean }>>([]);

  const [faqList, setFaqList] = useState<CsServiceItemModel[]>([]);

  const { openToast } = useToast();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: FaqColumns,
      data: faqList,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          width: 80,
          Header: "선택",
          accessor: "checked",
          Cell: ({ row }: Cell<CsServiceItemModel>) => {
            return (
              <div>
                <BaseCheckbox
                  id={`${row.original.id}`}
                  name={`${row.original.id}`}
                  checked={row.original.checked}
                  onChange={(checked: boolean) => {
                    row.original.checked = checked;
                    setSelected(
                      (prev: any) =>
                        checked ? [...prev, { ...row.original }] : prev.filter((item: CsServiceItemModel) => item.csItemId !== row.original.csItemId), //
                    );
                  }}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  // 자주하는 질문 팝업 list setState
  useEffect(() => {
    setFaqList(
      list.map((item) => {
        return {
          checked: item.isFrequent,
          ...item,
        };
      }),
    );

    if (list.some((item) => item.isFrequent)) {
      setSelected(list.filter((item) => item.isFrequent));
    }
  }, [list]);

  // 자주하는 질문 설정 5개 초과시 경고 Toast
  useEffect(() => {
    if (selected.length > 5) {
      const lastItem = selected[selected.length - 1];
      const filteredLast = faqList.map((faq) => {
        if (faq.csItemId === lastItem.csItemId) {
          faq = { ...faq, checked: false };
        }
        return faq;
      });
      setFaqList(filteredLast);
      setSelected(selected.filter((item) => item.csItemId !== lastItem.csItemId));
      openToast({ content: "자주하는 질문은 최대 5개까지 선택이 가능합니다.", isSystemToast: true });
    }
  }, [selected]);

  // 자주하는 질문 설정 api
  const onChangeIsFrequent = async () => {
    if (selected.length > 0) {
      const newData = faqList.map((faq) => {
        faq = { ...faq, cmdType: "U" as UnionCmdType, isFrequent: Boolean(faq.checked) };
        delete faq.checked;
        return faq;
      });
      onSubmit(newData);
    }
  };

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <div>
      <BaseAbstractModal className="dialog-modal " isOpen={true}>
        {/*table */}
        <section className="base-abstract-modal__title">
          <h1>자주 하는 질문 설정</h1>
        </section>
        <section className="base-abstract-modal__contents ">
          <div className="contents-container__table px30 ">
            <div {...getTableProps()} className="base-table view-data-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.length > 0 ? (
                  rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30 ">
          <div className="flex-center-end py20">
            <BaseButton title="취소" className="color-white mr10" onClick={onClose} />
            <BaseButton
              title="저장"
              onClick={() => {
                onChangeIsFrequent();
              }}
            />
          </div>
        </section>
      </BaseAbstractModal>
    </div>
  );
};

export default FaqSetting;
