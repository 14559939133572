import { divide } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { editProviderPopbillAsync, popbillInfoCheck, postProviderPopbillAsync } from "src/api/provider/provider-api";
import { PopbillCheckResponse, ProviderDetailModel, ProviderPopbillAddModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal } from "src/components";

import { Modal } from "src/pages/product/product-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";

type Props = {
  providerDetail?: ProviderDetailModel;
  providerId: number;
  providerDetailApi: Function;
};
const ProviderPopbillForm = ({ providerId, providerDetail, providerDetailApi }: Props) => {
  // const { openErrorModal } = useErrorModal();

  // 로딩바

  // 토스트
  // const { openToast } = useToast();

  // const [submitData, setSubmitData] = useState<ProviderPopbillAddModel>();

  // const [confirmModal, setConfirmModal] = useState<Modal>({
  //   isOpen: false,
  // });

  // // 경고창 모달
  // const [alertModal, setAlertModal] = useState<Modal>({
  //   isOpen: false,
  // });

  // // 팝빌 등록
  // const { executeAsync: postProviderPopbill } = useApiOperation(postProviderPopbillAsync);

  // // 팝빌 수정
  // const { executeAsync: editProviderPopbill } = useApiOperation(editProviderPopbillAsync);

  // // 팝빌 검증
  // const { executeAsync: getPopbillInfoCheck } = useApiOperation(popbillInfoCheck, {
  //   noHandleError: true,
  // });

  // const defaultValues = useMemo(() => {
  //   const providerPostData: ProviderPopbillAddModel = {
  //     providerPopbill: {
  //       providerId,
  //       linkId: "",
  //       secretKey: "",
  //       isDeleted: false,
  //     },
  //   };

  //   return providerPostData;
  // }, [providerId]);

  // const { control, getValues, setValue, register, watch, handleSubmit } = useForm<ProviderPopbillAddModel>({
  //   defaultValues,
  // });

  // //  유효성 확인
  // const formValidation = useCallback(() => {
  //   const requiredMessage = "필수입력 항목입니다.";

  //   // // 팝빌 LinkID
  //   register("providerPopbill.linkId", {
  //     required: { value: true, message: requiredMessage },
  //   });
  //   // // 팝빌 LinkID
  //   register("providerPopbill.secretKey", {
  //     required: { value: true, message: requiredMessage },
  //   });
  // }, [register]);

  // const providerPopbillSetValue = useCallback(() => {
  //   if (providerDetail?.providerPopbill?.linkId) {
  //     setValue("providerPopbill.linkId", providerDetail?.providerPopbill?.linkId);
  //     setValue("providerPopbill.secretKey", providerDetail?.providerPopbill?.secretKey);
  //   }
  // }, [providerDetail?.providerPopbill]);

  // useEffect(() => {
  //   providerPopbillSetValue();
  // }, [providerPopbillSetValue]);

  // useEffect(() => {
  //   formValidation();
  // }, [formValidation]);

  // const updatePopbillInfo = useCallback(
  //   async (data: ProviderPopbillAddModel) => {
  //     if (providerDetail?.providerPopbill?.linkId || providerDetail?.providerPopbill?.secretKey) {
  //       const response = await editProviderPopbill(data);
  //       if (response.status >= 200 && response.status <= 299) {
  //         const result = response.data.data.providerId;
  //         providerDetailApi(result);
  //         openToast({ content: `정상적으로 저장되었습니다.` });
  //         setConfirmModal({ isOpen: false });
  //       }
  //     } else {
  //       const response = await postProviderPopbill(data);
  //       if (response.status >= 200 && response.status <= 299) {
  //         const result = response.data.data.providerId;
  //         providerDetailApi(result);
  //         openToast({ content: `정상적으로 저장되었습니다.` });
  //         setConfirmModal({ isOpen: false });
  //       }
  //     }
  //   },
  //   [providerDetail?.providerPopbill],
  // );

  // // 유효성 검사후 저장
  // const onSubmit = useCallback(async (data?: ProviderPopbillAddModel, e?: any) => {
  //   console.log("data", data);
  //   e.preventDefault();
  //   setSubmitData(data);
  //   setConfirmModal({ isOpen: true, message: "저장하시겠습니까?" });
  // }, []);

  // const onError = useCallback((errors: any, e?: any) => {
  //   console.log("onError errors", errors);
  //   return false;
  // }, []);

  // // 팝빌 검증 api
  // const popbillInfoCheckApi = useCallback(
  //   async (id: number) => {
  //     const linkId = providerDetail?.providerPopbill?.linkId;
  //     const secretKey = providerDetail?.providerPopbill?.secretKey;

  //     //
  //     if (!linkId || !secretKey) {
  //       setAlertModal({ isOpen: true, message: "LinkID 와 SecretKey 를 저장하세요. " });
  //       return;
  //     }

  //     if (id) {
  //       const response = await getPopbillInfoCheck({ providerId: id });
  //       if (response.status >= 200 && response.status <= 299) {
  //         const result = response.data.data;

  //         // 에러 메세지 있을 시 팝업 여부 확인 필요
  //         if (result.verifiedError.verifiedAccountMessage) {
  //           setAlertModal({ isOpen: true, message: result.verifiedError.verifiedAccountMessage, payload: "error" });
  //         }

  //         if (result.verifiedError.verifiedBankMessage) {
  //           setAlertModal({ isOpen: true, message: result.verifiedError.verifiedBankMessage, payload: "error" });
  //         }

  //         if (result.verifiedError.verifiedTaxMessage) {
  //           setAlertModal({ isOpen: true, message: result.verifiedError.verifiedTaxMessage, payload: "error" });
  //         }
  //         providerDetailApi(providerId);
  //       } else {
  //         const errors = response.data.meta;

  //         const errorMessage = errors.errorMessage;
  //         const errorCode = errors.errorCode;

  //         let message = "";

  //         if (errorMessage?.includes("-99004021")) {
  //           message = "링크아이디가 존재하지 않습니다.";
  //         } else if (errorMessage?.includes("-99003008")) {
  //           message = "고유번호의 회원이 존재하지 않습니다.";
  //         }

  //         openErrorModal({ errorMessage: message, errorCode });
  //       }
  //     }
  //   },
  //   [providerDetail?.providerPopbill],
  // );

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title flex-column pb0">
        <h2>계좌 입금 확인 / 세금계산서 발행 정보</h2>
        {/* 가이드메시지 수정에서만 노출. 상세에선 노출하지않음 */}
        <p className="contents-container__sub-title-info mt10">
          프로바이더 계좌의 입금 확인과 세금계산서 발행을 위해서는 프로바이더가{" "}
          <a href="https://www.popbill.com/" target={"_blank"} className="text-underline" rel="noreferrer">
            팝빌
          </a>
          에 직접 가입해야 합니다.
        </p>
      </div>

      {/* <section className="contents-container__1200">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="mb10">
            <div className="index-tab">
              <span>팝빌 인증정보</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="flex-center mb10">
                <div className="minmax123">
                  <span className="required font13 text-gray600">LinkID</span>
                </div>
                <Controller
                  control={control}
                  name={`providerPopbill.linkId`}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return <BaseInput onChange={onChange} value={value} name={name} errorText={error && error.message} />;
                  }}
                ></Controller>
              </section>
              <section className="flex-center">
                <div className="minmax123">
                  <span className="required font13 text-gray600">SecretKey</span>
                </div>
                <Controller
                  control={control}
                  name={`providerPopbill.secretKey`}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                    return (
                      <BaseInput
                        onChange={onChange}
                        value={value}
                        name={name}
                        errorText={error && error.message}
                        placeholder="별도 비번관리 페이지에서 확인 가능"
                        type="password"
                      />
                    );
                  }}
                ></Controller>
              </section>
            </div>
          </div>
          <div className="flex-center-end mt10">
            <BaseButton type="submit" title="저장" />
          </div>
        </form>
      </section> */}

      <section className="contents-container__1200">
        <div className="mb10">
          <div className="index-tab">
            <span>팝빌 인증정보 검증</span>
          </div>
          <div className="border-gray py16 pr16 px20">
            {/* <section className="flex-center mb10">
              <div className="minmax123">
                <BaseButton title="검증" onClick={() => popbillInfoCheckApi(providerId)} />
              </div>
            </section> */}
            <section className="flex-center">
              <div className="minmax200 d-flex">
                <span className="font13 text-gray600">세금 계산서 발행 API : </span>
                <span className=" font13 ml10">{providerDetail?.provider?.isVerifiedTaxInvoice ? "성공" : "실패"}</span>
              </div>

              <div className="minmax200">
                <span className="font13 text-gray600">계좌 조회 API : </span>
                <span className=" font13 ml10">
                  {" "}
                  {/* {popbillCheck?.verifiedInfo.isVerifiedBankAccount ? "성공" : "실패"} */}
                  {providerDetail?.provider?.isVerifiedBankAccount ? "성공" : "실패"}
                </span>
              </div>
              <div className="minmax200">
                <span className="font13 text-gray600">현금영수증 조회 API : </span>
                <span className=" font13 ml10">
                  {" "}
                  {/* {popbillCheck?.verifiedInfo.isVerifiedBankAccount ? "성공" : "실패"} */}
                  {providerDetail?.provider?.isVerifiedCashbill ? "성공" : "실패"}
                </span>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnRightTitle="확인"
          btnLeftTitle="취소"
          onClose={() => setConfirmModal({ isOpen: false })}
          title={confirmModal.message}
          onClick={() => {
            submitData && updatePopbillInfo(submitData);
          }}
        ></BaseModal>
        <BaseModal
          isOpen={alertModal.isOpen}
          btnRightTitle="확인"
          title={!alertModal.payload ? alertModal.message : ""}
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
        >
          {alertModal.payload === "error" && (
            <div>
              <p className="font18 font-weight-semibold text-gray900 mb20">팝빌 검증에 실패하였습니다.</p>
              <p>{alertModal.message}</p>
            </div>
          )}
        </BaseModal>
      </> */}
    </article>
  );
};

export default ProviderPopbillForm;
