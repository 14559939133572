import { Dispatch, SetStateAction } from "react";
import { MgmtOfficeModel } from "src/api/building/building-types";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  managementDetail?: MgmtOfficeModel;
  setMgmtInfoOpen: Dispatch<SetStateAction<Modal>>;
  providerName: string;
  isAuthority: boolean | undefined;
};

const MgmtOfficeInfoDetail = ({ managementDetail, isAuthority, providerName, setMgmtInfoOpen }: Props) => {
  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title={"관리처"} />

        {isAuthority && (
          <>
            {managementDetail?.id ? (
              <div className="my10 flex-center">
                <BaseButton
                  title="수정"
                  className="size-small"
                  onClick={() => {
                    setMgmtInfoOpen({ isOpen: true });
                    // handleOpenSectionForm("info", managementDetail);
                  }}
                />
              </div>
            ) : (
              <BaseButton
                title="등록"
                className="size-small"
                onClick={() => {
                  setMgmtInfoOpen({ isOpen: true });
                  // handleOpenSectionForm("info");
                }}
              />
            )}
          </>
        )}
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>관리처명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{(managementDetail?.id && providerName && providerName) || "-"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>업무시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">
              {managementDetail?.startTime} ~ {managementDetail?.endTime}
            </span>
          </div>
          {managementDetail?.isHoliday && (
            <div className="minmax400">
              <span className="font14 text-primary3">• 공휴일 포함</span>
            </div>
          )}
        </div>
      </section>
    </article>
  );
};

export default MgmtOfficeInfoDetail;
