import _ from "lodash";

import { MediaFile } from "src/api/public-types";
import { WorkOrderAccessZone, WorkOrderFormType } from "src/api/work-order/workorder-types";

export const makeRequestEditData = (original: any, data: any) => {
  // const original = frontOriginalData;

  let editData: WorkOrderFormType = {};

  // 원본 데이터, 변경된 데이터 비교 -> 원본 데이터와 다르면 true 출력

  // 접수자 위치 - 건물
  // const isReporterBuildingName = _.isEqual(original?.reporterBuildingName, data.reporterBuildingName);

  // 접수자 위치 - 주소
  // const isReporterBuildingAddr = _.isEqual(original?.reporterBuildingAddr, data.reporterBuildingAddr);

  // 관리처 id
  const isMgmtOfficeId = _.isEqual(original?.mgmtOfficeId, data.mgmtOfficeId);
  // 관리처 담당자 id
  const isMgmtOfficerId = _.isEqual(original?.mgmtOfficerId, data.mgmtOfficerId);

  // 시작일
  const isStartDate = _.isEqual(original?.startDate, data.startDate);

  // 종료일
  const isEndDate = _.isEqual(original?.endDate, data.endDate);

  // 업무제목
  const isSummary = _.isEqual(original?.summary, data.summary);

  // 업무설명
  const isDescription = _.isEqual(original?.description, data.description);

  // 처리 내용
  const isCompletionReport = _.isEqual(original?.completionReport, data.completionReport) || (!original?.completionReport && !data.completionReport);

  // 상태
  const isStatus = _.isEqual(original?.status, data.status);

  // 재처리요청
  const isReopen = _.isEqual(original?.isReopened, data.isReopened);

  const isSendAlarm = _.isEqual(original?.isSendAlarm, data.isSendAlarm);

  // 처리비용 비교
  // 처리비용 데이터가 있으면 데이터들끼리 비교,
  let isWorkOrderSheetCostList = false;
  if (original?.workOrderSheetCostList && original.workOrderSheetCostList.length > 0) {
    //data?.workOrderSheetCostList 안에 apiId 제거하고  original과 비교
    const changedWorkOrderCosts = data?.workOrderSheetCostList?.map((item: any) => {
      if (item.cmdType) {
        return {
          id: item.id,
          description: item.description,
          cost: item.cost,
          isDeleted: null,
          cmdType: item.cmdType,
        };
      } else {
        return {
          id: item.id,
          description: item.description,
          cost: item.cost,
          isDeleted: null,
        };
      }
    });

    isWorkOrderSheetCostList = _.isEqual(original?.workOrderSheetCostList, changedWorkOrderCosts);
  } else {
    // 신규 등록 후 첫 수정시엔 처리비용 데이터가 없으므로 기본값과 비교
    isWorkOrderSheetCostList = _.isEqual([{ cost: 0, description: "", cmdType: "C" }], data.workOrderSheetCostList);
  }

  // 고객답변
  const isCustomerNotification =
    _.isEqual(original?.customerNotification, data.customerNotification) || (!original?.customerNotification && !data.customerNotification);
  const isMediaList = _.isEqual(
    original?.mediaList?.sort((a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0)),
    data.mediaList?.sort((a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0)),
  );

  // =============================================================
  // 변경된 데이터가 있을경우, (false 리턴일시) - editData 에 변경된 데이터 적용
  //

  // 상태
  if (!isStatus) {
    editData = { ...editData, status: data.status };
  }

  // 재처리 요청
  if (!isReopen) {
    editData = { ...editData, isReopened: data.isReopened };
  }

  // 접수자 건물 위치
  // if (!isReporterBuildingName) editData = { ...editData, reporterBuildingName: data.reporterBuildingName };

  // 접수자 건물 주소
  // if (!isReporterBuildingAddr) editData = { ...editData, reporterBuildingAddr: data.reporterBuildingAddr };

  // 관리처 id
  if (!isMgmtOfficeId) editData = { ...editData, mgmtOfficeId: data.mgmtOfficeId };

  // 관리처 담당자 id
  if (!isMgmtOfficerId) editData = { ...editData, mgmtOfficerId: data.mgmtOfficerId };

  // 시작일
  if (!isStartDate) {
    const workOrderSheetAccessZoneList = data.workOrderSheetAccessZoneList?.map((item: WorkOrderAccessZone) => ({
      ...item,
      startDate: data.startDate,
    }));
    editData = { ...editData, startDate: data.startDate, workOrderSheetAccessZoneList };
  }

  // 종료일
  if (!isEndDate) {
    const workOrderSheetAccessZoneList = data.workOrderSheetAccessZoneList?.map((item: WorkOrderAccessZone) => ({
      ...item,
      endDate: data.endDate,
      cmdType: "U",
    }));
    editData = { ...editData, endDate: data.endDate, workOrderSheetAccessZoneList };
  }

  // if (!isStartDate) editData = { ...editData, startDate: data.startDate }; //  v.1.11 이전
  // if (!isEndDate) editData = { ...editData, endDate: data.endDate };  // v.1.11 이전
  // 제목
  if (!isSummary) editData = { ...editData, summary: data.summary };
  // 업무설명
  if (!isDescription) editData = { ...editData, description: data.description };
  // 처리내용
  if (!isCompletionReport) editData = { ...editData, completionReport: data.completionReport };

  // 고객 답변용
  if (!isCustomerNotification) editData = { ...editData, customerNotification: data.customerNotification };

  //  처리비용 부분 수정
  if (!isWorkOrderSheetCostList) {
    // 변경된 데이터값 찾기
    const workOrderSheetCostList = _.differenceWith(data.workOrderSheetCostList, original?.workOrderSheetCostList || [], _.isEqual);

    editData = {
      ...editData,
      workOrderSheetCostList: workOrderSheetCostList.map((item) => {
        // 수정 데이터 request 전 apiId 삭제
        if (item.apiId) delete item.apiId;

        // 처리 금액에 빈 값 수정시 0으로 request
        if (!item.cost === true) {
          item = { ...item, cost: 0 };
        }

        if (!item.cmdType) {
          item = { ...item, cmdType: "U" };
        }
        return item;
      }),
    };
  }

  if (!isSendAlarm) {
    editData = { ...editData, isSendAlarm: data.isSendAlarm };
  }

  // 이미지 수정부분
  if (!isMediaList) {
    // list 서로 순서맞게 sort 적용
    const dataMedia = data.mediaList?.sort((a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0));
    const originalMedia = original?.mediaList?.sort((a: MediaFile, b: MediaFile) => Number(a.id || 0) - Number(b.id || 0));
    // 변경된 데이터 찾기
    const mediaList = _.differenceWith(dataMedia, originalMedia || [], _.isEqual);

    // 원본/변경 데이터 비교하여 삭제된 이미지 찾기
    const compareDeleteMedias = _.differenceWith(originalMedia, dataMedia || [], _.isEqual).map((item) => {
      return { ...item, cmdType: "D" };
    });

    if (compareDeleteMedias.length > 0) {
      editData = {
        ...editData,
        mediaList: compareDeleteMedias.map((item: MediaFile) => {
          return { ...item, cmdType: "D" };
        }),
      };
    }

    const newMediaList = [...(mediaList || []), ...compareDeleteMedias];

    editData = {
      ...editData,
      mediaList: newMediaList.map((media: MediaFile) => {
        if (media.cmdType === "C" || media.cmdType === "D") {
          return media;
        } else {
          return { ...media, cmdType: "U" };
        }
      }),
    };
  }

  return editData;
};
