import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import ProviderContactDetail from "../basicInfoDetail/components/ProviderContactDetail";

const ContractInfoDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const providerId = params.id;
  const location = useLocation();

  const [providerDetail, setProviderDetail] = useState<ProviderDetailModel>();

  const { isAuthority } = usePartnerAuthority();

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const providerDetailApi = useCallback(async (id: string) => {
    const response = await getProviderDetail({ providerId: id });
    if (response.status >= 200 || response.status <= 299) {
      const result = response.data.data;
      setProviderDetail(result);
    }
  }, []);

  useEffect(() => {
    if (providerId) {
      providerDetailApi(providerId);
    }
  }, [providerDetailApi, providerId]);
  return (
    <>
      <MetaTag subTitle={providerDetail?.provider?.providerName} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-basic-info">
          {/* 연락처 */}
          <ProviderContactDetail providerContacts={providerDetail?.providerContactList} />
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.provider.form}?id=${providerId}${location.search.replace("?", "&")}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContractInfoDetail;
