import { useRecoilValue } from "recoil";
import { toastState } from "src/recoil/toast/atom";
import ToastItem from "./ToastItem";

/**
 * 토스트 목록
 */
function ToastList() {
  const toasts = useRecoilValue(toastState);
  return (
    <>
      {toasts.map((toast) => (
        <ToastItem key={toast.id} {...toast} />
      ))}
    </>
  );
}

export default ToastList;
