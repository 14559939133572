import BaseNewTabLink from "src/components/BaseNewTabLink";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";

const roomsByOwnerListColumns: any = [
  {
    Header: "id",
    accessor: "buildingId",
    // sticky: "left",
    width: 200,
    Cell: ({ value }: any) => {
      const path = PagePath.building.detail.replace(":id", value);
      return <BaseNewTabLink path={path} value={value} className="w-100 text-left" />;
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 370,
    Cell: ({ value }: any) => {
      return <>{value}</>;
    },
  },
  {
    Header: "층",
    accessor: "floorNum",
    width: 250,
    Cell: ({ value }: any) => {
      return <span>{`${value > 0 ? "지상" : "지하"} ${Math.abs(value)}층`}</span>;
    },
  },
  {
    Header: "호실",
    accessor: "roomNum",
    width: 250,
    Cell: ({ value }: any) => {
      return <>{value}</>;
    },
  },
];
export default roomsByOwnerListColumns;
