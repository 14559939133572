import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { getAppliedPriceRulesListAsync } from "src/api/price/price-api";
import { getProviderList } from "src/api/provider/provider-api";
import { BaseInputWithSearch, BasePagination, BaseSelect } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import useApiLoading from "src/hooks/useApiLoading";
import PagePath from "src/pagePath.json";
import { changeFacilityTypeToText } from "src/pages/contract/contract-util";
import { numberToStringWithComma } from "src/utils";
import { findPriceRuleOptionToText } from "../price-types";
const searchType = [{ label: "공용공간명", value: "FACILITY_NAME" }];

const columnHeader: any = [
  {
    Header: "건물",
    accessor: "buildingName",
    width: 160,
    Cell: (props: any) => {
      const buildingId = props.row.original.buildingId;
      const path = PagePath.building.detail.replace(":id", buildingId);
      return <BaseNewTabLink path={path} value={props.value || "-"} ellipsis className="w-100 text-left" />;
    },
  },
  {
    Header: "공간타입",
    accessor: "commonFacilityType",
    width: 160,
    Cell: ({ value }: any) => {
      return <p>{changeFacilityTypeToText(value)}</p>;
    },
  },
  {
    Header: "공용공간",
    accessor: "facilityName",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "원가",
    accessor: "cost",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}원</p>
        </div>
      );
    },
  },
  {
    Header: "판매가",
    accessor: "price",
    width: 140,
    Cell: ({ value }: any) => {
      return (
        <div className="text-right w-100">
          <p>{numberToStringWithComma(value)}원</p>
        </div>
      );
    },
  },
  {
    Header: "기준요금 유형",
    accessor: "priceRuleType",
    width: 180,
    Cell: ({ value }: any) => {
      return <p>{findPriceRuleOptionToText(value)}</p>;
    },
  },
];
type Props = {
  isOpen: boolean;
  name?: string;
  columns?: any;
  partnerId?: string;
  placeholder?: string;
  rightBtnName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const AppliedBasePriceModal = (props: Props) => {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [tableDataList, setTableDataList] = useState<any[]>([]);
  const [size, setSize] = useState(20);
  const [providerSelectOption, setProviderSelectOption] = useState([{ label: "", value: "" }]);

  const { isApiLoading } = useApiLoading();
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: props.columns ? props.columns : columnHeader,
      data: tableDataList,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  const { handleSubmit, getValues, control, setValue } = useForm<{
    keywordList: string;
    providerId: string;
    facilityName: string;
    searchType: string;
  }>();
  const searchFormRef = useRef<HTMLFormElement>(null);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync);
  const { executeAsync: getAppliedPriceRulesList } = useApiOperation(getAppliedPriceRulesListAsync);

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const fetchProvider = async ({ page }: { page: number }) => {
    const ProviderResponse = await getProvider({
      page,
      size: 999,
      supplyType: "RENTAL",
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    const providerList = ProviderResponse.data.data.content;
    const providerIdListSelectOption = providerList.map((item) => {
      let selectOption = {
        label: String(item.providerName) || "",
        value: String(item.providerId) || "",
      };
      return selectOption;
    });
    setProviderSelectOption(providerIdListSelectOption);
    setValue("providerId", providerIdListSelectOption[0].value);
  };

  const fetchApi = useCallback(
    async ({
      keywordList,
      page,
      size,
      providerId,
      searchType,
    }: {
      page: number;
      size?: number;
      keywordList?: string;
      providerId: string;
      searchType: string;
    }) => {
      if (providerId) {
        const commonFacilityResponse = await getCommonFacilityList({
          page,
          size,
          searchType,
          keywordList: keywordList,
          isDeleted: false,
          buildingIsDeleted: false,
          sort: {
            orders: [
              {
                property: "building.buildingName",
                direction: "DESC",
              },
              {
                property: "commonFacilityType",
                direction: "DESC",
              },
              {
                property: "facilityName",
                direction: "DESC",
              },
            ],
          },
        });
        if (commonFacilityResponse.status >= 200 && commonFacilityResponse.status <= 299) {
          const commonFacilityList = commonFacilityResponse.data.data.content;
          const commonFacilityIdList = commonFacilityList.map((item) => item.id).join(",");
          const appliedPriceRulesResponse = await getAppliedPriceRulesList({
            page: 0,
            size,
            providerId,
            buildingCommonFacilityIdList: commonFacilityIdList,
            sort: {
              orders: [
                {
                  property: "id",
                  direction: "DESC",
                },
              ],
            },
          });
          if (appliedPriceRulesResponse.status >= 200 && appliedPriceRulesResponse.status <= 299) {
            const appliedPriceRulesList = appliedPriceRulesResponse.data.data.content;
            if (appliedPriceRulesList) {
              const findListForColumn = appliedPriceRulesList.map((appliedPriceRules) => {
                const findFacility = commonFacilityList.find((facility) => facility.id === appliedPriceRules.buildingCommonFacilityId);
                let columnData = {
                  buildingId: findFacility?.buildingId, // 건물ID
                  buildingName: findFacility?.buildingName, // 건물명
                  commonFacilityType: findFacility?.commonFacilityType, // 공간타입
                  facilityName: findFacility?.facilityName, // 공용공간
                  cost: appliedPriceRules.cost, // 원가
                  price: appliedPriceRules.price, //판매가
                  priceRuleType: appliedPriceRules.priceRuleType, // 기준요금 유형
                };
                return columnData;
              });
              setTableDataList(findListForColumn);
            }
            setPage({
              current: commonFacilityResponse.data.meta.pageMeta?.pageRequest.page || 0,
              total: commonFacilityResponse.data.meta.pageMeta?.totalPages || 0,
              totalElements: commonFacilityResponse.data.meta.pageMeta?.totalElements || 0,
            });
          }
        }
      }
    },
    [],
  );
  const onSubmit = ({ keywordList, providerId, searchType }: { keywordList: string; providerId: string; searchType: string }) =>
    search({ page: 0, size, keywordList, providerId, searchType });

  const search = ({
    keywordList,
    page,
    size,
    providerId,
    searchType,
  }: {
    page: number;
    size: number;
    keywordList?: string;
    providerId: string;
    searchType: string;
  }) => {
    setSize(size);
    fetchApi({ page, size, keywordList, providerId, searchType });
  };

  const goPage = (nextPage: number) =>
    search({ page: nextPage, size, providerId: getValues("providerId"), keywordList: getValues("keywordList"), searchType: getValues("searchType") });
  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchProvider({
      page: 0,
    });
  }, []);

  useEffect(() => {
    if (providerSelectOption.length > 0) {
      fetchApi({
        page: 0,
        size,
        providerId: providerSelectOption[0].value,
        keywordList: "",
        searchType: getValues("searchType"),
      });
    }
  }, [providerSelectOption, fetchApi, size, getValues]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={true}>
      <>
        <section className="base-abstract-modal__title flex-center-between">
          <h1>현재 적용된 기준요금</h1>

          <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef} className="flex-center-center">
            <div className="minmax200 mr10">
              <Controller
                name="providerId"
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    placeholder="프로바이더"
                    value={field.value}
                    stateOptions={providerSelectOption}
                    setStateValue={(value: string) => {
                      search({
                        page: 0, //
                        size,
                        providerId: value,
                        keywordList: getValues("keywordList"),
                        searchType: "FACILITY_NAME",
                      });
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </div>
            <Controller
              name="keywordList"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseInputWithSearch
                  type="text"
                  selectValue={"FACILITY_NAME"}
                  inputValue={value}
                  stateOptions={searchType}
                  isSelectDisabled={true}
                  setStateValue={(searchType: string) => {
                    setValue("searchType", searchType);
                  }}
                  onClearClick={() => {
                    setValue("keywordList", "");
                    search({
                      page: 0,
                      size,
                      // searchType: getValues("searchType"),
                      searchType: "FACILITY_NAME",
                      providerId: getValues("providerId"),
                      keywordList: "",
                    });
                  }}
                  onChange={onChange}
                  onKeyUp={() =>
                    search({
                      page: 0,
                      size,
                      searchType: "FACILITY_NAME",
                      providerId: getValues("providerId"),
                      keywordList: value,
                    })
                  }
                  onSearchClick={() =>
                    search({
                      page: 0,
                      size,
                      searchType: "FACILITY_NAME",
                      providerId: getValues("providerId"),
                      keywordList: value,
                    })
                  }
                />
              )}
            />
          </form>
        </section>
        {/* table */}
        <section className="base-abstract-modal__contents">
          <div {...getTableProps()} className="base-table view-data-table sticky  px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            pageIndex={page.current || 0}
            totalPages={page.total || 0}
            currentSize={Number(size) || 20}
            sizeOption={(size) => {
              setSize(size);
              search({
                page: 0,
                size,
                keywordList: getValues("keywordList"),
                providerId: getValues("providerId"),
                searchType: getValues("searchType"),
              });
            }}
            totalElements={page.totalElements || 0}
            goPage={goPage}
            children={
              <div className="flex-center">
                <button className="base-btn" onClick={props.onClose}>
                  확인
                </button>
              </div>
            }
          />
        </section>
      </>
    </BaseAbstractModal>
  );
};

export default AppliedBasePriceModal;
