import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { HeaderGroup, Row, useBlockLayout, useRowSelect, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { Order, PageMeta } from "src/api/public-types";
import { BaseCheckbox, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import TableRadioButton from "src/pages/product/components/TableRadioButton";

type Props = {
  pageMeta?: PageMeta;
  columns: any;
  data: any;
  title?: string;
  addedList?: any[];
  onClick: (list: any[]) => void;
  onClose: () => void;
  children?: ReactNode;
  goToPage?: (page: number) => void;
  currentSize?: (size: number) => void;
  checkboxType?: "checkbox" | "radio";
  setOrders?: Function;
  orders?: Array<Order>;
  disabledSortHeaders?: string[];
  isSearched?: boolean;
};

const IotCommonTableModal = (props: Props) => {
  // 체크박스된 데이터

  const [selectedItems, setSelectedItems] = useState<Array<any>>([]);
  const allCheckRef = useRef<HTMLInputElement>(null);
  const location = useLocation();

  /*  
  memberNo / item.id  두개로 핸들링하는 이유는, 건물/상품은 id이고, 신청계약 부분은 이용자의 회원번호로 핸들링 해야하기 때문
  아래 BaseCheckBox도 같은 맥락이다.
  */

  const newList = useMemo(() => {
    if (props.checkboxType === "checkbox") {
      return props.data.map((item: any) => {
        const selects = selectedItems.map((select: any) => Number(item.memberNo ? select.memberNo : select.id));
        return {
          checked: selects.includes(Number(item.memberNo ? item.memberNo : item.id)),
          ...item,
        };
      });
    } else {
      return props.data;
    }
  }, [props.checkboxType, props.data, selectedItems]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: props.columns,
      data: newList,
      addedList: props.addedList || [],
    },
    useBlockLayout,
    useSticky,
    useRowSelect,
    (hooks) => {
      props.checkboxType &&
        hooks.visibleColumns.push((columns: Array<any>) => [
          {
            width: 50,
            Header:
              props.checkboxType === "radio" || !location.pathname.includes("building")
                ? "선택"
                : ({ rows }) => (
                    <BaseCheckbox
                      inputRef={allCheckRef}
                      checked={rows.every((row: any) => row.original.checked)}
                      id={"device"}
                      name={"device"}
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setSelectedItems((prev) => {
                            const rowItems = rows.map((row) => {
                              return row.original;
                            });
                            const filtered = rowItems.filter((row: any) => {
                              return !prev.some((prevItem) => {
                                return prevItem.id === row.id;
                              });
                            });
                            const allSelected = [...prev, ...filtered];
                            return allSelected;
                          });
                        } else {
                          setSelectedItems((prev) => {
                            return prev.filter((item) => {
                              return !rows.some((row: Row<any>) => row.original.id === item.id);
                            });
                          });
                        }
                      }}
                    />
                  ),
            accessor: "checked",
            Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
              let currentState = row.getToggleRowSelectedProps();
              return (
                <>
                  {props.checkboxType === "checkbox" ? (
                    <BaseCheckbox
                      id={row.id}
                      name={row.id}
                      checked={row.original.checked}
                      onChange={(checked: boolean) => {
                        setSelectedItems((prev) =>
                          checked
                            ? [...prev, row.original]
                            : prev.filter((item) => {
                                return item.memberNo ? item.memberNo !== row.original.memberNo : item.id !== row.original.id;
                              }),
                        );
                      }}
                      disabled={isCheckDisabled(row.original)}
                    />
                  ) : (
                    <TableRadioButton
                      id={row.id}
                      disabled={props?.addedList?.some((value) => String(value) === String(row.original.id))}
                      onClick={() => {
                        toggleAllRowsSelected(false);
                        toggleRowSelected(row.id, true);
                        setSelectedItems([row.original]);
                      }}
                      name="selectedProvider"
                      {...currentState}
                    />
                  )}
                </>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  useEffect(() => {
    props.isSearched === true && setSelectedItems([]);
  }, [props.isSearched]);

  // header sort component
  const renderHeaderSortComponent = (header: HeaderGroup) => {
    if (isDisabledSortHeader(header)) return null;

    if (header.Header !== "기기명") return null;
    if (props.orders && props.orders.length > 0) {
      const finedHeader = props.orders.find((order: { property: string; direction: string }) => order.property === header.id);
      if (finedHeader) {
        if (finedHeader.direction === "DESC") {
          return <div className="ic_sort down"></div>;
        } else if (finedHeader.direction === "ASC") {
          return <div className="ic_sort up"></div>;
        }
      }
    }
    return <div className="ic_sort"></div>;
  };

  const isCheckDisabled = (item: any) => {
    // 건물 디바이스 기기 등록 할 때

    //건물 / 상품 iot 일 때
    if (item?.building?.location) {
      return props?.addedList?.includes(String(item.building.location.identifier));
    }
    // 계약 iot 일 때
    else if (item.memberNo) {
      return props?.addedList?.includes(String(item.memberNo));
    } else {
      return false;
    }
  };
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  // 정렬이 불가능한 header 인지 여부
  const isDisabledSortHeader = useCallback((header: HeaderGroup & { accessor?: string }): boolean => {
    let isDisabledSortHeader = false;

    if (header.accessor === "checked") isDisabledSortHeader = true;

    // iot 에선 "기기명" 만 sorting 적용
    if (header.id !== "internalDisplayName") {
      isDisabledSortHeader = true;
    }
    return isDisabledSortHeader;
  }, []);

  // 정렬 버튼 클릭
  const clickHeaderSort = (header: HeaderGroup) => {
    // 새로운 정렬
    let newOrders = [...(props.orders || [])];
    if (props.orders && props.orders.length > 0) {
      // 현재 정렬되어있는 프로퍼티일 경우 존재함
      const finedHeader = props.orders.find((order: { property: string; direction: string }) => order.property === header.id);

      if (finedHeader) {
        // 정렬되어있는 프로퍼티를 클릭했을 경우, 해당 프로퍼티 삭제
        newOrders = props.orders.filter((currentOrder: Order) => currentOrder.property !== finedHeader.property);
      }

      // 해당 프로퍼티 정렬 다시 재할당
      let newDirection = null;
      if (finedHeader?.direction) {
        // 기존 정렬 방향이 있으면
        if (finedHeader?.direction === "DESC") {
          // 내림차순이면 오름차순으로 변경
          newDirection = "ASC";
        } else {
          // 오름차순이면 정렬 해제이기 때문에 기존 정렬삭제되서 처리할게 없음
        }
      } else {
        // 기존 정렬 방향이 없으면 내림차순으로 변경
        newDirection = "DESC";
      }
      if (newDirection) {
        // newOrders = [...newOrders, { property: header.id, direction: newDirection }]; // 멀티 정렬시
        newOrders = [{ property: header.id, direction: newDirection }]; // 단일 정렬시
      }
    } else {
      // 기존에 정렬되어 있는게 없으면 클릭한 프로퍼티를 내림차순으로 변경
      newOrders = [{ property: header.id, direction: "DESC" }];
    }
    props.setOrders && props.setOrders(newOrders);
  };

  return (
    <BaseAbstractModal size="xlarge" isOpen={true}>
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>{props.title ? props.title : "항목 선택"}</h1>
        {props.children && props.children}
      </section>
      <section className="base-abstract-modal__contents">
        <div {...getTableProps()} className="base-table view-data-table sticky px30">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div
                      {...header.getHeaderProps()} //
                      className={`base-table__th ${!isDisabledSortHeader(header) ? "cursor-pointer" : ""}`}
                      onClick={() => {
                        if (!isDisabledSortHeader(header)) {
                          clickHeaderSort(header);
                        }
                      }}
                    >
                      {header.render("Header")}
                      <span>{renderHeaderSortComponent(header)}</span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {props.pageMeta ? (
        <section className="px30">
          <BasePagination
            pageIndex={props.pageMeta.pageRequest.page || 0}
            totalPages={props.pageMeta?.totalPages || 0}
            currentSize={props.pageMeta.pageRequest.size || 20}
            sizeOption={(sizeValue) => {
              props.currentSize && props.currentSize(sizeValue);
            }}
            totalElements={props.pageMeta?.totalElements || 0}
            goPage={(page: number) => {
              props.goToPage && props.goToPage(page);

              // if (allCheckRef.current) {
              //   allCheckRef.current.checked = false;
              // }
            }}
            children={
              <div className="flex-center">
                <button
                  className="base-btn color-white mr10"
                  onClick={() => {
                    props.onClose && props.onClose();
                  }}
                >
                  취소
                </button>
                <button
                  disabled={selectedItems.length > 0 ? false : true}
                  className="base-btn"
                  onClick={() => {
                    props.onClick(selectedItems);
                    props.onClose();
                  }}
                >
                  선택
                </button>
              </div>
            }
          />
        </section>
      ) : (
        <section className="pa30  flex-center-end w-100">
          <div className="flex-center">
            <button
              className="base-btn minmax80 color-white mr10"
              onClick={() => {
                props.onClose && props.onClose();
              }}
            >
              취소
            </button>
            <button
              disabled={selectedItems.length > 0 ? false : true}
              className="base-btn minmax80 "
              onClick={() => {
                props.onClick(selectedItems);
                props.onClose();
              }}
            >
              선택
            </button>
          </div>
        </section>
      )}
    </BaseAbstractModal>
  );
};

export default IotCommonTableModal;
