import { Dispatch, RefObject, SetStateAction, useState } from "react";
import { Controller } from "react-hook-form";
import { MgmtOfficerType } from "src/api/building/building-types";
import { HookFormProps, MediaFile } from "src/api/public-types";
import { WorkOrderDetailModel, WorkOrderFormType } from "src/api/work-order/workorder-types";
import { BaseButton, BaseCheckbox, BaseInput, BaseTextarea } from "src/components";
import Link from "src/components/PartnerLink";
import PublicImageUpdate from "src/components/PublicImageUpdate";
import { WorkOrderMediaType, WorkOrderStatus } from "src/pages/workOrder/workOrder-types";
import { useModal } from "src/recoil/modalState/hook";
import { RoleType } from "src/types/building";
import { YmdFormat, formatPhoneNumber } from "src/utils";
import ManagementBuildingModal from "./workOrderModal/managementBuilding/ManagementBuildingModal";
import OfficerModal from "./workOrderModal/officer/OfficerModal";
import { PagePath } from "src/pages/product/details";
import RangeDatepicker from "src/components/RangeDatepicker";
import moment from "moment";

type Props = {
  formProps: HookFormProps<WorkOrderFormType>;
  getOfficers: (mgmtOfficeId: string) => Promise<void>;
  setButtonRef: Dispatch<SetStateAction<RefObject<HTMLButtonElement> | undefined>>;
  mgmtOfficerList: MgmtOfficerType[];
  workOrderMedias: MediaFile[];
  setWorkOrderMedias: Dispatch<SetStateAction<Array<MediaFile>>>;
  workOrderDetail?: WorkOrderDetailModel;
};

const WorkInfoSection = ({ formProps, getOfficers, setButtonRef, mgmtOfficerList, workOrderMedias, setWorkOrderMedias, workOrderDetail }: Props) => {
  const { control, setValue, getValues, watch, errors } = formProps;
  const [buildingId, setBuildingId] = useState("");
  const { setBaseModal } = useModal();
  const { setAbstractModalZ1 } = useModal();
  // 담당자 input value 노출
  const officerInfoText = (officerId: string, officerList: MgmtOfficerType[]) => {
    //
    if (officerId && officerList.length > 0) {
      const officer = officerList.find((item) => item.id === officerId);
      let officerValue = "";

      if (officer?.isPrimary) {
        officerValue = `대표매니저 / ${officer.name || "-"} / ${formatPhoneNumber(officer.phone || "") || "-"}`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.MANAGER) {
        officerValue = `매니저 / ${officer?.name || "-"} / ${formatPhoneNumber(officer.phone || "") || "-"}`;
      } else if (!officer?.isPrimary && officer?.roleType === RoleType.USER) {
        officerValue = `${officer?.name || "-"} / ${formatPhoneNumber(officer.phone || "") || "-"}`;
      } else {
        return officerValue;
      }
      return officerValue;
    }
  };

  // 작업자 팝업
  const openOfficerModal = () => {
    if (!watch("mgmtOfficeId")) {
      return setBaseModal({
        isOpen: true,
        btnRightTitle: "확인",
        title: "건물/관리처를 선택해주세요.",
        onClose: () => setBaseModal({ isOpen: false }),
      });
    }

    return setAbstractModalZ1({
      isOpen: true,
      children: (
        <OfficerModal
          control={control}
          mgmtOfficerList={mgmtOfficerList}
          onSelected={(officer) => {
            setValue("mgmtOfficerId", officer.id, {
              shouldDirty: true,
              shouldValidate: true,
            });
            // setValue("")
            setValue("mgmtOfficerId", String(officer.id));
          }}
          onClose={() => setAbstractModalZ1({ isOpen: false })}
        />
      ),
    });
  };

  const openBuildingManagementModal = () => {
    return setAbstractModalZ1({
      isOpen: true,
      children: (
        <ManagementBuildingModal
          onClose={() => setAbstractModalZ1({ isOpen: false })}
          onSelected={(building) => {
            if (building) {
              const mgmtBuildingName = `${building.buildingName} / ${building.mgmtOfficeName}`;
              setBuildingId(String(building.id || ""));
              setValue("mgmtOfficeId", building.mgmtOfficeId, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setValue("buildingManagement", mgmtBuildingName);
              setValue("mgmtOfficerId", "");
              getOfficers(building?.mgmtOfficeId || "");
            }
          }}
        />
      ),
    });
  };

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title">
        <div>
          <h2 className="font18">업무 정보</h2>
        </div>
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">건물/관리처</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            {!watch("id") && <BaseButton setRef={setButtonRef} title="선택" className="color-white mr20" onClick={openBuildingManagementModal} />}
            {watch("mgmtOfficeId") && (
              <div className="flex-center">
                <Link
                  to={PagePath.building.detail.replace(":id", String(buildingId || workOrderDetail?.buildingId)) + "?tab=mgmtOffice"}
                  target="_blank"
                  className="text-underline"
                >
                  {watch("buildingManagement")}
                </Link>
                <span className="ic-target-blank"></span>
                {!watch("id") && (
                  <>
                    <button
                      className="base-remove ml5"
                      onClick={(e) => {
                        e.preventDefault();
                        setValue("mgmtOfficeId", "", {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("mgmtOfficerId", "");
                      }}
                    ></button>
                  </>
                )}
              </div>
            )}
          </div>
          <p className="validation-text">{errors?.mgmtOfficeId?.message}</p>
        </div>
        {/* 건물/관리처 팝업  */}
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">작업자</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <BaseButton
              title="선택"
              className="color-white"
              onClick={() => {
                openOfficerModal();
              }}
              disabled={watch("id") ? workOrderDetail?.status !== "WORK_ORDER_OPEN" && workOrderDetail?.status !== "WORK_ORDER_PROGRESS" : false}
            />

            {watch("mgmtOfficerId") && (
              <>
                <div className="flex-center ml20">
                  <span>{officerInfoText(watch("mgmtOfficerId")!, mgmtOfficerList)}</span>
                  {(workOrderDetail?.status === "WORK_ORDER_OPEN" || workOrderDetail?.status === "WORK_ORDER_PROGRESS") && (
                    <button
                      style={{ backgroundColor: "transparent" }}
                      className="base-remove ml5"
                      onClick={(e) => {
                        e.preventDefault();
                        setValue("mgmtOfficerId", "", { shouldDirty: true, shouldValidate: true });
                        // setValue("workOrderSheetAccessZoneList.0.mgmtOfficerId", "");
                      }}
                    ></button>
                  )}
                </div>
              </>
            )}
          </div>
          <p className="validation-text">{errors?.mgmtOfficerId?.message}</p>

          {!watch("id") && (
            <Controller
              control={control}
              name={"isSendAlarm"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <BaseCheckbox
                  id={name}
                  name={name}
                  className="mt10 minmax150"
                  checked={value || false}
                  label="신규 업무 알림톡 발송"
                  onChange={(checked: boolean) => {
                    onChange(checked);
                  }}
                />
              )}
            ></Controller>
          )}
        </div>
      </section>

      <section className="contents-container__grid ">
        <div className="contents-container__grid-index">
          <p className="required">업무 제목</p>
        </div>
        <div className="contents-container__grid-contents ">
          <div className=" flex-row flex-center">
            <Controller
              control={control}
              name={"summary"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <BaseInput
                  placeholder="제목을 입력하세요."
                  onChange={(value: string) => onChange(value)}
                  value={value}
                  name={name}
                  errorText={error?.message}
                  maxLength={45}
                />
              )}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid ">
        <div className="contents-container__grid-index">
          <p className="required">업무 내용</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <Controller
              control={control}
              name={"description"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <BaseTextarea
                  className="mr8"
                  maxLength={1000}
                  placeholder="상세 내용을 입력하세요."
                  onChange={(value: string) => onChange(value)}
                  value={value || ""}
                  name={name}
                  errorText={error?.message}
                />
              )}
            ></Controller>
          </div>
        </div>
      </section>

      {/* 이미지 등록 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>{`이미지 (${workOrderMedias.length}/3)`}</p>
        </div>
        <PublicImageUpdate
          fileMaxLength={3}
          mediaFiles={
            // watch("mediaList")?.filter((media) => media.mediaServiceType === WorkOrderMediaType.WORK)
            workOrderMedias.filter((media) => media.mediaServiceType === WorkOrderMediaType.WORK)
          }
          onChangeMediaFiles={setWorkOrderMedias}
          // onChangeMediaFiles={(medias: MediaFile[]) => {
          //   const newMedias = medias.map((media) => ({ ...media, cmdType: media.id ? "U" : "C" }));
          //   setValue("mediaList", newMedias);
          //   // setWorkOrderMedias
          // }}
          categoryName={WorkOrderMediaType.WORK}
          mediaServiceType="MEDIA_WORK_ORDER_SHEET"
        />
      </section>

      {watch("id") && (
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required ">업무 기간</p>
          </div>
          <div className="contents-container__grid-contents ">
            <div className="flex-row flex-center-start">
              <div className="minmax240">
                <RangeDatepicker
                  disabled={watch("status") !== WorkOrderStatus.OPEN}
                  dateRange={[
                    watch("startDate") ? moment(watch("startDate")).toDate() : null,
                    watch("endDate") ? moment(watch("endDate")).toDate() : null,
                  ]}
                  onChange={(date) => {
                    let start = "";
                    let end = "";

                    if (date[0] !== null) {
                      start = moment(date[0]).format(YmdFormat.WITH_TIME_ZONE);
                    }
                    if (date[1] !== null) {
                      end = moment(date[1]).endOf("day").format(YmdFormat.WITH_TIME_ZONE);
                    }

                    setValue("startDate", start);
                    setValue("endDate", end);

                    const accessZone = watch("workOrderSheetAccessZoneList.0");
                    setValue("workOrderSheetAccessZoneList.0", { ...accessZone, startDate: start, endDate: end });
                  }}
                  minDate={workOrderDetail?.workOrderSheetGroupId ? moment().toDate() : null}
                />
              </div>
            </div>
            {(errors?.startDate || errors?.endDate) && <p className="validation-text">필수입력 항목입니다</p>}
            {workOrderDetail?.startDate !== watch("startDate") && <p className="validation-text">*선택한 업무 일정에 맞춰 출입 권한이 변동됩니다.</p>}
          </div>
        </section>
      )}
    </article>
  );
};

export default WorkInfoSection;
