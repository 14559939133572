import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { Modal, ProductTypes } from "../../product-types";

import { BuildingModel } from "src/api/building/building-types";
import { updateProductAsync } from "src/api/product/product-api";
import { BaseButton, BaseDatePicker, BaseInput, BaseModal, BaseRadio, BaseTextarea } from "src/components";
import { BaseTooltip } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useToast } from "src/recoil/toast/hook";
import { onlyNumber } from "src/utils";
import { getProductDetailPath } from "src/utils/route-util";

export type FormData = {
  description: string; //설명
  // productIconTypeList: ProductIconTypeT[]; //강조 아이콘
  minLeasePeriod: string; //최소계약기간
  maxLeasePeriod: string; //최대계약기간
  isRightAwayMoveIn: boolean; //입구가능여부 즉시/날짜 선택여부
  moveInAvailDate: string; //입주가능일자
  vocUrl: string; //vocurl
  colorName: string; //색상명
  productNotice?: string;
};

interface IProps {
  product: ProductModel;
  productId: string;
}
/* 
  공간상품 등록 > 등록 or 수정 > 상품정보
 */
const ProductInfoForm = ({ product: productDetail, productId }: IProps) => {
  const themeColors = ["redg01", "redg02", "redg03", "redg04", "greyg01", "greeng01", "greeng02", "blueg01", "blueg02", "blueg03"];
  const navigate = useNavigate();

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [submitData, setSubmitData] = useState<FormData>();

  const { openToast } = useToast();

  // 공간상품 수정 Api

  const { executeAsync: updateProduct } = useApiOperation(updateProductAsync);

  const findPrimaryBuilding = (productDetail: ProductModel) => {
    let _findBuilding = undefined;

    productDetail.buildingList?.map((building) =>
      building.buildingFloorList?.map((buildingFloor) =>
        buildingFloor.buildingRoomList?.map((buildingRoom) => {
          if (buildingRoom.isPrimary === true) {
            _findBuilding = building;
          }
          return undefined;
        }),
      ),
    );

    return _findBuilding as unknown as BuildingModel | undefined;
  };

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // React Hook Form
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,

    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      description: productDetail.description ?? "", //설명
      // productIconTypeList: productDetail.productIconTypeList, //강조 아이콘
      minLeasePeriod: productDetail.minLeasePeriod ?? "", //최소계약기간
      maxLeasePeriod: productDetail.maxLeasePeriod ?? "", //최대계약기간
      isRightAwayMoveIn: productDetail.isRightAwayMoveIn ?? false, //입구가능여부 즉시/날짜 선택여부
      moveInAvailDate: productDetail.moveInAvailDate ?? "", //입주가능일자
      vocUrl: productDetail.vocUrl ?? "", //vocurl
      colorName: productDetail.colorName ?? "", //색상명
      productNotice: productDetail.productNotice ?? "",
    },
    mode: "onChange",
  });

  //  유효성 검사
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    if (productDetail.productType !== ProductTypes.TIME_COURT) {
      register("minLeasePeriod", {
        required: { value: true, message: requiredMessage },
      });
      register("maxLeasePeriod", {
        required: { value: true, message: requiredMessage },
      });

      register("moveInAvailDate", {
        validate: (value) => {
          if (watch("isRightAwayMoveIn")) {
            // 즉시 선택
            return true;
          }
          if (!value) {
            // 날짜 라디오를 선택하고 날짜를 입력 안 했을 때
            return requiredMessage;
          }
        },
      });
    }

    register("colorName", {
      required: { value: true, message: requiredMessage },
    });

    register("description", {
      required: { value: true, message: requiredMessage },
    });

    register("vocUrl", {
      validate: {
        required: (value) => {
          let message = requiredMessage;
          const pattern = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
          const isValidate = pattern.test(String(value));
          if (!isValidate) message = "http:// 또는 https:// 로 시작하는 값을 입력해주세요.";
          return isValidate || message;
        },
      },
    });
  }, [register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = async (data: FormData, e?: any) => {
    e.preventDefault();

    setSubmitData(data);

    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any) => {
    console.log("errors :>> ", errors);

    getValues();
  };

  // 정상 등록/수정 진행 (팝업 확인)
  const onClickConfirmModal = async (data: FormData) => {
    console.log(`request`, data);
    const response = await updateProduct({ product: { ...data, id: productId } });

    const result = response.data?.data?.content;
    if (result) {
      openToast({
        content: `정상적으로 수정 되었습니다.`,
      });
      navigate(
        getProductDetailPath({
          productId: productId + "?tab=productInfo",
        }),
      );
    }
  };

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)} id="productForm">
            <div className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <div className="minmax140 pb4">
                  <h2>상세정보</h2>
                </div>
              </div>

              <div className="flex-center">
                {/* 상품명 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <div className="flex-start">
                      <p className="mr4">상품명</p>
                      <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={16} tooltip="기본정보 탭에서 수정할 수 있습니다" />
                    </div>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div>
                      <span>{productDetail.productName}</span>
                    </div>
                  </div>
                </section>

                {/* 한줄 소개 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <div className="flex-start">
                      <p className="mr4">한줄 소개</p>
                      <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={16} tooltip="기본정보 탭에서 수정할 수 있습니다" />
                    </div>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div>
                      <span>{productDetail.introduce}</span>
                    </div>
                  </div>
                </section>
              </div>

              {/* 상품 소개 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">상품 소개</p>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <BaseTextarea
                          className="mr8"
                          onChange={onChange}
                          value={value}
                          name={name}
                          maxLength={1000}
                          height={144}
                          placeholder="상품의 상세 소개를 입력해 주세요."
                          errorText={errors.description?.message ?? ""}
                        />
                      );
                    }}
                  />
                </div>
              </section>

              {/* 상품 강조*/}
              {/* <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">상품 강조</p>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name="productIconTypeList"
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <div className="flex-row flex-center-start">
                          {objectKeys(ProductIconTypeLabels).map((key) => (
                            <BaseCheckbox
                              className="mr16 w-fit"
                              key={key}
                              onChange={(e) => {
                                const updatedValue = value;
                                if (e) {
                                  updatedValue.push(key);
                                } else {
                                  const index = updatedValue.indexOf(key);
                                  if (index > -1) {
                                    updatedValue.splice(index, 1);
                                  }
                                }
                                onChange(updatedValue);
                              }}
                              id={key}
                              name={name}
                              value={key}
                              checked={value?.includes(key)}
                              label={ProductIconTypeLabels[key]}
                            />
                          ))}
                        </div>
                      );
                    }}
                  />
                  <div className="my10 grey_text_12">
                    <span>• 최대 2개까지 선택 가능합니다.</span>
                  </div>
                </div>
              </section> */}
              {productDetail?.productType === ProductTypes.TIME_COURT ? null : (
                <>
                  {/* 계약기간 */}
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p className="required">계약기간</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <span className="font14 mr10">최소</span>
                        <div className="minmax60">
                          <Controller
                            control={control}
                            name="minLeasePeriod"
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return (
                                <>
                                  <BaseInput
                                    type="text"
                                    onChange={(text: string) => {
                                      const inputValue = onlyNumber(text);
                                      onChange(inputValue);
                                    }}
                                    value={value ?? ""}
                                    name={name}
                                    placeholder="6"
                                  />
                                </>
                              );
                            }}
                          ></Controller>
                        </div>
                        <span className="font14 mx10">개월 ~ 최대</span>
                        <div className="minmax60">
                          <Controller
                            control={control}
                            name="maxLeasePeriod"
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return (
                                <BaseInput
                                  type="text"
                                  onChange={(text: string) => {
                                    const inputValue = onlyNumber(text);
                                    onChange(inputValue);
                                  }}
                                  value={value ?? ""}
                                  name={name}
                                  placeholder="12"
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                        <span className="font14 ml10">개월</span>
                      </div>
                      {errors?.maxLeasePeriod && <p className="validation-text">{errors?.maxLeasePeriod?.message}</p>}
                    </div>
                  </section>
                  {/* 입주 가능일 */}
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p className="required">입주 가능일</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <Controller
                          control={control}
                          name="isRightAwayMoveIn"
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <>
                                <BaseRadio
                                  id={"moveInAvailDate1"}
                                  name={name}
                                  label="즉시"
                                  className="mr16"
                                  checked={value ? true : false}
                                  onChange={(checked: boolean, e) => {
                                    onChange(true);
                                    if (checked) {
                                      setValue("moveInAvailDate", "", { shouldValidate: true });
                                    }
                                  }}
                                />
                                <BaseRadio
                                  id={"moveInAvailDate2"}
                                  name={name}
                                  checked={!value ? true : false}
                                  onChange={(checked: boolean) => {
                                    if (checked) {
                                      onChange(false);
                                    }
                                  }}
                                />
                              </>
                            );
                          }}
                        ></Controller>

                        <div className="minmax130 ml10">
                          <Controller
                            control={control}
                            name="moveInAvailDate"
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <BaseDatePicker
                                  setDate={onChange}
                                  selectedDate={value ? moment(value).toDate() : null}
                                  name={name}
                                  readonly={watch("isRightAwayMoveIn") === true ? true : false}
                                  placeholder="기간 선택"
                                />
                              );
                            }}
                          ></Controller>
                        </div>
                      </div>

                      {errors?.moveInAvailDate && <p className="validation-text">{errors?.moveInAvailDate?.message}</p>}
                    </div>
                  </section>
                </>
              )}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <div>
                    <p className="mr4">위치안내</p>
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <span>{findPrimaryBuilding(productDetail)?.addressList?.[0].address ?? "-"}</span>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <div>
                    <p className="mr4">유의사항</p>
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name="productNotice"
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <BaseTextarea
                          className="mr8"
                          onChange={onChange}
                          value={value}
                          name={name}
                          maxLength={1000}
                          height={144}
                          placeholder="유의사항을 입력해 주세요."
                        />
                      );
                    }}
                  />
                </div>
              </section>
            </div>

            <div className="contents-container__wrap-contents">
              <div className="contents-container__sub-title">
                <div className="minmax140 pb4">
                  <h2>고객센터</h2>
                </div>
              </div>

              {/* VOC 링크 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">문의 채널</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400 flex-row flex-center">
                    <Controller
                      control={control}
                      name="vocUrl"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          type="text"
                          onChange={onChange}
                          value={`${value}`}
                          name={name}
                          errorText={error?.message}
                          placeholder="문의채널 URL를 입력해 주세요."
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>

            <div className="contents-container__wrap-contents">
              <div className="contents-container__sub-title">
                <div className="minmax140 pb4">
                  <h2>Taap 홈화면</h2>
                </div>
              </div>

              {/* 테마색상 */}
              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">테마색상</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row d-flex justify-contents-start flex-start product-theme">
                    <Controller
                      control={control}
                      name="colorName"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <>
                            <div className={`product-theme__selected ${value}`}></div>
                            <div className="product-theme__colors">
                              {themeColors.map((colorCode: string) => (
                                <div key={colorCode} className={`colors ${colorCode}`} onClick={() => onChange(colorCode)}></div>
                              ))}
                            </div>
                          </>
                        );
                      }}
                    ></Controller>
                  </div>
                  <p className="font12 text-gray500">* Taap 홈 화면 상단 상품 영역에 적용됩니다.</p>
                  {errors.colorName && <p className="validation-text">{`필수선택 항목입니다`}</p>}
                </div>
              </div>
            </div>
          </form>
          {alertModal.isOpen && (
            <BaseModal
              isOpen={true}
              btnRightTitle="확인"
              onClick={() => {
                setAlertModal({ isOpen: false });
                setFocus(alertModal.payload);
              }}
            >
              <span>{alertModal.message}</span>
            </BaseModal>
          )}

          {confirmModal.isOpen && (
            <BaseModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="확인"
              onClose={() => setConfirmModal({ isOpen: false })}
              onClick={() => submitData && onClickConfirmModal(submitData)}
              title={confirmModal.message}
            ></BaseModal>
          )}
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          <BaseButton
            title="수정취소"
            className="mr10 color-white size-large"
            onClick={() => {
              navigate(
                getProductDetailPath({
                  productId: productId ? String(productId) + "?tab=productInfo" : "",
                }),
              );
            }}
          />
          <BaseButton type="submit" title="저장" className=" size-large" form="productForm" />
        </div>
      </div>
    </>
  );
};
export default ProductInfoForm;
