import { AxiosInstance, AxiosResponse } from "axios";
import { NoticeModel, NoticeListParams } from "./notice-types";
import { ApiResponse, ApiResponseMeta } from "../public-types";
import qs from "qs";
import { ApiPath } from "..";

/* 
  RPB01. 공지사항 목록 조회
*/
export async function getNoticeListAsync(
  axios: AxiosInstance,
  params?: NoticeListParams,
): Promise<AxiosResponse<ApiResponse<{ rows: NoticeModel[] }>>> {
  return await axios.get<ApiResponse<{ rows: NoticeModel[] }>>(ApiPath.notice.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RET02. 공지사항 상세 조회
*/
export async function getNoticeDetailAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ notice: NoticeModel }>>> {
  if (!params?.id) {
    throw Error("id is not found.");
  }
  const id = params.id.toString();
  return await axios.get(ApiPath.notice.detail.replace("{id}", id), { params });
}

/* 
  RET05. 공지사항 삭제
*/
export async function deleteNoticeAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ notice: { id: number } }>>> {
  const path = ApiPath.notice.delete.replace("{id}", params!.id.toString());
  return await axios.delete(path);
}

/*
  RET06. 공지사항 푸쉬 알림 전송
*/
export async function postAppPushNotificationAsync(
  axios: AxiosInstance,
  params?: {
    id:string;
  },
): Promise<AxiosResponse<ApiResponse<{ notice?: {id:string}}>>> {
  if(!params?.id){
    throw Error('params.id not founded')
  }
  const path = ApiPath.notice.pushNotification.replace("{id}", params!.id.toString());
  return await axios.post(path);
}

/*
  RET03. 공지사항 등록
*/
export async function postNoticeAsync(
  axios: AxiosInstance,
  params?: {
    notice: NoticeModel;
  },
): Promise<AxiosResponse<ApiResponse<{ notice: Array<NoticeModel>; medias: any }>>> {
  return await axios.post(ApiPath.notice.add, params);
}
/*
  RET04. 공지사항 수정
*/
export async function putNoticeAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
    notice: NoticeModel;
  },
): Promise<AxiosResponse<ApiResponse<{ notice: NoticeModel; medias: any }>>> {
  const payload = {
    notice:params?.notice
  }
  return await axios.put(ApiPath.notice.edit.replace("{id}", params!.id.toString()), payload);
}
