import React from "react";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { BuildingModel } from "src/api/building/building-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";

const BuildingManagementColumns = [
  {
    Header: "건물ID",
    accessor: "id",
    width: 70,
    Cell: ({ row }: Cell<BuildingModel>) => {
      return (
        <div>
          <span>{row?.original?.id}</span>
        </div>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 250,
    Cell: ({ row }: Cell<BuildingModel>) => {
      const buildingName = row.original.buildingName;
      return (
        <div className="text-left w-100 flex-center">
          <Link className="text-underline" target={"_blank"} to={PagePath.building.detail.replace(":id", String(row.original.id))}>
            <BaseTooltip contents={buildingName} />
          </Link>
          <span className="ic-target-blank"></span>
        </div>
      );
    },
  },
  {
    Header: "주소",
    accessor: "address",
    width: 280,
    Cell: ({ value }: Cell<BuildingModel>) => <BaseTooltip contents={value} />,
  },
  {
    Header: "관리처ID",
    accessor: "mgmtProviderId",
    width: 80,
    Cell: ({ row }: Cell<BuildingModel>) => {
      return <span>{row?.original?.mgmtProviderId !== "0" ? row?.original?.mgmtProviderId : "-"}</span>;
    },
  },
  {
    Header: "관리처명",
    accessor: "mgmtOfficeName",
    width: 200,
    Cell: ({ row }: Cell<BuildingModel>) => {
      return <BaseTooltip contents={row?.original?.mgmtOfficeName ? row?.original?.mgmtOfficeName : "-"} />;
    },
  },
];

export default BuildingManagementColumns;
