import { useAuth0 } from "@auth0/auth0-react";
import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { axiosInstance, axiosInstanceNoLoading } from "src/utils";

/*
  이 컴포넌트는 axios 인스턴스에 인증 토큰을 주입하는 역할을 합니다.
  인증 토큰이 만료된 경우 인증 토큰을 갱신합니다.
  TODO: async/await 필요 없음
  TODO: useLayoutEffect를 사용하면 될듯
*/
function AuthInitializer({ children }: { children: React.ReactNode }) {
  const { getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { openErrorModal } = useErrorModal();
  const [isDone, setIsDone] = useState(false);

  const setAxiosRequestInterceptor = (axios: AxiosInstance) => {
    return new Promise((resolve) => {
      const id = axios.interceptors.request.use(async (config) => {
        await getAccessTokenSilently().catch((error) => {
          console.log("getAccessToken error", error);
          openErrorModal({ statusCode: 401 });
        });
        const response = await getIdTokenClaims();
        const idToken = response?.__raw;
        if (config.headers) config.headers.Authorization = `Bearer ${idToken}`;
        return config;
      }, Promise.reject);
      resolve(id);
    });
  };

  const setAxiosResponseInterceptor = (axios: AxiosInstance) => {
    return new Promise((resolve) => {
      const id = axios.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          const originalRequest = error.config;

          if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await getAccessTokenSilently();
            const response = await getIdTokenClaims();
            const idToken = response?.__raw;

            if (originalRequest.headers) originalRequest.headers.Authorization = `Bearer ${idToken}`;
            return axios(originalRequest);
          }

          return Promise.reject(error);
        },
      );

      resolve(id);
    });
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    setIsDone(false);

    (async () => {
      const axiosRequestInterceptor = await setAxiosRequestInterceptor(axiosInstance);
      const axiosResponseInterceptor = await setAxiosResponseInterceptor(axiosInstance);
      const noLoadingAxiosRequestInterceptor = await setAxiosRequestInterceptor(axiosInstanceNoLoading);
      const noLoadingAxiosResponseInterceptor = await setAxiosResponseInterceptor(axiosInstanceNoLoading);

      setIsDone(true);

      return () => {
        axiosInstance.interceptors.request.eject(axiosRequestInterceptor as number);
        axiosInstance.interceptors.response.eject(axiosResponseInterceptor as number);
        axiosInstanceNoLoading.interceptors.request.eject(noLoadingAxiosRequestInterceptor as number);
        axiosInstanceNoLoading.interceptors.response.eject(noLoadingAxiosResponseInterceptor as number);
      };
    })();
  }, [isAuthenticated]);

  if (!isDone) return null;

  return <>{children}</>;
}
export default AuthInitializer;
