import { Order, PageMeta, Sort } from "../public-types";

enum MemberStatus {
  MEMBER_INVITE = "MEMBER_INVITE",
  MEMBER_USE = "MEMBER_USE",
  MEMBER_DELETE = "MEMBER_DELETE",
  MEMBER_CLOSE = "MEMBER_CLOSE",
  MEMBER_EXPIRE = "MEMBER_EXPIRE",
}

interface Search {
  page?: number;
  size?: number;
  sort?: Sort;
  searchType?: string;
  searchValue?: string;
  memberStatus?: string;
  contractApplyNumber?: string;
  watchingCalendar?: boolean;
  partnerId?: string;
}

interface GetUSerListModel {
  data: {
    data: UserListModel[];
    meta: {
      pageMeta: PageMeta;
    };
  };
}

interface UserListModel {
  contractMemberId: number;
  contractManageId: number;
  contractApplyNumber: string;
  inviteMobileNumber: string;
  memberNo: string;
  isContractor: boolean;
  isAdmin: boolean;
  inviteTime: string;
  memberStatus: string;
  useTime: string;
  isDeleted: string;
  spaceProductId: string;
  spaceProductName: string;
  signUpTime: string;
  email: string;
  phoneNumber: string;
  nickName: string;
}

interface UserInsertModel {
  inviteMobileNumber: string;
}

interface UserDetailContent {
  contractMemberId: number;
  inviteMobileNumber: string;
  memberNo: string;
  isContractor: boolean;
  isAdmin: boolean;
  inviteDate: string;
  memberStatus: string;
  useTime: number;
  isDeleted: boolean;
  isWatchingCalendar: boolean;
  inviteeMemberId?: string;
  contractId?: number | string;
  contractManageId?: number | string;
}

interface UserDetailHr {
  signUpTime: string;
  phoneNumber: string;
  email: string;
  nickname: string;
  issueOccurredDate: string;
  isEmailVerified?: boolean;
  contractId: number;
  isWatchingCalendar: boolean;
  isExistsIssues: boolean;
  isResolvable: boolean;
}

interface UserDetailInvietee {
  inviteeMemberId: number;
  inviteeMemberNo: string;
  inviteeEmail: string;
  inviteePhoneNumber: string;
}

interface UserDetailModel {
  content: UserDetailContent;
  hrUser: UserDetailHr;
  inviteeInfo: UserDetailInvietee;
}
export { MemberStatus };
export type { Search, UserListModel, UserInsertModel, UserDetailInvietee, UserDetailModel, GetUSerListModel };

export interface GetUsersParams {
  contractApplyNumber: string;
  memberStatus?: string;
  searchType?: string;
  searchValue?: string;
  watchingCalendar?: boolean;
  partnerId?: string;
  page?: number;
  size?: number;
  sort?: Sort;
}

export interface GetIssueCountCalendar {
  contractManageId: number;
}

export interface GetIssueCountCalendarResponse {
  issueOccurredChannelsCount: number;
}
