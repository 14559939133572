import { numberToStringWithComma } from "src/utils";

export const columnFirst: any = [
  {
    Header: "위치",
    sticky: "left",
    accessor: "floorName",
    columns: [
      {
        Header: "",
        accessor: "floorName",
        width: 90,
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && <div className="ic_ho"></div>}
          {value && value}
        </>
      );
    },
    Footer: ({ row }: any) => {},
  },
  {
    Header: "호실",
    accessor: "roomName",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "roomName",
      },
    ],
    Cell: ({ value, row }: any) => {
      return (
        <>
          {!value && ""}
          {value && value}
        </>
      );
    },
    Footer: ({ row }: any) => {},
    width: 80,
  },
  {
    Header: () => {
      return <p>구분</p>;
    },
    accessor: "priceUnit",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "priceUnitName",
        Cell: ({ value, row }: any) => {
          return (
            <>
              {!value && ""}
              {value && value}
            </>
          );
        },
        Footer: ({ row }: any) => {
          return <p>합계 :</p>;
        },
      },
    ],
    Footer: ({ row }: any) => {},
    width: 80,
  },
  {
    Header: () => {
      return <p>소유주/계약자명</p>;
    },
    accessor: "level1",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "level1Name",
        width: 120,

        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {value && row.original.level1Name + " / " + row.original.level2Name}
              </span>
            </>
          );
        },
        Footer: ({ row }: any) => {},
      },
    ],
    Footer: ({ row }: any) => {},
  },
];

export const columnLast: any = [
  {
    Header: "합계",
    sticky: "right",
    width: 120,
    accessor: "totalAmount",
    columns: [{ Header: "", accessor: "totalAmount" }],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && <div className="ic_ho"></div>}
          {value && value}
        </>
      );
    },
    Footer: ({ row }: any) => {},
  },
  {
    Header: "수정",
    sticky: "right",
    accessor: "editable",
    columns: [
      {
        Header: "",
        accessor: "editable",
        width: 90,
      },
    ],
    Cell: ({ value }: any) => {
      return <></>;
    },
    Footer: ({ row }: any) => {},
  },
];
