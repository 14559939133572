import _ from "lodash";
import { useEffect, useState } from "react";
import { ContractDetail, ContractMedia, disabledEsignEdit } from "src/api/contract/contract-types";
import { SaveMediaDto } from "src/api/organization/org-types";
import ContractImageUpload from "./ContractImageUpload";
interface Props {
  contract: ContractDetail;
  editContract: ContractDetail;
  setEditContract: any;
}

const DirectFileUpload = ({ contract, editContract, setEditContract }: Props) => {
  const [uploadMediaFileMain, setUploadedMediaFileMain] = useState<SaveMediaDto[]>([]);
  const [uploadMediaFileSub, setUploadedMediaFileSub] = useState<SaveMediaDto[]>([]);

  useEffect(() => {
    if (editContract && editContract.mediaList) {
      const main = editContract?.mediaList?.filter((media: ContractMedia) => media.serviceTag === "CONTRACT_DOCUMENT") as any[];
      const sub = editContract?.mediaList?.filter((media: ContractMedia) => media.serviceTag !== "CONTRACT_DOCUMENT") as any[];

      setUploadedMediaFileMain(main);
      setUploadedMediaFileSub(sub);
    }
  }, [editContract]);

  return (
    <div className="flex-start">
      {/* 계약서 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>{`계약서 (${uploadMediaFileMain.length}/10)`}</p>
        </div>
        <ContractImageUpload
          type="PROOF"
          serviceTag={"CONTRACT_DOCUMENT"}
          fileMaxLength={10}
          images={uploadMediaFileMain}
          setImages={(files: any) => {
            const temp = _.cloneDeep(editContract!);

            const sub = temp?.mediaList?.filter((media: ContractMedia) => media.serviceTag !== "CONTRACT_DOCUMENT") as any[];
            temp.mediaList = [...(files as any[]), ...(sub as any[])];

            setUploadedMediaFileMain(files);
            temp.mediaList.forEach((mediaFile: ContractMedia, index: number) => {
              mediaFile["orderNums"] = index + 1;
              if (!mediaFile.id) {
                mediaFile["cmdType"] = "C";
              }
              if (index === 0) {
                mediaFile["isPrimary"] = true;
              } else {
                mediaFile["isPrimary"] = false;
              }
            });
            setEditContract(temp);
          }}
          disabled={disabledEsignEdit.includes(contract?.contractStep!)}
        />
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>{`계약 필요 서류 (${uploadMediaFileSub.length}/10)`}</p>
        </div>
        <div className="contents-container__grid-contents">
          <ContractImageUpload
            type="PROOF"
            serviceTag={"CONTRACT_REQUIRED"}
            fileMaxLength={10}
            images={uploadMediaFileSub}
            setImages={(files) => {
              setUploadedMediaFileSub(files);
              const temp = _.cloneDeep(editContract!);

              const main = editContract?.mediaList?.filter((media: ContractMedia) => media.serviceTag === "CONTRACT_DOCUMENT") as any[];
              temp.mediaList = [...(files as any[]), ...(main as any[])];

              setUploadedMediaFileMain(files);
              temp.mediaList.forEach((mediaFile: ContractMedia, index: number) => {
                mediaFile["orderNums"] = index + 1;
                if (!mediaFile.id) {
                  mediaFile["cmdType"] = "C";
                }
                if (index === 0) {
                  mediaFile["isPrimary"] = true;
                } else {
                  mediaFile["isPrimary"] = false;
                }
              });
              setEditContract(temp);
            }}
            disabled={disabledEsignEdit.includes(contract?.contractStep!)}
          />
        </div>
      </section>
    </div>
  );
};
export default DirectFileUpload;
