import React from "react";

import { atom, useRecoilState } from "recoil";
import { globalEditState } from "./atom";

const useAdminMemoState = () => {
  /** 관리자메모의 리스트중 하나라도 편집 상태면 true */
  const [globalIsEdit, setGlobalIsEdit] = useRecoilState(globalEditState);

  return { globalIsEdit, setGlobalIsEdit };
};

export default useAdminMemoState;
