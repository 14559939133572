import { DefaultOptions, useQuery } from "@tanstack/react-query";

import { getProductDetailAsync } from "src/api/product/product-api";
import { axiosInstance } from "src/utils";

/**
 *
 * @param param defaultOptions:DefaultOptions["queries"]
 * @returns ProductModel
 */
const useGetProduct = ({ productId, defaultOptions }: { productId?: string; defaultOptions?: DefaultOptions["queries"] }) => {
  const _defaultOptions = defaultOptions as unknown as Object;
  const query = useQuery({
    queryKey: ["getProductDetailAsync", productId],
    queryFn: async () => (productId ? (await getProductDetailAsync(axiosInstance, { productId: +productId })).data?.data?.content : undefined),
    enabled: !!productId,
    retryOnMount: false,
    staleTime: 5000,
    refetchOnMount: false,
    ...(_defaultOptions && _defaultOptions),
  });

  return { ...query };
};

export default useGetProduct;
