export interface IMenu {
  title: string;
  path: string;
  count: number;
  type?: string; // detail 타입은 상세 내용이 들어있는 박스 형태
  data?: any; // 3개씩 호출한 데이터
  showDateProperty?: string; // 화면에 보여줄 날짜 프로퍼티명
  status?: any; //상담관리 유니온타입 있어서 any로 변경
  sectionName?: string;
}

export const doubleType = {
  bar: 'bar',
  detail: "detail",
} as const

export interface IMenuDoubleSection {
  type: "bar" | "detail";
  element: IMenu[]
}

export interface ISection {
  title: string;
  doubleSection: IMenuDoubleSection[];
  totalPath: string;
  sectionName?: string;
}

export type ColorType = "Grass" | "Track" | "Wooden" | "Clay" | "Ivory" | "Default";
export type ChipOptions = "today" | "sevenDays" | "sevenDaysAfter" | "oneMonth" | "oneMonthAfter";

export interface DashboardChip {
  today: boolean;
  sevenDays: boolean;
  sevenDaysAfter: boolean;
  oneMonth: boolean;
  oneMonthAfter: boolean;
  // oneYear: boolean;
}
