import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { getBuildingsAsync } from "src/api/building/building-api";
import useApiOperation from "src/api/hooks/api-operation";
import { BaseInput, BaseRadio } from "src/components";
import TableSelectModal from "src/components/TableSelectModal";

type Building = {
  id?: number;
  buildingId: number;
  buildingName: string;
};

interface Props {
  onCanceled: () => void;
  onAdded: (selected: Building[]) => void;
  defaultChecked: Building[];
}

interface CellProps extends Cell<Building> {
  checked: boolean;
}

const TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const buildingId = props.row.original.id;
      let disabled = false
      return (
        <div data-data-id={buildingId} data-checked={props.value} className="checkbox" data-disabled={disabled}>
          <BaseRadio id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "건물id",
    accessor: "id",
    width: 100,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 340,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;

      return (
        <div className="w-100 text-left">
          {props.value}
        </div>
      );
    },
  },
  {
    Header: "주소",
    accessor: "address",
    width: 420,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
];

function BuildingSelectModal({ onCanceled, onAdded, defaultChecked }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [buildingList, setBuildingList] = useState<any[]>([]);
  const [selectedBuildingList, setSelectedBuildingList] = useState<Building[]>(defaultChecked);
  const { handleSubmit, getValues, control } = useForm<{ keyword: string }>();
  const [size, setSize] = useState(20)

  const searchFormRef = useRef<HTMLFormElement>(null);

  const buildingListTableData = buildingList.map((building) => {
    const selectedIdList = selectedBuildingList?.map((building) => building.buildingId);

    return {
      checked: selectedIdList.includes(building.id),
      ...building,
    };
  });

  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  const search = ({ keyword, page, size }: { page: number; size:number; keyword?: string; }) => {
    setSize(size);
    fetchBuildingList({ page, size, keyword });
  };

  const onSubmit = ({ keyword }: { keyword: string }) => search({ page: 0, size, keyword });
  const goPage = (nextPage: number) =>
    search({ page: nextPage, size, keyword: getValues("keyword") });

  const fetchBuildingList = async ({ keyword, page, size }: { page: number; size?:number; keyword?: string; }) => {
    const result = await getBuildingList({
      page,
      keyword,
      size,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    setBuildingList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const select = (id: string) => {

    const selectedBuilding = buildingList.find((building) => String(building.id) === id);

    setSelectedBuildingList([
      // ...selectedBuildingList,
      // 멀티셀렉일때 주석해제
      {
        buildingId: selectedBuilding?.id || 0,
        buildingName: selectedBuilding?.buildingName || "",
      },
    ]);

  };
  const unSelect = (buildingId: string) => {
    const filteredBuildingList = selectedBuildingList.filter((building) => String(building.buildingId) !== buildingId);
    setSelectedBuildingList(filteredBuildingList);
  };
  const _onAdded = () => onAdded(selectedBuildingList);

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchBuildingList({
      page: 0,
      size,
      keyword: "",
    });
  }, []);

  return (
    <TableSelectModal<any>
      className=""
      onCanceled={onCanceled}
      onAdded={_onAdded}
      goPage={goPage}
      page={page}
      columns={TABLE_COLUMNS}
      items={buildingListTableData}
      title={"건물"}
      select={select}
      unSelect={unSelect}
      sizeOption={(size) => {
        setSize(size);
        search({ page: 0, size, keyword: getValues("keyword")});
      }}
      currentSize={size}
      titleFormRender={() => {
        return (
          <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef} className="flex-center-center">
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                  onKeyUp={handleSearchClick}
                  clearable
                />
              )}
            />
          </form>
        );
      }}
    />
  );
}
export default BuildingSelectModal;
