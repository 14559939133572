import { useEffect } from "react";
import { DetailTotalData } from "src/api/contract/contract-types";
import GoToListButton from "src/components/GoToListButton";
import FOType from "./component/FOType";
import ServiceType from "./component/ServiceType";
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setData: Function;
}
const ContractDetail = ({ detailData, setData }: ContractBasicProp) => {
  const { contractManage } = detailData;
  const spaceProductType = contractManage?.spaceProductType;

  //   const ProductTypeLabels = {
  //   FULL_COURT: "공간임대", //F2
  //   TIME_COURT: "공간예약", //S2
  //   NON_RESIDENT_OFFICE: "비상주", //V
  //   MAINTENANCE_FEE: "관리비", //M
  //   DIGITAL_PRODUCT: "디지털", //D
  // } as const;

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {(spaceProductType === "FULL_COURT" || spaceProductType === "OPEN_COURT" || spaceProductType === "NON_RESIDENT_OFFICE") && (
            <FOType detailData={detailData} setData={setData} />
          )}
          {spaceProductType === "TIME_COURT" && <ServiceType detailData={detailData} setData={setData} />}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default ContractDetail;
