import { Cell } from "react-table";
import { UserListModel } from "src/api/user/user-types";
import { formatPhoneNumber } from "src/utils";

const DeviceControlMembersColumn: any[] = [
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 200,
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 180,
    Cell: ({ value }: Cell<UserListModel>) => <span>{formatPhoneNumber(value)}</span>,
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 240,
  },
  {
    Header: "닉네임",
    accessor: "nickname",
    width: 180,
  },
];

export default DeviceControlMembersColumn;
