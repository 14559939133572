import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { DeskSection, MeetingRoomSection, ParkingSection, RefreshRoomSection } from "./components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

/* 
  건물 > 등록 or 수정 > 공용공간
 */
const FacilityForm = () => {
  // 로딩바
  //

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 건물 상세 조회 (수정일 경우)
  const buildingId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [building, setBuilding] = useState<BuildingModel | null>(null);
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 상세조회 후 데이터 바인딩
  const fetchBuilding = useCallback(
    async (buildingId: number) => {
      //
      setBuilding(null);
      const { data } = await getBuilding({ buildingId });
      setBuilding(data?.data?.content?.building || null);
      //
    },
    [getBuilding],
  );

  useEffect(() => {
    if (buildingId) {
      fetchBuilding(buildingId);
    }
  }, [buildingId, fetchBuilding]);

  // title 에 건물명 바인딩
  let buildingName;
  if (buildingId && building?.buildingName) {
    buildingName = building?.buildingName;
  }

  return (
    <>
      <MetaTag subTitle={buildingName} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {/* 주차 */}
          <ParkingSection building={building} fetchBuilding={fetchBuilding} />
          {/* 회의실 */}
          <MeetingRoomSection building={building} fetchBuilding={fetchBuilding} />
          {/* 좌석 */}
          <DeskSection building={building} fetchBuilding={fetchBuilding} />
          {/* 편의시설 */}
          <RefreshRoomSection building={building} fetchBuilding={fetchBuilding} />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          <BaseButton
            title={"수정취소"}
            className="color-white size-large"
            onClick={() => {
              navigate(`${PagePath.building.detail.replace(":id", String(buildingId))}?tab=facility`);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FacilityForm;
