
type Props = {
  className?: string;
  value: string;
};

const BaseEmail = ({ className, value }: Props) => {
  return (
    <p className={className ? `${className} text-left w-100 break-all` : `text-left w-100 break-all`}>
      {value}
    </p>
  );
};

export default BaseEmail;
