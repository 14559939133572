import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getBuildingAsync } from "src/api/building/building-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, Modal } from "src/api/public-types";
import { getConnectedInfoListAsync, questionNotifyAsync } from "src/api/question/qeustion-api";
import {
  QuestionAnswerModel,
  QuestionConnectedInfoListResponse,
  QuestionDetailModel,
  QuestionNotifyMessageType,
  QuestionSite,
} from "src/api/question/question-type";
import { BaseButton, BaseModal } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import { YmdFormat, formatPhoneNumber, numberToStringWithComma } from "src/utils";
import { answerGubunOptions, siteOptions } from "../question-types";
import ReplyDetailSection from "./components/ReplyDetailSection";
import ReplyFormSection from "./components/ReplyFormSection";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  token: string;
  questionDetail: QuestionDetailModel;
  questionDetailMedias: MediaFile[];
  questionAnswerMedias: MediaFile[];
  fetchQuestionDetail: (id: number) => Promise<void>;
};

const BasicInfoDetail = ({ questionDetail, questionDetailMedias, fetchQuestionDetail, token, questionAnswerMedias }: Props) => {
  const { isAuthority } = usePartnerAuthority();

  const { id: questionId } = useParams();
  const navigate = useNavigate();
  const [buildingName, setBuildingName] = useState<string>();
  const [workOrder, setWorkOrder] = useState<QuestionConnectedInfoListResponse["content"]>();
  const [contract, setContract] = useState<QuestionConnectedInfoListResponse["content"]>();

  // Taap 유입건인지 확인
  const isFromTaap = questionDetail?.site === QuestionSite.TAAP;

  const isFromTaapVoC = isFromTaap && questionDetail.csCategoryType === "VoC";
  const isFromTaapAppUse = isFromTaap && questionDetail.csTypeName === "앱사용문의";

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 상담관리 연결정보 조회
  const { executeAsync: getConnectedInfoList } = useApiOperation(getConnectedInfoListAsync);

  useEffect(() => {
    // 빌딩명 호출
    const fetchBuilding = async (id: number) => {
      const { data } = await getBuilding({ buildingId: id });

      setBuildingName(data?.data?.content?.building?.buildingName);
    };
    // 연결된 신청계약 / 워크오더 호출
    const fetchConnectedInfoList = async (questionId: string) => {
      const { data, status } = await getConnectedInfoList({ questionId });
      if (status >= 200 && status <= 299) {
        const connectedInfoList = data?.data?.content;

        const workOrder = connectedInfoList?.filter((data) => data.connectedType === "CS_CONNECTED_WORKORDER");

        const contract = connectedInfoList?.filter((data) => data.connectedType === "CS_CONNECTED_CONTRACT");

        setWorkOrder(workOrder);
        setContract(contract);
      }
    };

    if (questionDetail?.id) {
      fetchConnectedInfoList(questionDetail.id);
    }

    if (questionDetail?.buildingId) {
      fetchBuilding(+questionDetail?.buildingId);
    }
  }, [getBuilding, questionDetail, questionDetail?.buildingId, questionDetail?.id, getConnectedInfoList]);

  // 토스트
  const { openToast } = useToast();

  // 에러모달
  const { openErrorModal } = useErrorModal();

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [plusAnswerLength, setPlusAnswerLength] = useState(0);

  // 유입 경로
  const site = useMemo(() => {
    const _site = siteOptions.find((item) => item.value === questionDetail?.site);
    return _site?.label || "-";
  }, [questionDetail]);

  const questionAnswerList = useMemo(
    () => questionDetail?.answerList?.sort((a: QuestionAnswerModel, b: QuestionAnswerModel) => Number(b.id) - Number(a.id)),
    [questionDetail],
  );

  // 알림톡 발송
  const { executeAsync: questionNotify } = useApiOperation(questionNotifyAsync, { noHandleError: true });

  // 더보기 클릭시 계산
  const showMoreAnswerList = (answerList: QuestionAnswerModel[]) => {
    if (answerList && answerList?.length > plusAnswerLength) {
      let showLength = 0;
      if (answerList.length - (plusAnswerLength + 1) > 5) {
        showLength = 5;
      } else {
        showLength = answerList.length - (plusAnswerLength + 1);
      }
      setPlusAnswerLength((prev) => prev + showLength);
    }
  };

  // 알림톡 / 이메일 발송 validation 확인
  const isValidateNotification = useCallback(
    (messageTypes: QuestionNotifyMessageType[]) => {
      let isValidated = true;

      if (messageTypes.length === 0 || (messageTypes.length === 1 && messageTypes[0] === "MESSAGE_KAKAOTALK")) {
        // 알림톡 발송 체크 true && 이메일 발송 체크 false 일때
        setAlertModal({ isOpen: true, title: "이메일 발송 체크해주세요." });
        isValidated = false;
        return;
      }
      if (messageTypes.length > 0) {
        messageTypes.forEach((type) => {
          if (type === "MESSAGE_EMAIL" && !questionDetail.reporterEmail) {
            setAlertModal({ isOpen: true, title: "이메일을 발송할 수 없습니다.", message: "문의자의 이메일을 등록해주세요." });
            isValidated = false;
            return;
          }

          if (type === "MESSAGE_KAKAOTALK" && !questionDetail.reporterPhone) {
            setAlertModal({ isOpen: true, title: "알림톡을 발송할 수 없습니다.", message: "문의자의 휴대폰을 등록해주세요." });
            isValidated = false;
            return;
          }
        });
      }
      return isValidated;
    },
    [questionDetail],
  );

  // 문의답변 등록 api

  // 문의답변 상세목록 - 재발송 api
  const sendNotification = useCallback(
    async (messageTypes: QuestionNotifyMessageType[], answerId?: string) => {
      // let result;
      const isValidted = isValidateNotification(messageTypes);

      if (isValidted === true) {
        if (messageTypes.length === 1) {
          try {
            const notifyResponse = await questionNotify({
              id: Number(questionId),
              messageType: messageTypes[0],
              answerId: String(answerId),
            });

            if (notifyResponse.status >= 200 && notifyResponse.status <= 299) {
              await fetchQuestionDetail(Number(questionId));
              openToast({ content: `${messageTypes[0] === "MESSAGE_EMAIL" ? "이메일" : "알림톡"}이 정상적으로 발송되었습니다.` });
            } else if (notifyResponse.status > 299) {
              const errorCode = notifyResponse.data?.meta?.errorCode;
              openErrorModal({
                errorCode,
                statusCode: notifyResponse.status,
                errorMessage: `${messageTypes[0] === "MESSAGE_EMAIL" ? "이메일" : "알림톡"} 발송이 실패하였습니다.\n 재 발송해 해주세요.`,
              });
            }
          } catch (error) {
            // 에러 처리 로직 추가
            console.error("Error occurred:", error);
          }
        } else if (messageTypes.length > 1) {
          const promises = messageTypes.map((messageType) =>
            questionNotify({
              id: Number(questionId),
              messageType: messageType,
              answerId: String(answerId),
            }),
          );

          try {
            const [notifyResponse, emailResponse] = await Promise.all(promises);

            if (notifyResponse.status >= 200 && notifyResponse.status <= 299) {
              await fetchQuestionDetail(Number(questionId));
              openToast({ content: "알림톡이 정상적으로 발송되었습니다." });
            } else if (emailResponse.status >= 200 && emailResponse.status <= 299) {
              await fetchQuestionDetail(Number(questionId));
              openToast({ content: "이메일이 정상적으로 발송되었습니다." });
            }

            if (notifyResponse.status > 299) {
              const errorCode = notifyResponse.data?.meta?.errorCode;
              openErrorModal({
                errorCode,
                statusCode: notifyResponse.status,
                errorMessage: `알림톡 발송이 실패하였습니다.\n 재 발송해 해주세요.`,
              });
            } else if (emailResponse.status > 299) {
              const errorCode = emailResponse.data?.meta?.errorCode;
              openErrorModal({
                errorCode,
                statusCode: emailResponse.status,
                errorMessage: `이메일 발송이 실패하였습니다.\n 재 발송해 해주세요.`,
              });
            }
          } catch (error: any) {
            // 하나 이상의 작업이 실패한 경우 처리
            console.log(`error`, error);
          }
        }
      }
    },
    [fetchQuestionDetail, isValidateNotification, questionId, questionNotify],
  );

  return (
    <>
      {questionDetail && (
        <div className="contents-container__scroll">
          <div className="contents-container__wrap workorder-web">
            <article className="contents-container__wrap-article">
              <BaseSectionTitle title={"문의 정보"} />

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>구분</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{questionDetail?.csCategoryType ?? "-"}</span>
                  </div>
                </div>
              </section>
              {!isFromTaapAppUse && (
                <div className="flex-center">
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>건물</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="minmax400">
                        <span>{buildingName || "-"}</span>
                      </div>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>세부 위치</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="minmax400">
                        <span>
                          {questionDetail?.floorNum
                            ? questionDetail?.floorNum > 0
                              ? `지상${questionDetail?.floorNum}층`
                              : `지하${Math.abs(questionDetail?.floorNum)}층`
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </section>
                </div>
              )}

              <div className="flex-center">
                {questionDetail?.csCategoryType === "문의" ? (
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>유형</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="minmax400">
                        <span>{questionDetail.csTypeName || "-"}</span>
                      </div>
                    </div>
                  </section>
                ) : (
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>유형/항목</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="minmax400">
                        <span>
                          {questionDetail.csTypeName} / {questionDetail.summary}
                        </span>
                      </div>
                    </div>
                  </section>
                )}

                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>유입경로</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>{`${site} / ${questionDetail.siteSummary ?? "-"}`}</span>
                    </div>
                  </div>
                </section>
              </div>

              <div className="flex-center">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>이름/닉네임</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>{questionDetail.reporterName ?? "-"}</span>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>이메일</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>{questionDetail?.reporterEmail ?? "-"}</span>
                    </div>
                  </div>
                </section>
              </div>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>휴대폰 번호</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{questionDetail?.reporterPhone ? formatPhoneNumber(questionDetail?.reporterPhone) : "-"}</span>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>문의 내용</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p className="pre-formatted">{questionDetail?.description || "-"}</p>
                </div>
              </section>
              {!isFromTaap && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>답변 방식</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p className="pre-formatted">{answerGubunOptions.find((data) => data.value === questionDetail?.answerGubun)?.label ?? "-"}</p>
                  </div>
                </section>
              )}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>첨부파일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="user-info-container">
                    {questionDetailMedias.length === 0 ? <span>-</span> : <BaseFileUpload isOnlyView fileList={questionDetailMedias} />}
                  </div>
                </div>
              </section>
              {!isFromTaap && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>접수 완료 알림톡</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>{questionDetail.isSendAlarm ? "발송 완료" : "미발송"}</span>
                    </div>
                  </div>
                </section>
              )}
            </article>

            {/*문의답변 */}
            {/* 수기등록이면서 취소상태인 경우 비노출 */}
            {questionDetail.status === "QUESTION_CANCELED" ? null : (
              <article className="contents-container__wrap-article">
                {questionDetail?.answerList && questionDetail?.answerList.length >= 1 && isFromTaap ? (
                  <div className="contents-container__sub-title">
                    <h2>답변 내용</h2>
                  </div>
                ) : (
                  //taap 인입 + 1개 이상의 답변이 등록되어있으면 노출x
                  <>
                    <BaseSectionTitle title={"답변 등록"} />
                    {isAuthority("w") && (
                      <ReplyFormSection
                        questionDetail={questionDetail}
                        fetchQuestionDetail={fetchQuestionDetail}
                        isValidateNotification={isValidateNotification}
                        sendNotification={sendNotification}
                      />
                    )}
                  </>
                )}

                {questionAnswerList && questionAnswerList.length > 0 && (
                  <div className="contents-container__1070">
                    {questionAnswerList
                      .filter((item, idx) => idx <= plusAnswerLength)
                      .map((answer, idx: number) => (
                        <div className={`mt10`} key={answer.id}>
                          <ReplyDetailSection
                            questionDetail={questionDetail}
                            token={token}
                            answer={answer}
                            answerMedias={questionAnswerMedias || []}
                            sendNotification={sendNotification}
                          />
                        </div>
                      ))}
                    {questionAnswerList.length - (plusAnswerLength + 1) > 0 && (
                      <div className="mt10 ">
                        <BaseButton
                          title={`${
                            questionAnswerList.length - (plusAnswerLength + 1) > 5 ? 5 : questionAnswerList.length - (plusAnswerLength + 1)
                          }개 답변 더보기`}
                          className="questions-more color-white"
                          onClick={() => {
                            showMoreAnswerList(questionAnswerList || []);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </article>
            )}

            <article className="contents-container__wrap-article">
              <BaseSectionTitle title={"담당자 정보"} />
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>담당자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{questionDetail?.assigneeEmail || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>참조</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{questionDetail?.referenceEmail ? questionDetail?.referenceEmail.split(",").join(" , ") : "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>매출</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p className="pre-formatted">{questionDetail?.cost !== 0 ? numberToStringWithComma(questionDetail?.cost) : "-"}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>지출</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p className="pre-formatted">{questionDetail?.expense !== 0 ? numberToStringWithComma(questionDetail?.cost) : "-"}</p>
                </div>
              </section>
            </article>

            <article className="contents-container__wrap-article">
              <BaseSectionTitle title={"연결 정보"} />
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>신청/계약</p>
                </div>
                <div className="flex-row flex-center">
                  <div className="contents-container__grid-contents">
                    {contract?.map((data) => (
                      <div key={data.id} className="flex-row flex-center">
                        <BaseNewTabLink
                          path={PagePath.contract.detail.replace(":id", data.connectedId)}
                          value={data.connectedId ?? "-"}
                          ellipsis
                          className="ml16"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>워크오더</p>
                </div>
                <div className="contents-container__grid-contents flex-start">
                  <div className="flex-row flex-center">
                    {workOrder?.map((data) => (
                      <BaseNewTabLink
                        key={data.id}
                        path={PagePath.workOrder.authenticated.detail.replace(":id", data.connectedId)}
                        value={data.connectedId ?? "-"}
                        ellipsis
                        className="ml16"
                      />
                    ))}
                    {/* <BaseButton title="생성" className="color-white ml18" onClick={() => alert("워크오더를 생성해주세요")} /> */}
                  </div>
                </div>
              </section>
            </article>

            <article className="contents-container__wrap-article">
              <BaseSectionTitle title={"등록 정보"} />
              {/* 최초 등록일 */}
              <div className="flex-center">
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p>최초 등록일</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>{moment(questionDetail?.createdDate).format(YmdFormat.YYYY_MM_DD_HH_MM)}</span>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p>최초 등록자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>
                        {questionDetail?.site === "SITE_TAAP" && questionDetail.questionCategory === "QUESTIONCATEGORY_ONLINE"
                          ? questionDetail.reporterEmail
                          : questionDetail.createdBy}
                      </span>
                    </div>
                  </div>
                </section>
              </div>

              <div className="flex-center">
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p>최종 수정일</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span>{moment(questionDetail?.modifiedDate).format(YmdFormat.YYYY_MM_DD_HH_MM)}</span>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid ">
                  <div className="contents-container__grid-index">
                    <p>최종 수정자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      {questionDetail?.modifiedBy && questionDetail.modifiedBy.includes("@") ? (
                        <span>{questionDetail.modifiedBy}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </article>

            <AdminMemoV3 serviceId={+questionId!} serviceType={ServiceTypes.SERVICE_QUESTION} />
          </div>
        </div>
      )}

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>

        <div className="right-area">
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.question.form}?id=${questionId}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>

      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
          title={alertModal.title}
        >
          {alertModal.message && <p>{alertModal.message}</p>}
        </BaseModal>
      )}
    </>
  );
};

export default BasicInfoDetail;
