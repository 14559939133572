import qs from "qs";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IotDeviceQueryParams, ProductDeviceModel } from "src/api/iot/iot-types";
import { ProductModel } from "src/api/product/product-types";
import { Order } from "src/api/public-types";
import { BaseButton, BaseInput } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import IotDevice from "src/pages/building/forms/iotDevice/IotDevice";
import BuildingDeviceColumns from "src/pages/building/forms/iotDevice/columns/BuildingDeviceColumns";
import IotCommonTableModal from "src/pages/building/forms/iotDevice/components/IotCommonTableModal";
import IotDeviceDetail from "src/pages/building/forms/iotDevice/components/IotDeviceDetail";
import { useIotDevice } from "src/pages/building/forms/iotDevice/hooks/useIotDevice";

interface Props {
  product: ProductModel;
}
//
const queryData = { page: 0, size: 20, sort: { orders: [{ property: "internalDisplayName", direction: "ASC" }] } };

const ProductIotDevice = ({ product }: Props) => {
  const location = useLocation();
  const { id: productId } = useParams();
  const [showDevices, setShowDevices] = useState(false);
  const buildingId = useMemo(() => (product && product.buildingList?.map((building) => building.id))?.join(",") || "", [product]) || "";
  const query = useMemo(() => qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true }), [location]);
  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useState<IotDeviceQueryParams>(queryData);

  // iot 탭 목록에서만 사용할 sorting 데이터
  const [deviceOrders, setDeviceOrders] = useState<Order[]>([{ property: "internalDisplayName", direction: "ASC" }]);

  // 기기추가 모달 리스트

  const { isAuthority } = usePartnerAuthority();

  const { iotDevices: buildingDevices, pageMeta } = useIotDevice(params, { executeApi: showDevices });

  const { iotDevices, addDevices, deleteDevice } = useIotDevice(
    { productId: String(productId || query.id), sort: { orders: deviceOrders } },
    { executeApi: true },
  );
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article ">
            <div className="contents-container__sub-title pb10">
              {!query.tabType && (
                <div className="flex-center-between w-100">
                  <BaseSectionTitle title={"IoT 기기"} />
                  {isAuthority("w") && (
                    <BaseButton
                      // className="mt30"
                      title="+ 기기 추가"
                      onClick={() => {
                        setShowDevices(true);
                        setParams({ ...params, buildingId });
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {(!query.tabType || query.tabType === "") && ( //
              <IotDevice
                deviceList={iotDevices}
                deleteDevice={deleteDevice}
                isAuthority={isAuthority("d")}
                orders={deviceOrders}
                setOrders={setDeviceOrders}
              />
            )}
          </div>
        </div>
      </div>

      {/*  */}

      {iotDevices.length > 0 && query.tabType === "detail" && (
        <IotDeviceDetail deviceType="product" detail={iotDevices.find((device: ProductDeviceModel) => Number(device.id) === Number(query.tabId))} />
      )}
      {showDevices && (
        <IotCommonTableModal
          columns={BuildingDeviceColumns.filter((item) => item.Header !== "id" && item.Header !== "삭제")}
          data={buildingDevices}
          title="기기선택"
          checkboxType={"checkbox"}
          setOrders={(orders?: Order[]) => {
            setParams({ ...params, sort: { orders } });
          }}
          pageMeta={pageMeta}
          goToPage={(page) => setParams({ ...params, page })}
          currentSize={(size: number) => setParams({ ...params, size })}
          orders={params.sort?.orders}
          onClick={function (list: any[]): void {
            if (list.length > 0) {
              const data = {
                productId: Number(productId),
                buildingDeviceData: list.map((item) => {
                  return {
                    buildingDeviceId: item.id,
                    internalDisplayName: item.internalDisplayName,
                    buildingName: item.building.buildingName,
                  };
                }),
              };
              addDevices(data);
            }
          }}
          onClose={function (): void {
            setShowDevices(false);
            setParams(queryData);
            setKeyword("");
          }}
        >
          <div className="right-area">
            <div className="d-flex">
              <BaseInput
                type="text"
                value={keyword}
                onChange={(keyword: string) => setKeyword(keyword)}
                onKeyUp={() => {
                  setParams({ ...params, page: 0, search001: keyword });
                }}
                placeholder="검색어를 입력해주세요"
                onSearchClick={() => {
                  setParams({ ...params, page: 0, search001: keyword });
                }}
              />
            </div>
          </div>
        </IotCommonTableModal>
      )}
      {/* 목록으로 */}
      <div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default ProductIotDevice;
