import { Cell } from "react-table";
import { CsItemModel } from "src/api/voc/voc-types";

export const CsItemColumns: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "VoC 유형",
    accessor: "csTypeName",
    width: 140,
  },
  {
    Header: "VoC 항목",
    accessor: "csItemName",
    width: 640,
    Cell: ({ value }: Cell<CsItemModel>) => {
      return <span className="text-start w-100">{value}</span>;
    },
  },
];
