import { useCallback, useEffect, useMemo, useState } from "react";
import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import Link from "src/components/PartnerLink";
import ProductMemberModal from "../modals/ProductMemberModal";
import { numberToStringWithComma } from "src/utils";
import useContactApi from "src/pages/contract/hooks/useContractApi";
import { useParams } from "react-router-dom";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  productManage: ContractOperationProductManage;
  setData: (data: ContractOperationProductManage) => void;
  goToList: string;
  isAuthority?: boolean | undefined;
};

const UserAccessSection = ({ productManage, setData, goToList, isAuthority }: Props) => {
  const [memberModal, setMemberModal] = useState<Modal>({ isOpen: false });

  const { id } = useParams();
  const { callDetails } = useContactApi();
  const [detailData, setDetailData] = useState<any>({});
  const getGetManageDetail = useCallback(
    async (id: string) => {
      const contractData: any = await callDetails(String(id));

      setDetailData(contractData);
    },
    [callDetails],
  );

  useEffect(() => {
    getGetManageDetail(String(id));
  }, [getGetManageDetail, id]);

  return (
    <div>
      <BaseSectionTitle title={"이용자 관리"} />
      <div className="contents-contract-user mr20">
        <div className="contents-detail flex-center-center">
          <div className="text-center px60 py30">
            <div className="mb8">
              <span className="font14 text-primary3">현재 이용자</span>
            </div>
            <div className="mb20">
              <span className="font22 mr5">
                <b>{productManage ? numberToStringWithComma(productManage.memberNums) : 0}</b>
              </span>
              <span className="font18">명</span>
            </div>
            <div>
              <Link to={goToList} target="_blank">
                <BaseButton
                  title="이용자 초대"
                  className="color-white icon-invite"
                  // disabled={detailData?.contractManage?.spaceProductType === "TIME_COURT" || false}
                />
              </Link>
            </div>
          </div>
          <div className="contents-slash">
            <span className="font14">/</span>
          </div>
          <div className="text-center px60 py30">
            <div className="mb8">
              <span className="font14 text-primary3">최대 이용자</span>
            </div>
            <div className="mb20">
              <span className="font22 mr5">
                <b>{productManage ? numberToStringWithComma(productManage.memberMaxNums) : 0}</b>
              </span>
              <span className="font18">명</span>
            </div>
            <div>
              {isAuthority && (
                <BaseButton
                  title="최대 인원 설정"
                  className="color-white"
                  onClick={() => setMemberModal({ isOpen: true })}
                  // disabled={detailData?.contractManage?.spaceProductType === "TIME_COURT" || false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {memberModal.isOpen && (
        <ProductMemberModal
          currentMembers={Number(productManage.memberNums)}
          onSubmit={(maxMembers: number) => {
            if (maxMembers > 0) {
              const calendarType = productManage.calendarType !== "" ? productManage.calendarType : "NONE";
              const newProductManage = { ...productManage, memberMaxNums: maxMembers, calendarType };
              setData(newProductManage);
              setMemberModal({ isOpen: false });
            }
          }}
          onClose={() => setMemberModal({ isOpen: false })}
        />
      )}
    </div>
  );
};

export default UserAccessSection;
