import loading from "src/assets/images/loading_w.gif"
export const LoadingBar = () => {
  return (
    <div className="dim light">
      <div className="base-loading-bar">
        <img src={loading} className="base-loading-bar__loading-img" alt="loading-img" width={80}/>
      </div>
    </div>
  );
};

export default LoadingBar;
