// 건물 Table Columns
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import PhoneNumberColumn from "src/components/PhoneNumberColumn";
import PagePath from "src/pagePath.json";
export const columns = [
  {
    Header: "id",
    accessor: "contractMemberId",
    sticky: "left",
    width: 80,
    Cell: ({ row, value }: any) => {
      const path = PagePath.user.detail.replace(":memberId", value) + "?contractApplyNumber=" + row.original.contractApplyNumber + "&tab=basic";
      return (
        <>
          <DetailLink to={path} className="text-underline text-center">
            {value}
          </DetailLink>
        </>
      );
    },
  },
  {
    Header: "신청번호",
    accessor: "contractApplyNumber",
    width: 120,
    sticky: "left",
  },
  {
    Header: "상태",
    accessor: "memberStatus",
    sticky: "left",
    width: 80,
    Cell: ({ value, row }: any) => {
      let changeValue = value;

      return (
        //visible 녹색
        //invisible 빨간색
        //draft = 회색
        <div className="flex-center">
          <p
            className={`${
              (changeValue === "MEMBER_INVITE" && "status A") ||
              (changeValue === "MEMBER_USE" && "status B") ||
              (changeValue === "MEMBER_DELETE" && "status D") ||
              (changeValue === "MEMBER_CLOSE" && "status D") ||
              (changeValue === "MEMBER_EXPIRE" && "status D")
            }`}
          >
            {(changeValue === "MEMBER_INVITE" && "초대") ||
              (changeValue === "MEMBER_USE" && "사용") ||
              (changeValue === "MEMBER_DELETE" && "삭제") ||
              (changeValue === "MEMBER_CLOSE" && "이용종료") ||
              (changeValue === "MEMBER_EXPIRE" && "초대만료")}
          </p>
        </div>
      );
    },
  },
  {
    Header: "회원번호",
    accessor: "memberNo",
    width: 120,
    sticky: "left",
    Cell: ({ value }: any) => {
      return <>{value ? value : "-"}</>;
    },
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 120,
    Cell: ({ row }: any) => {
      // console.log("row", row.original);
      return (
        <PhoneNumberColumn
          phoneNumber={
            row.original?.phoneNumber ? row.original.phoneNumber : row.original?.inviteMobileNumber ? row.original?.inviteMobileNumber : "-"
          }
        />
      );
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 160,
    Cell: ({ value }: any) => {
      const emailFront = value?.split("@")[0];
      const emailBack = value?.split("@")[1];

      const email = `${emailFront}@${emailBack}`;

      return (
        <div
          className="flex-center-start w-100"
          style={{
            wordBreak: "break-all",
          }}
        >
          {emailFront && emailBack ? email : "-"}
        </div>
      );
    },
  },
  {
    Header: "이메일 인증",
    accessor: "isEmailVerified",
    width: 80,
    Cell: ({ value }: any) => {
      return <>{value !== undefined ? <div>{(value === true && "인증") || (value === false && "미인증")}</div> : "-"}</>;
    },
  },
  {
    Header: "닉네임",
    accessor: "nickname",
    width: 120,
    Cell: ({ value }: any) => {
      return <div className="text-left w-100 ellipsis2">{value || "-"}</div>;
    },
  },
  {
    Header: "초대일시",
    accessor: "inviteDate",
    width: 120,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },
  {
    Header: "가입일시",
    accessor: "signUpTime",
    width: 120,
    Cell: ({ value }: any) => {
      return <BaseTooltip contents={value} type="date" />;
    },
  },

  {
    Header: "캘린더",
    accessor: "isWatchingCalendar",
    width: 80,
    Cell: ({ row }: any) => {
      if (row.original.isWatchingCalendar === undefined) return <div>-</div>;

      return <div>{(row.original.isWatchingCalendar === true && "연동") || (row.original.isWatchingCalendar === false && "미연동")}</div>;
    },
  },
  {
    Header: "캘린더 동기화",
    accessor: "isExistsIssues",
    width: 120,
    Cell: ({ row }: any) => {
      let text = "-";

      if (row.original.isExistsIssues !== undefined && row.original.isExistsIssues) {
        if (row.original.isResolvable !== undefined && row.original.isResolvable) {
          text = "실패(재 연동 필요)";
        } else {
          text = "실패(관리자 문의)";
        }
      }
      return <div className={`${row.original.isExistsIssues !== undefined && row.original.isExistsIssues === true && "chip draft"}`}>{text}</div>;
    },
  },
  {
    Header: "권한",
    accessor: "role",
    width: 120,
    Cell: ({ row }: any) => {
      let roles = "";
      if (row.original.isContractor === true) {
        roles = "계약자 ";
      }
      if (row.original.isAdmin === true) {
        if (roles !== "") {
          roles += ", 마스터";
        } else {
          roles += "마스터";
        }
      }

      return <div>{roles ? roles : "-"}</div>;
    },
  },
];
