import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getChargeItemList, getChargeItemsGroupDetail } from "src/api/chargeItems/chargeItems-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { columns } from "./ColumnsProperty";

const ChargeItemsDetailForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { executeAsync: getGroupItems } = useApiOperation(getChargeItemList);
  const { executeAsync: getGroupDetail } = useApiOperation(getChargeItemsGroupDetail);
  const [isOpen, setIsOpen] = useState(false);

  // const [stateOptions18, setStateOptions18] = useState<any>(stateOptions9);
  const [formData, setFormData] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [title, setTitle] = useState("");
  const groupDetail = useCallback(
    async (groupId: number) => {
      const response: any = await getGroupDetail({ groupId });
      if (response.status >= 200 && response.status <= 299) {
        setTitle(response.data.data.content.ctMntGroup.groupName);
      } else {
      }
    },
    [getGroupDetail],
  );

  const itemList = useCallback(async () => {
    const response: any = await getGroupItems({ groupId: id });
    const sorted = _.sortBy(response.data.data.ctMntItemList, "orderNum").reverse();
    setTableData(sorted);
  }, [getGroupItems, id]);

  useEffect(() => {
    if (id) {
      itemList();
      groupDetail(Number(id));
    }
  }, [groupDetail, id, itemList]);

  const goEditDetail = useCallback(() => {
    const path = PagePath.chargeItems.form + "?id=" + id + "&tab=detailCost";
    navigate(path);
  }, [id, navigate]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, rows } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 1000 },
      setFormData,
      setTableData,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {/* 세부 부과항목 */}
          <section className="">
            {/* 첫째줄 폼 */}

            {/* 2depth 테이블 */}
            <div className="inner-tab-table two-depth-header">
              <div {...getTableProps()} className="base-table sticky">
                <div className="header">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                      {headerGroup.headers.map((header: any) => {
                        // sticky 기준을 맞추기 위해 rowspan 처럼 보이는 헤더는 무조건 columns를 1개 가지고 있어야함.
                        // 스티키가 아닌 rowspan 헤더 케이스
                        if (!header.parent && header.depth === 0 && header.columns?.length === 1 && !header.sticky) {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              <div className="w-100 no-parent" style={{ zIndex: 2 }}>
                                {header.render("Header")}
                              </div>
                            </div>
                          );
                        } else if (header.sticky === "left") {
                          // 스티키 상태인 rowspan 케이스
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th" style={{ ...header.getHeaderProps().style, zIndex: 4 }}>
                              <div className="w-100 sticky-parent" style={{ zIndex: 2 }}>
                                {header.render("Header")}
                              </div>
                              {/* <div className="ic_sort"></div> */}
                            </div>
                          );
                        } else {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              <div className=" w-100 ">{header.render("Header")}</div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                  {page.map((row: any) => {
                    prepareRow(row);

                    return (
                      <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                        {row.cells.map((cell: any) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => {}} onClose={() => setIsOpen(false)}>
            <p>저장 하시겠습니까?</p>
          </BaseModal>
          <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => {}} onClose={() => setIsOpen(false)}>
            <p>삭제 하시겠습니까?</p>
          </BaseModal>
          <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => {}} onClose={() => setIsOpen(false)}>
            <p>목록으로 이동합니다. 미 저장 시 정보가 초기화 되어 저장됩니다.</p>
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => navigate(PagePath.chargeItems.list)} />
        </div>
        <div className="right-area">
          <BaseButton title="수정" className=" size-large" onClick={() => goEditDetail()} />
        </div>
      </div>
    </>
  );
};
export default ChargeItemsDetailForm;
