import React, { forwardRef, useEffect, useRef } from "react";

interface CheckboxProps {
  indeterminate?: boolean;
  disabled?: boolean;
  id?: string;
}

const TableCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ indeterminate, id, disabled, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = ref || (defaultRef as any);

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate, rest]);

    return (
      <div className={`d-flex `}>
        <input
          id={`bulding ${id}`}
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          disabled={disabled}
        ></input>
        <label htmlFor={`bulding ${id}`} className="d-flex">
          <div className="base-checkbox"></div>
        </label>
      </div>

      //
      // <div style={{ textAlign: "center" }}>
      //   <input className="d-block base-checkbox" type="checkbox" ref={resolvedRef} {...rest} />
      // </div>
    );
  },
);

export default TableCheckbox;
