import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";

function Link(props: LinkProps) {
  const partner = useRecoilValue(globalPartnerState);

  if (typeof props.to === "string") {
    const _to = props.to.replace(":partnerId", String(partner?.id));

    return <ReactRouterLink {...props} to={_to} />;
  }

  return <ReactRouterLink {...props} />;
}
export default Link;
