import { setHours, setMinutes } from "date-fns";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { Contractor } from "src/api/contract/contract-types";
import { BaseDatePicker } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import { ViewYmdFormat, YmdFormat } from "src/utils";

const ServiceType = ({ saveData, productDetail, setSaveData }: any) => {
  const [member, setMemeber] = useState<Contractor>();
  const filterPassedTime = (useStartTime: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(useStartTime);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <>
      <article className="contents-container__wrap-article">
        <div className="contents-container__sub-title">
          <h2>신청 시간</h2>
        </div>

        <div className="flex-center">
          <BaseSection
            title="신청 시작 시간"
            required={true}
            children={
              <div className="minmax160">
                <BaseDatePicker
                  type="full"
                  name={"useStartTime"}
                  timeIntervals={30}
                  selectedDate={saveData.useStartTime ? moment(saveData.useStartTime).toDate() : null}
                  setDate={(date: string) => {
                    const temp = _.cloneDeep(saveData);
                    temp.useStartTime = moment(date).format(YmdFormat.WITH_TIME_ZONE);
                    temp.useEndTime = moment(temp.useStartTime).add("minute", 30).format(YmdFormat.WITH_TIME_ZONE);

                    setSaveData(temp);
                  }}
                  disabled={saveData.selectedFacility === undefined}
                />
              </div>
            }
          />
          <BaseSection
            title="신청 종료 시간"
            required={true}
            children={
              <div className="minmax160">
                <BaseDatePicker
                  type="full"
                  name={"useEndTime"}
                  timeIntervals={30}
                  filteredTime={saveData.useStartTime}
                  excludeTimes={[
                    setHours(
                      setMinutes(new Date(), Number(moment(saveData.useStartTime).format("mm"))),
                      Number(moment(saveData.useStartTime).format("HH")),
                    ),
                  ]}
                  useFilterdTime={true}
                  minDate={moment(saveData.useStartTime).toDate()}
                  selectedDate={saveData.useEndTime ? moment(saveData.useEndTime).toDate() : null}
                  setDate={(date: string) => {
                    const temp = _.cloneDeep(saveData);
                    temp.useEndTime = moment(date).format(YmdFormat.WITH_TIME_ZONE);
                    setSaveData(temp);
                  }}
                  disabled={saveData.selectedFacility === undefined}
                />
              </div>
            }
          />
        </div>
      </article>
    </>
  );
};

export default ServiceType;
