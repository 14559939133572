import React, { ReactNode, useMemo, useState } from "react";
import { chunk } from "lodash";
import { BaseSelect } from "./BaseSelect";

interface Props {
  pageIndex: number;
  totalPages: number;
  goPage: Function;
  totalElements?: number;
  children?: ReactNode;
  sizeOption?: (number: number) => void;
  currentSize?: number;
}

const sizeOptions = [
  { value: 10, label: "10개씩 보기" },
  { value: 20, label: "20개씩 보기" },
  { value: 30, label: "30개씩 보기" },
  { value: 40, label: "40개씩 보기" },
  { value: 50, label: "50개씩 보기" },
];

const ITEMS_PER_PAGE = 10;
export const BasePagination = ({ pageIndex, totalPages, goPage, totalElements, children, sizeOption, currentSize }: Props) => {
  const sizeValue = useMemo(() => {
    let size = 20;
    if (currentSize) {
      const current = sizeOptions.find((item) => item.value === currentSize);
      size = Number(current?.value);
    }

    return size;
  }, [currentSize]);

  const pageChunk = useMemo(() => {
    const pages = Array.from(Array(totalPages).keys());
    return chunk(pages, ITEMS_PER_PAGE);
  }, [totalPages]);

  const blockIndex = useMemo(() => {
    return Math.floor((pageIndex + 1) / ITEMS_PER_PAGE) - ((pageIndex + 1) % ITEMS_PER_PAGE === 0 ? 1 : 0);
  }, [pageIndex]);

  // if (!totalElements || totalElements === 0) {
  //   return (
  //     <div className="base-pagination">
  //       <div className="base-pagination__total"> 전체 0</div>
  //       <div className="flex-center-center">
  //         <button className="base-pagination__previous" disabled></button>
  //         <div>
  //           <button className={`base-pagination__number`} disabled>
  //             1
  //           </button>
  //         </div>
  //         <button className="base-pagination__next" disabled></button>
  //       </div>
  //       <div className="child-btn-area">{children}</div>
  //     </div>
  //   );
  // }

  return (
    <div className="base-pagination">
      <div className="base-pagination__total">
        <span>전체 {totalElements}</span>
        {sizeOption && (
          <div className="ml30">
            <BaseSelect
              stateOptions={sizeOptions}
              setStateValue={(selected: number) => {
                // setSize(selected);
                sizeOption(selected);
              }}
              value={sizeValue}
            />
          </div>
        )}
      </div>

      <div className="flex-center-center">
        {/* 한개씩 이동 */}
        <button
          className="base-pagination__previous prev-all"
          onClick={() => {
            goPage(0);
          }}
          disabled={pageIndex === 0}
          onMouseUp={(event) => {
            if (event.button === 1) {
              // 마우스의 휠 버튼 클릭 여부 확인
              event.currentTarget.click();
            }
          }}
        ></button>
        {/* 10개씩 이동 */}
        <button
          className="base-pagination__previous prev-10"
          onClick={() => {
            goPage(pageChunk[blockIndex][0] - 1);
          }}
          disabled={blockIndex === 0 || Number(totalElements) === 0}
          onMouseUp={(event) => {
            if (event.button === 1) {
              // 마우스의 휠 버튼 클릭 여부 확인
              event.currentTarget.click();
            }
          }}
        ></button>
        {/* 끝으로 이동 */}
        <button
          className="base-pagination__previous"
          onClick={() => {
            goPage(pageIndex - 1);
          }}
          disabled={pageIndex === 0 || Number(totalElements) === 0}
          onMouseUp={(event) => {
            if (event.button === 1) {
              // 마우스의 휠 버튼 클릭 여부 확인
              event.currentTarget.click();
            }
          }}
        ></button>

        {pageChunk[blockIndex]?.map((i) => {
          const pageNumber = i + 1;
          return (
            <div key={pageNumber}>
              <button
                className={`base-pagination__number`}
                onClick={() => {
                  goPage(pageNumber - 1);
                }}
                disabled={pageIndex + 1 === pageNumber || Number(totalElements) === 0}
                onMouseUp={(event) => {
                  if (event.button === 1) {
                    // 마우스의 휠 버튼 클릭 여부 확인
                    event.currentTarget.click();
                  }
                }}
              >
                {pageNumber}
              </button>
            </div>
          );
        })}

        {/* 한개씩 이동 */}
        <button
          className="base-pagination__next"
          onClick={() => {
            goPage(pageIndex + 1);
          }}
          disabled={pageIndex + 1 === totalPages || Number(totalElements) === 0}
          onMouseUp={(event) => {
            if (event.button === 1) {
              // 마우스의 휠 버튼 클릭 여부 확인
              event.currentTarget.click();
            }
          }}
        ></button>
        {/* 10개씩 이동 */}
        <button
          className="base-pagination__next next-10"
          onClick={() => {
            goPage(pageChunk[blockIndex][9] + 1);
          }}
          disabled={blockIndex === pageChunk.length - 1 || Number(totalElements) === 0}
          onMouseUp={(event) => {
            if (event.button === 1) {
              // 마우스의 휠 버튼 클릭 여부 확인
              event.currentTarget.click();
            }
          }}
        ></button>
        {/* 끝으로 이동 */}
        <button
          className="base-pagination__next next-all"
          onClick={() => {
            goPage(totalPages - 1);
          }}
          disabled={pageIndex === totalPages - 1 || Number(totalElements) === 0}
          onMouseUp={(event) => {
            if (event.button === 1) {
              // 마우스의 휠 버튼 클릭 여부 확인
              event.currentTarget.click();
            }
          }}
        ></button>
      </div>
      <div className="child-btn-area">{children}</div>
    </div>
  );
};
