import { useCallback, useMemo } from "react";
import { Control } from "react-hook-form";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { MgmtOfficerType } from "src/api/building/building-types";
import { WorkOrderFormType } from "src/api/work-order/workorder-types";
import { BaseCheckbox } from "src/components";
import BaseScroll from "src/components/BaseScroll";
import { RoleType } from "src/pages/building/building-types";
import GlobalFilter from "src/pages/building/components/GlobalFilter";
import { officerTypeList } from "src/pages/building/forms/mgmtOffice/management-type";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { formatPhoneNumber } from "src/utils/common-util";

type Props = {
  control: Control<WorkOrderFormType>;
  onClose: () => void;
  mgmtOfficerList?: Array<MgmtOfficerType>;
  onSelected: (officer: MgmtOfficerType) => void;
};
const OfficerModal = ({ mgmtOfficerList, onSelected, onClose }: Props) => {
  // 담당자 조회 팝업 - 업무파트 부분
  const getOfficeWorkType = useCallback((workTypes: string[]) => {
    if (workTypes) {
      const changeValue = workTypes.map((type) => {
        officerTypeList.forEach((item) => {
          if (item.value === type) {
            type = item.label;
          }
          if (type === "OFFICER_UNRECOGNIZED") {
            type = "미선택";
          }
        });
        return type;
      });
      return changeValue;
    }
  }, []);

  const OfficerColumn: any[] = useMemo(() => {
    return [
      {
        Header: "이름/닉네임",
        accessor: "name",
        width: 150,
      },
      {
        Header: "휴대폰 번호",
        accessor: "phone",
        width: 150,
        Cell: ({ row }: Cell<MgmtOfficerType>) => formatPhoneNumber(String(row.original.phone)),
      },

      {
        Header: "업무 파트",
        accessor: "officerTypeList",
        width: 220,
        Cell: ({ row }: Cell<MgmtOfficerType>) => {
          const officerWorkList = row.original.officerTypeList;

          if (officerWorkList && officerWorkList.length > 0)
            return <span className="ellipsis">{getOfficeWorkType(officerWorkList || [])?.join(", ")}</span>;
        },
      },
      {
        Header: "권한",
        accessor: "roleType",
        width: 100,
        Cell: ({ row }: Cell<MgmtOfficerType>) => {
          const roleType = row.original.roleType;
          const isPrimary = row.original.isPrimary;

          if (isPrimary && roleType === RoleType.MANAGER) {
            return <span>대표 매니저</span>;
          }
          return roleType === RoleType.MANAGER ? <span>매니저</span> : "-";
        },
      },
      {
        Header: "세부업무",
        accessor: "description",
        width: 240,
        Cell: ({ value }: Cell<MgmtOfficerType>) => <span className="text-left w-100 ellipsis">{value || "-"}</span>,
      },
    ];
  }, [getOfficeWorkType]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
    state: { globalFilter },
    selectedFlatRows,
  } = useTable(
    {
      columns: OfficerColumn,
      data: mgmtOfficerList || [],
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 70,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedOfficer"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );
  return (
    <>
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>작업자 선택</h1>

        <div className="right-area">
          <div className="minmax260 mr5">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeHolder={"이름 / 닉네임"} />
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table px30">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps(header.getSortByToggleProps())} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <BaseScroll maxHeight={400}>
              {mgmtOfficerList?.length && mgmtOfficerList.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  <p className="mt10">목록이 없습니다.</p>
                </>
              )}
            </BaseScroll>
          </div>
          <div className="flex-center-end py30">
            <button className="base-btn color-white mr10" onClick={() => onClose()}>
              취소
            </button>
            <button
              className="base-btn"
              onClick={() => {
                if (!selectedFlatRows[0]) {
                  return;
                }
                onSelected(selectedFlatRows[0].original);
                onClose();
              }}
            >
              선택
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfficerModal;
