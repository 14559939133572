import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell } from "react-table";
import { CommonFacilityModel } from "src/api/building/building-types";
import { getEslTempProductDetailAsync } from "src/api/esl/esl-api";
import { EslTempProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { ReservationAttendee, ReservationListModel } from "src/api/reservation/reservation-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import MarkdownViewer from "src/components/MarkdownViewer";
import { floorOptions, SelectOption, spaceStyleOptions } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { ReservationAttendeeResponseStatus } from "src/pages/reservation/reservation-types";

type Props = {
  reservation: ReservationListModel | null;
  commonFacility: CommonFacilityModel | null;
};

interface EslLabel extends EslTempProduct {
  locationCode: string; // 공간유형 채번
}

/* 
  공용공간 예약 상세 > 회의실 컴포넌트
 */
const MeetingRoomSection = ({ reservation, commonFacility }: Props) => {
  // 주최자 찾기
  const [createMember, setCreateMember] = useState("");

  // (임시) ESL 데이터 상세 조회 api
  const { executeAsync: getEslTempProductDetail } = useApiOperation(getEslTempProductDetailAsync, {
    noHandleError: true,
  });

  // 조회한 ESL 데이터 목록
  const [eslLabels, setEslLabels] = useState<Array<EslLabel>>([]);

  // 건물 상세조회에서 얻어온 회의실 목록
  const meetingRoomList: Array<CommonFacilityModel> = useMemo(() => {
    return commonFacility ? [commonFacility] : [];
  }, [commonFacility]);

  // 이미지 목록
  const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);

  // esl 라벨 목록 조회
  const fetchAllLabels = useCallback(async (locationCodes: Array<string>) => {
    try {
      if (!locationCodes || locationCodes.length === 0) {
        throw Error("locationCodes.length === 0");
      }

      const eslLabels: Array<EslLabel> = [];
      for (let i = 0; i < locationCodes.length; i++) {
        const locationCode = locationCodes[i];

        const result = await getEslTempProductDetail({ productId: locationCode, serverId: "1" });
        if (result?.data?.data && result.status >= 200 && result.status <= 299) {
          if (result?.data?.data?.product) {
            // esl 라벨이 연동되어있을 경우만 조회됨
            eslLabels.push({
              ...result?.data?.data?.product,
              ...{
                locationCode,
              },
            });
          }
        }
      }
      setEslLabels(eslLabels);
    } catch (error) {
      // 에러 발생해도 무시
      console.debug("fetchAllLabels error", error);
    }
  }, []);

  // 이미지 갯수 클릭
  const clickImageCount = useCallback((rowData: any) => {
    setMediaList(rowData?.mediaList || []);
    return false;
  }, []);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 120,
        Cell: ({ value }: Cell<any>) => <BaseTooltip contents={value} isSingleLine={true} />,
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 90,
        Cell: ({ row }: any) => {
          const isGround: boolean = row?.original?.isGround; // 지상여부
          const floorNum: number = row?.original?.floorNum; // 위치
          const floor = floorOptions.find((option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`);
          return floor?.label || "";
        },
      },
      {
        Header: "회의실 명",
        accessor: "facilityName",
        width: 110,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "공간유형",
        accessor: "spaceStyle",
        width: 80,
        Cell: ({ value }: any) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 120,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "정원",
        accessor: "facilityMaxPeopleNums",
        width: 60,
      },
      {
        Header: "기자재",
        accessor: "equipments",
        width: 140,
        Cell: ({ row }: any) => {
          const facilityList: Array<string> = row?.original?.facilityList || []; // 기자재
          return <BaseTooltip contents={facilityList.join(",")} />;
        },
      },
      {
        Header: "설명",
        accessor: "description",
        width: 230,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 60,
        Cell: ({ row }: any) => {
          if (!!row?.original?.locationCode && eslLabels.length > 0) {
            const findLabel = eslLabels.find((eslLabel: EslLabel) => eslLabel.locationCode === row?.original?.locationCode);
            if (findLabel) {
              return <BaseTooltip contents={(findLabel.assignedLabelCodes || []).join(",")} />;
            }
          }
          return "";
        },
      },
      {
        Header: "이미지",
        accessor: "mediaList",
        width: 60,
        Cell: ({ row }: any) => {
          const imageCount = (row?.original?.mediaList || []).length;
          return (
            <div>
              <a className="text-underline cursor-pointer" onClick={() => clickImageCount(row?.original)}>
                {imageCount}
              </a>
            </div>
          );
        },
      },
    ],
    [clickImageCount, eslLabels],
  );

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title={"회의실"} />
      <ViewDataTable columns={columns} data={meetingRoomList} />
      <div className="mt20">
        <ImagesDetail isUsedRepresentative={true} isUsedDescription={true} images={mediaList} />
      </div>
      <section className="contents-container__grid mt20">
        <div className="contents-container__grid-index">
          <p className="">제목</p>
        </div>

        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservation?.summary ? reservation.summary : "-"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">설명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">{reservation?.description ? <MarkdownViewer value={reservation?.description} /> : <span>-</span>}</div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">주최자</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">
              {reservation?.organizer.displayName
                ? reservation?.organizer.displayName
                : reservation?.organizer.userEmail
                ? reservation?.organizer.userEmail
                : "-"}
            </span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">참석자</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            {/* <span className="font14">{(reservation?.reservationInviteeList || []).length}명</span> */}

            {reservation?.attendees.map((attendee: ReservationAttendee) => {
              let invitee = "";

              const inviteeLength = reservation?.attendees.length;

              if (attendee.responseStatus === ReservationAttendeeResponseStatus.ACCEPTED) {
                invitee = "수락";
              } else if (attendee.responseStatus === ReservationAttendeeResponseStatus.DECLINED) {
                invitee = "거부";
              } else if (attendee.responseStatus === ReservationAttendeeResponseStatus.TENTATIVE) {
                invitee = "임시적 수락";
              } else {
                invitee = "응답없음";
              }

              return (
                <div className=" d-flex" key={attendee.memberNo}>
                  <>
                    <div className="font14 minmax200">
                      <span className="">{attendee.userEmail}</span>
                    </div>
                    <div className="font14 minmax170">
                      <span> {invitee}</span>
                    </div>
                  </>
                  {inviteeLength === 0 && <span>-</span>}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </article>
  );
};

export default MeetingRoomSection;
