import { useCallback, useEffect, useMemo, useState } from "react";
import { ContentsTitle } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import ContractMemberFind from "src/pages/commonPopup/components/ContractMemberFind";
import TaapMemberFind from "./TaapMemberFind";

interface MemberProps {
  isOpen: boolean;
  memberInfo: any;
  onClick: any;
  onClose: any;
}
const ContractorChangeModal = ({ isOpen, memberInfo, onClick, onClose }: MemberProps) => {
  const [activeTab, setActiveTab] = useState<any>({ label: "이용자", value: "member" });
  const tabs = useMemo(() => {
    return [
      { label: "이용자", value: "member" },
      { label: "Taap회원", value: "taap" },
    ];
  }, []);
  // 현재 활성화 되어야하는 tab

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(async (tab: { value: string; label: string; disabled: boolean }) => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {}, []);
  console.log("activeTabs", activeTab);
  return (
    <div>
      <BaseAbstractModal size="xlarge" isOpen={isOpen}>
        <section className="base-abstract-modal__title">
          <div className="flex-row flex-center-between w-100">
            <h1>계약자 선택</h1>
          </div>
        </section>
        <ContentsTitle title="신청/계약" tabs={tabs} activeTab={activeTab} clickTab={clickTab} colorType={"contract"} />

        {activeTab.value === "member" && (
          <ContractMemberFind isOpen={isOpen} contractManageId={memberInfo.contractManageId!} onClick={onClick} onClose={onClose} />
        )}

        {activeTab.value === "taap" && (
          <TaapMemberFind isOpen={isOpen} memberInfo={memberInfo.contractManageId!} onClick={onClick} onClose={onClose} size="xlarge" />
        )}
      </BaseAbstractModal>
    </div>
  );
};
export default ContractorChangeModal;
