import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { RefObject, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { InsertAdminMemo, ServiceTypes, UnionServiceType, UpdateAdminMemo } from "src/api/adminMemo/adminmemo-types";
import { MediaFile } from "src/api/public-types";
import useMediasUpdate from "src/hooks/useMediasUpdate";
import useAdminMemoState from "src/recoil/adminMemo/hooks";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { BaseButton } from "../BaseButton";
import { BaseTextarea } from "../BaseTextarea";
import { AdminMemoIntegrationFormData } from "../adminMemoIntegration/adminmemo-types";
import useAdminMemo from "../adminMemoIntegration/useAdminMemo";
import BaseFileUpload from "../mediaFile/BaseFileUpload";
import { BaseTooltip } from "../BaseTooltip";

type Props = {
  serviceId: number; //
  serviceType: UnionServiceType;
  isMemoListItemEdit?: boolean;
  setIsMemoListItemEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  memoId?: string;
  memo?: string;
  medias?: MediaFile[];
};

export type AdminMemoFormData = {
  id?: string;
  serviceId?: number;
  memo: string;
};

const AdminMemoFormV3 = ({ serviceId, serviceType, isMemoListItemEdit, setIsMemoListItemEdit, memoId, memo, medias: mediasProp }: Props) => {
  const { globalIsEdit, setGlobalIsEdit } = useAdminMemoState();
  const [isShowBtn, setIsShowBtn] = useState(globalIsEdit ? true : false);
  const { setBaseModal } = useModal();
  const { add, edit, remove } = useAdminMemo();
  const { openErrorModal } = useErrorModal();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const partner = useRecoilValue(globalPartnerState);
  const queryCache = useQueryClient();
  const [ref, setRef] = useState<RefObject<HTMLInputElement>>();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AdminMemoIntegrationFormData>({
    defaultValues: {
      memo: isMemoListItemEdit ? memo : "",
    },
  });

  // 첨부파일 리스트
  const [medias, setMedias] = useState<MediaFile[]>(mediasProp ?? []);

  // 공통 미디어 파일 커스텀 훅
  const { requestMedias, updateMedias } = useMediasUpdate({ mediaList: medias });

  //  첨부파일 등록
  const postMediaFiles = async (serviceId: string) => {
    const { data: mediaResponse, status: mediaStatus } = await updateMedias(serviceId);
    if (mediaStatus >= 200 && mediaStatus <= 299) {
      console.log(`미디어 성공`);
    } else {
      const errorCode = mediaResponse?.meta?.errorCode;
      errorCode && openErrorModal({ errorCode, statusCode: mediaStatus, errorMessage: "첨부파일 등록이 실패하였습니다.\n 재등록해 주세요." });
    }
  };

  const memoReset = () => {
    setIsShowBtn(false);
    reset();
    setMedias([]);
    setBaseModal({ isOpen: false });

    setIsMemoListItemEdit && setIsMemoListItemEdit(false); // 메모 리스트 아이템 edit상태 리셋
    setGlobalIsEdit(false); // 관리자메모 전역 edit상태 리셋
  };

  const handleScrollDown = () => {
    if (globalIsEdit === false && isShowBtn === false) {
      const element = document.getElementById("input-wrap");
      if (element) {
        element.scrollIntoView({ block: "center" });
      }
    }
  };

  const onValid = async (formData: { memo: string }) => {
    let response;
    if (!isMemoListItemEdit) {
      const addMemoData: InsertAdminMemo = {
        partnerId: String(partner?.id),
        serviceId: String(serviceId),
        memo: formData.memo,
        serviceType,
      };
      response = await add(addMemoData);
    } else {
      const editMemoData: UpdateAdminMemo = {
        id: String(memoId),
        partnerId: String(partner?.id),
        serviceId: String(serviceId),
        memo: formData.memo,
        serviceType,
      };

      response = await edit(editMemoData);
    }

    // 관리자 메모에 첨부파일이 있으면 첨부파일 api 실행 후 navigate
    if (requestMedias.length > 0 && response?.data?.data?.content?.id) {
      await postMediaFiles(String(response.data.data.content.id));

      //첨부파일 목록 재검증
      queryCache.invalidateQueries({
        queryKey: ["getAdminMemoFile", memoId],
      });
    }
    //어드민 메모 목록 재검증
    queryCache.invalidateQueries({
      queryKey: ["getAdminMemoList", serviceId, serviceType],
    });

    memoReset();
  };

  return (
    <div id="input-wrap">
      <form onSubmit={handleSubmit(onValid)}>
        <Controller
          control={control}
          name="memo"
          rules={{
            required: "내용을 입력해야 메모를 저장할 수 있습니다",
          }}
          render={({ field: { onChange, value, name } }) => (
            <div className={clsx("textarea-warp", { "add-file": medias.length > 0 })}>
              <BaseTextarea
                inputRef={textAreaRef}
                height={40}
                className="d-flex"
                name={name}
                value={value}
                placeholder="메모를 입력하세요"
                maxLength={1000}
                onChange={onChange}
                adjustHeight
                onClick={() => {
                  setIsShowBtn(true);
                  handleScrollDown();
                }}
              />
              <BaseTooltip
                tooltip="첨부파일"
                className="ic-file-warp"
                touchIcon={
                  (<div onClick={() => ref?.current?.click()} className={`${isShowBtn ? "ic-file cursor-pointer" : ""} `}></div>) as any as string
                }
              />

              <BaseFileUpload
                setRef={setRef}
                mode="memo"
                containerClass={clsx("position-absolute left10 bottom10", { "add-file": medias.length > 0, "d-none": medias.length === 0 })}
                isOnlyView={false}
                limit={10}
                maxWidth={1020}
                fileList={medias ?? []}
                setFiles={(files: MediaFile[]) => {
                  const newFiles = files.map((file) => ({ ...file, serviceType: ServiceTypes.SERVICE_ADMINMEMO }));
                  setMedias(newFiles);
                }}
              />
            </div>
          )}
        ></Controller>
        {isShowBtn && (
          <div className="bottom-wrap">
            {errors.memo?.message && <p className="validation-text pt0">{errors.memo?.message}</p>}
            <div className="bottom-btn-wrap">
              <>
                <BaseButton
                  type="reset"
                  title="취소"
                  className="color-white"
                  onClick={() => {
                    if (!isMemoListItemEdit && !watch("memo") && medias.length < 1) {
                      //상단 등록(리스트수정모드x) 인풋에서 text가 없고, 파일도 없으때 얼럿모달x , 리셋만 실행
                      //
                      return memoReset();
                    }

                    setBaseModal({
                      isOpen: true,
                      title: `이 메모가 지워집니다.\n그래도 취소하겠습니까?`,
                      btnLeftTitle: "취소",
                      btnRightTitle: "확인",
                      onClick: () => memoReset(),
                    });
                  }}
                />
                <BaseButton type="submit" title="저장" />
              </>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdminMemoFormV3;
