import { AxiosInstance, AxiosResponse } from "axios";

import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";

/*
  CCM21. 관리비 총괄표 목록 조회
*/
export async function getCCM21(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.list;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/*
  CCM16. 건물별 관리비 부과 그룹 조회
*/
export async function getCCM16(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.ccm16.replace("{buildingId}", params!.buildingId);

  return await axios.get<ApiResponse<any>>(path);
}

/*
  CCM23. 관리비 총괄표 기본정보 조회
*/
export async function getCCM23(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.ccm23.replace("{chartId}", params!.chartId);

  return await axios.get<ApiResponse<any>>(path);
}
/*
  CCM22. 관리비 총괄표 기본정보 저장
*/
export async function saveCCM22(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.saveCcm22;

  return await axios.post<ApiResponse<any>>(path, { ctMntChart: params });
}

/*
  CCM22. 관리비 총괄표 기본정보 수정
*/
export async function editCCM22(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.editCcm22;

  return await axios.patch<ApiResponse<any>>(path, { ctMntChart: params });
}

/*
  CCM25. 관리비 총괄표 아이템 조회
*/
export async function getCCM25(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.itemListCcm25.replace("{chartId}", String(params?.chartId));

  return await axios.get<ApiResponse<any>>(path);
}

/*
  CCM24 관리비 총괄표 아이템목록 저장
*/
export async function saveCCM24(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.saveCcm24.replace("{chartId}", params?.chartId);

  return await axios.patch<ApiResponse<any>>(path, {
    ctMntChartItemList: params.ctMntChartItemList,
  });
}
/*
  CCM32 관리비 개별관리비 아이템 목록 조회
*/
export async function getCCM32(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeMnt.individualItemListCcm32.replace("{chartId}", String(params?.chartId));

  return await axios.get<ApiResponse<any>>(path);
}
