import RoutesList from "src/routes/RoutesList";
import { useLocation } from "react-router-dom";
import { isPublicPage } from "src/utils/common-util";
import ErrorModal from "../ErrorModal";
import LoadingBar from "../LoadingBar";
import ToastList from "../ToastList";
import { createPortal } from "react-dom";
import { memo, useEffect, useState } from "react";
import useApiLoading from "src/hooks/useApiLoading";
import { axiosInstance, axiosInstanceNoAuth } from "src/utils";
import { useRecoilValue } from "recoil";
import { errorModalState } from "src/recoil/errorModal/atom";
import { VariousProvider } from "src/VariousProvider";
import ModalList from "../ModalList";

/**
 * App > Container > Layout
 */

const root = document.getElementById("loading");

function Layout() {
  const { pathname } = useLocation();
  const { isApiLoading, startApiLoading, stopApiLoading } = useApiLoading();
  const errorModals = useRecoilValue(errorModalState);
  const [loadingRoot] = useState(() => document.createElement("div"));

  useEffect(() => {
    const authAxiosRequestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        startApiLoading();
        return config;
      },
      (error) => {
        stopApiLoading();
        return Promise.reject(error);
      },
    );

    const authAxiosResponseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        stopApiLoading();
        return response;
      },
      (error) => {
        stopApiLoading();
        return Promise.reject(error);
      },
    );

    const noAuthAxiosRequestInterceptor = axiosInstanceNoAuth.interceptors.request.use(
      (config) => {
        startApiLoading();
        return config;
      },
      (error) => {
        stopApiLoading();
        return Promise.reject(error);
      },
    );

    const noAuthAxiosResponseInterceptor = axiosInstanceNoAuth.interceptors.response.use(
      (response) => {
        stopApiLoading();
        return response;
      },
      (error) => {
        stopApiLoading();
        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.request.eject(authAxiosRequestInterceptor);
      axiosInstance.interceptors.response.eject(authAxiosResponseInterceptor);
      axiosInstanceNoAuth.interceptors.request.eject(noAuthAxiosRequestInterceptor);
      axiosInstanceNoAuth.interceptors.response.eject(noAuthAxiosResponseInterceptor);
    };
  }, []);

  useEffect(() => {
    root?.prepend(loadingRoot);

    return () => {
      root?.removeChild(loadingRoot);
    };
  }, []);

  const checkPathname = () => {
    let result = false;
    if (isPublicPage(pathname)) result = true;
    if (pathname === "/") result = true;
    if (pathname === "/partner") result = true;
    if (pathname.includes("/partner/")) result = true;
    if (pathname === "/mng/ctrl/init") result = true;
    if (pathname === "/mng/ctrl/accessdenied") result = true;
    return result;
  };

  return (
    <>
      <main className={isPublicPage(pathname) ? `base-layout-container mobile` : `base-layout-container`}>
        <div className="base-layout">
          <div className={checkPathname() ? "base-layout-contents" : "contents-container"}>
            {/* <Initializer> */}
            <VariousProvider>
              <RoutesList />
            </VariousProvider>
            {/* </Initializer> */}
          </div>
        </div>
      </main>
      {isApiLoading() && createPortal(<LoadingBar />, root!)}
      <ToastList />
      <ModalList />
      {errorModals.length > 0 && <ErrorModal />}
    </>
  );
}

export default memo(Layout);
