import { useCallback, useEffect } from "react";

import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseSection from "../../../../components/layout/BaseSection";
import { ViewYmdFormat } from "src/utils";
import moment from "moment";
import ContractImageUpload from "../ContractImageUpload";

interface BaseProps {
  detailData: any;
}

const ExpenseDocumentationDetail = ({ detailData }: BaseProps) => {
  const { expenseProofData } = detailData;
  const initDatas = useCallback(async () => {}, []);

  useEffect(() => {
    initDatas();
    console.log(["expenseProofData", expenseProofData]);
  }, [initDatas]);
  return (
    <ArticleSection
      title={"지출 증빙 자료"}
      children={
        <div>
          <div>
            <div className="flex-row flex-center">
              <BaseSection
                title={"신청 일시"}
                value={expenseProofData?.createdDate ? moment(expenseProofData?.createdDate).format(ViewYmdFormat.FULL) : "-"}
              />
              <BaseSection
                title={"현금영수증/세금계산서"}
                value={expenseProofData?.expenseProofType ? (expenseProofData?.expenseProofType === "CASHBILL" ? "현금영수증" : "세금계산서") : "-"}
              />
            </div>
            {expenseProofData?.expenseProofType !== "CASHBILL" ? (
              <div>
                <div className="flex-row flex-center">
                  <BaseSection title={"접수상태"} value={expenseProofData !== null ? "접수" : "미접수"} />
                  <BaseSection title={"사업자 등록번호"} value={expenseProofData?.identityNum ? expenseProofData?.identityNum : "-"} />
                </div>
                <div className="flex-row flex-center">
                  <BaseSection
                    title={"사업자 등록증 사본"}
                    children={<ContractImageUpload isOnlyView type={"PROOF"} images={expenseProofData?.mediaList} />}
                  />
                  <BaseSection title={"수신 이메일"} value={expenseProofData?.taxInvoiceEmail ? expenseProofData?.taxInvoiceEmail : "-"} />
                </div>
                <div className="flex-row flex-center">
                  <BaseSection title={"유선전화번호"} value={expenseProofData?.taxInvoicePhone ? expenseProofData?.taxInvoicePhone : "-"} />
                  <BaseSection
                    title={"계산서 작성일자(신청)"}
                    value={expenseProofData?.taxInvoiceDate ? moment(expenseProofData?.taxInvoiceDate).format(ViewYmdFormat.YYYY_MM_DD) : "-"}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="flex-row flex-center">
                  <BaseSection
                    title={"식별타입"}
                    value={
                      expenseProofData?.identityType !== null && expenseProofData?.identityType !== ""
                        ? expenseProofData?.identityType === "MOBILE_NUM"
                          ? "휴대폰번호"
                          : "현금영수증 카드번호"
                        : "-"
                    }
                  />
                  <BaseSection title={"식별번호"} value={expenseProofData?.identityNum ? expenseProofData?.identityNum : "-"} />
                </div>
                <div className="flex-row flex-center">
                  <BaseSection
                    title={"현금영수증 거래구분 "}
                    value={
                      expenseProofData?.tradeUsage !== null && expenseProofData?.tradeUsage !== ""
                        ? expenseProofData?.tradeUsage === "INCOME"
                          ? "소득공제용"
                          : "지출증빙용"
                        : "-"
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};
export default ExpenseDocumentationDetail;
