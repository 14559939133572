import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import { GetContractDashboardListParams, GetContractDashboardListResponse } from "./type";

export const getContractDashboardList = async (
  axios: AxiosInstance,
  params?: GetContractDashboardListParams,
): Promise<AxiosResponse<ApiResponse<GetContractDashboardListResponse>>> => {
  return await axios.get(ApiPath.contract.dashboardList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { encode: true }),
    },
  });
};
