import { useRef, useState } from "react";
import Select from "react-select";
export interface BaseSelectState {
  placeholder?: string;
  stateOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
  }>;
  value?: string | number | null;
  name?: string;
  setStateValue: Function;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
  menuPosition?: "fixed" | "absolute";
  menuPortalTarget?: HTMLElement | undefined;
  menuPlacement?: "auto";
  maxMenuHeight?: number;
}

export const BaseSelect = (props: BaseSelectState) => {
  let selectedValue = null; // null 로 초기화 해야 선택안함이 정상적으로 초기화됨
  const maxMenuHeight = props.maxMenuHeight ?? 300;
  const stateOptions = props.stateOptions;
  if (props.value) {
    selectedValue = props.stateOptions.find((option) => String(option.value) === String(props.value));
  }

  const selectRef = useRef<HTMLDivElement | null>(null);
  const [menuPlacement, setMenuPlacement] = useState<"top" | "bottom">("bottom");

  const handleMenuOpen = () => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();

      const BelowHeight = window.innerHeight - rect.bottom;

      const itemHeight = 36;
      const itemCount = stateOptions?.length;
      const itemListHeight = itemHeight * itemCount;

      //메뉴의 최대 높이보다 아이템 토탈 높이가 작으면 리턴, 작으면 itemListHeight 리턴
      const menuHeight = maxMenuHeight > itemListHeight ? itemListHeight : maxMenuHeight;

      //셀랙트 위치 기준 바텀 여유 공간이 메뉴의 높이보다 작으면 위로
      //패딩 + 마진 값 20px 추가
      if (BelowHeight < menuHeight + 20) {
        setMenuPlacement("top");
      } else {
        setMenuPlacement("bottom");
      }
    }
  };

  return (
    <div ref={selectRef as any} className={`w-100  base-select ${props.className ? props.className : ""}`}>
      <Select
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props?.placeholder || "선택해주세요"}
        isSearchable={false}
        value={selectedValue ?? null}
        className={`react-select-container`}
        classNamePrefix="react-select"
        onChange={(e) => {
          e && props.setStateValue(e.value);
        }}
        options={stateOptions}
        menuPortalTarget={props.menuPortalTarget}
        isDisabled={props.isDisabled}
        menuPosition={props.menuPlacement === "auto" ? "fixed" : props.menuPosition || "absolute"}
        menuPlacement={menuPlacement as any}
        onMenuOpen={handleMenuOpen}
        maxMenuHeight={props.maxMenuHeight}
        // closeMenuOnScroll={() => props.menuPlacement === "auto"}
        // menuIsOpen={true}
      />
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
