import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { WorkOrderPreivew } from "src/api/work-order/workorder-types";
import { BaseDatePicker } from "src/components";
import { YmdFormat } from "src/utils/common-util";

type Props = {
  previews: WorkOrderPreivew[];
  setPreviews: Dispatch<SetStateAction<WorkOrderPreivew[]>>;
};

const WorkOrderPreviews = ({ previews, setPreviews }: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [previews]);

  return (
    <section className="contents-container__grid mt30" ref={previews.length > 0 ? elementRef : null}>
      <div className="contents-container__grid-index">
        <p>스케줄 미리보기</p>
      </div>
      <div className="contents-container__grid-contents workorder-table-height">
        <table className="inner-table " width="925">
          <thead className="">
            <tr className="">
              <th className="bg-white border-y">
                <div>
                  <span>No</span>
                </div>
              </th>
              <th className="bg-white border-y">
                <span>작업자</span>
              </th>
              <th className="bg-white border-y">
                <span>업무 기간</span>
              </th>
              <th className="bg-white border-y">
                <span>출입 가능시간</span>
              </th>
            </tr>
          </thead>
          <tbody className="">
            {previews.map((preview: WorkOrderPreivew, idx) => (
              <tr key={idx}>
                <td width={80}>
                  <div className="flex-center-center mr10">{idx + 1}</div>
                </td>
                <td width={130}>
                  <div className="flex-center-center">{preview.officerName}</div>
                </td>
                {/* 업무기감 */}
                <td width={320}>
                  <section className="flex-center-center">
                    <div className="flex-center">
                      <BaseDatePicker
                        dateFormat="yyyy.MM.dd (eee)"
                        className="minmax130 mr10"
                        selectedDate={moment(preview.startDate).toDate()}
                        setDate={() => {}}
                        disabled
                      />
                    </div>
                    <div className="flex-center ml20">
                      <BaseDatePicker
                        dateFormat="yyyy.MM.dd (eee)"
                        className="minmax130 mr10"
                        minDate={moment(preview.startDate).toDate()}
                        // selectedDate={(preview.recurrenceType === RecurrenceType.DAILY && moment(preview.endDate).toDate()) || null}
                        selectedDate={moment(preview.endDate).toDate() || null}
                        setDate={(date: string) => {
                          const endDate = moment(date).format(YmdFormat.WITH_TIME_ZONE);

                          const updatedPreviews = previews.map((preview, selectedIdx) => ({
                            ...preview,
                            endDate: idx === selectedIdx ? endDate : preview.endDate,
                          }));

                          setPreviews(updatedPreviews);
                        }}
                      />
                    </div>
                  </section>
                </td>
                {/* 출입시간 */}
                <td width={320}>
                  <section className="flex-center-center">
                    <BaseDatePicker
                      className="minmax110 "
                      type="time"
                      setDate={(date: Date) => {
                        const updatedPreviews = previews.map((preview, selectedIdx) => ({
                          ...preview,
                          startTime: idx === selectedIdx ? date : preview.startTime,
                        }));
                        setPreviews(updatedPreviews);
                      }}
                      selectedDate={moment(preview.startTime).toDate() || null}
                      placeholder="선택"
                    />
                    <span className="mx30">-</span>
                    <BaseDatePicker
                      className="minmax110"
                      type="time"
                      setDate={(date: Date) => {
                        const updatedPreviews = previews.map((preview, selectedIdx) => ({
                          ...preview,
                          endTime: idx === selectedIdx ? date : preview.endTime,
                        }));

                        setPreviews(updatedPreviews);
                      }}
                      selectedDate={moment(preview.endTime).toDate() || null}
                      placeholder="선택"
                      minTime={moment(preview.startTime).add("m", 10).toDate()}
                    />
                  </section>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default WorkOrderPreviews;
