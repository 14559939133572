import { ProductTypeLabels, ProductTypeT } from "src/pages/product/product-types";
/** 상품고도화 v1.9 에 대응하는 상품유형 태그 컴포넌트  */
const ProductTypeChangeLabel = ({ productType }: { productType: ProductTypeT }) => {
  return (
    <div className={`product_type_tag ${productType}`}>
      {/* 태그 공백제거 */}
      <span>{ProductTypeLabels[productType]}</span>
    </div>
  );
};
export default ProductTypeChangeLabel;
