import { IMenu, IMenuDoubleSection, ISection, doubleType } from "../types";
import DashboardMenu from "./DashboardMenu";

import Link from "src/components/PartnerLink";

function DashboardSection({ title, doubleSection, totalPath, sectionName }: ISection) {
  const findTotal = (doubleSection: IMenuDoubleSection[]) => {
    const result = doubleSection.map((type) => {
      const sumOfSection = type.element.reduce((acc: number, cur: IMenu) => {
        // 완료예정, 처리지연은 카운트 갯수에 포함하지않음
        if (cur.title !== "완료예정" && cur.title !== "처리지연") {
          acc = Number(acc) + Number(cur.count);
        }
        return acc;
      }, 0);
      return sumOfSection;
    });
    return result.reduce((acc, cur) => (acc += cur));
  };
  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title">
        <div className="flex-center">
          <h2>{title}</h2>
          <Link className="font14 ml5 no-wrap text-underline" to={totalPath}>
            {(findTotal(doubleSection) >= 0 && findTotal(doubleSection)) || 0}건
          </Link>
        </div>
      </div>
      {doubleSection.map((type, idx) =>
        type.type === doubleType.bar ? (
          <div className="dashboard-bar" key={type.type + idx}>
            {type.element.map((menu, index) => (
              <DashboardMenu {...menu} sectionName={sectionName} type={type.type} key={JSON.stringify(menu) + index} />
            ))}
          </div>
        ) : (
          <div className="dashboard-section-grid" key={type.type}>
            {type.element.map((menu, index) => {
              return <DashboardMenu {...menu} sectionName={sectionName} type={type.type} key={JSON.stringify(menu) + index} />;
            })}
          </div>
        ),
      )}
    </article>
  );
}

export default DashboardSection;
