import { MouseEventHandler } from "react";

type prop = {
  title: string;
  subTitle?: string;
  lastLogin?: string;
  className?:string;
  onClick?:MouseEventHandler<HTMLDivElement>;
};

export const PartnerBox = ({ className, title, subTitle, lastLogin, onClick }: prop) => {
  return (
    <div className={`partner-box ${className || ''}`} onClick={onClick}>
      <p className="partner-box__title">{title}</p>
      <p className="partner-box__sub-title">{subTitle}</p>
      <div className="partner-box__last-login">
        <p>{lastLogin}</p>
      </div>
    </div>
  );
};

export default PartnerBox;
