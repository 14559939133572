import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { WorkOrderListAsync } from "src/api/work-order/workorder-api";
import { WorkOrderDetailModel, WorkOrderListModel, WorkOrderQueryParams, WorkOrderUpdateModel } from "src/api/work-order/workorder-types";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";
import { WorkOrderStatus, workOrderStatus } from "src/pages/workOrder/workOrder-types";
import { onChangeKoreanDays } from "src/utils";
import WorkOrderGroupTable from "./WorkOrderGroupTable";
type Props = {
  workOrder: WorkOrderUpdateModel | WorkOrderDetailModel;
  allCancel?: boolean;
};

const WorkOrderGroupList = ({ workOrder, allCancel }: Props) => {
  const location = useLocation();
  //
  const WorkOrderGroupColumns = useMemo(() => {
    return [
      {
        Header: "id",
        accessor: "id",
        width: 100,
        Cell: ({ value }: Cell<WorkOrderListModel>) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: "업무 번호",
        accessor: "workOrderSheetNo",
        width: 190,
        Cell: ({ row, value }: Cell<WorkOrderListModel>) => {
          const formPath = PagePath.workOrder.authenticated.form + `?id=${row.original.id}`;
          const detailPath = `${PagePath.workOrder.authenticated.detail.replace(":id", String(row.original.id))}?worksheetNo=${value}`;

          const path = location.pathname.includes("form") ? formPath : detailPath;
          return (
            <Link to={path} className="text-underline">
              {/* {value} */}
              {`${value.slice(0, 8)}-${value.slice(8, 13)}`}
            </Link>
          );
        },
      },

      {
        Header: "업무 시작일",
        accessor: "startDate",
        width: 160,
        Cell: ({ value }: Cell<WorkOrderListModel>) => {
          return (
            <div>
              <span>{value && moment(value).format("YYYY.MM.DD")}</span>
              <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
            </div>
          );
        },
      },
      {
        Header: "처리기한",
        accessor: "endDate",
        width: 160,
        Cell: ({ value }: Cell<WorkOrderListModel>) => {
          return (
            <div>
              <span>{value && moment(value).format("YYYY.MM.DD")}</span>
              <span className="ml5">{`(${onChangeKoreanDays(value)})`}</span>
            </div>
          );
        },
      },
      {
        Header: "진행 상태",
        accessor: "status",
        width: 100,
        Cell: ({ value }: Cell<WorkOrderListModel>) => {
          let status = workOrderStatus.find((option) => option.value === value)?.label;
          let color = workOrderStatus.find((option) => option.value === value)?.tagColor;
          return (
            <>
              {allCancel && status !== "취소" && status !== "종료" ? (
                <span className={`font12 ${workOrderStatus.find((option) => option.value === WorkOrderStatus.CANCELED)?.tagColor}`}>취소</span>
              ) : (
                <span className={`${color} font12`}>{status}</span>
              )}
            </>
          );
        },
      },
      {
        Header: "작업자",
        accessor: "mgmtOfficerName",
        width: 200,
      },
    ];
  }, [allCancel]);
  //
  //
  // 로딩바

  const queryParams: WorkOrderQueryParams = useMemo(() => {
    const _queryParams: WorkOrderQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (value) => value,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 999;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "startDate", direction: "ASC" }],
      };
    }
    if (!_queryParams.keywordList) {
      _queryParams.keywordList = workOrder.workOrderSheetGroupId || "";
    }

    if (!_queryParams?.searchType) {
      _queryParams.searchType = "GROUP_ID";
    }

    return _queryParams;
  }, [location.search, workOrder.workOrderSheetGroupId]);

  // 워크오더 리스트
  const [workOrders, setWorkOrders] = useState<Array<WorkOrderListModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);

  const [params, setParams] = useState<WorkOrderQueryParams>({ ...queryParams });

  // 워크오더 목록 조회 api
  const { executeAsync: getWorkOrderList } = useApiOperation(WorkOrderListAsync);

  const getWorkOrderListApi = useCallback(
    async (workOrderParams: WorkOrderQueryParams) => {
      const response = await getWorkOrderList(workOrderParams);
      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setWorkOrders(result || []);

        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getWorkOrderList],
  );

  useEffect(() => {
    getWorkOrderListApi(queryParams);
  }, [getWorkOrderListApi, queryParams, workOrder.status]);

  const groupWorkOrderPagination = useCallback(
    async (passParams?: WorkOrderQueryParams) => {
      const newQueryParams = { ...(passParams || {}) };
      const page = newQueryParams.page;
      const searchParams: WorkOrderQueryParams = {
        page,
        size: queryParams.size,
        sort: newQueryParams.sort,
        searchType: queryParams.searchType,
        keywordList: queryParams.keywordList,
      };

      const response = await getWorkOrderList(searchParams);
      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setWorkOrders(result);
      }

      setPageNum(Number(page));
    },
    [getWorkOrderList, queryParams],
  );

  return (
    <>
      <WorkOrderGroupTable
        data={workOrders}
        columns={WorkOrderGroupColumns}
        workOrderId={String(workOrder.id || "")}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            setParams({ ...params, sort: { orders } });
            groupWorkOrderPagination({ sort: { orders }, page: pageNum });
          }
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={[
          "id",
          "workOrderSheetGroupId",
          "workOrderSheetNo",
          "status",
          // "endDate",
          "mgmtOfficerName",
        ]}
      />
    </>
  );
};

export default WorkOrderGroupList;
