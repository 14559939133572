import { ReactNode } from "react";

type props = {
  maxHeight: number;
  children?: ReactNode;
  hideScroll?: boolean;
};

const BaseScroll = ({ maxHeight, children, hideScroll }: props) => {
  return (
    <div style={{ maxHeight: maxHeight }} className={hideScroll ? "base-scroll scroll-hidden" : "base-scroll"}>
      {children}
    </div>
  );
};

export default BaseScroll;
