import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BaseScroll from "src/components/BaseScroll";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";
import { numberToStringWithComma, ViewYmdFormat } from "src/utils";
import BaseSection from "../../../../components/layout/BaseSection";
import useContractApi from "../../hooks/useContractApi";
import useEstimateApi from "../../hooks/useEstimateApi";
import useProductFacilityApi from "../../hooks/useProductFacilityApi";
import { BaseTooltip } from "src/components";

interface BaseProps {
  detailData: any;
  viewOnly?: boolean;
}

const EstimateDetail = ({ detailData, viewOnly }: BaseProps) => {
  const { partnerId } = useParams();
  const { getProductData } = useContractApi();
  const { getEstimateList } = useEstimateApi();
  const { makeSpaceList } = useProductFacilityApi();

  const [estimateData, SetEstimateData] = useState([]);

  const initDatas = useCallback(async () => {
    if (detailData?.contract?.quotationList) {
      let ids = "";
      detailData?.contract?.quotationList.forEach((list: any) => {
        if (ids !== "") {
          ids = ids + "," + list.quotationId;
        } else {
          ids = list.quotationId;
        }
      });

      let tableData: any = [];
      const result = await getProductData(Number(detailData?.contractManage?.spaceProductId));
      if (result) {
        tableData = await makeSpaceList(result);
      }
      const response = await getEstimateList({ id: ids });
      if (response.data.data.content) {
        response.data.data.content.forEach((item: any) => {
          const find: any = tableData.find((list: any) => list.id === item?.quotationItemList[0].buildingCommonFacilityId);
          console.log("find", find);
          if (find) {
            item.buildingCommonFacilityId = item?.quotationItemList[0].buildingCommonFacilityId;
            item.buildingCommonFacilityName = find.facilityName;
            item.commonFacilityType =
              find.commonFacilityType === "MEETING_ROOM" ? "회의실" : find.commonFacilityType === "REFRESH_ROOM" ? "휴게공간" : "좌석";
            item.facilityMaxPeopleNums = find.facilityMaxPeopleNums;
            item.isGround = find.isGround;
            item.floorNum = find.floorNum;
          } else {
            item.buildingCommonFacilityId = 0;
            item.buildingCommonFacilityName = "없는 공용공간입니다.";
            item.commonFacilityType = "유형이 없습니다.";
            item.facilityMaxPeopleNums = 0;
            item.isGround = false;
            item.floorNum = 0;
          }
          item.subRows = item?.quotationItemList;
        });
        SetEstimateData(response.data.data.content);
      }
    }
  }, [detailData?.contract?.quotationList, detailData?.contractManage?.spaceProductId, getEstimateList, getProductData, makeSpaceList]);

  const moveProductFacility = (productId: string) => {
    return PagePath.product.detail.replace(":partenrId", String(partnerId)).replace(":id", productId) + "?tab=commonSpace";
  };

  useEffect(() => {
    initDatas();
  }, [initDatas]);
  return (
    <BaseScroll maxHeight={600}>
      {estimateData.map((item: any, index: number) => {
        return (
          <div key={index} className={index === 0 ? "" : "mt20"}>
            <div>
              <div className="flex-row flex-center">
                <BaseSection title={"id(견적서 고유ID)"} value={item.id + " (" + item.quotationNo + ")"} />
                <BaseSection title={"견적서 총액(부가세 별도)"} value={numberToStringWithComma(item.amount) + " 원"} />
              </div>
              <div className="flex-row flex-center">
                <BaseSection title={"이용 시작 시간"} value={moment(detailData?.contract?.useStartTime).format(ViewYmdFormat.YYYY_MM_DD_HH_MM)} />
                <BaseSection title={"이용 종료 시간"} value={moment(detailData?.contract?.useEndTime).format(ViewYmdFormat.YYYY_MM_DD_HH_MM)} />
              </div>
            </div>
            <BaseSection
              title={"세부 견적서"}
              children={
                <div className="flex-row d-flex flex-start w-100">
                  <section className="contents-container__grid">
                    <div className="flex-row flex-start">
                      <div className="base-table">
                        <div className="header">
                          <div className="flex-row flex-start base-table__tr">
                            <div className="base-table__th minmax200">공용공간명</div>
                            <div className="base-table__th minmax100">유형</div>
                            <div className="base-table__th minmax160">층</div>
                            <div className="base-table__th minmax160">정원</div>
                            <div className="base-table__th minmax220">금액(부가세 별도)</div>
                          </div>
                        </div>
                        <div className="body">
                          {item.subRows.map((row: any, idx: number) => {
                            return (
                              <div className={`base-table__tr flex-row flex-start `} key={idx}>
                                <div className="base-table__td minmax200 text-left">
                                  {item.buildingCommonFacilityId !== 0 ? (
                                    <Link to={moveProductFacility(detailData?.product?.id)} className="text-underline" target={"_blank"}>
                                      <p>
                                        {item.buildingCommonFacilityName.length > 15
                                          ? item.buildingCommonFacilityName.substring(0, 12) + "..."
                                          : item.buildingCommonFacilityName}
                                      </p>
                                      {item.buildingCommonFacilityName.length > 15 && (
                                        <BaseTooltip contents={""} tooltip={item.buildingCommonFacilityName} touchIcon={"QUESTION"} />
                                      )}
                                    </Link>
                                  ) : (
                                    <div>
                                      <p>
                                        {item.buildingCommonFacilityName.length > 15
                                          ? item.buildingCommonFacilityName.substring(0, 12) + "..."
                                          : item.buildingCommonFacilityName}
                                      </p>
                                      {item.buildingCommonFacilityName.length > 15 && (
                                        <BaseTooltip contents={""} tooltip={item.buildingCommonFacilityName} touchIcon={"QUESTION"} />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="base-table__td minmax100">
                                  <p>{item.commonFacilityType}</p>
                                </div>
                                <div className="base-table__td minmax160">
                                  <p>{item.isGround ? "지상 " + item.floorNum + "층" : "지하 " + item.floorNum + "층"}</p>
                                </div>
                                <div className="base-table__td minmax160">
                                  <p>{item.facilityMaxPeopleNums}명</p>
                                </div>
                                <div className="base-table__td minmax220 flex-center-end">
                                  <p>{numberToStringWithComma(row.price)} 원</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              }
            />
          </div>
        );
      })}
    </BaseScroll>
  );
};
export default EstimateDetail;
