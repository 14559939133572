export const vocSearchType = [
  {
    value: "ALL",
    label: "전체",
  },
  {
    value: "ID",
    label: "id",
  },
  {
    value: "NAME",
    label: "항목명",
  },
];
