import { useEffect, useState } from "react";
import { DetailTotalData } from "src/api/contract/contract-types";
import { ProviderAddModel } from "src/api/provider/provider-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import PagePath from "src/pagePath.json";
import { numberToStringWithComma } from "src/utils";
import BaseSection from "../../../../components/layout/BaseSection";
import { BaseTooltip } from "src/components/BaseTooltip";

/**
 * 상품정보 영역
 * Props
 * { productDetail,
 *   providerDetail }
 */
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}

const ContractProductDetail = ({ detailData }: ContractBasicProp) => {
  const [productDetail, setProductDetail] = useState<any>();
  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();

  //상품 상세 페이지로 이동
  const goProductDetail = (id: number) => {
    let path = "";
    if (id) {
      path = PagePath.product.detail.replace(":id", id!.toString());
    }
    return path;
  };

  //provider 상세 페이지로 이동
  const goProviderDetail = (id: number) => {
    let path = "";
    if (id) {
      path = PagePath.provider.detail.replace(":id", id!.toString());
    }
    return path;
  };

  useEffect(() => {
    if (detailData) {
      setProductDetail(detailData.product);
      setProviderDetail(detailData.provider);
    }
  }, [detailData]);

  return (
    <>
      <div className="flex-center gap10">
        <BaseSection
          title={"상품명"}
          children={
            <div className="flex-row flex-center-start w-100 font14">
              <div className={detailData?.product?.productName.length > 15 ? "" : "mr5"}>
                {detailData?.product?.productName !== "" && detailData?.product?.productName.length > 15 ? (
                  <div className={"flex-center-start"}>
                    <p>{detailData?.product?.productName!.substring(0, 15) + "..."}</p>
                    <BaseTooltip contents={""} tooltip={detailData?.product?.productName} touchIcon={"QUESTION"} />
                  </div>
                ) : (
                  detailData?.product?.productName
                )}{" "}
              </div>
              <span className="no-wrap">(id:</span>
              <BaseNewTabLink path={goProductDetail(productDetail?.id)} value={productDetail?.id || "-"} ellipsis />)
            </div>
          }
        />
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"보증금"}
            children={<p className="mr10 font-weight-bold">{productDetail?.deposit ? numberToStringWithComma(productDetail?.deposit) : "-"} 원</p>}
          />
        )}
      </div>
      <div className="flex-center gap10">
        <BaseSection title={"상품유형"} children={<ProductTypeChangeLabel productType={productDetail?.productType} />} />
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection title={"ㄴ 계약금"} children={<p>{productDetail?.earnest ? numberToStringWithComma(productDetail?.earnest) : "-"} 원</p>} />
        )}
      </div>
      <div className="flex-center gap10">
        <BaseSection
          title={"프로바이더 명"}
          children={
            <div className="flex-row flex-center-start w-100 font14">
              <div
                className={
                  providerDetail?.provider?.providerName !== "" &&
                  providerDetail?.provider?.providerName !== undefined &&
                  providerDetail?.provider?.providerName!.length > 15
                    ? ""
                    : "mr5"
                }
              >
                {providerDetail?.provider?.providerName !== "" &&
                providerDetail?.provider?.providerName !== undefined &&
                providerDetail?.provider?.providerName!.length > 15 ? (
                  <div className={"flex-center-start"}>
                    <p>{providerDetail?.provider?.providerName!.substring(0, 15) + "..."}</p>
                    <BaseTooltip contents={""} tooltip={providerDetail?.provider?.providerName} touchIcon={"QUESTION"} />
                  </div>
                ) : (
                  providerDetail?.provider?.providerName
                )}{" "}
              </div>
              <span className="no-wrap">(id:</span>
              <BaseNewTabLink
                path={goProviderDetail(Number(providerDetail?.provider?.providerId))}
                value={String(providerDetail?.provider?.providerId) || "-"}
                ellipsis
              />
              )
            </div>
          }
        />
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"ㄴ 잔금"}
            children={
              <p>{productDetail?.deposit ? numberToStringWithComma(Number(productDetail?.deposit) - Number(productDetail?.earnest)) : "-"} 원</p>
            }
          />
        )}
      </div>
      <div className="flex-center">
        <BaseSection />
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"월 이용료(부가세 포함)"}
            children={
              <p>
                {Array.isArray(productDetail?.rentalCostList) && productDetail?.rentalCostList[0]?.value1!
                  ? numberToStringWithComma(Number(productDetail?.rentalCostList[0]?.value1) + Number(productDetail?.rentalCostList[0]?.value1 / 10))
                  : "0"}{" "}
                원
              </p>
            }
          />
        )}
      </div>
    </>
  );
};

export default ContractProductDetail;
