import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { useRecoilValue } from "recoil";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { Order, PageMeta } from "src/api/public-types";
import { BaseInput, BasePagination } from "src/components";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { globalPartnerState } from "src/recoil/partners/atom";
import BuildingManagementColumns from "../../../columns/BuildingManagementColumns";

type Props = {
  onClose: () => void;
  onSelected: (building: BuildingModel) => void;
};

type BuilindsQueryParams = {
  id?: string;
  page?: number;
  size?: number;
  status?: string;
  searchTarget?: string;
  keyword?: string;
  isDeleted?: string;
  sort?: {
    orders?: Array<Order>;
  };
  partnerId?: string;
};

const ManagementBuildingModal = ({ onClose, onSelected }: Props) => {
  // 경고모달

  const [isMgmtOffice, setIsMgmtOffice] = useState(true);

  const partner = useRecoilValue(globalPartnerState);

  // 관리처 건물 팝업 columns

  const columns: any = useMemo(() => BuildingManagementColumns, []);

  const location = useLocation();

  const queryParams: BuilindsQueryParams = useMemo(() => {
    const _queryParams: BuilindsQueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (!_queryParams?.partnerId) {
      _queryParams.partnerId = String(partner?.id);
    }

    if (_queryParams.id) {
      delete _queryParams.id;
    }

    return _queryParams;
  }, [location, partner?.id]);

  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<BuilindsQueryParams>({ ...queryParams });
  const [pageNum, setPageNum] = useState(0);

  // 건물 관리처 목록 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  // 프로바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  const fetchBuildings = useCallback(
    async (buildingListParams: BuildingListParams) => {
      const { data } = await getBuildings(buildingListParams);
      const buildingList = data.data.content;

      const providerIds = buildingList.map((item) => item.mgmtProviderId);

      // providerIds 에서 중복id 및 null 제거
      const removedDuplications = providerIds.filter((num, i) => num !== null && providerIds.indexOf(num) === i);
      // providerName 추가하기
      const { data: providers } = await getProviers({
        providerId: removedDuplications.join(", "),
      });

      const newBuildings = buildingList.map((building) => {
        providers.data.content.forEach((provider) => {
          if (String(building.mgmtProviderId) === String(provider.providerId)) {
            building = { ...building, mgmtOfficeName: provider.providerName };
          }
        });
        return building;
      });
      setBuildings(newBuildings || []);

      setPageMeta(data?.meta?.pageMeta);
    },
    [getBuildings, getProviers],
  );
  useEffect(() => {
    // 건물 목록 조회후 데이터 바인딩

    fetchBuildings(queryParams);
  }, [fetchBuildings, queryParams]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = useTable(
    {
      columns,
      data: buildings,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 50,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedOrg"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  // 팝업 빌딩 목록 페이지네이션
  const buildingPagenation = useCallback(
    (passParams?: BuilindsQueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const page = newQueryParams.page;
      setPageNum(Number(page));
      // onSearchList(newQueryParams);
      fetchBuildings(newQueryParams);
    },
    [fetchBuildings, params],
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    // <BaseAbstractModal isOpen={true} className="dialog-modal">
    <>
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>건물/관리처 선택</h1>
        <div className=" right-area">
          <div className="minmax260">
            <BaseInput
              className=""
              placeholder="검색어를 입력하세요"
              onChange={(keyword: string) => setParams({ ...params, keyword })}
              value={params.keyword}
              onSearchClick={() => fetchBuildings({ ...params, page: 0 })}
              onKeyUp={() => fetchBuildings({ ...params, page: 0 })}
            />
          </div>
        </div>
      </section>

      {!isMgmtOffice && (
        <div className="flex-center-start ml30 mb5">
          <p className="validation-text">{"관리처가 등록된 건물만 선택 가능합니다."}</p>
        </div>
      )}

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table px30">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps(header.getSortByToggleProps())} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {buildings.length && buildings.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={pageNum || 0}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size });
            buildingPagenation({ ...params, size, page: 0 });
          }}
          goPage={(page: number) => {
            setParams({ ...params, page });
            buildingPagenation({ ...params, page });
          }}
          totalElements={pageMeta?.totalElements || 0}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => onClose()}>
                취소
              </button>
              <button
                className="base-btn"
                onClick={() => {
                  if (!selectedFlatRows[0]) {
                    return;
                  }
                  const selected = selectedFlatRows[0].original;
                  const mgmtOfficeId = selected.mgmtOfficeId;
                  const mgmtProviderId = selected.mgmtProviderId;
                  if (mgmtProviderId === "0" || mgmtOfficeId === "") {
                    setIsMgmtOffice(false);
                    return;
                  } else {
                    onSelected(selected);
                    onClose();
                  }
                }}
              >
                선택
              </button>
            </div>
          }
        />
      </section>
    </>
    // </BaseAbstractModal>
  );
};

export default ManagementBuildingModal;
