import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AuthProviderState from "./AuthProviderState";
import Container from "./components/layout/Container";
import PartnerProviderState from "./PartnerProviderState";
import ReactQueryProvider from "./ReactQueryProvider";

function App() {
  // TODO: throttle 적용
  useEffect(() => {
    // css property 사용하여 ios 모바일에서 스크롤 생기는 문제 해결.
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const setViewportHeightVariable = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setViewportHeightVariable);

    return () => {
      window.removeEventListener("resize", setViewportHeightVariable);
    };
  }, []);

  return (
    <div className="App">
      <RecoilRoot>
        <BrowserRouter>
          <AuthProviderState>
            <PartnerProviderState>
              <ReactQueryProvider>
                <Container />
              </ReactQueryProvider>
            </PartnerProviderState>
          </AuthProviderState>
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
}

export default App;
