// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

// 공지 유형
export enum ContainsTypes {
  SERVICE = "SERVICE",
  SPACE = "SPACE",
}

export const NoticeCategory = {
  NOTICE_CATEGORY_NONE: "NOTICE_CATEGORY_NONE", // 정의되지 않음
  FACILITY_MAINTENANCE: "FACILITY_MAINTENANCE", // 시설 유지/보수 (점검/보수)
  MOVE_IN_GUIDE: "MOVE_IN_GUIDE", // 입주 안내
  FACILITY_GUIDE: "FACILITY_GUIDE", // 시설 안내
  EVENTS_BENEFITS: "EVENTS_BENEFITS", // 행사/혜택
  SURVEY: "SURVEY", // 설문 조사
  APP_GUIDE: "APP_GUIDE", // 앱 안내
  ETC: "ETC", // 기타
} as const;

export const noticeCategoryToText = (noticeCategory: string) => {
  switch (noticeCategory) {
    case NoticeCategory.NOTICE_CATEGORY_NONE:
      return "-";
    case NoticeCategory.FACILITY_MAINTENANCE:
      return "점검/보수";
    case NoticeCategory.MOVE_IN_GUIDE:
      return "입주 안내";
    case NoticeCategory.FACILITY_GUIDE:
      return "시설 안내";
    case NoticeCategory.EVENTS_BENEFITS:
      return "행사/혜택";
    case NoticeCategory.SURVEY:
      return "설문 조사";
    case NoticeCategory.APP_GUIDE:
      return "앱 안내";
    case NoticeCategory.ETC:
      return "기타";
    default:
      return '-';
  }
};
