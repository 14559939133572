import { useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { CsItemModel, CsServiceItemModel, VocQueryParams } from "src/api/voc/voc-types";
import { BaseButton, BaseCheckbox, BaseInputWithSearch, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import { useCsOptions } from "src/pages/voc/hooks/useCsOptions";
import { useVocList } from "src/pages/voc/hooks/useVocList";
import { vocSearchType } from "src/pages/voc/voc-options";
import { CsItemColumns } from "./columns/CsItemColumns";

type Props = {
  onClose: () => void;
  serviceId: string;
  onSubmit: (passData: CsServiceItemModel[]) => Promise<void>;
  csServiceList: CsServiceItemModel[];
};
const CsItemsModal = ({ onClose, onSubmit, serviceId, csServiceList }: Props) => {
  const csServiceIds = useMemo(() => csServiceList.map((item) => item.csItemId), [csServiceList]);

  // 체크박스된 데이터
  const [selectedItems, setSelectedItems] = useState<Array<CsServiceItemModel & { checked: boolean }>>([]);

  const [params, setParams] = useState<VocQueryParams>({
    page: 0,
    size: 20,
    sort: {
      orders: [{ property: "id", direction: "DESC" }],
    },
  });

  const [searchParams, setSearchParams] = useState<VocQueryParams>(params);

  // voc 유형
  const { options: vocOptions } = useCsOptions("VoC");

  const { list: vocList, pageMeta } = useVocList(params);

  const newVocList = useMemo(
    () =>
      vocList.map((item) => {
        const selects = selectedItems.map((select: any) => Number(select.csItemId));
        return {
          checked: selects.includes(Number(item.id)),
          ...item,
        };
      }),
    [selectedItems, vocList],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: CsItemColumns,
      data: newVocList,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          width: 80,
          Header: "선택",
          accessor: "checked",
          Cell: ({ row }: Cell<CsItemModel & { checked: boolean }>) => {
            return (
              <div>
                <BaseCheckbox
                  id={`voc ${row.original.id}`}
                  name={`voc ${row.original.id}`}
                  checked={row.original.checked}
                  disabled={csServiceIds.includes(row.original.id)}
                  onChange={(checked: boolean) => {
                    row.original.checked = checked;
                    if (checked) {
                      const data: CsServiceItemModel & { checked: boolean } = {
                        serviceType: ServiceTypes.SERVICE_BUILDING,
                        csItemId: row.original.id,
                        serviceId,
                        isFrequent: false,
                        cmdType: "C",
                        checked: row.original.checked,
                      };
                      setSelectedItems((prev) => [...prev, data]);
                    } else {
                      setSelectedItems((prev) => prev.filter((item) => item.csItemId !== row.original.id));
                    }
                  }}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <div>
      <BaseAbstractModal className="dialog-modal " isOpen={true}>
        {/*table */}
        <section className="base-abstract-modal__title flex-center-between ">
          <h1>항목 선택</h1>
          <div className="right-area">
            <div className="d-flex">
              <div className="minmax140 mr8">
                <BaseMultiSelect
                  stateOptions={[{ label: "전체", value: "" }, ...vocOptions]}
                  value={(params?.csTypeIdList?.split(",").map(String) as string[]) || []}
                  setStateValue={(csTypeIdList: Array<{ value: string; label: string }>) => {
                    if (csTypeIdList.length === 0) {
                      setParams((prev) => {
                        delete prev.csTypeIdList;
                        return { ...prev };
                      });
                    } else {
                      setParams({ ...params, csTypeIdList: csTypeIdList.map((item) => item.value).join(","), page: 0 });
                    }
                  }}
                  placeholder="유형"
                />
              </div>
              <BaseInputWithSearch
                type="text"
                selectValue={searchParams?.searchType}
                stateOptions={vocSearchType}
                setStateValue={(searchType: string) => {
                  setSearchParams({ ...searchParams, searchType });
                }}
                inputValue={searchParams?.keywordList ? String(searchParams?.keywordList) : ""}
                onChange={(keywordList: string) => {
                  setSearchParams({ ...searchParams, keywordList });
                }}
                onKeyUp={() => {
                  setParams({ ...params, keywordList: searchParams.keywordList, searchType: searchParams.searchType });
                }}
                onSearchClick={() => {
                  setParams({ ...params, keywordList: searchParams.keywordList, searchType: searchParams.searchType });
                }}
              />
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__contents ">
          <div className="contents-container__table px30 ">
            <div {...getTableProps()} className="base-table view-data-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.length > 0 ? (
                  rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            sizeOption={(size) => {
              setParams({ ...params, ...{ page: 0, size } });
              // getAccessGroupsList({ ...params, page: 0, size });
            }}
            currentSize={Number(params.size) || 20}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              setParams({ ...params, ...{ page } });
              // getAccessGroupsList({ ...params, page });
            }}
            children={
              <>
                <BaseButton
                  title="취소"
                  onClick={() => {
                    onClose();
                    // setAccessSelect([]);
                  }}
                  className="color-white mr10"
                />
                <BaseButton
                  title="선택"
                  onClick={() => {
                    const newData = selectedItems.map(({ checked, ...rest }) => rest);
                    onSubmit(newData);
                    onClose();
                  }}
                />
              </>
            }
          />
        </section>
      </BaseAbstractModal>
    </div>
  );
};

export default CsItemsModal;
