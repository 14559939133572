import { useCallback, useEffect, useState } from "react";
import { Contractor, DetailTotalData } from "src/api/contract/contract-types";
import { BaseRadio, BaseTooltip } from "src/components";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import Link from "src/components/PartnerLink";
import pagePath from "src/pagePath.json";
import BaseSection from "../../../../components/layout/BaseSection";
import BaseImageUpload from "src/components/BaseImageUpload";
import { MbOrgMedia } from "src/api/organization/org-types";
import { formatPhoneNumber } from "src/utils";
import ContractImageUpload from "../ContractImageUpload";
import BaseNewTabLink from "src/components/BaseNewTabLink";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}

const ContractorAndOrganizationDetail = ({ detailData }: ContractBasicProp) => {
  const { contractManage, contractOrganization } = detailData;
  const [contractor, setContractor] = useState<Contractor>();
  const [organization, setOrganization] = useState<any>();
  const [proof, setProof] = useState<MbOrgMedia[]>();
  // const [bi, setBi] = useState<any>();
  const [orgShow, setOrgShow] = useState(false);

  const moveOrg = useCallback((id: number) => {
    let rtn = "";
    if (id) {
      rtn = pagePath.organization.detail.replace(":id", String(id));
    }
    return rtn;
  }, []);

  //데이터 화면용 초기화
  const initDatas = useCallback(() => {
    if (contractManage && contractManage?.contractorList) {
      const findedContractor = contractManage?.contractorList!.find((user: Contractor) => user.isContractor);
      if (findedContractor) {
        setContractor(findedContractor);
      }

      if (contractManage?.mbOrganizationId === "0") {
        setOrgShow(false);
      } else {
        setOrgShow(true);
      }

      setOrganization(contractOrganization?.organization);
      const proofs = contractOrganization?.mediaList!.filter((media: any) => media.type === "PROOF");
      // const bis = contractOrganization?.mediaList!.filter((media: any) => media.type !== "PROOF");
      setProof(proofs);
      // setBi(bis);
    }
  }, [contractManage, contractOrganization?.mediaList, contractOrganization?.organization]);

  useEffect(() => {
    initDatas();
  }, [initDatas]);

  return (
    <>
      <div className="flex-center">
        <BaseSection title={"이메일"} value={contractor?.memberEmail} />
        <BaseSection title={"회원 번호"} value={contractor?.memberNo} />
      </div>
      <div className="flex-center">
        <BaseSection title={"휴대폰"} value={formatPhoneNumber(String(contractor?.memberPhoneNumber))} />
        <BaseSection
          title={"사업자 여부"}
          children={
            <div className="flex-row flex-center-start">
              <BaseRadio
                id="mbOrgId"
                name="mbOrganizationId"
                className="mr10"
                value={"true"}
                label="해당없음"
                checked={String(orgShow) === "false" ? true : false}
                disabled
              ></BaseRadio>
              <BaseRadio
                id="mbOrgId"
                name="mbOrganizationId"
                value={"false"}
                label="개인/법인 사업자"
                checked={String(orgShow) === "true" ? true : false}
                disabled
              ></BaseRadio>
            </div>
          }
        />
      </div>

      {orgShow && (
        <>
          <div className="flex-center">
            <BaseSection
              title={"법인명/상호"}
              children={
                <div className="flex-row flex-center-start w-100 font14">
                  <div className={organization?.name.length > 15 ? "" : "mr5"}>
                    {organization?.name !== "" && organization?.name.length > 15 ? (
                      <div className={"flex-center-start"}>
                        <p>{organization?.name!.substring(0, 15) + "..."}</p>
                        <BaseTooltip contents={""} tooltip={organization?.name} touchIcon={"QUESTION"} />
                      </div>
                    ) : (
                      organization?.name
                    )}{" "}
                  </div>
                  <span className="no-wrap">(id:</span>
                  <BaseNewTabLink path={moveOrg(organization?.id)} value={organization?.id || "-"} ellipsis />)
                </div>
              }
            />
            <BaseSection
              title={"사업자 유형"}
              value={organization?.bizType ? (organization?.bizType === "CORP" ? "법인사업자" : "일반사업자") : "-"}
            />
          </div>
          <div className="flex-center">
            <BaseSection
              title={"대표자/성명"}
              value={organization?.ceoName ? organization?.ceoName : "-"}
              children={
                <div className="flex-row flex-center-start w-100 font14">
                  <div className={organization?.ceoName.length > 15 ? "" : "mr5"}>
                    {organization?.ceoName !== "" && organization?.ceoName.length > 15 ? (
                      <div className={"flex-center-start"}>
                        <p>{organization?.ceoName!.substring(0, 15) + "..."}</p>
                        <BaseTooltip contents={""} tooltip={organization?.ceoName} touchIcon={"QUESTION"} />
                      </div>
                    ) : (
                      organization?.ceoName
                    )}{" "}
                  </div>
                </div>
              }
            />
            <BaseSection title={"사업자 등록번호"} value={organization?.bizRegNo ? organization?.bizRegNo : "-"} />
          </div>
          <div className="flex-center">
            <BaseSection title={"사업장 주소"} children={<BaseTooltip contents={organization?.address ? organization?.address : "-"} />} />
            <BaseSection title={"사업자 등록증 첨부"} children={<ContractImageUpload isOnlyView type="PROOF" images={proof} />} />
          </div>
        </>
      )}
    </>
  );
};

export default ContractorAndOrganizationDetail;
