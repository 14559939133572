import { searchStatusOption } from "src/api/billingSchedule/billing-type";
import { Select } from "src/api/public-types";
type ViewProps = {
  value: string;
  contractStep?: string;
};
const PaymentStatusView = ({ value, contractStep }: ViewProps) => {
  let find = searchStatusOption.find((option: Select) => String(option.value) === value);

  if (!find) return null;
  if (find.value === "" && contractStep && contractStep === "APPLY_CANCEL") {
    find.label = "-";
  }
  if (find.label === "전체") {
    find.label = "-";
  }
  return <div className={`${find?.color}`}>{find.label}</div>;
};
export default PaymentStatusView;
