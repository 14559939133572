import PhoneNumber from "libphonenumber-js";

function PhoneNumberColumn({ phoneNumber }: { phoneNumber: string }) {
  const parsedPhoneNumber = PhoneNumber(phoneNumber);

  if (parsedPhoneNumber) {
    return <div>{parsedPhoneNumber.formatNational()}</div>;
  }

  return <div>{phoneNumber}</div>;
}
export default PhoneNumberColumn;
