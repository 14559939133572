import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import { CsServiceItemModel, CsServiceItemsParams, VocModelList, VocModelUpdate, VocOptionsType, VocQueryParams } from "./voc-types";

/**REQ09. VoC유형 목록 */
export const getVocOptionList = async (
  axios: AxiosInstance,
  params?: {
    csCategoryType: "VoC" | "문의" | "";
  },
): Promise<AxiosResponse<ApiResponse<{ content: VocOptionsType[] }>>> => {
  return await axios.get(ApiPath.csItem.options, { params });
};

/**REQ10. VoC항목 등록/수정/삭제 */

export const submitVocItemAsync = async (
  axios: AxiosInstance,
  data?: VocModelUpdate,
): Promise<AxiosResponse<ApiResponse<{ content: VocOptionsType[] }>>> => {
  return await axios.post(ApiPath.csItem.update, { csItemList: [data] });
};

/**REQ11. VoC항목 목록 */
export const getVocListAsync = async (axios: AxiosInstance, params?: VocQueryParams): Promise<AxiosResponse<ApiResponse<VocModelList>>> => {
  return await axios.get(ApiPath.csItem.list, {
    params,
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, { allowDots: true, encode: true });
      },
    },
  });
};

/** REQ12. 서비스별VoC항목 등록/수정/삭제 */
export const submitCsServiceItemAsync = async (
  axios: AxiosInstance,
  data?: CsServiceItemModel[],
): Promise<AxiosResponse<ApiResponse<CsServiceItemModel[]>>> => {
  return axios.post(ApiPath.csItem.service.update, { csServiceItemList: data });
};

/** REQ13. 서비스별VoC항목 목록 */
export const getCsServiceItemsAsync = async (
  axios: AxiosInstance,
  params?: CsServiceItemsParams,
): Promise<AxiosResponse<ApiResponse<{ content: CsServiceItemModel[] }>>> => {
  return axios.get(ApiPath.csItem.service.list, {
    params,
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, { allowDots: true, encode: true });
      },
    },
  });
};
