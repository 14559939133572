import moment from "moment";
import { useNavigate } from "react-router-dom";
import { BaseTooltip } from "src/components";
import Link from "src/components/PartnerLink";
import { ViewYmdFormat } from "src/utils";
import { IMenu, doubleType } from "../types";
import PagePath from "src/pagePath.json";
// if (title === "완료예정") {
//   return (
//     <Link to={path}>
//       <div className={`dashboard-grid__card ${backgroundColor !== "Default" && `bg-${backgroundColor}900 text-white`}`}>
//         <div className="w-100">
//           <div className="flex-center-between">
//             <p className="text-red w-fit">{title}</p>
//             <BaseTooltip size={16} tooltip={"오늘부터 3개월 이내에 이용이 종료됩니다"} />
//           </div>
//         </div>
//         <p className="w-100 text-right value">{count >= 0 && count}</p>
//       </div>
//     </Link>
//   );
// }

function DashboardMenu({ title, path, count, type, data, showDateProperty, sectionName }: IMenu) {
  const findData = (contents: any, sectionName?: string, showDateProperty?: string) => {
    switch (sectionName) {
      case "contract":
        return {
          path: PagePath.contract.detail.replace(":id", contents.contractId),
          description: contents.spaceProductName,
          date: showDateProperty && contents[showDateProperty] ? moment(contents[showDateProperty]).format(ViewYmdFormat.YYYY_MM_DD) : "",
        };
      case "question":
        return {
          path: PagePath.question.detail.replace(":id", contents.id),
          description: contents.description,
          date: showDateProperty ? moment(contents[showDateProperty]).format(ViewYmdFormat.YYYY_MM_DD) : "",
        };
      case "workorder":
        return {
          path: PagePath.workOrder.authenticated.detail.replace(":id", contents.id) + "?worksheetNo=" + contents.workOrderSheetNo,
          description: contents.summary,
          date: showDateProperty ? moment(contents[showDateProperty]).format(ViewYmdFormat.YYYY_MM_DD) : "",
        };
      case "reservation":
        return { path: PagePath.reservation.detail, description: "" };
      default:
        return { path: "", description: "" };
    }
  };

  const renderTooltip = (title: string) => {
    let contents = <></>;
    switch (title) {
      case "계약예정":
        contents = (
          <>
            아래 날짜는 계약예정일입니다.
            <br />
            계약예정일을 확인하시어 계약을 진행해 주세요.
          </>
        );
        break;
      case "계약체결":
        contents = (
          <>
            아래 날짜는 이용시작일입니다.
            <br />
            이용시작일 전에 이용승인으로 상태 변경이 필요합니다.
          </>
        );
        break;
      case "완료예정":
        contents = (
          <>
            아래 날짜는 이용종료일입니다.
            <br />
            오늘부터 3개월 이내에 계약이 종료 예정입니다.
          </>
        );
        break;
      case "처리지연":
        contents = (
          <>
            아래 날짜는 업무시작일입니다.
            <br />
            업무시작일 기준 가장 오래된 순으로 보여줍니다.
          </>
        );
        break;
      case "대기":
        contents = (
          <>
            아래 날짜는 상담문의의 최초 접수일입니다.
            <br />
            내용 확인하신 후 담당자 지정 및 문의자에게 답변처리가 필요합니다.
          </>
        );
        break;
      case "완료":
        contents = <>아래 날짜는 상품문의의 최근 답변 등록일입니다.</>;
        break;
      case "보류":
        contents = <>아래 날짜는 상품문의의 최근 보류 처리일입니다.</>;
        break;
      default:
        return "";
    }
    return (
      <BaseTooltip touchIcon={"QUESTION"} className="ml6" size={16}>
        {contents}
      </BaseTooltip>
    );
  };

  const renderCard = () => {
    if (type === doubleType.bar) {
      return (
        <div className="dashboard-bar__box">
          <p>{title}</p>
          <Link to={path}>
            <span>{count >= 0 && count}</span>
          </Link>
        </div>
      );
    } else if (type === doubleType.detail) {
      return (
        <div className="dashboard-section-grid__box">
          <div>
            <div className="flex-center">
              <p className={`${title === "완료예정" || title === "지연" ? "text-red" : ""}`}>{title}</p>
              {sectionName !== "question" && renderTooltip(title)}
            </div>
            <Link to={path}>
              <span>{count >= 0 && count}</span>
            </Link>
          </div>
          {data?.length > 0 ? (
            <div>
              {data?.map((contents: any, idx: number) => {
                const data = findData(contents, sectionName, showDateProperty);
                return (
                  <Link to={data.path} key={idx}>
                    <div className="contents-wrap">
                      <p className="ellipsis pr10">{data.description}</p>
                      <span className="no-wrap">{data.date}</span>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <div className="no-data">
              <p>처리할 업무가 없습니다</p>
            </div>
          )}
        </div>
      );
    }
  };

  return <>{renderCard()}</>;
}
export default DashboardMenu;
