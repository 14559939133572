import { DefaultOptions, useQuery } from "@tanstack/react-query";

import { getReservationPolicyModeAsync, ReservationPolicyModeListParams } from "src/api/product/product-api";
import { axiosInstance } from "src/utils";

/**
 * //예약 가능 기간
 * @param param defaultOptions:DefaultOptions["queries"]
 * @returns AvailableReservationTime[]
 */
const useGetReservationPolicyMode = ({
  reservationPolicyModeListParams,
  defaultOptions,
}: {
  reservationPolicyModeListParams: ReservationPolicyModeListParams;
  defaultOptions?: DefaultOptions["queries"];
}) => {
  const _defaultOptions = defaultOptions as unknown as Object;
  const query = useQuery({
    queryKey: ["getReservationPolicyModeAsync", reservationPolicyModeListParams],
    queryFn: () => getReservationPolicyModeAsync(axiosInstance, reservationPolicyModeListParams).then((res) => res?.data?.data?.content),

    enabled: !!reservationPolicyModeListParams,
    retryOnMount: false,
    staleTime: 1000,
    // refetchOnMount: false,
    ...(_defaultOptions && _defaultOptions),
  });

  return { ...query };
};

export default useGetReservationPolicyMode;
