import { useEffect, useState } from "react";
import { Control, Controller, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ProviderAddModel, ProviderBasicInfoAddModel, ProviderMediaFile } from "src/api/provider/provider-types";
import { BaseInput, BaseRadio } from "src/components";
import { companyRegistrationHypen } from "src/utils";
import ContractUpload from "./ContractUpload";

const checkBizRegNoLink = "https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/ab/a/a/UTEABAAA13.xml";

type Props = {
  control?: Control<ProviderBasicInfoAddModel>;
  register?: UseFormRegister<ProviderBasicInfoAddModel>;
  errors?: any;
  providerDetail?: ProviderAddModel;
  providerId?: string;
  setValue: UseFormSetValue<ProviderBasicInfoAddModel>;
};

const ProviderBasicForm = ({ control, providerDetail, providerId, errors, setValue }: Props) => {
  const [document, setDocument] = useState<ProviderMediaFile[]>([]);
  useEffect(() => {
    if (providerDetail?.contractMediaList && providerDetail.contractMediaList?.length > 0) {
      setDocument(providerDetail.contractMediaList);
    }
  }, [providerDetail]);

  return (
    <article>
      {/* 구분 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">구분</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <span>
              {Boolean(Number(providerId)) && providerDetail?.provider?.supplyType === "RENTAL" && "프로바이더"}
              {Boolean(Number(providerId)) && providerDetail?.provider?.supplyType === "MNT" && "관리처"}
            </span>
            {!Boolean(Number(providerId)) && (
              <Controller
                control={control}
                name={`provider.supplyType`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <div className="flex-center ">
                      {}
                      <BaseRadio
                        id={"provider"}
                        name={name}
                        label={"프로바이더"}
                        checked={value === "RENTAL"}
                        className="mr20"
                        onChange={(checked: boolean) => {
                          onChange(checked);
                          if (checked) {
                            setValue("provider.supplyType", "RENTAL", { shouldDirty: true });
                          }
                        }}
                        readOnly={value !== "" && true}
                      />
                      <BaseRadio
                        id={"manamgement"}
                        name={name}
                        label={"관리처"}
                        checked={value === "MNT"}
                        onChange={(checked: boolean) => {
                          onChange(checked);
                          if (checked) {
                            setValue("provider.supplyType", "MNT", { shouldDirty: true });
                          }
                        }}
                        readOnly={value !== ""}
                      />
                    </div>
                  );
                }}
              ></Controller>
            )}
          </div>
          <p className="validation-text">{errors.provider?.supplyType?.message}</p>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">법인명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.providerName`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 법인명(법인사업자) 또는 성명(개인임대사업자)을 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid mb30">
        <div className="contents-container__grid-index">
          <p className="required">사업자 등록번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.businessRegistrationNumber`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={(text: string) => onChange(text)}
                    value={companyRegistrationHypen(String(value))}
                    maxLength={12}
                    name={name}
                    errorText={error && error.message}
                  />
                );
              }}
            ></Controller>
          </div>
          <div className="flex-center mt10">
            <span className="text-red900">사업자 등록번호를 조회하여 사업자의 상태를 확인 후 등록해주세요.</span>
            <a href={checkBizRegNoLink} target="_blank" className="ml10 text-underline" rel="noreferrer">
              휴/폐업 조회
            </a>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">대표자 성명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.representative`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="개인임대사업자의 경우 사업자등록증의 성명을 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">주소</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.address`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 사업장 소재지를 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">업태</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.bizType`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 업태를 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">종목</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax450">
            <Controller
              control={control}
              name={`provider.bizClass`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorText={error && error.message}
                    placeholder="사업자등록증의 종목을 적어주세요."
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="required">{`사업자등록증 사본(${document.filter((item) => item.isDeleted !== true).length}/1)`}</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            <div className="flex-center mr16">
              <Controller
                control={control}
                name="contractMediaList"
                render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                  <>
                    <ContractUpload
                      setPrivateFiles={(file: ProviderMediaFile[]) => {
                        setDocument(file);
                        onChange(file);
                      }}
                      privateFiles={document}
                      errorText={error && error.message}
                      limit={1}
                      maxSize={10}
                    />
                  </>
                )}
              ></Controller>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default ProviderBasicForm;
