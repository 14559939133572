import { useEffect, useState } from "react";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { getCsServiceItemsAsync } from "src/api/voc/voc-api";
import { CsServiceItemModel } from "src/api/voc/voc-types";
import { BaseTooltip } from "src/components";
import BaseSelectTable from "src/components/BaseSelectTable";
import { PagePath } from "src/pages/product/details";
import BaseNewTabLink from "../BaseNewTabLink";

const columns: any = [
  {
    Header: "id",
    accessor: "csItemId",
    width: 120,
    Cell: ({ value }: { value?: string }) => {
      if (!value) {
        return "-";
      }
      return <BaseNewTabLink path={PagePath.voc.detail.replace(":id", value)} value={value} ellipsis className="ml16" />;
    },
  },
  {
    Header: "VoC 유형",
    accessor: "csTypeName",
    width: 180,
  },

  {
    Header: "VoC 항목",
    accessor: "csItemName",
    width: 575,
    Cell: ({ value }: { value?: string }) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
];

type Props = {
  onClick?: (csServiceItem: CsServiceItemModel) => void;
  onClose?: () => void;
  defaultCheckedList?: string[];
  defaultDisabledList?: string[];
  serviceId?: string;
  serviceType?: UnionServiceType;
};

const SelectCsServiceItemPopup = ({ onClick, onClose, defaultCheckedList, defaultDisabledList, serviceId, serviceType }: Props) => {
  const [csServiceItemList, setCsServiceItemList] = useState<CsServiceItemModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const { executeAsync: getCsServiceItems } = useApiOperation(getCsServiceItemsAsync);

  const [params, setParams] = useState({
    serviceId: serviceId,
    serviceType: serviceType,
    page: 0,
    size: 20,
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    getCsServiceItems(params).then((res) => {
      setCsServiceItemList(res?.data?.data?.content);
      setPageMeta(res?.data?.meta?.pageMeta);
    });
  }, [params]);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultDisabledList={defaultDisabledList}
      defaultCheckedList={defaultCheckedList}
      title="유형/항목 선택"
      selectKey={"id"}
      columns={columns}
      data={csServiceItemList ?? []}
      checkboxType={"radio"}
      onClick={(_, checkedObjList) => onClick && onClick(checkedObjList[0])}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        searchOptionList: [
          {
            value: "",
            label: "전체",
          },
          {
            value: "CS_ITEM_ID", // Voc 유형
            label: "ID",
          },
          {
            value: "CS_TYPE_NAME", // Voc 유형
            label: "유형",
          },
          {
            value: "CS_ITEM_NAME", // Voc 항목
            label: "항목",
          },
        ],
        onSearch(searchKeyword, searchOption) {
          setParams((prev) => ({ ...prev, keywordList: searchKeyword, searchType: searchOption }));
        },
      }}
    />
  );
};

export default SelectCsServiceItemPopup;
