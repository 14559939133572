interface BaseProps {
  title?: string;
  titleChildren?: React.ReactNode;
  required?: boolean;
  value?: string | number | undefined;
  children?: React.ReactNode;
}
const BaseSection = ({ title, value, children, required, titleChildren }: BaseProps) => {
  return (
    <section className="contents-container__grid">
      <div className="contents-container__grid-index">
        {titleChildren === undefined ? <p className={required ? "required" : ""}>{title}</p> : titleChildren}
      </div>
      <div className="contents-container__grid-contents">
        {children === undefined ? (
          <div className="">
            <p>{value}</p>
          </div>
        ) : (
          children
        )}
      </div>
    </section>
  );
};

export default BaseSection;
