import React from "react";
import { BuildingModel } from "src/api/building/building-types";
import { BaseSelect } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { SelectOption } from "src/pages/building/building-types";
import { PagePath } from "src/pages/product/details";

type Props = {
  building: BuildingModel | undefined;
  buildingOptions: SelectOption[];
  getBuildingDetail: (buildingId: number) => Promise<void>;
};

const BuildingSelect = ({ building, buildingOptions, getBuildingDetail }: Props) => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="left-area__index">
        <span>건물 선택</span>
      </div>
      <div className="left-area__contents">
        <div className="minmax250">
          <BaseSelect
            placeholder="건물을 선택해주세요"
            value={
              building ? buildingOptions.find((option) => String(option.value) === String(building?.id))?.value : buildingOptions[0]?.value || ""
            }
            stateOptions={buildingOptions}
            setStateValue={(value: string) => {
              navigate(`${PagePath.monitoring}?buildingId=${value}`);
              getBuildingDetail(Number(value));
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default BuildingSelect;
