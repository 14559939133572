import { DefaultOptions, useQuery } from "@tanstack/react-query";
import { getContractOperationDetailSync } from "src/api/contract/contract-facility-api";

import { axiosInstance } from "src/utils";

/**
 *
 * @param param defaultOptions:DefaultOptions["queries"]
 * @returns ProductModel
 */
const useGetContractOperation = ({ contractId, defaultOptions }: { contractId?: number; defaultOptions?: DefaultOptions["queries"] }) => {
  const _defaultOptions = defaultOptions as unknown as Object;
  const query = useQuery({
    queryKey: ["getContractOperationDetailSync", contractId],
    queryFn: async () =>
      contractId ? (await getContractOperationDetailSync(axiosInstance, { contractId: contractId }))?.data?.data?.content : undefined,
    enabled: !!contractId,
    retryOnMount: false,
    staleTime: 5000,
    refetchOnMount: false,
    ...(_defaultOptions && _defaultOptions),
  });

  return { ...query };
};

export default useGetContractOperation;
