import { useMutation, useQueryClient } from "@tanstack/react-query";
import { decommaizeNumber } from "@toss/utils";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  mutationReservationPolicyModeAsync,
  mutationUnitReservationTimeAsync,
  ReservationPolicyMode,
  UnitReservationTime,
} from "src/api/product/product-api";
import { BaseButton, BaseCheckbox, BaseRadio, BaseSelect, BaseTooltip } from "src/components";
import useGetUnitReservationTime from "src/hooks/product/useGetUnitReservationTime";
import { timeSelectLabel } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { axiosInstance, typeToString } from "src/utils";
import { ReservationProps } from "./ReservationAvailabilityPeriod";
import useDirtyState from "src/recoil/dirty/hooks";
import _ from "lodash";
import useGetReservationPolicyMode from "src/hooks/product/useGetReservationPolicyMode";

interface UnitReservationTimeForm {
  isLimitedUnitReservationTime: boolean; //토탈 제한 여부

  isMinimumReservationTime: boolean; //최소 제한 시간 적용함 여부
  isMaximumReservationTime: boolean; //최대 제한 시간 적용함 여부

  minimumReservationTime: string; //최소 제한 시간
  maximumReservationTime: string; //최대 제한 시간
}

const ReservationTimeUnit = ({ spaceType, serviceType, serviceId, isEditable, space, isDefault }: ReservationProps) => {
  const { setBaseModal, setAbstractModalZ1 } = useModal();
  const queryClient = useQueryClient();
  const { openToast } = useToast();
  const { mutateAsync: mutationReservationPolicyMode } = useMutation({
    mutationFn: (data: ReservationPolicyMode[]) => mutationReservationPolicyModeAsync(axiosInstance, data),
    onSuccess: (data) => {
      console.log("mutationReservationPolicyModeAsync data :>> ", data);
      queryClient.invalidateQueries({
        queryKey: ["getReservationPolicyModeAsync"],
      });
    },
  });

  const { data: unitReservationTimeList } = useGetUnitReservationTime({
    unitReservationTimeListParams: {
      isDefault: isDefault,
      serviceId,
      serviceType,
      commonFacilityType: spaceType,
      ...(space?.id && {
        buildingCommonFacilityId: String(space?.id),
      }),
    },
  });

  const isRegisterMode = !unitReservationTimeList?.[0]?.id;

  const { mutate } = useMutation({
    mutationFn: (data: UnitReservationTime[]) => mutationUnitReservationTimeAsync(axiosInstance, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUnitReservationTimeAsync"] });
    },
  });

  const { data: reservationPolicyModeList } = useGetReservationPolicyMode({
    reservationPolicyModeListParams: {
      serviceType,
      serviceId,
      commonFacilityType: spaceType,
      buildingCommonFacilityId: space?.id?.toString(),
      reservationPolicyModeType: "RESERVATION_POLICY_MODE_UNIT",
    },
  });

  const reservationPolicyMode = reservationPolicyModeList?.find(
    (data) => String(data.buildingCommonFacilityId) === String(space?.id) && data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_UNIT",
  );

  const [isPolicyMode, setIsPolicyMode] = useState<boolean>();

  useEffect(() => {
    // props isDefault가 false 인개별 설정 페이지에서 reservationPolicyMode의 isDefault 값으로 PolicyMode 값을 사용함(null 또는 undefined 면 true로 전환)
    setIsPolicyMode(!isDefault ? reservationPolicyMode?.isDefault !== false : false);
  }, [isDefault, reservationPolicyMode, reservationPolicyModeList, setIsPolicyMode, space?.id]);
  const {
    handleSubmit,
    setValue,
    reset,
    register,
    watch,
    trigger,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<UnitReservationTimeForm>({
    defaultValues: {
      isLimitedUnitReservationTime: false,

      isMinimumReservationTime: false,
      isMaximumReservationTime: false,

      minimumReservationTime: "", // 예약가능시간대시작 (단위: 분)
      maximumReservationTime: "", // 예약가능시간대종료
    },
  });

  const { setIsDirtyState, isDirtyState } = useDirtyState();
  useEffect(() => {
    if (isDirty) {
      return setIsDirtyState(true);
    } else if (!_.isEqual(!isDefault ? reservationPolicyMode?.isDefault !== false : false, isPolicyMode)) {
      return setIsDirtyState(true);
    } else {
      setIsDirtyState(false);
    }
  }, [dirtyFields, isDefault, isDirty, isPolicyMode, reservationPolicyMode?.isDefault, setIsDirtyState]);

  const onCloseTabModal = () => {
    if (isDirtyState) {
      setBaseModal({
        isOpen: true,
        title: "취소하시겠습니까?",
        children: "저장하지 않고 취소하면 입력한 내용이 모두 삭제됩니다.",
        btnLeftTitle: "취소",
        btnRightTitle: "확인",
        onClick: () => {
          setIsDirtyState(false);
          setBaseModal({ isOpen: false });
          setAbstractModalZ1({ isOpen: false });
        },
        onClose: () => {
          setBaseModal({ isOpen: false });
        },
      });
    } else {
      setIsDirtyState(false);
      setAbstractModalZ1({ isOpen: false });
    }
  };

  useEffect(() => {
    const defaultSettingData = unitReservationTimeList?.[0];

    if (defaultSettingData) {
      reset({
        isLimitedUnitReservationTime: defaultSettingData?.isLimitedUnitReservationTime,
        isMaximumReservationTime: !!typeToString(defaultSettingData?.maximumReservationTime),
        isMinimumReservationTime: !!typeToString(defaultSettingData?.minimumReservationTime),
        maximumReservationTime: typeToString(defaultSettingData?.maximumReservationTime),
        minimumReservationTime: typeToString(defaultSettingData?.minimumReservationTime),
      });
    }
  }, [unitReservationTimeList, reset]);

  const onValid = async (data: UnitReservationTimeForm) => {
    if (!isDefault) {
      //개별설정일때
      await mutationReservationPolicyMode([
        {
          serviceId: Number(serviceId), // 서비스ID
          serviceType: serviceType, // 서비스타입
          commonFacilityType: spaceType, // 공용공간타입
          isDefault: isPolicyMode, // 기본설정여부

          buildingCommonFacilityId: Number(space?.id), // 건물-공용공간 ID (isDefault=false인 경우 필수)

          reservationPolicyModeType: "RESERVATION_POLICY_MODE_UNIT",

          cmdType: reservationPolicyMode?.id ? "U" : "C", // 요청타입
          ...(reservationPolicyMode?.id && {
            // 수정모드일때 id 등록
            id: reservationPolicyMode?.id, // 예약가능시간대 ID
          }),
        },
      ]);
    }

    const sendData: UnitReservationTime[] = [
      {
        serviceId: Number(serviceId), // 서비스ID
        serviceType: serviceType, // 서비스타입
        commonFacilityType: spaceType, // 공용공간타입
        isDefault: isDefault, // 기본설정여부

        isLimitedUnitReservationTime: data.isLimitedUnitReservationTime,
        maximumReservationTime: data.isMaximumReservationTime ? Number(data.maximumReservationTime) : -1,
        minimumReservationTime: data.isMinimumReservationTime ? Number(data.minimumReservationTime) : -1,

        cmdType: isRegisterMode ? "C" : "U", // 요청타입
        buildingCommonFacilityId: Number(space?.id), // 건물-공용공간 ID (isDefault=false인 경우 필수)

        ...(!isRegisterMode && {
          // 수정모드일때 id 등록
          id: unitReservationTimeList?.[0]?.id, // 예약가능시간대 ID
        }),
      },
    ];

    console.log("unit sendData :>> ", sendData);

    mutate(sendData, {
      onSuccess() {
        console.log("onSuccess data :>> ", data);
        setBaseModal({ isOpen: false });
        setIsDirtyState(false);
        openToast({ content: "저장 되었습니다.", isSystemToast: true });
      },
    });
  };

  //  유효성 확인 후 저장 진행
  const formValidation = () => {
    if (watch("isLimitedUnitReservationTime") === true && isPolicyMode === false) {
      //제한이면서 개별설정으로 선택시 벨리데이션 체크함
      //무제한과 기본설정에서는 벨리데이션 안함

      register("maximumReservationTime", {
        validate: (value) => {
          if (value && watch("minimumReservationTime")) {
            if (decommaizeNumber(value) <= decommaizeNumber(watch("minimumReservationTime"))) {
              return "시작 시간과 동일하거나 시작 시간보다 낮게 입력할 수 없습니다.";
            }
          }

          return true;
        },
      });

      register("isMinimumReservationTime", {
        validate: (value) => {
          if (value === false && watch("isMaximumReservationTime") === false) {
            return "최소 선택 시간 또는 최대 선택 시간 중 한개 이상을 반드시 선택해주세요.";
          }
          if (value === true && watch("minimumReservationTime") === "") {
            return "시간을 선택해 주세요.";
          }

          return true;
        },
      });

      register("isMaximumReservationTime", {
        validate: (value) => {
          if (value === false && watch("isMinimumReservationTime") === false) {
            return "최소 선택 시간 또는 최대 선택 시간 중 한개 이상을 반드시선택해주세요.";
          }
          if (value === true && watch("maximumReservationTime") === "") {
            return "시간을 선택해 주세요.";
          }
          return true;
        },
      });
    } else {
      register("minimumReservationTime", {
        validate: () => true,
      });
      register("maximumReservationTime", {
        validate: () => true,
      });
      register("isMinimumReservationTime", {
        validate: () => true,
      });
      register("isMaximumReservationTime", {
        validate: () => true,
      });
    }
  };

  formValidation();

  return (
    <>
      <section className="base-abstract-modal__contents px30">
        <form onSubmit={handleSubmit(onValid)}>
          {!isDefault && (
            <>
              <div className="base-abstract-modal__contents-subtitle">
                <span className="required font18 font-weight-semibold">예약 정책 적용 방식</span>
              </div>
              <div className="flex-center h36 pt10">
                <BaseRadio
                  id={"isPolicyMode_true"}
                  name={"isPolicyMode"}
                  checked={isPolicyMode === true}
                  label="기본설정"
                  onChange={() => {
                    setIsPolicyMode(true);
                  }}
                />
                <BaseTooltip
                  className={"mr10 no-wrap"}
                  tooltip={
                    (
                      <div>
                        <div>• 예약 기본 정책 설정을 그대로 따릅니다.</div>
                        <div>• 기본 설정이 변경되면 즉시 적용됩니다.</div>
                      </div>
                    ) as any as string
                  }
                  touchIcon="QUESTION"
                ></BaseTooltip>
                <BaseRadio
                  id={"isPolicyMode_false"}
                  name={"isPolicyMode"}
                  checked={isPolicyMode === false}
                  label="개별설정" // 무제한
                  onChange={() => {
                    setIsPolicyMode(false);
                  }}
                />
                <BaseTooltip
                  tooltip={
                    (
                      <div>
                        <div>• 예약 기본 정책 설정을 따르지 않고 이 공용 공간에만 적용되는 별도의 예약 정책을 설정합니다.</div>
                        <div>• 기본 설정이 변경되더라도 영향을 받지 않습니다.</div>
                      </div>
                    ) as any as string
                  }
                  touchIcon="QUESTION"
                ></BaseTooltip>
              </div>
            </>
          )}
          <div className="base-abstract-modal__contents-subtitle">
            <span className="font18 font-weight-semibold">예약 시간 단위 제한</span>
          </div>
          <div className="flex-column d-flex">
            <span className="font14 text-primary3">• 1회 예약시 선택 가능한 최소 시간과 최대 시간을 설정할 수 있습니다.</span>
            <Controller
              control={control}
              name={"isLimitedUnitReservationTime"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <div className="flex-center h36 pt10">
                  <BaseRadio
                    id={"isLimitedUnitReservationTime_false"}
                    name={name}
                    checked={
                      //개별설정 && 첫 저장전(빈배열) 이면 noCheck && disabled 아니면 값에 따라 결정
                      isPolicyMode && Array.isArray(unitReservationTimeList) && unitReservationTimeList.length === 0 ? false : value === false
                    }
                    label="무제한" // 무제한
                    onChange={() => {
                      onChange(false);
                    }} // send value to hook form
                    className={"mr10"}
                    disabled={isPolicyMode !== false}
                  />
                  <BaseRadio
                    id={"isLimitedUnitReservationTime_true"}
                    name={name}
                    checked={value === true}
                    label="제한"
                    disabled={isPolicyMode !== false}
                    onChange={() => {
                      onChange(true);

                      //첫 설정시 사용함 체크
                      if (isRegisterMode) {
                        setValue("isMinimumReservationTime", true, {
                          shouldDirty: true,
                        });
                        setValue("isMaximumReservationTime", true, {
                          shouldDirty: true,
                        });
                      }
                    }} // send value to hook form
                  />
                </div>
              )}
            ></Controller>
          </div>

          <div className="base-abstract-modal__contents-subtitle">
            <span className="font18 font-weight-semibold">최소 선택 시간</span>
          </div>
          <div className="flex-column d-flex">
            <div className="flex-center pt10">
              <Controller
                control={control}
                name={"isMinimumReservationTime"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseCheckbox
                    className="w-fit mr10"
                    id={name}
                    name={name}
                    checked={value}
                    onChange={(checked) => {
                      onChange(checked);
                      setValue("minimumReservationTime", "", { shouldDirty: true });
                    }}
                    label="적용함"
                    disabled={watch("isLimitedUnitReservationTime") === false || isPolicyMode !== false}
                  />
                )}
              ></Controller>

              <Controller
                control={control}
                name={"minimumReservationTime"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <div className="minmax240">
                    <BaseSelect
                      menuPlacement="auto"
                      className="modalSelect"
                      placeholder=" "
                      stateOptions={timeSelectLabel}
                      value={value}
                      setStateValue={onChange}
                      isDisabled={
                        watch("isLimitedUnitReservationTime") === false || watch("isMinimumReservationTime") === false || isPolicyMode !== false
                      }
                    ></BaseSelect>
                  </div>
                )}
              ></Controller>
              {/* <span className="font18 ml10">{renderText(true, watch("availableReservationTimeStart") ?? "") ?? ""} 이내</span> */}
            </div>
            {errors.isMinimumReservationTime?.message && <p className="validation-text">{errors.isMinimumReservationTime?.message ?? ""}</p>}
            {errors.minimumReservationTime?.message && <p className="validation-text">{errors.minimumReservationTime?.message ?? ""}</p>}
          </div>
          <div className="base-abstract-modal__contents-subtitle">
            <span className="font18 font-weight-semibold">최대 선택 시간</span>
          </div>
          <div className="flex-column d-flex">
            <div className="flex-center pt10">
              <Controller
                control={control}
                name={"isMaximumReservationTime"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseCheckbox
                    className="w-fit mr10"
                    id={name}
                    name={name}
                    checked={value}
                    onChange={(checked) => {
                      onChange(checked);
                      setValue("maximumReservationTime", "", { shouldDirty: true });
                    }}
                    label="적용함"
                    disabled={watch("isLimitedUnitReservationTime") === false || isPolicyMode !== false}
                  />
                )}
              ></Controller>

              <Controller
                control={control}
                name={"maximumReservationTime"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <div className="minmax240">
                    <BaseSelect
                      menuPlacement="auto"
                      className="modalSelect"
                      placeholder=" "
                      stateOptions={timeSelectLabel}
                      value={value}
                      setStateValue={onChange}
                      isDisabled={
                        watch("isLimitedUnitReservationTime") === false || watch("isMaximumReservationTime") === false || isPolicyMode !== false
                      }
                    ></BaseSelect>
                  </div>
                )}
              ></Controller>
            </div>
            {errors.isMaximumReservationTime?.message && <p className="validation-text">{errors.isMaximumReservationTime?.message ?? ""}</p>}
            {errors.maximumReservationTime?.message && <p className="validation-text">{errors.maximumReservationTime?.message ?? ""}</p>}
          </div>
        </form>
      </section>

      <section className="base-abstract-modal__btn-wrap">
        {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}

        <BaseButton
          title={"취소"}
          className="color-white"
          onClick={() => {
            onCloseTabModal();
          }}
        />

        {isEditable && (
          <BaseButton
            title={"저장"}
            onClick={async () => {
              (await trigger()) &&
                setBaseModal({
                  isOpen: true,
                  btnRightTitle: "확인",
                  btnLeftTitle: "취소",
                  title: "저장하시겠습니까?",
                  children: "저장 후에는 변경 사항이 즉시 적용됩니다.",
                  onClick: () => {
                    handleSubmit(onValid)();
                  },
                });
            }}
          />
        )}
      </section>
    </>
  );
};

export default ReservationTimeUnit;
