import { AxiosInstance, AxiosResponse } from "axios";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import { BuildingUpdateResponseData, DeskGroupModel, PostDeskChartRequestBody } from "./building-types";

/**
 * CPB14. 좌석 그룹 목록 조회
 */
export async function getDeskgroupsAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<DeskGroupModel> }>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  const path = ApiPath.building.deskgroupList.replace("{id}", params.buildingId.toString());
  return await axios.get(path);
}

/**
 * CPB16. 좌석 그룹별 좌석 목록 조회
 */
export async function getDesksAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    deskGroupId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ content: DeskGroupModel }>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.deskGroupId) {
    throw Error("deskGroupId is not found.");
  }
  const path = ApiPath.building.deskList.replace("{id}", params.buildingId.toString()).replace("{deskGroupId}", params.deskGroupId.toString());
  return await axios.get(path);
}

/* 
  CPB17. 좌석배치도 등록/수정
*/
export async function postDeskChartAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    deskChart: PostDeskChartRequestBody;
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.deskChart) {
    throw Error("deskChart is not found.");
  }
  const path = ApiPath.building.deskChart.replace("{id}", params.buildingId.toString());
  return await axios.post(path, params.deskChart);
}

/* 
  CPB18. 좌석배치도 삭제
*/
export async function deleteDeskChartAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    deskGroupId: string; // 좌석 그룹 ID
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  if (!params?.buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!params?.deskGroupId) {
    throw Error("deskGroupId is not found.");
  }
  const path = ApiPath.building.deskChart.replace("{id}", params.buildingId.toString());
  return await axios.delete(path, {
    data: {
      id: params.deskGroupId,
    },
  });
}
