import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { uploadMediaFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, Modal } from "src/api/public-types";
import { editQuestionStatusAsync, postAnswerQuestionAsync } from "src/api/question/qeustion-api";
import { QuestionAnswerAddType, QuestionDetailModel, QuestionNotifyMessageType } from "src/api/question/question-type";
import { BaseButton, BaseCheckbox, BaseModal, BaseTextarea } from "src/components";

import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import BaseFileUpload from "src/components/mediaFile/BaseFileUpload";
import useMediasUpdate from "src/hooks/useMediasUpdate";
import { BaseTooltip } from "src/components";

type Props = {
  questionDetail: QuestionDetailModel;
  fetchQuestionDetail: (id: number) => Promise<void>;
  isValidateNotification: (arr: Array<any>) => boolean | undefined;
  sendNotification: (messageTypes: QuestionNotifyMessageType[], answerId?: string) => any;
};

const ReplyFormSection = ({ questionDetail, fetchQuestionDetail, isValidateNotification, sendNotification }: Props) => {
  // 케이스 별 UI
  // 1. taap 인입
  // 2. 수기입력, 이메일이 없는 경우
  const isFromTaap = questionDetail.site === "SITE_TAAP";
  const answerList = questionDetail?.answerList;

  // form default
  const answerDefaultValue = useMemo(() => {
    const _answerDefaultValue: QuestionAnswerAddType = {
      answerType: "ANSWER_NORMAL",
      description: "",
    };

    return _answerDefaultValue;
  }, []);

  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { dirtyFields, errors },
  } = useForm<QuestionAnswerAddType>({
    defaultValues: answerDefaultValue,
    mode: "onChange",
  });
  const { openErrorModal } = useErrorModal();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [messageTypes, setMessageTypes] = useState<QuestionNotifyMessageType[]>(["MESSAGE_EMAIL", "MESSAGE_KAKAOTALK"]);
  // 첨부파일
  const [mediasData, setMediasData] = useState<MediaFile[]>([]);

  const [isStatusComplete, setIsStatusComplete] = useState(true);

  // 답변등록
  const { executeAsync: postAnswerQuestion } = useApiOperation(postAnswerQuestionAsync, { noHandleError: true });

  // 상담관리 상태 변경
  const { executeAsync: editQuestionStatus } = useApiOperation(editQuestionStatusAsync);

  // 공통 - 미디어파일 등록
  const { executeAsync: uploadMediaFiles } = useApiOperation(uploadMediaFilesAsync, { noHandleError: true });

  const { requestMedias, updateMedias } = useMediasUpdate({ mediaList: mediasData });

  // 토스트
  const { openToast } = useToast();

  // 유효성 검사
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    register("answerType", {
      required: { value: true, message: requiredMessage },
    });

    register("description", {
      required: { value: true, message: requiredMessage },
    });
  }, [register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  const onSubmit = useCallback(
    async (data: QuestionAnswerAddType, e?: any) => {
      e.preventDefault();

      if (answerList && answerList.length >= 1 && isFromTaap) {
        setAlertModal({ isOpen: true, title: "Taap에서 등록된 문의는 최초 답변 등록 이후 답변 수정이 불가하며, 복수의 답변을 등록할 수 없습니다." });
        return;
      }

      if (messageTypes.length === 1 && messageTypes[0] === "MESSAGE_KAKAOTALK") {
        setAlertModal({ isOpen: true, title: "이메일 발송 체크해주세요." });
        return;
      }

      if (isFromTaap) {
        return setConfirmModal({
          isOpen: true,
          payload: data as QuestionAnswerAddType,
          title: "답변을 등록 하시겠습니까?",
          message: "Taap Push 알림으로 답변 완료 알림이 발송되며\n답변 내용을 수정할 수 없습니다",
        });
      }

      setConfirmModal({
        isOpen: true,
        payload: data as QuestionAnswerAddType,
        title: "등록 하시겠습니까?",
        message: "이메일 발송 선택후 등록시 \n문의자에게 답변이 발송됩니다.",
      });
    },
    [messageTypes],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);

    return false;
  };
  //  첨부파일 등록
  const postMediaFiles = async (serviceId: string) => {
    const { data: mediaResponse, status: mediaStatus } = await updateMedias(serviceId);

    // 첨부파일 등록 성공후, 알림톡 보내야 하면 실행
    if (mediaStatus >= 200 && mediaStatus <= 299) {
      if (messageTypes.length > 0) {
        await sendNotification(messageTypes, serviceId);
      }
    } else {
      const errorCode = mediaResponse?.meta?.errorCode;
      errorCode && openErrorModal({ errorCode, statusCode: mediaStatus, errorMessage: "등록이 실패하였습니다.\n 재등록해 주세요." });
    }
    await fetchQuestionDetail(Number(questionDetail.id));
  };

  //  등록버튼 -> 저장 확인 팝업 - 저장 선택시 실행
  const onClickConfirmModal = async (data: QuestionAnswerAddType) => {
    // 답변등록 api

    const answerResponse = await postAnswerQuestion({ id: String(questionDetail.id), data });
    if (answerResponse.status >= 200 && answerResponse.status <= 299) {
      const result = answerResponse.data.data.content;

      isStatusComplete && (await editQuestionStatus({ id: Number(questionDetail.id), status: "QUESTION_COMPLETE" }));
      //taap의 경우 완료처리로 변경하면 답변내용이 push 메시지로 전송

      // 첨부파일이 있으면, 답변 등록 성공 후 실행
      if (requestMedias.length > 0) {
        await postMediaFiles(String(result.id));
      }
      // 알림톡 여부 있으면
      else if (requestMedias.length === 0 && messageTypes.length > 0) {
        await sendNotification(messageTypes, result.id);
      }

      await fetchQuestionDetail(Number(questionDetail.id));

      reset();
      setConfirmModal({ isOpen: false });
      setMediasData([]);
      openToast({ content: "정상적으로 등록되었습니다." });
    } else {
      const errorCode = answerResponse?.data?.meta?.errorCode;
      openErrorModal({ errorCode, statusCode: answerResponse.status, errorMessage: "등록이 실패하였습니다.\n 재등록해 주세요." });
    }
  };

  useEffect(() => {
    if (questionDetail?.reporterEmail) {
      setMessageTypes(["MESSAGE_EMAIL", "MESSAGE_KAKAOTALK"]);
    } else {
      setMessageTypes([]);
    }

    if (isFromTaap) {
      setMessageTypes([]);
    }
  }, [questionDetail]);

  return (
    <div className="contents-container__1070 ">
      <div className="contents-question ">
        <div className="pa20">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {isFromTaap === false && (
              <section className="contents-container__grid ml10 pb10">
                <div className="contents-container__grid-index">
                  <p className="required">답변 발송 여부</p>
                </div>
                <div className="contents-container__grid-contents pt10">
                  <section className="flex-center-start">
                    <div className="mr20 flex-center-start">
                      <BaseCheckbox
                        id={"MESSAGE_EMAIL"}
                        name={"MESSAGE_EMAIL"}
                        label="이메일 발송"
                        checked={messageTypes.includes("MESSAGE_EMAIL") ? true : false}
                        disabled={!questionDetail?.reporterEmail}
                        onChange={(checked: boolean) => {
                          if (checked) {
                            setMessageTypes((prev) => [...prev, "MESSAGE_EMAIL"]);
                          } else {
                            setMessageTypes((prev) => prev.filter((value) => value !== "MESSAGE_EMAIL"));
                          }
                        }}
                      />
                      <BaseTooltip touchIcon={"QUESTION"} className="ml4 mr18" size={16} tooltip={`입력하신 내용을 문의자의 이메일로 발송합니다`} />
                      {!questionDetail?.reporterEmail && (
                        <p className="validation-text pt0 pl0">{"*등록된 문의자 이메일이 없는 경우 이메일 발송이 불가합니다"}</p>
                      )}
                    </div>
                    {messageTypes.includes("MESSAGE_EMAIL") && (
                      <div className="mr20 flex-center-start">
                        <BaseCheckbox
                          id={"MESSAGE_KAKAOTALK"}
                          name={"MESSAGE_KAKAOTALK"}
                          label="알림톡 발송"
                          checked={messageTypes.includes("MESSAGE_KAKAOTALK") ? true : false}
                          onChange={(checked: boolean) => {
                            if (checked) {
                              setMessageTypes((prev) => [...prev, "MESSAGE_KAKAOTALK"]);
                            } else {
                              setMessageTypes((prev) => prev.filter((value) => value !== "MESSAGE_KAKAOTALK"));
                            }
                          }}
                        />
                        <BaseTooltip
                          touchIcon={"QUESTION"}
                          className="ml4"
                          size={16}
                          tooltip={`문의자의 휴대폰 번호로 이메일 답변 완료 알림톡을 발송합니다.`}
                        />
                      </div>
                    )}
                  </section>
                </div>
              </section>
            )}

            <section className="contents-container__grid ml10 pb10">
              <div className="contents-container__grid-index">
                <p className="required">답변 내용</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Controller
                    control={control}
                    name={"description"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseTextarea
                        inputRef={textAreaRef}
                        height={79}
                        className="overflow-y-hidden"
                        maxLength={1000}
                        placeholder="상세 내용을 입력해주세요."
                        onChange={(value: string) => onChange(value)}
                        value={value || ""}
                        name={name}
                        errorText={error?.message}
                        adjustHeight
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>

            <section className="contents-container__grid ml10 pb4">
              <div className="contents-container__grid-index">
                <p>첨부파일{mediasData && `(${mediasData.length}/3)`}</p>
              </div>
              <BaseFileUpload
                fileList={mediasData}
                limit={3}
                setFiles={(files: any) => {
                  const newFiles = files.map((file: any) => ({ ...file, serviceType: "SERVICE_QUESTION_ANSWER" }));
                  setMediasData(newFiles);
                }}
              />
            </section>
            <div className="flex-center-between">
              <div className="flex-1">
                {isFromTaap && (
                  <span className="font12">
                    {"* Taap에서 등록된 문의는 최초 답변 등록 이후 답변 수정이 불가하며, 복수의 답변을 등록할 수 없습니다."}
                  </span>
                )}
              </div>
              <div className="flex-1 flex-center-end">
                <BaseCheckbox
                  id={"isStatusComplete"}
                  name={"isStatusComplete"}
                  label="완료 처리"
                  checked={isStatusComplete}
                  disabled={isFromTaap}
                  onChange={() => setIsStatusComplete((prev) => !prev)}
                  className="mr16"
                />
                <BaseButton title="답변등록" className="답변등록" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClick={() => {
            if (messageTypes.length > 0) {
              const isValidted = isValidateNotification(messageTypes);
              isValidted && onClickConfirmModal(confirmModal.payload! as QuestionAnswerAddType);
            } else {
              onClickConfirmModal(confirmModal.payload! as QuestionAnswerAddType);
            }
            setConfirmModal({ isOpen: false });
          }}
          onClose={() => setConfirmModal({ isOpen: false })}
        >
          <p className="font18 text-gray900 mb10">{confirmModal.title}</p>
          <p className=" pre-formatted">{confirmModal.message}</p>
        </BaseModal>
      )}

      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
          title={alertModal.title}
        ></BaseModal>
      )}
    </div>
  );
};

export default ReplyFormSection;
