import moment from "moment";
import { Controller, UseFormReturn } from "react-hook-form";
import { BaseDatePicker, BaseInput } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { getContractDetailPath, getProductDetailPath } from "src/utils/route-util";
import { VISITOR_STATUS_MAP, VISIT_PURPOSE_MAP, VISIT_TYPE_MAP } from "../constants";
import { checkTodayTimer, getFormErrorText } from "../utils";
import { InvitationInfo as InvitationInfoT, PageType, VisitorAddForm, VisitorEditForm } from "../visitor-types";

const InvitationInfo = ({
  invitationInfo,
  useFormReturn,
  pageType,
}: {
  invitationInfo: InvitationInfoT;
  pageType: PageType;
  useFormReturn: UseFormReturn<VisitorAddForm | VisitorEditForm | any>;
}) => {
  const { watch, control } = useFormReturn;

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title">
        <h2>초대 정보</h2>
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>신청/계약 번호</p>
        </div>
        <div className="contents-container__grid-contents flex-center">
          <BaseNewTabLink
            path={getContractDetailPath({
              contractId: invitationInfo.contractId!,
            })}
            value={invitationInfo.contractApplyNumber ?? "-"}
          />
        </div>
      </section>
      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>공간상품 id</p>
          </div>
          <div className="contents-container__grid-contents flex-center">
            <BaseNewTabLink
              path={getProductDetailPath({
                productId: invitationInfo.productId,
              })}
              value={invitationInfo.productId ?? "-"}
            />
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>공간상품명</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{invitationInfo.productName ?? "-"}</p>
          </div>
        </section>
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>건물 주소</p>
        </div>
        <div className="contents-container__grid-contents">
          <p>{invitationInfo.buildingAddress ?? "-"}</p>
        </div>
      </section>

      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">방문 시작일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row justify-contents-start">
              <div className="minmax140 mr10">
                <Controller
                  name="invitationInfo.visitStartTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <BaseDatePicker
                      disabled={pageType === "edit"}
                      selectedDate={field.value ? moment(field.value).toDate() : undefined}
                      setDate={(value: Date) => {
                        field.onChange(checkTodayTimer(value));
                      }}
                      errorText={getFormErrorText(fieldState.error?.type!)}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
              <div className="minmax140">
                <Controller
                  name="invitationInfo.visitStartTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <BaseDatePicker
                      disabled={pageType === "edit"}
                      type="time"
                      selectedDate={field.value ? moment(field.value).toDate() : undefined}
                      setDate={(value: Date) => {
                        field.onChange(value);
                      }}
                      timeIntervals={10}
                      errorText={getFormErrorText(fieldState.error?.type!)}
                      filteredTime={moment(field.value).toDate()}
                      useFilterdTime={!field.value || moment(field.value).isAfter(moment())}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">방문 종료일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row justify-contents-start">
              <div className="minmax140 mr10">
                <Controller
                  name="invitationInfo.visitEndTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <BaseDatePicker
                      disabled={pageType === "edit"}
                      selectedDate={field.value ? moment(field.value).toDate() : undefined}
                      setDate={(value: Date) => {
                        field.onChange(checkTodayTimer(value));
                      }}
                      errorText={getFormErrorText(fieldState.error?.type!)}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
              <div className="minmax140">
                <Controller
                  name="invitationInfo.visitEndTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <BaseDatePicker
                      disabled={pageType === "edit"}
                      type="time"
                      selectedDate={field.value ? moment(field.value).toDate() : undefined}
                      setDate={(value: Date) => {
                        field.onChange(value);
                      }}
                      errorText={getFormErrorText(fieldState.error?.type!)}
                      useFilterdTime={!field.value || moment(field.value).isAfter(moment())}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>만남 장소</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <Controller
              name="invitationInfo.meetingPlace"
              control={control}
              render={({ field }) => <BaseInput value={field.value} onChange={field.onChange} placeholder="만날 장소를 입력해 주세요" />}
            />
          </div>
        </div>
      </section>

      {pageType === "add" ? null : (
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>상태</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{VISITOR_STATUS_MAP[watch("invitationInfo.visitStatus") as keyof typeof VISITOR_STATUS_MAP] ?? "-"}</p>
          </div>
        </section>
      )}
    </article>
  );
};

export default InvitationInfo;
