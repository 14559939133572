import { useEffect, useState } from "react";

type Props = {
  url: string;
  className?: string;
  alt?: string;
};
/**
 * 이미지 원본 url 받아서 resized 이미지 먼저 보여주고,
 * 없을 경우 원본이미지 보여주는 컴포넌트
 */
export const ResizedImage = ({ url, className, alt }: Props) => {
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    if (url) {
      const ext = url.slice(url.lastIndexOf(".")); // 파일 확장자
      const resizedImage = url.slice(0, url.lastIndexOf(".")) + "_resized" + ext;
      setImageSrc(resizedImage);
    }
  }, [url]);

  return (
    <img
      className={className || "image-container__img"}
      src={imageSrc}
      alt={alt || "이미지를 불러오는데 실패했습니다."}
      onError={() => {
        // resized 이미지 오류날 경우 원본
        setImageSrc(url);
      }}
    />
  );
};
