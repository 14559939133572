import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import TaapMemberFind from "../contract/commonComponents/modal/TaapMemberFind";

type Props = {
  isOpen: boolean;
  memberInfo?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchMemberPopup = (props: Props) => {
  return (
    <div>
      <BaseAbstractModal size="medium" isOpen={props.isOpen}>
        <TaapMemberFind isOpen={props.isOpen} memberInfo={props.memberInfo} onClick={props.onClick} onClose={props.onClose} size={"medium"} />
      </BaseAbstractModal>
    </div>
  );
};

export default SearchMemberPopup;
