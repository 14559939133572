import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse, MediaFile, MediaGroup } from "../public-types";
import { MediaContentsList } from "./../public-types";
import { FileData, FilePolicy } from "./file-types";

/**
 * XFS02. (무인증) 파일 업로드 정책 조회
 */
export async function getFileUploadPoliciesAsync(
  axios: AxiosInstance,
  params?: {
    policyName: string;
  },
): Promise<AxiosResponse<ApiResponse<{ policy: FilePolicy }>>> {
  if (!params?.policyName) {
    throw Error("policyName is not found.");
  }
  return await axios.get(`/api/ctrl/fs/public/policies/${params.policyName}`);
}

/**
 * XFS01. (무인증) 파일 업로드 처리
 */
export async function postFileUploadAsync(
  axios: AxiosInstance,
  params?: {
    imageFile: any;
  },
): Promise<AxiosResponse<ApiResponse<{ media: FileData }>>> {
  if (!params?.imageFile) {
    throw Error("imageFile is not found.");
  }
  return await axios.post(`/api/ctrl/fs/public/files`, params.imageFile);
}

// RFS03. 파일 업로드 처리
export async function uploadFilesAsync(
  axios: AxiosInstance,
  params?: {
    file: any;
    type?: "public" | "private";
  },
): Promise<AxiosResponse<ApiResponse<{ media: FileData }>>> {
  // type 없으면 기본 public 으로 업로드
  const path = ApiPath.file.upload.replace("{type}", params?.type || "public");
  if (!params?.file) {
    throw Error("업로드할 파일이 없습니다.");
  }
  return await axios.post(path, params.file);
}

//REM01. 공통 미디어파일 목록
export async function getMediaFileListAsync(
  axios: AxiosInstance,
  // params?: { serviceId: number; mediaServiceType: Array<string> },
  params?: MediaContentsList,
): Promise<AxiosResponse<ApiResponse<{ content: Array<MediaFile> }>>> {
  return axios.get(ApiPath.media.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// REM02. 공통 미디어파일 등록/수정/삭제
export async function uploadMediaFilesAsync(
  axios: AxiosInstance,
  params?: Array<MediaGroup>,
): Promise<AxiosResponse<ApiResponse<{ content: number }>>> {
  return axios.post(ApiPath.media.update, { mediaGroupList: params });
}

// blob 형태로 파일 받기
export async function getFileDownload(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.get<ApiResponse<any>>(String(params?.url), { responseType: "blob" });
}

/**RPM05. 공통 미디어파일 이동*/
export async function moveMediaFilesAsync(axios: AxiosInstance, params?: MediaFile[]): Promise<AxiosResponse<ApiResponse<{ content: MediaFile[] }>>> {
  return axios.post("/api/ctrl/partners/{partnerId}/pr/mediaFile/move", params);
}
