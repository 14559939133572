import { QuestionStatus } from "src/api/question/question-type";
import { IMenuDoubleSection } from "../types";
import PagePath from "src/pagePath.json";

const BASE_PATH = `${PagePath.question.list}?&statusList=`;
const sortPath = (sortProperty: string) => `&sort.orders[0].property=${sortProperty}&sort.orders[0].direction=DESC`;

const QUESTION_MENU_LIST: IMenuDoubleSection[] = [
  {
    type: "bar",
    element: [
      {
        title: "완료",
        count: -1,
        path: BASE_PATH + QuestionStatus.COMPLETE + sortPath("modifiedDate"),
        status: QuestionStatus.COMPLETE,
        showDateProperty: "createdDate",
      },
    ],
  },
  {
    type: "detail",
    element: [
      {
        title: "접수",
        count: -1,
        path: BASE_PATH + QuestionStatus.WAIT + sortPath("createdDate"),
        status: QuestionStatus.WAIT,
        data: [],
        showDateProperty: "createdDate",
      },
      {
        title: "처리중",
        count: -1,
        path: BASE_PATH + QuestionStatus.PROGRESS + sortPath("modifiedDate"),
        status: QuestionStatus.PROGRESS,
        data: [],
        showDateProperty: "createdDate",
      },
      {
        title: "보류",
        count: -1,
        path: BASE_PATH + QuestionStatus.HOLD + sortPath("modifiedDate"),
        status: QuestionStatus.HOLD,
        data: [],
        showDateProperty: "createdDate",
      },
    ],
  },
];

export { QUESTION_MENU_LIST };
