import moment from "moment";
import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useApiOperation } from "src/api/hooks";
import { patchVisitors } from "src/api/visitor/visitor-api";
import { BaseButton, BaseModal, ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useToast } from "src/recoil/toast/hook";
import { formatPhoneNumber } from "src/utils";
import { getVisitorDetailPath, getVistorDetailPath } from "src/utils/route-util";
import InvitationInfo from "../components/InvitationInfo";
import VisitorInfo from "../components/VisitorInfo";
import useVisitor from "../hooks/useVisitor";
import { VisitorEditForm } from "../visitor-types";
function VisitorEdit() {
  const { setBaseModal } = useModal();
  const { visitApplyNumber, contractApplyNumber } = useParams();

  const location = useLocation();
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const tabs = [
    {
      label: "상세정보",
      value: "basic",
      disabled: false,
    },
  ];

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0], [queryParams]);
  // 탭 클릭시 callback 함수
  const clickTab = (tab: { value: string; label: string }) => {
    const newQueryParamStr = qs.stringify({ tab: tab.value, id: queryParams.id }, { allowDots: true });
    navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
  };

  const scrollContainer = useRef<HTMLDivElement>(null);
  const partner = useRecoilValue(globalPartnerState);
  const { invitationInfo, visitorInfoList } = useVisitor({ visitApplyNumber, contractApplyNumber });
  const formRef = useRef<HTMLFormElement>(null);

  const useFormReturn = useForm<VisitorEditForm>();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { isDirty },
  } = useFormReturn;

  const {
    fields: formVisitorInfoList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visitorInfoList",
  });

  const [modal, setModal] = useState({
    atLeastOneAlert: {
      isOpen: false,
      title: "1명 이상의 방문자 등록이 필요합니다.",
    },
    duplicateAlert: {
      isOpen: false,
      title: "중복으로 등록된 연락처가 있습니다.",
    },
  });

  const { openToast } = useToast();
  const { executeAsync: patchVisitorsAsync } = useApiOperation(patchVisitors);

  // 실재하는 목록 (form에 보이는 목록 그 자체): newContractVisitorIds
  // 기존 목록: visitorInfoList
  // 기존 목록에서 삭제된 목록: visitorInfoList - newContractVisitors = deletedContractVisitorIds
  const edit = async ({ data, visitApplyNumber, partnerId }: { data: VisitorEditForm; visitApplyNumber: string; partnerId: number }) => {
    const invitationInfo = data.invitationInfo;

    const newVisitorInfoList = data.visitorInfoList.map((contractVisitor) => {
      return {
        ...contractVisitor,
        isDeleted: false,
      };
    });

    const newVisitorInfoListIds = newVisitorInfoList.map((contractVisitor) => Number(contractVisitor.contractVisitorId));
    const oldVisitorInfoListIds = visitorInfoList.map((contractVisitor) => Number(contractVisitor.contractVisitorId));

    const deletedVisitoInfoIds = oldVisitorInfoListIds.filter((id) => !newVisitorInfoListIds.includes(id));

    const deletedVisitorInfoList = visitorInfoList
      .map((contractVisitor) => {
        return {
          ...contractVisitor,
          isDeleted: deletedVisitoInfoIds.includes(Number(contractVisitor.contractVisitorId)),
        };
      })
      .filter((contractVisitor) => contractVisitor.isDeleted);

    const contractVisitorList = [...deletedVisitorInfoList, ...newVisitorInfoList].map((contractVisitor) => {
      return {
        contractVisitorId: Number(contractVisitor.contractVisitorId || 0),
        visitorName: contractVisitor.name || "",
        visitorMobileNumber: contractVisitor.phoneNumber || "",
        visitorEmail: contractVisitor.visitorEmail || undefined,
        visitorEmailLang: contractVisitor.invitationType === "email" ? contractVisitor.visitorEmailLang || undefined : undefined,
        visitorCarNumber: contractVisitor.carNumber,
        isDeleted: contractVisitor.isDeleted,
        visitStartTime: moment(invitationInfo.visitStartTime).format(),
        visitEndTime: moment(invitationInfo.visitEndTime).format(),
        meetingPlace: invitationInfo.meetingPlace,
      };
    });

    return await patchVisitorsAsync({
      partnerId,
      visitApplyNumber,
      contractVisitorList,
    });
  };

  const openModal = (modalName: keyof typeof modal) => {
    setModal((prev) => ({ ...prev, [modalName]: { ...prev[modalName], isOpen: true } }));
  };

  const closeModal = (modalName: keyof typeof modal) => {
    setModal((prev) => ({ ...prev, [modalName]: { ...prev[modalName], isOpen: false } }));
  };

  const onSubmit = async (data: VisitorEditForm) => {
    if (data.visitorInfoList.length === 0) {
      openModal("atLeastOneAlert");
      return;
    }

    const result = await edit({
      data,
      visitApplyNumber: visitApplyNumber!,
      partnerId: partner?.id!,
    });

    if (result.status === 200) {
      openToast({
        type: "SUCCESS",
        content: "방문자 정보가 수정되었습니다.",
      });
      navigate(getVistorDetailPath({ visitApplyNumber: visitApplyNumber!, contractApplyNumber: contractApplyNumber! }));
    } else {
      openToast({
        type: "FAIL",
        content: "방문자 정보 수정에 실패했습니다.",
      });
    }
  };

  const handleClickEditButton = () => formRef.current?.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));

  const handleClickCancelEditButton = () =>
    navigate(
      getVisitorDetailPath({
        visitApplyNumber: visitApplyNumber!,
        contractApplyNumber: contractApplyNumber!,
      }),
    );

  useEffect(() => {
    const readOnlyMappedVisitorList = visitorInfoList.map((contractVisitor) => {
      return {
        ...contractVisitor,
        phoneNumber: formatPhoneNumber(contractVisitor.phoneNumber),
        invitationType: contractVisitor.phoneNumber ? "phone" : "email",
      };
    });

    console.log("readOnlyMappedVisitorList :>> ", readOnlyMappedVisitorList);

    reset({
      invitationInfo: invitationInfo,
      visitorInfoList: readOnlyMappedVisitorList,
    });
  }, [invitationInfo, visitorInfoList]);

  return (
    <div>
      <ContentsTitle
        title="방문자 초대"
        tabs={tabs}
        clickTab={clickTab}
        activeTab={activeTab}
        detailInfo={[{ title: "방문신청번호", value: visitApplyNumber || "Error" }]}
      />
      <MetaTag subTitle={visitApplyNumber} />

      {activeTab!.value === "basic" && (
        <div>
          <div className="contents-container__scroll" ref={scrollContainer}>
            <div className="contents-container__wrap page-visitor">
              <form className="" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <InvitationInfo invitationInfo={invitationInfo} useFormReturn={useFormReturn} pageType="edit" />
                <VisitorInfo
                  pageType="edit"
                  append={append}
                  remove={remove}
                  control={control}
                  formVisitorInfoList={formVisitorInfoList}
                  scrollContainer={scrollContainer}
                  useFormReturn={useFormReturn}
                />
              </form>
            </div>
          </div>
          <div className="contents-container__btn-wrap">
            <div className="left-area"></div>
            <div className="right-area">
              {isDirty && (
                <div className="mr20">
                  <p className="required font14 text-red">수정된 사항이 있으니 다시 한번 확인해주세요.</p>
                </div>
              )}
              <BaseButton title={"수정취소"} className="color-white size-large" onClick={handleClickCancelEditButton} />
              {invitationInfo.visitType !== "SELF_REGIST" && (
                <BaseButton
                  title="저장"
                  className="size-large ml10"
                  onClick={async () => {
                    (await trigger()) &&
                      setBaseModal({
                        isOpen: true,
                        title: "저장하시겠습니까?",
                        btnLeftTitle: "취소",
                        btnRightTitle: "확인",
                        onClick: () => {
                          handleClickEditButton();
                          setBaseModal({ isOpen: false });
                        },
                      });
                  }}
                />
              )}
            </div>
          </div>
          {modal.atLeastOneAlert.isOpen && (
            <BaseModal isOpen={true} title={modal.atLeastOneAlert.title} onClick={() => closeModal("atLeastOneAlert")} btnRightTitle="확인" />
          )}
        </div>
      )}
    </div>
  );
}
export default VisitorEdit;
