type PartnerStatus = "STANDBY" | "ACTIVE" | "SHUTDOWN";

interface Partner {
  id?: string;
  code: string;
  name: string;
  useStartDate: string;
  useEndDate: string;
  contractUrl?: string;
  status?: PartnerStatus;
  statusDate?: string;
  createdDate?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  features?: PaPartner[];
}

export interface PaPartner {
  active: boolean;
  name: NameType;
}

type NameType =
  | "CTRLROOM_NAVIGATION_MAINTENANCE_FEE"
  | "CTRLROOM_NAVIGATION_RESERVATION"
  | "CTRLROOM_NAVIGATION_CS"
  | "CTRLROOM_NAVIGATION_WORKORDER"
  | "FEATURE_TAAP_VOC"
  | "FEATURE_RESERVATION_POST_PAYMENT"
  | "CTRLROOM_PRODUCT_S2"
  | "CTRLROOM_PRODUCT_PUBLIC";

interface PaPartnerMember {
  id: string; // 아이디
  email: string; // 이메일
  connections?: string[]; // 로그인 수단 목록
  partners?: string[]; // 소속 파트너 목록
  lastLoginDate?: string; // 마지막 로그인 일시
  createdDate?: string; // 생성 일시
  blocked: boolean; // 상태
}

export const PartnerPrivilegeCode = {
  BASIC: "BASIC",
  BUILDING: "BUILDING",
  PRODUCT: "PRODUCT",
  WORKORDER: "WORKORDER",
  CS: "CS",
  CONTRACT: "CONTRACT",
  RESERVATION: "RESERVATION",
  VISITOR: "VISITOR",
  NOTICE: "NOTICE",
} as const;

export type UnionPartnerPrivilegeCode = (typeof PartnerPrivilegeCode)[keyof typeof PartnerPrivilegeCode];

export const PrivilegeCodeMenus = {
  BASIC: ["main", "monitoring", "provider", "org", "price", "voc", "guide"],
  BUILDING: ["building"],
  PRODUCT: ["product"],
  WORKORDER: ["workorder"],
  CS: ["question"],
  CONTRACT: ["contract", "billing", "user"],
  RESERVATION: ["reservation"],
  VISITOR: ["visitor"],
  NOTICE: ["notice"],
};

// 파트너 권한 타입
export const PartnerPermission = {
  WRITE: "w", // 등록/수정
  READ: "r", // 읽기
  DELETE: "d", // 삭제
} as const;

export type UnionPartnerPermission = (typeof PartnerPermission)[keyof typeof PartnerPermission];

interface PartnerPrivilege {
  code: UnionPartnerPrivilegeCode;
  permissions: string[];
}

interface PaPartnerMemberAuthority {
  partnerId: string | number;
  memberId: string;
  email: string;
  blocked: boolean;
  readOnly: boolean;
  privileges: PartnerPrivilege[];
}

export type { Partner, PartnerStatus, PaPartnerMember, PaPartnerMemberAuthority };
