import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { ContractAccessListResponse, ContractAccessResponse, ContractAccessUpdateData } from "./contractac-types";

// 출입그룹 등록
export async function postContractAccessAsync(
  axios: AxiosInstance,
  params?: ContractAccessUpdateData,
): Promise<AxiosResponse<ApiResponse<ContractAccessResponse>>> {
  return axios.post(`/api/ctrl/partners/{partnerId}/ct/access-group/contract-manage/${params?.contractManageId}/create`, {
    ctAccessGroupList: [params],
  });
}

// 신청/계약 출입그룹 탭 목록
export async function getContractAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractAccessListResponse>>> {
  if (!params?.contractManageId) {
    throw Error("Please check ContractId");
  }
  return await axios.get(`/api/ctrl/partners/{partnerId}/ct/access-group/contract-manage/${params.contractManageId}`);
}

//RCA84. 계약 출입그룹 삭제

export async function deleteContractAccessAsync(
  axios: AxiosInstance,
  params?: {
    ctAccessGroupId: number;
    contractManageId: number;
  },
) {
  return axios.patch(`/api/ctrl/partners/{partnerId}/ct/access-group/contract-manage/${params?.contractManageId}/delete`, {
    ctAccessGroup: { ctAccessGroupId: params?.ctAccessGroupId },
  });
}
