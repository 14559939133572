import { isValidPhoneNumber } from "libphonenumber-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MgmtOfficeModel, MgmtPhoneType } from "src/api/building/building-types";
import { editBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BaseModal } from "src/components";
import { BaseTooltip } from "src/components";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { formatPhoneNumber, onlyNumber, parsedPhoneNumber } from "src/utils";
import { FrontMgmtOfficeModel, FrontMgmtPhoneType } from "../management-type";

type Props = {
  managementDetail?: MgmtOfficeModel;
  buildingId?: string;
  getBuildingManagement: Function;
  contactDetail: MgmtPhoneType | undefined;
};

const MgmtContactForm = ({ managementDetail, buildingId, getBuildingManagement, contactDetail }: Props) => {
  // 토스트
  const { openToast } = useToast();

  const { setAbstractModalZ2, setAbstractModalZ1 } = useModal();

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 관리처 관리 수정
  const { executeAsync: editBuildingManagement } = useApiOperation(editBuildingManagementAsync);

  const defaultValues = useMemo(() => {
    const officeData: FrontMgmtOfficeModel = {
      // buildingId,
      officePhoneList: [
        {
          isEmergencyContact: false,
          isPrimary: false,
          number: "",
          cmdType: "C",
        },
      ],
    };

    return officeData;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FrontMgmtOfficeModel>({
    defaultValues,
  });

  useEffect(() => {
    if (!contactDetail) return;
    setValue("officePhoneList.0", contactDetail);
  }, [contactDetail]);

  // 유효성 확인
  const formValidation = useCallback(() => {
    register(`officePhoneList.0.name`, { required: { value: true, message: "연락처명을 입력해주세요." } });

    register(`officePhoneList.0.number`, {
      validate: {
        required: (value) => {
          const phoneNumber = parsedPhoneNumber(String(value));
          const isPhoneValided = isValidPhoneNumber(String(phoneNumber), "KR"); //mobile 번호가 맞는지 확인

          let message = "";
          let result = true;

          if (!value) message = "전화번호를 입력해주세요.";

          // 대표여부 체크 / 수정 시 대표인데 삭제된것도 체크

          if (value && isPhoneValided === false) message = "전화번호 형식에 맞게 입력해주세요.";
          return (result && isPhoneValided) || message;
        },
      },
    });

    register(`officePhoneList.0.isPrimary`, {
      validate: {
        required: (isPrimary) => {
          let message = "1개 이상의 대표번호가 필요합니다.";
          let result = true;

          const isPrimaryItem = managementDetail?.officePhoneList?.find((item) => item.isPrimary === true);

          const currentItem = watch("officePhoneList.0");

          if (!isPrimaryItem?.isPrimary && !isPrimary) {
            result = false;
          } else if (isPrimaryItem?.id === currentItem.id && !isPrimary) {
            result = false;
          }

          return result || message;
        },
      },
    });
  }, [managementDetail?.officePhoneList, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 수정 데이터 만들기
  const makeRequestEditData = useCallback((data: FrontMgmtOfficeModel) => {
    let editData = {};
    const officePhoneList = data.officePhoneList;

    editData = {
      ...editData,
      officePhoneList: officePhoneList?.map((item: FrontMgmtPhoneType) => {
        if (item.cmdType === "C" || item.cmdType === "D") {
          return { ...item, number: parsedPhoneNumber(String(item.number)) };
        } else {
          return { ...item, cmdType: "U", number: parsedPhoneNumber(String(item.number)) };
        }
      }),
    };

    return editData;
  }, []);
  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: FrontMgmtOfficeModel) => {
      console.log("data", data);
      // 수정 api 통신
      const editData = makeRequestEditData(data);
      // // const editData = data;
      console.log("id", data.id);
      const response = await editBuildingManagement({
        id: String(managementDetail?.id),
        office: editData,
      });
      if (response.status >= 200 && response.status <= 200) {
        openToast({ content: `정상적으로 ${"수정"}되었습니다.` });
        setConfirmModal({ isOpen: false });
        reset();
      }

      await getBuildingManagement(String(buildingId));
      setAbstractModalZ1({ isOpen: false });
      setAbstractModalZ2({ isOpen: false });
    },
    [buildingId, editBuildingManagement, getBuildingManagement, makeRequestEditData, managementDetail],
  );
  // 유효성 검사후 저장
  const onSubmit = useCallback(
    async (data?: FrontMgmtOfficeModel, e?: any) => {
      e.preventDefault();
      const phoneList = managementDetail?.officePhoneList;

      //  새로 등록된 담당자
      const willBePrimaryPhone = data?.officePhoneList![0] || {};
      // 기존 대표 담당자
      const wasPrimaryPhone = phoneList?.find((item) => item.isPrimary);

      if (wasPrimaryPhone?.isPrimary === true && willBePrimaryPhone?.isPrimary === true && wasPrimaryPhone.id !== willBePrimaryPhone.id) {
        let newData = { ...data };
        newData.officePhoneList?.push({ ...wasPrimaryPhone, isPrimary: false, cmdType: "U" });
        setConfirmModal({
          isOpen: true,
          title: "이미 대표번호가 지정되어 있습니다.\n변경하시겠습니까?",
          payload: newData,
          type: "isPrimary",
        });
        return;
      }

      setConfirmModal({ isOpen: true, title: "저장하시겠습니까?", payload: data });
    },
    [managementDetail?.officePhoneList],
  );
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <article className="contents-container__wrap-article">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__sub-title">
          <h2>연락처</h2>
        </div>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">연락처명</p>
          </div>
          <div className="contents-container__grid-contents">
            <Controller
              control={control}
              name={"officePhoneList.0.name"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return <BaseInput onChange={onChange} value={value} name={name} placeholder="연락처명을 입력해주세요." errorText={error?.message} />;
              }}
            ></Controller>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">전화번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <Controller
              control={control}
              name={"officePhoneList.0.number"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    type="phone"
                    onChange={(text: string) => {
                      // const text = onlyNumberAndSign(e.target.value);
                      onChange(text);
                    }}
                    value={value}
                    name={name}
                    placeholder="전화번호를 입력해주세요."
                    errorText={error?.message}
                  />
                );
              }}
            ></Controller>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>대표 지정</p>
          </div>
          <div className="contents-container__grid-contents">
            <Controller
              control={control}
              name={`officePhoneList.0.isPrimary`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <>
                    <BaseCheckbox
                      id={`phoneNumber`}
                      name={name}
                      label="대표"
                      checked={value}
                      onChange={(checked: boolean) => {
                        onChange(checked);
                      }}
                    />
                    {error && <p className="validation-text">{error.message}</p>}
                  </>
                );
              }}
            ></Controller>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p className="mr3">Taap 비상 연락</p>
              <BaseTooltip touchIcon={"QUESTION"} size={16} tooltip="선택한 연락처가 [Taap > 도와주세요 > 비상 연락]에 노출됩니다." />
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <Controller
              control={control}
              name={`officePhoneList.0.isEmergencyContact`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseCheckbox
                    id={`isEmergencyContact`}
                    name={name}
                    label="노출"
                    checked={value}
                    onChange={(checked: boolean) => {
                      onChange(checked);
                    }}
                  />
                );
              }}
            ></Controller>
          </div>
        </section>

        <div className="flex-center-end">
          <BaseButton title="취소" className="color-white mr5" onClick={() => setAbstractModalZ2({ isOpen: false })} />
          <BaseButton title="저장" type="submit" />
        </div>
      </form>

      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          title={confirmModal.title}
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => confirmModal.payload && onClickConfirmModal(confirmModal.payload)}
        >
          {confirmModal.type === "isPrimary" && (
            <div className="minmax220" style={{ margin: "0px auto" }}>
              <div className="flex-start-start mb5">
                <p className="mb4 mr5 text-left">{`기존: `}</p>
                <p className="text-left">{`${managementDetail?.officePhoneList?.find((item) => item.isPrimary)?.name} / ${formatPhoneNumber(
                  String(managementDetail?.officePhoneList?.find((item) => item.isPrimary)?.number || ""),
                )}`}</p>
              </div>
              <div className="flex-start-start">
                <p className="text-left mr5">{`변경: `}</p>
                <p className="text-left">{`${watch("officePhoneList")![0].name} / ${formatPhoneNumber(
                  String(watch("officePhoneList")![0].number),
                )}`}</p>
              </div>
            </div>
          )}
        </BaseModal>
        <BaseModal isOpen={alertModal.isOpen} title={alertModal.title} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
          {alertModal.message && <p className="pre-formatted">{alertModal.message}</p>}
        </BaseModal>
      </>
    </article>
  );
};

export default MgmtContactForm;
