import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { editGroup, getChargeItemsGroupDetail } from "src/api/chargeItems/chargeItems-api";
import { ChargeItems } from "src/api/chargeItems/chargeItems-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat } from "src/utils";

const BasicInfo = () => {
  const navigate = useNavigate();
  const { openToast } = useToast();

  const { id } = useParams();
  const { executeAsync: getGroupDetail } = useApiOperation(getChargeItemsGroupDetail);
  const { executeAsync: editGroupItem } = useApiOperation(editGroup);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [title, setTitle] = useState("");
  const [ctMntGroup, setCtMntGroup] = useState<ChargeItems>({
    buildingId: "",
    buildingName: "",
    ctMntGroup: {
      isUse: false,
      isDeleted: false,
    },
  });

  const groupDetail = useCallback(
    async (groupId: number) => {
      const response: any = await getGroupDetail({ groupId });
      if (response.status >= 200 && response.status <= 299) {
        setCtMntGroup(response.data.data.content);
        setTitle(response.data.data.content.ctMntGroup.groupName);
      } else {
      }
    },
    [getGroupDetail],
  );

  const editGI = useCallback(async () => {
    const response: any = await editGroupItem(ctMntGroup);
    console.log("editGi", response);
    if (response.status >= 200 && response.status <= 299) {
      const path = PagePath.chargeItems.detail.replace(":id", response.data.data.ctMntGroup.groupId);
      openToast({ content: "정상적으로 수정되었습니다." });
      navigate(path);
    } else {
    }
  }, [ctMntGroup, editGroupItem, navigate, openToast]);

  const isUseTrigger = useCallback(
    async (ctMntGroup: ChargeItems) => {
      ctMntGroup.ctMntGroup.isUse = !ctMntGroup.ctMntGroup.isUse;
      await setCtMntGroup(ctMntGroup);
      await editGI();
      setIsOpen(false);
    },
    [editGI],
  );

  const isDeleteTrigger = useCallback(
    async (ctMntGroup: ChargeItems) => {
      ctMntGroup.ctMntGroup.isDeleted = !ctMntGroup.ctMntGroup.isDeleted;
      await setCtMntGroup(ctMntGroup);
      await editGI();
      const path = PagePath.chargeItems.list;
      navigate(path);
    },
    [editGI, navigate],
  );

  useEffect(() => {
    if (id) {
      groupDetail(Number(id));
    }
  }, [id]);

  const goEditBasic = useCallback(() => {
    const path = PagePath.chargeItems.form + "?id=" + id + "&tab=basicInfo";
    navigate(path);
  }, [id, navigate]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {/* 건물 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">건물</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="base-chip flex-center mr8">
                  <>
                    <span className="ml6">
                      [{ctMntGroup?.buildingId}]{ctMntGroup?.buildingName}
                    </span>
                  </>
                </div>
              </div>
            </div>
          </section>
          {/* 부과항목 그룹 명 */}
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">부과항목 그룹 명</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <div className="font14">{ctMntGroup?.ctMntGroup?.groupName}</div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>생성일시</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <div className="font14">
                  {ctMntGroup?.ctMntGroup.createdDate ? moment(ctMntGroup?.ctMntGroup.createdDate).format(ViewYmdFormat.FULL) : "-"}
                </div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>등록 ID</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <div className="font14">{ctMntGroup?.ctMntGroup.createdBy}</div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>최종 수정일</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <div className="font14">
                  {ctMntGroup?.ctMntGroup.modifiedDate ? moment(ctMntGroup?.ctMntGroup.modifiedDate).format(ViewYmdFormat.FULL) : "-"}
                </div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>최종 작성자</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax400">
                <div className="font14">{ctMntGroup?.ctMntGroup.modifiedBy}</div>
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>사용여부</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="flex-row flex-center-start">
                <div className="minmax90 ">
                  <div className="font14">{ctMntGroup?.ctMntGroup.isUse ? "사용중" : "미사용"}</div>
                </div>
                <div className="minmax120">
                  <BaseButton
                    title={ctMntGroup?.ctMntGroup.isUse ? "미사용" : "사용"}
                    className="color-white size-small"
                    onClick={() => setIsOpen(true)}
                  />
                </div>
              </div>
            </div>
          </section>

          <BaseModal
            isOpen={isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => isUseTrigger(ctMntGroup)}
            onClose={() => setIsOpen(false)}
          >
            <p>{ctMntGroup?.ctMntGroup.isUse ? "미사용" : "사용"}으로 전환하시겠습니까?</p>
          </BaseModal>

          <BaseModal
            isOpen={isOpen2}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => isDeleteTrigger(ctMntGroup)}
            onClose={() => setIsOpen2(false)}
          >
            <p>삭제 하시겠습니까?</p>
          </BaseModal>
          <BaseModal isOpen={isOpen3} btnLeftTitle="취소" btnRightTitle="확인" onClick={() => editGI()} onClose={() => setIsOpen3(false)}>
            <p>변경 내역을 저장 하시겠습니까?</p>
          </BaseModal>
          {/* 버튼영역 */}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => navigate(PagePath.chargeItems.list)} />
          <BaseButton title="삭제" onClick={() => setIsOpen2(true)} className="no-outline color-white size-large text-red900 ml10" />
        </div>
        <div className="right-area">
          <BaseButton title="수정" className=" size-large" onClick={goEditBasic} />
        </div>
      </div>
    </>
  );
};
export default BasicInfo;
