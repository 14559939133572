import { Property, SelectOption } from "src/pages/building/building-types";
import SaveFloor from "./SaveFloor";
import SaveHo from "./SaveHo";

type Props = {
  property?: Property;
  saveProperty: Function;
  setProperty: Function;
  properties?: Array<Property>;
  rangeFloorOptions: Array<SelectOption>;
};

// 층 호실 저장 테이블 컴포넌트
const SaveFloorHoTable = ({
  property,
  setProperty,
  saveProperty,
  properties,
  rangeFloorOptions,
}: Props) => {
  // console.log("property", property);

  if (property?.type === "FLOOR") {
    return (
      <SaveFloor
        property={property}
        saveProperty={saveProperty}
        setProperty={setProperty}
        properties={properties}
        rangeFloorOptions={rangeFloorOptions}
      />
    );
  } else {
    return (
      <SaveHo
        property={property}
        saveProperty={saveProperty}
        setProperty={setProperty}
        properties={properties}
        rangeFloorOptions={rangeFloorOptions}
      />
    );
  }
};

export default SaveFloorHoTable;
