import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, Row, useBlockLayout, useRowSelect, useTable } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderListQueryParams, ProviderModel } from "src/api/provider/provider-types";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BasePagination, BaseTooltip } from "src/components";
import BaseScroll from "src/components/BaseScroll";
import TableRadioButton from "../../components/TableRadioButton";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { companyRegistrationHypen } from "src/utils";

type Props = {
  close: (selectedProvider?: any) => void;
  selectedProvider?: any;
};

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchValue?: string;
  supplyType?: string;
};

const ProvidersModal = ({ close, selectedProvider }: Props) => {
  const [providers, setProviders] = useState<Array<ProviderModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);

  // 프롤바이더 목록 api
  const { executeAsync: getProviders } = useApiOperation(getProviderList);

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse("", {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    _queryParams.supplyType = "RENTAL";

    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }

    if (!_queryParams.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "providerId", direction: "DESC" }],
      };
    }

    return _queryParams;
  }, []);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const getProviderListApi = useCallback(
    async (param: ProviderListQueryParams | undefined) => {
      const response = await getProviders(param);
      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviders],
  );

  useEffect(() => {
    console.log(queryParams);
    getProviderListApi(queryParams);
  }, [getProviderListApi, queryParams]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "providerId",
        width: 100,
      },
      {
        Header: "프로바이더 명",
        accessor: "providerName",
        width: 240,
        Cell: ({ value }: Cell<ProviderModel>) => <BaseTooltip className="w-100 text-left" contents={value || "-"} />,
      },
      {
        Header: "사업자 등록 번호",
        accessor: "businessRegistrationNumber",
        width: 220,
        Cell: ({ value }) => (
          <div>
            <span>{companyRegistrationHypen(value) || "-"}</span>
          </div>
        ),
      },
      {
        Header: "등록일",
        accessor: "createdDate",
        width: 150,
        Cell: (props: Cell<ProviderModel>) => {
          return <BaseTooltip contents={props.value} type="date" />;
        },
      },
      {
        Header: "최종 수정일",
        accessor: "modifiedDate",
        width: 150,
        Cell: (props: Cell<ProviderModel>) => {
          return <BaseTooltip contents={props.value} type="date" />;
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows,
    toggleRowSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: providers,
    },
    useBlockLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 80,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedProvider"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    // TODO: 이미 선택되어있는 row index 찾아서 바인딩
    if (selectedProvider && selectedProvider.providerId) {
      const selectedRow = rows.find((row: Row<any>) => row.original.providerId === selectedProvider.providerId);

      if (selectedRow) {
        toggleRowSelected(selectedRow.id, true);
      }
    }
  }, [selectedProvider, rows]);

  const onSearchList = useCallback(
    async (passParams?: QueryParams) => {
      queryParams.page = passParams?.page;
      queryParams.searchValue = passParams?.searchValue;
      queryParams.size = passParams?.size;
      const response = await getProviders(queryParams);

      if (response.status >= 200 && response.status <= 299) {
        setProviders(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getProviders, queryParams],
  );

  const providerPagination = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      console.log(`newQueryParams`, newQueryParams);
      const page = newQueryParams.page;
      setPageNum(Number(page));
      onSearchList(newQueryParams);
    },
    [onSearchList, params],
  );
  return (
    <BaseAbstractModal isOpen={true} className="dialog-modal">
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>프로바이더 선택</h1>
        <div className="right-area">
          <div>
            <BaseInput
              type="text"
              placeholder="프로바이더 명 또는 사업자 등록번호 입력"
              onChange={(value: string) => {
                setParams({ ...params, searchValue: value });
              }}
              value={params.searchValue}
              onSearchClick={() => providerPagination({ page: 0 })}
              onKeyUp={() => providerPagination({ page: 0 })}
            />
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table">
          <div {...getTableProps()} className="base-table sticky px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row, idx: number) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="base-table__tr">
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={pageNum}
          totalElements={pageMeta?.totalElements}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size });
            providerPagination({ ...params, size });
          }}
          goPage={(page: number) => {
            providerPagination({ page });
          }}
          children={
            <>
              <BaseButton title={"취소"} className="color-white mr10" onClick={() => close()} />
              <BaseButton
                title={"선택"}
                onClick={() => {
                  if (selectedFlatRows && selectedFlatRows.length > 0) {
                    close(selectedFlatRows[0].original);
                  } else {
                    close();
                  }
                }}
              />
            </>
          }
        />
      </section>
    </BaseAbstractModal>
  );
};
export default ProvidersModal;
