import { MemberAccountStatusType } from "src/api/member/member-types";
import PagePath from "src/pagePath.json";
import { IMenuDoubleSection } from "../types";

const BASE_PATH = `${PagePath.member.list}?page=0&size=20&role=ROLE_MEMBER&sort.orders[0].property=id&sort.orders[0].direction=DESC&status=`;

const MEMBER_MENU_LIST: IMenuDoubleSection[] = [
  {
    type:'bar',
    element:[],
  },
  {
    type:'detail',
    element:[
      {
        title: "전체",
        count: -1,
        path: BASE_PATH,
      },
      {
        title: "정상",
        count: -1,
        path: BASE_PATH + MemberAccountStatusType.AVAILABLE,
        status: MemberAccountStatusType.AVAILABLE,
      },
      {
        title: "일시정지",
        count: -1,
        path: BASE_PATH + MemberAccountStatusType.LOCKED,
        status: MemberAccountStatusType.LOCKED,
      },
      {
        title: "휴면 계정",
        count: -1,
        path: BASE_PATH + MemberAccountStatusType.INACTIVE,
        status: MemberAccountStatusType.INACTIVE,
      },
      {
        title: "탈퇴",
        count: -1,
        path: BASE_PATH + MemberAccountStatusType.DELETE,
        status: MemberAccountStatusType.DELETE,
      },
    ],
  }
];

export { MEMBER_MENU_LIST };
