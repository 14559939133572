import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ServiceTypes, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import { Modal, Sort } from "src/api/public-types";
import { getCsServiceItemsAsync, submitCsServiceItemAsync } from "src/api/voc/voc-api";
import { CsItemModel, CsServiceItemModel, CsServiceItemsParams } from "src/api/voc/voc-types";
import { BaseButton, BaseConfirmModal, BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import CsServiceColumn from "./columns/CsServiceColumn";
import CsItemsModal from "./components/CsItemsModal";
import FaqSetting from "./components/FaqSetting";
import { useCsOptions } from "src/pages/voc/hooks/useCsOptions";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import _ from "lodash";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const successMessage = [{ value: "C", message: "항목이 추가되었습니다." }];

type Props = {
  serviceId: string;
};

const VocService = ({ serviceId }: Props) => {
  const defaultParams = useMemo(() => {
    return {
      serviceId,
      serviceType: ServiceTypes.SERVICE_BUILDING,
      sort: {
        orders: [{ property: "id", direction: "DESC" }],
      },
    };
  }, [serviceId]);
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 자주하는 질문  팝업
  const [showFaq, setShowFaq] = useState(false);

  // 항목 선택 팝업
  const [showVoc, setShowVoc] = useState(false);

  const [csServiceList, setCsServiceList] = useState<CsServiceItemModel[]>([]);

  const [original, setOriginal] = useState<CsServiceItemModel[]>([]);

  const [params, setParams] = useState<CsServiceItemsParams>(defaultParams);

  const { isAuthority } = usePartnerAuthority();

  // voc 유형
  const { options: vocOptions } = useCsOptions("VoC");

  // Voc항목 목록 api
  const { executeAsync: getCsService } = useApiOperation(getCsServiceItemsAsync);

  // Voc항목 등록 api
  const { executeAsync: submitCsService } = useApiOperation(submitCsServiceItemAsync);

  const convertedColumns = useMemo(
    () => CsServiceColumn.filter((column: any) => (isAuthority("d") ? column : column.Header !== "삭제")),
    [isAuthority],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: convertedColumns as any,
      data: csServiceList,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isAuthority("d"))
        hooks.visibleColumns.push((columns: Array<any>) => [
          ...columns,
          {
            Header: "삭제",
            accessor: "",
            width: 140,
            Cell: ({ row }: Cell<CsItemModel>) => {
              return (
                <button
                  className="base-trash-btn color-gray"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmModal({ isOpen: true, title: "항목을 삭제하시겠습니까?", type: "remove", payload: row.original });
                  }}
                ></button>
              );
            },
          },
        ]);
    },
  );

  const fetchCsService = useCallback(async () => {
    if (params.csTypeIdList === "") {
      delete params.csTypeIdList;
    }
    const { data, status } = await getCsService(params); //2개
    const { data: defaultData, status: defaultStatus } = await getCsService(defaultParams);
    if (status >= 200 && status <= 299) {
      setCsServiceList(data.data.content);
    }
    if (defaultStatus >= 200 && defaultStatus <= 299) {
      setOriginal(defaultData.data.content); //6개
    }
  }, [getCsService, params]);

  useEffect(() => {
    fetchCsService();
  }, [fetchCsService]);

  const onDeleteCsServiceItem = async (passData: CsServiceItemModel) => {
    const newData: CsServiceItemModel = {
      ...passData,
      cmdType: "D",
    };
    await onSubmitCsService([newData]);
  };

  // Voc 항목 등록/수정/삭제 api
  const onSubmitCsService = async (passData: CsServiceItemModel[]) => {
    if (passData) {
      const { status } = await submitCsService(passData);
      if (status >= 200 && status <= 299) {
        setAlertModal({
          isOpen: true,
          title: `항목이 ${
            passData[0].cmdType === "C"
              ? "추가되었습니다." //
              : passData[0].cmdType === "U"
              ? "저장되었습니다."
              : "삭제되었습니다."
          }`,
        });
      }
    }
  };

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article">
            <div className="flex-center-between w-100  mb20">
              <BaseSectionTitle title={"VoC 항목"} className="pb0" />
              <div className="flex-center">
                <div className="minmax140 mr10">
                  <BaseMultiSelect
                    stateOptions={[{ label: "전체", value: "" }, ...vocOptions]}
                    value={(params?.csTypeIdList?.split(",").map(String) as string[]) || []}
                    setStateValue={(csTypeIdList: Array<{ value: string; label: string }>) => {
                      setParams({
                        ...params,
                        csTypeIdList: csTypeIdList.map((item) => item.value).join(","),
                      });
                    }}
                    placeholder="유형"
                  />
                </div>

                {isAuthority("w") && (
                  <div className="d-flex">
                    <BaseButton title="자주 하는 질문 설정" className="color-white mr10" onClick={() => setShowFaq(true)} />
                    <BaseButton
                      title="항목 선택"
                      onClick={() => {
                        setShowVoc(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="contents-container__grid-contents">
              <section className="inner-tab-table">
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {rows.map((row: any) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={`base-table__tr`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  {rows.length === 0 && (
                    <div className="base-table__tr table-cursor-unset">
                      <div className="base-table__td w-100 text-center">
                        <div className="w-100">
                          <span>데이터가 없습니다.</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          {confirmModal.isOpen && (
            <BaseConfirmModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="확인"
              onClose={() => setConfirmModal({ isOpen: false })}
              onClick={() => {
                onDeleteCsServiceItem(confirmModal.payload as CsServiceItemModel);
                setConfirmModal({ isOpen: false });
              }}
              className="px30"
              // title={confirmModal.title}
            >
              <h2 className="font18 font-weight-semibold">{confirmModal.title}</h2>
            </BaseConfirmModal>
          )}
          {alertModal.isOpen && (
            <BaseModal
              isOpen={true}
              btnRightTitle="확인"
              onClick={() => {
                fetchCsService();
                setAlertModal({ isOpen: false });
                setShowFaq(false);
              }}
              title={alertModal.title}
              className="px30"
            ></BaseModal>
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
      <>{showFaq && <FaqSetting list={csServiceList} onSubmit={onSubmitCsService} onClose={() => setShowFaq(false)} />}</>
      <>
        {showVoc && (
          <CsItemsModal
            onClose={() => setShowVoc(false)} //
            serviceId={serviceId}
            onSubmit={onSubmitCsService}
            csServiceList={original}
          />
        )}{" "}
      </>
    </>
  );
};

export default VocService;
