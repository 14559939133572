import { UnionServiceType } from "../adminMemo/adminmemo-types";
import { Sort, UnionCmdType } from "../public-types";

// UserGuideNetwork 인터페이스
export interface UserGuideNetwork {
  id?: string; // 사용안내 네트워크 ID (수정시 필수)
  wifiName?: string; // Wifi (등록시 필수)
  wifiPassword?: string; // Wifi Password
  cmdType?: UnionCmdType; // 등록/수정/삭제 여부 (필수)
}

// 이용안내 타입
export const UserGuideTypes = {
  USER_GUIDE_PARKINGLOT: "USER_GUIDE_PARKINGLOT", // 주차
  USER_GUIDE_ETC: "USER_GUIDE_ETC", // 사용안내 일반
  USER_GUIDE_WIFI: "USER_GUIDE_WIFI",
} as const;

// ProductGuideTypes Union 타입
export type UserGuideType = (typeof UserGuideTypes)[keyof typeof UserGuideTypes];

export const UserGuideTypeMap = {
  USER_GUIDE_UNRECOGNIZED: "-",
  USER_GUIDE_PRINTER: "복합기",
  USER_GUIDE_PARKINGLOT: "주차",
  USER_GUIDE_ETC: "일반",
  USER_GUIDE_WIFI: "네트워크",
} as const;

// UserGuide 인터페이스
export interface UserGuideModel {
  id?: string; // 사용안내 ID (수정시 필수)
  type?: UserGuideType; // 사용안내 타입 (등록시 필수, 수정불가)
  description?: string; // 설명
  location?: string; // 위치정보
  wifiList?: UserGuideNetwork[]; // 고객용 네트워크 (PRODUCT_GUIDE_WIFI인 경우 사용)
  subject?: string; // 제목 (등록시 필수)
  isOpened?: boolean; // 공개 네트워크 여부 (PRODUCT_GUIDE_WIFI 등록시 필수)
  providerId?: string; // 프로바이더 ID (등록시 필수, 수정불가)
  providerName?: string; // FE전용 타입
  partnerId?: string; // 파트너 ID (등록시 필수, 수정불가)
  cmdType?: UnionCmdType; // 등록/수정/삭제 여부 (등록, 수정, 삭제시 필수)
  createdDate?: string; // 생성일
  createBy?: string; // 생성자
  modifiedDate?: string; // 수정일
  modifiedBy?: string; // 수정자
}

export interface GuideListParams {
  id?: string;
  page?: number;
  size?: number;
  sort?: Sort; // id 정렬 지원
  providerId?: string; //프로바이더 ID
  idList?: string; //단건만 검색 (ex 상세조회)
  isOpened?: string; //true: 공개된 사용안내 , false: 비공개된 사용안내
}

export interface GuideMappingListParams {
  serviceId?: string;
  serviceType?: string;
  buildingId?: string;
  isDisplayed?: string;
  sort?: Sort; //가중치,id 내림차순
}

export interface ServiceUserGuide {
  id?: string; // 서비스별 사용안내 매핑정보 ID
  serviceId?: string; // 사용하고자 하는 서비스들의 pk (등록시 필수, 수정불가)
  serviceType?: UnionServiceType; // 사용하고자 하는 서비스들의 타입 (등록시 필수, 수정불가)
  userGuideId?: string; // 매핑할 이용안내의 Id (등록시 필수, 수정불가)
  userGuide?: UserGuideModel; // 조회시 이용안내 내용을 연결하여 보여줌
  buildingId?: string; // 건물 ID (등록시 필수, 수정불가)
  isDisplayed?: boolean; // 노출여부 (default: false)
  orderNums?: number; // 정렬순서
  cmdType?: UnionCmdType; // 등록/수정/삭제 여부 (등록, 수정, 삭제시 필수)
}
