import { Cell } from "react-table";

const FloorColumns: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "층",
    accessor: "floorNum",
    width: 240,
    Cell: ({ row }: Cell<any>) => {
      const isGround = String(row.original.floorNum).includes("-");
      return <span>{`${!isGround ? "지상" : "지하"} ${String(row.original.floorNum).replace("-", "")}층`}</span>;
    },
  },
  {
    Header: "층 명칭",
    accessor: "floorName",
    width: 540,
  },
];
export default FloorColumns;
