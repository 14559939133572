import { AxiosInstance } from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { globalPartnerState, partnersState } from "src/recoil/partners/atom";
import { axiosInstance, axiosInstanceNoLoading } from "src/utils";

/*
  axios 인스턴스에 파트너 아이디를 주입하는 컴포넌트입니다.
  파트너 아이디가 없는 경우 파트너 선택 페이지로 리디렉션됩니다.
  TODO: async/await 필요 없음
  TODO: useLayoutEffect를 사용하면 될듯
*/
function ParnterInitializer({ children }: { children: React.ReactNode }) {
  const globalPartner = useRecoilValue(globalPartnerState);
  const globalPartners = useRecoilValue(partnersState);
  const location = useLocation();
  const navigate = useNavigate();
  const setGlobalPartner = useSetRecoilState(globalPartnerState);
  const [isDone, setIsDone] = useState(false);

  const setAxiosRequestInterceptor = (axiosInstance: AxiosInstance) => {
    return new Promise((resolve) => {
      const id = axiosInstance.interceptors.request.use((config) => {
        if (config.url?.includes("{partnerId}")) {
          config.url = config.url.replace("{partnerId}", String(globalPartner?.id));
        }
        return config;
      });
      resolve(id);
    });
  };

  const pathPartnerId = useMemo(() => {
    return location.pathname.split("/")[4] === "undefined" ? undefined : location.pathname.split("/")[4];
  }, [location]);

  useEffect(() => {
    if (pathPartnerId === undefined) return;
    const partner = globalPartners?.find((partner) => String(partner.id) === pathPartnerId);
    if (!partner) return navigate(PagePath.init);

    setGlobalPartner(partner);
  }, [pathPartnerId]);

  useEffect(() => {
    setIsDone(false);
    (async () => {
      const result = await setAxiosRequestInterceptor(axiosInstance);
      const noLoadingResult = await setAxiosRequestInterceptor(axiosInstanceNoLoading);

      setIsDone(true);

      return () => {
        axiosInstance.interceptors.request.eject(result as number);
        axiosInstanceNoLoading.interceptors.request.eject(noLoadingResult as number);
      };
    })();
  }, [globalPartner?.id]);

  if (!isDone) return null;

  return <>{children}</>;
}
export default ParnterInitializer;
