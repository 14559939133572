import { PriceRules } from "src/api/price/price-types";
export const findPriceRuleToText = (priceRuleType: string) => {
  switch (priceRuleType) {
    case PriceRules.PRICE_RULE_UNRECOGNIZED:
      return "-";
    case PriceRules.PRICE_RULE_PROVIDER:
      return "기본요금";
    case PriceRules.PRICE_RULE_FACILITY:
      return "기본요금";
    case PriceRules.PRICE_RULE_SPECIFIC:
      return "개별요금";
    default:
      return "-";
  }
};
export const findPriceRuleOptionToText = (priceRuleType: string) => {
  switch (priceRuleType) {
    case PriceRules.PRICE_RULE_UNRECOGNIZED:
      return "-";
    case PriceRules.PRICE_RULE_PROVIDER:
      return "프로바이더";
    case PriceRules.PRICE_RULE_FACILITY:
      return "공용공간";
    case PriceRules.PRICE_RULE_SPECIFIC:
      return "-";
    default:
      return "-";
  }
};

export const CommonFacilityType = {
  COMMONFACILITY_UNRECOGNIZED: "COMMONFACILITY_UNRECOGNIZED",
  MEETING_ROOM: "MEETING_ROOM",
  DESK: "DESK",
  REFRESH_ROOM: "REFRESH_ROOM",
} as const;
