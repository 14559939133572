import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { ESignRecipient, SaveEsignDatas } from "src/api/contract/contract-types";
import { BaseButton, BaseDatePicker, BaseInput } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { formatPhoneNumber, onlyNumber, ViewYmdFormat, YmdFormat } from "src/utils";

interface Props {
  setSeletedTemplate: any;
  selectedTemplate: any;
  workflow: SaveEsignDatas;
  setWorkflow: any;
  sendUser: any;
}

const EsignStepTwo = ({ sendUser, setSeletedTemplate, selectedTemplate, workflow, setWorkflow }: Props) => {
  useEffect(() => {
    if (selectedTemplate && Number(selectedTemplate.totalOrderCount) > 0 && workflow.recipientList === undefined) {
      const newArr = [];
      for (let i = 0; i < selectedTemplate.totalOrderCount; i++) {
        const temp: ESignRecipient = {};
        temp["order"] = i + 1;
        temp["isPhoneShowPopup"] = false;
        temp["isPasswordShowPopup"] = false;
        newArr.push(temp);
      }
      const workflowName = selectedTemplate.templateName + "_" + sendUser.businessName + "_" + moment().format(ViewYmdFormat.YYYY_MM_DD);
      console.log("workOrderName", workflowName);
      const workflowTemp = { ...workflow, ...{ workflowName: workflowName, recipientList: newArr } };
      setWorkflow(workflowTemp);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // console.log("workflow", workflow);
  }, [setWorkflow, workflow]);

  return (
    <>
      <section className="base-abstract-modal__contents-subtitle mt0">
        <h2>문서 설정</h2>
      </section>
      <div className="contents-container pb20">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">문서명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div>
              <BaseInput
                name="workOrderName"
                maxLength={128}
                value={workflow.workflowName}
                onChange={(value: string) => {
                  const temp = _.cloneDeep(workflow);
                  const escapeText = /[`~!@#$%^&*()|+\=?;:'",\<\>\{\}\[\]\\\/ ]/gim;
                  console.log("escapeText.test(value", escapeText.test(value));

                  temp.workflowName = value.replace(escapeText, "");

                  setWorkflow(temp);
                }}
              />
            </div>
          </div>
        </section>
        <div className="flex-center flex-row">
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">문서 작성 기한</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax240">
                <BaseDatePicker
                  name={"expiryDate"}
                  type="date"
                  selectedDate={workflow?.expiryDate ? moment(workflow?.expiryDate).toDate() : null}
                  setDate={(value: string) => {
                    const temp = _.cloneDeep(workflow!);
                    let addTime = moment(value).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999";

                    temp.expiryDate = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);
                    console.log("temp.expiryDate", temp.expiryDate);
                    setWorkflow(temp);
                  }}
                  minDate={moment().toDate()}
                  readonly={workflow.workflowName === undefined || workflow.workflowName === ""}
                />
              </div>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="">보낸 사람</p>
            </div>
            <div className="contents-container__grid-contents flex-center-end">
              <BaseInput name="workOrderName" maxLength={128} value={sendUser.name + " (" + sendUser.email + ")"} readonly />
            </div>
          </section>
        </div>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>전달 메세지</p>
          </div>
          <div className="contents-container__grid-contents">
            <div>
              <BaseInput
                name="comment"
                value={workflow.comment}
                maxLength={250}
                placeholder="받는 사람에게 전할 내용을 입력해 주세요."
                onChange={(value: string) => {
                  const temp = _.cloneDeep(workflow);
                  temp.comment = value;
                  setWorkflow(temp);
                }}
              />
            </div>
          </div>
        </section>
      </div>

      <section className="base-abstract-modal__contents-subtitle">
        <h2>받는 사람과 보안 설정</h2>
      </section>
      <div className="contents-container">
        {workflow.recipientList &&
          workflow.recipientList.length > 0 &&
          workflow.recipientList.map((rec: ESignRecipient, key: number) => {
            return (
              <section className="contents-container__grid" key={key}>
                <div className="contents-container__grid-index">
                  <p className="required">{key + 1} 번째 작성자</p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="flex-row flex-center-start">
                    <div className={`${rec.emailError ? "pb20" : ""} minmax200`}>
                      <BaseInput
                        name={"name_" + key}
                        maxLength={250}
                        value={rec.name}
                        placeholder={"받는 사람의 실명"}
                        onChange={(value: string) => {
                          const temp = _.cloneDeep(workflow);
                          temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                            if (index === key) {
                              rep.name = value;
                            }
                          });
                          setWorkflow(temp);
                        }}
                        disabled={workflow.expiryDate === undefined || workflow.expiryDate === ""}
                      />
                    </div>
                    <div className="minmax240 ml10 mr20">
                      <BaseInput
                        name={"email_" + key}
                        maxLength={250}
                        value={rec.email}
                        placeholder={"이메일 또는 휴대폰 번호"}
                        onChange={(value: string) => {
                          const temp = _.cloneDeep(workflow);

                          temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                            if (index === key) {
                              rep.email = value;
                              if (rep.emailError) {
                                delete rep.emailError;
                              }
                            }
                          });

                          setWorkflow(temp);
                        }}
                        onBlur={() => {
                          const temp = _.cloneDeep(workflow);
                          const checkPhone = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
                          const checkEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
                          console.log("checkPhone.test(value)", checkPhone.test(rec.email!), "checkEmail.test(value)", checkEmail.test(rec.email!));
                          if (checkPhone.test(rec.email!) === false && checkEmail.test(rec.email!) === false) {
                            temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                              if (index === key) {
                                rep.emailError = "이메일 또는 휴대폰 번호를 정확히 입력해 주세요.";
                              }
                            });
                          } else {
                            temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                              if (index === key) {
                                delete rep.emailError;
                              }
                            });
                          }

                          setWorkflow(temp);
                        }}
                        disabled={workflow.expiryDate === undefined || workflow.expiryDate === ""}
                        errorText={rec.emailError ? rec.emailError : ""}
                      />
                    </div>

                    <div className={`${rec.emailError ? "pb20" : ""}`}>
                      <p className="text-primary3 mr20">받는 사람의 인증 방법</p>
                    </div>
                    <div className={`${rec.emailError ? "pb20" : ""}`}>
                      <button
                        type="button"
                        className={`base-chip ${rec.mobile ? "--active" : ""} mr10`}
                        onClick={() => {
                          const temp = _.cloneDeep(workflow);
                          temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                            if (index === key) {
                              rep.isPhoneShowPopup = true;
                            }
                          });
                          setWorkflow(temp);
                        }}
                      >
                        휴대폰
                      </button>
                      {rec.isPhoneShowPopup && (
                        <BaseAbstractModal isOpen={rec.isPhoneShowPopup} size={"medium"} className="dialog-modal">
                          <section className="base-abstract-modal__title flex-center-between ">
                            <h1>받는 사람의 휴대폰 인증 추가</h1>
                          </section>
                          <section className="base-abstract-modal__contents mx30">
                            <ul className="base-list">
                              <li>받는 사람의 휴대폰 본인 인증이 추가됩니다.</li>
                              <li>
                                받는 사람의 본인 명의 휴대폰 인증만 가능합니다.
                                <br />
                                (타인, 법인 명의의 휴대폰 본인 인증 불가)
                              </li>
                            </ul>
                            <div className="contents-container mt20">
                              <section className="contents-container__grid my0">
                                <div className="contents-container__grid-index">
                                  <p className="required">받는 사람의 휴대폰 번호</p>
                                </div>
                                <div className="contents-container__grid-contents flex-center-end pl5">
                                  <BaseInput
                                    name={"mobile_" + key}
                                    value={formatPhoneNumber(rec.mobile!)?.replaceAll("-", "")}
                                    placeholder={"숫자만 입력해주세요"}
                                    onChange={(value: string) => {
                                      const temp = _.cloneDeep(workflow);
                                      temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                        if (index === key) {
                                          rep.mobile = onlyNumber(value);
                                          if (rep.mobileError) {
                                            delete rep.mobileError;
                                          }
                                        }
                                      });
                                      setWorkflow(temp);
                                    }}
                                    onBlur={() => {
                                      const temp = _.cloneDeep(workflow);
                                      const checkPhone = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
                                      if (checkPhone.test(rec.mobile!) === false) {
                                        temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                          if (index === key) {
                                            rep.mobileError = "올바르지 않은 휴대폰 번호입니다.";
                                          }
                                        });
                                      } else {
                                        temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                          if (index === key) {
                                            delete rep.mobileError;
                                          }
                                        });
                                      }
                                      setWorkflow(temp);
                                    }}
                                    errorText={rec.mobileError}
                                  />
                                </div>
                              </section>
                            </div>
                          </section>
                          <section className="base-abstract-modal__btn-wrap">
                            <BaseButton
                              title={"닫기"}
                              className="color-white"
                              onClick={() => {
                                const temp = _.cloneDeep(workflow);
                                temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                  if (index === key) {
                                    rep.mobile = "";
                                    rep.isPhoneShowPopup = false;
                                  }
                                });
                                setWorkflow(temp);
                              }}
                            />
                            <BaseButton
                              title={"인증추가"}
                              disabled={
                                rec.mobile === undefined ||
                                rec.mobile === "" ||
                                /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/.test(rec.mobile!) === false
                              }
                              onClick={() => {
                                const temp = _.cloneDeep(workflow);
                                temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                  if (index === key) {
                                    rep.isPhoneShowPopup = false;
                                  }
                                });
                                setWorkflow(temp);
                              }}
                            />
                          </section>
                        </BaseAbstractModal>
                      )}
                    </div>
                    <div className={`${rec.emailError ? "pb20" : ""}`}>
                      <button
                        className={`base-chip ${rec.password ? "--active" : ""} mr10`}
                        type="button"
                        onClick={() => {
                          const temp = _.cloneDeep(workflow);
                          temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                            if (index === key) {
                              rep.isPasswordShowPopup = true;
                            }
                          });
                          setWorkflow(temp);
                        }}
                      >
                        비밀번호
                      </button>
                      {rec.isPasswordShowPopup && (
                        <BaseAbstractModal isOpen={rec.isPasswordShowPopup} size={"medium"} className="dialog-modal ">
                          <section className="base-abstract-modal__title flex-center-between ">
                            <h1>받는 사람의 비밀번호 인증 추가</h1>
                          </section>
                          <section className="base-abstract-modal__contents mx30">
                            <ul className="base-list">
                              <li>문서 열람 시 비밀번호 인증이 추가됩니다.</li>
                              <li>영문과 숫자를 사용할 수 있으며, 대소문자를 구분합니다.</li>
                            </ul>
                            <div className="contents-container mt20">
                              <section className="contents-container__grid">
                                <div className="contents-container__grid-index">
                                  <p className="required">문서 열람 비밀번호</p>
                                </div>
                                <div className="contents-container__grid-contents">
                                  <BaseInput
                                    name={"password_" + key}
                                    value={rec.password}
                                    placeholder={"4~15자 이내로 입력해 주세요."}
                                    maxLength={15}
                                    onChange={(value: string) => {
                                      let passReg = /^[A-Za-z0-9]*$/;
                                      const temp = _.cloneDeep(workflow);
                                      temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                        if (index === key) {
                                          if (passReg.test(value)) {
                                            rep.password = value;
                                          }
                                          if (rep.passwordError) {
                                            delete rep.passwordError;
                                          }
                                        }
                                      });
                                      setWorkflow(temp);

                                      console.log("passReg", passReg.test(value));
                                    }}
                                    onBlur={() => {
                                      const temp = _.cloneDeep(workflow);
                                      if (/^[A-Za-z0-9]{4,15}$/.test(rec.password!) === false) {
                                        temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                          if (index === key) {
                                            rep.passwordError = "비밀번호는 4~15자 이내로 입력해 주세요.";
                                          }
                                        });
                                      } else {
                                        temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                          if (index === key) {
                                          }
                                        });
                                      }

                                      setWorkflow(temp);
                                    }}
                                    errorText={rec.passwordError ? rec.passwordError : ""}
                                  />
                                </div>
                              </section>
                              <section className="contents-container__grid mb0">
                                <div className="contents-container__grid-index">
                                  <p className="">비밀번호 힌트</p>
                                </div>
                                <div className="contents-container__grid-contents">
                                  <BaseInput
                                    name={"passwordHint_" + key}
                                    maxLength={50}
                                    value={rec.passwordHint}
                                    placeholder={"50자 이내로 입력해 주세요."}
                                    onChange={(value: string) => {
                                      const temp = _.cloneDeep(workflow);
                                      temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                        if (index === key) {
                                          rep.passwordHint = value;
                                        }
                                      });
                                      setWorkflow(temp);
                                    }}
                                  />
                                </div>
                              </section>
                            </div>
                          </section>
                          <section className="base-abstract-modal__btn-wrap">
                            <BaseButton
                              title={"닫기"}
                              className="color-white"
                              onClick={() => {
                                const temp = _.cloneDeep(workflow);
                                temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                  if (index === key) {
                                    rep.password = "";
                                    rep.passwordHint = "";
                                    rep.isPasswordShowPopup = false;
                                  }
                                });
                                setWorkflow(temp);
                              }}
                            />
                            <BaseButton
                              title={"인증추가"}
                              disabled={rec.password === undefined || rec.password === "" || /^[A-Za-z0-9]{4,15}$/.test(rec.password!) === false}
                              onClick={() => {
                                const temp = _.cloneDeep(workflow);
                                temp.recipientList!.forEach((rep: ESignRecipient, index: number) => {
                                  if (index === key) {
                                    rep.isPasswordShowPopup = false;
                                  }
                                });
                                setWorkflow(temp);
                              }}
                            />
                          </section>
                        </BaseAbstractModal>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
      </div>
    </>
  );
};
export default EsignStepTwo;
