import { useCallback, useState } from "react";
import { saveCCM24 } from "src/api/chargeMnt/chargeMnt-api";
import { ctMntChartTotal } from "src/api/chargeMnt/chargeMnt-type";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { contract_bill_user_errors } from "src/api/public-types";
import { BaseModal } from "src/components";
import { useErrorModal } from "src/recoil/errorModal/hook";

import { useToast } from "src/recoil/toast/hook";
import { numberToStringWithComma } from "src/utils";

export const columns: any = [
  {
    Header: "id",
    sticky: "left",
    accessor: "itemId",
    columns: [
      {
        Header: "",
        accessor: "itemId",
        width: 90,
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && <div className="ic_ho"></div>}
          {value && value}
        </>
      );
    },
    Footer: ({ row }: any) => {},

    width: 60,
  },
  {
    Header: "부과항목",
    accessor: "itemName",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "itemName",
      },
    ],

    Footer: ({ row }: any) => {},
    width: 80,
  },
  {
    Header: () => {
      return (
        <p>
          부과기준
          <br />
          (부과방식/산정기준)
        </p>
      );
    },
    accessor: "priceUnit",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "priceUnitName",
        Cell: ({ value, row }: any) => {
          return (
            <>
              {!value && ""}
              {value && row.original.priceUnitName + " / " + row.original.roundingModeName}
            </>
          );
        },
        Footer: ({ row }: any) => {
          return <p>합계 :</p>;
        },
      },
    ],
    Footer: ({ row }: any) => {},
    width: 80,
  },
  {
    Header: () => {
      return (
        <p>
          부과방법
          <br />
          (단위범위/반올림)
        </p>
      );
    },
    accessor: "level1",
    sticky: "",
    columns: [
      {
        Header: "",
        accessor: "level1Name",
        width: 120,
        sticky: "",
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="ellipsis2">{value && row.original.level1Name + " / " + row.original.level2Name}</span>
            </>
          );
        },
        Footer: ({ row }: any) => {},
      },
    ],
    Footer: ({ row }: any) => {},
  },
  {
    Header: "당월",
    accessor: "ctMntChartItem",
    sticky: "",
    Footer: ({ row }: any) => {},
    columns: [
      {
        Header: "발생금액",
        accessor: "ctMntChartItem.occurSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ value, row }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctMntChartItem.occurSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "부과금액",
        accessor: "ctMntChartItem.impsSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctMntChartItem.impsSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "차액",
        accessor: "ctMntChartItem.diffSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctMntChartItem.diffSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
    ],
  },
  {
    Header: "전월",
    accessor: "ctPrevMntChartItem",
    sticky: "",
    Footer: ({ row }: any) => {},
    columns: [
      {
        Header: "발생금액",
        accessor: "ctPrevMntChartItem.occurSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ value, row }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctPrevMntChartItem.occurSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "부과금액",
        accessor: "ctPrevMntChartItem.impsSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctPrevMntChartItem.impsSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "차액",
        accessor: "ctPrevMntChartItem.diffSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctPrevMntChartItem.diffSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
    ],
  },
  {
    Header: () => {
      return (
        <p>
          월간차액
          <br />
          (단위범위/반올림)
        </p>
      );
    },
    accessor: "diffMonthlyAmount",
    sticky: "",
    width: 120,
    Footer: ({ row }: any) => {},
    columns: [
      {
        Header: "",
        accessor: "diffMonthlyAmount",
        sticky: "",
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.diffMonthlyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
    ],
  },
  {
    Header: "수정/삭제",
    accessor: "editable",
    sticky: "right",
    Footer: ({ rows }: any) => {},
    columns: [
      {
        Header: "",
        accessor: "editable",
        width: 80,
        Cell: ({ row, rows, value, setFormData, clearForm, formData, setFlagChange, chartId }: any) => {
          const [isDelete, setIsDelete] = useState(false);
          const { executeAsync: addItemOrUpdate } = useApiOperation(saveCCM24);
          const { openErrorModal } = useErrorModal();
          const { openToast } = useToast();
          const insertOrUpdate = useCallback(
            async (formData: ctMntChartTotal) => {
              return await addItemOrUpdate({
                chartId: chartId,
                ctMntChartItemList: [formData.ctMntChartItem],
              });
            },
            [addItemOrUpdate, chartId],
          );

          const setDetail = useCallback(() => {
            clearForm(row.original.itemId);

            setFormData(row.original);
          }, [clearForm, row.original, setFormData]);

          const deleteItem = useCallback(
            async (row: any) => {
              setIsDelete(false);
              clearForm(row.original.itemId);
              row.original.ctMntChartItem.isDeleted = true;
              setFlagChange(String(row.original.itemId), false, false, false, 1000);
              const response: any = await insertOrUpdate(row.original);
              if (response.status > 199 && response.status < 300) {
                openToast({ content: "정상적으로 삭제 되었습니다." });
                setFlagChange(String(row.original.itemId), false, true, false, 1000);
              } else {
                setFlagChange(String(row.original.itemId), false, false, true, 1000);
              }
              setFlagChange(String(row.original.itemId), false, false, false, 3000);
            },
            [clearForm, insertOrUpdate, openErrorModal, openToast, setFlagChange],
          );

          return (
            <>
              <BaseModal
                isOpen={isDelete}
                btnLeftTitle="취소"
                btnRightTitle="확인"
                onClick={() => deleteItem(row)}
                onClose={() => setIsDelete(false)}
              >
                <p>삭제하시겠습니까?</p>
              </BaseModal>
              <div className="d-flex justify-contents-end">
                <>
                  {row.original.successFlag && <button className="base-check-btn"></button>}
                  {row.original.failFlag && <button className="base-fail-btn"></button>}
                  {row.original.loadingFlag && <button className="base-loading-btn"></button>}
                  {row.original.successFlag === false && row.original.loadingFlag === false && row.original.failFlag === false && (
                    <>
                      <button className="base-edit-btn mr4" disabled={row.original.ctMntChartItem.isDeleted} onClick={() => setDetail()}></button>
                      <button className="base-trash-btn" onClick={() => setIsDelete(true)}></button>
                    </>
                  )}
                </>
              </div>
            </>
          );
        },
        Footer: ({ rows }: any) => {},
      },
    ],
  },
];
