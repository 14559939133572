import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { addProviderAccountAsync, confirmAccountHolderAsync, editProviderAccountAsync, getBankCodeListAsync } from "src/api/provider/provider-api";
import { ProviderAccountAddModel, ProviderAccountList, ProviderAccountModel, ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect } from "src/components";
import { Modal } from "src/pages/product/product-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import { onlyNumber } from "src/utils";

type Props = {
  providerDetail?: ProviderDetailModel;
  providerId: number;
  providerDetailApi: Function;
};

const MntTypeAccountForm = ({ providerId, providerDetail, providerDetailApi }: Props) => {
  //
  const location = useLocation();
  const navigate = useNavigate();

  // 토스트
  const { openToast } = useToast();

  const { openErrorModal } = useErrorModal();

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
    payload: true,
  });

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  const defaultValues = useMemo(() => {
    const providerPostData: ProviderAccountAddModel = {
      providerAccountList: [
        {
          providerId,
          accountId: 0,
          bankCode: "",
          accountNumber: "",
          accountHolder: "",
          accountType: "MANAGEMENT_ACCOUNT",
          isDeleted: false,
        },
      ],
    };

    return providerPostData;
  }, [providerId]);

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<ProviderAccountAddModel>({
    defaultValues,
  });

  // 은행리스트 select
  const [bankList, setBankList] = useState<Array<{ value: string; label: string }>>([]);

  // 팝빌 은행코드 api
  const { executeAsync: getBankCodeList } = useApiOperation(getBankCodeListAsync);

  // 팝빌 예금주확인 api
  const { executeAsync: confirmAccountHolder } = useApiOperation(confirmAccountHolderAsync, {
    noHandleError: true,
  });

  /**프로바이더 계좌 등록 api */
  const { executeAsync: postProviderAccount } = useApiOperation(addProviderAccountAsync, {
    noHandleError: true,
  });

  /**프로바이더 계좌 수정 api */
  const { executeAsync: editProviderAccount } = useApiOperation(editProviderAccountAsync, {
    noHandleError: true,
  });

  const {
    fields: accountList,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "providerAccountList",
  });

  // 프로바이더 계좌 detail 정보를 setValue
  useEffect(() => {
    if (providerDetail) {
      const providerAccountList = providerDetail.providerAccountList;
      if (providerAccountList && providerAccountList?.length > 0) {
        setValue("providerAccountList", providerAccountList);
      }
    }
  }, [providerDetail, setValue]);

  //
  //
  // 은행코드 리스트
  const getBankCodeListApi = useCallback(async () => {
    const response = await getBankCodeList({ codeGroup: "POPBILL_BANK_CODE" });
    if (response.status >= 200 || response.status <= 200) {
      const result = response.data.data.content;

      const convertList = result.map((item) => {
        return { value: item.code, label: item.desc };
      });
      convertList.sort((a, b) => (a.label < b.label ? -1 : 1));
      setBankList(convertList);
    }
  }, [getBankCodeList]);

  //
  //
  // 예금주 조회 전 팝빌 인증 유효성검사
  const accountHolderValidation = useCallback(
    (accountNumber: string, bankCode: string) => {
      const popbillLinkId = providerDetail?.providerPopbill?.linkId; // 팝빌 linkId
      const popbillSecretKey = providerDetail?.providerPopbill?.secretKey; //팝빌 secretKey

      if (!popbillLinkId || !popbillSecretKey) {
        setAlertModal({
          isOpen: true,
          message:
            "팝빌 인증정보_ 예금주 조회 API 검증이 완료 후 예금주 정보 확인을 하실 수 있습니다.\n\n 팝빌 인증 정보 입력 후 검증을 진행해 주세요.",
          payload: false,
        });
        return false;
      } else if (!accountNumber && !bankCode) {
        // 은행 / 계좌번호 미입력 시
        setAlertModal({
          isOpen: true,
          message: "은행, 계좌번호 입력 후 예금주 확인이 가능합니다.",
          payload: false,
        });
        return false;
      } //
      else if (accountNumber.length < 8 || accountNumber.length > 14) {
        setAlertModal({
          isOpen: true,
          message: "8자 ~ 14자 숫자를 입력해주세요",
          payload: false,
        });
        return false;
      }

      return true;
    },
    [providerDetail?.providerPopbill, setAlertModal],
  );

  //
  //
  //  예금주 정보 확인 api
  const confirmAccountHolderApi = useCallback(
    async (accountNumber: string, bankCode: string, accountIdx: number) => {
      const check = accountHolderValidation(accountNumber, bankCode);

      if (check === true) {
        const response = await confirmAccountHolder({
          bankCode,
          accountNumber,
          providerId,
        });

        // 정상 200 코드
        if (response && response.status >= 200 && response.status <= 299) {
          const data = response.data.data;
          if (data.result && data.result === "100") {
            setValue(`providerAccountList.${accountIdx}.accountHolder`, data.accountName);
          } else {
            setAlertModal({ isOpen: true, message: data.resultMessage });
            setValue(`providerAccountList.${accountIdx}.accountHolder`, "");
          }
        } else {
          const errors = response.data.meta;

          const errorMessage = errors.errorMessage;
          const errorCode = errors.errorCode;

          let message = "";

          if (errorMessage?.includes("-99004021")) {
            message = "링크아이디가 존재하지 않습니다.";
          } else if (errorMessage?.includes("-99003008")) {
            message = "고유번호의 회원이 존재하지 않습니다.";
          }

          openErrorModal({ errorMessage: message, errorCode, statusCode: response.status });
        }
      }
    },
    [accountHolderValidation, confirmAccountHolder, providerId, openErrorModal],
  );

  useEffect(() => {
    getBankCodeListApi();
  }, [getBankCodeListApi]);

  //

  //
  // 계좌 입력 유효성 확인
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    //
    accountList?.forEach((account, idx: number) => {
      register(`providerAccountList.${idx}.bankCode`, {
        required: { value: true, message: requiredMessage },
      });

      register(`providerAccountList.${idx}.accountNumber`, {
        required: { value: true, message: requiredMessage },
        minLength: { value: 8, message: "8자 ~ 14자 숫자를 입력해주세요" },
        maxLength: { value: 14, message: "8자 ~ 14자 숫자를 입력해주세요" },
      });
      register(`providerAccountList.${idx}.accountHolder`, {
        validate: {
          required: (value) => {
            let result = true;
            let message = "";

            if (!value) {
              result = false;
              message = "예금주 정보 확인은 필수사항입니다.";
              setAlertModal({ isOpen: true, message });
            }

            return result || message;
          },
        },
      });
    });
  }, [accountList, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  //
  //
  // 계좌정보 등록/수정 api
  const updateProviderAccountApi = useCallback(
    async (data: ProviderAccountList) => {
      const providerAccountList = data.providerAccountList || [];
      providerAccountList.map((item) => (item.providerId = providerId));

      // 계좌의 accountId 가 0 이면 신규등록 / 아니면 계좌 수정
      const isAccountId = providerAccountList[0].accountId !== 0;
      const response = isAccountId
        ? await editProviderAccount({ providerAccountList: providerAccountList }) // 수정
        : await postProviderAccount({ providerAccountList }); //등록

      if (response.status >= 200 && response.status <= 200) {
        openToast({ content: `정상적으로 저장 되었습니다.` });
        setConfirmModal({ isOpen: false });
        if (providerAccountList.length === 1 && providerAccountList[0].isDeleted) {
          navigate(location.pathname + location.search);
        }

        await providerDetailApi(String(response.data.data.providerId));
      }
      // 에러메세지 노출
      else {
        const errorCode = response.data?.meta?.errorCode;
        const errorData = Object.keys(response.data?.meta?.errorData).length > 0 ? JSON.parse(response.data?.meta?.errorData) : null;

        let errorMessage = "";

        const detailMessage = errorData
          ? `(구분: ${errorData.supplyType === "RENTAL" ? "프로바이더" : "관리처"}, 파트너 ID: ${errorData.partnerId || "-"}, 프로바이더 ID: ${
              errorData.providerId || "-"
            }, 은행 코드: ${errorData.bankCode}, 계좌번호: ${errorData.accountNumber})`
          : "";

        if (errorCode === "eCT110") {
          errorMessage = `다른 파트너에 사용중인 계좌는 등록할 수 없습니다.${detailMessage ? `\n\n${detailMessage}` : ""}`;
        }

        if (errorCode === "eCT113") {
          errorMessage = `다른 프로바이더/관리처에 사용 중인 계좌는 등록할 수 없습니다.${detailMessage ? `\n\n${detailMessage}` : ""}`;
        }

        if (errorCode === "eCT114") {
          errorMessage = `다른 용도(보증금, 임대료, 관리비)에 사용 중인 계좌는 등록할 수 없습니다.${detailMessage ? `\n\n${detailMessage}` : ""}`;
        }

        if (errorCode) {
          openErrorModal({ errorMessage, errorCode });
        }
      }
    },
    [editProviderAccount, openErrorModal, openToast, postProviderAccount, providerDetailApi, providerId],
  );

  const onSubmit = useCallback((data?: ProviderAccountAddModel, e?: any) => {
    e.preventDefault();

    const accounts = data?.providerAccountList?.map((item) => item.accountNumber);

    //저장시 목록의 중복계좌 체크
    const duplicated = accounts?.some((item) => accounts.indexOf(item) !== accounts.lastIndexOf(item));
    if (duplicated) {
      setAlertModal({ isOpen: true, message: "이미 등록된 계좌번호 입니다." });
      return;
    }

    // 전 계좌 삭제 시 1개 등록요청 경고모달
    const allDeletedAccounts = data?.providerAccountList?.every((item) => item.isDeleted === true);
    if (allDeletedAccounts) {
      setAlertModal({ isOpen: true, message: "최소 1개 이상의 계좌정보가 등록되어야 합니다." });
      return;
    }

    // setSubmitData(data);
    setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", payload: data });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);
  return (
    <>
      <div className="contents-container__sub-title">
        <h2>계좌 정보</h2>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="contents-container__1200">
          {accountList?.map((account: ProviderAccountModel & { id: string }, accountIdx: number) => {
            return (
              <div key={account.id} className={`border-gray py16 pr16 px20 position-relative mb10 ${account.isDeleted === true && "d-none"}`}>
                {providerDetail?.provider?.supplyType === "RENTAL" && (
                  <section className="flex-center mb10">
                    <div className="minmax123">
                      <span className="font13 text-gray600">용도</span>
                    </div>

                    <div className="flex-center h-36 ">
                      {account.accountType === "DEPOSIT_ACCOUNT" && <span className="font12">보증금</span>}
                      {account.accountType === "RENT_ACCOUNT" && <span className="font12">임대료</span>}
                      {account.accountType === "MANAGEMENT_ACCOUNT" && <span className="font12">관리비</span>}
                    </div>
                  </section>
                )}

                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className="required font13 text-gray600">은행</span>
                  </div>
                  <div className="minmax160">
                    <Controller
                      control={control}
                      name={`providerAccountList.${accountIdx}.bankCode`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseSelect
                            stateOptions={bankList}
                            isDisabled={account.accountId !== 0 && true}
                            setStateValue={(value: string) => {
                              // 프로바이더 상세에 저장된 bankCode
                              const accountNumberDetail = providerDetail?.providerAccountList?.[accountIdx]?.bankCode;

                              // 기존 데이터 변경되면  예금주 정보 재확인 필요
                              if (accountNumberDetail !== value) {
                                setValue(`providerAccountList.${accountIdx}.accountHolder`, "");
                              }
                              onChange(value);
                            }}
                            value={value}
                            errorText={error && error.message}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className="required font13 text-gray600">계좌번호</span>
                  </div>
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name={`providerAccountList.${accountIdx}.accountNumber`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            onChange={(accountNumber: string) => {
                              // 프로바이더 상세에 저장된 계좌번호
                              const accountNumberDetail = providerDetail?.providerAccountList?.[accountIdx]?.accountNumber;

                              // 기존 데이터 변경되면  예금주 정보 재확인 필요

                              if (accountNumberDetail !== accountNumber) {
                                setValue(`providerAccountList.${accountIdx}.accountHolder`, "");
                              }
                              onChange(accountNumber);
                            }}
                            value={value ? onlyNumber(value) : ""}
                            name={name}
                            placeholder="8자 ~ 14자 숫자를 입력해주세요"
                            errorText={error && error.message}
                            readonly={account.accountId !== 0 && true}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </section>
                <section className="flex-center">
                  <div className="minmax123">
                    <span className="required font13 text-gray600">예금주</span>
                  </div>
                  <div className="flex-center minmax400">
                    <BaseButton
                      title={"예금주 정보 확인"}
                      className="mr10"
                      onClick={() => {
                        let accountNumber = watch(`providerAccountList.${accountIdx}.accountNumber`);
                        let bankCode = watch(`providerAccountList.${accountIdx}.bankCode`);

                        confirmAccountHolderApi(String(accountNumber), String(bankCode), accountIdx);
                      }}
                    />

                    <Controller
                      control={control}
                      name={`providerAccountList.${accountIdx}.accountHolder`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return <BaseInput onChange={onChange} value={value} name={name} readonly />;
                      }}
                    ></Controller>
                  </div>

                  <div className="w-100 flex-center-end">
                    <BaseButton
                      title="삭제"
                      className="color-white ml4"
                      onClick={() => {
                        if (!account.accountId) {
                          remove(accountIdx);
                        } else {
                          const deletedArr = accountList
                            .filter((item) => item.accountId !== 0)
                            .map((item) => {
                              if (item.accountId === account.accountId) {
                                item = { ...item, isDeleted: true };
                              }
                              return item;
                            });

                          if (deletedArr?.length < 2 && deletedArr[0].isDeleted === true) {
                            setAlertModal({
                              isOpen: true,
                              message: "1개 이상의 계좌정보가 필요합니다.",
                            });
                            return;
                          }

                          setConfirmModal({
                            isOpen: true,
                            message: "삭제하시겠습니까?",
                            payload: { providerAccountList: deletedArr },
                          });
                          // update(accountIdx, { ...account, isDeleted: true });
                        }
                      }}
                    />
                  </div>
                </section>
              </div>
            );
          })}

          <div className="flex-center-end mt10">
            <BaseButton
              title="추가"
              className="color-white mr3"
              onClick={(e: any) => {
                append({
                  providerId,
                  accountId: 0,
                  bankCode: "",
                  accountNumber: "",
                  accountHolder: "",
                  accountType: "MANAGEMENT_ACCOUNT",
                  isDeleted: false,
                });
              }}
            />
            <BaseButton title="저장" type="submit" />
          </div>
        </section>
      </form>
      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            btnLeftTitle="취소"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              // submitData && updateProviderAccountApi(submitData);
              updateProviderAccountApi(confirmModal.payload);

              setConfirmModal({ isOpen: false });
            }}
          >
            <p>{confirmModal.message}</p>
          </BaseModal>
        )}

        {alertModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            onClick={() => {
              setAlertModal({ isOpen: false });
            }}
          >
            <p className="pre-formatted">{alertModal.message}</p>
          </BaseModal>
        )}
      </>
    </>
  );
};

export default MntTypeAccountForm;
