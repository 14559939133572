import qs from "qs";
import { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeviceModel } from "src/api/iot/iot-types";
import { BaseButton } from "src/components";
import IotDevice from "./IotDevice";
import BuildingDeviceForm from "./components/BuildingDeviceForm";
import IotDeviceDetail from "./components/IotDeviceDetail";
import { useIotDevice } from "./hooks/useIotDevice";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { Modal } from "src/api/public-types";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const BuildingIotDevice = () => {
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true }), [location]);
  const { id: buildingId } = useParams();

  const [formOpen, setFormOpen] = useState<Modal>({ isOpen: false });

  const [orders, setOrders] = useState([{ property: "internalDisplayName", direction: "ASC" }]);
  const { iotDevices, buildings, deleteDevice, getDevices } = useIotDevice(
    {
      buildingId: String(buildingId || query.id),
      sort: { orders },
    },
    { executeApi: true },
  );

  const { isAuthority } = usePartnerAuthority();

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article pt0">
            <div className="contents-container__sub-title pb10">
              {!query.tabType && (
                <div className="pt30" style={{ width: 1100 }}>
                  <div className="flex-center-between w-100 mb10">
                    <BaseSectionTitle title={"IoT 기기"} className="pb0" />
                    {isAuthority("w") && (
                      <BaseButton
                        title="+ 기기 추가"
                        onClick={() => {
                          setFormOpen({ isOpen: true });
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            {(!query.tabType || query.tabType === "") && ( //
              <IotDevice deviceList={iotDevices} deleteDevice={deleteDevice} isAuthority={isAuthority("d")} orders={orders} setOrders={setOrders} />
            )}
          </div>
        </div>
      </div>
      {/* 목록으로 */}

      {formOpen.isOpen && (
        <BaseAbstractModal isOpen={true} size="large">
          <BuildingDeviceForm
            building={buildings[0]}
            getDevices={getDevices}
            buildingId={String(buildingId || query.id)}
            cancelModal={() => setFormOpen({ isOpen: false })}
            addedDevice={iotDevices.map((device: any) => {
              return { ...device.building.location, registeredDeviceId: device.registeredDeviceId };
            })}
          />
        </BaseAbstractModal>
      )}
      {buildings.length > 0 && query.tabType === "detail" && (
        <IotDeviceDetail deviceType={"building"} detail={iotDevices.find((device: DeviceModel) => Number(device.id) === Number(query.tabId))} />
      )}
      <div>
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>
          <div className="right-area"></div>
        </div>
      </div>
    </>
  );
};

export default BuildingIotDevice;
