import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";

/*
  부과관리 그룹목록 정보 조회
*/
export async function getChargeItemsList(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.list;
  if (params?.isUse === "All") {
    delete params.isUse;
  }
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}
/*
  부과관리 그룹목록 정보 조회
*/
export async function getChargeItemsGroupDetail(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.groupDetail.replace("{groupId}", params?.groupId);

  return await axios.get<ApiResponse<any>>(path, params);
}

/*
  부과관리 그룹 아이템 목록 정보 조회
*/
export async function getChargeItemList(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.groupItemList.replace("{groupId}", params?.groupId);

  return await axios.get<ApiResponse<any>>(path, params);
}

/*
  부과관리 그룹 등록
*/
export async function addGroup(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.addGroup;

  return await axios.post<ApiResponse<any>>(path, { ctMntGroup: params.ctMntGroup });
}

/*
  부과관리 그룹 수정
*/
export async function editGroup(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.editGroup;

  return await axios.patch<ApiResponse<any>>(path, { ctMntGroup: params.ctMntGroup });
}

/*
  부과관리 그룹 아이템 등록
*/
export async function addGroupItem(
  axios: AxiosInstance,
  params?: {
    groupId: string;
    ctMntItemList: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.addItem.replace("{groupId}", String(params?.groupId));

  return await axios.post<ApiResponse<any>>(path, { ctMntItemList: params?.ctMntItemList });
}

/*
  부과관리 그룹 아이템 수정
*/
export async function editGroupItem(
  axios: AxiosInstance,
  params?: {
    groupId: string;
    ctMntItemList: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.editItem.replace("{groupId}", String(params?.groupId));

  return await axios.patch<ApiResponse<any>>(path, { ctMntItemList: params?.ctMntItemList });
}

/*
  공통코드 조회
*/
export async function getCommonCode(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.chargeItems.findCode + "?codeGroup=" + params?.codeGroup;
  return await axios.get<ApiResponse<any>>(path);
}
