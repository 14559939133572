import React from "react";
import DetailLink from "./DetailLink";
import qs from "qs";
import { useMemo } from "react";
import { Link as ReactRouterLink, LinkProps, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";
import { BaseTooltip } from "./BaseTooltip";

type Props = {
  path: string;
  value: string;
  ellipsis?: boolean;
  className?: string;
  isAddChevronPositionRight?: boolean; // 꺾새 모양 '>' 아이콘을 우측에 붙일지 여부
  tooltip?: boolean;
};

const BaseNewTabLink = (props: Props) => {
  const partner = useRecoilValue(globalPartnerState);
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: (value) => value,
  });

  const encodeQueryParams = useMemo(() => encodeURIComponent(qs.stringify(queryParams, { allowDots: true })), [queryParams]);
  const _to = props.path.replace(":partnerId", String(partner?.id));

  if (props.ellipsis) {
    return (
      <div className={props.className ? `${props.className} flex-center` : "flex-center"}>
        <ReactRouterLink
          className={`text-underline target-blank ellipsis d-block`}
          target="_blank"
          to={encodeQueryParams ? _to + `${props.path.includes("?") ? "&" : "?"}list=${encodeQueryParams}` : _to}
        >
          {props.tooltip ? <BaseTooltip contents={props.value || "-"} isSingleLine /> : props.value || "-"}
        </ReactRouterLink>
        <div className="ic-target-blank"></div>
      </div>
    );
  }

  if (props.tooltip) {
    return (
      <div className={props.className ? `${props.className} flex-center w-full` : "flex-center w-full"}>
        <ReactRouterLink
          className={`text-underline target-blank ellipsis2 d-block`}
          target="_blank"
          to={encodeQueryParams ? _to + `${props.path.includes("?") ? "&" : "?"}list=${encodeQueryParams}` : _to}
        >
          <BaseTooltip contents={props.value || "-"} />
        </ReactRouterLink>
        <div className="ic-target-blank"></div>
      </div>
    );
  }

  return (
    <>
      <ReactRouterLink
        className="text-underline target-blank"
        target="_blank"
        to={encodeQueryParams ? _to + `${props.path.includes("?") ? "&" : "?"}list=${encodeQueryParams}` : _to}
      >
        {props.value || "-"}
      </ReactRouterLink>
      <div className="ic-target-blank"></div>
      {props.isAddChevronPositionRight && <div className="ic-chevron-right mr4"></div>}
    </>
  );
};

export default BaseNewTabLink;
