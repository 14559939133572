import { useAuth0 } from "@auth0/auth0-react";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { ADMIN_MEMO, MediaFile, Modal } from "src/api/public-types";
import { editQuestionStatusAsync, getQuestionDetailAsync } from "src/api/question/qeustion-api";
import { QuestionDetailModel, QuestionStatus, QuestionStatusUnionType } from "src/api/question/question-type";
import { BaseModal, ContentsTitle } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { statusOptions } from "../question-types";
import BasicInfoDetail from "./BasicInfoDetail";
import MetaTag from "src/components/layout/MetaTag";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { ServiceTypes, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getBuildingAsync } from "src/api/building/building-api";
import { getFilteredStatusOptionsAndIsDisableSelectButton } from "../libs";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  // { value: ADMIN_MEMO, label: "관리자 메모" },
];

const QuestionDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: questionId } = useParams();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { isAuthority } = usePartnerAuthority();

  // 상담관리 상세
  const [questionDetail, setQuestionDetail] = useState<QuestionDetailModel>();

  // 상담관리 미디어 파일
  const [questionMediaFiles, setQuestionMediaFiles] = useState<MediaFile[]>([]);
  // 상담관리 답변 미디어 파일

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const [token, setToken] = useState("");
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // 상담관리 상세
  const { executeAsync: getQuestionDetail } = useApiOperation(getQuestionDetailAsync);

  // 상담관리 상태 변경
  const { executeAsync: editQuestionStatus } = useApiOperation(editQuestionStatusAsync);

  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync, { noLoading: true });

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: Array<{ serviceId: number; serviceType: UnionServiceType }>) => {
      const response = await getMediaFileList({ contentsList: data });

      if (response.status >= 200 && response.status <= 299) {
        setQuestionMediaFiles(response.data.data.content);
      }
    },
    [getMediaFileList],
  );

  // 상담관리 상세

  const fetchQuestionDetail = useCallback(
    async (id: number) => {
      const { data } = await getQuestionDetail({ id });
      const result: QuestionDetailModel = data.data.content;
      if (result) {
        setQuestionDetail(result);

        let questionMediaList: Array<{ serviceId: number; serviceType: UnionServiceType }> = [];

        // 상담관리 상세 미디어 파일
        const mediaData: { serviceId: number; serviceType: UnionServiceType } = {
          serviceId: Number(result?.id),
          serviceType: "SERVICE_QUESTION",
        };

        const answerList = result.answerList;
        if (answerList && answerList?.length > 0) {
          questionMediaList = answerList.map((item) => {
            return {
              serviceId: Number(item.id),
              serviceType: "SERVICE_QUESTION_ANSWER",
            };
          });
        }
        questionMediaList.push(mediaData);
        await fetchMediaList(questionMediaList);

        // setTimeout(async () => await fetchMediaList(questionMediaList), 300);
      }
    },
    [fetchMediaList, getQuestionDetail],
  );

  // 토근 가져오기
  const getToken = useCallback(async () => {
    // access token 가져오기
    await getAccessTokenSilently();

    // user IdToken 가져오기 (access token 을 먼저 가져와야 정상적으로 실행됨)
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  // 상세 페이지
  useEffect(() => {
    fetchQuestionDetail(Number(questionId));
    getToken();
  }, [fetchQuestionDetail, getToken, questionId]);

  // 상담관리 상태변경
  const onChangeQuestionStatus = async (id: number, status: QuestionStatusUnionType) => {
    const answerList = questionDetail?.answerList;
    // if (questionDetail?.site === "SITE_TAAP" && questionDetail.status === "QUESTION_CANCELED") {
    //   setAlertModal({ isOpen: true, title: "taap 인입건이 취소상태인 경우 다른 상태로 변경할 수 없습니다" });
    //   return;
    // }

    // if (questionDetail?.site === "SITE_TAAP" && status === "QUESTION_CANCELED") {
    //   setAlertModal({ isOpen: true, title: "taap 인입건의 경우 취소 상태로 수동으로 변경할 수 없으며, 회원에 의해 변경할 수 있습니다" });
    //   return;
    // }

    if (!answerList && status === QuestionStatus.COMPLETE) {
      setAlertModal({ isOpen: true, title: "등록된 답변이 없습니다." });
      return;
    }

    const response = await editQuestionStatus({ id, status });
    if (response.status >= 200 && response.status <= 299) {
      await fetchQuestionDetail(id);
      setConfirmModal({ isOpen: false });
    }
  };

  // 상태변경 select options
  const filteredStatus = useMemo(() => {
    const _statusOptions = statusOptions.filter((item, idx) => idx !== 0);

    let newStatusOptions: any = [];

    switch (questionDetail?.status) {
      case QuestionStatus.COMPLETE: {
        newStatusOptions = _statusOptions.filter((status) => status.value !== QuestionStatus.HOLD);
        break;
      }
      case QuestionStatus.HOLD: {
        newStatusOptions = _statusOptions.filter((status) => status.value !== QuestionStatus.COMPLETE);
        break;
      }
      default: {
        return _statusOptions;
      }
    }
    return newStatusOptions;
  }, [questionDetail?.status, questionDetail?.site]);

  return (
    <div className="page-product-access">
      <MetaTag subTitle={questionId} />

      <ContentsTitle
        title="상담관리"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[{ title: "id", value: questionId || "" }]}
        selectDisabled={getFilteredStatusOptionsAndIsDisableSelectButton(questionDetail).isDisableSelectButton} // taap 유입은 수동으로 상태 변경 불가
        selectOptions={getFilteredStatusOptionsAndIsDisableSelectButton(questionDetail).filteredStatusOptions}
        selectValue={questionDetail?.status}
        setSelectStateValue={(status: QuestionStatusUnionType) => {
          setConfirmModal({ isOpen: true, title: "변경하시겠습니까?", payload: status });
        }}
      />
      {/* 탭 */}
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && (
          <BasicInfoDetail
            token={token}
            questionDetail={questionDetail!}
            questionDetailMedias={questionMediaFiles.filter((item) => item.serviceType === "SERVICE_QUESTION") || []}
            questionAnswerMedias={questionMediaFiles.filter((item) => item.serviceType === "SERVICE_QUESTION_ANSWER")}
            fetchQuestionDetail={fetchQuestionDetail}
          />
        )}
        {/* {activeTab?.value === ADMIN_MEMO && (
          <AdminMemoIntegration serviceId={Number(questionId)} serviceType={ServiceTypes.SERVICE_QUESTION} viewOnly={false} />
        )} */}
      </div>

      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              onChangeQuestionStatus(Number(questionId), confirmModal.payload as QuestionStatusUnionType);
              setConfirmModal({ isOpen: false });
            }}
            title={confirmModal.title}
          ></BaseModal>
        )}
        {alertModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            onClick={() => {
              setAlertModal({ isOpen: false });
            }}
            title={alertModal.title}
          ></BaseModal>
        )}
      </>
    </div>
  );
};

export default QuestionDetail;
