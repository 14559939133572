import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ImageRequestModal } from "src/types/product";
import { ApiResponse, MediaFile, Sort, UnionCmdType } from "../public-types";
import {
  ProductAddModel,
  ProductBuildingCommonFacilitySaveListReq,
  ProductBuildingCommonFacilitySaveListRes,
  ProductDetailType,
  ProductEditModel,
  ProductListData,
  ProductListParams,
  ProductMediaFileList,
  ProductUpdateResponseData,
} from "./product-types";
import { ProductStatusT, SpaceTypeT } from "src/pages/product/product-types";
import { BuildingModel } from "../building/building-types";
import { UnionServiceType } from "../adminMemo/adminmemo-types";

// RPP01. 공간상품 기본정보 목록
export async function getProductListAsync(axios: AxiosInstance, params?: ProductListParams): Promise<AxiosResponse<ApiResponse<ProductListData>>> {
  return await axios.get<ApiResponse<ProductListData>>(ApiPath.product.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// RPP02. 공간상품 기본정보 상태 변경

export async function productStatusAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
    status: ProductStatusT;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const path = ApiPath.product.status.replace("{id}", String(params?.productId));
  return await axios.patch(path, { product: { status: params?.status } });
}

/* 
  RPP03. 공간상품 기본정보 등록
*/
export async function createProductAsync(
  axios: AxiosInstance,
  params?: {
    product: ProductAddModel;
  },
): Promise<AxiosResponse<ApiResponse<{ content?: string }>>> {
  return await axios.post(ApiPath.product.add.basicInfo, params);
}

/* 
  RPP04. 공간상품 정보 수정
*/
export async function updateProductAsync(
  axios: AxiosInstance,
  params?: {
    product: ProductEditModel;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  if (!params?.product.id) {
    throw Error("id is not found.");
  }
  const path = ApiPath.product.edit.replace("{id}", params.product.id.toString());
  return await axios.put(path, params);
}

/* 
  RPP05. 공간상품 기본정보 상세
*/
export async function getProductDetailAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductDetailType>>> {
  if (!params?.productId) {
    throw Error("productId is not found.");
  }
  const path = ApiPath.product.detail.replace("{id}", params.productId.toString());
  return await axios.get(path);
}

// RPP06. 공간상품 기본정보 삭제
export async function deleteProductsAsync(
  axios: AxiosInstance,
  params?: {
    productId: any;
  },
): Promise<AxiosResponse<ApiResponse<ProductUpdateResponseData>>> {
  const path = ApiPath.product.delete.replace("{id}", String(params?.productId));
  return await axios.delete(path);
}

// 공간상품 이미지 등록
export const postPrImageAsync = async (axios: AxiosInstance, parmas?: Array<MediaFile>): Promise<AxiosResponse<ApiResponse<Array<MediaFile>>>> => {
  return await axios.post("/api/ctrl/partners/{partnerId}/pr/mediaFile", parmas);
};

// RPM01. 공통 미디어파일 목록
export const getPrImageAsync = async (
  axios: AxiosInstance,
  params?: ImageRequestModal,
): Promise<AxiosResponse<ApiResponse<ProductMediaFileList>>> => {
  const imageParams = {
    serviceId: Number(params?.serviceId),
    mediaServiceType: String(params?.mediaServiceTypes.join()),
  };

  return axios.get(`/api/ctrl/partners/{partnerId}/pr/mediaFile`, { params: imageParams });
};

// 공간상품 이미지 삭제
export const deletePrImageAsync = async (
  axios: AxiosInstance,
  params?: {
    ids: Array<string>;
  },
) => {
  const { ids } = params || {};

  return axios.delete("/api/ctrl/partners/{partnerId}/pr/mediaFile", { data: { ids } });
};

/* 
  RPP22. 공간상품 빌딩 공용공간 등록/수정/삭제
*/
export async function mutationProductBuildingCommonFacility(
  axios: AxiosInstance,
  params?: {
    productBuildingCommonFacilitySaveList: ProductBuildingCommonFacilitySaveListReq;
    productId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<ProductBuildingCommonFacilitySaveListRes>>> {
  if (!params?.productId) {
    throw Error("id is not found.");
  }

  const path = ApiPath.product.productBuildingCommonFacility.replace("{id}", params.productId.toString());
  return await axios.post(path, { productBuildingCommonFacilitySaveList: params.productBuildingCommonFacilitySaveList });
}

/* 
  RPB12. 공간상품 빌딩 조회
*/
export async function buildingsDetails(
  axios: AxiosInstance,
  params?: {
    building: {
      ids: string[];
    };
  },
): Promise<AxiosResponse<ApiResponse<{ content?: [{ building: BuildingModel }] }>>> {
  const path = ApiPath.building.details;
  return await axios.post(path, params);
}

export interface AvailableReservationTime {
  id?: number; // 예약가능시간대 ID
  serviceId?: number; // 서비스ID
  serviceType?: UnionServiceType; // 서비스타입
  commonFacilityType?: SpaceTypeT; // 공용공간타입
  isDefault?: boolean; // 기본설정여부
  buildingCommonFacilityId?: number; // 건물-공용공간 ID (isDefault=false인 경우 필수)
  isLimitedAvailableReservationTime?: boolean; // 예약가능시간대제한여부
  availableReservationTimeStart?: number; // 예약가능시간대시작 (단위: 분)
  availableReservationTimeEnd?: number; // 예약가능시간대종료
  cmdType?: UnionCmdType; // 요청타입
  partnerId?: number; // 파트너ID
}

export interface AvailableReservationTimeListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  serviceId?: string; // 사용하고자 하는 서비스들의 pk (등록시 필수, 수정불가)
  serviceType?: UnionServiceType; // 사용하고자 하는 서비스들의 타입 (등록시 필수, 수정불가)
  isDefault?: boolean;
  commonFacilityType?: SpaceTypeT;
  buildingCommonFacilityId?: string; // 건물-공용공간 ID (isDefault=false인 경우 필수)
}

export interface UnitReservationTime {
  id?: number; // 예약단위 ID (수정 시 필수)
  serviceId?: number; // 서비스ID
  serviceType?: UnionServiceType; // 서비스타입
  commonFacilityType?: SpaceTypeT; // 공용공간타입
  isDefault?: boolean; // 기본설정여부
  buildingCommonFacilityId?: number; // 건물-공용공간 ID (isDefault=true인 경우 필수)
  isLimitedUnitReservationTime?: boolean; // 예약단위제한여부
  minimumReservationTime?: number; // 최소예약시간 (단위: 분, 제한없음: -1)
  maximumReservationTime?: number; // 최대예약시간
  cmdType?: UnionCmdType; // 요청타입
  partnerId?: number; // 파트너ID
}

export interface UnitReservationTimeListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  serviceId?: string; // 사용하고자 하는 서비스들의 pk (등록시 필수, 수정불가)
  serviceType?: UnionServiceType; // 사용하고자 하는 서비스들의 타입 (등록시 필수, 수정불가)
  isDefault?: boolean;
  commonFacilityType?: SpaceTypeT;
  buildingCommonFacilityId?: string; // 건물-공용공간 ID (isDefault=false인 경우 필수)
}

export interface ReservationPolicyModeListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  serviceId?: string; // 사용하고자 하는 서비스들의 pk (등록시 필수, 수정불가)
  serviceType?: UnionServiceType; // 사용하고자 하는 서비스들의 타입 (등록시 필수, 수정불가)
  commonFacilityType?: SpaceTypeT;
  buildingCommonFacilityId?: string; // 건물-공용공간 ID (isDefault=false인 경우 필수)
  reservationPolicyModeType?: ReservationPolicyModeType;
}

export const reservationPolicyModeTypeMap = {
  RESERVATION_POLICY_MODE_UNRECOGNIZED: "정의되지 않은 상태",
  RESERVATION_POLICY_MODE_AVAILABLE: "예약가능구간",
  RESERVATION_POLICY_MODE_UNIT: "예약시간단위",
  RESERVATION_POLICY_MODE_CLOSED_PERIOD: "휴무일",
} as const;

export type ReservationPolicyModeType = keyof typeof reservationPolicyModeTypeMap;

export interface ReservationPolicyMode {
  id?: number; // 예약단위 ID (수정 시 필수)
  serviceId?: number; // 서비스ID
  serviceType?: UnionServiceType; // 서비스타입
  commonFacilityType?: SpaceTypeT; // 공용공간타입
  isDefault?: boolean; // 기본설정여부
  buildingCommonFacilityId?: number; // 건물-공용공간 ID (isDefault=true인 경우 필수)
  reservationPolicyModeType?: ReservationPolicyModeType;
  cmdType?: UnionCmdType; // 요청타입
  partnerId?: number; // 파트너ID
}

export interface ReservationPolicyClosedPeriod {
  id?: number; // 예약단위 ID (수정 시 필수)
  serviceId?: number; // 서비스ID
  serviceType?: UnionServiceType; // 서비스타입
  commonFacilityType?: SpaceTypeT; // 공용공간타입
  isDefault?: boolean; // 기본설정여부
  buildingCommonFacilityId?: number; // 건물-공용공간 ID (isDefault=false인 경우 필수)
  days?: string; // 요일 (콤마로 구분, 지정하지 않을 경우 빈 값)
  startDate?: string; // 시작일시 (예: 2024-01-01T00:00:00+09:00)
  endDate?: string; // 종료일시 (예: 2024-01-31T00:00:00+09:00)
  startTime?: string; // 시작시간 (30분 단위, 예: 00:00)
  endTime?: string; // 종료시간 (30분 단위, 예: 24:00)
  cmdType?: UnionCmdType; // 요청타입
  partnerId?: number; // 파트너ID
}

export interface ReservationPolicyClosedPeriodListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  serviceId?: string; // 사용하고자 하는 서비스들의 pk (등록시 필수, 수정불가)
  serviceType?: UnionServiceType; // 사용하고자 하는 서비스들의 타입 (등록시 필수, 수정불가)
  commonFacilityType?: SpaceTypeT;
  isDefault?: boolean; // 기본설정여부
  buildingCommonFacilityId?: number; // 건물-공용공간 ID (isDefault=false인 경우 필수)
}

/* 
  RES01. 예약가능구간 설정 등록/수정
  https://pnpt.atlassian.net/wiki/x/EICcLQ
*/
export async function mutationAvailableReservationTimeAsync(
  axios: AxiosInstance,
  params?: AvailableReservationTime[],
): Promise<AxiosResponse<ApiResponse<{ content?: AvailableReservationTime[] }>>> {
  return await axios.post(ApiPath.product.availableReservationTime, {
    availableReservationTimeList: params,
  });
}

/**
 * RES03. 예약가능구간 설정값 DB 조회
 * https://pnpt.atlassian.net/wiki/x/PwCiLQ
 */
export async function getAvailableReservationTimeAsync(
  axios: AxiosInstance,
  params?: AvailableReservationTimeListParams,
): Promise<AxiosResponse<ApiResponse<{ content?: AvailableReservationTime[] }>>> {
  return await axios.get(ApiPath.product.availableReservationTime, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RES04. 예약시간단위 설정 등록/수정
  https://pnpt.atlassian.net/wiki/x/AgCiLQ
*/
export async function mutationUnitReservationTimeAsync(
  axios: AxiosInstance,
  params?: UnitReservationTime[],
): Promise<AxiosResponse<ApiResponse<{ content?: UnitReservationTime[] }>>> {
  return await axios.post(ApiPath.product.unitReservationTime, {
    unitReservationTimeList: params,
  });
}

/**
  RES06. 예약시간단위 설정값 DB 조회
  https://pnpt.atlassian.net/wiki/x/ZACgLQ
 */
export async function getUnitReservationTimeAsync(
  axios: AxiosInstance,
  params?: AvailableReservationTimeListParams,
): Promise<AxiosResponse<ApiResponse<{ content?: UnitReservationTime[] }>>> {
  return await axios.get(ApiPath.product.unitReservationTime, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RES07. 개별예약정책 설정 방식 등록/수정
  https://pnpt.atlassian.net/wiki/x/KACgLQ
*/
export async function mutationReservationPolicyModeAsync(
  axios: AxiosInstance,
  params?: ReservationPolicyMode[],
): Promise<AxiosResponse<ApiResponse<{ content?: ReservationPolicyMode[] }>>> {
  return await axios.post(ApiPath.product.reservationPolicyMode, {
    reservationPoliyModeList: params,
  });
}

/**
  RES08. 개별예약정책 설정 방식 정보 DB 조회
  https://pnpt.atlassian.net/wiki/x/KgCiLQ
 */
export async function getReservationPolicyModeAsync(
  axios: AxiosInstance,
  params?: ReservationPolicyModeListParams,
): Promise<AxiosResponse<ApiResponse<{ content?: ReservationPolicyMode[] }>>> {
  return await axios.get(ApiPath.product.reservationPolicyMode, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  RES09. 휴무일 설정 등록/수정/삭제
  https://pnpt.atlassian.net/wiki/x/OwCgLQ
*/
export async function mutationReservationPolicyClosedPeriodAsync(
  axios: AxiosInstance,
  params?: ReservationPolicyClosedPeriod[],
): Promise<AxiosResponse<ApiResponse<{ content?: ReservationPolicyClosedPeriod[] }>>> {
  return await axios.post(ApiPath.product.reservationPolicyClosedPeriod, {
    reservationPolicyClosedPeriodList: params,
  });
}

/**
  RES10. 휴무일 설정값 DB 조회
  https://pnpt.atlassian.net/wiki/x/TgCgLQ
 */
export async function getReservationPolicyClosedPeriodAsync(
  axios: AxiosInstance,
  params?: ReservationPolicyClosedPeriodListParams,
): Promise<AxiosResponse<ApiResponse<{ content?: ReservationPolicyClosedPeriod[] }>>> {
  return await axios.get(ApiPath.product.reservationPolicyClosedPeriod, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}
