import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ContractorNoti, ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import BaseSection from "src/components/layout/BaseSection";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import pagePath from "src/pagePath.json";
import EstimateDetail from "src/pages/contract/commonComponents/detailComponent/EstimateDetail";
import ExpenseDocumentationDetail from "src/pages/contract/commonComponents/detailComponent/ExpenseDocumentationDetail";
import { checkContractStatus } from "src/pages/contract/contract-util";
import useContractApi from "src/pages/contract/hooks/useContractApi";

const ServiceCourtInfo = ({ contractDetail, scheduleList, rentalSchedule, totalCount }: any) => {
  console.log("scheduleList", scheduleList);
  const { getSchedulesRounds } = useContractApi();

  const [s2Rounds, setS2Rounds] = useState<any>(null);
  const findRounds = useCallback(
    async (scheduleId: string) => {
      const getRounds: any = await getSchedulesRounds(scheduleId);
      if (Array.isArray(getRounds) && getRounds.length > 0) {
        setS2Rounds(getRounds[0]);
      }
    },
    [getSchedulesRounds],
  );
  const [s2TaxNotiOne, setS2TaxNotiOne] = useState<any>({
    email: "",
    isDeleted: false,
    contact: "",
    receiveNotiType: "TAX_INVOICE",
    description: "",
    errorTextEmail: "",
  });
  useEffect(() => {
    if (rentalSchedule?.scheduleId) {
      findRounds(String(rentalSchedule?.scheduleId!));
    }
    const billContractorNoti = _.cloneDeep(contractDetail?.contract?.billContactList);

    const tax = billContractorNoti!.filter((noti: ContractorNoti) => noti.receiveNotiType !== "TALK_OFFICIALDOC");

    if (tax.length > 0) {
      setS2TaxNotiOne(tax[0]);
    }
  }, [contractDetail, findRounds, rentalSchedule]);
  console.log("s2Rounds", s2Rounds);
  return (
    <div>
      <div className="contents-container__wrap">
        <article className="contents-container__wrap-article">
          <BaseSectionTitle title={"신청/계약 정보"} />

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <BaseNewTabLink
                    path={pagePath.contract.detail.replace(":id", contractDetail.contract?.contractId)}
                    value={contractDetail.contractManage?.contractApplyNumber || "-"}
                    ellipsis
                  />
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div
                  className={`${
                    (contractDetail.contract?.contractStep === ContractStep.APPLY_RECEIVED && "chip gray") ||
                    (contractDetail.contract?.contractStep === ContractStep.APPLY_CANCEL && "chip gray") ||
                    (contractDetail.contract?.contractStep === ContractStep.APPLY_CONFIRM && "chip blue") ||
                    (contractDetail.contract?.contractStep === ContractStep.CONTRACT_PLAN && "chip draft") ||
                    (contractDetail.contract?.contractStep === ContractStep.CONTRACT_ACCEPT && "chip blue") ||
                    (contractDetail.contract?.contractStep === ContractStep.USE_APPROVAL && "chip visible") ||
                    (contractDetail.contract?.contractStep === ContractStep.USE_PROGRESS && "chip visible") ||
                    (contractDetail.contract?.contractStep === ContractStep.USE_COMPLETE && "chip gray") ||
                    (contractDetail.contract?.contractStep === ContractStep.TERMINATE_RECEIVED && "chip invisible") ||
                    (contractDetail.contract?.contractStep === ContractStep.TERMINATE_COMPLETE && "chip gray")
                  }`}
                >
                  <p>{contractDetail.contract?.contractStep ? checkContractStatus(contractDetail.contract?.contractStep) : "-"}</p>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 회원번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start w-100 font14">
                  <div className={contractDetail?.product?.productName.length > 15 ? "" : "mr5"}>
                    {contractDetail?.product?.productName !== "" && contractDetail?.product?.productName.length > 15 ? (
                      <div className={"flex-center-start"}>
                        <p>{contractDetail?.product?.productName!.substring(0, 15) + "..."}</p>
                        <BaseTooltip contents={""} tooltip={contractDetail?.product?.productName} touchIcon={"QUESTION"} />
                      </div>
                    ) : (
                      contractDetail?.product?.productName
                    )}{" "}
                  </div>
                  <span className="no-wrap">(id:</span>
                  <BaseNewTabLink
                    path={pagePath.product.detail.replace(":id", contractDetail.product?.id)}
                    value={contractDetail.product?.id || "-"}
                    ellipsis
                  />
                  )
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품유형</p>
              </div>
              <div className="contents-container__grid-contents flex-center">
                <ProductTypeChangeLabel productType={contractDetail?.product?.productType} />
              </div>
            </section>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약자 회원 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* <Link to={""} className="text-underline"> */}
                <p>{contractDetail.contractManage?.contractorList[0]?.memberNo}</p>
                {/* </Link> */}
              </div>
            </section>

            {/* 이름 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약자 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <BaseTooltip contents={contractDetail?.contract?.applicantName} />
              </div>
            </section>
          </div>

          <div className="flex-center">
            <BaseSection title={"세금계산서 수신 이메일"} children={<p>{s2TaxNotiOne.email}</p>} />
          </div>
        </article>
        <article className="contents-container__wrap-article">
          <BaseSectionTitle title={"견적서 정보"} />
          <EstimateDetail detailData={contractDetail} />
        </article>
        <article className="contents-container__wrap-article">
          <BaseSectionTitle title={"결제 정보"} />

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contractDetail?.provider?.provider?.providerName}</p>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 방법</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {s2Rounds !== null && s2Rounds?.bill?.pgcode === "creditcard" && "신용카드"}
                  {s2Rounds !== null && s2Rounds?.bill?.pgcode === "banktransfer" && "계좌이체"}
                  {s2Rounds !== null && s2Rounds?.bill?.pgcode === "CMS" && "CMS"}
                  {s2Rounds !== null && s2Rounds?.bill?.pgcode === "virtualaccount" && "가상계좌"}
                  {s2Rounds === null && "="}
                </p>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {s2Rounds !== null && s2Rounds?.bill?.pgcode === "virtualaccount" && s2Rounds?.paymentList[0]?.virtualAccount && (
              <BaseSection
                title={"ㄴ 부가 정보"}
                children={<p>{s2Rounds?.paymentList[0]?.virtualAccount?.bankName + " " + s2Rounds?.paymentList[0]?.virtualAccount?.accountNo}</p>}
              />
            )}
          </div>
        </article>
        {s2Rounds?.bill?.pgcode === "virtualaccount" && contractDetail?.contract?.expenseProofData?.expenseProofType !== "CASHBILL" && (
          <ExpenseDocumentationDetail detailData={contractDetail} />
        )}
      </div>
    </div>
  );
};

export default ServiceCourtInfo;
