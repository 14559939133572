import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import { ADMIN_MEMO } from "src/api/public-types";
import { changeReservationStatusAsync, getReservationListAsync } from "src/api/reservation/reservation-api";
import { ReservationListModel, ReservationListParams } from "src/api/reservation/reservation-types";
import { BaseModal, ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useToast } from "src/recoil/toast/hook";
import { Modal, ReservationState } from "../reservation-types";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";
import History from "./history/History";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "history", label: "변경내역" },
];
/* 
  공용공간 예약 > 상세
 */
const ReservationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { openToast } = useToast();
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // 공용공간 예약 상태변경 api
  const { executeAsync: changeReservationState } = useApiOperation(changeReservationStatusAsync);

  // 공용공간 예약 목록 조회 api
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);
  const [reservation, setReservation] = useState<ReservationListModel | null>(null);

  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  const { isAuthority } = usePartnerAuthority();

  const fetchReservation = useCallback(
    async (reservationId: string) => {
      // 공용공간 예약 목록 api 로 상세 조회 대신함
      const isDeleted: any = queryParams.isDeleted;
      const reservationListParams: ReservationListParams = {
        page: 0,
        size: 1,
        id: reservationId,
      };
      const { data } = await getReservationList(reservationListParams);

      const _reservation: ReservationListModel | null = data?.data?.content[0] || null;
      if (_reservation) {
        setReservation(_reservation);
      } else {
        setReservation(null);
      }
    },
    [getReservationList, location.search],
  );

  useEffect(() => {
    if (params.id) {
      fetchReservation(params.id);
    }
  }, [params.id, fetchReservation]);

  const findStatus = async (value: string) => {
    switch (value) {
      case ReservationState.ACCEPTED:
        return;
      case ReservationState.CANCELLED:
        setConfirmModal({
          isOpen: true,
          message: "취소로 변경 하시겠습니까?",
          payload: { status: ReservationState.CANCELLED },
        });
    }
  };

  const findSelectOptions = (status: string) => {
    switch (status) {
      case ReservationState.ACCEPTED:
        return [
          { value: ReservationState.ACCEPTED, label: "접수", color: "color-blue" },
          { value: ReservationState.CANCELLED, label: "취소", color: "color-gray" },
        ];
      case ReservationState.ACKNOWLEDGED:
        return [
          { value: ReservationState.ACKNOWLEDGED, label: "점유", color: "color-green" },
          { value: ReservationState.CANCELLED, label: "취소", color: "color-gray" },
        ];
      case ReservationState.INCOMPLETE:
        return [
          { value: ReservationState.INCOMPLETE, label: "불완전", color: "color-gray" },
          { value: ReservationState.CANCELLED, label: "취소", color: "color-gray" },
        ];
      default:
        return [{ value: ReservationState.CANCELLED, label: "취소", color: "color-gray" }];
    }
  };

  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    if (!params.id) {
      throw Error("reservationId is not found");
    }
    const { data } = await changeReservationState({
      id: String(params.id),
      statusCode: ReservationState.CANCELLED, // 취소
    });
    if (data?.data?.reservation) {
      openToast({ content: "정상적으로 변경되었습니다." });
      if (params.id) {
        fetchReservation(params.id);
      }
    }
  }, [changeReservationState, confirmModal.payload?.status, fetchReservation, params.id]);

  return (
    <div className="page-product-access">
      <MetaTag subTitle={params.id ? params.id : ""} />
      {confirmModal.isOpen && (
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => clickModalConfirm()}
          title={confirmModal.message}
        ></BaseModal>
      )}
      <ContentsTitle
        title="공용공간 예약"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[
          { title: "예약ID", value: params.id ? params.id : "" },
          { title: "그룹ID", value: String(queryParams.groupID) ? String(queryParams.groupID) : "" },
        ]}
        selectValue={reservation?.status.code}
        selectOptions={reservation?.status ? findSelectOptions(reservation?.status.code) : []}
        selectDisabled={reservation?.status?.code === ReservationState.CANCELLED ? true : false}
        setSelectStateValue={(value: string) => findStatus(value)}
        colorType="reservation"
      />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab?.value === "basicInfo" && <BasicInfoDetail reservation={reservation} />}
      {activeTab?.value === "history" && <History />}
    </div>
  );
};
export default ReservationDetail;
