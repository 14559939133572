import { useRecoilState } from "recoil";
import { modalObserverState } from "./atom";

/*
  이 훅은 모달 옵저버를 관리하는 훅입니다.
  모달 옵저버는 모달에서 실행할 함수를 관리합니다.
*/
function useModalObserver() {
  const [modalObserver, setModalObserver] = useRecoilState(modalObserverState);

  const generateId = () => {
    if (modalObserver.length === 0) return 1;
    return Math.max(...modalObserver.map((item) => item.id)) + 1;
  };

  const subscribe = (id: number, handler: { [key: string]: () => void }) => {
    setModalObserver((prev) => {
      return [...prev, { id, handler }];
    });
  };

  const unsubscribe = (id: number) => {
    setModalObserver((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  const notify = (id: number, type: string) => {
    const target = modalObserver.find((item) => item.id === id);
    if (target) {
      console.log("target.handler :>> ", target.handler);
      if (typeof target.handler?.[type] === "function") target.handler[type]();
    }
  };

  const getLastId = () => {
    if (modalObserver.length === 0) return 0;
    return modalObserver[modalObserver.length - 1].id;
  };

  return {
    modalObserver,
    generateId,
    subscribe,
    unsubscribe,
    notify,
    getLastId,
  };
}

export default useModalObserver;
