import { ReactNode } from "react";
import { atom } from "recoil";

export interface BaseModalState {
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode | string;
  btnLeftTitle?: string;
  btnRightTitle?: string;
  type?: string;
  btnRightType?: "button" | "submit" | "reset" | undefined;
  className?: string;
  title?: string;
  btnRightDisable?: boolean;
  payload?: any;
}
/**
 * 베이스 모달 스테이트
 */
export const baseModalState = atom<BaseModalState>({
  key: "baseModalState",
  default: { isOpen: false },
});

export interface BaseAbstractModalStateZ1 {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  size?: "small" | "medium" | "large" | "xlarge"; // default xlarge(1000) size
  isTwoDepth?: boolean; // 이중팝업 여부
  isNotUseWhiteBoard?: boolean; // 화이트배경 미사용 여부
  opacity?: number; // 0 ~ 1 default 0.6
}
/**
 * 앱스트릭트 모달 z-index:1 개념
 */
export const baseAbstractModalStateZ1 = atom<BaseAbstractModalStateZ1>({
  key: "baseAbstractModalStateZ1",
  default: { isOpen: false },
});

export interface BaseAbstractModalStateZ2 {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  size?: "small" | "medium" | "large" | "xlarge"; // default xlarge(1000) size
  isTwoDepth?: boolean; // 이중팝업 여부
  isNotUseWhiteBoard?: boolean; // 화이트배경 미사용 여부
  opacity?: number; // 0 ~ 1 default 0.6
}
/**
 * 앱스트릭트 모달 z-index:2 개념
 */
export const baseAbstractModalStateZ2 = atom<BaseAbstractModalStateZ2>({
  key: "baseAbstractModalStateZ2",
  default: { isOpen: false },
});
