import { Cell } from "react-table";
import { Ac2ProductAccessGroupModel } from "src/api/access/productac-types";

export const SelectProductAccessGroupColumn = [
  {
    Header: "출입그룹 id",
    accessor: "id",
    width: 180,
  },
  {
    Header: "출입그룹명",
    accessor: "accessGroupName",
    width: 500,
    Cell: ({ value }: Cell<Ac2ProductAccessGroupModel>) => <div className="w-100 text-left">{value}</div>,
  },
  {
    Header: "방문자 허용",
    accessor: "visitorAccessYn",
    width: 160,
    Cell: ({ value }: Cell<Ac2ProductAccessGroupModel>) => <div>{value ? "허용" : "미허용"}</div>,
  },
];
