import qs from "qs";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync, updateProductAsync } from "src/api/product/product-api";
import { useToast } from "src/recoil/toast/hook";
import UserAccessSection from "./UserAccessSection";

const UserAndVisitorAccess = ({ product }: any) => {
  const { id } = useParams();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { openToast } = useToast();

  const [productInfo, setProduct] = useState<any>(product);

  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  const { executeAsync: updateProduct } = useApiOperation(updateProductAsync);

  //  상세 - 최대인원 멤버 및 현재 이용자 수 확인
  const fetchProduct = async (id: string) => {
    const response = await getProductDetail({ productId: Number(id) });
    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.content;
      setProduct(result);
    }
  };

  useEffect(() => {
    if (product) {
      fetchProduct(product.id);
    }
  }, [product]);

  // 이용자 관리 - 최대 이용자수 변경
  const editProductManageData = async (data: any) => {
    if (data) {
      console.log("???", data);
      const response = await updateProduct({ product: data });
      if (response.status >= 200 && response.status <= 299) {
        await fetchProduct(String(data.id));
        openToast({ content: "정상적으로 수정되었습니다." });
      }
    }
  };

  return (
    <div className="flex-center">
      {/* 이용자 관리 */}
      <UserAccessSection
        product={productInfo || {}}
        setData={(data: ContractOperationProductManage) => {
          if (data) {
            editProductManageData(data);
          }
        }}
      />
    </div>
  );
};

export default UserAndVisitorAccess;
