import { range } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { BuildingModel, CommonFacilityModel, SpaceStyle } from "src/api/building/building-types";
import { postCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { getEslTempProductDetailAsync } from "src/api/esl/esl-api";
import { EslTempProduct } from "src/api/esl/esl-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseTextarea, BaseToggle, BaseTooltip } from "src/components";
import ImagesUpdate from "src/components/ImagesUpdate";
import { floorOptions, Modal, SelectOption, sortingRefreshRoomList, spaceStyleOptions } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import Esl from "src/pages/building/details/facilityDetail/components/Esl";
import { useErrorModal } from "src/recoil/errorModal/hook";
// import { useLoadingBarContext, useToastContext } from "src/pages/hooks";

type Props = {
  building: BuildingModel | null;
  fetchBuilding: (buildingId: number) => void;
};

type FormData = {
  isUsedRefreshSpace: boolean;
  id?: number; // 건물_공용시설_ID (수정시)
  floor: string | null; // 위치 string
  facilityName: string; // 시설명
  spaceStyle: SpaceStyle; // 공간유형
  locationCode: string; // 공간유형 채번
  equipments: string; // 시설안내
  description: string; // 이용 안내
  facilityMaxPeopleNums: number; // 정원
};

interface EslLabel extends EslTempProduct {
  locationCode: string; // 공간유형 채번
}

// 편의시설 컴포넌트
const RefreshRoomSection = ({ building, fetchBuilding }: Props) => {
  // 로딩바
  //
  const { openErrorModal } = useErrorModal();

  // 토스트
  // const { openToast } = useToast();

  // API 에서 조회한 편의시설 목록
  const [refreshRoomList, setRefreshRoomList] = useState<Array<CommonFacilityModel>>([]);

  // 편의시설별 이미지
  const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);

  // 건물 공용공간 관리(등록/수정/삭제) API
  const { executeAsync: postCommonfacility } = useApiOperation(postCommonfacilityAsync, {
    noHandleError: true,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  // const [alertModal, setAlertModal] = useState<Modal>({
  //   isOpen: false,
  // });

  // useForm defaultValues
  const defaultValues: FormData = useMemo(() => {
    return {
      isUsedRefreshSpace: false,
      id: undefined,
      floor: "1",
      facilityName: "",
      spaceStyle: "SPACE_STYLE_UNRECOGNIZED",
      locationCode: "",
      equipments: "",
      description: "",
      facilityMaxPeopleNums: 1,
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  const fetchRefreshRoom = useCallback(async () => {
    const refreshRoomList = sortingRefreshRoomList(building?.buildingCommonFacility?.refreshRoomList || []);

    // locaiotn code 로 라벨 목록 조회
    const locationCodes: Array<string> = [];
    for (let i = 0; i < refreshRoomList.length; i++) {
      const locationCode = refreshRoomList[i].locationCode;
      if (!!locationCode) {
        locationCodes.push(locationCode);
      }
    }

    setRefreshRoomList(refreshRoomList);
    if (refreshRoomList.length > 0) {
      setValue("isUsedRefreshSpace", true);
    } else {
      setValue("isUsedRefreshSpace", false);
    }
  }, [building, setValue]);

  // 수정시 api 에서 받아온 building 정보로 setValue 처리
  useEffect(() => {
    if (building) {
      fetchRefreshRoom();
    }
  }, [building, fetchRefreshRoom]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    register("floor", {
      required: "위치은 필수입력 항목입니다.",
    });
    register("facilityName", {
      required: "공간명은 필수입력 항목입니다.",
    });
    register("spaceStyle", {
      validate: {
        required: (spaceStyle?: SpaceStyle) => {
          const requiredMessage = "공간유형은 필수입력 항목입니다.";
          return (!!spaceStyle && spaceStyle !== "SPACE_STYLE_UNRECOGNIZED") || requiredMessage;
        },
      },
    });
    register("locationCode", {
      required: "Location code 는 필수입력 항목입니다.",
    });
  }, [register]);

  // 건물 > 기본정보 > 규모 범위 내의 층 (위치) 옵션
  const rangeFloorOptions: Array<SelectOption> = useMemo(() => {
    // 건물 > 기본정보 > 규모 범위
    const floorRange = range(
      Number(building?.undergroundFloorNums || 0) === 0 ? 0 : Number(building?.undergroundFloorNums || 0) * -1,
      Number(building?.floorNums || 0) + 1,
    );
    const _rangeFloorOptions: Array<SelectOption> = [];
    for (let i = 0; i < floorRange.length; i++) {
      const floor: number = floorRange[i];
      if (floor !== 0) {
        _rangeFloorOptions.push({
          value: String(floor || ""),
          label: floorOptions.find((v: SelectOption) => v.value === String(floor || ""))?.label || "",
        });
      }
    }
    if (_rangeFloorOptions.length === 0) {
      return floorOptions;
    } else {
      return _rangeFloorOptions.sort((a: SelectOption, b: SelectOption) => Number(b.value || 0) - Number(a.value || 0));
    }
  }, [building]);

  // 수정 버튼 클릭
  const clickEdit = useCallback(
    (rowData: CommonFacilityModel) => {
      clearErrors();

      // 편의시설 input form data 바인딩
      const isGround: boolean = rowData?.isGround === true; // 지상여부
      const floorNum: number = rowData?.floorNum || 1; // 위치
      const floor = `${isGround ? "" : "-"}${floorNum}`;

      // 규모 범위 안에 존재하지 않는 층이면 null 로 바인딩되서 선택되어있지 않게 처리
      const isFindFloorOption = !!rangeFloorOptions.find((option: SelectOption) => option.value === floor);

      const formData: FormData = {
        isUsedRefreshSpace: true,
        id: rowData.id,
        floor: isFindFloorOption ? floor : null,
        facilityName: rowData?.facilityName || "",
        spaceStyle: rowData.spaceStyle || "SPACE_STYLE_UNRECOGNIZED",
        locationCode: rowData?.locationCode || "",
        facilityMaxPeopleNums: rowData?.facilityMaxPeopleNums || 1,
        equipments: (rowData?.facilityList || []).join(","),
        description: rowData?.description || "",
      };

      for (const [name, value] of Object.entries(formData)) {
        setValue(name as keyof FormData, value, {
          shouldDirty: true,
        });
      }

      // 편의시설 이미지 바인딩
      setMediaList(rowData?.mediaList || []);
    },
    [clearErrors, setValue, rangeFloorOptions],
  );

  // 삭제 버튼 클릭
  const clickDelete = (rowData: CommonFacilityModel) => {
    setConfirmModal({
      title: "삭제하시겠습니까?",
      isOpen: true,
      type: "REFRESH_ROOM_DELETE",
      payload: rowData,
    });
  };

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ value }: Cell<CommonFacilityModel>) => <BaseTooltip contents={value} isSingleLine={true} />,
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 100,
        Cell: ({ row }: any) => {
          const isGround: boolean = row?.original?.isGround; // 지상여부
          const floorNum: number = row?.original?.floorNum; // 위치
          const floor = floorOptions.find((option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`);
          return floor?.label || "";
        },
      },
      {
        Header: "공간 명",
        accessor: "facilityName",
        width: 100,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "공간유형",
        accessor: "spaceStyle",
        width: 80,
        Cell: ({ value }: any) => {
          const findOption = spaceStyleOptions.find((option: SelectOption) => option.value === value);
          if (findOption && findOption.value !== "SPACE_STYLE_UNRECOGNIZED") {
            return <BaseTooltip contents={findOption.label} />;
          }
          return "";
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 80,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "정원",
        accessor: "facilityMaxPeopleNums",
        width: 60,
      },
      {
        Header: "시설안내",
        accessor: "equipments",
        width: 130,
        Cell: ({ row }: any) => {
          const facilityList: Array<string> = row?.original?.facilityList || []; // 시설안내
          return <BaseTooltip contents={facilityList.join(",")} preformatted />;
        },
      },
      {
        Header: "이용 안내",
        accessor: "description",
        width: 150,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} preformatted />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 80,
        Cell: ({ row }: any) => <Esl row={row} />,
      },
      {
        Header: "이미지",
        accessor: "images",
        width: 60,
        Cell: ({ row }: any) => {
          const imageCount = (row?.original?.mediaList || []).length;
          return <div>{imageCount}</div>;
        },
      },
      {
        Header: "수정/삭제",
        accessor: "buttons",
        Cell: ({ row }: any) => {
          return (
            <div className="minmax50 d-flex justify-contents-end">
              <button type="button" className="base-edit-btn mr4" onClick={() => clickEdit(row?.original)}></button>
              <button type="button" className="base-trash-btn" onClick={() => clickDelete(row?.original)}></button>
            </div>
          );
        },
        width: 80,
      },
    ],
    [clickEdit],
  );

  // CommonFacilityModel 으로 변환
  const convertCommonFacilityModel = useCallback(
    (buildingId: number, data: FormData) => {
      const commonFacilityModel: CommonFacilityModel = {
        id: data?.id,
        buildingId: buildingId,
        commonFacilityType: "REFRESH_ROOM", // 편의시설
        isGround: Number(data.floor) > 0,
        floorNum: Math.abs(Number(data.floor)),
        facilityName: data.facilityName,
        facilityList: data.equipments.split(","),
        facilityMaxPeopleNums: data.facilityMaxPeopleNums,
        locationCode: data.locationCode,
        description: data.description,
        spaceStyle: data.spaceStyle,
        mediaList: mediaList.map((mediaFile: MediaFile) => {
          const additionalMediaFile: MediaFile = {
            ...mediaFile,
            ...{
              mediaServiceType: "MEDIA_BUILDING_REFRESH_ROOM", // 리프레쉬공간
              category1: "",
              category2: "",
            },
          };
          return additionalMediaFile;
        }),
      };
      return commonFacilityModel;
    },
    [mediaList],
  );

  // 취소 버튼클릭시 위치 form 리셋
  const resetForm = useCallback(() => {
    setMediaList([]);
    clearErrors();
    for (const [name, value] of Object.entries(defaultValues)) {
      if (name !== "isUsedRefreshSpace") {
        setValue(name as keyof FormData, value);
      }
    }
  }, [clearErrors, setValue, defaultValues]);

  // 편의시설 저장
  const saveRefreshRoom = useCallback(
    async (submitData: FormData) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }

        //

        // 주차장/회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
        const deskSpace = {
          deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        // 새롭게 저장할 편의시설 목록
        let newRefreshRoomList: Array<CommonFacilityModel> = [];
        // 기존 편의시설
        newRefreshRoomList = [...refreshRoomList];

        if (submitData?.id) {
          // id 가 있으면, 편의시설 수정
          newRefreshRoomList = newRefreshRoomList.map((currentRefreshRoom: CommonFacilityModel) => {
            if (currentRefreshRoom.id === submitData.id) {
              // id 가 동일한 수정할 편의시설
              const newRefreshRoom: CommonFacilityModel = convertCommonFacilityModel(building.id, submitData);
              return newRefreshRoom;
            } else {
              return currentRefreshRoom;
            }
          });
        } else {
          // id 가 없으면 편의시설 등록
          const newRefreshRoom: CommonFacilityModel = convertCommonFacilityModel(building.id, submitData);
          newRefreshRoomList.push(newRefreshRoom);
        }

        // 등록 또는 수정
        const { status, data: upsertResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList,
          meetingRoomList,
          deskSpace,
          refreshRoomList: newRefreshRoomList,
        });

        if (status >= 200 && status <= 299 && upsertResultData?.data?.content) {
          // openToast("정상적으로 저장 되었습니다.");

          // form reset
          resetForm();

          // 이미지 reset
          setMediaList([]);

          // 건물 다시 상세조회
          await fetchBuilding(building.id);
        } else {
          if (upsertResultData?.meta?.errorCode === "ePR0553") {
            openErrorModal({ statusCode: status, errorCode: upsertResultData?.meta?.errorCode, errorMessage: "Location code 가 중복입니다." });
          } else {
            openErrorModal({ statusCode: status, errorCode: upsertResultData?.meta?.errorCode });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        //
      }
    },
    [building, fetchBuilding, postCommonfacility, resetForm, convertCommonFacilityModel],
  );

  // 편의시설 초기화 저장 (삭제)
  const deleteAllRefreshRoom = useCallback(async () => {
    try {
      if (!building?.id) {
        throw Error("building.id is not found.");
      }

      //

      // 주차장/회의실/좌석/편의시설 기본 값
      const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
      const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
      const deskSpace = {
        deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
        mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
      };

      const { status, data: deleteAllResultData } = await postCommonfacility({
        buildingId: building.id,
        parkingLotList,
        meetingRoomList,
        deskSpace,
        refreshRoomList: [], // 초기화
      });
      if (status >= 200 && status <= 299 && deleteAllResultData?.data?.content) {
        // openToast("정상적으로 초기화 저장 되었습니다.");

        // form reset
        resetForm();

        // 이미지 reset
        setMediaList([]);

        // 건물 다시 상세조회
        await fetchBuilding(building.id);
      } else {
        openErrorModal({ statusCode: status, errorCode: deleteAllResultData?.meta?.errorCode });
      }
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  }, [building, fetchBuilding, postCommonfacility, resetForm]);

  // 편의시설 row 삭제
  const deleteRefreshRoom = useCallback(
    async (submitData: CommonFacilityModel) => {
      try {
        if (!building?.id) {
          throw Error("building.id is not found.");
        }

        //

        // 주차장/회의실/좌석/편의시설 기본 값
        const meetingRoomList = building?.buildingCommonFacility?.meetingRoomList || [];
        const parkingLotList = building?.buildingCommonFacility?.parkingLotList || [];
        const deskSpace = {
          deskGroupList: building?.buildingCommonFacility?.deskSpace?.deskGroupList || [],
          mediaList: building?.buildingCommonFacility?.deskSpace?.mediaList || [],
        };
        const refreshRoomList = building?.buildingCommonFacility?.refreshRoomList || [];

        // 새롭게 저장할 편의시설 목록
        const newRefreshRoomList: Array<CommonFacilityModel> = [];

        for (let i = 0; i < refreshRoomList.length; i++) {
          const currentRefreshRoom: CommonFacilityModel = refreshRoomList[i];
          if (currentRefreshRoom.id !== submitData.id) {
            // 선택한 편의시설 제외하고 저장
            newRefreshRoomList.push(currentRefreshRoom);
          }
        }
        // 선택한 편의시설 제외하고 저장 (삭제)
        const { status, data: deleteResultData } = await postCommonfacility({
          buildingId: building.id,
          parkingLotList,
          meetingRoomList,
          deskSpace,
          refreshRoomList: newRefreshRoomList,
        });
        if (status >= 200 && status <= 299 && deleteResultData?.data?.content) {
          // openToast("정상적으로 삭제 되었습니다.");

          // form reset
          resetForm();

          // 이미지 reset
          setMediaList([]);

          // 건물 다시 상세조회
          await fetchBuilding(building.id);
        } else {
          if (deleteResultData?.meta?.errorCode === "ePR0517") {
            const errorData = deleteResultData?.meta?.errorData;
            let errorMessage = "";
            const isReserved = Object.keys(errorData)[0] === "reservationId";

            for (let i in errorData) {
              errorMessage = `사용중인 공용공간은 삭제할 수 없습니다.\n ${
                isReserved ? "공용공간 예약 내역을 확인해 주세요." : "해당 공용공간을 하위 단계에서 먼저 삭제해 주세요."
              } \n ${i}: [${errorData[i]}]`;
            }

            openErrorModal({ statusCode: status, errorCode: deleteResultData?.meta?.errorCode, errorMessage });
          } else {
            openErrorModal({ statusCode: status, errorCode: deleteResultData?.meta?.errorCode });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        //
      }
    },
    [building, fetchBuilding, postCommonfacility, resetForm],
  );

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    try {
      if (confirmModal.type === "REFRESH_ROOM_SAVE") {
        // 편의시설 저장
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
        await saveRefreshRoom(confirmModal.payload);
      } else if (confirmModal.type === "REFRESH_ROOM_ALL_DELETE") {
        // 편의시설 초기화 저장 (삭제) 확인 모달
        await deleteAllRefreshRoom();
      } else if (confirmModal.type === "REFRESH_ROOM_DELETE") {
        // 편의시설 row 삭제
        if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");
        await deleteRefreshRoom(confirmModal.payload);
      }
    } catch (error) {
      console.error(error);
    }
  }, [confirmModal, saveRefreshRoom, deleteAllRefreshRoom, deleteRefreshRoom]);

  // 편의시설 토글 버튼 변경
  const changeToggle = (value: boolean) => {
    if (value) {
      setValue("isUsedRefreshSpace", true);
    } else {
      setConfirmModal({
        isOpen: true,
        title: "정보가 초기화되어 저장됩니다.",
        type: "REFRESH_ROOM_ALL_DELETE",
      });
    }
  };

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: FormData, e?: any) => {
    e.preventDefault();
    setConfirmModal({
      title: "저장하시겠습니까?",
      isOpen: true,
      type: "REFRESH_ROOM_SAVE",
      payload: data,
    });
    e.target.reset();
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  return (
    <article className="contents-container__wrap-article">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller
          control={control}
          name="isUsedRefreshSpace"
          render={({ field: { value, name } }) => (
            <>
              <div className="contents-container__sub-title pb0">
                <div className="flex-center">
                  <h2 className="minmax80">편의시설</h2>
                  <BaseToggle
                    checked={value}
                    onChange={(value: boolean) => {
                      changeToggle(value);
                    }}
                    name={name}
                  />
                </div>
              </div>

              {/* 토글 활성화 되면 노출 */}
              {getValues("isUsedRefreshSpace") && (
                <>
                  {/* 편의시설 - 폼 */}
                  <table className="inner-table mt20" width="1070">
                    <thead>
                      <tr>
                        <th>
                          <div>
                            <span className="required">위치</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="required">공간 명</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="required">공간유형</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span className="required">Location code</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span>정원</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="150px">
                          <Controller
                            control={control}
                            name="floor"
                            render={({ field: { value, name, onChange } }) => (
                              <BaseSelect stateOptions={rangeFloorOptions} setStateValue={onChange} value={value} name={name} />
                            )}
                          ></Controller>
                        </td>
                        <td width="350px">
                          <Controller
                            control={control}
                            name="facilityName"
                            render={({ field: { value, name, onChange } }) => <BaseInput type="text" value={value} name={name} onChange={onChange} />}
                          ></Controller>
                        </td>
                        <td width="150px">
                          <Controller
                            control={control}
                            name="spaceStyle"
                            render={({ field: { value, name, onChange } }) => (
                              <BaseSelect stateOptions={spaceStyleOptions} setStateValue={onChange} value={value} name={name} />
                            )}
                          ></Controller>
                        </td>
                        <td width="290px">
                          <Controller
                            control={control}
                            name="locationCode"
                            render={({ field: { value, name, onChange } }) => <BaseInput value={value} name={name} onChange={onChange} />}
                          ></Controller>
                        </td>
                        <td width="100px">
                          <Controller
                            control={control}
                            name="facilityMaxPeopleNums"
                            render={({ field: { value, name, onChange } }) => (
                              <BaseSelect
                                stateOptions={Array.from(Array(100).keys()).map((i: number) =>
                                  Object.assign({ label: `${i + 1}`, value: `${i + 1}` }),
                                )}
                                setStateValue={onChange}
                                value={String(value)}
                                name={name}
                              />
                            )}
                          ></Controller>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className={errors && Object.keys(errors).length > 0 ? "inner-table" : "inner-table mb20"} width="1070">
                    <thead>
                      <tr>
                        <th>
                          <div>
                            <span>시설안내</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            <span>이용 안내</span>
                          </div>
                        </th>
                        {/* <th>
                        <div className="minmax100"></div>
                      </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="350px">
                          <Controller
                            control={control}
                            name="equipments"
                            render={({ field: { value, name, onChange } }) => <BaseTextarea value={value} name={name} onChange={onChange} />}
                          ></Controller>
                        </td>
                        <td width="390px">
                          <Controller
                            control={control}
                            name="description"
                            render={({ field: { value, name, onChange } }) => <BaseTextarea value={value} name={name} onChange={onChange} />}
                          ></Controller>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex-center-end">
                    <BaseButton type="submit" title="저장" className="mr2" />
                    <BaseButton title="취소" className="color-white" onClick={() => resetForm()} />
                  </div>
                  {errors && Object.keys(errors).length > 0 && <p className="validation-text mb20">{Object.entries(errors)[0][1]?.message}</p>}
                  {/* 편의시설 - 이미지 */}
                  <ImagesUpdate onChangeMediaFiles={setMediaList} mediaFiles={mediaList} categoryName="refreshRoom" fileMaxLength={10} />
                  {/* 편의시설 - 테이블 */}
                  <div className="inner-tab-table mt20 mb80">
                    <ViewDataTable columns={columns} data={refreshRoomList} />
                  </div>
                </>
              )}
            </>
          )}
        ></Controller>

        {/* 취소, 확인 버튼이 있는 confirm 모달 */}
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            title={confirmModal.title}
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            {/* <p>{confirmModal.message}</p> */}
          </BaseModal>
        )}
      </form>
    </article>
  );
};

export default RefreshRoomSection;
