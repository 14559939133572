import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PagePath } from "src/pages/product/details";
import { globalPartnerState } from "src/recoil/partners/atom";

export function useRedirectMain() {
  const location = useLocation();

  const globalPartner = useRecoilValue(globalPartnerState);

  // const [path, setPath] = useState("");

  // useEffect(() => {
  //   setPath(location.pathname);
  // }, []);

  const setLocalstorageUrl = () => {
    localStorage.setItem("redirectMain", PagePath.main.replace(":partnerId", String(globalPartner?.id)));
  };

  return { setLocalstorageUrl };
}
