import { useCallback, useEffect, useMemo } from "react";
import { Row, useBlockLayout, useGlobalFilter, useRowSelect, useTable } from "react-table";
import { BaseCheckbox } from "./BaseCheckbox";
import { BaseRadio } from "./BaseRadio";

type Props = {
  selectType: "RADIO" | "CHECKBOX"; // 선택 유형
  columns: Array<any>;
  data: Array<any>;
  onChangeSelectedRows: (selectedRows: Array<Row<any>>) => void; // 부모컴포넌트에서 선택변경시 선택한 정보 받을 수 있는 함수
  isSingleSelect?: boolean; // selectType 이 CHECKBOX 인데도, 하나만 선택을 원하는 경우 true
  manualRowSelectedKey?: string; // react table 에서 사용하는 manualRowSelectedKey. 지정안하면 기본 "id"
  pageSize?: number; // page size. 기본 20
  selectInputName?: string; // radio or checkbox input name. 기본 "selected_row"
  selectedRowIds?: Array<number | string>; // 기존에 선택되어있어야 하는 row id
  selectColumnWidth?: number; // 선택 컬럼 witdh. 지정안하면 기본 100
};

/*
  useTable 을 사용해서 소팅, 필터 없이 데이터만 받아서 목록을 그려주는 table.
  맨앞 컬럼은 "선택" 이 가능함 (radio or checkbox)
*/
const SelectViewDataTable = ({
  selectType,
  columns,
  data,
  onChangeSelectedRows,
  isSingleSelect,
  manualRowSelectedKey = "id",
  pageSize = 20,
  selectInputName = "selected_row",
  selectedRowIds,
  selectColumnWidth = 100,
}: Props) => {
  // 기존에 선택되어있어야 하는 row id obj
  const selectedRowIdsObj = useMemo(() => {
    let obj = {};
    if (selectedRowIds && selectedRowIds.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (selectedRowIds.includes(data[i].id)) {
          Object.assign(obj, { [i]: true });
        }
      }
    }
    return obj;
  }, [data, selectedRowIds]);

  // 부모 컴포넌트에 전달
  const changeSelectedRows = useCallback(
    (selectedFlatRows: Row<any>[]) => {
      onChangeSelectedRows(selectedFlatRows);
    },
    [onChangeSelectedRows],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize,
        selectedRowIds: selectedRowIdsObj,
      },
      manualRowSelectedKey,
    },
    useBlockLayout,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "select",
          Header: "선택",
          Cell: ({ row, toggleRowSelected, toggleAllRowsSelected }) => {
            return (
              <div>
                {selectType === "RADIO" ? (
                  <BaseRadio
                    id={row.id}
                    name={selectInputName}
                    checked={row.isSelected}
                    onChange={(checked: boolean) => {
                      // 다른 행은 선택해제
                      toggleAllRowsSelected(false);
                      // 해당 radio 선택
                      toggleRowSelected(row.id, checked);
                    }}
                  />
                ) : (
                  <BaseCheckbox
                    id={row.id}
                    name={selectInputName}
                    checked={row.isSelected}
                    onChange={(checked: boolean) => {
                      if (isSingleSelect) {
                        // 다른 행은 선택해제
                        toggleAllRowsSelected(false);
                      }
                      toggleRowSelected(row.id, checked);
                    }}
                  />
                )}
              </div>
            );
          },
          width: selectColumnWidth,
        },
        ...columns.map((col) => col),
      ]);
    },
  );

  useEffect(() => {
    // 선택하는 행이 달라지면 부모 컴포넌트에 전달
    changeSelectedRows(selectedFlatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]); // dependency 변경 금지

  return (
    <section className="contents-container__table">
      {/* 테이블 헤더 */}
      <div {...getTableProps()} className="base-table sticky">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th">
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {/* 테이블 바디 */}
        <div {...getTableBodyProps()} className="body">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                // isGray: 회색 바탕 여부
                className={`base-table__tr ${row.original?.isGray ? "bg-gray100" : ""}`}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <div {...cell.getCellProps()} className="base-table__td">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {rows.length === 0 && (
            <div className="base-table__tr table-cursor-unset">
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SelectViewDataTable;
