import React from "react";
import { BaseTooltip } from "src/components";
import { calculatePyeong, numberToStringWithComma } from "src/utils";

export const FloorHoColumn = [
  {
    Header: "층수",
    accessor: "floorNum",
    width: 80,

    Cell: ({ value }: any) => {
      if (value < 0) {
        const newValue = String(value).replace("-", "");
        return <div>지하 {newValue}</div>;
      } else {
        return <div>지상 {value}</div>;
      }
    },
  },
  {
    Header: "호실",
    accessor: "roomNum",
    width: 90,
    Cell: ({ value }: any) => {
      if (value < 0) {
        const newValue = String(value).replace("-", "");
        return <div>{newValue}</div>;
      } else {
        return <div>{value}</div>;
      }
    },
  },
  {
    Header: "전용면적(m2)",
    accessor: "leasableAreaNet",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
      return <BaseTooltip contents={`${numberToStringWithComma(sum)}`} isSingleLine={true} />;
    },
  },
  {
    Header: "전용면적(평)",
    accessor: "",
    width: 120,
    Cell: ({ row }: any) => {
      const value = row.original.leasableAreaNet;
      return (
        <>
          <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
      return <BaseTooltip contents={`${calculatePyeong(sum)}`} isSingleLine={true} className="font-weight-bold" />;
    },
  },
  {
    Header: "임대면적(m2)",
    accessor: "leasableArea",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        // TODO :: 세번째 숫자 , 찍기
        <>
          <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;

      rows.map((item: any) => (sum += Number(item.original.leasableArea)));
      return (
        // 임대면적 SUM leasableArea

        <BaseTooltip contents={`${numberToStringWithComma(sum)}(m2)`} isSingleLine={true} className="font-weight-bold" />
      );
    },
  },
  {
    Header: "임대면적(평)",
    accessor: "",
    width: 120,
    Cell: ({ row }: any) => {
      const value = row.original.leasableArea;
      return (
        // TODO :: 세번째 숫자 , 찍기
        <>
          <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;

      rows.map((item: any) => (sum += Number(item.original.leasableArea)));
      return (
        // 임대면적 SUM leasableArea

        <BaseTooltip contents={`${calculatePyeong(sum)}`} isSingleLine={true} className="font-weight-bold" />
      );
    },
  },
];
