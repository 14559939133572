import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AdminMemoAddModel, ServiceTypes, UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getMediaFileListAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, Modal } from "src/api/public-types";
import { BaseButton } from "../BaseButton";
import { BaseModal } from "../BaseModal";
import BaseFileUpload from "../mediaFile/BaseFileUpload";
import useAdminMemo from "./useAdminMemo";

type Props = {
  serviceId: number; // 관리자메모가 있는 메뉴의 ID
  serviceType: UnionServiceType; // 관리자메모가 있는 메뉴의 타입
  memoId: string; // 관리자메모 id
  viewOnly?: boolean; // 읽기전용
  onClose: () => void; // 모달 닫기
  openAdminMemoForm: (serviceId: string, serviceType: UnionServiceType, memoId: string) => void; // 관리자메모 등록 팝업 오픈
  callList: () => Promise<void>; // 관리자메모 목록 호출
};

const AdminMemoDetail = ({ serviceId, serviceType, viewOnly, callList, memoId, onClose, openAdminMemoForm }: Props) => {
  // 관리자 메모 상세
  const [memoDetail, setMemoDetail] = useState<AdminMemoAddModel>();

  const [medias, setMedias] = useState<MediaFile[]>([]);

  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false, payload: 0 });

  const { user } = useAuth0();

  const isDisabled = useMemo(() => memoDetail?.createdBy === user?.email, [memoDetail?.createdBy, user?.email]);

  const { getList, remove } = useAdminMemo();

  // 미디어 목록
  const { executeAsync: getMediaFileList } = useApiOperation(getMediaFileListAsync);

  /** 첨부파일 목록 api*/
  const fetchMediaList = useCallback(
    async (data: Array<{ serviceId: number; serviceType: UnionServiceType }>) => {
      const response = await getMediaFileList({ contentsList: data });

      if (response.status >= 200 && response.status <= 299) {
        setMedias(response.data.data.content);
      }
    },
    [getMediaFileList],
  );

  useEffect(() => {
    const findMemo = async () => {
      const memoList = await getList(Number(serviceId), serviceType);
      const find: AdminMemoAddModel = memoList.data.data.content.find((memo: AdminMemoAddModel) => memo.id === memoId);
      setMemoDetail(find);
    };
    if (serviceId && memoId) {
      findMemo();
      fetchMediaList([{ serviceId: Number(memoId), serviceType: ServiceTypes.SERVICE_ADMINMEMO }]);
    }
  }, [fetchMediaList, serviceId, memoId]);

  // 관리자 메모 삭제
  const onDeleteAdminMemoApi = async (memoId: number) => {
    if (memoId !== 0) {
      const response = await remove([memoId]);
      if (response.status >= 200 && response.status <= 299) {
        await callList();
        setDeleteModal({ isOpen: false, payload: 0 });
      }
    }
  };

  return (
    <>
      <section className="base-abstract-modal__title flex-center-between ">
        <h2 className="font20">관리자 메모</h2>
      </section>
      <section className="base-abstract-modal__contents">
        {memoDetail && (
          <article className="px30">
            <section className="contents-container__grid mb20">
              <div className="contents-container__grid-index">
                <p>내용</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="pre-formatted pt10">
                    <p>{memoDetail?.memo}</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>{`첨부파일 (${medias.length})`}</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  {medias.length > 0 ? <BaseFileUpload isOnlyView fileList={medias} /> : <span>-</span>}
                </div>
              </div>
            </section>
          </article>
        )}
      </section>
      <div className="h-100 flex-center-end pa30">
        {!isDisabled && !viewOnly && <span className="font13 mr20 text-primary4">작성자만 수정할 수 있습니다. </span>}
        <BaseButton title={viewOnly ? "닫기" : "취소"} className="color-white  mr10" onClick={onClose} />
        {!viewOnly && (
          <BaseButton
            title="수정"
            disabled={!isDisabled}
            onClick={() => {
              onClose();
              openAdminMemoForm(String(serviceId), serviceType, memoId || "");
            }}
          />
        )}
      </div>
      <BaseModal
        isOpen={deleteModal.isOpen}
        title={"정말로 삭제하시겠습니까?"}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={() => {
          onDeleteAdminMemoApi(Number(deleteModal.payload));
        }}
        onClose={() => setDeleteModal({ isOpen: false })}
      ></BaseModal>
    </>
  );
};

export default AdminMemoDetail;
