import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getScheduleDetail_RCB01, mntBillUpdate, mntGetWeekDay } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { BaseButton, BaseModal, BaseSelect } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useToast } from "src/recoil/toast/hook";
import BillBasicInfo from "./BillBasicInfo";
import BillDetailListInfo, { BillDetail } from "./BillDetailListInfo";
import { objDiff } from "src/pages/contract/hooks/useContractApi";
import moment from "moment";
import { ViewYmdFormat } from "src/utils";

const MakeBillPopup = ({
  isChange,
  contractDetail,
  showBtn,
  bill,
  setBill,
  onClose,
  onClick,
  onConfirm,
  messageModal,
  setMessageModal,
  getRounds,
  allBill,
  buildingId,
  scheduleId,
  deletePop,
}: any) => {
  const { openToast } = useToast();
  const { executeAsync: getScheduleList } = useApiOperation(getScheduleDetail_RCB01);
  const { executeAsync: getWeekDay } = useApiOperation(mntGetWeekDay);
  const { executeAsync: updateBill } = useApiOperation(mntBillUpdate);
  const [delFlag, setDelFlag] = useState(false);
  //  프로바이더 목록 api
  const { executeAsync: getProviders } = useApiOperation(getProviderList);
  const [selectedBuildingId, setSelectedBuildingId] = useState<any>(null);
  const [selectedScheduleId, setSelectedScheduleId] = useState<any>(null);
  const [firstSelector, setFirstSelector] = useState<any>([]);
  const [secondSelector, setSecondSelector] = useState<any>([]);
  const [detailList, setDetailList] = useState<Array<BillDetail>>([]);
  const [taxModal, setTaxModal] = useState(false);

  const [confirmModal, setConfirmModal] = useState<any>({
    isOpen: false,
    btnTitle: ["취소", "확인"],
    title: "",
    message: "",
    successMessage: "",
    index: 0,
  });

  const supplyTotal = useMemo(() => {
    let sum = 0;
    if (detailList!.length > 0) {
      detailList.forEach((detail: any) => {
        if (!detail.isDeleted) {
          sum = sum + detail.supplyAmount;
        }
      });
    }
    return sum;
  }, [detailList]);
  const taxTotal = useMemo(() => {
    let sum = 0;
    if (detailList!.length > 0) {
      detailList.forEach((detail: any) => {
        if (!detail.isDeleted) {
          sum = sum + detail.taxAmount;
        }
      });
    }
    return sum;
  }, [detailList]);
  const priceTotal = useMemo(() => {
    const totalAmount = supplyTotal + taxTotal;

    return totalAmount;
  }, [supplyTotal, taxTotal]);

  /**
   * 선택한 건물의 스케줄에 등록 된 프로바이더 목록 조회
   * ,로 구분된 스트링 값
   */
  const getProvidersName = useCallback(
    async (providerIds: string) => {
      const getPds = await getProviders({ providerId: String(providerIds) });
      // console.log("getProviders", getPds);
      return getPds.data.data.content;
    },
    [getProviders],
  );

  /**
   * 선택한 건물의 관리비 스케줄 목록 조회
   */
  const findMntScheduleIds = useCallback(
    async (buildingId: string, scheduleId?: string) => {
      if (contractDetail) {
        const params = {
          contractId: Number(contractDetail!.contract!.contractId),
          buildingId: Number(buildingId),
          supplyType: "MNT",
        };
        const list = await getScheduleList(params);
        // setTableData(list.data.data.scheduleList.content);
        // console.log("list", list.data.data.scheduleList);
        if (list.data.data.scheduleList && list.data.data.scheduleList.length > 0) {
          let pvIds = list.data.data.scheduleList.map((schedule: any) => schedule.providerId).join(",");

          //받아온 목록에서 프로바이더 명 매핑처리
          const providers = await getProvidersName(pvIds);
          if (providers.length > 0) {
            let mntScheduleList = list.data.data.scheduleList;
            const second: any = [];
            mntScheduleList.forEach((msl: any) => {
              const pd = providers.find((pv: any) => msl.providerId === pv.providerId);
              const opt = {
                label: pd?.providerName + "(" + pd?.providerId + ")",
                value: msl.scheduleId,
              };
              second.push(opt);
            });
            const temp = _.cloneDeep(bill);
            setSecondSelector(second);
            if (scheduleId) {
              setSelectedScheduleId(String(scheduleId));
              temp.scheduleId = Number(scheduleId);
            } else {
              setSelectedScheduleId(second[0]!.value);
              temp.scheduleId = Number(second[0]!.value);
            }

            setBill(temp);
          }
        }
      }
    },
    [contractDetail, getScheduleList, getProvidersName, bill, setBill],
  );
  const handleSendMail = useCallback(async () => {
    console.log("del Bill", bill);
    const delRtn = await updateBill({ scheduleId: scheduleId, body: { billId: bill.billId, isDeleted: true } });
    if (delRtn.status >= 200 && delRtn.status < 400) {
      setDelFlag(false);
      setMessageModal({ ...messageModal, isOpen: true, message: "삭제 되었습니다." });
      await getRounds(bill.scheduleId);
    }
  }, [updateBill, scheduleId, bill, setMessageModal, messageModal, getRounds]);

  useEffect(() => {
    setDetailList([]);
    if (contractDetail.product) {
      // console.log("bill", bill);
      const buildingSelectData: any = [];

      //상품 ID로 상품안의 건물 데이터
      const snapshotProduct = contractDetail.product;

      snapshotProduct!.buildings!.map((building: any) => {
        // console.log("building", building);
        const option = {
          label: building.initValue + "(" + building.domainId + ")",
          value: building.domainId,
          providerId: 0,
        };
        buildingSelectData.push(option);
      });
      // console.log("buildingSelectData[0]!.value", buildingSelectData[0]!.value);
      setFirstSelector(buildingSelectData);
      findMntScheduleIds(bill.buildingId, bill.scheduleId);
      if (bill && bill.billId > 0) {
        //수정 모드이기 때문에 데이터 세팅 해준다.
        console.log("ID가 있다.");

        if (bill.billDetailList && bill.billDetailList.length > 0) {
          bill.billDetailList.forEach((detail: any) => {
            detail.isAutoTaxUse = false;

            if (detail.taxAmount > 0) {
              detail.checkTaxAmount = "tax";
            } else {
              detail.checkTaxAmount = "nontax";
            }
          });
        }

        setSelectedBuildingId(String(bill.buildingId));
        setSelectedScheduleId(String(bill.scheduleId));

        setDetailList(bill.billDetailList);
      } else {
        console.log("신규 생성이다.");

        const temp = _.cloneDeep(bill);
        if (buildingId) {
          temp.buildingId = buildingId;
        } else {
          temp.buildingId = Number(buildingSelectData[0]!.value!);
        }
        setSelectedBuildingId(temp.buildingId);

        if (scheduleId) {
          findMntScheduleIds(temp.buildingId, scheduleId);
        } else {
          findMntScheduleIds(temp.buildingId);
        }

        setBill(temp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDetail]);
  /**
   * 저장 시 필수는 산출 기간, 납부기한
   */
  const basicValidation = (bill: any) => {
    if (bill.billTitle === "") {
      openToast({ content: "청구서명은 필수입니다.", isSystemToast: true });
      return true;
    }
    if (bill.billStartTime === "") {
      openToast({ content: "산출기간 시작일은 필수입니다.", isSystemToast: true });
      return true;
    }
    if (bill.billEndTime === "") {
      openToast({ content: "산출기간 종료일은 필수입니다.", isSystemToast: true });
      return true;
    }
    if (bill.billIssueDate === "") {
      openToast({ content: "납부기간 시작일은 필수입니다.", isSystemToast: true });
      return true;
    }
    if (bill.billDueDate === "") {
      openToast({ content: "납부기간 종료일은 필수입니다.", isSystemToast: true });
      return true;
    }
    console.log("bill.taxInvoiceIssueDate", bill.taxInvoiceIssueDate);
    if (bill.pgcode !== "creditcard" && (bill.taxInvoiceIssueDate === null || bill.taxInvoiceIssueDate === "")) {
      openToast({ content: "계산서 작성일은 필수입니다.", isSystemToast: true });
      return true;
    }

    return false;
  };
  /**
   * 확정 시 벨리데이션 체크
   * return true or false
   */
  const validationBillData = useCallback(
    (bill: any) => {
      // console.log("bill", bill);
      let sum = 0;
      if (bill) {
        if (detailList && detailList.length === 0) {
          openToast({ content: "세부내역은 1건이상 등록되어야 합니다.", isSystemToast: true });
          return true;
        } else {
          try {
            detailList.forEach((detail: any) => {
              if (!detail.isDeleted) {
                sum = sum + detail.totalAmount;
              }
              if (!detail.isDeleted && (detail.itemName === "" || detail.itemName === null)) {
                openToast({ content: "항목명은 필수 입니다.", isSystemToast: true });
                throw Error;
              }
              if (!detail.isDeleted && Number(detail.supplyAmount + detail.taxAmount) !== Number(detail.totalAmount)) {
                openToast({ content: detail.itemName + "의 공급가와 부가세의 합이 소계와 맞지 않습니다.", isSystemToast: true });
                throw Error;
              }

              if (!detail.isDeleted && detail.checkTaxAmount === "tax") {
                if (Number(detail.taxAmount) === 0) {
                  openToast({ content: "부가세는 공급가의 10%로 입력해 주세요.", isSystemToast: true });
                  throw Error;
                }
                console.log(detail, Number(detail.supplyAmount * 0.1) - 1, Number(detail.taxAmount), Number(detail.supplyAmount * 0.1) + 1);
                if (
                  Number(detail.supplyAmount * 0.1) - 1 > Number(detail.taxAmount) ||
                  Number(detail.taxAmount) > Number(detail.supplyAmount * 0.1) + 1
                ) {
                  openToast({ content: "부가세는 공급가의 10%로 입력해 주세요.", isSystemToast: true });
                  throw Error;
                }
              }
            });
          } catch (error) {
            return true;
          }
        }
        if (sum === 0) {
          openToast({ content: "소계의 총합이 0원일 수 없습니다.", isSystemToast: true });
          return true;
        }
      }
      return false;
    },
    [openToast, detailList],
  );

  const changeConfirm = () => {
    const temp = _.cloneDeep(bill);
    temp.isConfirmed = !temp.isConfirmed;
    setBill(temp);
  };
  const confirmPopup = () => {
    const temp = _.cloneDeep(confirmModal);
    temp.isOpen = true;
    temp.title = bill.isConfirmed ? "확정 해제하시겠습니까?" : "확정하시겠습니까?";
    temp.btnTitle = ["취소", "확인"];
    let confirmTrue = "작성한 내용으로 청구서를 확정 해제하시겠습니까?";
    let confirmFalse = "작성한 내용으로 청구서를 확정 승인하시겠습니까?";
    temp.message = bill.isConfirmed ? confirmTrue : confirmFalse;
    temp.index = 0;
    temp.successMessage = bill.isConfirmed ? "해제되었습니다." : "확정되었습니다.";
    setConfirmModal(temp);
  };

  const closePopup = () => {
    const temp = _.cloneDeep(confirmModal);
    temp.isOpen = true;
    temp.title = "취소 하시겠습니까?";
    temp.btnTitle = ["아니요", "예"];
    temp.message = "작성한 내용을 저장하지 않고 취소하시겠습니까?";
    temp.index = 1;
    temp.successMessage = "취소 되었습니다.";
    setConfirmModal(temp);
  };

  const savePopup = () => {
    const temp = _.cloneDeep(confirmModal);
    temp.isOpen = true;
    temp.title = "저장하시겠습니까?";
    temp.btnTitle = ["취소", "확인"];
    temp.message = "작성한 내용으로 청구서를 저장하시겠습니까?";
    temp.index = 2;
    temp.successMessage = "저장되었습니다.";
    setConfirmModal(temp);
  };

  return (
    <>
      <BaseAbstractModal size="xlarge" isOpen={isChange} className="billing-schedule-info-modal">
        <article className="base-abstract-modal__contents">
          <section className="base-abstract-modal__title border-bottom">
            <h1>청구서 {showBtn ? (bill?.billId > 0 ? "수정" : "생성") : "상세"}</h1>
          </section>

          {/* 건물 및 스케줄 선택 */}
          {contractDetail?.product?.productType === "TIME_COURT" && (
            <div className="flex-center mt10 px30">
              <BaseSelect
                value={selectedBuildingId || null}
                setStateValue={(buildingId: string) => {
                  setSelectedBuildingId(buildingId);
                  findMntScheduleIds(buildingId);
                  const temp = _.cloneDeep(bill);
                  temp.buildingId = Number(buildingId);
                  setBill(temp);
                }}
                stateOptions={firstSelector}
                className="mr8 minmax260"
                isDisabled={true}
              />
              <BaseSelect
                value={selectedScheduleId || null}
                setStateValue={(scheduleId: string) => {
                  console.log("scheduleId", scheduleId);
                  setSelectedScheduleId(String(scheduleId));
                  const temp = _.cloneDeep(bill);
                  temp.scheduleId = Number(scheduleId);
                  setBill(temp);
                }}
                stateOptions={secondSelector}
                className="minmax260"
                isDisabled={true}
              />
            </div>
          )}
          <div className="flex-center mt10 px30">
            <p className="pr30 font14 text-gray400">* 청구서 상세 항목 작성 및 확정은 납부시작일 전일까지 완료해 주세요.</p>
          </div>
          {/* 기본정보 */}
          <BillBasicInfo bill={bill} setBill={setBill} showBtn={showBtn} priceTotal={priceTotal} />
          {/* 세부내역 */}
          <BillDetailListInfo
            bill={bill}
            setBill={setBill}
            showBtn={showBtn}
            detailList={detailList}
            setDetailList={setDetailList}
            supplyTotal={supplyTotal}
            taxTotal={taxTotal}
            priceTotal={priceTotal}
          />
          <div className="base-abstract-modal__btn-wrap flex-center-between">
            <div className="flex-row flex-center start">
              {showBtn && Number(bill?.billId) > 0 && (
                <>
                  <button
                    className={`base-btn ${bill?.isConfirmed ? "color-white" : "color-blue"}`}
                    onClick={() => {
                      bill?.billId ? confirmPopup() : changeConfirm();
                    }}
                    disabled={
                      bill.billPayStatus !== "TEMP_SAVE" ||
                      allBill?.billEtc?.receiptCount > 0 ||
                      allBill?.billEtc?.taxInvoiceCount > 0 ||
                      allBill?.billEtc?.chargeEmailSentAt !== ""
                    }
                  >
                    {bill?.isConfirmed ? "확정해제" : "확정"}
                  </button>
                  {Number(bill?.billId) > 0 && bill?.isConfirmed === false && (
                    <BaseButton
                      title="삭제"
                      className="ml8 color-white"
                      disabled={bill.isConfirmed || bill.billPayStatus !== "TEMP_SAVE"}
                      onClick={() => setDelFlag(true)}
                    />
                  )}
                </>
              )}
              {delFlag && (
                <BaseModal
                  isOpen={delFlag}
                  btnLeftTitle={"취소"}
                  btnRightTitle={"삭제"}
                  onClose={() => {
                    setDelFlag(false);
                  }}
                  onClick={handleSendMail}
                  title={"삭제하시겠습니까?"}
                >
                  <p>{"청구서를 삭제하시겠습니까?"}</p>
                </BaseModal>
              )}
            </div>
            {showBtn && !bill?.isConfirmed ? (
              <div className="flex-row flex-center start">
                <button className="base-btn color-white mr8" onClick={() => closePopup()}>
                  취소
                </button>
                <button className="base-btn" onClick={() => savePopup()}>
                  저장
                </button>
              </div>
            ) : (
              <div className="flex-row flex-center start">
                <button className="base-btn mr8" onClick={() => onClose()}>
                  확인
                </button>
              </div>
            )}
          </div>
        </article>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle={confirmModal?.btnTitle[0]!}
            btnRightTitle={confirmModal?.btnTitle[1]!}
            onClose={() => {
              setConfirmModal({ ...confirmModal, isOpen: false });
            }}
            onClick={async () => {
              setConfirmModal({ ...confirmModal, isOpen: false });
              if (confirmModal.index === 0) {
                const temp = _.cloneDeep(bill);

                if (bill.isConfirmed === false) {
                  let rtnChecker = validationBillData(temp);

                  if (rtnChecker) {
                    return;
                  } else {
                    const today = moment();
                    const response = await getWeekDay({ checkDate: moment(today).format("YYYYMMDD") });

                    const useableDate = moment(response.data.data.weekday).format(ViewYmdFormat.FULL);
                    //휴무일시 적용 해야함 오늘 날짜 기준으로 휴무일시체크

                    const ym = moment(temp.taxInvoiceIssueDate).format("YYYY.MM");

                    const addMd = moment(ym).add(1, "months").add(10, "days");

                    const check = moment(addMd).format(ViewYmdFormat.FULL);

                    if (moment(useableDate).isSameOrAfter(moment(check))) {
                      setTaxModal(true);
                    } else {
                      const saved = await onConfirm(detailList);
                      if (saved && saved.status >= 200 && saved.status < 400) {
                        setMessageModal({ ...messageModal, message: confirmModal.successMessage, isOpen: true });
                        await onClose();
                      }
                    }
                  }
                } else {
                  const saved = await onConfirm();
                  if (saved.status >= 200 && saved.status < 400) {
                    setMessageModal({ ...messageModal, message: confirmModal.successMessage, isOpen: true });
                    await onClose();
                  }
                }
              }
              if (confirmModal.index === 1) {
                await onClose();

                setMessageModal({ ...messageModal, message: confirmModal.successMessage, isOpen: true });
              }
              if (confirmModal.index === 2) {
                const temp = _.cloneDeep(bill);
                let rtnChecker = basicValidation(temp);

                if (rtnChecker) {
                  return;
                } else {
                  const saved = await onClick(detailList);
                  if (saved.status >= 200 && saved.status < 400) {
                    setMessageModal({ ...messageModal, message: confirmModal.successMessage, isOpen: true });
                    await onClose();
                  }
                }
              }
            }}
            title={confirmModal.title}
          >
            <p>{confirmModal.message}</p>
          </BaseModal>
        )}
        {taxModal && (
          <BaseModal
            isOpen={taxModal}
            btnRightTitle={"확인"}
            onClick={async () => {
              setTaxModal(false);
              const saved = await onConfirm(detailList);
              if (saved && saved.status >= 200 && saved.status < 400) {
                setMessageModal({ ...messageModal, message: confirmModal.successMessage, isOpen: true });
                await onClose();
              }
            }}
            title={"가산세 부과 안내"}
          >
            <p>선택한 작성일자로 세금계산서 또는 계산서를 발행하면 가산세가 부과될 수 있으니 참고해 주세요</p>
          </BaseModal>
        )}
      </BaseAbstractModal>
    </>
  );
};
export default MakeBillPopup;
