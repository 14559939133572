import { useEffect } from "react";
import { Control, Controller, useFieldArray, UseFormRegister, UseFormReturn } from "react-hook-form";
import { UserGuideModel } from "src/api/guide/guide-types";
import { BaseInput } from "src/components";

type Props = {
  control: Control<UserGuideModel>;

  register: UseFormRegister<UserGuideModel>;
  errors?: UseFormReturn<UserGuideModel>["formState"]["errors"];
};

const GuideWifiList = ({ control, errors, register }: Props) => {
  // 네트워크 목록

  const {
    fields: wifiList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "wifiList",
  });

  // 고객용 / 방문자용 네트워크 - 아이디 비밀번호 유효성 검사 적용
  useEffect(() => {
    if (wifiList.length > 0) {
      wifiList.forEach((network, networkIdx: number) => {
        register(`wifiList.${networkIdx}.wifiName`, {
          required: true,
        });

        register(`wifiList.${networkIdx}.wifiPassword`, {
          required: true,
        });
      });
    }
  }, [wifiList]);

  return (
    <div className="flex-start mb10">
      <div className="minmax140">
        <p className="required">
          네트워크 이름/
          <br />
          비밀번호
        </p>
      </div>
      <table className="inner-table" width="100%">
        <thead>
          <tr>
            <th>
              <span>No</span>
            </th>
            <th>
              <span>네트워크 이름</span>
            </th>
            <th>
              <span>비밀번호</span>
            </th>
            <th>
              <span></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {wifiList.map((network, index) => (
            <tr key={network?.id}>
              <td width="100">
                <div className="text-center">
                  <span>{index + 1}</span>
                </div>
              </td>
              <td width="220">
                <div className="flex-center-center">
                  <Controller
                    control={control}
                    name={`wifiList.${index}.wifiName`}
                    rules={{
                      required: {
                        value: true,
                        message: "필수입력 항목입니다",
                      },
                    }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                      return <BaseInput value={value} name={name} onChange={onChange} placeholder="네트워크 이름을 입력하세요." />;
                    }}
                  ></Controller>
                </div>
              </td>
              <td width="auto">
                <div>
                  <span>
                    <Controller
                      control={control}
                      name={`wifiList.${index}.wifiPassword`}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return <BaseInput value={value} name={name} onChange={onChange} placeholder="비밀번호를 입력하세요." />;
                      }}
                    ></Controller>
                  </span>
                </div>
              </td>

              <td width="60">
                <div className="flex-center-center">
                  {index === 0 ? (
                    <button
                      type="button"
                      className="base-add-btn"
                      onClick={() => {
                        append({
                          wifiName: "",
                          wifiPassword: "",
                        });
                      }}
                    ></button>
                  ) : (
                    <button
                      type="button"
                      className="base-erase-btn"
                      onClick={() => {
                        remove(index);
                      }}
                    ></button>
                  )}
                </div>
              </td>
            </tr>
          ))}
          {errors?.wifiList && (
            <tr>
              <td className="validation-text font12">필수입력 항목입니다</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default GuideWifiList;
