// import { ErrorCodeMessage, errorStatus, HttpStatusError } from "../public-types";
import { ErrorCodeMessage, errorStatus, HttpStatusError } from "../public-types";

const findErrorMessage = (errorCode: string, errors: ErrorCodeMessage[]) => {
  let message = "알수 없는 형식의 오류 :" + errorCode;

  if (errorCode !== "") {
    const finded = errors.find((error: ErrorCodeMessage) => error.errorCode === errorCode);
    if (finded) {
      message = finded.message;
    }
  }
  return message;
};

const findStatusErrorMessage = (status: number) => {
  let message = "정의되지 않은 상태의 오류 :" + status;

  const finded = errorStatus.find((st: HttpStatusError) => Number(st.statusCode) === status);
  if (finded) {
    message = finded.message;
  }

  return message;
};

//메세지만 상태값만 있고 코드가 없으면 상태로 표시, 코드 있으면 코드값으로 표시
const errorMessageHandler = (status: number, errorCode: string, errors: ErrorCodeMessage[]) => {
  let message = findStatusErrorMessage(status);

  if (errors.find((ee: ErrorCodeMessage) => errorCode === ee.errorCode)) {
    message = findErrorMessage(errorCode, errors);
  }

  return message;
};
//메세지, 코드, 에러데이터(서버에서 내려온) 리턴
const errorDataHandler = (response: any, errors: ErrorCodeMessage[]) => {
  let message = findStatusErrorMessage(response.status);

  if (errors.find((ee: ErrorCodeMessage) => response.data?.meta?.errorCode === ee.errorCode)) {
    message = findErrorMessage(response.data?.meta?.errorCode, errors);
  }
  let code = response.data?.meta?.errorCode ? response.data?.meta?.errorCode : response.status;

  return { message, code, errorData: response?.data?.meta?.errorData };
};

export { findErrorMessage, findStatusErrorMessage, errorMessageHandler, errorDataHandler };
