import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { MgmtOfficerType } from "src/api/building/building-types";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList, ContractStep, SearchParams } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { HookFormProps, Modal, PageMeta } from "src/api/public-types";
import { WorkOrderFormType, WorkOrderPreivew } from "src/api/work-order/workorder-types";
import { BaseButton, BaseCheckbox, BaseDatePicker, BaseRadio, BaseSelect } from "src/components";
import Link from "src/components/PartnerLink";
import RangeDatepicker from "src/components/RangeDatepicker";
import { PagePath } from "src/pages/product/details";
import { DayOfWeekType, UnionDayOfWeekType, UnionRecurrenceType, cycleOptions, dayOptions } from "src/pages/workOrder/workOrder-types";
import { useModal } from "src/recoil/modalState/hook";
import { YmdFormat } from "src/utils";
import TableModal from "../../components/TableModal";
import WorkOrderGroupListSection from "../../detail/components/workOrderGroup/WorkOrderGroupListSection";
import AccessAreaColumns from "../columns/AccessAreaColumns";
import WorkOrderPreviews from "./WorkOrderPreviews";
import { useLocation } from "react-router-dom";
import qs from "qs";

// 출입구역 추가 할 수 있는 계약 상태 - [이용중, 해지접수]
const INVITEABLE_CONTRACT_STEP = [ContractStep.USE_PROGRESS, ContractStep.TERMINATE_RECEIVED];

const searchOptionList = [
  { value: "ALL", label: "전체" },
  { value: "CONTRACT_ID", label: "ID" },
  { value: "CONTRACT_APPLY_NUMBER", label: "신청번호" },
  { value: "APPLICANT_CORPORATION", label: "법인명/상호" },
  { value: "SPACE_PRODUCT_NAME", label: "상품명" },
  { value: "APPLICANT_NAME", label: "계약자명" },
];

type Options = Array<{ value: string; label: string; checked: boolean }>;

type RecurrenceOptions = Array<{ value: "DAILY" | "MONTHLY" | "WEEKLY"; label: string; checked: boolean }>;

type Props = {
  formProps: HookFormProps<WorkOrderFormType>;
  workOrderId?: string;
  mgmtOfficerList: MgmtOfficerType[];
  scheduleOptions: Options;
  setScheduleOptions: Dispatch<SetStateAction<Options>>;
  getWorkOrderPreview: (data: WorkOrderPreivew) => void;
  previews: WorkOrderPreivew[];
  setPreviews: Dispatch<SetStateAction<WorkOrderPreivew[]>>;
  setAllCancel: Dispatch<React.SetStateAction<boolean>>;
  allCancel: boolean;
  isPast: boolean;
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  setGroupPayload: Dispatch<React.SetStateAction<{ isHoliday: boolean; recurrenceType: UnionRecurrenceType; recurrenceDayOfWeekType: string }>>;
};

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  searchType: "ALL",
  searchValue: "",
  sort: {
    orders: [
      {
        property: "contractManageId",
        direction: "DESC",
      },
    ],
  },
};

const dateOptions = (days: number) => {
  return Array.from({ length: days }, (_, index) => ({ value: Number(index + 1), label: `${String(index + 1)}일` }));
};

// 반복 기간
const DEFAULT_RECURRENCE_PERIOD = {
  days: [],
  deadline: 1,
};

const WorkOrderScheduleSection = ({
  formProps, //
  previews,
  setScheduleOptions,
  mgmtOfficerList,
  scheduleOptions,
  getWorkOrderPreview,
  setPreviews,
  setAllCancel,
  allCancel,
  isPast,
  setDateRange,
  setGroupPayload,
}: Props) => {
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { control, setValue, watch, errors, reset, getValues, resetField } = formProps;

  const [tableModal, setTableModal] = useState<Modal>({ isOpen: false });

  const [isHoliday, setIsHoliday] = useState(false);

  // 반복 주기
  const [recurrenceOptions, setRecurrenceOptions] = useState(cycleOptions);

  // 출입구역 목록 검색 params
  const [contractParams, setContractParams] = useState(DEFAULT_PARAMS);

  // 출입구역 목록 [신청계약 목록]
  const [contracts, setContracts] = useState<Array<ContractManageList>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  //  정기업무 > 반복 기간 values
  const [recurrencePeriod, setRecurrencePeriod] = useState<{ days: UnionDayOfWeekType[]; deadline: number | null; monthlyStart?: number | null }>(
    DEFAULT_RECURRENCE_PERIOD,
  );
  // 정기/비정기 업무 boolean 판단
  const isRegular = useMemo(() => scheduleOptions[1].checked, [scheduleOptions]);

  // 반복 스케줄 value 리턴 -  DAILY | WEEKLY | MONTHLY
  const reccurenceValue = useMemo(() => recurrenceOptions.find((option) => option.checked)?.value, [recurrenceOptions]);

  // 신청계약 api
  const { executeAsync: getContractList } = useApiOperation(getContractApply);

  const { setBaseModal } = useModal();

  // 출입그룹 목록 api[신청계약]
  const fetchContractApi = async (passParams: SearchParams) => {
    const { data, status } = await getContractList(passParams);
    if (status >= 200 && status <= 299) {
      const contracts = data?.data?.content!;
      const pageMeta = data?.meta?.pageMeta!;
      setContracts(contracts);
      setPageMeta(pageMeta);
    }
  };
  useEffect(() => {
    if (tableModal.type === "ACCESS") {
      fetchContractApi(contractParams);
    }
  }, [
    tableModal.type,
    contractParams,
    // contractParams.page, contractParams.size, contractParams.searchValue
  ]);

  // 스케줄 미리보기 클릭시
  const scheduleValidation = () => {
    const showModal = (message: string) => {
      setBaseModal({
        isOpen: true, //
        title: message,
        btnRightTitle: "확인",
        onClose: () => setBaseModal({ isOpen: false }),
      });
    };

    const startDate = watch("workOrderSheetAccessZoneList.0.startDate");
    const endDate = watch("workOrderSheetAccessZoneList.0.endDate");
    const startTime = watch("workOrderSheetAccessZoneList.0.startTime");
    const endTime = watch("workOrderSheetAccessZoneList.0.endTime");
    const contractId = watch("workOrderSheetAccessZoneList.0.contractId");
    const officer = watch("mgmtOfficerId");

    const isTrue = startDate && endDate && startTime && endTime && contractId;

    const isValidSchedule =
      isTrue &&
      (reccurenceValue === "DAILY" || //
        (reccurenceValue === "WEEKLY" && recurrencePeriod.days.length > 0) ||
        reccurenceValue === "MONTHLY");

    if (!isValidSchedule) {
      showModal("스케줄/출입 정보를 모두 입력해주세요.");
      return false;
    } else if (!officer) {
      showModal("작업자를 선택해주세요.");
      return false;
    }
    return true;
  };

  const handleClickWorkOrderPreivews = () => {
    const accessZone = watch("workOrderSheetAccessZoneList.0");
    let data: WorkOrderPreivew = {
      startDate: accessZone.startDate,
      isHoliday,
      recurrenceEndDate: accessZone.endDate,
      endDate: accessZone.endDate,
      recurrenceType: recurrenceOptions.find((item) => item.checked)?.value as UnionRecurrenceType,
      startTime: accessZone.startTime,
      endTime: accessZone.endTime,
      officerName: String(mgmtOfficerList.find((officer) => officer.id === watch("mgmtOfficerId"))?.name || ""),
    };

    if (reccurenceValue !== "DAILY") {
      data = { ...data, recurrenceDayOfWeekTypeList: recurrencePeriod.days.join(","), deadline: recurrencePeriod.deadline! };

      if (reccurenceValue === "MONTHLY") {
        let newStartDate = moment(data.startDate).startOf("month").date(Number(recurrencePeriod.monthlyStart));

        data = {
          ...data,
          monthlyStart: Number(recurrencePeriod.monthlyStart),
          // startDate: newStartDate.format(YmdFormat.WITH_TIME_ZONE),
        };
      }
    }

    if (scheduleValidation()) getWorkOrderPreview(data);
  };
  const handleOnDateRangeChange = (date: [Date | null, Date | null]) => {
    setDateRange(date);
    let start = "";
    let end = "";

    if (date[0] !== null) {
      start = moment(date[0]).format(YmdFormat.WITH_TIME_ZONE);
    }
    if (date[1] !== null) {
      if (reccurenceValue === "MONTHLY") {
        end = moment(date[1]).endOf("M").endOf("day").format(YmdFormat.WITH_TIME_ZONE);
      } else {
        end = moment(date[1]).endOf("day").format(YmdFormat.WITH_TIME_ZONE);
      }
    }
    setValue("startDate", start);
    setValue("endDate", end);
    setValue("workOrderSheetAccessZoneList.0.startDate", start);
    setValue("workOrderSheetAccessZoneList.0.endDate", end);
  };

  return (
    <>
      {!getValues("id") ? (
        <article className="contents-container__wrap-article">
          <div className="contents-container__sub-title">
            <h2 className="font18">스케줄 / 출입</h2>
          </div>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">업무유형</p>
            </div>

            <div className="flex-center">
              {scheduleOptions.map((type) => (
                <div key={type.value}>
                  <BaseRadio
                    id={type.value} //
                    name={"WORK_TYPE"}
                    label={type.label}
                    className="mr20"
                    disabled={queryParams.csId && !isRegular ? true : false}
                    checked={type.checked}
                    onChange={(checked: boolean) => {
                      setScheduleOptions((prev: Options) => prev.map((item) => ({ ...item, checked: item.value === type.value ? checked : false })));
                      setRecurrenceOptions(cycleOptions);
                      setValue("startDate", "");
                      setValue("endDate", "");
                      setValue("workOrderSheetAccessZoneList.0.startDate", "");
                      setValue("workOrderSheetAccessZoneList.0.endDate", "");
                      setPreviews([]);
                      // setDateRange([null, null]);
                    }}
                  />
                </div>
              ))}
            </div>
          </section>

          {/* 작업기간*/}

          {isRegular && (
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">반복 단위</p>
              </div>
              <div className="flex-center">
                {recurrenceOptions.map((option) => (
                  <BaseRadio
                    key={option.value}
                    checked={option.checked}
                    label={option.label}
                    id={option.value}
                    name={"workCycle"}
                    className="mr15"
                    onChange={(checked: boolean) => {
                      setRecurrencePeriod({ ...DEFAULT_RECURRENCE_PERIOD, days: [] });
                      setRecurrenceOptions((prev: RecurrenceOptions) =>
                        prev.map((item) => ({ ...item, checked: item.value === option.value ? checked : false })),
                      );

                      setValue("startDate", "");
                      setValue("endDate", "");
                      setValue("workOrderSheetAccessZoneList.0.startDate", "");
                      setValue("workOrderSheetAccessZoneList.0.endDate", "");
                      setPreviews([]);
                      setGroupPayload((prev) => ({
                        ...prev,
                        recurrenceType: option.value,
                        recurrenceDayOfWeekType: option.value === "WEEKLY" ? prev.recurrenceDayOfWeekType : "",
                      }));
                    }}
                  />
                ))}
              </div>
            </section>
          )}

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required ">{isRegular ? "반복 기간" : "업무 기간 "}</p>
            </div>
            <div className="contents-container__grid-contents ">
              <div className="flex-row flex-center-start">
                <div className="minmax240">
                  <RangeDatepicker
                    dateRange={[
                      watch("workOrderSheetAccessZoneList.0.startDate") ? moment(watch("workOrderSheetAccessZoneList.0.startDate")).toDate() : null,
                      watch("workOrderSheetAccessZoneList.0.endDate") ? moment(watch("workOrderSheetAccessZoneList.0.endDate")).toDate() : null,
                    ]}
                    onChange={handleOnDateRangeChange}
                    dateFormat={(reccurenceValue === "MONTHLY" && "yyyy.MM") || ""}
                    minDate={(scheduleOptions[1].checked && moment().toDate()) || undefined}
                    type={reccurenceValue === "MONTHLY" ? "month" : ""}
                  />
                </div>

                {isRegular && (
                  <BaseCheckbox
                    label="주말/공휴일 포함"
                    id={"isHoliday"}
                    name={"isHoliday"}
                    className="ml20"
                    checked={isHoliday}
                    onChange={(checked: boolean) => {
                      setIsHoliday(checked);
                      setGroupPayload((prev) => ({ ...prev, isHoliday: checked }));

                      if (checked === false) {
                        setRecurrencePeriod((prev) => ({
                          ...prev,
                          days: prev.days.filter((day) => day !== DayOfWeekType.SAT && day !== DayOfWeekType.SUN),
                        }));
                      }
                    }}
                  />
                )}
              </div>
              {(errors?.startDate || errors?.endDate) && <p className="validation-text">필수입력 항목입니다</p>}
              {isPast && <p className="validation-text">*과거 날짜에 대해서는 출입 권한이 부여되지 않습니다.</p>}
            </div>
          </section>

          {reccurenceValue !== "DAILY" && isRegular && (
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required ">업무 일정</p>
              </div>
              <div className="contents-container__grid-contents ">
                <section className="flex-center-start flex-row ">
                  {reccurenceValue === "WEEKLY" && (
                    <>
                      {dayOptions.map((day, idx: number) => (
                        <div key={day.value}>
                          <BaseCheckbox
                            id={`${day.value}`}
                            name={`${day.value}`}
                            className="mr20 "
                            label={day.label}
                            checked={recurrencePeriod.days.includes(day.value)}
                            disabled={!isHoliday && (day.value === DayOfWeekType.SAT || day.value === DayOfWeekType.SUN) ? true : false}
                            onChange={(checked: boolean) => {
                              let days: UnionDayOfWeekType[] = recurrencePeriod.days || [];
                              if (checked) {
                                days.push(day.value);
                              } else {
                                days = days.filter((week) => week !== day.value);
                              }
                              setRecurrencePeriod((prev) => ({ ...prev, days }));
                              setGroupPayload((prev) => ({ ...prev, period: days.join(",") }));
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {reccurenceValue === "MONTHLY" && (
                    <div className="flex-center">
                      <p>매월</p>
                      <BaseSelect
                        className="minmax100 ml20"
                        stateOptions={dateOptions(31)}
                        setStateValue={(date: any) => {
                          setRecurrencePeriod((prev) => ({ ...prev, monthlyStart: date }));
                        }}
                        value={recurrencePeriod.monthlyStart}
                        placeholder="선택"
                      />{" "}
                    </div>
                  )}

                  <div className="flex-center ml50">
                    <p className="required w-100 mr20">처리 기한</p>
                    <BaseSelect
                      className="minmax100"
                      stateOptions={dateOptions(30)}
                      setStateValue={(data: any) => {
                        setRecurrencePeriod((prev) => ({ ...prev, deadline: data }));
                      }}
                      value={recurrencePeriod.deadline}
                    />
                  </div>
                </section>
              </div>
            </section>
          )}

          {/* 출입 구역*/}

          <div className="flex-center">
            <section className="contents-container__grid position-relative">
              <div className="contents-container__grid-index">
                <p className="required">출입 구역</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton title="선택" className="color-white" onClick={() => setTableModal({ isOpen: true, type: "ACCESS" })} />

                  <Controller
                    control={control}
                    name={"workOrderSheetAccessZoneList.0.contractId"}
                    // rules={{ required: !isPast ? true : false }}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <>
                        {tableModal.isOpen && tableModal.type === "ACCESS" && (
                          <TableModal
                            title="출입 구역 선택"
                            data={contracts} //
                            columns={AccessAreaColumns}
                            checkboxType="radio"
                            setParams={setContractParams}
                            pageMeta={pageMeta}
                            selectKey="contractId"
                            defaultDisabledList={
                              contracts
                                .filter((contract) => !INVITEABLE_CONTRACT_STEP.includes(contract.contractStep as any))
                                .map((item) => String(item.contractId || "")) || []
                            }
                            onClick={(selected) => {
                              onChange(selected[0].contractId);
                              setValue("workOrderSheetAccessZoneList.0.contractManageId", selected[0].contractManageId);
                            }}
                            onClose={() => {
                              setTableModal({ isOpen: false });
                              setContractParams(DEFAULT_PARAMS);
                            }}
                            searchInputProps={{
                              onSearch: (value, searchType) => setContractParams({ ...contractParams, page: 0, searchValue: value, searchType }),
                              selectOptions: searchOptionList,
                            }}
                          ></TableModal>
                        )}
                      </>
                    )}
                  ></Controller>

                  {watch("workOrderSheetAccessZoneList.0.contractId") && (
                    <>
                      <div className="flex-center ml20">
                        <Link
                          to={PagePath.contract.detail.replace(":id", watch("workOrderSheetAccessZoneList.0.contractId") + "?tab=accessLevel")}
                          target="_blank"
                          className="text-underline"
                        >
                          {
                            contracts.find((contract) => contract.contractId === Number(watch("workOrderSheetAccessZoneList.0.contractId")))
                              ?.spaceProductName
                          }
                        </Link>
                        <span className="ic-target-blank"></span>
                        <button
                          style={{ backgroundColor: "transparent" }}
                          className="base-remove ml5"
                          onClick={(e) => {
                            e.preventDefault();
                            setValue("workOrderSheetAccessZoneList.0.contractId", "");
                            setValue("workOrderSheetAccessZoneList.0.contractManageId", "");
                          }}
                        ></button>
                      </div>
                      {/* 작업자 */}
                    </>
                  )}
                </div>
                {errors?.workOrderSheetAccessZoneList && errors?.workOrderSheetAccessZoneList[0]!.contractId && (
                  <p className="validation-text position-absolute">필수입력 항목입니다</p>
                )}
              </div>
            </section>

            <section className="contents-container__grid position-relative">
              <div className="contents-container__grid-index">
                <p className="required">출입 가능 시간</p>
              </div>
              <div className="contents-container__grid-contents ">
                <div className="flex-center-start flex-row ">
                  <div className="flex-center-start">
                    <Controller
                      control={control}
                      // rules={{ required: !isPast ? true : false }}
                      rules={{ required: true }}
                      name={"workOrderSheetAccessZoneList.0.startTime"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <div className="flex-column">
                          <BaseDatePicker
                            className="minmax120"
                            type="time"
                            setDate={onChange}
                            timeIntervals={10}
                            selectedDate={(value && moment(value).toDate()) || null}
                            placeholder="시간을 선택하세요"
                          />
                        </div>
                      )}
                    ></Controller>

                    <div className="mx20">-</div>

                    <Controller
                      control={control}
                      // rules={{ required: !isPast ? true : false }}
                      rules={{ required: true }}
                      name={"workOrderSheetAccessZoneList.0.endTime"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseDatePicker
                          className="minmax120"
                          type="time"
                          timeIntervals={10}
                          setDate={onChange}
                          selectedDate={(value && moment(value).toDate()) || null}
                          placeholder="시간을 선택하세요"
                          minTime={moment(watch("workOrderSheetAccessZoneList.0.startTime")).add("m", 10).toDate()}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
                {errors?.workOrderSheetAccessZoneList && (
                  <>
                    {(errors.workOrderSheetAccessZoneList[0]?.startTime || errors.workOrderSheetAccessZoneList[0]?.endTime) && (
                      <p className="validation-text position-absolute">필수입력 항목입니다</p>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>

          {/* 스케줄 미리보기 / 초기화 버튼 */}
          {isRegular && (
            <section>
              <div className="flex-center-end">
                {/* v1,11 에서 비노출  */}
                {/* <BaseButton
                  title="초기화"
                  disabled={previews.length < 1}
                  className="color-white"
                  onClick={() => {
                    setBaseModal({
                      isOpen: true, //
                      title: "생성된 스케줄을 초기화 할까요?",
                      btnRightTitle: "확인",
                      btnLeftTitle: "취소",
                      children: <p>출입 권한 설정과 스케줄 미리보기가 삭제됩니다.</p>,
                      onClick: () => {
                        setPreviews([]);
                        resetField && resetField("workOrderSheetAccessZoneList");
                        setBaseModal({ isOpen: false });
                      },
                      onClose: () => setBaseModal({ isOpen: false }),
                    });
                  }}
                /> */}
                <BaseButton title="스케줄 미리보기" className="ml10" onClick={handleClickWorkOrderPreivews} />
              </div>
            </section>
          )}
          {previews.length > 0 && <WorkOrderPreviews previews={previews} setPreviews={setPreviews} />}
        </article>
      ) : (
        <>
          {/* 그룹 일정 그룹 워크오더시 리스트 노출 */}
          {getValues()?.workOrderSheetGroupId && (
            <article className="contents-container__wrap-article">
              <div className="contents-container__sub-title">
                <div className="minmax200 pb4">
                  <h2 className="font18">정기 스케줄</h2>
                </div>
              </div>
              <section className="contents-container__grid ">
                <div className="contents-container__grid-index">
                  <p>그룹 id</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{watch("workOrderSheetGroupId")}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>출입 권한</p>
                </div>
                <div className="contents-container__grid-contents">
                  <WorkOrderGroupListSection workOrder={getValues()} allCancel={allCancel} />

                  <div className="flex-center">
                    <BaseButton
                      title={`모든 일정 ${allCancel ? "복구" : "취소"}`}
                      className={`color-white ${allCancel ? "icon-refresh" : "icon-trash color-gray"} `}
                      onClick={() => {
                        setAllCancel((checked: boolean) => !checked);
                      }}
                    />
                    {/* <ul className="list-marker ml20">
                      <li className=""> */}
                    <p className="ml20 font13 text-gray600">{`[접수], [처리중], [작업완료] 상태의 업무를 [취소] 상태로 변경합니다.`}</p>
                    {/* </li>
                    </ul> */}
                  </div>
                </div>
              </section>
            </article>
          )}
        </>
      )}
    </>
  );
};

export default WorkOrderScheduleSection;
