import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { PartnerLoginListResponse } from "./api/partnerLogin/partnerlist-type";
import { ApiResponse } from "./api/public-types";
import { useErrorModal } from "./recoil/errorModal/hook";
import { partnersState } from "./recoil/partners/atom";
import { axiosInstance } from "./utils";
import { partnerMemberState } from "./recoil/partnerMember/atom";
import { getPartnerMemberResponse } from "./api/partner/type";

/*
  PartnerProviderState는 partner 관련된 정보를 전역으로 관리하는 컴포넌트입니다.
  AuthProviderState와 함께 사용됩니다.
  AuthProviderState가 로그인이 되었을 때만 동작합니다.
  로그인이 되었을 때, partner 정보를 가져옵니다.
  recoil을 사용하여 전역으로 partner 정보를 관리합니다.
*/
function PartnerProviderState({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();
  const setGlobalPartnerList = useSetRecoilState(partnersState);
  const setGlobalPartnerMember = useSetRecoilState(partnerMemberState);
  const { openErrorModal } = useErrorModal();

  const getPartners = async () => {
    await getAccessTokenSilently();
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;

    return axiosInstance.get<ApiResponse<PartnerLoginListResponse>>("/api/ctrl/pa/partners", {
      headers: { Authorization: `Bearer ${idToken}` },
    });
  };

  const getPartnerMember = async () => {
    await getAccessTokenSilently();
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;

    return axiosInstance.get<ApiResponse<getPartnerMemberResponse>>("/api/ctrl/pa/me", {
      headers: { Authorization: `Bearer ${idToken}` },
    });
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      try {
        const result = await getPartners();
        const partners = result.data.data.activePartners;
        setGlobalPartnerList(partners);

        const partnerMember = await getPartnerMember();
        // console.log(`partnerMember`, partnerMember.data.data);
        setGlobalPartnerMember(partnerMember.data.data.member);
      } catch (error: any) {
        console.log(error);
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
    })();
  }, [isAuthenticated]);

  return <>{children}</>;
}
export default PartnerProviderState;
