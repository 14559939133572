import React from "react";
import { ProviderPayletterModel } from "src/api/provider/provider-types";

type Props = {
  providerPayletter: ProviderPayletterModel;
};

const BasicPayletterDetail = ({ providerPayletter }: Props) => {
  return (
    <div className="mb10">
      <div className="index-tab">
        <span>일반 결제 페이레터 정보 (필수)</span>
      </div>
      <div className="border-gray py16 pr16 px20">
        <section className="flex-center mb10">
          <div className="minmax123">
            <span className=" font13 text-gray600">가맹점 아이디</span>
          </div>
          <p className="font13">{providerPayletter.clientId}</p>
        </section>
        <section className="flex-center mb10">
          <div className="minmax123">
            <span className=" font13 text-gray600">API Key (PAYMENT)</span>
          </div>
          <p className="font13">{providerPayletter.paymentKey?.replace(/./g, "*")}</p>
        </section>
        <section className="flex-center">
          <div className="minmax123">
            <span className=" font13 text-gray600">API Key (SEARCH)</span>
          </div>
          <p className="font13">{providerPayletter.searchKey?.replace(/./g, "*")}</p>
        </section>
      </div>
      <div className="mt10 mb30">
        <div className="index-tab">
          <span>일반 결제 페이레터 정보 검증</span>
        </div>
        <div className="border-gray py16 pr16 px20">
          <section className="flex-center">
            <div className="minmax200 d-flex">
              <span className="font13 text-gray600">1. Payment API Key: </span>
              <span className=" font13 ml10">
                {providerPayletter?.isVerifiedPaymentKey ? "성공" : "실패"}
              </span>
            </div>

            <div className="minmax200">
              <span className="font13 text-gray600">2. Search API Key 조회 API: </span>
              <span className=" font13 ml10">
                {providerPayletter?.isVerifiedSearchKey ? "성공" : "실패"}
              </span>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BasicPayletterDetail;
