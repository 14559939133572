import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import {
  EcEslGatewayListData,
  EcEslLabelAllListData,
  EcEslLabelDetailData,
  EcEslLabelListData,
  EcEslLabelsAllListParams,
  EcEslLabelsListParams,
  EcEslServerListData,
  EcEslStationListData,
  EslTempProductDetailData,
} from "./esl-types";

/* 
  CEC01. ESL 서버 목록 조회
*/
export async function getEslServersAsync(axios: AxiosInstance): Promise<AxiosResponse<ApiResponse<EcEslServerListData>>> {
  return await axios.get<ApiResponse<EcEslServerListData>>(ApiPath.esl.serverList);
}

/* 
  CEC02. ESL 게이트웨이 목록 조회
*/
export async function getEslGatewaysAsync(
  axios: AxiosInstance,
  params?: {
    serverId: string;
  },
): Promise<AxiosResponse<ApiResponse<EcEslGatewayListData>>> {
  if (!params?.serverId) {
    throw Error("serverId is not found");
  }
  return await axios.get<ApiResponse<EcEslGatewayListData>>(ApiPath.esl.gatewayList.replace("{serverId}", params.serverId));
}

/* 
  CEC03. ESL 라벨 목록 페이지 조회
*/
export async function getEslLabelsAsync(
  axios: AxiosInstance,
  params?: EcEslLabelsListParams,
): Promise<AxiosResponse<ApiResponse<EcEslLabelListData>>> {
  if (!params?.serverId) {
    throw Error("serverId is not found");
  }
  return await axios.get<ApiResponse<EcEslLabelListData>>(ApiPath.esl.labelList.replace("{serverId}", params.serverId), {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  CEC04. ESL 라벨 목록 조회
*/
export async function getAllEslLabelsAsync(
  axios: AxiosInstance,
  params?: EcEslLabelsAllListParams,
): Promise<AxiosResponse<ApiResponse<EcEslLabelAllListData>>> {
  if (!params?.ids || params.ids.length === 0) {
    throw Error("ids is not found");
  }
  return await axios.get<ApiResponse<EcEslLabelAllListData>>(ApiPath.esl.labelAllList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    },
  });
}

/* 
  CEC05. ESL 라벨 상세 조회
*/
export async function getEslLabelAsync(
  axios: AxiosInstance,
  params?: {
    labelId: string;
  },
): Promise<AxiosResponse<ApiResponse<EcEslLabelDetailData>>> {
  if (!params?.labelId) {
    throw Error("labelId is not found");
  }
  return await axios.get<ApiResponse<EcEslLabelDetailData>>(ApiPath.esl.labelDetail.replace("{id}", params.labelId));
}

/* 
  CEC06. ESL 상점 정보 목록 조회
*/
export async function getEslStationsAsync(
  axios: AxiosInstance,
  params?: {
    serverId: string;
  },
): Promise<AxiosResponse<ApiResponse<EcEslStationListData>>> {
  if (!params?.serverId) {
    throw Error("serverId is not found");
  }
  return await axios.get<ApiResponse<EcEslStationListData>>(ApiPath.esl.stationList.replace("{serverId}", params.serverId));
}

/* 
  REC07. (임시) ESL 데이터 상세 조회
*/
export async function getEslTempProductDetailAsync(
  axios: AxiosInstance,
  params?: {
    productId: string;
    serverId: string;
  },
): Promise<AxiosResponse<ApiResponse<EslTempProductDetailData>>> {
  if (!params?.productId) {
    throw Error("productId is not found");
  }
  const path = ApiPath.esl.tempProductDetail.replace("{productId}", params.productId).replace("{serverId}", params.serverId);
  return await axios.get<ApiResponse<EslTempProductDetailData>>(path);
}
