import React, { Dispatch, memo, SetStateAction, useCallback, useRef, useState } from "react";
import { getFileDownload, uploadFilesAsync } from "src/api/file/file-api";
import { FileData } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, MediaServiceType } from "src/api/public-types";
//
import { useAuth0 } from "@auth0/auth0-react";
import { BaseAbstractModal } from "./BaseAbstractModal";
import { BaseModal } from "./BaseModal";
import { ResizedImage } from "./ResizedImage";
import { BaseButton } from "./BaseButton";
import { IMAGE_ACCEPT } from "src/vars";

interface Props {
  onChangeMediaFiles?: (mediaFiles: Array<MediaFile>) => void; // MediaFile 배열 변경 callback 함수
  mediaFiles?: Array<MediaFile>; // 기존에 저장되어있는 mediaFile 이미지 배열
  categoryName?: string; // 여러 ImageUpdate 컴포넌트 사용시 구분할 수 있는 카테고리 명칭
  uploadType?: "public" | "private"; // public: 아마존 S3 저장, private: PV 저장
  fileMaxLength?: number; // 이미지 최대 저장 length
  isUsedRepresentative?: boolean; // 대표 사용여부
  isUsedSelfDescription?: boolean; // 직적입력 설명 사용여부
  isUsedDescription?: boolean; // 설명 사용여부
  className?: string; // TODO: 사용하는 곳 없음 ?
  deleteIds?: Array<string>;
  setDeleteIds?: Dispatch<SetStateAction<Array<string>>>;
  mediaServiceType?: MediaServiceType;
  isOnlyView?: boolean;
  setOnChangeOrders?: Dispatch<SetStateAction<boolean>>;
  maxSize?: number;
}

type DragDropState = {
  draggedFrom: number | null;
  draggedTo: number | null;
  isDragging: boolean;
  originalOrder: MediaFile[];
  updatedOrder: MediaFile[];
};

const s3Url = process.env.REACT_APP_S3_BASEURL;
const mb = 1024 * 1024;

/* 
  이미지 수정 공통 컴포넌트
*/
const PublicImageUpdate = memo(
  ({
    onChangeMediaFiles,
    mediaFiles,
    categoryName,
    uploadType,
    fileMaxLength,
    isUsedRepresentative,
    isUsedSelfDescription,
    isUsedDescription,
    deleteIds,
    setDeleteIds,
    mediaServiceType,
    setOnChangeOrders,
    isOnlyView,
    maxSize = 10,
  }: Props) => {
    //

    const fileRef = useRef<HTMLInputElement>(null);

    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

    // 파일 업로드 api hook
    const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync, {
      noHandleError: true,
    });

    // blob 파일
    const { executeAsync: getFileData } = useApiOperation(getFileDownload, {
      noHandleError: true,
      noAuthenticationRequired: true,
    });

    // 파일 제한 갯수 초과시 띄우는 모달
    const [modalOpen, setModalOpen] = useState(false);

    const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
    const [focusFullScreenImageIndex, setFocusFullScreenImageIndex] = useState(0);

    // input file 이 변경될때 실행
    const changeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
      //

      // input file 객체
      const files = event.target.files;

      // 파일 업로드 response media 배열
      const fileDatas: Array<FileData> = [];

      if (files) {
        for (let i = 0; i < files.length; i++) {
          // console.log(e.target.files[i].size, sizeLimit);
          if (files[i].size > maxSize * mb) {
            setModalOpen(true);
            return;
          }
        }

        if (fileMaxLength) {
          // 파일 제한 갯수가 있으면, 파일 갯수 > 파일 제한 갯수
          const totalLength = files.length + (mediaFiles || []).length;
          if (totalLength > fileMaxLength) {
            setModalOpen(true);
            //
            return;
          }
        }

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          const formData = new FormData();
          formData.append("file", file);

          // 파일 업로드
          const res = await postImageFile({
            file: formData,
            type: uploadType,
          });
          if (res.status >= 200 && res.status <= 299 && res?.data?.data?.media) {
            const data: FileData = res.data.data.media;

            fileDatas.push(data);
          } else {
            throw Error("파일 업로드를 실패했습니다.");
          }
        }

        const convertedMediaFiles = convertMediaFiles(fileDatas);
        // 기존 파일 + 업로드한 파일
        const concat = [...(mediaFiles || []), ...convertedMediaFiles];
        onChangeMediaFiles && onChangeMediaFiles(concat);
      }

      // 파일 객체 초기화. (같은 파일 업로드시 작동되게)
      event.target.value = "";

      //
    };

    // 업로드한 FileData -> MediaFile 로 변환
    const convertMediaFiles = (fileDatas: Array<FileData>) => {
      const count = (mediaFiles || []).length;
      const convertedMediaFiles: Array<MediaFile> = fileDatas.map((fileData: FileData, idx: number) => {
        const mediaFile: MediaFile = {
          // id: Math.random().toString(36).substr(2, 9),
          description: "",
          fileStorageType: fileData.fileStorageType,
          key: fileData.key,
          filename: fileData.filename,
          contentType: fileData.contentType,
          fileSize: fileData.fileSize,
          isPrimary: false,
          orderNums: idx + 1 + count, // orderNums 1부터 시작
          mediaServiceType: mediaServiceType ? mediaServiceType : "MEDIASERVICE_UNRECOGNIZED",
        };
        return mediaFile;
      });
      return convertedMediaFiles;
    };

    // 이미지 삭제
    const removeMediaFile = (idx: number, deleteId?: string) => {
      const _mediaFiles = [...(mediaFiles || [])];
      _mediaFiles.splice(idx, 1);
      if (deleteIds && setDeleteIds && deleteId) {
        setDeleteIds([...deleteIds, deleteId]);
      }
      onChangeMediaFiles && onChangeMediaFiles(_mediaFiles);
    };

    // 풀스크린 이미지 열기
    const handleOpenFullImage = (idx: number) => {
      setFocusFullScreenImageIndex(idx);
      setIsFullScreenImageOpen((prev) => !prev);
    };

    const handleFullScreenImage = (position: "left" | "right") => {
      if (mediaFiles) {
        if (position === "left") {
          setFocusFullScreenImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
        }
        if (position === "right") {
          setFocusFullScreenImageIndex((prev) => (prev < mediaFiles.length - 1 ? prev + 1 : prev));
        }
      }
    };

    // 미리보기 이미지 다운로드
    const forceDownload = useCallback(
      async (url: string, filename: string) => {
        try {
          const getFile: any = await getFileData({ url });
          const blob = new Blob([getFile.data]);
          const fileUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = fileUrl;
          link.style.display = "none";
          link.download = filename;

          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(fileUrl);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      },
      [getFileData],
    );

    // auth0 토큰 가져오기
    const getTokenWithDownload = useCallback(
      async (url: string, fileName: string) => {
        try {
          // access token 가져오기
          await getAccessTokenSilently();
          const response = await getIdTokenClaims();
          const idToken = response?.__raw;

          const downloadUrl = `${url}?access_token=${idToken}&forceDownload`;

          // 다운로드 함수 실행
          await forceDownload(downloadUrl, fileName);
        } catch (error) {
          console.error("Error getting token or downloading file:", error);
        }
      },
      [getAccessTokenSilently, getIdTokenClaims, forceDownload],
    );

    const sliceTitle = (fileName: string) => {
      const dotIndex = fileName.lastIndexOf(".");
      const title = fileName.slice(0, dotIndex);
      const extension = fileName.slice(dotIndex + 1);
      return { title, extension };
    };

    return (
      <div className="base-image-wrap">
        {!isOnlyView && (
          <>
            <input
              ref={fileRef}
              type="file"
              style={{ display: "none" }}
              accept={IMAGE_ACCEPT}
              onChange={changeFile}
              // onChange={onSaveImages}
              multiple
            />
            <BaseButton
              title={"파일찾기"}
              className="color-white"
              disabled={fileMaxLength && mediaFiles && mediaFiles.length >= fileMaxLength ? true : false}
              onClick={() => fileRef.current?.click()}
            />
            {mediaFiles && mediaFiles.length === 0 && (
              <ul className="base-list mt10">
                <li>문서를 카메라로 찍거나 스캔하여 업로드해주세요.</li>
                <li>업로드 용량은 개당 10MB까지 가능합니다.</li>
              </ul>
            )}
          </>
        )}
        <div className="base-image ">
          {mediaFiles &&
            mediaFiles.map((item: MediaFile, idx: number) => {
              return (
                <div
                  className={`base-image__wrap  ${isOnlyView ? "detail" : "form"}`}
                  key={idx}
                  draggable={true}
                  data-position={idx}
                  onClick={() => {
                    handleOpenFullImage(idx);
                  }}
                >
                  {/* 이미지 */}
                  <ResizedImage url={s3Url + (item?.key || "")} />
                  {!isOnlyView && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeMediaFile(idx, String(item.id));
                      }}
                    ></button>
                  )}
                  <div className="title-area">
                    <div className="not-hover">
                      <p>{sliceTitle(String(item.filename || "")).title}</p>
                      <span>.{sliceTitle(String(item.filename || "")).extension}</span>
                    </div>
                    <div className="active-hover">
                      <p>{sliceTitle(String(item.filename || "")).title}</p>
                      <span>.{sliceTitle(String(item.filename || "")).extension}</span>
                      {isOnlyView && (
                        <button
                          type="button"
                          className="detail-download"
                          onClick={(e) => {
                            e.stopPropagation();
                            getTokenWithDownload(item.url!, String(item.filename || ""));
                          }}
                        ></button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {mediaFiles && mediaFiles?.length > 0 && isFullScreenImageOpen && (
            <BaseAbstractModal isNotUseWhiteBoard isOpen={true}>
              <div className="base-image__full-screen">
                <img
                  src={
                    mediaFiles[focusFullScreenImageIndex].url
                      ? mediaFiles[focusFullScreenImageIndex].url
                      : mediaFiles[focusFullScreenImageIndex].key?.includes("http")
                      ? mediaFiles[focusFullScreenImageIndex].key
                      : s3Url + mediaFiles[focusFullScreenImageIndex]?.key!
                  }
                  alt={mediaFiles[focusFullScreenImageIndex].url || "이미지를 불러오는데 실패했습니다."}
                />
                <div className="img-button-wrap">
                  <div className="flicker">
                    <button className="left" onClick={() => handleFullScreenImage("left")}></button>
                    <p>
                      {focusFullScreenImageIndex + 1} / {mediaFiles.length}
                    </p>
                    <button className="right" onClick={() => handleFullScreenImage("right")}></button>
                  </div>
                  <button
                    className="download"
                    onClick={() => {
                      const url = String(mediaFiles[focusFullScreenImageIndex].url);
                      const fileName = String(mediaFiles[focusFullScreenImageIndex].filename);
                      getTokenWithDownload(url, fileName);
                    }}
                  ></button>

                  <button className="close" onClick={() => setIsFullScreenImageOpen(false)}>
                    닫기
                  </button>
                </div>
              </div>
            </BaseAbstractModal>
          )}
          {modalOpen && (
            <BaseModal
              isOpen={true}
              btnRightTitle="확인"
              className="pre-formatted"
              title={`첨부파일 업로드가 실패했습니다.`}
              onClick={() => setModalOpen(false)}
            >
              <p>{`한 파일당 업로드 용량은 10MB, 최대 ${fileMaxLength}개 까지만 업로드 가능합니다.`}</p>
            </BaseModal>
          )}
        </div>
      </div>
    );
  },
);

export default React.memo(PublicImageUpdate);
