import { useState } from "react";
import { DetailTotalData } from "src/api/contract/contract-types";
import { ArticleSection } from "src/components/layout/ArticleSection";
import ContractBillingScheduleNotiDetail from "./detailComponent/ContractBillingScheduleNotiDetail";
import ContractBillingScheduleNotiEdit from "./editComponent/ContractBillingScheduleNotiEdit";

interface BasicProps {
  detailData: DetailTotalData;
  setData: Function;
}
const ContractBillingScheduleNoti = ({ detailData, setData }: BasicProps) => {
  const [editable, setEditable] = useState(false);
  const [saveFlags, setSaveFlags] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [editConfrim, setEditConfrim] = useState(false);
  const [commonModals, setCommonModals] = useState<any>({ message: "", title: "" });

  return (
    <ArticleSection
      title={"청구 수신 정보"}
      isEditable={editable}
      setIsEditable={setEditable}
      onClickSave={() => setSaveFlags(true)}
      popupState={popupState}
      setPopupState={setPopupState}
      editConfrim={editConfrim}
      setEditConfrim={setEditConfrim}
      commonModals={commonModals}
      disabledState={[String(detailData?.contract?.contractStep), String(detailData?.product.productType)]}
      children={
        editable === false ? (
          <ContractBillingScheduleNotiDetail detailData={detailData} />
        ) : (
          <ContractBillingScheduleNotiEdit
            detailData={detailData}
            saveFlags={saveFlags}
            setEditable={setEditable}
            setSaveFlags={setSaveFlags}
            setData={setData}
            popupState={popupState}
            setPopupState={setPopupState}
            setCommonModals={setCommonModals}
            setEditConfrim={setEditConfrim}
          />
        )
      }
    />
  );
};
export default ContractBillingScheduleNoti;
