import qs from "qs";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { partnersState } from "src/recoil/partners/atom";
import PagePath from "../../pagePath.json";
import PartnerBox from "./PartnerBox";

type PartnerCode = {
  partnerCode?: string;
};

const InitCtrlRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query: PartnerCode = useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true, allowDots: true });
  }, [location]);

  // 인증 만료 후 로그인시 > 기존 파트너 main 페이지로 이동
  const redirectMain = useMemo(() => localStorage.getItem("redirectMain"), []);

  // url 접근시 > 인증이 안되어 있으면 인중 후 기존에 진입하려던 url 로 이동
  const returnToUrlPage = useMemo(() => localStorage.getItem("returnTo")?.replaceAll(`"`, ""), []);

  const partners = useRecoilValue(partnersState);

  useEffect(() => {
    if (partners && query.partnerCode && query.partnerCode !== "") {
      let partner = partners.find((partner) => partner.code === query.partnerCode);
      if (!partner) {
        navigate(PagePath.accessDenied);
      } else {
        navigate(PagePath.main.replace(":partnerId", String(partner.id)));
      }
    }
  }, [query, partners]);

  useEffect(() => {
    if (redirectMain) {
      localStorage.removeItem("redirectMain");
      navigate(`${redirectMain}`);
    }
    if (returnToUrlPage) {
      localStorage.removeItem("returnTo");
      navigate(`${returnToUrlPage}`);
    }
  }, [navigate, redirectMain, returnToUrlPage]);

  if (!partners) return null;

  return (
    <>
      <MetaTag subTitle={"파트너 선택"} />
      <div className="page-init">
        <div className="page-init__bin"></div>
        <div className="page-init__contents">
          <h1>관리하실 파트너를 선택하세요</h1>

          {partners.length > 0 ? (
            <div className={`${partners.length > 4 ? "justify-content-start" : ""} partner-box-wrap`}>
              {partners.map((partner) => (
                <PartnerBox
                  key={partner.id}
                  title={partner.name}
                  subTitle={partner.code}
                  onClick={() => {
                    //
                    //recoil 파트너 id 저장
                    // setPartner(partner);
                    window.location.href = PagePath.main.replace(":partnerId", String(partner.id));
                    // const path = PagePath.main.replace(":partnerId", String(partner.id));
                    // navigate(path);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className={"partner-box-wrap"}>
              <PartnerBox className="empty" title={"선택가능한 파트너가 없습니다"} />
            </div>
          )}
        </div>
        <div className="page-init__footer">
          <p>
            이용에 어려움이 있으신가요? <span>계약 담당자에게 권한을 요청해주세요</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default InitCtrlRoom;
