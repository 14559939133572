import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useRowSelect, useTable } from "react-table";
import { useSticky } from "react-table-sticky";

import { Order, PageMeta } from "src/api/public-types";
import { BaseCheckbox, BaseInput, BasePagination, BaseRadio, BaseSelect, BaseSelectState } from "src/components";
import { useToast } from "src/recoil/toast/hook";

export interface BaseSelectTableQueryParams {
  keyword?: string;
  searchType?: string;
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  [key: string]: any;
}

const BaseSelectTable = <QueryParams extends BaseSelectTableQueryParams, Data>({
  setParams,
  columns,
  data,
  checkboxType,
  title,
  titleNode,
  selectKey,
  defaultDisabledList,
  defaultCheckedList,
  onChangeCheckedList,
  onClick,
  onClose,
  searchNode,
  pageMeta,
  maxLength = 100,
  maxLengthText,
  minLength = 1,

  searchInputProps,
}: {
  setParams: Dispatch<SetStateAction<QueryParams>>; //QueryParams의 key가 [keyword,searchType]인 경우 setParams하나만 인자로 넘겨주면 검색과 페이지네이션 프롭스를 사용하지 않아도 됩니다.
  columns: any;
  data: Data[];
  checkboxType: "checkbox" | "radio";
  title?: string;
  titleNode?: ReactNode;
  selectKey: string; // data[selectKey] 형식으로 data의 selectKey 값을 기준으로 체크합니다.
  defaultDisabledList?: string[]; // 마운트시 disable 시킬 key의 값
  defaultCheckedList?: string[]; // 마운트시 check 시킬 key의 값
  onChangeCheckedList?: (checkedKeyList: string[], checkedObjList: Data[]) => void;
  onClick: (checkedKeyList: string[], checkedObjList: Data[]) => void;
  onClose: () => void;
  pageMeta?: PageMeta;
  maxLength?: number; // checkboxType이 checkbox인 경우만 사용
  maxLengthText?: string;
  minLength?: number; // checkboxType이 checkbox인 경우만 사용

  searchInputProps?: {
    placeholder?: string;
    searchOptionPlaceholder?: string;
    onSearch: (searchKeyword: string, searchOption: string) => void; // 검색에서 setParams를 무시합니다.
    searchOptionList?: BaseSelectState["stateOptions"];
    selectWidth?: string;
    className?: string;
  };

  searchNode?: ReactNode;
}) => {
  if (titleNode && title) {
    throw Error("cannot pass both titleNode and title as props simultaneously");
  }
  if (searchNode && searchInputProps) {
    throw Error("cannot pass both searchNode and searchInputProps and searchOptionProps as props simultaneously");
  }
  if (data?.length > 0 && data[0]?.[selectKey as keyof (typeof data)[0]] === undefined) {
    console.log("data[0] :>> ", data);
    console.log("data[0]?.[selectKey as keyof (typeof data)[0]] :>> ", data[0]?.[selectKey as keyof (typeof data)[0]]);

    throw Error("data에서 selectKey를 찾을 수 없습니다.");
  }
  if (data?.length > 0 && typeof data?.[0]?.[selectKey as keyof (typeof data)[0]] !== "string") {
    throw Error("data[selectKey]의 값이 string이 아닙니다.");
  }

  const [selectedKeys, setSelectedItems] = useState(defaultCheckedList ? defaultCheckedList : []);
  const [selectedObjList, setSelectedObjList] = useState();

  const [isFocus, setIsFocus] = useState(false);
  const [isAllowedSubmitButton, setIsAllowedSubmitButton] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchOption, setSearchOption] = useState("");

  const { openToast } = useToast();

  useEffect(() => {
    if (onChangeCheckedList) {
      onChangeCheckedList(
        selectedKeys,
        data.filter((obj) => selectedKeys.includes(obj[selectKey as keyof typeof obj] as string)),
      );
    }

    if (selectedKeys?.length >= minLength && selectedKeys?.length <= maxLength) {
      setIsAllowedSubmitButton(true);
    } else {
      setIsAllowedSubmitButton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data as readonly object[],
      selectedKeys: selectedKeys,
      defaultDisabledList,
    },
    useBlockLayout,
    useSticky,
    useRowSelect,
    (hooks) => {
      checkboxType &&
        hooks.visibleColumns.push((columns: any[]) => [
          {
            width: 50,
            Header: "선택",
            accessor: "checked",
            Cell: ({ row, selectedKeys, defaultDisabledList }: any) => {
              return (
                <>
                  {checkboxType === "checkbox" ? (
                    <BaseCheckbox
                      id={row.id}
                      name={row.id}
                      checked={selectedKeys.includes(row.original[selectKey])}
                      onChange={(checked: boolean) => {
                        if (checked) {
                          if (selectedKeys.length === maxLength) {
                            if (maxLengthText) {
                              return openToast({ content: maxLengthText, isSystemToast: true });
                            } else {
                              return openToast({ content: `최대 ${maxLength}개의 항목까지 선택이 가능합니다`, isSystemToast: true });
                            }
                          }
                          setSelectedItems((prev) => [...prev, row.original[selectKey]]);
                        } else {
                          setSelectedItems((prev) => prev.filter((item) => item !== row.original[selectKey]));
                        }
                      }}
                      disabled={defaultDisabledList?.includes(row.original[selectKey])}
                    />
                  ) : (
                    <BaseRadio
                      id={row.id}
                      name={selectKey + "radio"}
                      value={row.original[selectKey]}
                      checked={selectedKeys.includes(row.original[selectKey])}
                      onChange={() => {
                        setSelectedItems([row.original[selectKey]]);
                      }}
                      disabled={defaultDisabledList?.includes(row.original[selectKey])}
                    />
                  )}
                </>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <>
      <section className="base-abstract-modal__title flex-center-between">
        <h1>{title && title}</h1>

        <div className="right-area">
          {searchNode && searchNode}
          {searchInputProps && searchInputProps?.searchOptionList ? (
            <div
              className={`base-search-input ${searchInputProps.className ? searchInputProps.className : ""} ${
                isFocus ? "base-search-input__focus" : ""
              }`}
            >
              <div className={searchInputProps.selectWidth ? `minmax${searchInputProps.selectWidth}` : "minmax148"}>
                <BaseSelect
                  onFocus={() => setIsFocus(true)}
                  onBlur={() => setIsFocus(false)}
                  value={searchOption}
                  placeholder={searchInputProps.searchOptionPlaceholder ?? "검색 옵션"}
                  stateOptions={searchInputProps.searchOptionList}
                  setStateValue={(value: string) => {
                    setSearchOption(value);
                  }}
                />
              </div>
              <BaseInput
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                type="text"
                value={searchKeyword}
                onChange={(value: string) => setSearchKeyword(value)}
                onKeyUp={() => {
                  searchInputProps.onSearch(searchKeyword, searchOption);
                }}
                placeholder={searchInputProps.placeholder ?? "검색어를 입력해주세요"}
                onSearchClick={() => {
                  searchInputProps.onSearch(searchKeyword, searchOption);
                }}
              />
            </div>
          ) : (
            searchInputProps && (
              <BaseInput
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                type="text"
                value={searchKeyword}
                onChange={(value: string) => setSearchKeyword(value)}
                onKeyUp={() => {
                  searchInputProps?.onSearch(searchKeyword, searchOption);
                }}
                placeholder={searchInputProps?.placeholder ?? "검색어를 입력해주세요"}
                onSearchClick={() => {
                  searchInputProps?.onSearch(searchKeyword, searchOption);
                }}
              />
            )
          )}
        </div>
      </section>
      <section className="base-abstract-modal__contents">
        <div {...getTableProps()} className="base-table view-data-table sticky px30">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {pageMeta ? (
        <section className="px30">
          <BasePagination
            pageIndex={pageMeta.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={pageMeta.pageRequest.size || 20}
            sizeOption={(size) => setParams((prev) => ({ ...prev, size }))}
            totalElements={pageMeta?.totalElements || 0}
            goPage={(page: number) => setParams((prev) => ({ ...prev, page }))}
            children={
              <div className="flex-center">
                <button
                  className="base-btn color-white mr10"
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  취소
                </button>
                <button
                  disabled={!isAllowedSubmitButton}
                  className="base-btn"
                  onClick={() => {
                    onClick(
                      selectedKeys,
                      data.filter((obj) => selectedKeys.includes(obj[selectKey as keyof typeof obj] as string)),
                    );
                    onClose();
                  }}
                >
                  선택
                </button>
              </div>
            }
          />
        </section>
      ) : (
        <section className="pa30 flex-center-end w-100">
          <div className="flex-center">
            <button
              className="base-btn minmax80 color-white mr10"
              onClick={() => {
                onClose && onClose();
              }}
            >
              취소
            </button>
            <button
              disabled={!isAllowedSubmitButton}
              className="base-btn minmax80 "
              onClick={() => {
                onClick(
                  selectedKeys,
                  data.filter((obj) => selectedKeys.includes(obj[selectKey as keyof typeof obj] as string)),
                );
                onClose();
              }}
            >
              선택
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default BaseSelectTable;
