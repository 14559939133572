import moment from "moment";
import React from "react";
import { Cell } from "react-table";
import { BaseTooltip } from "src/components";
import { BaseContractStatus } from "src/components/BaseContractStatus";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";
import { ViewYmdFormat } from "src/utils";

const AccessAreaColumns = [
  {
    Header: "id",
    accessor: "contractId",
    width: 50,
    Cell: ({ value }: { value?: string }) => {
      if (!value) {
        return "-";
      }
      return <BaseNewTabLink path={PagePath.provider.detail.replace(":id", value)} value={value} ellipsis className="ml16" />;
    },
  },

  {
    Header: "상태",
    accessor: "contractStep",
    width: 80,
    Cell: ({ value }: Cell<any>) => {
      return <BaseContractStatus status={value} />;
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 220,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 130,
    sticky: "left",
    Cell: ({ value }: Cell<any>) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 130,
    sticky: "left",
    Cell: ({ value }: Cell<any>) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "출입 그룹",
    accessor: "",
    width: 80,
    Cell: ({ row }: Cell<any>) => {
      const contractId = row.original.contractId;
      return (
        <Link to={`${PagePath.contract.detail.replace(":id", contractId)}?tab=accessLevel`} className="text-underline" target="_blank">
          보기
        </Link>
      );
    },
  },
  {
    Header: "이용시작일",
    accessor: "contractStartTime",
    width: 100,
    Cell: ({ value }: Cell<any>) => {
      return <>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</>;
    },
  },
  {
    Header: "이용종료일",
    accessor: "contractEndTime",
    width: 100,
    Cell: ({ value }: Cell<any>) => {
      return <>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</>;
    },
  },
];

export default AccessAreaColumns;
