import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { LogParams, getAccessLog } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseButton, BaseDatePicker, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import useApiLoading from "src/hooks/useApiLoading";
import { ViewYmdFormat, YmdFormat, downloadExcel } from "src/utils";

const columnHeader: any = [
  {
    Header: "일시",
    accessor: "datetime",
    // sticky: "left",
    width: 220,
    Cell: ({ value }: any) => {
      return (
        <>
          <p>{value ? moment(value).format(ViewYmdFormat.FULL) : ""}</p>
        </>
      );
    },
  },
  {
    Header: "이용자",
    accessor: "phoneNumberChange",
    width: 250,
  },
  {
    Header: "출입문",
    accessor: "deviceName",
    width: 250,
    Cell: ({ value }: Cell<any>) => <span className="w-100 text-left">{value}</span>,
  },
  {
    Header: "이벤트",
    accessor: "eventGroupName",
    width: 200,
  },
];
type Props = {
  isOpen: boolean;
  api?: any;
  visitorId?: number;
  contractManageId: string;
  contractApplyNumber: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchAccessLog = (props: Props) => {
  const [logs, setLogs] = useState<any>([]);

  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: findAccessLog } = useApiOperation(getAccessLog);

  const [params, setParams] = useState<LogParams>({
    page: 0,
    size: 20,
    startDate: moment().subtract(8, "days").startOf("days").format(YmdFormat.WITH_TIME_ZONE),
    endDate: moment().endOf("days").format(YmdFormat.WITH_TIME_ZONE),
    contractManageId: props.contractManageId,
    sort: { orders: [{ direction: "DESC", property: "datetime" }] },
  });
  const { isApiLoading } = useApiLoading();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: logs,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const makeName = (eventGroup: string) => {
    let text = eventGroup;
    switch (eventGroup) {
      case "ACCESS_FAILURE":
        text = "출입 인증 실패";
        break;
      case "ACCESS_REJECTED":
        text = "출입 거부";
        break;
      case "ACCESS_SUCCESS":
        text = "출입 인증 성공";
        break;
      default:
        break;
    }
    return text;
  };

  const changePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      let cp = phoneNumber.replace("+82", "0");
      let second = cp.substring(3, 11);
      let middle = second.substring(0, 4);
      let last = second.substring(4, 8);
      return "010-" + middle + "-" + last;
    }
  };

  const fetchLog = useCallback(
    async (param: any) => {
      let response: any = {};

      if (props.api) {
        param.visitorId = props.visitorId;
        response = await props.api(param);
      } else {
        response = await findAccessLog(param);
      }

      let array: any = [];
      if (response.data.data.content.length > 0) {
        response.data.data.content.forEach((obj: any) => {
          if (obj.phoneNumber !== undefined) {
            obj.phoneNumberChange = changePhoneNumber(obj.phoneNumber);
          } else {
            obj.phoneNumberChange = "";
          }

          if (obj.eventGroup !== undefined) {
            obj.eventGroupName = makeName(obj.eventGroup);
          } else {
            obj.eventGroupName = "";
          }
          if (obj.deviceName === undefined) {
            obj.deviceName = "";
          }
          if (obj.datetime === undefined) {
            obj.datetime = "";
          }
        });
        array = _.sortBy(response.data.data.content, ["datetime"]).reverse();
      }

      setLogs(array);
      setPageMeta(response.data.meta.pageMeta);
    },
    [findAccessLog],
  );

  const download = async (tableData: any[]) => {
    const downloadData = tableData.map((item: any) => {
      const dateTime = moment(item.datetime).format(YmdFormat.FULL);
      const phoneNumber = item.phoneNumber;
      const deviceName = item.deviceName;
      const eventGroup = item.eventGroup;

      const formatData = {
        dateTime,
        phoneNumber,
        deviceName,
        eventGroup,
      };

      return formatData;
    });

    try {
      const fileName = `ctrl.room_taap출입로그_${props.contractApplyNumber}_${moment(params.startDate).format(YmdFormat.YYYY_MM_DD)}~${moment(
        params.endDate,
      ).format(YmdFormat.YYYY_MM_DD)}_${moment().format("YYYYMMDDHHmmss")}.xlsx`;

      await downloadExcel({
        data: downloadData,
        fileName,
        header: ["일시", "이용자", "출입문", "이벤트"],
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (props.contractManageId) {
      fetchLog({ ...params, contractManageId: props.contractManageId });
    }
  }, [params.page, params.size]);

  const onDownloadAccessList = async () => {
    const downloadRequest = { ...params, page: 0, size: 5000 };

    let excelList = [];
    // 엑셀 다운로드 클릭시, 첫번째 목록 호출
    const { data: firstData, status } = await findAccessLog(downloadRequest);
    if (status >= 200 && status <= 299) {
      excelList = [...firstData.data.content];

      // 토탈 pages 수를 확인해 그 수만큼 api 콜
      const totalPages = firstData.meta.pageMeta?.totalPages;
      if (totalPages && totalPages > 1) {
        const promise = [];
        for (let i = 1; i < totalPages; i++) {
          promise.push(findAccessLog({ ...downloadRequest, page: i }));
        }
        const result = await Promise.all(promise);
        const final = result.map((res) => res.data.data.content);
        excelList = [...excelList, ...final].flat();
      }

      const finalList = excelList.map((item) => ({
        ...item,
        phoneNumber: changePhoneNumber(item.phoneNumber),
        eventGroup: makeName(item.eventGroup),
      }));

      // 갯수 테스트
      // const newArray = [...Array(1500)].flatMap(() => finalList);
      // console.log("newArray", newArray);
      // download(newArray);

      download(finalList);
    }
  };

  return (
    <>
      <BaseAbstractModal size="xlarge" isOpen={props.isOpen}>
        <section className="base-abstract-modal__title">
          <div className="flex-row flex-center-between w-100">
            <h1>출입기록 조회</h1>
          </div>
          <div className="flex-row flex-center">
            <div className="flex-row flex-center-between mr30">
              <div className="minmax80 font14">
                <p>시작일</p>
              </div>
              <div className="minmax140">
                <BaseDatePicker
                  name={"searchStart"}
                  selectedDate={params.startDate ? moment(params.startDate).toDate() : moment().toDate()}
                  setDate={(value: Date) => {
                    const startDate = moment(value).startOf("days").format(YmdFormat.WITH_TIME_ZONE);
                    setParams({ ...params, startDate });
                  }}
                  maxDate={moment().toDate()}
                />
              </div>
            </div>
            <div className="flex-row flex-center">
              <div className="minmax80 font14 pl10">종료일</div>
              <div className="minmax140">
                <BaseDatePicker
                  name={"paramsEnd"}
                  selectedDate={params.endDate ? moment(params.endDate).toDate() : moment().toDate()}
                  setDate={(value: Date) => {
                    const dump = _.cloneDeep(params);

                    let endT = moment(value).format(YmdFormat.YYYY_MM_DD);

                    let addTime = endT + " 23:59:59";

                    dump.endDate = moment(addTime).format(YmdFormat.WITH_TIME_ZONE);
                    setParams(dump);
                  }}
                  maxDate={moment().toDate()}
                />
              </div>
            </div>
            <BaseButton
              title={"검색"}
              className="ml8"
              onClick={async () => {
                await fetchLog({ ...params, page: 0 });
              }}
            />
          </div>
        </section>

        <section className="base-abstract-modal__contents">
          <div className="contents-container__table">
            <div {...getTableProps()} className="base-table view-data-table sticky  px30">
              <div className="base-pagination__total mb5"> 전체 {pageMeta?.totalElements}</div>
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>

              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                <div>
                  {rows.length === 0 && (
                    <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                      <div className="base-table__td w-100 text-center">
                        <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="base-abstract-modal__btn-wrap">
          <BasePagination
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={pageMeta?.pageRequest.size || 20}
            sizeOption={(size) => {
              setParams((prev) => ({ ...prev, size, page: 0 }));
              // currentSize && currentSize(sizeValue);
            }}
            totalElements={pageMeta?.totalElements || 0}
            goPage={(page: number) => {
              setParams((prev) => ({ ...prev, page }));
              // goToPage && goToPage(page);
            }}
            children={
              <>
                <BaseButton title={"닫기"} className="color-white" onClick={props.onClose} />
                <BaseButton
                  title={"엑셀 Export"}
                  onClick={() => {
                    logs.length > 0 && onDownloadAccessList();
                  }}
                />
              </>
            }
          />
        </section>
      </BaseAbstractModal>
    </>
  );
};

export default SearchAccessLog;
