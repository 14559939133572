import moment from "moment";
import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { getPartnerMembersAsync } from "src/api/partner";
import { PageMeta } from "src/api/public-types";
import BaseSelectTable from "src/components/BaseSelectTable";
import { PaPartnerMember, Partner } from "src/types/partner";
import { YmdFormat } from "src/utils";

export const columns: any = [
  {
    Header: "이메일",
    accessor: "email",
    Cell: ({ value }: Cell<PaPartnerMember>) => {
      return <div>{value}</div>;
    },
    width: 210,
  },
  {
    Header: "파트너 소속",
    accessor: "partners",
    width: 320,
    Cell: ({ value }: Cell<PaPartnerMember>) => {
      if (value.length >= 2) return value[0].name + " 외 " + (value.length - 1) + "개";
      return <div>{value.map((partner: Partner) => partner.name)}</div>;
      // return ;
      // console.log(value);
    },
  },
  {
    Header: "최근 로그인",
    accessor: "lastLoginDate",
    width: 210,
    Cell: ({ value }: Cell<PaPartnerMember>) => {
      return (
        <>
          <span className="ellipsis2">{moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)}</span>
        </>
      );
    },
  },
  {
    Header: "상태",
    accessor: "blocked",
    width: 100,
    Cell: ({ value }: Cell<PaPartnerMember>) => {
      // console.log(value);
      return <div>{value ? "비공개" : "전체공개"}</div>;
    },
  },
];

type Props = {
  onClick?: (paPartnerMember: PaPartnerMember["email"][]) => void;
  onClose?: () => void;
  defaultCheckedList?: string[];
  defaultDisabledList?: string[];
  title: string;
  selectKey: string;
  checkboxType: "checkbox" | "radio";
  min?: number;
  max?: number;
};

const SelectQuestionManagerPopup = ({
  onClick,
  onClose,
  defaultCheckedList,
  defaultDisabledList,
  title,
  selectKey,
  checkboxType,
  min,
  max,
}: Props) => {
  const [partnerMemberList, setPartnerMemberList] = useState<PaPartnerMember[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // 파트너 회원 목록 api
  const { executeAsync: getPartnerMembers } = useApiOperation(getPartnerMembersAsync);

  const [params, setParams] = useState({
    containsEmail: "",
    page: 0,
    size: 20,
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    getPartnerMembers(params).then((res) => {
      setPartnerMemberList(res?.data?.data?.content);
      setPageMeta(res?.data?.meta?.pageMeta);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultDisabledList={defaultDisabledList}
      defaultCheckedList={defaultCheckedList}
      title={title}
      selectKey={selectKey}
      columns={columns}
      data={partnerMemberList ?? []}
      checkboxType={checkboxType}
      minLength={min}
      maxLength={max}
      onClick={(checkedKeyList, checkedObjList) => onClick && onClick(checkedKeyList)}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        onSearch(value) {
          setParams({ ...params, containsEmail: value, page: 0 });
        },
      }}
    />
  );
};

export default SelectQuestionManagerPopup;
