import { useEffect, useState } from "react";
import { NavigateOptions, To, useNavigate as _useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";

/*
  이 훅은 파트너 ID를 포함한 URL로 이동하는 훅입니다.
*/
export default function useNavigate() {
  const _navigate = _useNavigate();
  const partner = useRecoilValue(globalPartnerState);

  const [isNewTab, setIsNewTab] = useState(false);

  function navigate(to: To | number, options?: NavigateOptions) {
    if (typeof to === "string") {
      const _to = to.replace(":partnerId", String(partner?.id));
      if (isNewTab) {
        window.open(_to);
      } else {
        _navigate(_to, options);
      }

      return;
    }

    if (typeof to === "number") {
      _navigate(to);
      return;
    }

    if (isNewTab) {
      if (typeof to === "object" && to.search) {
        window.open(window.location.pathname + "?" + to.search);
      }
    } else {
      _navigate(to, options);
    }
  }

  useEffect(() => {
    const handleBlur = () => {
      // console.log("blur event - window level");
      setIsNewTab(false);
    };

    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    const handleClickKeydown = (event: KeyboardEvent) => {
      // command Keydown
      if (event.key === "Meta" && event.metaKey) {
        // console.log("Keydown");
        setIsNewTab(true);
      }
    };

    const handleClickKeyup = (event: KeyboardEvent) => {
      // command Keyup
      if (event.key === "Meta" && event.metaKey === false) {
        // console.log("Keyup");
        setIsNewTab(false);
      }
    };

    const handleWheelClickMousedown = (event: any) => {
      if (event.button === 1) {
        setIsNewTab(true);
      }
    };

    const handleClickMouseup = (event: any) => {
      if (event.button === 1) {
        setIsNewTab(false);
      }
    };
    //등록
    //커맨드
    document.addEventListener("keydown", handleClickKeydown);
    document.addEventListener("keyup", handleClickKeyup);
    //마우스 휠
    document.addEventListener("mousedown", handleWheelClickMousedown);
    document.addEventListener("mouseup", handleClickMouseup);

    //제거
    return () => {
      //커맨드
      document.removeEventListener("keydown", handleClickKeydown);
      document.removeEventListener("keyup", handleClickKeyup);
      //마우스 휠
      document.removeEventListener("mousedown", handleWheelClickMousedown);
      document.removeEventListener("mouseup", handleClickMouseup);
    };
  }, []);

  return navigate;
}
