import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { getContractBuildingRooms } from "src/api/contract/contract-api";
import { ContractBuildingRoom, ContractBuildingRoomForTable, DetailTotalData } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseTooltip } from "src/components";
import { ArticleSection } from "src/components/layout/ArticleSection";

export const columns: any = [
  {
    Header: "id",
    accessor: "buildingId",
    // sticky: "left",
    Cell: ({ value }: any) => {
      return <>{value && value}</>;
    },
    width: 70,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    Cell: ({ value }: any) => {
      return <div className="minmax250 text-left ellipsis2"><BaseTooltip contents={value} /></div>
    },
    width: 250,
  },

  {
    Header: "층",
    accessor: "floorName",
    width: 250,
    Cell: ({ value, row }: any) => {
      // console.log(value);
      return (
        <>
          {row.isGround ? "지하 " : "지상 "}
          {value && value}
        </>
      );
    },
  },
  {
    Header: "호",
    accessor: "rooms",
    width: 500,
    Cell: ({ value }: any) => {
      return (
        <>
          <span className="ellipsis2">{value}</span>
        </>
      );
    },
  },
];

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}
const ContractBuildingRooms = ({ detailData }: ContractBasicProp) => {
  const { contract } = detailData;
  const { executeAsync: getRooms } = useApiOperation(getContractBuildingRooms);

  const [rooms, setRooms] = useState<Array<ContractBuildingRoomForTable>>([]);

  const concatRooms = (rooms: Array<ContractBuildingRoom>) => {
    let returnRooms: Array<ContractBuildingRoomForTable> = [];
    let tempId: string | number | undefined = "";
    rooms.forEach((room: ContractBuildingRoom) => {
      if (tempId === room.buildingId) {
        returnRooms.forEach((tableRooms: ContractBuildingRoomForTable) => {
          if (String(tempId) === String(tableRooms.buildingId)) {
            tableRooms.rooms = tableRooms.rooms + ", " + room.roomName;
          }
        });
      } else {
        tempId = room.buildingId;
        const orign = _.cloneDeep(room);
        let dump: ContractBuildingRoomForTable = { ...orign, ...{ rooms: orign.roomName } };
        returnRooms.push(dump);
      }
    });

    return returnRooms;
  };

  const getRoomsList = useCallback(
    async (contractId: number) => {
      const res2: any = await getRooms({ contractId: Number(contractId) });

      if (res2.status > 199 && res2.status < 300) {
        setRooms(concatRooms(res2.data.data.content));
      }
    },
    [getRooms],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: rooms,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  useEffect(() => {
    if (contract) {
      getRoomsList(Number(contract.contractId));
    }
  }, [contract, getRoomsList]);
  return (
    <ArticleSection
      title={"건물 정보"}
      children={
        <div className="contents-container__grid-contents">
          <div>
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div {...row.getRowProps()} className={`base-table__tr`}>
                      {row.cells &&
                        row.cells.map((cell: any, index: number) => {
                          // console.log(row.original, user);
                          // const visibleFlag = row.original.createdBy === user?.email ? false : true;

                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              <div>{cell.render("Cell")}</div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
                <div>
                  {rows.length === 0 && (
                    <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                      <div className="base-table__td w-100 text-center">
                        <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ContractBuildingRooms;
