import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postOperationFacilityAsync } from "src/api/contract/contract-facility-api";
import { ContractFacility } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { axiosInstance } from "src/utils";

/** 공용공간 생성/수정 함수
 * RCA37. 계약 - 운영정보 공용시설 등록/수정 request body { productManage:{}, productFacility: [{}] }
 * postOperationFacilityAsync
 */
const usePostOperationFacility = () => {
  const queryClient = useQueryClient();

  const { executeAsync } = useApiOperation(postOperationFacilityAsync);

  const query = useMutation({
    // mutationFn: async (params: { contractId: number; productFacilityList: ContractFacility[] }) => postOperationFacilityAsync(axiosInstance, params),
    mutationFn: executeAsync,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { ...query };
};

export default usePostOperationFacility;
