import React from "react";
import { Cell } from "react-table";
import { ProductModel } from "src/api/product/product-types";
import { BaseTooltip } from "src/components/BaseTooltip";

const ProductDateCell = (props: Cell<ProductModel>) => {
  return <BaseTooltip contents={props?.value} type="date" />;
};

export default ProductDateCell;
