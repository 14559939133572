import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { ProductModel } from "src/api/product/product-types";
import { PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BasePagination, BaseRadio, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import BaseScroll from "src/components/BaseScroll";
import useApiLoading from "src/hooks/useApiLoading";

const columnHeader: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    // sticky: "left",
    width: 100,
    Cell: ({ value, row, setSelected }: any) => {
      const changeTrigger = useCallback(() => {
        setSelected(row.original);
      }, [row.original, setSelected]);
      return (
        <div>
          <BaseRadio id={`selector${row.original.id}`} name={"isSelected"} onChange={changeTrigger} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 120,
  },

  {
    Header: "건물명",
    accessor: "buildingName", // api 스펙에 없음
    width: 720,
    Cell: ({ value, row, setSelected }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
];
type Props = {
  isOpen: boolean;
  buildingName: string;
  partnerId?: string;
  rightBtnName?: string;
  onClick?: (building: BuildingModel) => void;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchBuildingPopup = (props: Props) => {
  const [selected, setSelected] = useState<any>();

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);
  const [keyword, setKeyword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const { isApiLoading } = useApiLoading();

  const fetchBuilding = useCallback(
    async (param: any) => {
      return await getBuildings(param);
    },
    [getBuildings],
  );
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,
    keyword: "",
    // status: 'ENABLED',
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: products,
      setSelected,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const onClickData = useCallback(async () => {
    if (props.onClick) props.onClick(selected);
  }, [props, selected]);

  const closeData = useCallback(async () => {
    if (props.onClose) props.onClose(selected);
  }, [props]);

  const callList = useCallback(
    async (param: any) => {
      if (props.buildingName === undefined) {
        delete param.buildingName;
      }

      const response: any = await fetchBuilding(param);
      if (response.status > 199 && response.status < 300) {
        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
        }
        setProducts(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      }
    },
    [fetchBuilding],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.page = 0;
      // prs.keyword = props.buildingName !== undefined && props.buildingName !== "" ? decodeURIComponent(props.buildingName) : "";
      // props.buildingName !== undefined && props.buildingName !== "" ? setKeyword(decodeURIComponent(props.buildingName)) : setKeyword("");
      if (props.partnerId) {
        prs.partnerId = props.partnerId;
      }
      setKeyword("");
      callList(prs);
      setParams(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={props.isOpen}>
      <section className="base-abstract-modal__title">
        <div className="flex-row flex-center-between w-100">
          <h1>건물 선택</h1>

          <div className="minmax240 text-left">
            <BaseInput
              type="text"
              value={keyword || ""}
              placeholder="검색어를 입력해주세요"
              onKeyUp={() => {
                callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
              }}
              onChange={(value: string) => {
                setKeyword(value);
              }}
              onSearchClick={() => {
                callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
              }}
              onClearClick={() => {
                setKeyword("");
                setParams({ ...params, ...{ keyword: "" }, ...{ page: 0 } });
              }}
            />
          </div>
        </div>
      </section>
      <section className="base-abstract-modal__contents">
        {/* table */}

        <div {...getTableProps()} className="base-table view-data-table sticky px30">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <BaseModal isOpen={error} btnRightTitle={"확인"} onClick={() => setError(false)}>
          <p>{message}</p>
        </BaseModal>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={params.page || 0}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(sizeValue) => {
            setParams({ ...params, size: sizeValue });
            callList({ ...params, size: sizeValue });
          }}
          totalElements={pageMeta?.totalElements || 0}
          goPage={(page: number) => {
            setParams({ ...params, ...{ page } });
            callList({ ...params, ...{ page } });
          }}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => closeData()}>
                취소
              </button>
              <button className="base-btn" onClick={() => onClickData()}>
                {props.rightBtnName ? props.rightBtnName : "선택"}
              </button>
            </div>
          }
        />
      </section>
    </BaseAbstractModal>
  );
};

export default SearchBuildingPopup;
