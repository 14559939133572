interface BaseProp {
  title?: string;
  value?: string;
}
const TableTextCell = ({ title, value }: BaseProp) => {
  return (
    <tr>
      <td width="180">
        <div className="flex-center pl16">
          <span className="text-gray600">{title && title!}</span>
        </div>
      </td>
      <td>
        <div className="w-100 flex-center-start">{value && value!}</div>
      </td>
    </tr>
  );
};
export default TableTextCell;
