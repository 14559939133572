import React, { Dispatch, memo, SetStateAction, useMemo, useRef, useState } from "react";
import { uploadFilesAsync } from "src/api/file/file-api";
import { FileData } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile, MediaServiceType } from "src/api/public-types";
//
import { BaseInput } from "./BaseInput";
import { BaseModal } from "./BaseModal";
import { BaseRadio } from "./BaseRadio";
import { ResizedImage } from "./ResizedImage";
import { IMAGE_ACCEPT } from "src/vars";

interface Props {
  onChangeMediaFiles: (mediaFiles: Array<MediaFile>) => void; // MediaFile 배열 변경 callback 함수
  mediaFiles?: Array<MediaFile>; // 기존에 저장되어있는 mediaFile 이미지 배열
  categoryName?: string; // 여러 ImageUpdate 컴포넌트 사용시 구분할 수 있는 카테고리 명칭
  uploadType?: "public" | "private"; // public: 아마존 S3 저장, private: PV 저장
  fileMaxLength?: number; // 이미지 최대 저장 length
  isUsedRepresentative?: boolean; // 대표 사용여부
  isUsedSelfDescription?: boolean; // 직적입력 설명 사용여부
  isUsedDescription?: boolean; // 설명 사용여부
  className?: string; // TODO: 사용하는 곳 없음 ?
  deleteIds?: Array<string>;
  setDeleteIds?: Dispatch<SetStateAction<Array<string>>>;
  mediaServiceType?: MediaServiceType;
  serviceId?: string;
}

type DragDropState = {
  draggedFrom: number | null;
  draggedTo: number | null;
  isDragging: boolean;
  originalOrder: MediaFile[];
  updatedOrder: MediaFile[];
};

const s3Url = process.env.REACT_APP_S3_BASEURL;

/* 
  이미지 수정 공통 컴포넌트
*/
const ImagesUpdate = memo(
  ({
    onChangeMediaFiles,
    mediaFiles,
    categoryName,
    uploadType,
    fileMaxLength = 10,
    isUsedRepresentative,
    isUsedSelfDescription,
    isUsedDescription,
    deleteIds,
    setDeleteIds,
    serviceId,
    mediaServiceType,
  }: Props) => {
    //

    const fileRef = useRef<HTMLInputElement>(null);

    // 파일 업로드 api hook
    const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync, {
      noHandleError: true,
    });

    // 파일 제한 갯수 초과시 띄우는 모달
    const [modalOpen, setModalOpen] = useState(false);

    // 드래그로 변경될 내용들을 담아줄 객체
    const [dragAndDrop, setDragAndDrop] = useState<DragDropState>({
      draggedFrom: null, //드래그가 시작하는 index
      draggedTo: null, // 변경될 드래그 index
      isDragging: false,
      originalOrder: [],
      updatedOrder: [],
    });

    // input file 이 변경될때 실행
    const changeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
      //

      // input file 객체
      const files = event.target.files;

      // 파일 업로드 response media 배열
      const fileDatas: Array<FileData> = [];

      if (files) {
        if (fileMaxLength) {
          // 파일 제한 갯수가 있으면, 파일 갯수 > 파일 제한 갯수
          const totalLength = files.length + (mediaFiles || []).length;
          if (totalLength > fileMaxLength) {
            setModalOpen(true);
            //
            return;
          }
        }

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          const formData = new FormData();
          formData.append("file", file);

          // 파일 업로드
          const res = await postImageFile({
            file: formData,
            type: uploadType,
          });
          if (res.status >= 200 && res.status <= 299 && res?.data?.data?.media) {
            const data: FileData = res.data.data.media;

            fileDatas.push(data);
          } else {
            throw Error("파일 업로드를 실패했습니다.");
          }
        }

        const convertedMediaFiles = convertMediaFiles(fileDatas);
        // 기존 파일 + 업로드한 파일
        const concat = [...(mediaFiles || []), ...convertedMediaFiles];

        const addIsPrimaryInMedias = concat.map((item, idx) => ({
          ...item,
          isPrimary: item.isPrimary || (idx === 0 && !concat.some((item) => item.isPrimary)),
        }));

        onChangeMediaFiles(addIsPrimaryInMedias);
      }

      // 파일 객체 초기화. (같은 파일 업로드시 작동되게)
      event.target.value = "";

      //
    };

    // 업로드한 FileData -> MediaFile 로 변환
    const convertMediaFiles = (fileDatas: Array<FileData>) => {
      const count = (mediaFiles || []).length;
      const convertedMediaFiles: Array<MediaFile> = fileDatas.map((fileData: FileData, idx: number) => {
        const mediaFile: MediaFile = {
          // id: Math.random().toString(36).substr(2, 9),
          description: "",
          fileStorageType: fileData.fileStorageType,
          key: fileData.key,
          filename: fileData.filename,
          contentType: fileData.contentType,
          fileSize: fileData.fileSize,
          isPrimary: false,
          orderNums: idx + 1 + count, // orderNums 1부터 시작
          mediaServiceType: mediaServiceType ? mediaServiceType : "MEDIASERVICE_UNRECOGNIZED",
          serviceId: String(serviceId || ""),
        };
        return mediaFile;
      });
      return convertedMediaFiles;
    };

    // 이미지 삭제
    const removeMediaFile = (idx: number, deleteId?: string) => {
      const _mediaFiles = [...(mediaFiles || [])];
      _mediaFiles.splice(idx, 1);
      if (deleteIds && setDeleteIds && deleteId) {
        setDeleteIds([...deleteIds, deleteId]);
      }
      onChangeMediaFiles(_mediaFiles);
    };

    // 사용자가 객체(object)를 드래그하려고 시작할 때 발생함.
    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.style.opacity = "0.4";
      const initialPosition = Number(event.currentTarget.dataset.position);

      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition,
        originalOrder: mediaFiles || [],
      });
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      let newList = dragAndDrop.originalOrder;
      const draggedFrom = dragAndDrop.draggedFrom; // 드래그 되는 항목의 인덱스(시작)
      const draggedTo = Number(event.currentTarget.dataset.position); // 놓을 수 있는 영역의 인덱스(끝)
      const itemDragged = newList[draggedFrom || 0]; //

      const remainingItems = newList.filter((item, idx) => idx !== draggedFrom); //draggedFrom(시작) 항목 제외한 배열 목록

      newList = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)];

      // 놓을 수 있는 영역이 변경 되면 객체를 변경해줌
      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    };

    // onDrop 잡은 Item을 적절한 곳에 놓았을 때 발생하는 이벤트 (다른 item이랑 겹쳐졌을 때)
    const onDrop = () => {
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
      });

      // orderNums 순서 재할당
      const updatedOrderMediaFiles = dragAndDrop.updatedOrder.map((value: MediaFile, idx: number) => Object.assign(value, { orderNums: idx + 1 }));
      onChangeMediaFiles(updatedOrderMediaFiles);
    };

    // onDragLeave 범위를 벗어나면 발생하는 이벤트
    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.classList.remove("over");
      setDragAndDrop({
        ...dragAndDrop,
        draggedTo: null,
      });
    };

    // 다른 item이랑 겹쳐졌을 때 발생하는 이벤트 (1번만)
    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.classList.add("over");
    };

    // 잡은 Item을 놓았을 때 발생하는 이벤트 (다른 item이랑 겹치지 않아도 발생함)
    const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
      event.currentTarget.style.opacity = "1";

      const listItems = document.querySelectorAll(".draggable");
      listItems.forEach((item) => {
        item.classList.remove("over");
      });
    };

    // 이미지 설명 변경
    const changeDesc = (value: string, idx: number) => {
      const files = [...(mediaFiles || [])];
      files[idx].description = value;
      onChangeMediaFiles(files);
    };

    // 이미지 설명 직접입력 부분 변경
    const changeSelfDesc = (value: string, idx: number) => {
      const files = [...(mediaFiles || [])];
      // files[idx].selfDescription = value;
      files[idx].category1 = value;

      onChangeMediaFiles(files);
    };

    // 이미지 대표 변경
    const changeIsPrimary = (checked: boolean, idx: number) => {
      const files = [...(mediaFiles || [])].map((mediaFile: MediaFile, _idx: number) => {
        if (_idx === idx) {
          return Object.assign(mediaFile, { isPrimary: checked });
        } else {
          return Object.assign(mediaFile, { isPrimary: !checked });
        }
      });
      onChangeMediaFiles(files);
    };

    return (
      <div className="flex-row justify-contents-start align-items-center overflow-scroll mb10">
        <div className="image-scroll">
          {mediaFiles &&
            mediaFiles.map((item: MediaFile, idx: number) => {
              return (
                <div
                  className={`image-container ${isUsedSelfDescription && isUsedDescription ? "two-input-case" : ""}${
                    !isUsedSelfDescription && !isUsedDescription ? "only-img" : ""
                  }`}
                  key={idx}
                  draggable={true}
                  data-position={idx}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  onDragEnter={onDragEnter}
                  onDragEnd={onDragEnd}
                >
                  <div className="image-container__img-wrap">
                    <span className="index-number">{idx + 1}</span>
                    {/* 이미지 */}
                    <ResizedImage url={s3Url + (item?.key || "")} />
                    <button type="button" className="image-container__erase-btn" onClick={() => removeMediaFile(idx, String(item.id))}></button>

                    {/* 대표 사용여부 true 일 경우에만 노출 */}
                    {isUsedRepresentative && (
                      <BaseRadio
                        id={categoryName ? `${categoryName}_radio_${idx}` : `representative_radio_${idx}`}
                        name={categoryName ? `${categoryName}_radio` : `representative_radio`}
                        className="chip-case"
                        checked={item.isPrimary}
                        onChange={(checked: boolean) => changeIsPrimary(checked, idx)}
                        label="대표"
                      />
                    )}
                  </div>

                  {/* 직접설명 사용여부 true 또는 설명 사용여부 true 일 경우 노출 */}
                  {(isUsedSelfDescription || isUsedDescription) && (
                    <div>
                      {/* 직접설명 사용여부 true 일 경우에만 노출 */}
                      {isUsedSelfDescription && (
                        <BaseInput
                          className="mt8"
                          type="text"
                          placeholder="직접입력"
                          // value={item.selfDescription}
                          value={item.category1}
                          onChange={(value: string) => changeSelfDesc(value, idx)}
                        />
                      )}

                      {/* 설명 사용여부 true 일 경우에만 노출 */}
                      {isUsedDescription && (
                        <BaseInput
                          className="mt8"
                          type="text"
                          placeholder="이미지 설명을 적어주세요"
                          value={item.description}
                          onChange={(value: string) => changeDesc(value, idx)}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}

          <div
            className={
              "image-container empty" +
              (isUsedSelfDescription && isUsedDescription ? " two-input-case" : "") +
              (!isUsedSelfDescription && !isUsedDescription ? " only-img" : "") +
              (fileMaxLength && (mediaFiles || []).length === fileMaxLength ? " d-none" : "") // 파일 제한 갯수랑 동일한 갯수면 추가 못하게
            }
          >
            <input ref={fileRef} type="file" style={{ display: "none" }} accept={IMAGE_ACCEPT} onChange={changeFile} multiple />

            <button type="button" className="image-container__plus-btn" onClick={() => fileRef.current?.click()} />
          </div>
        </div>
        {modalOpen && (
          <BaseModal isOpen={true} btnRightTitle="확인" onClick={() => setModalOpen(false)}>
            <p>이미지 파일은 최대 {fileMaxLength}개 까지 등록이 가능합니다.</p>
          </BaseModal>
        )}
      </div>
    );
  },
);

export default React.memo(ImagesUpdate);
