import { numberToStringWithComma } from "src/utils";

export const columns: any = [
  {
    Header: "id",
    sticky: "left",
    accessor: "itemId",
    columns: [
      {
        Header: "",
        accessor: "itemId",
        width: 90,
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && <div className="ic_ho"></div>}
          {value && value}
        </>
      );
    },
    Footer: ({ row }: any) => {},

    width: 60,
  },
  {
    Header: "부과항목",
    accessor: "itemName",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "itemName",
      },
    ],
    Cell: ({ value, row }: any) => {
      return (
        <>
          {!value && ""}
          {value && row.original.priceUnitName + " / " + row.original.roundingModeName}
        </>
      );
    },
    Footer: ({ row }: any) => {},
    width: 80,
  },
  {
    Header: () => {
      return (
        <p>
          부과기준
          <br />
          (부과방식/산정기준)
        </p>
      );
    },
    accessor: "priceUnit",
    sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "priceUnitName",
        Cell: ({ value, row }: any) => {
          return (
            <>
              {!value && ""}
              {value && row.original.priceUnitName + " / " + row.original.roundingModeName}
            </>
          );
        },
        Footer: ({ row }: any) => {
          return <p>합계 :</p>;
        },
      },
    ],
    Footer: ({ row }: any) => {},
    width: 80,
  },
  {
    Header: () => {
      return (
        <p>
          부과방법
          <br />
          (단위범위/반올림)
        </p>
      );
    },
    accessor: "level1",
    sticky: "",
    columns: [
      {
        Header: "",
        accessor: "level1Name",
        width: 120,
        sticky: "",
        Cell: ({ value, row }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {value && row.original.level1Name + " / " + row.original.level2Name}
              </span>
            </>
          );
        },
        Footer: ({ row }: any) => {},
      },
    ],
    Footer: ({ row }: any) => {},
  },
  {
    Header: "당월",
    accessor: "ctMntChartItem",
    sticky: "",
    Footer: ({ row }: any) => {},
    columns: [
      {
        Header: "발생금액",
        accessor: "ctMntChartItem.occurSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ value, row }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctMntChartItem.occurSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "부과금액",
        accessor: "ctMntChartItem.impsSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctMntChartItem.impsSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "차액",
        accessor: "ctMntChartItem.diffSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctMntChartItem.diffSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
    ],
  },
  {
    Header: "전월",
    accessor: "ctPrevMntChartItem",
    sticky: "",
    Footer: ({ row }: any) => {},
    columns: [
      {
        Header: "발생금액",
        accessor: "ctPrevMntChartItem.occurSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ value, row }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctPrevMntChartItem.occurSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "부과금액",
        accessor: "ctPrevMntChartItem.impsSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctPrevMntChartItem.impsSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
      {
        Header: "차액",
        accessor: "ctPrevMntChartItem.diffSupplyAmount",
        sticky: "",
        width: 120,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.ctPrevMntChartItem.diffSupplyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
    ],
  },
  {
    Header: () => {
      return (
        <p>
          월간차액
          <br />
          (단위범위/반올림)
        </p>
      );
    },
    accessor: "diffMonthlyAmount",
    sticky: "",
    width: 120,
    Footer: ({ row }: any) => {},
    columns: [
      {
        Header: "",
        accessor: "diffMonthlyAmount",
        sticky: "",
        Cell: ({ row, value }: any) => {
          return (
            <>
              <p>{numberToStringWithComma(value)}</p>
            </>
          );
        },
        Footer: ({ rows }: any) => {
          let sum = 0;
          rows.map((row: any) => {
            if (row.original.ctMntChartItem.isDeleted === false) {
              sum = Number(sum) + Number(row.original.diffMonthlyAmount);
            }
          });
          return <p>{numberToStringWithComma(sum)}</p>;
        },
      },
    ],
  },
];
