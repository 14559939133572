import { useCallback, useEffect, useState } from "react";
import { DetailTotalData } from "src/api/contract/contract-types";
import ContractBillingSchedule from "src/pages/contract/commonComponents/ContractBillingSchedule";
import ContractInfo from "src/pages/contract/commonComponents/ContractInfo";
import ContractorAndOrganization from "src/pages/contract/commonComponents/ContractorAndOrganization";
import EstimateInfo from "src/pages/contract/commonComponents/EstimateInfo";
import ExpenseDocumentationDetail from "src/pages/contract/commonComponents/detailComponent/ExpenseDocumentationDetail";
import useContractApi from "src/pages/contract/hooks/useContractApi";
// import RefundInfoDetail from "src/pages/contract/commonComponents/detailComponent/RefundInfoDetail";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setData: Function;
}

const ServiceType = ({ detailData, setData }: ContractBasicProp) => {
  const { getSchedulesRounds } = useContractApi();
  const [showExpense, setShowExpense] = useState(false);
  const [s2Rounds, setS2Rounds] = useState<any>(null);
  const findRounds = useCallback(
    async (scheduleId: string) => {
      const getRounds: any = await getSchedulesRounds(scheduleId);
      if (Array.isArray(getRounds) && getRounds.length > 0) {
        setS2Rounds(getRounds[0]);
      }
    },
    [getSchedulesRounds],
  );
  useEffect(() => {
    if (detailData.scheduleList) {
      const rental: any = detailData.scheduleList.find((rental: any) => rental.supplyType === "RENTAL");

      if (rental) {
        findRounds(rental?.scheduleId!);
      }
    }
  }, [detailData, findRounds]);

  return (
    <>
      <ContractorAndOrganization detailData={detailData} setData={setData} />
      <ContractInfo detailData={detailData} setData={setData} />
      <EstimateInfo detailData={detailData} />
      <ContractBillingSchedule detailData={detailData} setData={setData} />
      {s2Rounds !== null && s2Rounds?.bill?.pgcode === "virtualaccount" && <ExpenseDocumentationDetail detailData={detailData} />}
      {/* <RefundInfoDetail detailData={detailData} isAuthorityReadonly={isAuthorityReadonly} /> */}
      {/* <ContractBillingScheduleNoti detailData={detailData} setData={setData} /> */}
    </>
  );
};

export default ServiceType;
