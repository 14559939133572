import { ArticleSection } from "src/components/layout/ArticleSection";
import EstimateDetail from "./detailComponent/EstimateDetail";
interface BaseProps {
  detailData: any;
  viewOnly?: boolean;
}

const EstimateInfo = ({ detailData, viewOnly }: BaseProps) => {
  return <ArticleSection title={"견적서 정보"} children={<EstimateDetail detailData={detailData} viewOnly={viewOnly} />} />;
};
export default EstimateInfo;
