import { useCallback, useEffect, useState } from "react";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import RegistrationInformation from "src/components/RegistrationInformation";
import FullCourtInfo from "./FullCourtInfo";
import ServiceCourtInfo from "./ServiceCourtInfo";

const ScheduleBasicInfo = ({ contractDetail }: any) => {
  const { scheduleList } = contractDetail;
  const [rentalSchedule, setRentalSchedule] = useState<any>({});
  const [totalCount, setTotalCount] = useState(0);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);

  /**
   * 선택한 건물 스케줄의 청구서 목록을 조회
   */
  const getRounds = useCallback(
    async (scheduleId: string) => {
      let count = 0;
      const rounds = await getRoundList({ scheduleId: Number(scheduleId) });
      console.log("rounds", rounds);
      if (rounds.data.data.content.length) {
        const payList = rounds.data.data.content.filter((sd: any) => Number(sd.bill.parentBillId) === 0);
        count = payList.length;
      }
      setTotalCount(count);
    },
    [getRoundList],
  );

  useEffect(() => {
    if (scheduleList) {
      let scheduleData = scheduleList && scheduleList.length > 0 ? scheduleList!.find((sd: any) => sd.supplyType === "RENTAL") : "";
      if (scheduleData !== "") {
        scheduleData.createdBy = "system";
        scheduleData.modifiedBy = "system";
        setRentalSchedule(scheduleData);
        getRounds(scheduleData!.scheduleId!);
      }
    }
  }, [getRounds, scheduleList]);

  return (
    <div>
      <MetaTag subTitle={contractDetail.contractManage?.contractApplyNumber} />
      <div className="contents-container__scroll">
        {contractDetail?.product?.productType !== "TIME_COURT" ? (
          <FullCourtInfo contractDetail={contractDetail} scheduleList={scheduleList} rentalSchedule={rentalSchedule} totalCount={totalCount} />
        ) : (
          <ServiceCourtInfo contractDetail={contractDetail} scheduleList={scheduleList} rentalSchedule={rentalSchedule} totalCount={totalCount} />
        )}
        <RegistrationInformation data={rentalSchedule} />

        <AdminMemoV3 serviceId={Number(contractDetail?.contract?.contractId)} serviceType={ServiceTypes.SERVICE_BILLING_SCHEDULE} />
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
      </div>
    </div>
  );
};

export default ScheduleBasicInfo;
