import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { getGuideListAsync } from "src/api/guide/guide-api";
import { UserGuideModel, UserGuideType, UserGuideTypeMap } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";

import { BaseTooltip } from "src/components";
import BaseSelectTable from "src/components/BaseSelectTable";
import { guideTypeOptions } from "src/pages/guide/guide-types";
import { PagePath } from "src/pages/product/details";
import BaseNewTabLink from "../BaseNewTabLink";

const columns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ value }: { value?: string }) => {
      if (!value) {
        return "-";
      }
      return <BaseNewTabLink path={PagePath.guide.detail.replace(":id", value)} value={value} ellipsis className="ml16" />;
    },
  },

  {
    Header: "유형",
    accessor: "type",
    width: 200,
    Cell: ({ value }: Cell<UserGuideModel>) => {
      return <span>{UserGuideTypeMap[value as UserGuideType]}</span>;
    },
  },
  {
    Header: "항목명",
    accessor: "subject",
    width: 600,
    Cell: ({ value }: Cell<UserGuideModel>) => {
      return <BaseTooltip contents={value} className="w-100" />;
    },
  },
];
type Props = {
  partnerId?: string; //TODO: 필요 없으면 관련코드 제거 해주세요.
  onClick?: (data: string[]) => void;
  onClose?: () => void;
  providerId?: string;
  defaultDisabledList?: string[];
};

const SelectGuidePopup = ({ partnerId, onClick, onClose, defaultDisabledList, providerId }: Props) => {
  const [guideList, setGuideList] = useState<UserGuideModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const { executeAsync: getGuideList } = useApiOperation(getGuideListAsync);

  const [params, setParams] = useState({
    providerId: providerId,
    page: 0,
    size: 20,
    keywordList: "",
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const getGuideListRes = await getGuideList(params);
      // 취소 상태 제거

      setGuideList(getGuideListRes?.data?.data?.content ?? []);
      setPageMeta(getGuideListRes?.data?.meta?.pageMeta);
    };

    fetchApi();
  }, [params]);

  console.log("defaultDisabledList :>> ", defaultDisabledList);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultDisabledList={defaultDisabledList}
      title="항목 추가"
      selectKey={"id"}
      columns={columns}
      data={guideList ?? []}
      checkboxType={"checkbox"}
      onClick={(checkedKeyList) => onClick && onClick(checkedKeyList)}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        placeholder: "항목명을 입력하세요",
        searchOptionPlaceholder: "유형",
        searchOptionList: guideTypeOptions,
        onSearch(value, searchOption) {
          setParams((prev) => ({ ...prev, page: 0, keywordList: value, type: searchOption }));
        },
      }}
    />
  );
};

export default SelectGuidePopup;
