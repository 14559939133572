import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { DeskGroupModel } from "src/api/building/building-types";
import { deleteDeskChartAsync, getDeskgroupsAsync } from "src/api/building/deskgroup-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal, BaseSelect, BaseToggle } from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
// import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import { Modal, SelectOption, sortingDeskGroupLayoutList } from "../../building-types";
import DeskLayout from "./components/DeskLayout";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

/* 
  건물 > 등록 or 수정 > 좌석 배치도
 */
const SeatingChartForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 로딩바
  //
  // 토스트
  // const { openToast } = useToast();

  // 좌석 그룹 목록 조회 api
  const { executeAsync: getDeskgroups } = useApiOperation(getDeskgroupsAsync);

  // 좌석배치도 삭제 api
  const { executeAsync: deleteDeskChart } = useApiOperation(deleteDeskChartAsync);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 건물 id
  const buildingId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 선택되어있는 좌석 그룹 id
  const [selectedDeskGroupId, setSelectedDeskGroupId] = useState<string>("");

  // 좌석 그룹 목록
  const [deskGroups, setDeskGroups] = useState<Array<DeskGroupModel>>([]);

  // 선택되어있는 좌석 그룹의 활성 여부
  const [isActiveDeskGroup, setIsActiveDeskGroup] = useState<boolean>(false);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 좌석 그룹 목록 조회
  const fetchDeskgroups = useCallback(
    async (buildingId: number | string, deskGroupId?: string) => {
      //
      const { data } = await getDeskgroups({ buildingId: Number(buildingId) });
      const deskGroups = data?.data?.content || [];
      const sortedDeskGroups = sortingDeskGroupLayoutList(deskGroups || []);
      setDeskGroups(sortedDeskGroups);

      if (deskGroupId) {
        setSelectedDeskGroupId(deskGroupId);
      } else {
        if (!selectedDeskGroupId && sortedDeskGroups.length > 0) {
          // 선택되어있는 좌석그룹이 없을 때 첫번째 좌석그룹 선택되어있게
          const firstDeskGroup = sortedDeskGroups[0];
          setSelectedDeskGroupId(firstDeskGroup.id);
        }
      }

      //
    },
    [getDeskgroups, selectedDeskGroupId],
  );

  useEffect(() => {
    if (buildingId) {
      fetchDeskgroups(buildingId, String(queryParams?.deskGroupId || ""));
    }
  }, [buildingId, fetchDeskgroups, queryParams]);

  // 건물 좌석 그룹 셀렉트 박스 옵션 목록
  const deskGroupOptions = useMemo(() => {
    const options: Array<SelectOption> = deskGroups.map((deskGroup: DeskGroupModel) => {
      return {
        value: String(deskGroup.id || ""),
        label: String(deskGroup.groupName || ""),
      };
    });
    return options;
  }, [deskGroups]);

  // 선택되어있는 좌석 그룹 상세
  const selectedDeskGroup: DeskGroupModel | undefined = useMemo(() => {
    return deskGroups.find((deskGroup: DeskGroupModel) => deskGroup.id === selectedDeskGroupId);
  }, [deskGroups, selectedDeskGroupId]);

  useEffect(() => {
    if (selectedDeskGroup) {
      if ((selectedDeskGroup?.mediaList || []).length > 0) {
        // 선택되어있는 좌석 그룹의 배치도 배경 이미지 파일이 있을 경우 활성화
        setIsActiveDeskGroup(true);
      } else {
        setIsActiveDeskGroup(false);
      }
    } else {
      setIsActiveDeskGroup(false);
    }
  }, [selectedDeskGroup]);

  // 좌석 그룹 활성화 버튼 클릭
  const onChangeIsActiveDeskGroup = useCallback(
    (isActive: boolean) => {
      if (!selectedDeskGroupId) {
        setAlertModal({ isOpen: true, message: "좌석 그룹을 선택해주세요." });
        return;
      }
      if (isActive) {
        setIsActiveDeskGroup(true);
      } else {
        setConfirmModal({
          isOpen: true,
          title: "정보가 초기화되어 저장됩니다.",
          type: "DESK_LAYOUT_DELETE",
        });
      }
    },
    [selectedDeskGroupId],
  );

  // form 리셋
  const resetForm = useCallback(() => {
    setDeskGroups([]);
  }, []);

  // 좌석 그룹의 좌석배치도 등록정보 삭제 (비활성)
  const deleteAllDeskLayout = useCallback(async () => {
    if (!buildingId) throw Error("buildingId is not found");
    if (!selectedDeskGroupId) throw Error("selectedDeskGroupId is not found");

    const { data } = await deleteDeskChart({ buildingId, deskGroupId: selectedDeskGroupId });
    if (data?.data?.content) {
      // openToast("정상적으로 초기화 저장 되었습니다.");

      resetForm();

      // 좌석 그룹 목록 다시 조회
      fetchDeskgroups(buildingId);
    }
  }, [deleteDeskChart, buildingId, selectedDeskGroupId, resetForm, fetchDeskgroups]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    if (confirmModal.type === "DESK_LAYOUT_DELETE") {
      // 좌석 그룹의 좌석배치도 등록정보 삭제 (비활성)
      await deleteAllDeskLayout();
    }
  }, [confirmModal]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {deskGroupOptions.length === 0 ? (
              <>
                {/* 등록된 공용공간 좌석 그룹이 없을 경우 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">좌석 그룹</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <span className="font14">데이터가 없습니다.</span>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">좌석 그룹</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-center-start flex-row">
                      <div className="minmax200">
                        <BaseSelect
                          value={selectedDeskGroupId}
                          stateOptions={deskGroupOptions}
                          setStateValue={(value: string) => {
                            const formPath = `${PagePath.building.form}?id=${buildingId}&tab=seatingChart&deskGroupId=${value}`;
                            navigate(formPath);
                          }}
                        />
                      </div>
                      <div className="ml10">
                        <BaseToggle checked={isActiveDeskGroup} onChange={(isActive: boolean) => onChangeIsActiveDeskGroup(isActive)} />
                      </div>
                    </div>
                  </div>
                </section>

                {/* 선택한 좌석 그룹 활성화시 노출 */}
                {isActiveDeskGroup && <DeskLayout buildingId={buildingId} deskGroup={selectedDeskGroup} />}
              </>
            )}
          </article>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            title={confirmModal.title}
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            {/* {confirmModal.message && <p>{confirmModal.message}</p>} */}
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal isOpen={alertModal.isOpen} title={alertModal.title} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
            {/* <p>{alertModal.message}</p> */}
          </BaseModal>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          <BaseButton
            title={"수정취소"}
            className="color-white size-large"
            onClick={() => {
              navigate(`${PagePath.building.detail.replace(":id", String(buildingId))}?tab=seatingChart`);
            }}
          />{" "}
        </div>
      </div>
    </>
  );
};
export default SeatingChartForm;
