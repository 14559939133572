import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { SearchParams, searchStatusOption, searchTypeOption } from "src/api/billingSchedule/billing-type";
import { getScheduleList } from "src/api/billingSchedule/billingSchedule-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { Order, PageMeta, Select } from "src/api/public-types";
import { BaseInput, BaseInputWithSearch, BasePagination, BaseRadio, BaseSelect, BaseTable, BaseTooltip } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import RangeDatepicker from "src/components/RangeDatepicker";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import SearchOrgPopup from "src/pages/commonPopup/SearchOrgPopup";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { ViewYmdFormat, YmdFormat, qsParse } from "src/utils/common-util";
import BillIngScheduleColumns from "./columns/Columns";

const orgColumns: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 80,
    Cell: ({ row, setSelectedOrg }: any) => {
      const changeTrigger = useCallback(() => {
        setSelectedOrg(row.original);
      }, [row.original, setSelectedOrg]);

      return <BaseRadio id={`selector${row.original.id}`} name={"isSelected"} onChange={changeTrigger} value={row.original.isSeleted} />;
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 120,
  },
  {
    Header: "법인명/상호",
    accessor: "name",
    width: 720,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
];
const searchStatusOption2: Select[] = [
  { label: "전체", value: "" },
  // { label: "신청접수", value: ContractStep.APPLY_RECEIVED },
  { label: "신청취소", value: ContractStep.APPLY_CANCEL },
  { label: "신청확인", value: ContractStep.APPLY_CONFIRM },
  { label: "계약예정", value: ContractStep.CONTRACT_PLAN },
  { label: "계약체결", value: ContractStep.CONTRACT_ACCEPT },
  { label: "이용승인", value: ContractStep.USE_APPROVAL },
  { label: "이용중", value: ContractStep.USE_PROGRESS },
  { label: "이용완료", value: ContractStep.USE_COMPLETE },
  { label: "해지접수", value: ContractStep.TERMINATE_RECEIVED },
  { label: "해지완료", value: ContractStep.TERMINATE_COMPLETE },
];
const BillingScheduleList = () => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const { openErrorModal } = useErrorModal();
  const navigate = useNavigate();
  const location = useLocation();
  const { executeAsync } = useApiOperation(getScheduleList, { noHandleError: true });
  const { executeAsync: getOrgListApi } = useApiOperation(getOrgList);
  const [data, setData] = useState<any>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [building, setBuilding] = useState<any>({ buildingName: "" });
  const [isBuildingOpen, setIsBuildingOpen] = useState(false);
  const [org, setOrg] = useState<any>({ name: "" });
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [partners, setPartners] = useState<any>([]);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParam: SearchParams = useMemo(() => {
    // console.log('location.search',location.search);
    const _queryParams: any = qsParse(location.search);
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "modifiedDate", direction: "DESC" }],
      };
    }
    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = "";
    }

    if (!_queryParams?.searchType) {
      _queryParams.searchType = "";
    }

    if (!_queryParams?.isOverdue) {
      _queryParams.isOverdue = "";
    }

    if (String(_queryParams?.isOverdue) === "true") {
      _queryParams.isOverdue = "true";
    }

    if (String(_queryParams?.isOverdue) === "false") {
      _queryParams.isOverdue = "false";
    }

    if (!_queryParams?.billPayStatus) {
      _queryParams.billPayStatus = "";
    }
    if (_queryParams.buildingName === undefined || _queryParams.buildingName === "") {
      _queryParams.buildingName = "";
      _queryParams.buildingId = undefined;
      // delete _queryParams.buildingId;
      // setBuilding({buildingName : "" , buildingId: undefined});
    }
    console.log("_queryParams.orgName", _queryParams.orgName);
    if (_queryParams.orgName === undefined || _queryParams.orgName === "") {
      _queryParams.orgName = "";

      _queryParams.mbOrganizationId = undefined;
      // delete _queryParams.mbOrganizationId
      // setOrg({orgName : "", mbOrganizationId: undefined});
    }

    const dump = _.cloneDeep(dateRange);
    // console.log("searchStartTime, searchEndTime", _queryParams?.searchStartTime, _queryParams?.searchEndTime);
    if (_queryParams?.searchStartTime !== undefined && _queryParams?.searchStartTime !== "") {
      let st = moment(_queryParams?.searchStartTime, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD");
      dump[0] = moment(st).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.searchEndTime !== undefined && _queryParams?.searchEndTime !== "") {
      let et = moment(_queryParams?.searchEndTime, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD");

      dump[1] = moment(et).toDate();
    } else {
      dump[1] = null;
    }
    setDateRange(dump);

    return _queryParams;
  }, [location.search]);

  // console.log('qs params',queryParams);
  const [params, setParams] = useState<any>({ ...queryParam });

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: SearchParams) => {
      const newQueryParams = { ...queryParam, ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      setParams(newQueryParams);
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [params, navigate, location.pathname],
  );
  const settingBuilding = useCallback(
    (bd: any) => {
      setIsBuildingOpen(false);
      setBuilding(bd);

      navigateWithQueryParams({ ...params, ...{ buildingId: bd.id, buildingName: bd.buildingName } });
    },
    [params],
  );

  const settingOrg = useCallback(
    (org: any) => {
      setIsOrgOpen(false);
      setOrg(org);

      navigateWithQueryParams({ ...params, ...{ mbOrganizationId: org.id, orgName: org.name } });
    },
    [params],
  );

  const fetchList = useCallback(
    async (passParams: any) => {
      // console.log("passParams", passParams);
      if (dateRange[0]) {
        let startTime = moment(dateRange[0]).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        passParams.searchStartTime = startTime;
        setParams({ ...params, ...{ searchStartTime: startTime } });
      }
      if (dateRange[1]) {
        let endTime = moment(dateRange[1]).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";
        passParams.searchEndTime = endTime;
        setParams({ ...params, ...{ searchEndTime: endTime } });
      }
      passParams.searchValue = passParams.searchValue !== undefined ? decodeURIComponent(passParams.searchValue) : "";
      if (passParams.buildingName === undefined || passParams.buildingName === "") {
        delete passParams.buildingId;
      }
      if (passParams.orgName === undefined || passParams.orgName === "") {
        delete passParams.mbOrganizationId;
      }
      setParams({ ...passParams });
      const response: any = await executeAsync(passParams);

      if (response.status >= 200 && response.status <= 299) {
        console.log("response schedules", response);
        // getOrgListApi
        let callIds = "";
        let orgList: any = [];
        response.data.data.content.map((item: any) => {
          if (item.mbOrganizationId !== "0") {
            if (callIds !== "") {
              callIds = callIds + "," + item.mbOrganizationId;
            } else {
              callIds = item.mbOrganizationId;
            }
          }
        });

        if (callIds !== "") {
          const res: any = await getOrgListApi({ id: callIds });
          orgList = res.data.data.content;
          console.info("res", res);
        }

        response.data.data.content.map((item: any) => {
          const finded = orgList.find((org: any) => String(org.id) === String(item.mbOrganizationId));
          if (finded) {
            item.mbOrganizationName = finded.name;
          }

          return item;
        });

        setData(response.data.data.content);
        const { pageMeta: pm } = response.data.meta;
        setPageMeta(pm);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dateRange, executeAsync, getOrgListApi, location.search, params],
  );

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    if (dateRange[0]) {
      let startTime = moment(dateRange[0]).format(ViewYmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
      setParams({ ...params, ...{ searchStartTime: startTime } });
    }
    if (dateRange[1]) {
      let endTime = moment(dateRange[1]).format(ViewYmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";
      navigateWithQueryParams({ ...params, ...{ searchEndTime: endTime } });
    }
  };

  const returnContractSteps = useMemo(() => {
    let rtn: any = [];
    // console.log("params.contractStep", params.contractStep);
    if (params.contractStep !== undefined && params.contractStep !== "") {
      rtn = params.contractStep.split(",");
    }

    return rtn;
  }, [params.contractStep]);

  const cutLastOption = useMemo(() => {
    let find = searchStatusOption.filter((search: Select) => search.label !== "연체");
    console.log("find", find);
    return find;
  }, []);

  useEffect(() => {
    fetchList({ ...queryParam });
    // setParams({ ...queryParam });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: BillIngScheduleColumns,
      data: data,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  return (
    <>
      <div className="page-product-list">
        <div className="contents-container__table">
          <div className="contents-container__search-wrap">
            <div className="left-area">
              <section>
                <div className="left-area__index">
                  <span>지정검색</span>
                </div>
                <div className="left-area__contents">
                  <div className="minmax140">
                    <BaseInput
                      type="text"
                      value={decodeURIComponent(params?.buildingName) || ""}
                      placeholder="건물 선택"
                      readonly={true}
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsBuildingOpen(true);
                      }}
                      onClearClick={() => {
                        let cl = _.cloneDeep(params);
                        cl.buildingName = "";
                        delete cl.buildingId;
                        navigateWithQueryParams({ ...params, ...cl });
                      }}
                    />
                    <SearchBuildingPopup
                      isOpen={isBuildingOpen}
                      partnerId={params.partnerId}
                      buildingName={params?.buildingName || ""}
                      onClick={settingBuilding}
                      onClose={() => setIsBuildingOpen(false)}
                    />
                  </div>
                  <div className="minmax140">
                    <BaseInput
                      type="text"
                      value={decodeURIComponent(params?.orgName) || ""}
                      placeholder="사업자 선택"
                      readonly={true}
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsOrgOpen(true);
                      }}
                      onClearClick={() => {
                        let cl = _.cloneDeep(params);
                        cl.orgName = "";
                        delete cl.mbOrganizationId;
                        navigateWithQueryParams({ ...params, ...cl });
                      }}
                    />
                    <SearchOrgPopup
                      isOpen={isOrgOpen}
                      partnerId={params.partnerId}
                      name={params?.orgName || ""}
                      columns={orgColumns}
                      placeholder={"사업자를 입력해 주세요"}
                      onClick={settingOrg}
                      onClose={() => setIsOrgOpen(false)}
                    />
                  </div>
                </div>
              </section>
              <section>
                <div className="left-area__index">
                  <span>조건검색</span>
                  {/* <StorageProcessingModal /> */}
                </div>
                <div className="left-area__contents">
                  <div className="minmax201">
                    <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                  </div>
                  <div className="minmax140 ">
                    <BaseMultiSelect
                      placeholder="계약상태"
                      stateOptions={searchStatusOption2}
                      value={returnContractSteps || []}
                      setStateValue={(options: Array<{ value: string; label: string }>) => {
                        let status = "";
                        options.map((obj: { value: string; label: string }) => {
                          if (status === "") {
                            status = obj.value;
                          } else {
                            status += "," + obj.value;
                          }
                        });
                        let dump = _.cloneDeep(params);
                        dump.page = 0;
                        dump.contractStep = status;
                        navigateWithQueryParams(dump);
                      }}
                    />
                    {/* <BaseSelect
                    value={params.contractStep}
                    placeholder="상태"
                    stateOptions={searchStatusOption}
                    setStateValue={(contractStep: string) => {
                      navigateWithQueryParams({ ...params, ...{ contractStep, page: 0 } });
                    }}
                  /> */}
                  </div>
                  <div className="minmax160 ">
                    <BaseSelect
                      value={String(params.isOverdue)}
                      placeholder="빌링기간 내 연체여부 "
                      stateOptions={[
                        { label: "전체", value: "" },
                        { label: "연체", value: "true" },
                        { label: "정상", value: "false" },
                      ]}
                      setStateValue={(isOverdue: string) => {
                        if (isOverdue !== "") {
                          let flag = isOverdue === "true" ? true : false;
                          navigateWithQueryParams({ ...params, isOverdue: flag });
                        } else {
                          const temp = _.cloneDeep(params);
                          temp.isOverdue = "";

                          navigateWithQueryParams({ ...params, ...temp });
                        }
                      }}
                    />
                  </div>
                  <div className="minmax120 ">
                    <BaseSelect
                      value={params.billPayStatus}
                      placeholder="결제상태"
                      stateOptions={cutLastOption}
                      setStateValue={(billPayStatus: string) => {
                        navigateWithQueryParams({ ...params, billPayStatus });
                      }}
                    />
                  </div>

                  <div>
                    <BaseInputWithSearch
                      type="text"
                      selectValue={params?.searchType}
                      inputValue={decodeURIComponent(String(params?.searchValue) || "")}
                      stateOptions={searchTypeOption}
                      setStateValue={(searchType: string) => {
                        setParams({ ...params, searchType });
                      }}
                      onChange={(keyword: string) => setParams({ ...params, ...{ searchValue: keyword } })}
                      onKeyUp={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                      onSearchClick={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                      onClearClick={() => navigateWithQueryParams({ ...params, ...{ searchValue: "" } })}
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="right-area"></div>
          </div>

          {data !== undefined && (
            <BaseTable
              data={data}
              columns={BillIngScheduleColumns}
              pageIndex={Number(params?.page) || 0}
              totalPages={Number(pageMeta?.totalPages) || 0}
              totalElements={pageMeta?.totalElements || 0}
              goPage={(page: number) => {
                navigateWithQueryParams({ ...params, ...{ page } });
              }}
              disabledSortHeaders={["imagePath"]}
              orders={params.sort?.orders}
              setOrders={(orders?: Array<Order>) => {
                if (orders) {
                  navigateWithQueryParams({ ...params, ...{ sort: { orders } } });
                }
              }}
            />
            // <div className="base-table-wrap">
            //   {/* 세부 부과항목 */}
            //   {/* 첫째줄 폼 */}

            //   {/* 2depth 테이블 */}
            //   <div {...getTableProps()} className="base-table sticky h-100 two-depth-header">
            //     <div className="header">
            //       {headerGroups.map((headerGroup) => (
            //         <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
            //           {headerGroup.headers.map((header: any) => {
            //             // sticky 기준을 맞추기 위해 rowspan 처럼 보이는 헤더는 무조건 columns를 1개 가지고 있어야함.
            //             // 스티키가 아닌 rowspan 헤더 케이스
            //             if (!header.parent && header.depth === 0 && header.columns?.length === 1 && !header.sticky) {
            //               return (
            //                 <div {...header.getHeaderProps()} className="base-table__th">
            //                   <div className="w-100 no-parent" style={{ zIndex: 2 }}>
            //                     {header.render("Header")}
            //                   </div>
            //                 </div>
            //               );
            //             } else if (header.sticky === "left" || header.sticky === "right") {
            //               // 스티키 상태인 rowspan 케이스
            //               return (
            //                 <div {...header.getHeaderProps()} className="base-table__th" style={{ ...header.getHeaderProps().style, zIndex: 4 }}>
            //                   <div className="w-100 sticky-parent" style={{ zIndex: 2 }}>
            //                     {header.render("Header")}
            //                   </div>
            //                   {/* <div className="ic_sort"></div> */}
            //                 </div>
            //               );
            //             } else {
            //               return (
            //                 <div {...header.getHeaderProps()} className="base-table__th">
            //                   <div className=" w-100 ">{header.render("Header")}</div>
            //                 </div>
            //               );
            //             }
            //           })}
            //         </div>
            //       ))}
            //     </div>
            //     <div {...getTableBodyProps()} className="body">
            //       {rows.map((row: any) => {
            //         prepareRow(row);

            //         return (
            //           <div {...row.getRowProps()} className={`base-table__tr`}>
            //             {row.cells.map((cell: any) => {
            //               return (
            //                 <div {...cell.getCellProps()} className="base-table__td">
            //                   {cell.render("Cell")}
            //                 </div>
            //               );
            //             })}
            //           </div>
            //         );
            //       })}
            //     </div>
            //   </div>
            //   <BasePagination
            //     pageIndex={Number(params.page) || 0}
            //     currentSize={Number(params.size) || 20}
            //     sizeOption={(sizeValue) => {
            //       navigateWithQueryParams({ ...params, size: sizeValue });
            //     }}
            //     totalPages={Number(pageMeta?.totalPages) || 0}
            //     totalElements={pageMeta?.totalElements || 0}
            //     goPage={(page: number) => {
            //       navigateWithQueryParams({ ...params, ...{ page } });
            //     }}
            //   ></BasePagination>
            // </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BillingScheduleList;
