import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { columns as storageColumns, notS2, editCol } from "./StorageProcessingColumns";

import { useSticky } from "react-table-sticky";
import BaseScroll from "src/components/BaseScroll";
import { useMemo } from "react";

interface DataBundle {
  sortedRecevieList: any;
  showBtn: boolean;
  receiveData: any;
  setReceiveData: any;
  makeSum: number;
  makeOvSum: number;
  row: any;
  setReceiveList: any;
  paymentArray: any;

  delReceiveList: any;
  setDelReceiveList: any;
  contractBaseInfo: any;
}
interface Props {
  dataBundle: DataBundle;
}

const StorageProcessingTable = ({ dataBundle }: Props) => {
  const {
    sortedRecevieList,
    showBtn,
    receiveData,
    setReceiveData,
    makeSum,
    makeOvSum,
    row,
    setReceiveList,
    delReceiveList,
    setDelReceiveList,
    contractBaseInfo,
    paymentArray,
  } = dataBundle;

  const useCols = useMemo(() => {
    console.log("row", row.original.bill);
    if (contractBaseInfo.spaceProductType !== "TIME_COURT") {
      if (showBtn) {
        editCol[0].width = 80;
        storageColumns[0].width = 160;
        storageColumns[1].width = 240;
        storageColumns[2].width = 160;
        notS2[0].width = 140;
        notS2[1].width = 140;
        return storageColumns.concat(notS2).concat(editCol);
      } else {
        editCol[0].width = 80;
        storageColumns[0].width = 160;
        storageColumns[1].width = 240;
        storageColumns[2].width = 180;
        notS2[0].width = 180;
        notS2[1].width = 180;
        return storageColumns.concat(notS2);
      }
    } else {
      if (showBtn) {
        if (row.original.bill.pgcode === "creditcard") {
          storageColumns[0].width = 260;
          storageColumns[1].width = 340;
          storageColumns[2].width = 340;

          return storageColumns;
        } else {
          editCol[0].width = 200;
          storageColumns[0].width = 240;
          storageColumns[1].width = 240;
          storageColumns[2].width = 260;
          // console.log("row", row.original.bill.pgcode);
          return storageColumns.concat(editCol);
        }
      } else {
        storageColumns[0].width = 260;
        storageColumns[1].width = 340;
        storageColumns[2].width = 340;
        return storageColumns;
      }
    }
  }, [contractBaseInfo]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, footerGroups } = useTable(
    {
      columns: useCols,
      data: sortedRecevieList,
      sortedRecevieList,
      showBtn,
      receiveData,
      setReceiveData,
      makeSum,
      makeOvSum,
      mainRow: row,
      setReceiveList,
      delReceiveList,
      setDelReceiveList,
      paymentArray,
      contractBaseInfo,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    useRowSelect,
  );
  return (
    <div>
      <div {...getTableProps()} className="base-table sticky ">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th">
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <BaseScroll maxHeight={250}>
          <div {...getTableBodyProps()} className="body overflow-x-hidden">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </BaseScroll>
        {footerGroups.map((group) => {
          return (
            <div {...group.getFooterGroupProps()} className="overflow-x-hidden">
              {group.headers.map((column) => (
                <div {...column.getFooterProps()} className="base-table__td base-table-footer">
                  {column.render("Footer")}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StorageProcessingTable;
