const STATUS_MAP = {
  APPLY_RECEIVED: {
    name: "신청접수",
    chip: "gray",
  },
  APPLY_CANCEL: {
    name: "신청취소",
    chip: "gray",
  },
  APPLY_CONFIRM: {
    name: "신청확인",
    chip: "blue",
  },
  CONTRACT_PLAN: {
    name: "계약예정",
    chip: "draft",
  },
  CONTRACT_ACCEPT: {
    name: "계약체결",
    chip: "blue",
  },
  USE_APPROVAL: {
    name: "이용승인",
    chip: "visible",
  },
  USE_PROGRESS: {
    name: "이용중",
    chip: "visible",
  },
  USE_COMPLETE: {
    name: "이용완료",
    chip: "gray",
  },
  TERMINATE_RECEIVED: {
    name: "해지접수",
    chip: "invisible",
  },
  TERMINATE_COMPLETE: {
    name: "해지완료",
    chip: "gray",
  },
};

function ContractStatusChip({ status }: { status: string }) {
  const _status = STATUS_MAP[status as keyof typeof STATUS_MAP];

  return <div className={`chip ${_status.chip}`}>{_status.name}</div>;
}
export default ContractStatusChip;
