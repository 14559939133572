import { useCallback, useMemo, useState } from "react";
import { Cell } from "react-table";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { EslTempProduct } from "src/api/esl/esl-types";
import { MediaFile } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import { SelectOption, floorOptions, sortingMeetingRoomList, spaceStyleOptions } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import Esl from "./Esl";

type Props = {
  building: BuildingModel | null;
};

interface EslLabel extends EslTempProduct {
  locationCode: string; // 공간유형 채번
}

/* 
  회의실 컴포넌트
 */
const MeetingRoomSection = ({ building }: Props) => {
  // 건물 상세조회에서 얻어온 회의실 목록
  const meetingRoomList: Array<CommonFacilityModel> = useMemo(() => {
    return sortingMeetingRoomList(building?.buildingCommonFacility?.meetingRoomList || []);
  }, [building]);

  // 이미지 목록
  const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);

  // 이미지 갯수 클릭
  const clickImageCount = useCallback((rowData: CommonFacilityModel) => {
    setMediaList(rowData?.mediaList || []);
    return false;
  }, []);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ value }: Cell<CommonFacilityModel>) => <BaseTooltip contents={value} isSingleLine={true} />,
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 90,
        Cell: ({ row }: any) => {
          const isGround: boolean = row?.original?.isGround; // 지상여부
          const floorNum: number = row?.original?.floorNum; // 위치
          const floor = floorOptions.find((option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`);
          return floor?.label || "";
        },
      },
      {
        Header: "회의실 명",
        accessor: "facilityName",
        width: 90,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "공간유형",
        accessor: "spaceStyle",
        width: 80,
        Cell: ({ value }: any) => {
          const findOption = spaceStyleOptions.find((option: SelectOption) => option.value === value);
          if (findOption && findOption.value !== "SPACE_STYLE_UNRECOGNIZED") {
            return <BaseTooltip contents={findOption.label} />;
          }
          return "";
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 120,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "정원",
        accessor: "facilityMaxPeopleNums",
        width: 60,
      },
      {
        Header: "시설 안내",
        accessor: "equipments",
        width: 140,
        Cell: ({ row }: any) => {
          const facilityList: Array<string> = row?.original?.facilityList || []; // 기자재
          return <BaseTooltip preformatted contents={facilityList.join(",")} />;
        },
      },
      {
        Header: "이용 안내",
        accessor: "description",
        width: 140,
        Cell: ({ value }: any) => {
          return <BaseTooltip preformatted contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 80,
        Cell: ({ row }: any) => <Esl row={row} />,
      },
      {
        Header: "이미지",
        accessor: "mediaList",
        width: 60,
        Cell: ({ row }: any) => {
          const imageCount = (row?.original?.mediaList || []).length;
          return (
            <div>
              <a className="text-underline cursor-pointer" onClick={() => clickImageCount(row?.original)}>
                {imageCount}
              </a>
            </div>
          );
        },
      },
    ],
    [clickImageCount],
  );

  return (
    <section className="">
      {meetingRoomList.length > 0 ? (
        <>
          <ViewDataTable columns={columns} data={meetingRoomList} />
          <div className="mt20">
            <ImagesDetail isUsedRepresentative={true} isUsedDescription={true} images={mediaList} />
          </div>
        </>
      ) : (
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">데이터가 없습니다.</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default MeetingRoomSection;
