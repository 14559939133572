import moment from "moment";
import { useMemo } from "react";
import { getBuildingAsync } from "src/api/building/building-api";
import { getBuildingManagementAsync } from "src/api/building/management-api";
import { moveMediaFilesAsync } from "src/api/file/file-api";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { getConnectedInfoListAsync } from "src/api/question/qeustion-api";
import {
  WorkOrderCancelAsync,
  WorkOrderDetailAsync,
  WorkOrderEditAsync,
  WorkOrderPostAsync,
  getWorkOrderPreviewAsync,
  postWorkOrderGoupIdAsync,
} from "src/api/work-order/workorder-api";
import { WorkOrderFormType } from "src/api/work-order/workorder-types";
import { YmdFormat } from "src/utils";

const useWorkOrder = () => {
  const defaultValues = useMemo(() => {
    const workOrderFormData: WorkOrderFormType = {
      // workOrderType: WorkOrderType.SPACE,
      workOrderSheetAccessZoneList: [
        {
          cmdType: "C",
          // mgmtOfficerId: "",
          contractManageId: "",
          contractId: "",
          startDate: moment().format(YmdFormat.WITH_TIME_ZONE),
          endDate: moment().endOf("days").format(YmdFormat.WITH_TIME_ZONE),
          startTime: "",
          endTime: "",
        },
      ],
      // workOrderPriority: 2,
      accessType: "TIME",
      mgmtOfficeId: "",
      mgmtOfficerId: "",
      startDate: moment().format(YmdFormat.WITH_TIME_ZONE),
      endDate: moment().endOf("days").format(YmdFormat.WITH_TIME_ZONE),
      summary: "",
      description: "",
      isSendAlarm: true,
    };

    return workOrderFormData;
  }, []);

  // 워크오더 상세 api
  const { executeAsync: getWorkOrderDetail } = useApiOperation(WorkOrderDetailAsync);

  // 관리처 관리 상세
  const { executeAsync: getBuildingManagement } = useApiOperation(getBuildingManagementAsync);

  // 건물 상세 조회 api
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 워크오더 반복일정 api
  const { executeAsync: getWorkOrderPreview } = useApiOperation(getWorkOrderPreviewAsync);

  // 워크오더 등록 api
  const { executeAsync: postWorkOrder } = useApiOperation(WorkOrderPostAsync);

  // 워크오더 수정 api
  const { executeAsync: editWorkOrder } = useApiOperation(WorkOrderEditAsync);

  // 프로바이더 상세조회
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 워크오더 취소 api
  const { executeAsync: workOrderCancel } = useApiOperation(WorkOrderCancelAsync);

  // 그룹 id 생성
  const { executeAsync: postWorkOrderGroupId } = useApiOperation(postWorkOrderGoupIdAsync);

  const { executeAsync: moveMedias } = useApiOperation(moveMediaFilesAsync);
  // 상담관리 연결정보
  const { executeAsync: getConnectedInfoList } = useApiOperation(getConnectedInfoListAsync);

  return {
    defaultValues,
    getWorkOrderDetail,
    getBuildingManagement,
    getBuilding,
    getWorkOrderPreview,
    editWorkOrder,
    getProviderDetail,
    workOrderCancel,
    postWorkOrder,
    postWorkOrderGroupId,
    moveMedias,
    getConnectedInfoList,
  };
};

export default useWorkOrder;
