import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "src/api/public-types";
import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import {
  ReservationHistoryResponse,
  ReservationListParams,
  ReservationListResponse,
  ReservationUpdateResponseData,
  ReservationUsageResponse,
} from "./reservation-types";

/**
 * RRV01. 예약 상세 목록 페이지 조회
 */

export const getReservationListAsync = async (
  axios: AxiosInstance,
  params?: ReservationListParams,
): Promise<AxiosResponse<ApiResponse<ReservationListResponse>>> => {
  return await axios.get<ApiResponse<ReservationListResponse>>(ApiPath.reservation.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RRV02. 예약 상태 변경 요청
 */
export async function changeReservationStatusAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
    statusCode: "ACCEPTED" | "CANCELLED";
  },
): Promise<AxiosResponse<ApiResponse<ReservationUpdateResponseData>>> {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  if (!params?.statusCode) {
    throw Error("status is not found.");
  }
  const path = ApiPath.reservation.changeStatus.replace("{id}", params.id.toString());
  return await axios.patch(path, {
    statusCode: params.statusCode,
  });
}

/**
 * RRV03. 예약 소진 시간 조회
 */

export const getReservationUsagesAsync = async (
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<ReservationUsageResponse>>> => {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  return await axios.get<ApiResponse<ReservationUsageResponse>>(ApiPath.reservation.usages.replace("{id}", params.id.toString()));
};

/**
 * RRV04. 예약 소진 시간 목록 조회
 */

export const getReservationUsageListAsync = async (
  axios: AxiosInstance,
  params?: ReservationListParams,
): Promise<AxiosResponse<ApiResponse<ReservationListResponse>>> => {
  return await axios.get<ApiResponse<ReservationListResponse>>(ApiPath.reservation.usagesList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RRV05. 예약 이력 목록 조회
 */

export const getReservationHistoryAsync = async (
  axios: AxiosInstance,
  params?: { id: string | number },
): Promise<AxiosResponse<ApiResponse<{ histories: ReservationHistoryResponse[] }>>> => {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  return await axios.get(ApiPath.reservation.history.replace("{id}", params?.id.toString()), {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RRV06. 예약 취소 처리
 */
export async function deleteReservationAsync(
  axios: AxiosInstance,
  params?: {
    reservationId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  if (!params?.reservationId) {
    throw Error("reservationId is not found.");
  }

  const path = ApiPath.reservation.delReservation.replace("{reservationId}", params.reservationId.toString());
  return await axios.delete(path);
}
