import { Cell } from "react-table";
import { CsServiceItemModel } from "src/api/voc/voc-types";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";

const CsServiceColumn: any = [
  {
    Header: "id",
    accessor: "csItemId",
    width: 140,
    Cell: ({ value }: Cell<CsServiceItemModel>) => (
      <>
        <Link className="text-underline" target="_blank" to={PagePath.voc.detail.replace(":id", value)}>
          {value}
        </Link>
        <span className="ic-target-blank"></span>
      </>
    ),
  },
  {
    Header: "VoC 유형",
    accessor: "csTypeName",
    width: 140,
  },
  {
    Header: "VoC 항목",
    accessor: "csItemName",
    width: 510,
    Cell: ({ value }: Cell<CsServiceItemModel>) => {
      return <span className="text-start w-100">{value}</span>;
    },
  },
  {
    Header: "자주하는 질문",
    accessor: "isFrequent",
    width: 140,
    Cell: ({ value }: Cell<any>) => (value === true ? "적용" : "-"),
  },
];

export default CsServiceColumn;
