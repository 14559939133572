import { useAuth0 } from "@auth0/auth0-react";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useOnClickOutsideClose } from "src/hooks/useOnClickOutsideClose";
import PagePath from "src/pagePath.json";
import { menuTitleState } from "src/recoil/menuTitle/atom";
import { globalPartnerState, partnersState } from "src/recoil/partners/atom";

type props = {
  className?: string;
  logo: boolean;
};

export const GlobalNav = ({ className, logo }: props) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);
  const menuTitle = useRecoilValue(menuTitleState);

  // 파트너 목록
  const partnerList = useRecoilValue(partnersState);

  const globalPartner = useRecoilValue(globalPartnerState);

  const ref = useRef(null);

  useOnClickOutsideClose(ref, () => setShowDropdown((prev) => !prev), true);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <header className={`global-nav ${className ? className : ""}`}>
      {logo && <div className="global-nav__logo"></div>}
      <p className="global-nav__title">{menuTitle}</p>
      {isAuthenticated && user && (
        <>
          <div className="global-nav__user-wrap">
            <span>
              안녕하세요!<b> {globalPartner?.name} </b>관리자님
            </span>
            <div
              className="global-nav__user"
              onClick={(e) => {
                setShowDropdown(!showDropdown);
              }}
            >
              <div className="global-nav__avatar">{user.email ? user.email[0].toUpperCase() : "P"}</div>
              {showDropdown && (
                <div className="global-nav__dropdown" ref={ref}>
                  <div className="global-nav__partner-list-wrap">
                    <div className="global-nav__partner-list">
                      <h5>파트너 선택</h5>
                      <ul>
                        {partnerList?.map((_partner) => {
                          return (
                            <a href={PagePath.main.replace(":partnerId", String(_partner.id))} key={_partner.id + _partner.code}>
                              <li
                                className={globalPartner?.code === _partner.code ? "--active" : ""}
                                // onClick={() => {
                                //   window.location.href = PagePath.main.replace(":partnerId", String(_partner.id));
                                // }}
                              >
                                <p>{_partner.name}</p>
                                <span>{_partner.code}</span>
                              </li>
                            </a>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="global-nav__dropdown-user"
                    onClick={() => {
                      logout({ logoutParams: { returnTo: window.location.origin } });
                    }}
                  >
                    <div className="global-nav__avatar">
                      <p>{user.email ? user.email[0].toUpperCase() : "P"}</p>
                    </div>
                    <div className="user-info">
                      <p>{user.email}</p>
                      <span>로그아웃</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </header>
  );
};
