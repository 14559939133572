import { memo, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useValidRoutes } from "src/hooks/useValidRoutes";
import PageNotFound from "src/pages/errors/PageNotFound";
import { ENVIRONMENT } from "src/types/common";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeList } from "./routes";

const RoutesList = () => {
  // 환경별 라우트 필터링
  const memoizedRouteList = useMemo(() => {
    return routeList.filter((route) =>
      route.allowEnvironments ? route.allowEnvironments.includes(process.env.REACT_APP_ENVIRONMENT as ENVIRONMENT) : true,
    );
  }, []);
  // 파트너 메뉴 허용 hook
  const { validRoutes } = useValidRoutes(memoizedRouteList);
  return (
    <Routes>
      {validRoutes?.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.isProtectRoute ? <ProtectedRoute component={route.component} isWithPartner={route.withPartner} /> : <route.component />}
        ></Route>
      ))}

      {/* 에러페이지 */}
      <Route path="/error">
        <Route path="page-not-found" element={<PageNotFound />} />
      </Route>

      {/* page not found */}
      <Route path="*" element={<Navigate to="/error/page-not-found" replace></Navigate>} />
    </Routes>
  );
};

export default memo(RoutesList);
