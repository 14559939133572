import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import {
  WorkOrderDetail,
  WorkOrderDetailModel,
  WorkOrderDetailResponse,
  WorkOrderFormTypeOriginal,
  WorkOrderGroupType,
  WorkOrderListParams,
  WorkOrderListResponse,
  WorkOrderPreviewRequest,
  WorkOrderPreviewResponse,
  WorkOrderQueryParams,
  WorkOrderRevisionParams,
  WorkOrderRevisionsResponse,
  WorkOrderSheet,
  WorkOrderUpdateModel,
} from "./workorder-types";
import { UnionRecurrenceType } from "src/pages/workOrder/workOrder-types";

/**RPW01. 워크시트 등록 */
export const WorkOrderPostAsync = async (
  axios: AxiosInstance,
  params?: { workOrderSheetList: WorkOrderFormTypeOriginal[] },
): Promise<AxiosResponse<ApiResponse<{ content: Array<WorkOrderDetailModel> }>>> => {
  return await axios.post(ApiPath.workOrder.authenticated.add, params);
};

/**RPW02. 워크시트 수정*/
export const WorkOrderEditAsync = async (
  axios: AxiosInstance,
  params?: { id: string; workOrderSheet: WorkOrderUpdateModel },
): Promise<AxiosResponse<ApiResponse<{ content: Array<WorkOrderDetailModel> }>>> => {
  if (!params?.id) {
    throw Error("workOrder id is not found");
  }

  return await axios.put(ApiPath.workOrder.authenticated.edit.replace("{id}", String(params?.id)), {
    workOrderSheet: params.workOrderSheet,
  });
};

/**
 * RPO02. (무인증) 워크시트 상세조회
 */
export async function getWorkOrderDetailAsync(
  axios: AxiosInstance,
  params?: {
    workOrderSheetNo: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: WorkOrderDetail }>>> {
  if (!params?.workOrderSheetNo) {
    throw Error("workOrderSheetNo is not found.");
  }
  return await axios.get(`/api/ctrl/pr/public/work-orders/${params.workOrderSheetNo}`);
}

/**
 * RPO01. (무인증) 워크시트 수정
 */
export async function putWorkOrderAsync(
  axios: AxiosInstance,
  params?: {
    workOrderSheetNo: string;
    workOrderSheet: WorkOrderSheet;
  },
): Promise<AxiosResponse<ApiResponse<{ content: WorkOrderDetail }>>> {
  if (!params?.workOrderSheetNo) {
    throw Error("workOrderSheetNo is not found.");
  }
  return await axios.put(`/api/ctrl/pr/public/work-orders/${params.workOrderSheetNo}`, {
    workOrderSheet: params.workOrderSheet,
  });
}

/**
 * RPO05. (무인증) 워크시트 목록조회
 */
export async function getWorkOrderListAsync(
  axios: AxiosInstance,
  params?: WorkOrderListParams,
): Promise<AxiosResponse<ApiResponse<{ content: WorkOrderDetail[] }>>> {
  if (!params?.keywordList) {
    throw Error("workOrderSheet Keyword is not found.");
  }
  if (!params?.page && params?.page !== 0) {
    throw Error("page is not found.");
  }
  if (!params?.size) {
    throw Error("size is not found.");
  }
  return await axios.get(`/api/ctrl/pr/public/work-orders`, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**RPW04. 워크시트 상세조회 */
export const WorkOrderDetailAsync = async (
  axios: AxiosInstance,
  params?: { id: number },
): Promise<AxiosResponse<ApiResponse<WorkOrderDetailResponse>>> => {
  if (!params?.id) {
    throw Error("workOrder id is not found");
  }
  return await axios.get(ApiPath.workOrder.authenticated.detail.replace("{id}", String(params?.id)));
};

/**RPW05. 워크시트 인증 목록조회  */
export const WorkOrderListAsync = async (
  axios: AxiosInstance,
  params?: WorkOrderQueryParams,
): Promise<AxiosResponse<ApiResponse<WorkOrderListResponse>>> => {
  return await axios.get(ApiPath.workOrder.authenticated.list, {
    params,
    paramsSerializer: {
      serialize: (param) => qs.stringify(param, { allowDots: true, encode: true }),
    },
  });
};

// RPW07. 워크시트 취소
export const WorkOrderCancelAsync = async (
  axios: AxiosInstance,
  params?: {
    ids?: string;
    groupIds?: string;
  },
): Promise<AxiosResponse<ApiResponse<{ content: number }>>> => {
  let cancelData;
  let path = "";

  if (params?.ids && !params.groupIds) {
    cancelData = { ids: params?.ids };
    path = `${ApiPath.workOrder.authenticated.cancel}?ids=${params.ids}`;
  } else {
    cancelData = { groupIds: params?.groupIds };
    path = `${ApiPath.workOrder.authenticated.cancel}?groupIds=${params?.groupIds}`;
  }

  return axios.patch(path, {
    params: cancelData,
  });
};

/**
 * RPW09. 워크시트 단일 리비전 조회
 * https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/215416868/CPW09. */
export const getWorkOrderRevisionsAsync = (
  axios: AxiosInstance,
  params?: WorkOrderRevisionParams,
): Promise<AxiosResponse<ApiResponse<WorkOrderRevisionsResponse>>> => {
  return axios.get(ApiPath.workOrder.authenticated.revision, {
    params,
    paramsSerializer: {
      serialize: (param) => qs.stringify(param, { allowDots: true, encode: true }),
    },
  });
};

/**RPW06. 워크시트 반복일정 미리보기 */
export const getWorkOrderPreviewAsync = async (
  axios: AxiosInstance,
  params?: WorkOrderPreviewRequest,
): Promise<AxiosResponse<ApiResponse<WorkOrderPreviewResponse>>> => {
  return axios.get(ApiPath.workOrder.authenticated.preview, { params: params });
};

/**RPW10. 워크시트 그룹ID 생성*/

export const postWorkOrderGoupIdAsync = async (
  axios: AxiosInstance,
  params?: {
    startDate?: string;
    recurrenceType?: UnionRecurrenceType;
    recurrenceEndDate?: string;
    recurrenceDayOfWeekType?: string;
    isHoliday?: boolean;
    period?: number;
  }, //
): Promise<AxiosResponse<ApiResponse<{ content: WorkOrderGroupType }>>> => {
  return axios.post(ApiPath.workOrder.authenticated.createGroup, { workOrderSheetGroup: params });
};
