import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getManageBuildingAsync } from "src/api/building/building-api";
import { CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import NoImage from "src/assets/images/NoImage.png";
import { BaseCheckbox, BaseTooltip } from "src/components";
import { useMakeAddableSpaceList } from "src/hooks/contract/useMakeAddableSpaceList";
import usePostOperationFacility from "src/hooks/contract/usePostOperationFacility";
import { useQueryParams } from "src/hooks/useQueryParams";
import { changeFacilityTypeToText } from "src/pages/contract/contract-util";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";

const meetingRoomColumn: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 100,
    Cell: ({ row, addedSpaceIds, setSpace }: any) => {
      addedSpaceIds as string[];
      //추가된 공간
      const isDisabled = addedSpaceIds.includes(row.original.id);
      //추가할 공간 체크
      const changeTrigger = (checked: boolean) => {
        if (checked) {
          setSpace((prev: string[]) => [...prev, row.original.id]);
        } else {
          setSpace((prev: string[]) => [...prev].filter((id) => id !== row.original.id));
        }
      };

      return (
        <div>
          <BaseCheckbox id={row.id} name={"isSelected"} disabled={isDisabled} onChange={(checked: boolean) => changeTrigger(checked)} />
        </div>
      );
    },
  },
  {
    Header: "회의실 id",
    accessor: "id",
    width: 80,
  },
  // 신청계약에선 제외
  // {
  //   Header: "이미지",
  //   accessor: "img",
  //   width: 80,
  //   Cell: ({ row }: Cell<any>) => {
  //     return (
  //       <div className="flex-center-center">
  //         <img className="product_table_td_img" src={row.original?.mediaList?.[0]?.url ?? NoImage} alt="" />
  //       </div>
  //     );
  //   },
  // },
  {
    Header: "회의실명",
    accessor: "facilityName",
    width: 250,
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "정원",
    accessor: "facilityMaxPeopleNums",
    width: 78,
    Cell: ({ value }: any) => {
      return <>{value ? value + "인" : "-"}</>;
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 168,
    Cell: ({ row, value }: any) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",

    width: 190,
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
];
const refreshRoomColumn: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 100,
    Cell: ({ row, addedSpaceIds, setSpace }: any) => {
      addedSpaceIds as string[];
      //추가된 공간
      const isDisabled = addedSpaceIds.includes(row.original.id);
      //추가할 공간 체크
      const changeTrigger = (checked: boolean) => {
        if (checked) {
          setSpace((prev: string[]) => [...prev, row.original.id]);
        } else {
          setSpace((prev: string[]) => [...prev].filter((id) => id !== row.original.id));
        }
      };

      return (
        <div>
          <BaseCheckbox id={row.id} name={"isSelected"} disabled={isDisabled} onChange={(checked: boolean) => changeTrigger(checked)} />
        </div>
      );
    },
  },
  {
    Header: "편의시설 id",
    accessor: "id",
    width: 100,
  },
  {
    Header: "편의시설명",
    accessor: "facilityName",
    width: 167,
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "설명",
    accessor: "description",
    width: 312,
    Cell: ({ value, row }: any) => {
      <div className={"w-100 text-left"}>
        <BaseTooltip contents={value} />
      </div>;
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 95,
    Cell: ({ row, value }: any) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 166,
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
];
const deskColumn: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 100,
    Cell: ({ row, addedSpaceIds, setSpace }: any) => {
      addedSpaceIds as string[];
      //추가된 공간
      const isDisabled = addedSpaceIds.includes(row.original.id);
      //추가할 공간 체크
      const changeTrigger = (checked: boolean) => {
        if (checked) {
          setSpace((prev: string[]) => [...prev, row.original.id]);
        } else {
          setSpace((prev: string[]) => [...prev].filter((id) => id !== row.original.id));
        }
      };

      return (
        <div>
          <BaseCheckbox id={row.id} name={"isSelected"} disabled={isDisabled} onChange={(checked: boolean) => changeTrigger(checked)} />
        </div>
      );
    },
  },
  {
    Header: "좌석 id",
    accessor: "id",
    width: 77,
  },
  {
    Header: "그룹명",
    accessor: "groupName",
    width: 260,
    Cell: ({ value, row }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "좌석명",
    accessor: "facilityName",
    width: 76,
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 168,
    Cell: ({ row, value }: any) => {
      const isGround = row.original.isGround;
      return (
        <p>
          {isGround ? "지상" : "지하"} {value}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 259,
    Cell: ({ value }: any) => {
      return (
        <div className={"w-100 text-left"}>
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
];
type Props = {
  addedSpaceIds: string[];
  spaceType: SpaceTypeT;
  contractManageId: string;
  contractId: string;
};

/** 신청계약 공용공간 추가 모달*/
const SpaceAddModal = ({ addedSpaceIds, spaceType, contractManageId, contractId }: Props) => {
  const { setBaseModal, setAbstractModalZ1 } = useModal();
  const [space, setSpace] = useState<string[]>([]);
  const { makeAddableSpaceList } = useMakeAddableSpaceList();
  const [buildingsSpaceList, setBuildingsSpaceList] = useState<(CommonFacilityModel | undefined)[] | undefined>([]);

  const { mutate } = usePostOperationFacility();

  // 계약 건물 운영정보 조회
  const { executeAsync: getManageBuilding } = useApiOperation(getManageBuildingAsync);

  /** 계약에 등록된 건물들의 공용공간 리스트 생성 : 회의실, 좌석, 편의시설 추가용 */
  useEffect(() => {
    (async () => {
      if (contractManageId) {
        const buildingManageList = await getManageBuilding({ contractManageId: contractManageId }).then((res) => res.data?.data?.buildingManageList);
        const buildingIds = buildingManageList.map((item) => String(item.buildingId));
        makeAddableSpaceList(buildingIds, spaceType).then((data) => setBuildingsSpaceList(data));
      }
    })();
  }, [contractManageId]);

  const submitHandleMutationCommonFacility = async (space: string[]) => {
    if (space.length === 0) {
      return;
    }
    mutate(
      {
        contractId: Number(contractId),
        productFacilityList: space.map((id) => ({
          contractFacilityId: "0", //등록시 "0" 입력
          id: id,
        })),
      },
      {
        onSuccess(data) {
          if (data.status <= 200 && data.status <= 299) {
            setAbstractModalZ1({ isOpen: false });
            setBaseModal({
              isOpen: true,
              title: `${SpaceTypeLabel[spaceType]}이 추가되었습니다`,
              children: `${SpaceTypeLabel[spaceType]}에 바로 엑세스 할 수 있습니다`,
              btnRightTitle: "확인",
            });
          }
        },
      },
    );
  };

  const changeTypeToColumn = (spaceType: string) => {
    switch (spaceType) {
      case "MEETING_ROOM":
        return meetingRoomColumn;
      case "REFRESH_ROOM":
        return refreshRoomColumn;
      case "DESK":
        return deskColumn;
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: changeTypeToColumn(spaceType),
      data: buildingsSpaceList as CommonFacilityModel[],
      addedSpaceIds,
      space,
      setSpace,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <>
      <section className="base-abstract-modal__title">
        <div className="flex-row flex-center-between w-100">
          <h1>{changeFacilityTypeToText(spaceType)} 추가</h1>
        </div>
      </section>

      {/* table */}
      <section className="base-abstract-modal__contents px30">
        <div {...getTableProps()} className="base-table overflow-visible sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            {rows.length > 0 &&
              rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            {rows.length === 0 && (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="base-abstract-modal__btn-wrap">
        <button className="base-btn color-white" onClick={() => setAbstractModalZ1({ isOpen: false })}>
          취소
        </button>
        <button className="base-btn" onClick={() => submitHandleMutationCommonFacility(space)}>
          선택
        </button>
      </div>
    </>
  );
};

export default SpaceAddModal;
