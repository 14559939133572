// 관리처 CRUD 처리요청
export const CmdType = {
  C: "C", // 등록
  U: "U", // 수정
  D: "D", // 삭제
} as const;

export type UnionCmdType = (typeof CmdType)[keyof typeof CmdType] | "";

// 관리자 업무타입
export const OfficerType = {
  UNRECOGNIZED: "OFFICER_UNRECOGNIZED", // 정의 안된
  MANAGEMENT: "MANAGEMENT", // 관리
  FACILITY: "FACILITY", // 시설
  CLEANER: "CLEANER", // 미화
  SECURITY: "SECURITY", // 보안과 경비
  CONVENIENCE: "CONVENIENCE", // 생활편의
  CS: "CS", // CS
} as const;

export type UnionOfficerType = (typeof OfficerType)[keyof typeof OfficerType];

// 관리처 역할 타입
export const RoleType = {
  UNRECOGNIZED: "ROLE_UNRECOGNIZED",
  MANAGER: "ROLE_MANAGER",
  USER: "ROLE_USER",
} as const;

export type UnionRoleType = (typeof RoleType)[keyof typeof RoleType];
