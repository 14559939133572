import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MgmtOfficeModel, MgmtOfficerType } from "src/api/building/building-types";
import { editBuildingManagementAsync } from "src/api/building/management-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseCheckbox, BaseInput, BaseModal } from "src/components";
import { Modal, RoleType, UnionOfficerType } from "src/pages/building/building-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { formatPhoneNumber, onlyNumber, parsedPhoneNumber } from "src/utils";
import { FrontMgmtOfficeModel, FrontMgmtOfficerType, officerTypeList } from "../management-type";

type Props = {
  managementDetail?: MgmtOfficeModel;
  frontOriginalData?: FrontMgmtOfficeModel;
  buildingId?: string;
  getBuildingManagement: Function;
  officerDetail: MgmtOfficerType;
};

const MgmtOfficerForm = ({ managementDetail, frontOriginalData, buildingId, getBuildingManagement, officerDetail }: Props) => {
  //

  // 토스트
  const { openToast } = useToast();

  const { openErrorModal } = useErrorModal();

  const { setAbstractModalZ2 } = useModal();

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 관리처 관리 수정
  const { executeAsync: editBuildingManagement } = useApiOperation(editBuildingManagementAsync, { noHandleError: true });
  // useForm default
  const defaultValues = useMemo(() => {
    const officeData: FrontMgmtOfficeModel = {
      officerList: [
        {
          name: "",
          phone: "",
          isPrimary: false,
          roleType: "ROLE_USER",
          officerTypeList: [],
          description: "",
          cmdType: "C",
        },
      ],
    };

    return officeData;
  }, []);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FrontMgmtOfficeModel>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (!officerDetail) return;

    setValue("officerList.0.id", officerDetail.id);
    setValue("officerList.0.name", officerDetail.name);
    setValue("officerList.0.phone", formatPhoneNumber(String(officerDetail.phone))?.replaceAll("-", ""));
    setValue("officerList.0.isPrimary", officerDetail.isPrimary);
    setValue("officerList.0.isDeleted", officerDetail.isDeleted);
    setValue("officerList.0.officerTypeList", officerDetail.officerTypeList);
    setValue("officerList.0.roleType", officerDetail.roleType);
    setValue("officerList.0.description", officerDetail.description);
    setValue("officerList.0.cmdType", "U");

    // const newDetail: MgmtOfficerType = {
    //   ...officerDetail,
    //   phone: formatPhoneNumber(String(officerDetail.phone))?.replaceAll("-", ""),
    //   cmdType: "U",
    //   officerTypeList: officerDetail.officerTypeList,
    // };
    // console.log("newDetail", newDetail);
    // setValue("officerList.0", newDetail, { shouldDirty: true });
  }, [officerDetail]);

  //

  useEffect(() => {
    if (officerDetail) return;

    if (managementDetail?.officerList) {
      const officeList = managementDetail.officerList;
      const isPrimary = officeList.some((item) => item.isPrimary === true);
      if (isPrimary) {
        setValue("officerList.0.isPrimary", false);
        setValue("officerList.0.roleType", "ROLE_USER");
      }
    }
  }, [managementDetail, officerDetail]);

  // 유효성 확인
  const formValidation = useCallback(() => {
    let requiredMessage = "필수입력 항목입니다";

    register(`officerList.0.name`, { required: { value: true, message: requiredMessage } });

    register(`officerList.0.phone`, {
      validate: {
        required: (phone) => {
          let result = true;

          // 기존 담당자중 대표여부 확인

          const isPhoneValided = isValidPhoneNumber(String(phone), "KR"); //mobile 번호가 맞는지 확인

          if (!phone) {
            result = false;
          } else if (phone && !isPhoneValided) {
            result = false;
            requiredMessage = "휴대폰 번호 형식에 맞게 입력해주세요.";
          }

          return result || requiredMessage;
        },
      },
    });

    register(`officerList.0.isPrimary`, {
      validate: {
        required: (isPrimary) => {
          let result = true;

          const primaryOfficer = managementDetail?.officerList?.find((officer: MgmtOfficerType) => officer.isPrimary === true);

          const officer = watch("officerList.0");
          // 기존 담당자중 대표여부 확인

          if (!primaryOfficer?.isPrimary && !isPrimary) {
            result = false;
            requiredMessage = "1명 이상의 대표 매니저가 필요합니다.";
          } else if (primaryOfficer?.id === officer.id && !isPrimary) {
            result = false;
            requiredMessage = "1명 이상의 대표 매니저가 필요합니다.";
          } else if (isPrimary === true && officer.roleType !== "ROLE_MANAGER") {
            result = false;
            requiredMessage = "대표 선택 시 매니저 선택은 필수입니다.";
          }

          return result || requiredMessage;
        },
      },
    });

    register(`officerList.0.officerTypeList`, {
      validate: {
        required: (officerTypeList) => {
          let result = true;

          const officer = watch("officerList.0");

          if (officer?.isPrimary === false && officer?.roleType === "ROLE_USER" && officerTypeList?.length === 0) {
            result = false;
            requiredMessage = "업무파트를 선택해주세요.";
          }
          return result || requiredMessage;
        },
      },
    });
  }, [managementDetail?.officerList, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  // 수정 데이터 만들기
  const makeRequestEditData = useCallback(
    (data: FrontMgmtOfficeModel) => {
      // 인자로 넘어온 data와 original 데이터를 비교해서 변경된 것만 editData에 추가
      const original = frontOriginalData;

      let editData = {};

      const isOfficerList = _.isEqual(original?.officerList, data.officerList);

      const officerList = data.officerList;

      // 담당자 수정부분
      if (!isOfficerList) {
        editData = {
          ...editData,
          officerList: officerList?.map((item: FrontMgmtOfficerType) => {
            let officerData = { ...item, phone: parsedPhoneNumber(String(item.phone)) };
            // 등록 또는 삭제시 item 리턴

            if (officerData.cmdType === "C" || officerData.cmdType === "D") {
              if (officerData.officerTypeList && officerData.officerTypeList.length < 1) {
                return (officerData = {
                  ...officerData,
                  officerTypeList: ["OFFICER_UNRECOGNIZED"],
                });
              }

              return officerData;
            }
            // 수정 시
            else {
              // 매니저 선택 ok / 업무선택 미선택 시 "OFFICER_UNRECOGNIZED" 전달
              if (officerData.officerTypeList && officerData.officerTypeList.length < 1) {
                return (officerData = {
                  ...officerData,
                  officerTypeList: ["OFFICER_UNRECOGNIZED"],
                });
              }
              // 업무파트 미선택이었는데  선택후 저장시 "OFFICER_UNRECOGNIZED" 삭제
              else if (officerData.officerTypeList && officerData.officerTypeList.length > 1) {
                return (officerData = {
                  ...officerData,
                  officerTypeList: officerData.officerTypeList.filter((value) => value !== "OFFICER_UNRECOGNIZED"),
                });
              }
              return {
                ...officerData,
              };
            }
          }),
        };
      }
      return editData;
    },
    [frontOriginalData],
  );

  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (data: FrontMgmtOfficeModel) => {
      //
      // 처음에 관리처를 등록해야하기 때문에 담당자는 POST 가 아닌 수정 api 이용

      // 수정 api 통신
      const editData = makeRequestEditData(data);
      const response = await editBuildingManagement({
        id: String(managementDetail?.id),
        office: editData,
      });
      if (response.status >= 200 && response.status <= 200) {
        openToast({ content: `정상적으로 ${"수정"}되었습니다.` });
        setConfirmModal({ isOpen: false });
      } else {
        const errorCode = response.data?.meta?.errorCode;
        if (errorCode && errorCode === "ePR0517") {
          openErrorModal({ errorMessage: "진행중인 워크시트가 있습니다.", errorCode, statusCode: response.status });
          // openErrorModal(response.data.meta);
        }
      }

      reset();
      await getBuildingManagement(String(buildingId));
      setAbstractModalZ2({ isOpen: false });
    },
    [buildingId, editBuildingManagement, getBuildingManagement, makeRequestEditData, managementDetail?.id, reset],
  );

  // 유효성 검사후 저장
  const onSubmit = useCallback(
    async (data?: FrontMgmtOfficeModel, e?: any) => {
      e.preventDefault();

      const officerList = managementDetail?.officerList?.filter((item) => item.isDeleted === false);
      // 경고창 모달 부분

      // 새로 등록된 담당자
      const willBeOfficer: FrontMgmtOfficerType = data?.officerList![0] || {};

      // 기존 등록된 연락처
      const havePhoneOfficer = officerList?.find((item) => parsedPhoneNumber(String(item.phone)) === parsedPhoneNumber(String(willBeOfficer.phone)));

      // 새로 등록될 연락처와 기존에 등록된 연락처가 겹칠경우
      if (
        parsedPhoneNumber(String(willBeOfficer.phone)) === parsedPhoneNumber(String(havePhoneOfficer?.phone)) &&
        willBeOfficer.id !== havePhoneOfficer?.id
      ) {
        setAlertModal({ isOpen: true, title: "이미 등록된 연락처입니다." });
        return;
      }

      //

      if (data?.officerList) setConfirmModal({ isOpen: true, title: "저장하시겠습니까?", payload: data });
    },
    [managementDetail?.officerList],
  );
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);
  return (
    <article className="contents-container__wrap-article">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__sub-title">
          <h2>담당자</h2>
        </div>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">이름(닉네임)</p>
          </div>
          <div className="contents-container__grid-contents minmax240">
            <Controller
              control={control}
              name={`officerList.0.name`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={(text: string) => onChange(text)}
                    value={value}
                    name={name}
                    errorText={error?.message}
                    placeholder="이름을 입력해 주세요"
                  />
                );
              }}
            ></Controller>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">휴대폰 번호</p>
          </div>
          <div className="contents-container__grid-contents minmax240">
            <Controller
              control={control}
              name={`officerList.0.phone`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseInput
                    onChange={(phoneNumber: string) => onChange(phoneNumber)}
                    value={String(value)}
                    name={name}
                    errorText={error?.message}
                    placeholder="전화번호를 입력해 주세요"
                    type="phone"
                  />
                );
              }}
            ></Controller>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">매니저</p>
          </div>
          <div className="flex-center-start">
            <Controller
              control={control}
              name={`officerList.0.isPrimary`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseCheckbox
                    id={name}
                    name={`${name}0`}
                    className="mr20"
                    label="대표"
                    checked={value}
                    onChange={(checked: boolean) => {
                      onChange(checked);
                    }}
                  />
                );
              }}
            ></Controller>
            <Controller
              control={control}
              name={`officerList.0.roleType`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return (
                  <BaseCheckbox
                    id={name}
                    name={name}
                    label="매니저"
                    checked={value === RoleType.MANAGER ? true : false}
                    onChange={(checked: boolean) => {
                      let role = checked ? RoleType.MANAGER : RoleType.USER;
                      onChange(role);
                    }}
                  />
                );
              }}
            ></Controller>
          </div>
          {errors.officerList && errors.officerList[0]?.isPrimary && (
            <>
              <div className="contents-container__grid-index"></div>
              <p className="validation-text ">{errors.officerList![0]?.isPrimary.message}</p>
            </>
          )}
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">업무파트</p>
          </div>
          <div className="flex-center-start">
            {officerTypeList.map((type: { value: UnionOfficerType; label: string }, typeIdx: number) => (
              <Controller
                key={type.value}
                control={control}
                name={`officerList.0.officerTypeList`}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                  return (
                    <BaseCheckbox
                      id={`${name}${typeIdx}`}
                      name={`${name}${typeIdx}`}
                      className=" mr20"
                      checked={value?.includes(type.value)}
                      label={type.label}
                      onChange={(checked: boolean) => {
                        let officeTypeArray: UnionOfficerType[] = watch(`officerList.0.officerTypeList`) || [];

                        if (checked) {
                          officeTypeArray.push(type.value);
                        } else {
                          officeTypeArray = officeTypeArray.filter((value) => value !== type.value);
                        }
                        onChange(officeTypeArray);
                      }}
                    />
                  );
                }}
              ></Controller>
            ))}
          </div>
          {errors.officerList && errors.officerList![0]?.officerTypeList && (
            <>
              <div className="contents-container__grid-index"></div>
              <p className="validation-text ">{errors.officerList![0]?.officerTypeList.message}</p>
            </>
          )}
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>세부 업무내용</p>
          </div>
          <div className="">
            <Controller
              control={control}
              name={`officerList.0.description`}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                return <BaseInput onChange={onChange} value={value} name={name} maxLength={1000} placeholder="세부 업무내용을 입력해주세요." />;
              }}
            ></Controller>
          </div>
        </section>

        <div className="flex-center-end">
          <BaseButton title="취소" className="color-white mr5" onClick={() => setAbstractModalZ2({ isOpen: false })} />
          <BaseButton title="저장" type="submit" />
        </div>
      </form>

      <>
        <BaseModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          title={confirmModal.title}
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            confirmModal.payload && onClickConfirmModal(confirmModal.payload);
            setConfirmModal({ isOpen: false });
          }}
        >
          {/* <p>{confirmModal.message}</p> */}
        </BaseModal>
        <BaseModal isOpen={alertModal.isOpen} title={alertModal.title} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
          {/* <p className="pre-formatted">{alertModal.message}</p> */}
        </BaseModal>
      </>
    </article>
  );
};

export default MgmtOfficerForm;
