import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBlockLayout, useRowSelect, useTable } from "react-table";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { BuilidingPopupColumn } from "../modalPopup/columns/BuilidingPopupColumn";
import TableRadioButton from "./TableRadioButton";

type QueryParams = {
  page?: number;
  size?: number;
  keyword?: string;
  isDeleted?: string;
  sort?: {
    orders?: Array<Order>;
  };
  partnerId?: string;
};

type Props = {
  setSelectedBuilding?: Function;
  partnerId: string;
  // getBuildingsId?: Function;
  initialArray?: Array<any>;
  onClose?: Function;
  onConfirm?: Function;
};

const ProductBuildingListModal = ({ setSelectedBuilding, partnerId, onClose, onConfirm, initialArray }: Props) => {
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse("", {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, []);

  const [buildingList, setBuildingList] = useState<BuildingModel[]>([]); // 빌딩 목록
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [pageNum, setPageNum] = useState(0);

  // 건물 목록 조회 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  const columns = useMemo(() => BuilidingPopupColumn, []);
  const divRef = useRef<HTMLDivElement>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,

    state: { selectedRowIds },
  } = useTable(
    {
      columns: columns as any,
      data: buildingList,
      autoResetSelectedRows: true,
    },
    useBlockLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            const buildingIdList = initialArray?.map((building) => Number(building.buildingId));
            const isDisabled = buildingIdList?.includes(Number(row.original.id));
            return (
              <div>
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                    setSelectedBuilding && setSelectedBuilding(row.original);
                  }}
                  disabled={isDisabled}
                  name="buiding"
                  {...currentState}
                />
              </div>
            );
          },
          width: 70,
          Header: "선택",
        },

        ...columns,
      ]);
    },
  );

  // 빌딩 목록 조회
  useEffect(() => {
    async function fetchBuildings(buildingListParams: BuildingListParams) {
      const { data } = await getBuildings(buildingListParams);
      setBuildingList(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);
    }

    queryParams.partnerId = partnerId;

    // 건물 목록 조회후 데이터 바인딩
    fetchBuildings(queryParams);
  }, [queryParams, getBuildings]);

  //===================================================
  // 쿼리파라미터 정보와 함께 네비게이션

  // 팝업내에서 건물 리스트 검색
  const onSearchList = useCallback(
    async (passParams?: QueryParams) => {
      queryParams.page = passParams?.page;
      queryParams.keyword = passParams?.keyword;
      queryParams.partnerId = partnerId;
      queryParams.size = passParams?.size;
      const response = await getBuildings(queryParams);

      if (response.status >= 200 && response.status <= 299) {
        setBuildingList(response.data.data.content);
        setPageMeta(response.data.meta.pageMeta);
      }
    },
    [getBuildings, partnerId, queryParams],
  );

  // 팝업 빌딩 목록 페이지네이션
  const buildingPagenation = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const page = newQueryParams.page;
      setPageNum(Number(page));
      onSearchList(newQueryParams);
    },
    [onSearchList, params],
  );

  return (
    <BaseAbstractModal isOpen={true} className="dialog-modal ">
      <section className="base-abstract-modal__title flex-center-between px30">
        <h1>건물 선택</h1>
        <div className="right-area">
          <div className="minmax260">
            <BaseInput
              type="text"
              placeholder="검색어를 입력해주세요"
              onSearchClick={() => buildingPagenation({ page: 0 })}
              onChange={(keyword: string) => {
                setParams({ ...params, keyword });
              }}
              onKeyUp={() => buildingPagenation({ page: 0 })}
              value={params.keyword}
            />
          </div>
        </div>
      </section>

      {/* 테이블 부분 */}
      {/* 테이블 부분 */}
      <section className="base-abstract-modal__contents">
        <div className="contents-container__table ">
          <div {...getTableProps()} className="base-table view-data-table sticky px30" ref={divRef}>
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row, idx: number) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="base-table__tr">
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          totalElements={pageMeta?.totalElements}
          pageIndex={pageNum || 0}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size });
            buildingPagenation({ ...params, size });
          }}
          goPage={(page: number) => buildingPagenation({ page })}
          children={
            <>
              <BaseButton title="취소" onClick={onClose} className="mr10 color-white" />
              <BaseButton title="선택" onClick={onConfirm} />
            </>
          }
        />
      </section>
    </BaseAbstractModal>
  );
};

export default ProductBuildingListModal;
