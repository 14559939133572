import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import { AddPartnerData, EditPartnerData, GetPartnersParams, Partner, PartnerStatus } from "./type";
import { PaPartnerMember, PaPartnerMemberAuthority } from "src/types/partner";
import { QuestionManagersQuery } from "../question/question-type";

/**CPA01. 파트너 목록 페이지 조회 */
export const getPartners = async (axios: AxiosInstance, params?: GetPartnersParams): Promise<AxiosResponse<ApiResponse<{ content: Partner[] }>>> => {
  const _params: any = { ...params };

  if (params?.orders) {
    const _orders = [...params.orders];
    delete _params?.order;

    let index = 0;

    for (const order of _orders) {
      _params[`sort.orders[${index}].direction`] = order.direction;
      _params[`sort.orders[${index}].nullHandling`] = order.nullHandling;
      _params[`sort.orders[${index}].property`] = order.property;
      _params[`sort.orders[${index}].ignoreCase`] = order.ignoreCase;

      index++;
    }
  }

  return await axios.get(ApiPath.partner, {
    params: _params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { encode: true }),
    },
  });
};

/**CPA01. 파트너 목록 페이지 조회 */
export const getPartnerList = async (
  axios: AxiosInstance,
  params?: GetPartnersParams,
): Promise<AxiosResponse<ApiResponse<{ content: Partner[] }>>> => {
  const _params: any = { ...params };

  if (params?.orders) {
    const _orders = [...params.orders];
    delete _params?.order;

    let index = 0;

    for (const order of _orders) {
      _params[`sort.orders[${index}].direction`] = order.direction;
      _params[`sort.orders[${index}].nullHandling`] = order.nullHandling;
      _params[`sort.orders[${index}].property`] = order.property;
      _params[`sort.orders[${index}].ignoreCase`] = order.ignoreCase;

      index++;
    }
  }

  return await axios.get(ApiPath.partner, {
    params: _params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { encode: true }),
    },
  });
};

// CPA02. 파트너 생성 처리

export const postPartner = async (axios: AxiosInstance, data?: AddPartnerData): Promise<AxiosResponse<ApiResponse<{ content: Array<Partner> }>>> => {
  return await axios.post(ApiPath.partner, data);
};

// CPA03. 파트너 조회
export const getPartner = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  return await axios.get(ApiPath.partner + `/${params?.id}`);
};

const getAPiPathPatchPartner = (id: number | string) => `${ApiPath.partner}/${id}`;

// CPA04. 파트너 수정 처리
export const putPartner = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    data?: EditPartnerData;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  if (params?.id === undefined) throw new Error("id is undefined");

  return await axios.put(getAPiPathPatchPartner(params.id), params?.data);
};

const getApiPathPatchPartnerStatus = (id: number | string) => `${ApiPath.partner}/${id}/status`;

// CPA05. 파트너 상태 수정 처리
export const patchPartnerStatus = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    status?: PartnerStatus;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  if (params?.id === undefined) throw new Error("id is undefined");

  return await axios.patch(getApiPathPatchPartnerStatus(params.id), {
    status: params?.status,
  });
};

// RPA12. 파트너 회원 목록 페이지 조회

export const getPartnerMembersAsync = async (
  axios: AxiosInstance,
  params?: QuestionManagersQuery,
): Promise<AxiosResponse<ApiResponse<{ content: Array<PaPartnerMember> }>>> => {
  return axios.get(ApiPath.partnerMemberList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

// RPA14. 로그인 사용자 파트너 회원 권한 조회
export const getPartnerAuthorityAsync = async (
  axios: AxiosInstance,
  partnerId: string,
): Promise<AxiosResponse<ApiResponse<{ authority: PaPartnerMemberAuthority }>>> => {
  return axios.get(ApiPath.partnerAuthority.replace("{partnerId}", partnerId), {
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};
