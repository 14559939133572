import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { editScheduleStop, getScheduleDetail_RCB01 } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseDatePicker, BaseModal, BaseSelect, BaseTextarea } from "src/components";
import { useErrorModal } from "src/recoil/errorModal/hook";

type Props = {
  showBtn: boolean;
  round: string;
  totalRound: any;
  isOpen: boolean;
  scheduleId: string;
  setIsOpen: any;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick: any;
};

const StopModal = (props: Props) => {
  const { openErrorModal } = useErrorModal();
  const params = useParams();
  const { executeAsync: scheduleStop } = useApiOperation(editScheduleStop);
  const { executeAsync: getScheduleList } = useApiOperation(getScheduleDetail_RCB01);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      scheduleId: 0,
      schedule: {
        isContractCancel: true,
        contractCancelDate: "",
        contractCancelStartOrder: "0",
        contractCancelReason: "",
      },
    },
  });

  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [isSave, setIsSave] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [stopObj, setStopObj] = useState<any>();

  const checkStop = useCallback(async () => {
    const res: any = await getScheduleList({ contractId: Number(params.id), supplyType: "RENTAL" });

    if (res.status >= 200 && res.status <= 299) {
      if (String(res.data.data.scheduleList[0].isContractCancel) === "true") {
        setValue("schedule.isContractCancel", res.data.data.scheduleList[0].isContractCancel);

        setValue("schedule.contractCancelDate", res.data.data.scheduleList[0].contractCancelDate);
        setValue("schedule.contractCancelReason", res.data.data.scheduleList[0].contractCancelReason);
        setValue("schedule.contractCancelStartOrder", String(res.data.data.scheduleList[0].contractCancelStartOrder));
      } else {
        setValue("schedule.contractCancelStartOrder", String(props.round));
      }
      setStopObj(res.data.data.scheduleList[0]);
    }
  }, [getScheduleList, params.id, setValue, props.round]);

  const updateScheduleStop = useCallback(
    async (data: FormData) => {
      if(options.length === 0){
        setValue('schedule.contractCancelStartOrder', props.totalRound.length +1)
      }
      let temp: any = {
        isContractCancel: getValues("schedule.isContractCancel"),
        contractCancelStartOrder: getValues("schedule.contractCancelStartOrder"),
      };

      if(getValues("schedule.contractCancelReason") !==''){
        temp.contractCancelReason = getValues("schedule.contractCancelReason");
      }
      if(getValues("schedule.contractCancelDate") !==null && getValues("schedule.contractCancelDate") !==''){
        temp.contractCancelDate = getValues("schedule.contractCancelDate");
      }

      // console.log("temp", temp);
      setStopObj({ ...stopObj, ...temp });
      props.onClick({ ...stopObj, ...temp }, { ...stopObj, ...temp });

      const response: any = await scheduleStop({ scheduleId: stopObj.scheduleId, schedule: temp });
      if (response.status >= 200 && response.status <= 299) {
        setIsSuccess(true);
      }

      await setIsSave(false);
    },
    [getValues, setValue, props, scheduleStop, stopObj, options],
  );

  const submit = useCallback(() => {
    updateScheduleStop(stopObj);
  }, [stopObj, updateScheduleStop]);

  const confirm = useCallback((data: FormData, e?: any) => {
    setIsSave(true);
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      const message = "필수 입력 사항입니다.";
      const option: any = [];

      props.totalRound.forEach((row: any) => {
        if (row.original.bill.billPayStatus === "PAYMENT_READY" || row.original.bill.billPayStatus === "PAYMENT_STOP") {
          option.push({
            label: String(row.original.bill.billOrder),
            value: String(row.original.bill.billOrder),
          });
        }
      });

      setOptions(option);
      

      register("schedule.isContractCancel");

      register("schedule.contractCancelStartOrder", {
        required: message,
      });

      checkStop();
      // console.log("showBtn", props.showBtn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, checkStop]);

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    //console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };
  return (
    <div>
      <>
        <BaseModal className="dialog-modal  small-size" isOpen={props.isOpen}>
          <>
            <form onSubmit={handleSubmit(confirm, onError)}>
              <div className="modal-title border-bottom">
                <div className="left-area align-items-center">
                  <span>빌링스케줄 중지</span>
                  <p className="sub-title-info ml40">계약자와 협의하신 내용을 입력해 주세요. </p>
                </div>
                <div className="right-area"></div>
              </div>
              <div className="text-left px30 pt20 pb100">
                {options.length >0 &&
                  <section className="flex-center-start mb20">
                    <div className="minmax140">
                      <p className="text-gray600 required">선택 회차</p>
                    </div>
                    <div className="">
                      <div className="minmax120">
                        <Controller
                          control={control}
                          name="schedule.contractCancelStartOrder"
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return (
                              <BaseSelect
                                name={name}
                                value={String(value)}
                                stateOptions={options}
                                setStateValue={onChange}
                                errorText={error?.message}
                                isDisabled={!props.showBtn}
                              />
                            );
                          }}
                        ></Controller>
                      </div>
                    </div>
                  </section>
                  }
                <section className="flex-center-start mb20">
                  <div className="minmax140">
                    <p className="text-gray600">중지 일시</p>
                  </div>
                  <div className="minmax300">
                    <Controller
                      control={control}
                      name="schedule.contractCancelDate"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseDatePicker
                            timeIntervals={10}
                            type="date"
                            selectedDate={value ? moment(value).toDate() : null}
                            setDate={onChange}
                            name={name}
                            errorText={error?.message}
                            disabled={!props.showBtn}
                          />
                        );
                      }}
                    ></Controller>
                    {/* {errors?.remodelingYmd?.message && <p className="validation-text">{errors?.remodelingYmd?.message}</p>} */}
                  </div>
                </section>
                <section className="flex-center-start">
                  <div className="minmax140">
                    <p className="text-gray600">중지 사유</p>
                  </div>
                  <div className="minmax300">
                    <Controller
                      control={control}
                      name="schedule.contractCancelReason"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseTextarea
                          placeholder="중지사유를 입력해주세요"
                          className="mr8"
                          onChange={onChange}
                          value={value}
                          name={name}
                          maxLength={1000}
                          errorText={error?.message}
                          disabled={!props.showBtn}
                        />
                      )}
                    ></Controller>
                  </div>
                </section>
              </div>
              <div className="base-modal__btn-wrap">
                {props.showBtn === true ? (
                  <>
                    <BaseButton title={"취소"} onClick={props.onClose} className="color-white no-outline text-gray600 mr10" />

                    <BaseButton title="저장" type="submit" />
                  </>
                ) : (
                  <>
                    <BaseButton title={"확인"} onClick={props.onClose} className="no-outline mr10" />
                  </>
                )}
              </div>
            </form>
          </>
        </BaseModal>
        <BaseModal
          isOpen={isSave}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setIsSave(false)}
          onClick={() => submit()}
          title={"저장하시겠습니까?"}
        ></BaseModal>
        <BaseModal isOpen={isSuccess} btnRightTitle="확인" onClick={() => setIsSuccess(false)} title={"저장되었습니다."}></BaseModal>
      </>
    </div>
  );
};

export default StopModal;
