import moment from "moment";
import "moment/locale/ko";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getMgmtOfficeAsync } from "src/api/mgmtOffice/mgmt-office-api";
import { MgmtOffice, Officer } from "src/api/mgmtOffice/mgmt-office.types";
import { getProviderBasicAsync } from "src/api/provider/provider-api";
import { ProviderBasicModel } from "src/api/provider/provider-types";
import { getWorkOrderListAsync } from "src/api/work-order/workorder-api";
import {
  PassParam,
  WorkOrderDetail as WorkOrder,
  WorkOrderListParams,
  WorkOrderStatus,
  getWorkOrderStatusColor,
  getWorkOrderStatusText,
} from "src/api/work-order/workorder-types";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { workSheetNoWithHyphen } from "src/utils/common-util";

/**
 * 워크오더 목록
 *
 */

// feature 0 : WorkOrderDetail.tsx에서 navigate 시, 쿼리파람을 보냄 (관리처 정보)

const WorkOrderList = () => {
  const location = useLocation();
  const { mgmtOfficerId } = useParams();

  const queryParams: WorkOrderListParams & PassParam = useMemo(() => {
    const _queryParams: WorkOrderListParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, [location]);

  const [workSheetList, setWorkSheetList] = useState<WorkOrder[]>([]); // 워크오더 목록
  const [mgmtOffice, setMgmtOffice] = useState<MgmtOffice>(); // 관리처 조회
  const [providerBasic, setProviderBasic] = useState<ProviderBasicModel>(); // 정산정보 조회
  const { executeAsync: getWorkOrderList } = useApiOperation(getWorkOrderListAsync, { noAuthenticationRequired: true }); // 워크오더 목록 조회
  const { executeAsync: getMgmtOffice } = useApiOperation(getMgmtOfficeAsync, { noAuthenticationRequired: true }); // 관리처 조회
  const { executeAsync: getProviderBasic } = useApiOperation(getProviderBasicAsync, { noAuthenticationRequired: true }); // 정산정보 기본 조회

  const navigate = useNavigate();

  useEffect(() => {
    const fetchGetWorkOrderList = async (fetchParam: WorkOrderListParams) => {
      const { data } = await getWorkOrderList(fetchParam);
      if (data?.data?.content) {
        setWorkSheetList(data.data.content);
        if (queryParams.mgmtOfficeId) {
          // 관리처 조회 - 담당 관리자 필요
          const { data: mgmtData } = await getMgmtOffice({
            id: queryParams.mgmtOfficeId,
          });
          setMgmtOffice(mgmtData?.data?.content);
          if (mgmtData?.data?.content?.providerId) {
            //정산정보 조회 - 관리처명 필요
            const { data: providerBasic } = await getProviderBasic({ providerId: mgmtData?.data?.content?.providerId });
            setProviderBasic(providerBasic?.data.provider);
          }
        }
      }
    };
    if (mgmtOfficerId) {
      fetchGetWorkOrderList({
        keywordList: mgmtOfficerId,
        ...{ page: 0, size: 200 },
        ...{
          sort: {
            orders: [
              {
                property: "startDate",
                direction: "ASC",
              },
            ],
          },
        },
      });
    }
  }, [getWorkOrderList, mgmtOfficerId]);

  // 관리자 찾기
  const filterPrimaryManagers = (officerList?: Officer[]) => {
    const primary = officerList?.filter((manager) => manager.isPrimary && !manager.isDeleted).map((primary) => primary.name);

    return primary?.join(",") || "-";
  };
  const findManager = (officerList?: Officer[]) => {
    const manager = officerList?.find((manager) => manager.id === mgmtOfficerId);
    return manager;
  };

  return (
    <>
      <MetaTag title="업무 목록" />
      <div className="page-workorder-mobile workorder-list">
        <section className="page-workorder-mobile__summary">
          <div className="page-workorder-mobile__summary-title-list">
            <h1>{findManager(mgmtOffice?.officerList)?.name}님</h1>
            <p>
              {providerBasic?.providerName || "-"} (담당:{filterPrimaryManagers(mgmtOffice?.officerList)})
            </p>
          </div>
        </section>
        {workSheetList && (
          <section className="page-workorder-mobile__contents-list">
            <div className="contents">
              {workSheetList.length === 0 ? (
                <div className="list-empty-case">
                  <p>현재 업무가 없습니다</p>
                </div>
              ) : (
                <>
                  {workSheetList.map((workSheet) => (
                    <div
                      className="list-box"
                      key={workSheet.id}
                      onClick={() => {
                        navigate(`/mng/ctrl/public/workOrder/${workSheet.workOrderSheetNo}`);
                      }}
                    >
                      <div className="list-box__head">
                        <div className="list-box__head-left">
                          {workSheet.status && (
                            <>
                              <div className={`base-chip-mobile ${getWorkOrderStatusColor(getWorkOrderStatusText(workSheet.status))}`}>
                                <span>{getWorkOrderStatusText(workSheet.status)}</span>
                              </div>
                              {(workSheet.status === WorkOrderStatus.OPEN || workSheet?.status === WorkOrderStatus.PROGRESS) && (
                                <>
                                  {moment(moment().format("YYYY-MM-DD")).isAfter(workSheet?.endDate) ? (
                                    <div className="base-chip-mobile color-red">
                                      <span>지연</span>
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div className="list-box__head-right">
                          <span>{workSheet?.workOrderSheetNo ? workSheetNoWithHyphen(workSheet?.workOrderSheetNo) : ""}</span>
                        </div>
                      </div>
                      <div className="list-box__content">
                        <p>{workSheet.summary}</p>
                      </div>
                      <div className="list-box__info">
                        <span>업무 기간 : {workSheet.startDate ? moment(workSheet.startDate).format("YYYY.MM.DD(dd)") : ""}</span>
                        <span className="mx4">-</span> <span>{workSheet.endDate ? moment(workSheet.endDate).format("YYYY.MM.DD(dd)") : ""}</span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        )}
      </div>
    </>
  );
};
export default WorkOrderList;
