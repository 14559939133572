import { ProviderAccountModel } from "src/api/provider/provider-types";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  providerAccount?: Array<ProviderAccountModel>;
};

const ProviderAccountDetail = ({ providerAccount }: Props) => {
  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title={"계좌 정보"} />

      <section className="contents-container__1200">
        {providerAccount && providerAccount.length > 0 ? (
          providerAccount?.map((account: ProviderAccountModel) => {
            return (
              <div key={account.accountId} className="border-gray py16 pr16 px20 position-relative mb10">
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className=" font13 text-gray600">용도</span>
                  </div>
                  <div className="flex-center h-36">
                    {account.accountType === "DEPOSIT_ACCOUNT" ? (
                      <span className="font13">보증금</span>
                    ) : account.accountType === "RENT_ACCOUNT" ? (
                      <span className="font13">임대료</span>
                    ) : (
                      <span className="font13">관리비</span>
                    )}
                  </div>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className=" font13 text-gray600">은행</span>
                  </div>
                  <div className="minmax160 font13">{account.bankName || ""}</div>
                </section>
                <section className="flex-center mb10">
                  <div className="minmax123">
                    <span className=" font13 text-gray600">계좌번호</span>
                  </div>
                  <div className="minmax400 font13">
                    <span>{account.accountNumber}</span>
                  </div>
                </section>
                <section className="flex-center">
                  <div className="minmax123">
                    <span className=" font13 text-gray600">예금주</span>
                  </div>
                  <div className="flex-center minmax400 font13">
                    <span>{account.accountHolder}</span>
                  </div>
                </section>
              </div>
            );
          })
        ) : (
          <p className="font14">등록된 계좌 정보가 없습니다.</p>
        )}
      </section>
    </article>
  );
};

export default ProviderAccountDetail;
