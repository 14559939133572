// ========================================== 건물/호실 함수 시작 =================================================

import { ProductBuilding } from "src/api/product/product-types";
import { BuildingFloorAndHo, PropertyType } from "../../product-types";
import _ from "lodash";
import { UseFormSetValue } from "react-hook-form";
import { BuildingFloorModel, BuildingModel } from "src/api/building/building-types";

// 수정 시, 건물 층 호실 대표여부 (isPrimary) checked
export const isPrimaryBuildingChecked = (table: BuildingFloorAndHo[], setSelectedRow: any, id?: any) => {
  let pri = {};

  const primaryIdx = table.findIndex((item: BuildingFloorAndHo) => item.isPrimary === true);
  const primaryItem = table.find((item: BuildingFloorAndHo) => item.isPrimary === true);

  if (id) {
    let wasPrimary = Object.assign(pri, { [primaryIdx]: true });

    if (!primaryItem) {
      wasPrimary = Object.assign(pri, { 0: true });
    }
    setSelectedRow(wasPrimary);
  } else {
    const wasPrimary = Object.assign(pri, { 0: true });
    setSelectedRow(wasPrimary);
  }
};

// ============== 공간상품 처음 등록 시 건물/층 목록 ==============
// 서버에서 가져온 properties와 층/호실 팝업에서 선택한 selectedRooms를 비교해 ID 값이 일치하는 것만 리턴
export const onDrawViewTable = (
  rooms: Array<PropertyType>,
  setSelectedRow: any,
  setValue: UseFormSetValue<any>,
  selectedBuilding: any,
  setBuildingAndHo: any,
  buildingAndHo: any,
) => {
  const viewArr: Array<BuildingFloorAndHo> = []; // view 용

  rooms.forEach((item) => {
    const viewData = {
      buildingId: String(selectedBuilding?.id),
      roomId: String(item.roomId),
      buildingName: selectedBuilding?.buildingName,
      floorNum: item.floorNum,
      roomNum: item.roomNum,
      leasableAreaNet: item.leasableAreaNet,
      leasableArea: item.leasableArea,
      isPrimary: false,
      floorId: String(item.floorId),
      rateOfUse: (Number(item.leasableAreaNet) / Number(item.leasableArea)) * 100,
    };

    viewArr.push(...buildingAndHo, viewData);
  });
  setBuildingAndHo(viewArr);

  // 이미 있는 리스트는 제거
  const filteredViewTable = viewArr.filter((item: BuildingFloorAndHo, i: number) => {
    return (
      viewArr.findIndex((item2: BuildingFloorAndHo, j: number) => {
        return item.roomId === item2.roomId;
      }) === i
    );
  });

  setValue("buildingList", filteredViewTable, { shouldDirty: true });
  isPrimaryBuildingChecked(filteredViewTable, setSelectedRow);
};

/** 테이블 형식의 테이터를 API 요청용 ProductBuilding 테이터로 변환 */
export const changeBuildings = (table: BuildingFloorAndHo[]): ProductBuilding[] => {
  return _.chain(table)
    .groupBy("buildingId")
    .map(function (v, i) {
      return {
        id: Number(_.get(_.find(v, "buildingId"), "buildingId")),
        productBuildingFloorList: _.chain(v)
          .groupBy("floorId")
          .map((v, i) => {
            return {
              id: Number(i),
              productBuildingRoomList: v.map((a) => {
                return {
                  id: Number(a.roomId),
                  isPrimary: a.isPrimary,
                };
              }),
            };
          })
          .value(),
      };
    })
    .orderBy(["buildingId"])
    .value();
};

export const makeBuilding = (buildingArr: BuildingModel[]) => {
  let tableArr: BuildingFloorAndHo[] = [];
  buildingArr?.map((item) => {
    const building = item;
    const floorList = building?.buildingFloorList;
    floorList?.map((floor: BuildingFloorModel) => {
      const roomList = floor.buildingRoomList!;
      roomList.map((room) =>
        tableArr.push({
          buildingId: String(building.id),
          buildingName: building.buildingName,
          floorId: String(floor.id),
          floorNum: Number(floor.floorNum),
          roomId: String(room.id),
          roomNum: Number(room.roomNum),
          isPrimary: Boolean(room.isPrimary),
          leasableArea: String(room.leasableArea),
          leasableAreaNet: String(room.leasableAreaNet),
          rateOfUse: (Number(room.leasableAreaNet) / Number(room?.leasableArea)) * 100,
        }),
      );
    });
  });
  return tableArr;
}; // react Hook Form 부분

// ========================================== 건물/호실 함수 끝 =================================================

// 프로바이더명 조회
export const getProviderName = async <T extends () => Promise<any>>(providerId: string, getProviers: T) => {
  const response = await getProviers();

  const provider = response?.data?.data?.content.filter((item: { providerId: any }) => String(item.providerId) === providerId);

  if (provider?.length > 0 && provider[0]) {
    const providerData = {
      providerId: providerId,
      uid: providerId,
      providerName: String(provider[0].providerName),
    };

    return providerData;
  }
};

export const ProductErrorDataMap = {
  //기본정보
  providerId: {
    tabName: "기본정보",
    containerName: "기본정보",
    itemName: "프로바이더",
  },
  productType: {
    tabName: "기본정보",
    containerName: "기본정보",
    itemName: "상품유형",
  },
  productBuildingList: {
    tabName: "기본정보",
    containerName: "기본정보",
    itemName: "건물/호실",
  },
  productName: {
    tabName: "기본정보",
    containerName: "기본정보",
    itemName: "상품명",
  },
  introduce: {
    tabName: "기본정보",
    containerName: "기본정보",
    itemName: "1줄 소개",
  },
  //전시정보
  description: {
    tabName: "전시정보",
    containerName: "상세정보",
    itemName: "상품소개",
  },

  minLeasePeriod: {
    tabName: "전시정보",
    containerName: "상세정보",
    itemName: "계약기간(최소)",
  },
  maxLeasePeriod: {
    tabName: "전시정보",
    containerName: "상세정보",
    itemName: "계약기간(최대)",
  },

  // isRightAwayMoveIn: {
  //   tabName: "전시정보",
  //   containerName: "상세정보",
  //   itemName: "즉시입주가능여부",
  // },
  moveInAvailDate: {
    tabName: "전시정보",
    containerName: "상세정보",
    itemName: "입주 가능일",
  },

  //
  vocUrl: {
    tabName: "전시정보",
    containerName: "고객센터",
    itemName: "문의채널",
  },
  colorName: {
    tabName: "전시정보",
    containerName: "Taap 홈화면",
    itemName: "테마색상",
  },
  mediaList: {
    tabName: "이미지",
    containerName: "이미지",
    itemName: "공간상품 / 사무공간",
  },

  deposit: {
    tabName: "요금/결제",
    containerName: "요금 관리",
    itemName: "보증금",
  },
  // earnest: {
  //   tabName: "요금/결제",
  //   containerName: "요금 관리",
  //   itemName: "보증금",
  // },
  // balanceFullPaymentDate: {
  //   tabName: "요금/결제",
  //   containerName: "요금 관리",
  //   itemName: "보증금",
  // },

  costType: {
    tabName: "요금/결제",
    containerName: "요금 관리",
    itemName: "이용료 납부유형",
  },
  // productionCost: {
  //   tabName: "요금/결제",
  //   containerName: "요금 관리",
  //   itemName: "이용료 납부유형",
  // },
  // rentalCostList: {
  //   tabName: "요금/결제",
  //   containerName: "요금 관리",
  //   itemName: "이용료 납부유형",
  // },

  refundInfo: {
    tabName: "요금/결제",
    containerName: "결제 관리",
    itemName: "결제 및 취소 안내",
  },
  parkingLotFreeDescription: {
    tabName: "공용공간",
    containerName: "주차",
    itemName: "상세 설정(무료/유료)",
  },
  isProviderDefaultPriceRule: {
    tabName: "공용공간",
    containerName: "공용공간 기본 요금 설정 여부",
    itemName: "",
  },
  maxNums: {
    tabName: "출입 그룹",
    containerName: "이용자 관리",
    itemName: "최대인원 설정",
  },
  accessGroup: {
    tabName: "출입 그룹",
    containerName: "출입그룹 관리",
    itemName: "출입그룹 추가",
  },
};

export function getErrorData(fieldName: keyof typeof ProductErrorDataMap) {
  const entry = ProductErrorDataMap[fieldName];

  if (entry) {
    return `${entry.tabName} > ${entry.containerName} > ${entry.itemName}`;
  } else {
    return null; // 항목이 존재하지 않을 경우 null 반환
  }
}
