import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CommonFacilityModel } from "src/api/building/building-types";
import { getCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import { BaseModal } from "src/components";
import ReadOnlyPrAdminMemoTable from "src/components/adminmemo/ReadOnlyPrAdminMemoTable";
import GoToListButton from "src/components/GoToListButton";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import { getReservationStatusDetail, Modal, ReservationState } from "../../reservation-types";
import { DeskSection, MeetingRoomSection, RefreshRoomSection, ReservationHistorySection } from "./components";
import { useErrorModal } from "src/recoil/errorModal/hook";
import MetaTag from "src/components/layout/MetaTag";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import RegistrationInformation from "src/components/RegistrationInformation";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

/* 
  공용공간 예약 > 상세 > 기본 정보
  feature :: 탭내 상태변경을 사용하기때문에 상위 ReservationDetail 에서 공용공간상세 호출.
 */
const BasicInfoDetail = (props: any) => {
  const { id } = useParams();
  const { openErrorModal } = useErrorModal();
  // 건물 공용공간 상세 조회 api
  const { executeAsync: getCommonfacility } = useApiOperation(getCommonfacilityAsync, { noHandleError: true });

  // 건물 공용공간 상세
  const [commonFacility, setCommonFacility] = useState<CommonFacilityModel | null>(null);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const location = useLocation();
  // 공용공간 예약 상세 후 데이터 바인딩
  const fetchReservation = useCallback(async () => {
    if (props.reservation) {
      if (!props.reservation.facility.building.id) {
        throw Error("reservation.facility.building.id is not found.");
      }
      if (!props.reservation.facility.id) {
        throw Error("reservation.facility.id is not found.");
      }
      const response = await getCommonfacility({
        buildingId: String(props.reservation.facility.building.id),
        facilityId: String(props.reservation.facility.id),
      });

      if (response.status >= 200 && response.status <= 299) {
        const facilityData = response.data?.data?.content;
        if (facilityData) setCommonFacility(facilityData);
      } else {
        let errorCode = response.data.meta?.errorCode;
        let statusCode = response.status;
        if (errorCode === "ePR0514") {
          // 없는 회의실 모달 비오픈
          return;
        } else {
          openErrorModal({ errorCode, statusCode });
        }
      }
    }
  }, [getCommonfacility, props.reservation]);

  useEffect(() => {
    fetchReservation();
  }, [fetchReservation]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 기본정보 */}
            <BaseSectionTitle title={"기본정보"} />
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="mr10">
                    <span className="font14">{getReservationStatusDetail(props.reservation?.status.code)}</span>
                  </div>
                </div>
              </div>
            </section>

            {props.reservation?.status === ReservationState.CANCELLED && (
              <>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">취소일시</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <p>{props.reservation?.lastModifiedDate ? moment(props.reservation?.lastModifiedDate).format(ViewYmdFormat.FULL) : "-"} </p>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">최종수정자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <p>{props.reservation?.lastModifiedBy.name || "-"}</p>
                    </div>
                  </div>
                </section>
              </>
            )}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">신청번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400 flex-row flex-center-start">
                  {props.reservation?.contract.applyNumber ? (
                    <BaseNewTabLink
                      path={`${PagePath.contract.detail.replace(":id", props.reservation?.contract.id || "")}`}
                      value={props.reservation?.contract.applyNumber}
                      className="w-100 text-left"
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </section>
          </article>

          {/* 예약 내역 */}
          <ReservationHistorySection reservation={props.reservation} />

          {/* 회의실 */}
          {props.reservation?.facility.type === "MEETING_ROOM" && (
            <MeetingRoomSection reservation={props.reservation} commonFacility={commonFacility} />
          )}

          {/* 좌석 */}
          {props.reservation?.facility.type === "DESK" && <DeskSection commonFacility={commonFacility} />}

          {/* 편의시설 */}
          {props.reservation?.facility.type === "REFRESH_ROOM" && <RefreshRoomSection commonFacility={commonFacility} />}
          <RegistrationInformation
            data={{
              createdBy: props.reservation?.createdBy.name,
              modifiedBy: props.reservation?.lastModifiedBy.name,
              createdDate: props.reservation?.createdDate,
              modifiedDate: props.reservation?.lastModifiedDate,
            }}
          />

          <AdminMemoV3 serviceId={Number(id)} serviceType={ServiceTypes.SERVICE_RESERVATION} />

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            title={alertModal.message}
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          ></BaseModal>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
