import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ESignRecipient, ESignRecipientAPIFormat, SaveEsignDatas } from "src/api/contract/contract-types";
import { BaseButton, BaseModal } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { BaseStep } from "src/components/BaseStep";
import { parsedPhoneNumber } from "src/utils";
import useEsignApi from "../../hooks/useEsignApi";
import EsignStepOne from "./components/EsignStepOne";
import EsignStepThree from "./components/EsignStepThree";
import EsignStepTwo from "./components/EsignStepTwo";

interface Props {
  setIsSaved: any;
  flags: boolean;
  setEditable: any;
  setESignFlags: any;
  initDatas: any;
  initMainData?: any;
  setIsEsign?: any;
  setData?: any;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
interface Step {
  stepNumber: number;
  label: string;
  dataCheck: boolean;
  children: React.ReactNode;
}

const MakeEsignInfo = ({ flags, setIsSaved, setESignFlags, initDatas, setEditable, initMainData, setIsEsign, setData }: Props) => {
  const { id } = useParams();
  const { getMemberInfo, getWritedPreviewData, confirmEsignData } = useEsignApi();
  const [selectedTemplate, setSeletedTemplate] = useState<any>({});
  const [isSave, setIsSave] = useState(false);

  const [isPassed, setIsPassed] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [sendUser, setSendUser] = useState<any>({});
  const [workflow, setWorkflow] = useState<SaveEsignDatas>({});
  const [writeUrl, setWriteUrl] = useState("");
  const steps: Array<Step> = [
    {
      stepNumber: 0,
      label: "서식 선택",
      dataCheck: false,
      children: (
        <EsignStepOne selectedTemplate={selectedTemplate} setSeletedTemplate={setSeletedTemplate} workflow={workflow} setWorkflow={setWorkflow} />
      ),
    },
    {
      stepNumber: 1,
      label: "문서 설정",
      dataCheck: false,
      children: (
        <EsignStepTwo
          selectedTemplate={selectedTemplate}
          setSeletedTemplate={setSeletedTemplate}
          sendUser={sendUser}
          workflow={workflow}
          setWorkflow={setWorkflow}
        />
      ),
    },

    {
      stepNumber: 2,
      label: "확인 및 전송",
      dataCheck: false,
      children: <EsignStepThree sendUser={sendUser} workflow={workflow} isPassed={isPassed} setIsPassed={setIsPassed} writeUrl={writeUrl} />,
    },
  ];
  const [isOpen, setIsOpen] = useState(flags);
  const [activeTab, setActiveTab] = useState(0);

  const changeListObject = (saveData: SaveEsignDatas) => {
    const list = saveData.recipientList!;
    const arr: Array<ESignRecipientAPIFormat> = [];
    for (let i = 0; i <= list.length - 1; i++) {
      const ar: ESignRecipientAPIFormat = {
        order: Number(list[i].order),

        name: String(list[i].name),
        email: String(list[i].email),
      };
      if (list[i].mobile) {
        ar.mobile = parsedPhoneNumber(list[i].mobile!);
        // ar.mobile = list[i].mobile!;
      } else {
        ar.mobile = "";
      }
      if (list[i].password) {
        ar.password = list[i].password;
      } else {
        ar.password = "";
      }
      if (list[i].passwordHint) {
        ar.passwordHint = list[i].passwordHint;
      } else {
        ar.passwordHint = "";
      }

      arr.push(ar);
    }
    saveData.recipientList = arr;

    console.log("saveData", saveData);
    return saveData;
  };

  const confirmSign = useCallback(
    async (workflow: SaveEsignDatas) => {
      const saveData = changeListObject(workflow);
      const sendData = { contractId: id, workflow: saveData };
      const response: any = await confirmEsignData(sendData);

      setIsSaved(true);
      setESignFlags(false);
      setIsEsign(true);
      setEditable(false);
      initDatas();
      initMainData();
      setData(String(id));
    },
    [confirmEsignData, id, initDatas, setESignFlags, setEditable, setIsSaved, initMainData],
  );

  const getLoginEsignMemeber = useCallback(async () => {
    const response = await getMemberInfo();
    // console.log("response.data.data.esignonMember", response.data.data.esignonMember);

    setSendUser(response.data.data.esignonMember);
  }, [getMemberInfo]);

  const getWritedPreview = useCallback(
    async (workflow: SaveEsignDatas) => {
      const saveData = changeListObject(workflow);
      const url = await getWritedPreviewData({ contractId: id, workflow: saveData });
      setWriteUrl(url);
      // console.log("url", url);
    },
    [getWritedPreviewData, id],
  );

  useEffect(() => {
    getLoginEsignMemeber();
    if (selectedTemplate && selectedTemplate.templateId) {
      setWorkflow({ ...workflow, ...{ templateId: selectedTemplate.templateId } });
    }
  }, [selectedTemplate, setSeletedTemplate]);

  useEffect(() => {
    if (flags === true) {
      setIsOpen(flags);
    }
  }, [flags]);

  useEffect(() => {
    if (activeTab === 0) {
      if (selectedTemplate && selectedTemplate.templateId) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    }
    if (activeTab === 1) {
      setBtnDisabled(checkDataforDisable);
    }
    if (activeTab === 2) {
      setBtnDisabled(false);
    }
  }, [activeTab, selectedTemplate]);

  useEffect(() => {
    if (activeTab === 1) {
      setBtnDisabled(checkDataforDisable);
    }
  }, [workflow]);

  const checkDataforDisable = useMemo(() => {
    let flag = false;
    const checkPhone = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
    const checkEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    console.log("workflow", workflow);
    if (workflow.recipientList === undefined) {
      flag = true;
    } else {
      workflow.recipientList!.map((recipient: ESignRecipient) => {
        if (recipient.name === undefined || recipient.name === "") {
          flag = true;
        }
        if (recipient.email === undefined || recipient.email === "") {
          flag = true;
        } else {
          if (checkPhone.test(recipient.email!) === false && checkEmail.test(recipient.email!) === false) {
            flag = true;
          }
        }
      });
    }
    console.log("flag", flag);
    return flag;
  }, [workflow]);

  return (
    <>
      {isOpen && (
        <BaseAbstractModal size="xlarge" isOpen={isOpen}>
          <>
            <section className={`base-abstract-modal__title border-bottom flex-center-between ${activeTab === 2 ? "has-list" : ""}`}>
              <div>
                <h1>전자서명 요청</h1>
                {activeTab === 2 && (
                  <ul className="base-list mt20">
                    <li>문서 전송 후 문서명, 작성기한, 받는 사람 등을 변경하시려면 수정 후 다시 보내기 또는 새로 작성해야 합니다.</li>
                  </ul>
                )}
              </div>
              <div>
                <BaseStep stepList={["서식 선택", "문서 설정", "확인 및 전송"]} selectedStepIndex={activeTab} />
              </div>
            </section>
            <section className="base-abstract-modal__contents px30">{steps[activeTab].children}</section>

            <section className="base-abstract-modal__btn-wrap flex-center-between">
              <BaseButton
                title={"취소"}
                className="color-white"
                onClick={() => {
                  setESignFlags(false);
                }}
              />
              <div className="flex-center">
                {activeTab !== 0 && (
                  <BaseButton
                    title={"이전"}
                    className="color-white mr10"
                    onClick={() => {
                      let tab = activeTab !== 0 ? activeTab - 1 : activeTab;
                      setActiveTab(tab);
                    }}
                  />
                )}
                {activeTab >= 0 && steps.length - 1 > activeTab && (
                  <BaseButton
                    title={"다음"}
                    disabled={btnDisabled}
                    onClick={() => {
                      if (activeTab === 0) {
                        if (selectedTemplate.templateId) {
                          setActiveTab(activeTab + 1);
                        }
                      }
                      if (activeTab === 1) {
                        if (workflow.templateId) {
                          getWritedPreview(workflow);
                          setActiveTab(activeTab + 1);
                        }
                      }
                    }}
                  />
                )}
                {activeTab === steps.length - 1 && (
                  <BaseButton
                    title={"서명요청"}
                    // disabled={isPassed === false}
                    onClick={() => {
                      setIsSave(true);
                    }}
                  />
                )}
              </div>
            </section>
          </>
        </BaseAbstractModal>
      )}
      {isSave && (
        <BaseModal
          isOpen={isSave}
          btnLeftTitle="취소"
          btnRightTitle="전송"
          title={"서명요청을 전송하시겠습니까?"}
          onClick={() => {
            confirmSign(workflow);
          }}
          onClose={() => setIsSave(false)}
        ></BaseModal>
      )}
    </>
  );
};
export default MakeEsignInfo;
