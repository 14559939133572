import { Toast, toastState } from "src/recoil/toast/atom";
import { useRecoilState } from "recoil";
import { baseAbstractModalStateZ1, baseAbstractModalStateZ2, baseModalState } from "./atom";

/**
 * 모달 hook
 */

export function useModal() {
  const [baseModal, setBaseModal] = useRecoilState(baseModalState);
  const [abstractModalZ1, setAbstractModalZ1] = useRecoilState(baseAbstractModalStateZ1);
  const [abstractModalZ2, setAbstractModalZ2] = useRecoilState(baseAbstractModalStateZ2);

  return { baseModal, setBaseModal, abstractModalZ1, setAbstractModalZ1, abstractModalZ2, setAbstractModalZ2 };
}
