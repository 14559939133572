import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Modal } from "src/api/public-types";
import { WorkOrderDetailModel } from "src/api/work-order/workorder-types";
import { BaseModal, ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import useWorkOrder from "../../hooks/useWorkOrder";
import { UnionWorkOrderStatus, workOrderStatus, workOrderTabs } from "../../workOrder-types";
import BasicInfoDetail from "./components/BasicInfoDetail";
import WorkOrderAccess from "./components/WorkOrderAccess";
import WorkOrderHistory from "./components/WorkOrderHistory";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

// const tabs = [
//   { value: "basicInfo", label: "기본정보" },
//   { value: "history", label: "변경내역" },
//   { value: ADMIN_MEMO, label: "관리자 메모" },
// ];

const WorkOrderDetailAuthenticated = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState(""); // 워크오더 서머리 타이틀바용

  // 워크오더 상세 데이터
  const [workOrderDetail, setWorkOrderDetail] = useState<WorkOrderDetailModel>();
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const { getWorkOrderDetail, editWorkOrder } = useWorkOrder();

  const { id: workOrderId } = useParams();

  const { isAuthority } = usePartnerAuthority();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (value) => value,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return workOrderTabs.find((tab) => tab.value === queryParams?.tab);
    }
    return workOrderTabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  const breadCrumbs = [{ value: "workorder", label: "워크오더" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }
  const workSheetNo = useMemo(() => {
    const _workSheetNo = workOrderDetail?.workOrderSheetNo || queryParams.worksheetNo;
    if (_workSheetNo) {
      return `${String(_workSheetNo).slice(0, 8)}-${String(_workSheetNo).slice(8, 13)}`;
    }
  }, [workOrderDetail?.workOrderSheetNo, queryParams.worksheetNo]);

  //워크오더 상세 api
  const fetchWorkOrder = useCallback(
    async (id: string) => {
      if (id) {
        const response = await getWorkOrderDetail({ id: Number(id) });
        if (response.status >= 200 && response.status <= 299) {
          const workOrder = response.data.data.content;
          setSummary(String(workOrder.summary || ""));
          // 워크오더 상세 데이터 저장
          setWorkOrderDetail(workOrder);

          // 건물/관리처를 표기하기 위한 api 호출
        }
      }
    },
    [getWorkOrderDetail],
  );

  useEffect(() => {
    if (workOrderId) {
      fetchWorkOrder(workOrderId);
    }
  }, [location.search]);

  // 상담관리 상태변경
  const onChangeWorkOrderStatus = async (id: string, status: UnionWorkOrderStatus) => {
    const response = await editWorkOrder({ id, workOrderSheet: { status } });
    if (response.status >= 200 && response.status <= 299) {
      await fetchWorkOrder(String(workOrderId));
      setConfirmModal({ isOpen: false });
    }
  };

  return (
    <div>
      <MetaTag subTitle={summary} />
      <ContentsTitle
        title="워크오더"
        tabs={workOrderTabs}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[
          { title: "id", value: workOrderId ? workOrderId : "" },
          { title: "업무 번호", value: workSheetNo || "-" },
        ]}
        selectOptions={workOrderStatus.filter((status, idx) => idx !== 0)}
        selectValue={workOrderDetail?.status}
        setSelectStateValue={(status: UnionWorkOrderStatus) => {
          setConfirmModal({ isOpen: true, title: "상태를 변경하시겠습니까?", payload: status });
        }}
        selectDisabled={(workOrderDetail?.status === "WORK_ORDER_CANCELED" && true) || (workOrderDetail?.status === "WORK_ORDER_CLOSED" && true)}
      />
      {activeTab?.value === "basicInfo" && <BasicInfoDetail setSummary={setSummary} workOrderDetail={workOrderDetail} />}

      {/* 출입관리 */}
      {activeTab?.value === "access" && <WorkOrderAccess workOrder={workOrderDetail} />}

      {/* 변경내역 정보 */}
      {activeTab?.value === "history" && <WorkOrderHistory />}

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            onChangeWorkOrderStatus(String(workOrderId), confirmModal.payload as UnionWorkOrderStatus);
            setConfirmModal({ isOpen: false });
          }}
          title={confirmModal.title}
        ></BaseModal>
      )}
    </div>
  );
};

export default WorkOrderDetailAuthenticated;
