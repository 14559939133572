import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editContractOperationAsync, postOperationFacilityAsync } from "src/api/contract/contract-facility-api";
import { ProductManage } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { axiosInstance } from "src/utils";

/** 공용공간 운영정보 수정
 * RCA32. 계약 - 운영정보 수정
 * postOperationFacilityAsync
 */
const useEditContractOperation = () => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: async (params: { contractId: string; productManage: ProductManage }) => editContractOperationAsync(axiosInstance, params),
    // mutationFn: editContractOperationAsync,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return { ...query };
};

export default useEditContractOperation;
