import { PageMeta, Select, Sort } from "../public-types";

interface ChargeItems {
  buildingId: string | null;
  buildingName: string | null;
  ctMntGroup: CtMntGroup;
}

interface CtMntGroup {
  buildingId?: string | null;
  buildingName?: string | null;
  groupId?: number | null;
  groupName?: string | null;
  isUse?: boolean;
  isDeleted?: boolean;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

interface ChargeItem {
  itemId?: number;
  groupId: number;
  itemName?: string;
  itemNameError?: string;
  itemLabel?: string;
  itemLabelError?: string;
  level1?: string;
  level1Error?: string;
  level2?: string;
  level2Error?: string;
  isApplyTax?: boolean;
  isApplyTaxError?: string;
  isApplyOverdue?: boolean;
  isApplyOverdueError?: string;
  isApplyAdjust?: boolean;
  isApplyAdjustError?: string;
  priceUnit?: string;
  priceUnitError?: string;
  roundingMode?: string;
  roundingModeError?: string;
  orderNum?: number;
  isFreezing?: boolean;
  isUse?: boolean;
  isUseError?: string;
  isDeleted?: boolean;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

const SearchType: Select[] = [
  { label: "전체", value: "" },
  { label: "부과그룹 ID", value: "GROUP_ID" },
  { label: "부과그룹명", value: "GROUP_NAME" },
  { label: "건물 ID", value: "BUILDING_ID" },
  { label: "건물명", value: "BUILDING_NAME" },
  { label: "생성자", value: "CREATED_BY" },
  { label: "수정자", value: "MODIFIED_BY" },
];

export { SearchType };
export type { ChargeItems, ChargeItem, CtMntGroup };
