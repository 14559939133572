import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import { getOrgDetail } from "src/api/organization/org-api";
import { BizType, MbOrgDetail, MbOrgMedia, MediaType, TaxType } from "src/api/organization/org-types";
import { Sort } from "src/api/public-types";
import { BaseButton } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseImageUpload from "src/components/BaseImageUpload";
import GoToListButton from "src/components/GoToListButton";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { companyRegistrationHypen, corpHypen, formatPhoneNumber } from "src/utils";
import TextAttribute from "../../components/TextAttribure";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
};

const OrgBaseInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: orgId } = useParams();
  const { isAuthority } = usePartnerAuthority();
  const classname = "minmax300";
  const [logo, setLogo] = useState<MbOrgMedia[]>([]);
  const [proofs, setProofs] = useState<MbOrgMedia[]>([]);

  // 메모 목록

  // 상세 데이터
  const [detail, setDetail] = useState<MbOrgDetail>();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    if (!_queryParams.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams.size) {
      _queryParams.size = 3;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "adminNote.id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const { executeAsync: getOrganizationDetail } = useApiOperation(getOrgDetail);

  const checkFileType = useCallback((mediaList: any) => {
    if (mediaList && mediaList.length > 0) {
      const pfs = mediaList.filter((media: any) => media.type === MediaType.PROOF);
      const lg = mediaList.filter((media: any) => media.type === MediaType.LOGO);
      setLogo(lg);
      setProofs(pfs);
    }
  }, []);

  const goList = useCallback(() => {
    navigate(PagePath.organization.list);
  }, [navigate]);

  const goEditPage = useCallback(() => {
    const page = PagePath.organization.form + "?id=" + orgId!.toString();
    navigate(page);
  }, [navigate, orgId]);

  const findTaxType = useCallback((taxType: string, bizType: string) => {
    let rtn = "";
    switch (taxType) {
      case TaxType.GENERAL:
        if (bizType === "SP") {
          rtn = "일반 과세";
        } else {
          rtn = "과세";
        }
        break;
      case TaxType.SIMPLIFIED_A:
        rtn = "간이(세금계산서 발급형)";
        break;
      case TaxType.SIMPLIFIED_B:
        rtn = "간이(세금계산서 미발급형)";
        break;
      case TaxType.FREE:
        rtn = "면세";
        break;
      case TaxType.ETC:
        rtn = "기타";
        break;
    }
    return rtn;
  }, []);

  useEffect(() => {
    getOrganizationDetail({ id: Number(orgId) }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        const result = response.data.data;
        setDetail(result);
        checkFileType(result?.mediaList);
      }
    });
  }, [orgId, checkFileType, getOrganizationDetail]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"사업자 상세 정보"} />
            <section className="user-basic-info">
              <div className="user-info-container">
                <div className="flex-center">
                  {/* id */}
                  <TextAttribute title={"id"} value={detail?.organization.id} classname={classname} />
                </div>
                <div className="flex-center">
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>구분</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>
                        {detail?.organization.classifications
                          .map((classification) => (classification === "GENERAL_BIZ" ? "일반 사업자" : "소유주"))
                          .join(", ")}
                      </p>
                    </div>
                  </section>{" "}
                </div>
                <div className="flex-center">
                  <TextAttribute title={"사업자 형태"} value={detail?.organization.bizType === BizType.CORP ? "법인 사업자" : "개인 사업자"} />
                </div>
                <div className="flex-center">
                  <TextAttribute title={"법인명/상호"} value={detail?.organization.name} classname={classname} />
                </div>
                <div className="flex-center">
                  <TextAttribute
                    title={"사업자 등록번호"}
                    value={companyRegistrationHypen(detail?.organization.bizRegNo || "-")}
                    classname={classname}
                  />
                </div>
                {detail?.organization.bizType === BizType.CORP && (
                  <div className="flex-center">
                    <TextAttribute title={"법인 등록번호"} value={corpHypen(detail?.organization.corRegNo || "-")} classname={classname} />
                  </div>
                )}
                <div className="flex-center">
                  <TextAttribute title={"대표자/성명"} value={detail?.organization.ceoName} classname={classname} />
                </div>
                <div className="flex-center">
                  <TextAttribute title={"업태"} value={detail?.organization.bizCondition} classname={classname} />
                </div>
                <div className="flex-center">
                  <TextAttribute title={"종목"} value={detail?.organization.bizItem} classname={classname} />
                </div>
                <div className="flex-center">
                  <TextAttribute
                    title={"과세 유형"}
                    value={findTaxType(String(detail?.organization.taxType), String(detail?.organization.bizType))}
                    classname={classname}
                  />
                </div>
                <div className="flex-center">
                  <TextAttribute title={"사업장 주소지"} value={detail?.organization.address} classname={classname} />
                </div>
                {/* <div className="flex-center">
                  <TextNFileDownload title={`사업자 증빙 서류`} fileList={proofs} classname={classname} />
                </div> */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>사업자 증빙 서류</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="user-info-container">
                      {/* <BaseFileUpload isOnlyView fileList={proofs} /> */}
                      <BaseImageUpload isOnlyView type="PROOF" images={proofs} />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>로고 이미지</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="user-info-container">
                      <BaseImageUpload type="LOGO" isOnlyView images={logo} />
                    </div>
                  </div>
                </section>
                <div className="flex-center">
                  <TextAttribute title={"이메일"} value={detail?.organization.email} classname={classname} />
                </div>
                <div className="flex-center">
                  <TextAttribute title={"휴대폰"} value={formatPhoneNumber(detail?.organization.phoneNumber || "")} classname={classname} />
                </div>
              </div>
            </section>
          </article>
          <RegistrationInformation
            data={{
              createdBy: detail?.organization?.createdBy,
              modifiedBy: detail?.organization?.lastModifiedBy,
              createdDate: detail?.organization?.createdDate,
              modifiedDate: detail?.organization?.lastModifiedDate,
            }}
          />

          <AdminMemoV3 serviceId={Number(orgId)} serviceType={ServiceTypes.SERVICE_ORGANIZATION} />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">{isAuthority("w") && <BaseButton title="수정" onClick={() => goEditPage()} className=" size-large" />}</div>
      </div>
    </>
  );
};

export default OrgBaseInfo;
