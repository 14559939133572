import { useEffect, useState } from "react";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import BaseSelectTable from "src/components/BaseSelectTable";
import BaseNewTabLink from "../BaseNewTabLink";
import { PagePath } from "src/pages/product/details";

const columns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 120,
    Cell: ({ value }: { value?: string }) => {
      if (!value) {
        return "-";
      }
      return <BaseNewTabLink path={PagePath.provider.detail.replace(":id", value)} value={value} ellipsis className="ml16" />;
    },
  },

  {
    Header: "건물명",
    accessor: "buildingName",
    width: 300,
    Cell: ({ value }: { value?: string }) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "주소",
    accessor: "address",
    width: 420,
    Cell: ({ value }: { value?: string }) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
];
type Props = {
  partnerId?: string; //TODO: 필요 없으면 관련코드 제거 해주세요.
  onClick?: (building: BuildingModel) => void;
  onClose?: () => void;
  defaultCheckedList?: string[];
  defaultDisabledList?: string[];
};

const SelectBuildingPopup = ({ partnerId, onClick, onClose, defaultCheckedList, defaultDisabledList }: Props) => {
  console.log("defaultCheckedList :>> ", defaultCheckedList);
  const [buildingList, setBuildingList] = useState<BuildingModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  const [params, setParams] = useState({
    page: 0,
    size: 20,
    keyword: "",
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    getBuildings(params).then((res) => {
      setBuildingList(res?.data?.data?.content);
      setPageMeta(res?.data?.meta?.pageMeta);
    });
  }, [params]);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultDisabledList={defaultDisabledList}
      defaultCheckedList={defaultCheckedList}
      title="건물 선택"
      selectKey={"id"}
      columns={columns}
      data={buildingList ?? []}
      checkboxType={"radio"}
      onClick={(_, checkedObjList) => onClick && onClick(checkedObjList[0])}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        onSearch(searchKeyword) {
          setParams((prev) => ({ ...prev, keyword: searchKeyword, page: 0 }));
        },
      }}
    />
  );
};

export default SelectBuildingPopup;
