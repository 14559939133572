import { useMemo, useState } from "react";
import { HeaderGroup, useBlockLayout, useTable } from "react-table";
import { Modal, Order } from "src/api/public-types";
import { BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import BuildingDeviceColumns from "./columns/BuildingDeviceColumns";

type Props = {
  deviceList: any[];
  deleteDevice?: (id: string) => void;
  isAuthority?: boolean;
  orders?: Order[];
  setOrders?: (orders: Order[]) => void;
};

const IotDevice = ({ deviceList, deleteDevice, isAuthority, orders, setOrders }: Props) => {
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  const convertedColumns = useMemo(() => BuildingDeviceColumns.filter((column) => (isAuthority ? column : column.Header !== "삭제")), [isAuthority]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: convertedColumns as any,
      data: deviceList,
      setConfirmModal,
    },
    useBlockLayout,
  );

  // 정렬 버튼 클릭
  const clickHeaderSort = (header: HeaderGroup) => {
    // 새로운 정렬
    let newOrders = [...(orders || [])];
    if (orders && orders.length > 0) {
      const finedHeader = orders.find((order: { property: string; direction: string }) => order.property === header.id);

      if (finedHeader) {
        newOrders = orders.filter((currentOrder: Order) => currentOrder.property !== finedHeader.property);
      }

      let newDirection = null;
      if (finedHeader?.direction) {
        if (finedHeader?.direction === "DESC") {
          newDirection = "ASC";
        } else {
        }
      } else {
        newDirection = "DESC";
      }
      if (newDirection) {
        newOrders = [{ property: header.id, direction: newDirection }]; // 단일 정렬시
      }
    } else {
      newOrders = [{ property: header.id, direction: "DESC" }];
    }
    setOrders && setOrders(newOrders);
  };

  // 정렬이 불가능한 header 인지 여부
  const isDisabledSortHeader = (header: HeaderGroup): boolean => {
    let isDisabledSortHeader = false;

    // iot 에선 "기기명" 만 sorting 적용
    if (header.id !== "internalDisplayName") {
      isDisabledSortHeader = true;
    }
    return isDisabledSortHeader;
  };

  const renderHeaderSortComponent = (header: HeaderGroup) => {
    if (isDisabledSortHeader(header)) return null;

    if (header.Header !== "기기명") return null;
    if (orders && orders.length > 0) {
      const finedHeader = orders.find((order: { property: string; direction: string }) => order.property === header.id);
      if (finedHeader) {
        if (finedHeader.direction === "DESC") {
          return <div className="ic_sort down"></div>;
        } else if (finedHeader.direction === "ASC") {
          return <div className="ic_sort up"></div>;
        }
      }
    }
    return <div className="ic_sort"></div>;
  };
  return (
    <>
      <section className="inner-tab-table">
        <div {...getTableProps()} className="base-table view-data-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div
                      {...header.getHeaderProps()}
                      className={`base-table__th ${!isDisabledSortHeader(header) ? "cursor-pointer" : ""}`}
                      onClick={() => {
                        if (!isDisabledSortHeader(header)) {
                          clickHeaderSort(header);
                        }
                      }}
                    >
                      {header.render("Header")}
                      <span>{renderHeaderSortComponent(header)}</span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {rows.length === 0 && (
            <div className="base-table__tr table-cursor-unset">
              <div className="base-table__td w-100 text-center">
                <div className="w-100">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnLeftTitle="취소"
          btnRightTitle="확인"
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => {
            deleteDevice && deleteDevice(String(confirmModal.payload));
            setConfirmModal({ isOpen: false });
          }}
          title={confirmModal.message}
          className="px30"
        ></BaseModal>
      )}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
          title={alertModal.title}
          className="px30"
        ></BaseModal>
      )}
    </>
  );
};

export default IotDevice;
