import React, { Dispatch, SetStateAction } from "react";
import ImagesList from "./ImagesList";
import { MediaServiceType, WorkOrderDetail, WorkOrderFormType } from "src/api/work-order/workorder-types";
import { HookFormProps } from "src/api/public-types";
import { FilePolicy } from "src/api/file/file-types";

type Props = {
  workSheet?: WorkOrderDetail;
  changedWorkSheet?: WorkOrderDetail;
  formProps: HookFormProps<any>;
  setChangedWorkSheet?: Dispatch<SetStateAction<WorkOrderDetail | undefined>>;
  policy: FilePolicy;
};
const MobileContents = ({ workSheet, changedWorkSheet, formProps, setChangedWorkSheet, policy }: Props) => {
  const { control, setValue, getValues, watch, errors, reset } = formProps;
  return (
    <section className="page-workorder-mobile__contents">
      <div className="flex-center-between">
        <h2>업무 내용</h2>
      </div>
      <div className="contents">
        <p className="contents-text pre-formatted">{workSheet?.description || "-"}</p>
        {changedWorkSheet && (
          <ImagesList
            control={control}
            changedWorkSheet={changedWorkSheet}
            setChangedWorkSheet={setChangedWorkSheet}
            isReadonly={true}
            isHideAddBtn={true}
            setValue={setValue}
            getValues={getValues}
            policy={policy}
            serviceType={MediaServiceType.MEDIA_WORK_ORDER_SHEET}
          />
        )}
      </div>
    </section>
  );
};

export default MobileContents;
