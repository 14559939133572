import { useCallback } from "react";
import {
  addNUpdateOperationData,
  deleteOperationFacility,
  editContractOperation,
  getBaseFacilityPricies,
  getContractFacilityList,
  getContractOperationInfo,
  getFacilityPricieSetting,
  getFacilityUsage,
  getProductFacilityList,
  setFacilityPriceSetting,
} from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";

const useContractFacilityApi = () => {
  // 운영정보 상세 조회 API
  const { executeAsync: getOperationInfo } = useApiOperation(getContractOperationInfo);
  // 운영정보 수정 API
  const { executeAsync: editOperationInfo } = useApiOperation(editContractOperation);
  // 운영정보 공용 시설 조회 API
  const { executeAsync: getOperationFacilities } = useApiOperation(getContractFacilityList);
  // 공용 시설 부가 정보 조회 API
  const { executeAsync: getFacilitiesDetatils } = useApiOperation(getProductFacilityList);
  // 운영정보 공용 시설 삭제 API
  const { executeAsync: deleteFacility } = useApiOperation(deleteOperationFacility);
  // 운영정보 공용 시설 등록/수정 API
  const { executeAsync: addNUpdateFacility } = useApiOperation(addNUpdateOperationData);
  // 프로바이더 요금 룰 목록 조회 API
  const { executeAsync: getBaseRules } = useApiOperation(getBaseFacilityPricies);
  // 건물- 공용 공간 요금 룰 등록/수정/삭제 API
  const { executeAsync: setSettingPrice } = useApiOperation(setFacilityPriceSetting);
  // 건물- 공용 공간 요금 룰 목록 조회 API
  const { executeAsync: getSettingPrice } = useApiOperation(getFacilityPricieSetting);
  // 월별 공용 공간 사용 실정 조회 API
  const { executeAsync: getUsage } = useApiOperation(getFacilityUsage);

  /** 운영정보 상세 조회 */
  const operationInfoData = useCallback(
    async (contractId: string) => {
      const response: any = await getOperationInfo({ id: Number(contractId) });

      if (response.data.data.content) {
        return response.data.data.content;
      } else {
        return {};
      }
    },
    [getOperationInfo],
  );

  const operationsFacilities = useCallback(
    async (contractId: string) => {
      const response: any = await getOperationFacilities({ id: Number(contractId) });

      if (response.data.data.content) {
        return response.data.data.content;
      } else {
        return {};
      }
    },
    [getOperationFacilities],
  );

  const getPrFacilities = useCallback(
    async (productId: string, idList: String) => {
      const response: any = await getFacilitiesDetatils({ productId, buildlingCommonFacilityId: idList, size: 9999 });

      if (response.data.data.content) {
        return response.data.data.content;
      } else {
        return [];
      }
    },
    [getFacilitiesDetatils],
  );

  const delFacility = useCallback(
    async (contractId: string, facilityId: string) => {
      const response = await deleteFacility({ id: Number(contractId), facilityId: Number(facilityId) });
      return response;
    },
    [deleteFacility],
  );

  const insertOrUpdate = useCallback(
    async (saveData: any) => {
      const response = await addNUpdateFacility(saveData);
      if (response.status > 199 && response.status < 300) {
        return true;
      } else {
        return false;
      }
    },
    [addNUpdateFacility],
  );

  const searchFacilityUsage = useCallback(
    async (contractId: string, type: string, month: Array<string>) => {
      const response = await getUsage({ id: Number(contractId), facilityType: type, yearMonth: month });
      return response.data.data.facilityUsage;
    },
    [getUsage],
  );

  const getProviderPriceRules = useCallback(
    async (providerId: string, buildingCommonFacilityId: string) => {
      const response = await getBaseRules({ providerId, page: 0, size: 9999 });

      return response.data.data.content || [];
    },
    [getBaseRules],
  );

  const getContractPriceRules = useCallback(
    async (serviceId: string, serviceType: string) => {
      const response = await getSettingPrice({ serviceId, serviceType });
      return response.data.data.content || [];
    },
    [getSettingPrice],
  );

  const setContractOperation = useCallback(
    async (saveData: any) => {
      const response = await editOperationInfo(saveData);
      return response;
    },
    [editOperationInfo],
  );

  const setContractPriceRules = useCallback(
    async (buildingCommonFacilityPriceRuleList: Array<any>) => {
      const response: any = await setSettingPrice({ buildingCommonFacilityPriceRuleList });
      return response.data.data.content.length > 0 ? true : false;
    },
    [setSettingPrice],
  );

  return {
    operationInfoData,
    operationsFacilities,
    getPrFacilities,
    delFacility,
    insertOrUpdate,
    searchFacilityUsage,
    getProviderPriceRules,
    getContractPriceRules,
    setContractOperation,
    setContractPriceRules,
  };
};
export default useContractFacilityApi;
