import React from "react";
import { BaseButton } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "../product/details";

const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <div className="page-init">
      <div className="page-init__bin"></div>
      <div className="page-init__contents">
        <h1>해당 파트너의 관리 권한이 없습니다</h1>
        <div className="flex-center-center">
          <BaseButton title="파트너 목록보기" onClick={() => navigate(`${PagePath.init}`)} />
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
