import { useEffect, RefObject } from "react";

// 전달받은 ref 이외 영역 (외부) 클릭시 닫기
export const useOnClickOutsideClose = (
  ref: RefObject<HTMLElement>,
  handler: (e: MouseEvent | TouchEvent) => void,
  isIncludeParent?: boolean,
): void => {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      // ref가 붙은 요소 혹은 부모 노드가 클릭영역에 포함되면 닫지않음
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }
      if (isIncludeParent && ref.current?.parentNode?.contains(e.target as Node)) {
        return;
      }
      e.stopPropagation();
      handler(e);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
