import { useRecoilValue } from "recoil";
import { BaseButton } from "src/components/BaseButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import { globalPartnerState, partnersState } from "src/recoil/partners/atom";

const PageNotFound = () => {
  const globalPartners: any = useRecoilValue(partnersState);
  const partner = useRecoilValue(globalPartnerState);
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <section>
        <div className="error-icon ic-question"></div>
        <div className="error-message-wrap pt20">
          <p className="font18 font-weight-semibold mb4">요청하신 페이지를 찾을 수 없습니다.</p>
          <p className="font15">정확한 주소인지 다시 한 번 확인해주세요</p>
        </div>
      </section>
      <section className="mt30">
        <div className="flex-center-between w-100">
          <BaseButton
            title="이전으로"
            className="color-white middle-size mr10"
            onClick={() => {
              navigate(-1);
            }}
          ></BaseButton>
          <BaseButton
            title="메인으로"
            className="middle-size"
            onClick={() => {
              // 네비게이션 안먹으니 사용하지 말 것
              // console.log("globalPartners", globalPartners[0]);
              // window.location.href = pagePath.main.replace(":partnerId", String(globalPartners[0].id));
              window.location.href = pagePath.main.replace(":partnerId", String(partner?.id));
            }}
          ></BaseButton>
        </div>
      </section>
    </div>
  );
};
export default PageNotFound;
