import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { isPublicPage } from "src/utils/common-util";

type Props = {
  isOpen: boolean;
  children?: React.ReactNode;
  className?: string;
};
/*
  BaseFullModal
  전체화면 모달
*/
const BaseFullModal = (props: Props) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    ref.current = document.getElementById("modal");
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!props.isOpen) return null;

  return mounted
    ? createPortal(
        <>
          <div className={isPublicPage(pathname) ? `dim mobile` : "dim"}>
            <div className={`base-full-modal ${props.className ? props.className : ""}`}>
              <div className="base-full-modal__contents">{props.children && <>{props.children}</>}</div>
            </div>
          </div>
        </>,
        ref.current!,
      )
    : null;
};

export default BaseFullModal;
