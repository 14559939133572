import { Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren, useMemo } from "react";
import { useLocation } from "react-router-dom";

const AuthProviderState = ({ children }: PropsWithChildren<any>): JSX.Element | null => {
  const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

  const isEnValid = domain && clientId && audience;

  if (!isEnValid) {
    throw new Error("Please check isEnvalid in authProviderState.tsx");
  }

  const location = useLocation();

  const partnerCode = useMemo(() => {
    if (location.pathname.includes("/partner/")) {
      const code = location.pathname.split("/")[2];
      return code ? code : "";
    }
    return "";
  }, [location]);

  return (
    <Auth0Provider
      domain={`https://${domain}`}
      clientId={clientId}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        audience:audience,
        redirect_uri: `${window.location.origin}/partner?partnerCode=${partnerCode}`,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProviderState;
