import { useSetRecoilState } from "recoil";
import { MediaFile } from "src/api/public-types";
import { baseModalState } from "src/recoil/modalState/atom";

const usePrimary = () => {
  const setBaseModal = useSetRecoilState(baseModalState);

  const isPrimary = (medias: MediaFile[]) => {
    if (medias && medias.length > 0 && medias.every((desk) => desk.isPrimary === false)) {
      setBaseModal({ isOpen: true, title: "대표를 선택해주세요.", btnRightTitle: "확인", onClose: () => setBaseModal({ isOpen: false }) });

      return false;
    }
    return true;
  };
  return { isPrimary };
};

export default usePrimary;
