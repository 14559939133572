import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  Cell,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { BaseModal } from "src/components";

import { CtBuildingManage } from "src/api/contract/contract-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { PagePath } from "src/pages/product/details";
import { Modal } from "src/pages/product/product-types";
import _ from "lodash";

type Props = {
  viewTable: Array<CtBuildingManage>;
  setViewTable?: Function;
  errorText?: string;
  readOnly?: boolean;
  selectedRow?: any; // 건물/호실 테이블에서 체크된 데이터
  setSelectedRow?: Function;
  onSelectedRowsChange?: Function;
  detailColumn?: any;

  getLocationCode?: Function;
  setValue?: UseFormSetValue<{ buildingManageList: CtBuildingManage[] }>;
  getValues?: UseFormGetValues<{ buildingManageList: CtBuildingManage[] }>;
};

// 신청/계약 > 계약(공간임대) > 건물 운영관리 테이블
const BuildingManagementTable = ({
  viewTable,
  setViewTable,
  errorText,
  readOnly,
  selectedRow,
  onSelectedRowsChange,
  detailColumn,
  getLocationCode,
  setValue,
  getValues,
}: Props) => {
  const [deleteModal, setDeleteModal] = useState<Modal>({ isOpen: false });

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "대표",
        accessor: "isPrimary",
        Cell: ({ row, rows, value, setViewTable, viewTable }: any) => {
          return (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                checked={value || false}
                id={row.id}
                onClick={() => {
                  const allChange = viewTable.map((building: any) => {
                    let changedPrimaryFalse = {
                      ...building,
                      isPrimary: Number(building.buildingId) === Number(row.original.buildingId) ? true : false,
                    };
                    return changedPrimaryFalse;
                  });
                  setViewTable(allChange);
                }}
              />
            </div>
          );
        },

        width: 80,
      },
      {
        Header: "건물 ID",
        accessor: "buildingId",

        width: 120,
      },
      {
        Header: "건물 명",
        accessor: "buildingName",
        Cell: ({ value, row }: Cell<any>) => {
          const path = PagePath.building.detail.replace(":id", row.original.buildingId);
          return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
        },
        width: 280,
      },
      {
        Header: "주소",
        accessor: "address",
        Cell: ({ value, row }: Cell<any>) => {
          return <p>{value}</p>;
        },
        width: 380,
      },

      {
        Header: "삭제",
        accessor: "product",
        Cell: ({ row, rows }: any) => {
          const building: CtBuildingManage = row.original;
          return (
            <div className="minmax50 flex-center-center">
              <button
                className="base-trash-btn"
                disabled={readOnly ? readOnly : false}
                onClick={(e) => {
                  e.preventDefault();
                  if (rows.length > 1) {
                    setDeleteModal({
                      isOpen: true,
                      message: `[건물ID:${building.buildingId}] 를 삭제하시겠습니까?`,
                      payload: building,
                    });
                  } else {
                    setDeleteModal({
                      isOpen: true,
                      type: "confirm",
                      message: `건물을 최소 한 개 이상 등록해 주세요`,
                    });
                  }
                }}
              ></button>
            </div>
          );
        },
        width: 60,
      },
    ],
    [readOnly],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: detailColumn ? detailColumn : columns,
      data: viewTable,
      initialState: { selectedRowIds: selectedRow },
      viewTable,
      setViewTable,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  //
  // 등록됐던 대표여부 Checked
  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  // table default 대표여부 / 대표 클릭시 isprimary = true

  const onDeleteProperty = useCallback(
    (buildingManage: CtBuildingManage) => {
      const tableData = _.cloneDeep(viewTable);

      const filteredDeletedData = tableData.map((item) => {
        return Number(item.buildingId) === Number(buildingManage.buildingId) ? { ...item, isDeleted: true, isPrimary: false } : item;
      });

      if (buildingManage.isPrimary) {
        //isDeleted가 아닌것 중에서 가장 첫번째 아이템 찾기
        const viewTableFirstIdx = filteredDeletedData.findIndex((building) => !building.isDeleted);
        filteredDeletedData[viewTableFirstIdx].isPrimary = true;
      }
      setViewTable && setViewTable(filteredDeletedData);

      setDeleteModal({ isOpen: false });
    },
    [setViewTable, viewTable],
  );

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="d-flex chip-case">
          <input type="radio" id={rest.id} ref={resolvedRef} name="buildingAndHo" {...rest} disabled={readOnly ? true : false} />
          <label htmlFor={rest.id} className="d-flex">
            <div className="base-radio"></div>
            <div className="font14 no-wrap">대표</div>
          </label>
        </div>
      </>
    );
  });

  return (
    <section className="inner-tab-table">
      {errorText && <p className="validation-text">{errorText}</p>}
      <div className="my20">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <BaseModal
          isOpen={deleteModal.isOpen}
          onClick={() => {
            deleteModal.type === "confirm" ? setDeleteModal({ isOpen: false }) : onDeleteProperty(deleteModal.payload);
          }}
          onClose={() => setDeleteModal({ isOpen: false })}
          btnLeftTitle={deleteModal.type === "confirm" ? undefined : "취소"}
          btnRightTitle="확인"
        >
          {deleteModal.message}
        </BaseModal>
      </div>
    </section>
  );
};

export default BuildingManagementTable;
