import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseButton, BaseInput } from "src/components";
import useEditContractOperation from "src/hooks/contract/useEditContractOperation";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";

const VocUrlModal = ({ vocUrl, contractId }: { vocUrl: string; contractId: string }) => {
  // 공용공간 운영정보 수정
  const { mutate: editContractOperation } = useEditContractOperation();
  const { openToast } = useToast();
  const { setBaseModal, setAbstractModalZ1 } = useModal();

  const { register, control, handleSubmit } = useForm({
    defaultValues: { vocUrl: vocUrl ?? "" },
  });

  //  유효성 검사
  const formValidation = useCallback(() => {
    register("vocUrl", {
      validate: {
        required: (value) => {
          let message = "";
          const pattern = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
          const isValidate = pattern.test(String(value));
          if (!isValidate) message = "http:// 또는 https:// 로 시작하는 값을 입력해주세요.";
          return isValidate || message;
        },
      },
    });
  }, [register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  return (
    <>
      <section className="base-abstract-modal__title">
        <h1>문의채널</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <form>
          <div className="pb30">
            <Controller
              control={control}
              name="vocUrl"
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <BaseInput
                  type="text"
                  onChange={onChange}
                  value={`${value}`}
                  name={name}
                  errorText={error?.message}
                  placeholder="문의채널 URL를 입력해 주세요."
                />
              )}
            ></Controller>
          </div>
        </form>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"취소"} className="color-white" onClick={() => setAbstractModalZ1({ isOpen: false })} />

        <BaseButton
          title={"저장"}
          onClick={() => {
            handleSubmit((data) => {
              setBaseModal({
                isOpen: true,
                btnRightTitle: "확인",
                btnLeftTitle: "취소",
                title: "저장하시겠습니까?",
                onClick: () => {
                  editContractOperation(
                    { contractId: contractId!, productManage: { vocUrl: data.vocUrl } },
                    {
                      onSuccess: () => {
                        openToast({
                          type: "SUCCESS",
                          content: "저장되었습니다.",
                        });
                        setBaseModal({ isOpen: false });
                        setAbstractModalZ1({ isOpen: false });
                      },
                    },
                  );
                },
              });
            })();
          }}
        />
      </section>
    </>
  );
};

export default VocUrlModal;
