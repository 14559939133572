import qs from "qs";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ContentsTitle } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import BasicInfoForm from "./basicInfo/BasicInfoForm";
import MetaTag from "src/components/layout/MetaTag";

const NoticeForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { value: "basicInfo", label: "등록하기" },
  ];

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const noticeId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      if (!queryParams.id && tab.value !== "basicInfo") {
        return;
      }

      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  return (
    <>
      {noticeId ? <MetaTag /> : <MetaTag isCreatePage />}
      <div>
        <ContentsTitle title="공지사항 등록" tabs={tabs} activeTab={activeTab} clickTab={clickTab} detailInfo={[{ title: "id", value: noticeId || "" }]} />
        {activeTab?.value === "basicInfo" && <BasicInfoForm/>}
      </div>
    </>
  );
};

export default NoticeForm;
