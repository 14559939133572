import _ from "lodash";
import moment from "moment";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChargeItems, SearchType } from "src/api/chargeItems/chargeItems-type";
import { getCCM21 } from "src/api/chargeMnt/chargeMnt-api";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInputWithSearch, BaseTable, BaseTooltip } from "src/components";
import Link from "src/components/PartnerLink";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { ViewYmdFormat } from "src/utils";

// select sample
const searchUsed = [
  { value: "All", label: "전체" },
  { value: "true", label: "사용" },
  { value: "false", label: "미사용" },
];

export const columns = [
  {
    Header: "id",
    accessor: "chartId",
    // sticky: "left",
    width: 80,
    Cell: (props: any) => {
      const location = useLocation();
      const queryParams = useMemo(
        () =>
          qs.parse(location.search, {
            ignoreQueryPrefix: true,
            allowDots: true,
            decoder: (c) => c,
          }),
        [location],
      );
      queryParams.tab = "basicInfo";

      const path =
        PagePath.chargeMnt.detail.replace(":id", props.row.original.chartId) +
        "?" +
        decodeURIComponent(qs.stringify(queryParams, { allowDots: true, encode: true }));

      // console.log("props.row.original", props.row.original);
      return (
        <>
          <Link to={path} className="text-underline">
            {props.value}
          </Link>
        </>
      );
    },
  },

  {
    Header: "건물ID",
    accessor: "buildingId",
    width: 80,
    Cell: (props: any) => {
      const path = PagePath.building.detail.replace(":id", props.row.original.buildingId);
      return (
        <>
          <Link to={path} target={"_blank"} className="text-underline">
            {props.value}
          </Link>
        </>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 150,
    Cell: ({ value }: any) => {
      return <>{value ? <BaseTooltip contents={value} /> : "-"}</>;
    },
  },
  {
    Header: "관리처",
    accessor: "mgmtOfficeName",
    width: 150,
    Cell: ({ value }: any) => {
      return <>{value ? <BaseTooltip contents={value} /> : "-"}</>;
    },
  },
  {
    Header: "회차(년월)",
    accessor: "yearMonth",
    width: 150,
    Cell: ({ value }: any) => {
      let ve = moment(value, "YYYY-MM").format(ViewYmdFormat.YYYY_MM);
      return (
        <>
          <span className="ellipsis2">{ve}</span>
        </>
      );
    },
  },
  {
    Header: "산출기간",
    accessor: "calcStartDate",
    width: 150,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</>;
    },
  },
  {
    Header: "납부기간",
    accessor: "billIssueDate",
    width: 150,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</>;
    },
  },

  {
    Header: "생성일",
    accessor: "createdDate",
    width: 150,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</>;
    },
  },

  {
    Header: "등록ID",
    accessor: "createdBy",
    width: 180,
    Cell: ({ value }: any) => {
      return <p>{value}</p>;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 150,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</>;
    },
  },

  {
    Header: "최종 작성자",
    accessor: "modifiedBy",
    width: 180,
    Cell: ({ value }: any) => {
      return <p>{value}</p>;
    },
  },
  {
    Header: "진행상태",
    accessor: "mntStatus",
    width: 90,
    Cell: ({ value }: any) => {
      return (
        <div className={`${(value === "READY" && "chip visible") || (value !== "READY" && "chip invisible")}`}>
          {(value === "READY" && "사용") || (value !== "READY" && "미사용")}
        </div>
      );
    },
  },
];

/* 
  관리비 목록 마크업
 */
const ChargeMntList = () => {
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();
  const { executeAsync: getChargeMntList } = useApiOperation(getCCM21);
  const location = useLocation();
  console.log(location.search, "location.search");
  const queryParams: any = useMemo(() => {
    const _queryParams: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (c) => c,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "chartId", direction: "DESC" }],
      };
    }
    if (_queryParams?.id) {
      delete _queryParams?.id;
    }
    if (!_queryParams?.searchValue) {
      _queryParams.searchValue = "";
    }

    return _queryParams;
  }, [location]);
  const [params, setParams] = useState<any>({ ...queryParams });
  const [partner, setPartner] = useState<any>();
  const [pagination, setPagination] = useState<PageMeta>();
  const [data, setData] = useState<ChargeItems[]>([]);

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: any) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      setParams(newQueryParams);
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params],
  );

  const list = useCallback(
    async (params: any) => {
      params.searchValue = decodeURIComponent(params.searchValue);
      const response: any = await getChargeMntList(params);
      if (response.status >= 200 && response.status <= 299) {
        if (response.data.data.content.length > 0) {
          setData(response.data.data.content);
          setPagination(response.data.meta.pageMeta);
        }
      }
    },
    [getChargeMntList],
  );

  useEffect(() => {
    list(params);
  }, [location]);

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="관리비 - 관리비 부과관리" breadCrumbs={[{ value: "adminCost", label: "관리비 > 관리비 부과관리" }]} /> */}
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* <section>
              <div className="left-area__index">
                <span></span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140"></div>
              </div>
            </section> */}
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120">
                  <BaseInputWithSearch
                    type="text"
                    selectValue={params.searchType}
                    inputValue={decodeURIComponent(String(params?.searchValue) || "")}
                    stateOptions={SearchType}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onChange={(keyword: string) => setParams({ ...params, ...{ searchValue: keyword } })}
                    onKeyUp={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                    onSearchClick={() => navigateWithQueryParams({ ...params, ...{ page: 0 } })}
                    onClearClick={() => navigateWithQueryParams({ ...params, ...{ searchValue: "" } })}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">
            <BaseButton title="+ 회차 등록" onClick={() => navigate(PagePath.chargeMnt.form)} />
          </div>
        </div>
        <BaseTable
          data={data}
          columns={columns}
          pageIndex={Number(params?.page) || 0}
          totalPages={Number(pagination?.totalPages) || 0}
          totalElements={Number(pagination?.totalElements) || 0}
          currentSize={Number(queryParams.size) || 20}
          sizeOption={(sizeValue) => {
            navigateWithQueryParams({ ...params, size: sizeValue });
          }}
          goPage={(page: number) => {
            navigateWithQueryParams({ page });
          }}
          disabledSortHeaders={
            [
              // "contractApplyNumber",
              // "spaceProductId",
              // "createdDate",
              // "modifiedDate",
            ]
          }
          orders={params?.sort?.orders}
          setOrders={(orders?: Array<Order>) => {
            if (orders) {
              navigateWithQueryParams({ sort: { orders } });
            }
          }}
        />
      </div>
    </div>
  );
};
export default ChargeMntList;
