import { DeskSpace, SelectOption } from "src/pages/building/building-types";
import { SaveDesk, SaveDeskGroup } from "../components";

type Props = {
  deskSpace?: DeskSpace;
  saveDeskSpace: Function;
  setDeskSpace: Function;
  deskSpaces?: Array<DeskSpace>;
  rangeFloorOptions: Array<SelectOption>;
};

// 좌석 저장 테이블 컴포넌트
const SaveDeskTable = ({
  deskSpace,
  setDeskSpace,
  saveDeskSpace,
  deskSpaces,
  rangeFloorOptions,
}: Props) => {
  if (deskSpace?.type === "GROUP") {
    return (
      <SaveDeskGroup
        deskSpace={deskSpace}
        saveDeskSpace={saveDeskSpace}
        setDeskSpace={setDeskSpace}
        deskSpaces={deskSpaces}
        rangeFloorOptions={rangeFloorOptions}
      />
    );
  } else {
    return (
      <SaveDesk
        deskSpace={deskSpace}
        saveDeskSpace={saveDeskSpace}
        setDeskSpace={setDeskSpace}
        deskSpaces={deskSpaces}
      />
    );
  }
};

export default SaveDeskTable;
