import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { SaveEsignDatas, TemplateInfo } from "src/api/contract/contract-types";
import { BaseButton, BaseRadio } from "src/components";
import useEsignApi from "src/pages/contract/hooks/useEsignApi";
import { ViewYmdFormat } from "src/utils";

const columns: any = [
  {
    Header: "선택",
    accessor: "selected",
    // sticky: "left",
    Cell: ({ row, setOnClickedUrl, selectedTemplate, setSeletedTemplate, setWorkflow }: any) => {
      let flags = false;
      if (selectedTemplate) {
        if (selectedTemplate !== undefined && selectedTemplate.templateId !== undefined) {
          if (selectedTemplate.templateId === row.original.templateId) {
            flags = true;
          }
        }
      }
      return (
        <>
          <BaseRadio
            id={row.original.templateId}
            name={"templateId"}
            checked={flags}
            onChange={async () => {
              if (Number(row.original.totalOrderCount) > 0) {
                const setData = { template: row.original, previewUrl: row.original.previewUrl };
                setSeletedTemplate(row.original);
                setWorkflow({ templateId: row.original });
                setOnClickedUrl(setData);
              }
            }}
            disabled={Number(row.original.totalOrderCount) === 0}
          />
        </>
      );
    },
    width: 80,
  },

  {
    Header: "서식명",
    accessor: "templateName",
    // sticky: "left",
    Cell: ({ value }: any) => {
      return <>{value && value}</>;
    },
    width: 250,
  },
  {
    Header: "부서명",
    accessor: "folderPath",
    Cell: ({ value }: any) => {
      return <>{value && value}</>;
    },
    width: 150,
  },

  {
    Header: "문서 작성자 수",
    accessor: "totalOrderCount",
    width: 150,
    Cell: ({ value, row }: any) => {
      // console.log(value);
      return <>{value && value + "명"}</>;
    },
  },
  {
    Header: "마지막 수정일",
    accessor: "lastModifiedDate",
    width: 150,
    Cell: ({ value, row }: any) => {
      // console.log(value);
      return <>{value && moment(value).subtract(9,'hours').format(ViewYmdFormat.FULL)}</>;
    },
  },
  {
    Header: "미리보기",
    accessor: "preview",
    width: 150,
    Cell: ({ value, row, getTemplateDetail }: any) => {
      const { id } = useParams();
      // console.log(value);
      const callTemplateDetail = useCallback(async () => {
        const getDetail = await getTemplateDetail(id, row.original.templateId!);
        window.open(getDetail.previewUrl, "_blank");
      }, [getTemplateDetail]);

      return (
        <>
          <BaseButton
            title={"미리보기"}
            className={"color-white"}
            onClick={() => {
              callTemplateDetail();
            }}
          />
        </>
      );
    },
  },
];
interface Seleted {
  template?: TemplateInfo;
  previewUrl?: string;
}
interface Props {
  setSeletedTemplate: any;
  selectedTemplate: any;
  workflow: SaveEsignDatas;
  setWorkflow: any;
}
const EsignStepOne = ({ setSeletedTemplate, selectedTemplate, setWorkflow }: Props) => {
  const { getTemplateList, getTemplateDetail } = useEsignApi();
  const [templateList, setTemplateList] = useState<Array<TemplateInfo>>([]);
  const [onClickedUrl, setOnClickedUrl] = useState<Seleted>({});
  const { id: contractId } = useParams();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: templateList,
      initialState: { pageSize: 1000 },
      onClickedUrl,
      setOnClickedUrl,
      selectedTemplate,
      setSeletedTemplate,
      setWorkflow,
      getTemplateDetail,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const getTemplateDetailData = useCallback(
    async (contractId: number, templateList: Array<TemplateInfo>) => {
      if (templateList && templateList.length > 0) {
        // console.log("templateList", templateList);
        const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

        templateList.forEach((template: TemplateInfo) => {
          // console.log(template.folderPath?.replace("///g", ""));
          template.folderPath = template.folderPath?.replace(reg, "");
        });
        setTemplateList(templateList);
      }
    },
    [getTemplateDetail],
  );

  const initData = useCallback(
    async (contractId: number) => {
      const templateLists = await getTemplateList(contractId);

      getTemplateDetailData(contractId, templateLists);
    },
    [getTemplateDetailData, getTemplateList],
  );
  useEffect(() => {
    if (contractId) {
      initData(Number(contractId));
    }
  }, [contractId, initData]);
  useEffect(() => {
    if (selectedTemplate.temoplateId) {
      setOnClickedUrl({ template: selectedTemplate, previewUrl: selectedTemplate.previewUrl });
    }
  }, [selectedTemplate]);

  return (
    <>
      <section className="base-abstract-modal__contents-subtitle mt0">
        <h2>서식선택</h2>
      </section>
      <ul className="base-list mb30">
        <li>서식을 추가하시려면 cs@ppoint.kr에 파일을 보내주세요.</li>
        <li>서식 추가에 따른 데이터 연동은 별도 비용으로 진행됩니다.</li>
      </ul>
      <div className="flex-row d-flex flex-center-start">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);

              return (
                <div {...row.getRowProps()} className={`base-table__tr`}>
                  {row.cells &&
                    row.cells.map((cell: any, index: number) => {
                      // console.log(row.original, user);
                      // const visibleFlag = row.original.createdBy === user?.email ? false : true;

                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          <div>{cell.render("Cell")}</div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
            <div>
              {rows.length === 0 && (
                <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                  <div className="base-table__td w-100 text-center">
                    <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex-row">
          <PreviewIframe src={onClickedUrl.previewUrl!} />
        </div> */}
      </div>
    </>
  );
};
export default EsignStepOne;
