import { MediaFile, PageMeta, Select, Sort } from "../public-types";

enum MemberRollType {
  // 휴대폰 인증 후 회원 가입 완료 전 상태의 사용자
  ROLE_STARTER = "ROLE_STARTER",
  // 일반 사용자
  ROLE_MEMBER = "ROLE_MEMBER",
}
enum MemberStatusType {
  //회원 가입 필요 상태
  REQUIRE_JOIN = "REQUIRE_JOIN",
  //이미 가입 된 휴대폰 번호와 다른 디바이스 식별 정보로 휴대폰 점유 인증을 시도 하는 상태
  ALREADY_EXISTS_PHONE_NUMBER = "ALREADY_EXISTS_PHONE_NUMBER",
  // 회원 가입 상태
  JOINED = "JOINED",
  // 이메일 인증 상태
  EMAIL_VERIFIED = "EMAIL_VERIFIED",
  // 휴대폰 점유 인증 상태
  PHONE_NUMBER_VERIFIED = "PHONE_NUMBER_VERIFIED",
  // 마케팅 동의 상태
  MARKETING_CONSENT = "MARKETING_CONSENT",
  MARKETING_CONSENT_DISAGREE = "MARKETING_CONSENT_DISAGREE",
  //계정 연결 대기 상태
  WAITING_FOR_RECOVERY = "WAITING_FOR_RECOVERY",
}

enum MemberAccountStatusType {
  // 정상
  AVAILABLE = "AVAILABLE",
  // 이용 정지
  LOCKED = "LOCKED",
  // 휴면
  INACTIVE = "INACTIVE",
  // 탈퇴
  DELETE = "DELETED",
}

enum MemberAccountStatusChangeReasonType {
  // 신규 회원 가입
  JOIN = "JOIN",
  // 휴대폰 점유 인증 상실
  LOSS_PHONE_NUMBER_VERIFIED = "LOSS_PHONE_NUMBER_VERIFIED",
  // 이용 중지
  RESTRICTION = "RESTRICTION",
  RESOLVED = "RESOLVED",
  PRIVACY_EXPIRED = "PRIVACY_EXPIRED",
  QUIT = "QUIT",

  // 기기 변경
  CHANGE_DEVICE = "CHANGE_DEVICE",
}

enum MemberVerifyType {
  // 이메일
  EMAIL = "EMAIL",
  // SMS
  SMS = "SMS",
}

enum SearchMemberType {
  ALL = "",
  UID = "id",
  //containsOrgName
  CONTAINS_MEMBER_NAME = "containsMemberName",
  //containsEmail
  CONTAINS_EMAIL = "containsEmail",
  //containsPhoneNumber
  CONTAINS_PHONE_NUMBER = "containsPhoneNumber",
  CONTAINS_MEMBER_NUMBER = "containsMemberNo",
  CONTAINS_NICKNAME = "containsNickname",
}

const MemberSearchStatusOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "정상", value: MemberAccountStatusType.AVAILABLE },
  { label: "일시 정지", value: MemberAccountStatusType.LOCKED },
  { label: "휴면 계정", value: MemberAccountStatusType.INACTIVE },
  { label: "탈퇴", value: MemberAccountStatusType.DELETE },
];

const MemberSearchTypeOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "아이디", value: SearchMemberType.UID },
  { label: "법인명/상호", value: SearchMemberType.CONTAINS_MEMBER_NAME },
  { label: "이메일", value: SearchMemberType.CONTAINS_EMAIL },
  { label: "휴대폰번호", value: SearchMemberType.CONTAINS_PHONE_NUMBER },
  { label: "회원번호", value: SearchMemberType.CONTAINS_MEMBER_NUMBER },
  { label: "닉네임", value: SearchMemberType.CONTAINS_NICKNAME },
];

interface OrganizationsList {
  id: number;
  name: string;
}

//회원 기본 정보
interface MemberModel {
  id: number;
  memberNo: string;
  email: string;
  phoneNumber: string;
  deviceUniqueId: string;
  roles: Array<string>;
  accountState: MemberAccountState;
  joinDate: string;
  lastModifiedDate: string;
  // states: MemberStatus;
  states: Array<MemberStateStatus>;
  organizations: Array<OrganizationsList>;
  nickname: string;
}

interface ClientModel {
  device: {
    family: string;
  };
  os: {
    family: string;
    major: string;
    minor: string;
    patch: string;
    patchMinor: string;
  };
  userAgent: {
    family: string;
    major: string;
    minor: string;
    patch: string;
  };
}

interface MemberLoginModel {
  authTime: string;
  client: ClientModel;
  id: string;
  memberId: string;
}

interface Order {
  property: string;
  direction: string;
}

//회원 상태
// interface MemberStatus {
//   memberId: number;
//   status: string;
//   time: string;
// }

interface MemberStateStatus {
  status: string;
  statusDate: string;
}

//회원 계정 변경 내역
interface MemberAccountState {
  id: number;
  status: string;
  statusDate: string;
  cause: string;
  current_yn: Boolean;
  member_id: number;
  login?: MemberLoginModel;
}

interface MemberAccountStateList {
  accountStates?: MemberAccountState[];
}

//회원 이용 제한 등록
interface MemberRestrictionAdd {
  inclusiveStartDate: string;
  inclusiveEndDate: string;
  comment: string;
  memberId: number;
}

interface MemberRestrictionEdit extends MemberRestrictionAdd {
  id?: number;
}

//회원 이용 제한
interface MemberRestriction extends MemberRestrictionEdit {
  memberNo: number;
  createdDate: string;
  lastModifiedDate: string;
}

interface MemberRestrictionList {
  content: MemberRestriction[];
}

interface QueryParams {
  id?: number | string;
  accountStateStatus?: string;
  keyword?: string;
  searchType?: string;
  containsPhoneNumber?: string;
  containsMemberName?: string;
  containsEmail?: string;
  containsMemberNo?: string;
  containsNickname?: string;
  search001?: string;
  search002?: string;
  notEmptyMemberNo?: boolean;
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  role?: string;
}

interface SearchPageInitData {
  memberSearchStatusOptions: Select[];
  memberSearchTypeOptions: Select[];
}
interface MemberListData {
  content: Array<MemberModel>;
}

interface MbMemberNoteAdd {
  memberId: number;
  content: string;
}

interface MbMemberNoteEdit extends MbMemberNoteAdd {
  id?: number;
}

interface MbMemberNote extends MbMemberNoteAdd {
  id?: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

interface MbMemberNoteList {
  content: MbMemberNote[];
}

export {
  MemberSearchStatusOptions,
  MemberSearchTypeOptions,
  MemberRollType,
  MemberStatusType,
  MemberAccountStatusType,
  MemberAccountStatusChangeReasonType,
  MemberVerifyType,
  SearchMemberType,
};
export type {
  SearchPageInitData,
  MemberRestriction,
  // MemberStatus,
  MemberStateStatus,
  OrganizationsList,
  MemberModel,
  QueryParams,
  // MemberListParams,
  Order,
  MemberListData,
  MemberAccountState,
  MemberAccountStateList,
  MemberRestrictionList,
  MemberRestrictionAdd,
  MemberRestrictionEdit,
  MbMemberNoteList,
  MbMemberNote,
  MbMemberNoteAdd,
  MbMemberNoteEdit,
  MemberLoginModel,
};
