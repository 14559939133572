import { BaseModal } from "src/components";

type Props = {
  isOpen: boolean;
  data: any;
  close: any;
  accessGroupName: string;
};

const AccessGroupDetailModal = ({ isOpen, data, accessGroupName, close }: Props) => {
  // console.log("accessGroup", data);
  return (
    <div>
      <BaseModal
        className="dialog-modal small-size"
        isOpen={isOpen}
        btnRightTitle="확인"
        onClick={() => {
          if (close) {
            close();
          }
        }}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>출입그룹 상세정보</span>
            </div>
            <div className="right-area">
              <div className="minmax260 text-right">{accessGroupName}</div>
            </div>
          </div>
          <div className="contents-scroll overflow-x-hidden">
            <table className="inner-table text-center" width="480">
              <thead>
                <tr>
                  <th>장치 id</th>
                  <th>장치 이름</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((obj: any, index: number) => (
                    <tr key={index}>
                      <td>{obj.externalDeviceId}</td>
                      <td>{obj.deviceName}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default AccessGroupDetailModal;
