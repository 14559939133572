import { useEffect, useMemo, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import {
  deleteContractDeviceMembers,
  deviceControlExcuteAsync,
  getBuildingDeviceControlAsync,
  getContractDeviceMembersAsync,
  postContractDeviceMembers,
} from "src/api/iot/iot-api";
import {
  BuildingDeviceControl,
  ContractControlMemberParams,
  ContractDeviceControl,
  ContractDeviceControlMember,
  DeviceControlParams,
  DeviceModel,
} from "src/api/iot/iot-types";
import { Modal } from "src/api/public-types";
import { UserListModel } from "src/api/user/user-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import DeviceControlMembersColumn from "src/pages/contract/detail/components/iotDevice/columns/DeviceControlMembersColumn";
import AddMemberListModal from "src/pages/contract/detail/components/iotDevice/components/AddMemberListModal";
import ExecuteMembersModal from "src/pages/contract/detail/components/iotDevice/components/ExecuteMembersModal";
import { UnionPartnerPrivilegeCode } from "src/types/partner";
import ControlDeviceTable from "./ControlDeviceTable";
import { useLocation } from "react-router-dom";
type Props = {
  detail?: DeviceModel;
  deviceType?: "building" | "product" | "contract";
  contractApplyNumber?: string;
};
interface AuthortyPage {
  [key: string]: string;
  // 다른 속성들...
}
export const CURRENT_PAGE: AuthortyPage = {
  product: "PRODUCT",
  contract: "CONTRACT",
  building: "BUILDING",
};

const IotDeviceDetail = ({ detail, deviceType, contractApplyNumber }: Props) => {
  const [controlList, setControlList] = useState<BuildingDeviceControl[]>([]);
  const [executeItem, setExecuteItem] = useState<ContractDeviceControl | null>(null);

  // 실행권한 모달 내 목록
  const [executeMembers, setExecuteMembers] = useState<ContractDeviceControlMember[]>([]);

  // 이용자 추가 모달
  const [memberModal, setMemberModal] = useState<Modal>({ isOpen: false });

  const { isAuthority } = usePartnerAuthority();

  const { pathname } = useLocation();

  const PAGE_NAME: any = useMemo(() => {
    const splitPath = pathname.split("/");
    const currentPageName = splitPath[5];
    return CURRENT_PAGE[currentPageName];
  }, [pathname]);

  // 디바이스 제어 목록
  const { executeAsync: getDeviceControl } = useApiOperation(getBuildingDeviceControlAsync);
  // 디바이스 제어 실행
  const { executeAsync: deviceControlExcute } = useApiOperation(deviceControlExcuteAsync);

  // 실행권한이 주어진 이용자 목록
  const { executeAsync: getContractDeviceMembers } = useApiOperation(getContractDeviceMembersAsync);

  // 권한부여 이용자 추가 api
  const { executeAsync: addExecuteMembers } = useApiOperation(postContractDeviceMembers);

  // 권한부여 이용자 삭제 api
  const { executeAsync: deleteExecuteMember } = useApiOperation(deleteContractDeviceMembers);

  // 디바이스 제어 목록 호출
  const fetchDeviceControlList = async () => {
    const { data, status } = await getDeviceControl({ deviceType: deviceType as "building" | "product" | "contract", id: String(detail?.id) });
    if (status >= 200 && status <= 299) {
      setControlList(data.data.controls);
    }
  };

  useEffect(() => {
    detail?.id && fetchDeviceControlList();
  }, []);

  // 제어실행 결과값 parse 여부 확인
  const isValidJSONString = (value: string) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  // iot 제어구분이 "명령" 인 실행에 대해 수정 권한확인 ("w" 여부)
  const checkExecuteAuthority = (deviceType: UnionPartnerPrivilegeCode) => {
    const isAuthorityExecute = isAuthority("w", String(deviceType).toUpperCase() as UnionPartnerPrivilegeCode);
    return isAuthorityExecute;
  };

  /** 디바이스 제어 실행*/
  const excuteDevice = async (
    passData: DeviceControlParams, //
  ) => {
    const { data, status } = await deviceControlExcute(passData);
    const result = data.data.result;
    if (status >= 200 && status <= 299) {
      const isParsed = isValidJSONString(result);

      setControlList((prev) =>
        [...prev].map((item: BuildingDeviceControl & { executed?: string; isParsed?: boolean; arguments?: string | number }) => {
          if (String(item.control.id) === String(passData.controlId)) {
            item = { ...item, executed: result, isParsed, arguments: passData.arguments };
          }
          return item;
        }),
      );
    }
  };

  // 디바이스 실행권한 목록 api
  const fetchContractDeviceMembers = async () => {
    if (executeItem !== null) {
      const passData = {
        contractDeviceId: executeItem?.contractDeviceId,
        controlId: executeItem?.control.id,
      };
      const { data, status } = await getContractDeviceMembers(passData);
      if (status >= 200 && status <= 299) {
        setExecuteMembers(data.data.members);
      }
    }
  };
  useEffect(() => {
    fetchContractDeviceMembers();
  }, [executeItem]);

  // 권한 멤버 추가
  const addMember = async (selected: UserListModel[]) => {
    const passData: ContractControlMemberParams = {
      contractDeviceId: String(executeItem?.contractDeviceId),
      controlId: String(executeItem?.control.id),
      memberNos: selected.map((member) => member.memberNo),
    };
    const { data, status } = await addExecuteMembers(passData);
    if (status >= 200 && status <= 299) {
      await fetchContractDeviceMembers();
      console.log(`data`, data);
    }
  };

  // 권한 멤버 삭제
  const deleteMember = async (controlMemberId: string) => {
    const passData: ContractControlMemberParams = {
      contractDeviceId: String(executeItem?.contractDeviceId),
      controlId: String(executeItem?.control.id),
      controlMemberId,
    };

    const { data, status } = await deleteExecuteMember(passData);

    if (status >= 200 && status <= 299) {
      console.log(`data`, data);
      await fetchContractDeviceMembers();
    }
  };

  return (
    <div>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            <div className="contents-container__sub-title">
              <h2>IoT 기기</h2>
            </div>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">건물</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{detail?.building.buildingName}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">층</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400 ">
                  <span className="font14">
                    {String(detail?.building.floor).includes("-")
                      ? `지하 ${String(detail?.building.floor).replace("-", "")}층`
                      : `지상 ${String(detail?.building.floor).replace("-", "")}층`}
                  </span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{detail?.building.ho}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">공용공간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  {/* iot 핫픽스 처리 > iot 추가한 공간명칭 노출을 위해 FE 에서 spaceName 추가 */}
                  <span className="font14">{detail?.building.location.facilityName || "-"}</span>
                  {/*  */}
                  {/* <span className="font14">
                    {detail?.building.facility === "DESK"
                      ? "좌석"
                      : detail?.building.facility === "MEETING_ROOM"
                      ? "회의실"
                      : detail?.building.facility === "REFRESH_ROOM"
                      ? "편의시설"
                      : "-"}
                  </span> */}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">IoT 구분</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{detail?.platformDevice.platformAccount.platform}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">기기명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{detail?.internalDisplayName}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">Device ID</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{detail?.platformDevice.id}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">Labels</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400 pt10">
                  {detail?.labels?.map((label, idx) => (
                    <span key={`${label.value}-${idx}`} className="font14 mb8">{`${label.name} : ${label.value}`}</span>
                  ))}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">제어 정보</p>
              </div>
              <div className="contents-container__grid-contents">
                <ControlDeviceTable
                  controlList={controlList.sort((a, b) => a.control.displayName.localeCompare(b.control.displayName, "ko"))}
                  excuteDevice={excuteDevice}
                  onClickControl={(item: ContractDeviceControl) => setExecuteItem(item)}
                  checkExecuteAuthority={checkExecuteAuthority}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <>
        {executeItem !== null && (
          <ExecuteMembersModal
            handleDeleteMember={deleteMember}
            openMemberModal={() => {
              setMemberModal({ isOpen: true });
            }}
            onClose={() => {
              setExecuteItem(null);
            }}
            columns={DeviceControlMembersColumn}
            data={executeMembers}
            isRemovable={isAuthority("d")}
            isEditable={isAuthority("w")}
          />
        )}

        {memberModal.isOpen && (
          <AddMemberListModal
            contractApplyNumber={contractApplyNumber || ""}
            onClose={() => setMemberModal({ isOpen: false })}
            addMember={addMember}
            executeMembers={executeMembers.map((member) => member.memberNo)}
          />
        )}
      </>
    </div>
  );
};

export default IotDeviceDetail;
