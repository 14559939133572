import { Viewer } from "@toast-ui/react-editor";

type Props = {
  value: string;
};

const MarkdownViewer = ({ value }: Props) => {
  return (
    <div className="base-markdown">
      {/* @ts-ignore*/}
      <Viewer initialValue={value} />
    </div>
  );
};

export default MarkdownViewer;
