import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { menuTitle } from "./Container";

type Props = {
  isCreatePage?: boolean; // 등록페이지인지 여부
  title?: string; // 커스텀 타이틀:: 우선적용
  subTitle?: string; // 서브타이틀:: '대메뉴 | subTitle | env Ctrl.Room'
  description?: string;
  keywords?: string;
  imgsrc?: string;
  url?: string;
  siteName?: string;
};

/**
 * SEO meta tag 컴포넌트
 * @param title : 기본 "Ctrl.room"
 */
const CTRL_ROOM = "Ctrl.Room";

const findEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "local":
      return `local ${CTRL_ROOM}`;
    case "dev":
      return `dev ${CTRL_ROOM}`;
    case "stg":
      return `stg ${CTRL_ROOM}`;
    case "prod":
      return `${CTRL_ROOM}`;
    default:
      return `${CTRL_ROOM}`;
  }
};

const findMenuNameInURL = (url: string) => {
  const menuName = url.split("/")[5];
  return menuName;
};



export default function MetaTag({ title, subTitle, isCreatePage, description, keywords, imgsrc, url, siteName }: Props) {
  const location = useLocation();
  const menuName = findMenuNameInURL(location.pathname);
  let titles = [];
  
  if(menuName){
    for(let menu of menuTitle){
      if(menu.keyword === menuName){
        titles.push(menu.title) 
      }
    }
  } 
 
  // 서브타이틀이 있으면 추가 ex) 신청계약번호, id 등
  if (subTitle) {
    titles.push(subTitle);
  }

  // 등록 페이지일 경우
  if(isCreatePage){
    titles.unshift('등록');
  }

  return (
    <Helmet>
      <title>{title ? title : [...titles, findEnv()].join(" | ")}</title>
      <meta name="format-detection" content="telephone=yes" />

      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteName} />
      {description && <meta property="og:description" content={description} />}
      {imgsrc && <meta property="og:image" content={imgsrc} />}
      {url && <meta property="og:url" content={url} />}

      {url && <link rel="canonical" href={url} />}
    </Helmet>
  );
}
