import React from "react";

import { atom, useRecoilState } from "recoil";
import { globalDirtyState } from "./atom";

const useDirtyState = () => {
  /** 변경된 데이터가 있으면 boolean = true로 set */
  const [isDirtyState, setIsDirtyState] = useRecoilState(globalDirtyState);

  return { isDirtyState, setIsDirtyState };
};

export default useDirtyState;
