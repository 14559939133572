import moment from "moment";
import { ContractStep } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import pagePath from "src/pagePath.json";
import { checkContractStatus } from "src/pages/contract/contract-util";
import { ViewYmdFormat } from "src/utils/common-util";

const FullCourtInfo = ({ contractDetail, scheduleList, rentalSchedule, totalCount }: any) => {
  return (
    <div>
      <div className="contents-container__wrap">
        <article className="contents-container__wrap-article">
          <BaseSectionTitle title={"신청/계약 정보"} />

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>신청 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <BaseNewTabLink
                    path={pagePath.contract.detail.replace(":id", contractDetail.contract?.contractId)}
                    value={contractDetail.contractManage?.contractApplyNumber || "-"}
                    ellipsis
                  />
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div
                  className={`${
                    (contractDetail.contract?.contractStep === ContractStep.APPLY_RECEIVED && "chip gray") ||
                    (contractDetail.contract?.contractStep === ContractStep.APPLY_CANCEL && "chip gray") ||
                    (contractDetail.contract?.contractStep === ContractStep.APPLY_CONFIRM && "chip blue") ||
                    (contractDetail.contract?.contractStep === ContractStep.CONTRACT_PLAN && "chip draft") ||
                    (contractDetail.contract?.contractStep === ContractStep.CONTRACT_ACCEPT && "chip blue") ||
                    (contractDetail.contract?.contractStep === ContractStep.USE_APPROVAL && "chip visible") ||
                    (contractDetail.contract?.contractStep === ContractStep.USE_PROGRESS && "chip visible") ||
                    (contractDetail.contract?.contractStep === ContractStep.USE_COMPLETE && "chip gray") ||
                    (contractDetail.contract?.contractStep === ContractStep.TERMINATE_RECEIVED && "chip invisible") ||
                    (contractDetail.contract?.contractStep === ContractStep.TERMINATE_COMPLETE && "chip gray")
                  }`}
                >
                  <p>{contractDetail.contract?.contractStep ? checkContractStatus(contractDetail.contract?.contractStep) : "-"}</p>
                </div>
              </div>
            </section>
          </div>
          <div className="flex-center">
            {/* 회원번호 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품 id</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <BaseNewTabLink
                    path={pagePath.product.detail.replace(":id", contractDetail.product?.id)}
                    value={contractDetail.product?.id || "-"}
                    ellipsis
                  />
                </div>
              </div>
            </section>
            {/* 이름 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>상품명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contractDetail.product?.productName}</p>
              </div>
            </section>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약자 회원 번호</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* <Link to={""} className="text-underline"> */}
                <p>{contractDetail.contractManage?.contractorList[0]?.memberNo}</p>
                {/* </Link> */}
              </div>
            </section>
            {/* 이름 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>계약자 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <BaseTooltip contents={contractDetail?.contract?.applicantName} />
              </div>
            </section>
          </div>
        </article>
        <article className="contents-container__wrap-article">
          <BaseSectionTitle title={"신청/계약 빌링 기간"} />

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링 시작</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{rentalSchedule?.scheduleStartTime ? moment(rentalSchedule?.scheduleStartTime).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</p>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌링 종료</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{rentalSchedule?.scheduleEndTime ? moment(rentalSchedule?.scheduleEndTime).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</p>
              </div>
            </section>
          </div>
        </article>
        <article className="contents-container__wrap-article">
          <BaseSectionTitle title={"이용료 결제 정보"} />

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>프로바이더 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>{contractDetail?.provider?.provider?.providerName}</p>
              </div>
            </section>
          </div>

          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>결제 방법</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {rentalSchedule?.regularPgcode === "creditcard" && "신용카드"}
                  {rentalSchedule?.regularPgcode === "banktransfer" && "계좌이체"}
                  {rentalSchedule?.regularPgcode === "CMS" && "CMS"}
                  {rentalSchedule?.regularPgcode === "virtualaccount" && "현금(가상계좌)"}
                </p>
              </div>
            </section>
          </div>
          {contractDetail?.contract?.spaceProductType !== "TIME_COURT" && (
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>정기 청구일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {rentalSchedule?.payRegularDay + "일"} {rentalSchedule?.payRegularMonth === "PREV_MONTH" && "(전월 기준)"}
                    {rentalSchedule?.payRegularMonth === "CURR_MONTH" && "(당월 기준)"}
                    {rentalSchedule?.payRegularMonth === "NEXT_MONTH" && "(익월 기준)"}
                  </p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>납부기한</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{rentalSchedule?.payDueDays ? "청구일 기준 + " + rentalSchedule?.payDueDays + "일" : "-"}</p>
                </div>
              </section>
            </div>
          )}
          {contractDetail?.contract?.spaceProductType !== "TIME_COURT" && (
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>세금계산서 자동 발행</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>{rentalSchedule?.isTaxInvoiceIssue ? "발행" : "발행 안함"}</p>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>세금계산서 발행일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    {rentalSchedule?.taxInvoiceIssueDay ? rentalSchedule?.taxInvoiceIssueDay + "일" : "-"}
                    {rentalSchedule?.taxInvoiceIssueDay && rentalSchedule?.taxInvoiceIssueMonth === "PREV_MONTH" && "(전월 기준)"}
                    {rentalSchedule?.taxInvoiceIssueDay && rentalSchedule?.taxInvoiceIssueMonth === "CURR_MONTH" && "(당월 기준)"}
                    {rentalSchedule?.taxInvoiceIssueDay && rentalSchedule?.taxInvoiceIssueMonth === "NEXT_MONTH" && "(익월 기준)"}{" "}
                  </p>
                </div>
              </section>
            </div>
          )}
          <div className="flex-center">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>빌키</p>
              </div>
              <div className="contents-container__grid-contents">
                <p>
                  {rentalSchedule?.billkey !== undefined && rentalSchedule?.billkey !== null
                    ? rentalSchedule?.billkey !== ""
                      ? "등록완료"
                      : "미등록"
                    : "-"}
                </p>
              </div>
            </section>
          </div>
        </article>
      </div>
    </div>
  );
};

export default FullCourtInfo;
