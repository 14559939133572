import React from "react";
import { ProviderAssignedWorkType, ProviderContactModel } from "src/api/provider/provider-types";
import ProviderContactTable from "../../contactInfoDetail/components/ProviderContactTable";

type Props = { providerContacts?: Array<ProviderContactModel> };

const providerContactTypes: Array<ProviderAssignedWorkType> = ["CS_PROVIDE", "NORMAL_PROVIDE", "TAX_PROVIDE"];

const ProviderContactDetail = ({ providerContacts }: Props) => {
  return (
    <div>
      {providerContactTypes.map((type) => (
        <ProviderContactTable key={type} providerContacts={providerContacts?.filter((item) => item.assignedWorkType === type)} type={type} />
      ))}
    </div>
  );
};

export default ProviderContactDetail;
