import { DetailTotalData } from "src/api/contract/contract-types";
import ContractBillingSchedule from "src/pages/contract/commonComponents/ContractBillingSchedule";
import ContractBillingScheduleNoti from "src/pages/contract/commonComponents/ContractBillingScheduleNoti";
import ContractBuildingManagement from "src/pages/contract/commonComponents/ContractBuildingManagement";
import ContractInfo from "src/pages/contract/commonComponents/ContractInfo";
import ContractorAndOrganization from "src/pages/contract/commonComponents/ContractorAndOrganization";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setData: Function;
}
const FOType = ({ detailData, setData }: ContractBasicProp) => {
  return (
    <>
      <ContractorAndOrganization detailData={detailData} setData={setData} />
      <ContractInfo detailData={detailData} setData={setData} />
      <ContractBillingSchedule detailData={detailData} setData={setData} />
      <ContractBillingScheduleNoti detailData={detailData} setData={setData} />
      <ContractBuildingManagement detailData={detailData} setData={setData} />
    </>
  );
};

export default FOType;
