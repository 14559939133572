import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { ContractorNoti, DetailTotalData } from "src/api/contract/contract-types";
import { BaseButton, BaseRadio, BaseTooltip } from "src/components";
import { ViewYmdFormat, calculateDate, formatPhoneNumber, numberToStringWithComma } from "src/utils";
import BaseSection from "../../../../components/layout/BaseSection";
import ContractImageUpload from "../ContractImageUpload";
import EsignOnLink from "../editComponent/EsignOnLink";
import ModifyAmountModalAccordion from "../modal/ModifyAmountModalAccordion";
import SnapShotModal from "../modal/SnapShotModal";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setData: any;
}
const ContractInfoDetail = ({ detailData, setData }: ContractBasicProp) => {
  const R = "RENTAL";
  const { contract, contractManage, scheduleList } = detailData;
  const [textGrey, setTextGrey] = useState("contents-container__grid ");
  const [isSnapShotOpen, setIsSnapShotOpen] = useState(false);
  const [document, setDocument] = useState<any[]>([]);
  const [require, setRequire] = useState<any[]>([]);
  const [rentalSchedule, setRentalSchedule] = useState<BillingDetail>();
  const [isModifyAmountOpen, setIsModifyAmountOpen] = useState(false);
  const [isEsign, setIsEsign] = useState(false);
  const [calDate, setCalDate] = useState("");
  const [s2TaxNotiOne, setS2TaxNotiOne] = useState<any>({
    email: "",
    isDeleted: false,
    contact: "",
    receiveNotiType: "TAX_INVOICE",
    description: "",
    errorTextEmail: "",
  });
  const initDatas = useCallback(() => {
    if (detailData) {
      const doc = contract?.mediaList!.filter((media: any) => media.serviceTag === "CONTRACT_DOCUMENT");
      let req = contract?.mediaList!.filter((media: any) => media.serviceTag !== "CONTRACT_DOCUMENT");
      req = _.sortBy(req, ["orderNums"]);
      if (doc) {
        setDocument(doc);
      }
      if (req) {
        setRequire(req);
      }

      const schedule = scheduleList?.find((sd: BillingDetail) => sd.supplyType === R);
      let returnText = calculateDate(contract?.contractStartTime!, contract?.contractEndTime!, contractManage?.spaceProductType!);
      if (returnText !== "") {
        setCalDate(returnText);
      }
      const billContractorNoti = _.cloneDeep(contract?.billContactList);

      const tax = billContractorNoti!.filter((noti: ContractorNoti) => noti.receiveNotiType !== "TALK_OFFICIALDOC");

      if (detailData.product.productType === "TIME_COURT" && tax.length > 0) {
        setS2TaxNotiOne(tax[0]);
      }

      setRentalSchedule(schedule);
    }
  }, [
    contract?.contractEndTime,
    contract?.contractStartTime,
    contract?.eSignList,
    contract?.mediaList,
    contractManage?.spaceProductType,
    detailData,
    scheduleList,
  ]);
  const checkEsign = useMemo(() => {
    if (Array.isArray(contract?.eSignList) && contract?.eSignList.length! > 0) {
      setIsEsign(true);
      return true;
    } else {
      setIsEsign(false);
      return false;
    }
  }, [contract]);

  useEffect(() => {
    initDatas();
  }, [initDatas]);
  return (
    <>
      <div className="flex-center">
        <BaseSection
          titleChildren={
            <div className="flex-center">
              <p>계약명</p>
              <BaseTooltip
                touchIcon={"QUESTION"}
                className="ml4 mt1"
                size={16}
                tooltip="계약명을 설정하면, 앱/알림톡/이메일 공문에 관리자가 입력한 정보가 출력됩니다."
              />
            </div>
          }
          children={
            <div className="flex-row flex-center-start w-100 font14">
              <div className={contract && contract?.contractName!.length > 15 ? "" : "mr5"}>
                {contract && contract?.contractName!.length > 25 ? (
                  <div className={"flex-center-start"}>
                    <p>{contract?.contractName!.substring(0, 15) + "..."}</p>
                    <BaseTooltip contents={""} tooltip={contract?.contractName} touchIcon={"QUESTION"} />
                  </div>
                ) : (
                  contract?.contractName
                )}{" "}
              </div>
            </div>
          }
        />
        <BaseSection
          title={"계약일시"}
          value={contract?.contractDate ? moment(contract?.contractDate).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}
        />
      </div>
      <div className="flex-center">
        <BaseSection
          title={"계약자명"}
          children={
            <div className="flex-row flex-center-start w-100 font14">
              <div className={detailData?.contract?.applicantName && detailData?.contract?.applicantName.length > 15 ? "" : "mr5"}>
                {contract && contract?.applicantName && contract?.applicantName!.length > 25 ? (
                  <div className={"flex-center-start"}>
                    <p>{contract?.applicantName!.substring(0, 15) + "..."}</p>
                    <BaseTooltip contents={""} tooltip={contract?.applicantName} touchIcon={"QUESTION"} />
                  </div>
                ) : (
                  contract?.applicantName
                )}{" "}
              </div>
            </div>
          }
        />
        {detailData?.product?.productType !== "TIME_COURT" ? (
          <BaseSection
            title={"이용 기간"}
            children={
              <p>
                {contract?.contractStartTime ? moment(contract?.contractStartTime).format(ViewYmdFormat.YYYY_MM_DD) : "-"} ~{" "}
                {contract?.contractEndTime ? moment(contract?.contractEndTime).format(ViewYmdFormat.YYYY_MM_DD) : "-"} ({calDate ? calDate : "-"})
              </p>
            }
          />
        ) : (
          <BaseSection
            title={"계약 번호"}
            children={
              <div className="flex-row flex-center-start">
                <span className="mr10">{contract?.contractNumber ? contract?.contractNumber : "-"}</span>
                {textGrey !== "" && (
                  <BaseButton
                    title="계약 상품정보 스냅샷"
                    className="color-white"
                    disabled={
                      contract?.contractStep === "APPLY_RECEIVED" ||
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "APPLY_CONFIRM"
                    }
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={Number(contract?.contractId)}
                  detailData={detailData}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            }
          />
        )}
      </div>
      <div className="flex-center">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection title={"보증금"} value={numberToStringWithComma(Number(contract?.earnestAmount) + Number(contract?.balanceAmount)) + " 원"} />
        )}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"이용료(부가세 별도)"}
            children={
              <div className="flex-row flex-center-start ">
                <span className="mr16">{contract?.payAmount ? numberToStringWithComma(contract?.payAmount) + " 원" : "-"}</span>
                <BaseButton
                  title="청구 금액 확인"
                  className="color-white"
                  disabled={
                    contract?.contractStep === "APPLY_RECEIVED" ||
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "APPLY_CONFIRM" ||
                    contract?.contractStep === "CONTRACT_PLAN"
                  }
                  onClick={() => setIsModifyAmountOpen(true)}
                />
                <ModifyAmountModalAccordion
                  detailData={detailData}
                  scheduleId={Number(rentalSchedule?.scheduleId)}
                  isOpen={isModifyAmountOpen}
                  onClose={() => {
                    setIsModifyAmountOpen(false);
                  }}
                  onClick={() => {}}
                  viewOnly={true}
                />
                {/* <ModifyAmountModal
                  detailData={detailData}
                  scheduleId={Number(rentalSchedule?.scheduleId)}
                  isOpen={isModifyAmountOpen}
                  onClose={() => {
                    setIsModifyAmountOpen(false);
                  }}
                  onClick={() => {}}
                  viewOnly={true}
                /> */}
              </div>
            }
          />
        )}
      </div>

      <div className="flex-center">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"ㄴ 계약금"}
            children={
              <div className="flex-row flex-center-start">
                <div className={contract?.earnestPayStatus === "PAY_WAIT" ? `chip blue` : "chip green"}>
                  {contract?.earnestPayStatus === "PAY_WAIT" ? "결제 대기" : "결제 완료"}
                </div>
                <span className="ml5">{Number(contract?.earnestAmount) > 0 ? numberToStringWithComma(contract?.earnestAmount) + " 원" : "-"}</span>
              </div>
            }
          />
        )}
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"납부 유형"}
            children={
              <div className="flex-row flex-center-start">
                <div className={contract?.costType !== "COST_BILLING_ONE_TIME" ? `chip blue` : "chip green"}>
                  {contract?.costType !== "COST_BILLING_ONE_TIME" ? "정기 납부" : "일시 납부"}
                </div>
              </div>
            }
          />
        )}
      </div>
      <div className="flex-center">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"ㄴ 잔금"}
            children={
              <div className="flex-row flex-center-start">
                <div className={contract?.balancePayStatus === "PAY_WAIT" ? `chip blue` : "chip green"}>
                  {contract?.balancePayStatus === "PAY_WAIT" ? "결제 대기" : "결제 완료"}
                </div>
                <span className="ml5">{Number(contract?.balanceAmount) > 0 ? numberToStringWithComma(contract?.balanceAmount) + " 원" : "-"}</span>
              </div>
            }
          />
        )}
      </div>
      <div className="flex-center">
        {detailData?.product?.productType !== "TIME_COURT" ? (
          <BaseSection
            title={"계약 번호"}
            children={
              <div className="flex-row flex-center-start">
                <span className="mr10">{contract?.contractNumber ? contract?.contractNumber : "-"}</span>
                {textGrey !== "" && (
                  <BaseButton
                    title="계약 상품정보 스냅샷"
                    className="color-white"
                    disabled={
                      contract?.contractStep === "APPLY_RECEIVED" ||
                      contract?.contractStep === "APPLY_CANCEL" ||
                      contract?.contractStep === "APPLY_CONFIRM"
                    }
                    onClick={() => setIsSnapShotOpen(true)}
                  />
                )}
                <SnapShotModal
                  isOpen={isSnapShotOpen}
                  contractId={Number(contract?.contractId)}
                  detailData={detailData}
                  onClose={() => {
                    setIsSnapShotOpen(false);
                  }}
                />
              </div>
            }
          />
        ) : (
          <BaseSection title={"세금계산서 수신 이메일"} children={<p>{s2TaxNotiOne.email}</p>} />
        )}
        <BaseSection
          title={"계약 형태"}
          children={
            <div className="flex-row flex-center-start">
              <BaseRadio
                id="contractType1"
                name="contractType"
                className="mr10"
                value={"true"}
                label="직접 계약"
                checked={String(checkEsign) === "false" ? true : false}
                onChange={() => {
                  setIsEsign(false);
                }}
                disabled
              ></BaseRadio>
              <BaseRadio
                id="contractType2"
                name="contractType"
                value={"false"}
                label="전자 서명"
                checked={String(checkEsign) === "true" ? true : false}
                disabled
                onChange={() => {
                  setIsEsign(true);
                }}
              ></BaseRadio>
            </div>
          }
        />
      </div>
      {isEsign ? (
        <EsignOnLink detailData={detailData} viewOnly={true} setData={setData} />
      ) : (
        <div className="flex-start">
          <BaseSection title={"계약서"} children={<ContractImageUpload isOnlyView type={"PROOF"} images={document} />} />
          <BaseSection title={"계약 필요 서류"} children={<ContractImageUpload isOnlyView type={"PROOF"} images={require} />} />
        </div>
      )}
      <div className="flex-start">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection title={"관리비 청구 유형"} value={contract?.isMntContain ? "관리비 포함" : "관리비 별도"} />
        )}
      </div>
      <div className="flex-start">
        {detailData?.product?.productType !== "TIME_COURT" && (
          <BaseSection
            title={"공문 수신자"}
            children={
              <>
                {contract?.billContactList?.map((list: any, index: number) => {
                  return (
                    <div className="flex-row flex-center" key={index}>
                      {list.receiveNotiType === "TALK_OFFICIALDOC" && (
                        <>
                          <BaseSection title={"이메일"} value={list?.email ? list?.email : "-"} />
                          <BaseSection title={"휴대폰"} value={list?.contact ? formatPhoneNumber(String(list?.contact)) : "-"} />
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      {detailData?.product?.productType !== "TIME_COURT" && rentalSchedule?.isTaxInvoiceIssue && (
        <div className="flex-start">
          <BaseSection
            title={"세금계산서 수신자"}
            children={
              <>
                {contract?.billContactList?.map(
                  (list: any, index: number) =>
                    list.receiveNotiType !== "TALK_OFFICIALDOC" && (
                      <div className="flex-row flex-center-start" key={index}>
                        <BaseSection title={"이메일"} value={list?.email ? list?.email : "-"} />
                      </div>
                    ),
                )}
              </>
            }
          />
        </div>
      )}
    </>
  );
};
export default ContractInfoDetail;
