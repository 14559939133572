import { useState } from "react";
import { DetailTotalData } from "src/api/contract/contract-types";
import { ArticleSection } from "src/components/layout/ArticleSection";
import ContractorAndOrganizationDetail from "./detailComponent/ContractorNOrganizationDetail";
import ContractorNOrganizationEdit from "./editComponent/ContractorNOrganizationEdit";

/**
 * 상품정보 영역
 * Props
 * detailData
 */
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  setData: Function;
}

const ContractorAndOrganization = ({ detailData, setData }: ContractBasicProp) => {
  const [editable, setEditable] = useState(false);
  const [saveFlags, setSaveFlags] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [editConfrim, setEditConfrim] = useState(false);
  const [commonModals, setCommonModals] = useState<any>({
    message: "",
    title: "",
  });
  return (
    <ArticleSection
      title={"계약자 정보"}
      isEditable={editable}
      setIsEditable={setEditable}
      onClickSave={() => setSaveFlags(true)}
      popupState={popupState}
      setPopupState={setPopupState}
      editConfrim={editConfrim}
      setEditConfrim={setEditConfrim}
      commonModals={commonModals}
      disabledState={[String(detailData?.contract?.contractStep), String(detailData?.product.productType)]}
      children={
        editable === false ? (
          <ContractorAndOrganizationDetail detailData={detailData} />
        ) : (
          <ContractorNOrganizationEdit
            detailData={detailData}
            setData={setData}
            setEditable={setEditable}
            saveFlags={saveFlags}
            setSaveFlags={setSaveFlags}
            popupState={popupState}
            setPopupState={setPopupState}
            setCommonModals={setCommonModals}
            setEditConfrim={setEditConfrim}
          />
        )
      }
    />
  );
};

export default ContractorAndOrganization;
