import React from "react";
import { ProviderPayletterModel } from "src/api/provider/provider-types";
type Props = { providerPayletter: ProviderPayletterModel };

const AutoPayletterDetail = ({ providerPayletter }: Props) => {
  return (
    <div className="mb10">
      <div className="index-tab">
        <span>자동 결제 페이레터 정보</span>
      </div>
      {providerPayletter.autoClientId &&
      providerPayletter.autoSearchKey &&
      providerPayletter.autoPaymentKey ? (
        <>
          {" "}
          <div className="border-gray py16 pr16 px20">
            <section className="flex-center mb10">
              <div className="minmax123">
                <span className="font13 text-gray600">가맹점 아이디</span>
              </div>
              <p className="font13">
                {providerPayletter.autoClientId
                  ? providerPayletter.autoClientId
                  : "등록된 내용이 없습니다."}
              </p>
            </section>
            <section className="flex-center mb10">
              <div className="minmax123">
                <span className="font13 text-gray600">API Key (PAYMENT)</span>
              </div>
              <p className="font13">
                {providerPayletter.autoPaymentKey
                  ? providerPayletter.autoPaymentKey.replace(/./g, "*")
                  : "등록된 내용이 없습니다."}
              </p>
            </section>
            <section className="flex-center">
              <div className="minmax123">
                <span className="font13 text-gray600">API Key (SEARCH)</span>
              </div>
              <p className="font13">
                {providerPayletter.autoSearchKey
                  ? providerPayletter.autoSearchKey.replace(/./g, "*")
                  : "등록된 내용이 없습니다."}
              </p>
            </section>
          </div>
          <div className="mt10">
            <div className="index-tab">
              <span>자동 결제 페이레터 정보 검증</span>
            </div>
            <div className="border-gray py16 pr16 px20">
              <section className="flex-center">
                <div className="minmax200 d-flex">
                  <span className="font13 text-gray600">1. Payment API Key: </span>
                  <span className=" font13 ml10">
                    {providerPayletter?.isVerifiedAutoPaymentKey ? "성공" : "실패"}
                  </span>
                </div>

                <div className="minmax200">
                  <span className="font13 text-gray600">2. Search API Key 조회 API: </span>
                  <span className=" font13 ml10">
                    {providerPayletter?.isVerifiedAutoSearchKey ? "성공" : "실패"}
                  </span>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <div className="border-gray py16 pr16 px20">
          <span className="font13">입력된 정보가 없습니다.</span>
        </div>
      )}
    </div>
  );
};

export default AutoPayletterDetail;
