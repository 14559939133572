import { useState } from "react";
import { BaseButton } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import ProductFacilitySelectModal from "src/pages/contract/commonComponents/modal/FacilitySelectModal";
interface ContractBasicProp {
  saveObject: any;
  setSaveData: any;
}

const ProductCommomFacilitySelect = ({ saveObject, setSaveData }: ContractBasicProp) => {
  const [faciltyFlag, setFacilityFlag] = useState(false);
  console.log("saveObject?.applicantMemberNo", saveObject?.applicantMemberNo);
  return (
    <>
      <article className="contents-container__wrap-article">
        <div className="contents-container__sub-title">
          <h2>신청 공간</h2>
        </div>

        <div className="flex-row flex-center-start">
          <BaseSection
            title={"공용공간 명"}
            children={
              <div className="flex-row flex-center-start">
                <p>{saveObject?.selectedFacility?.facilityName ? saveObject?.selectedFacility?.facilityName : "-"}</p>
                <BaseButton
                  title={"신청 공간 선택"}
                  className="color-white ml10"
                  onClick={() => {
                    setFacilityFlag(true);
                  }}
                  disabled={saveObject?.applicantMemberNo === undefined || saveObject?.applicantMemberNo === ""}
                />
                {faciltyFlag && (
                  <ProductFacilitySelectModal
                    isOpen={faciltyFlag}
                    saveObject={saveObject}
                    setSaveData={setSaveData}
                    onClose={() => setFacilityFlag(false)}
                  />
                )}
              </div>
            }
          />

          <BaseSection
            title={"공용공간 유형"}
            children={
              <p>
                {saveObject?.selectedFacility?.commonFacilityType === undefined && "-"}
                {saveObject?.selectedFacility?.commonFacilityType === "DESK" && "좌석"}
                {saveObject?.selectedFacility?.commonFacilityType === "MEETING_ROOM" && "회의실"}
                {saveObject?.selectedFacility?.commonFacilityType === "REFRESH_ROOM" && "편의시설"}
              </p>
            }
          />
        </div>

        <div className="flex-center">
          <BaseSection
            title={"정원"}
            children={<p>{saveObject?.selectedFacility?.facilityMaxPeopleNums ? saveObject?.selectedFacility?.facilityMaxPeopleNums + "명" : "-"}</p>}
          />
          <BaseSection title={"위치"} value={saveObject?.selectedFacility?.locationCode ? saveObject?.selectedFacility?.locationCode : "-"} />
        </div>
        <div className="flex-center">
          <BaseSection
            title={"건물"}
            children={<p>{saveObject?.selectedFacility?.buildingName ? saveObject?.selectedFacility?.buildingName : "-"}</p>}
          />
        </div>
      </article>
    </>
  );
};

export default ProductCommomFacilitySelect;
