import { Cell } from "react-table";
import { BaseTooltip } from "src/components";
import Link from "src/components/PartnerLink";
import { calculatePyeong, calculateRateOfUse, numberToStringWithComma } from "src/utils";
import { PagePath } from "..";
import BaseNewTabLink from "src/components/BaseNewTabLink";

export const columns: Array<any> = [
  {
    Header: "대표",
    accessor: "isPrimary",
    Cell: ({ row, toggleAllRowsSelected, toggleRowSelected, ...rest }: any) => {
      return <div>{row.getToggleRowSelectedProps().checked ? "대표" : "-"}</div>;
    },
    Footer: ({ rows }: any) => {
      return <div>-</div>;
    },
    width: 60,
  },
  {
    Header: "건물 ID",
    accessor: "buildingId",

    width: 70,
  },
  {
    Header: "건물 명",
    accessor: "buildingName",
    Cell: ({ value, row }: Cell<any>) => {
      const path = PagePath.building.detail.replace(":id", row.original.buildingId);
      //새탭열기+튤팁+말줄임+2줄
      return <BaseNewTabLink path={path} value={value || "-"} tooltip></BaseNewTabLink>;
    },
    width: 180,
  },
  {
    Header: "호실",
    accessor: "roomNum",
    width: 90,
    Cell: ({ value }: any) => {
      if (value < 0) {
        return <span>{String(value).replace("-", "")}</span>;
      } else {
        return <span>{value}</span>;
      }
    },
  },
  {
    Header: "층",
    accessor: "floorNum",
    width: 90,
    Cell: ({ value }: any) => {
      if (value < 0) {
        return <span>지하 {String(value).replace("-", "")}</span>;
      } else {
        return <span>지상 {value}</span>;
      }
    },
  },
  {
    Header: "전용면적(m2)",
    accessor: "leasableAreaNet",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
      return <BaseTooltip contents={`${numberToStringWithComma(sum)}`} isSingleLine={true} />;
    },
  },
  {
    Header: "전용면적(평)",
    accessor: "",
    width: 120,
    Cell: ({ row }: any) => {
      const value = row.original.leasableAreaNet;
      return (
        <>
          <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;
      rows.map((item: any) => (sum += Number(item.original.leasableAreaNet)));
      return <BaseTooltip contents={`${calculatePyeong(sum)}`} isSingleLine={true} className="font-weight-bold" />;
    },
  },
  {
    Header: "임대면적(m2)",
    accessor: "leasableArea",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        // TODO :: 세번째 숫자 , 찍기
        <>
          <BaseTooltip contents={`${numberToStringWithComma(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;

      rows.map((item: any) => (sum += Number(item.original.leasableArea)));
      return (
        // 임대면적 SUM leasableArea

        <BaseTooltip contents={`${numberToStringWithComma(sum)}`} isSingleLine={true} className="font-weight-bold" />
      );
    },
  },
  {
    Header: "임대면적(평)",
    accessor: "",
    width: 120,
    Cell: ({ row }: any) => {
      const value = row.original.leasableArea;
      return (
        // TODO :: 세번째 숫자 , 찍기
        <>
          <BaseTooltip contents={`${calculatePyeong(value)}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      let sum = 0;

      rows.map((item: any) => (sum += Number(item.original.leasableArea)));
      return (
        // 임대면적 SUM leasableArea

        <BaseTooltip contents={`${calculatePyeong(sum)}`} isSingleLine={true} className="font-weight-bold" />
      );
    },
  },
  {
    Header: "전용률(%)",
    accessor: "rateOfUse",
    width: 100,
    Cell: ({ row }: any) => {
      // let rateOfUse = 0;

      const rateOfUse = calculateRateOfUse(row?.original?.leasableAreaNet, row?.original?.leasableArea);

      return (
        // TODO :: 세번째 숫자 , 찍기
        <>
          <BaseTooltip contents={`${rateOfUse}`} isSingleLine={true} />
        </>
      );
    },
    Footer: ({ rows }: any) => {
      //let sum = 0
      let sumLeasableAreaNet = 0;
      let sumLeasableArea = 0;

      rows.map((row: any) => {
        return (sumLeasableAreaNet += Number(row.original.leasableAreaNet));
      });

      rows.map((row: any) => {
        return (sumLeasableArea += Number(row.original.leasableArea));
      });

      const rateOfUse = calculateRateOfUse(sumLeasableAreaNet, sumLeasableArea);

      return (
        // TODO :: 세번째 숫자 , 찍기
        <BaseTooltip contents={`${rateOfUse}`} isSingleLine={true} className="font-weight-bold" />
      );
    },
  },
];
