import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { BaseDatePicker, BaseInput, BaseSelect } from "src/components";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, deleteCommas, numberToStringWithComma, onlyNumber } from "src/utils";

export const columns: any = [
  {
    Header: "결제수단",
    accessor: "pgcode",
    // sticky: "left",
    width: 180,
    Cell: ({ row, mainRow, sortedRecevieList, setReceiveList, showBtn, contractBaseInfo }: any) => {
      const [pgcode, setPgcode] = useState("");
      const options = useMemo(() => {
        // console.log("contractBaseInfo", contractBaseInfo, mainRow);
        let options1 = [
          { label: "신용카드", value: "creditcard" },
          { label: "가상계좌", value: "virtualaccount" },
          { label: "입금연계", value: "banktransfer" },
        ];
        let options2 = [
          { label: "현금", value: "physicalcash" },
          { label: "기타", value: "other" },
        ];

        if (mainRow.original.bill.supplyType !== "MNT") {
          if (Number(row.original.receiptId) !== 0 || Number(row.original.acctCreditId) !== 0) {
            return options1.concat(options2);
          } else {
            return options2;
          }
        } else {
          return options2;
        }
      }, [mainRow, row.original.acctCreditId, row.original.receiptId]);

      return (
        <BaseSelect
          stateOptions={options}
          isDisabled={Number(row.original.receiptId) !== 0 || Number(row.original.acctCreditId) !== 0}
          value={String(row.original.pgcode)}
          menuPosition="fixed"
          menuPortalTarget={document.body}
          setStateValue={(value: string) => {
            setPgcode(value);

            const receiveDataTemp = _.cloneDeep(sortedRecevieList);

            receiveDataTemp.forEach((rd: any) => {
              console.log("rd", rd);
              if (row.original.viewCount === rd.viewCount) {
                rd.pgcode = value;
              }
            });

            setReceiveList(receiveDataTemp);
          }}
        />
      );
    },

    Footer: ({ rows }: any) => {
      return <p className="text-primary3">수납 처리 합계</p>;
    },
  },
  {
    Header: "수납 금액",
    accessor: "receiptAmount",

    Cell: ({ value, row, showBtn, receiveData, setReceiveData, sortedRecevieList, mainRow, setReceiveList, paymentArray }: any) => {
      const [price, setPrice] = useState("");
      const { openToast } = useToast();
      useEffect(() => {
        setPrice(value);
      }, [value]);

      return (
        <div className="w-100 flex-center-end pr20">
          <div className="minmax184 mr10">
            <BaseInput
              placeholder="금액을 입력하세요"
              disabled={!showBtn}
              className="text-right"
              value={numberToStringWithComma(Number(price))}
              onChange={(stringValue: string) => {
                if (stringValue !== "") {
                  let value = Number(onlyNumber(deleteCommas(stringValue!)));
                  let limit = 0;
                  console.log(stringValue, value, "Not NaN", !isNaN(value), "NaN", isNaN(value));

                  if (Number(row.original.acctCreditId) === 0 && Number(row.original.paymentId) === 0) {
                    sortedRecevieList.forEach((receive: any) => {
                      if (_.isEqual(receive.receiptId, row.original.receiptId) === false) {
                        limit += Number(receive.receiptAmount);
                      }
                    });

                    if (!isNaN(value) && Number(mainRow.original.receiptBundle.billTotalAmount) < Number(limit) + Number(value)) {
                      openToast({ content: "수납처리 전 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });
                      return;
                    }
                  } else {
                    //입금인 경우
                    if (Number(row.original.acctCreditId) > 0) {
                      receiveData.forEach((acc: any) => {
                        if (acc.acctCredit.acctCreditId === row.original.acctCreditId) {
                          limit = acc.credit.creditBalance;
                        }
                      });

                      if (!isNaN(value) && Number(value) > Number(limit)) {
                        openToast({ content: "입금 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });
                        return;
                      }

                      limit = 0;

                      sortedRecevieList.forEach((receive: any, ind: number) => {
                        if (_.isEqual(receive, row.original) === false) {
                          limit = Number(limit) + Number(receive.receiptAmount);
                        }
                      });

                      // console.log("value, limit", value, limit, mainRow.original.receiptBundle.billTotalAmount);

                      if (!isNaN(value) && Number(value) + Number(limit) > Number(mainRow.original.receiptBundle.billTotalAmount)) {
                        openToast({ content: "수납 전 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });
                        return;
                      }
                    }
                    limit = 0;
                    if (Number(row.original.paymentId) > 0) {
                      paymentArray.forEach((payment: any) => {
                        limit += Number(payment.totalAmount);
                      });

                      if (!isNaN(value) && Number(value) > Number(limit)) {
                        openToast({ content: "입금 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });
                        return;
                      }

                      sortedRecevieList.forEach((receive: any, ind: number) => {
                        if (_.isEqual(receive, row.original) === false) {
                          limit = Number(limit) + Number(receive.receiptAmount);
                        }
                      });

                      // console.log("value, limit", value, limit, mainRow.original.receiptBundle.billTotalAmount);

                      if (!isNaN(value) && Number(value) + Number(limit) > Number(mainRow.original.receiptBundle.billTotalAmount)) {
                        openToast({ content: "수납 전 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });
                        return;
                      }
                    }
                  }
                } else {
                  stringValue = "0";
                }
                setPrice(onlyNumber(deleteCommas(stringValue!)));
              }}
              onBlur={() => {
                let value = Number(onlyNumber(deleteCommas(price!)));

                let limit = 0;
                //기타인 경우
                if (Number(row.original.acctCreditId) === 0 && Number(row.original.paymentId) === 0) {
                  sortedRecevieList.forEach((receive: any) => {
                    if (_.isEqual(receive.receiptId, row.original.receiptId) === false) {
                      limit += Number(receive.receiptAmount);
                    }
                  });

                  if (Number(mainRow.original.receiptBundle.billTotalAmount) < Number(limit) + Number(value)) {
                    openToast({ content: "수납처리 전 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });

                    return;
                  }
                } else {
                  //입금인 경우
                  if (Number(row.original.acctCreditId) > 0) {
                    receiveData.forEach((acc: any) => {
                      if (acc.acctCredit.acctCreditId === row.original.acctCreditId) {
                        limit = acc.credit.creditBalance;
                      }
                    });

                    if (Number(value) > Number(limit)) {
                      openToast({ content: "입금 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });

                      return;
                    }

                    limit = 0;

                    sortedRecevieList.forEach((receive: any, ind: number) => {
                      if (_.isEqual(receive, row.original) === false) {
                        limit = Number(limit) + Number(receive.receiptAmount);
                      }
                    });

                    // console.log("value, limit", value, limit, mainRow.original.receiptBundle.billTotalAmount);

                    if (Number(value) + Number(limit) > Number(mainRow.original.receiptBundle.billTotalAmount)) {
                      openToast({ content: "수납 전 잔액보다 많은 금액을 입력할 수 없습니다.", isSystemToast: true });

                      return;
                    }
                  }
                  // paymentArray.forEach((payment: any) => {
                  //   // limit = payment;
                  //   console.log("payment", payment);
                  // });
                }

                const temp = _.cloneDeep(sortedRecevieList);

                temp.forEach((rp: any) => {
                  if (
                    rp.acctCreditId === row.original.acctCreditId &&
                    rp.paymentId === row.original.paymentId &&
                    rp.receiptId === row.original.receiptId &&
                    rp.viewCount === row.original.viewCount
                  ) {
                    console.log("value1", value);
                    rp.receiptAmount = Number(value!);
                  }
                });

                // console.log("temp", temp);
                setReceiveList(temp);

                const receiveDataTemp = _.cloneDeep(receiveData);
                // console.log("price, value", price, value);
                const newTemp: any = receiveDataTemp.map((rd: any) => {
                  if (row.original.acctCreditId === rd.acctCredit.acctCreditId) {
                    rd.credit.creditBalance =
                      Number(rd.credit.creditBalance) + Number(mainRow.original.receiptBundle.billTotalAmount) - Number(value);

                    mainRow.original.receiptBundle.receiptTotalAmount = Number(mainRow.original.receiptBundle.receiptTotalAmount) - Number(value);
                  }

                  return rd;
                });

                setReceiveData(newTemp);
              }}
              readonly={Number(row.original.receiptId) > 0}
            />
          </div>
          <p>원</p>
        </div>
      );
    },
    width: 300,
    Footer: ({ rows, makeSum }: any) => {
      return (
        <div className="w-100 flex-center-end pr20">
          <span>{numberToStringWithComma(makeSum) + "원"}</span>
        </div>
      );
    },
  },
  {
    Header: "거래일시",
    accessor: "trDateTime",
    width: 240,
    Cell: ({ value, row, mainRow, sortedRecevieList, setReceiveList, showBtn }: any) => {
      return (
        <div className="minmax200 flex-center-center">
          <div className="minmax184">
            <BaseDatePicker
              type={"full"}
              readonly={Number(row.original.receiptId) > 0}
              placeholder={"거래일시를 입력하세요"}
              name={"receive.trDateTime"}
              disabled={!showBtn}
              maxDate={moment().toDate()}
              filterdReverse={true}
              filteredTime={moment(value).toDate()}
              timeIntervals={30}
              useFilterdTime={moment(value).format(ViewYmdFormat.YYYY_MM_DD) === moment().format(ViewYmdFormat.YYYY_MM_DD)}
              selectedDate={value ? moment(value).toDate() : null}
              setDate={(value: Date) => {
                const temp = _.cloneDeep(sortedRecevieList);
                // console.log("temp", temp);
                temp.forEach((rp: any) => {
                  if (_.isEqual(rp, row.original)) {
                    if (value !== null) {
                      rp.trDateTime = Number(value);
                    } else {
                      rp.trDateTime = null;
                    }
                  }
                });
                setReceiveList(temp);
              }}
            />
          </div>
        </div>
      );
    },
  },
];
export const notS2 = [
  {
    Header: "연체기간",
    accessor: "overdueDays",
    width: 100,
    Cell: ({ value }: any) => {
      return (
        <>
          <p>{value ? value + "일" : "-"}</p>
        </>
      );
    },
  },

  {
    Header: "연체료",
    accessor: "overdueAmount",
    width: 172,
    Cell: ({ value }: any) => {
      return <p className="w-100 text-right pr20">{numberToStringWithComma(value) + "원"}</p>;
    },
    Footer: ({ makeOvSum }: any) => {
      return (
        <div className="w-100 flex-center-end pr20">
          <span>{numberToStringWithComma(makeOvSum) + "원"}</span>
        </div>
      );
    },
  },
];
export const editCol = [
  {
    Header: ({ showBtn, sortedRecevieList: receiveList, mainRow, setReceiveList }: any) => {
      const { openToast } = useToast();
      return (
        <div className="flex-center-start">
          {showBtn && mainRow.original.bill.billPayStatus !== "PAYMENT_SUCCESS" && (
            <button
              className="base-add-btn"
              onClick={() => {
                let rm: any = _.cloneDeep(receiveList);

                console.log("before", rm);
                let lastViewCount = 0;
                let insertRowCheck = true;
                let rmSum = 0;
                rm.forEach((arr: any) => {
                  if (Number(arr.viewCount!) > Number(lastViewCount)) {
                    lastViewCount = arr.viewCount!;
                  }
                  if (Number(arr.receiptAmount) === 0 || arr.pgcode === "" || arr.trDateTime === undefined || arr.trDateTime === "") {
                    insertRowCheck = false;
                  }
                  rmSum += Number(arr.receiptAmount);
                });
                if (rmSum >= mainRow.original.receiptBundle.billTotalAmount) {
                  openToast({ content: "수납 금액을 초과 입력 할 수 없습니다..", isSystemToast: true });
                  return;
                }
                if (insertRowCheck) {
                  let temp: any = {
                    acctCreditId: "0",
                    paymentId: "0",
                    pgcode: "",
                    billId: mainRow.original.bill.billId,
                    receiptAmount: "0",
                    receiptId: "0",
                    viewCount: lastViewCount + 1,
                  };

                  rm.push(temp);
                  console.log("after", rm);
                  setReceiveList(rm);
                } else {
                  openToast({ content: "수납 추가 건 중 입력을 완료하지 않은 것이 있습니다.", isSystemToast: true });
                  return;
                }
              }}
            />
          )}
        </div>
      );
    },
    accessor: "editable",
    width: 80,
    Cell: ({ row, sortedRecevieList: receiveList, setReceiveList }: any) => {
      return (
        <>
          {Number(row.original.receiptId) === 0 && Number(row.original.acctCreditId) === 0 && (
            <div className="flex-center-start">
              <button
                onClick={() => {
                  let rm: any = _.cloneDeep(receiveList);
                  const idx = rm.findIndex(function (arr: any) {
                    return arr.viewCount === row.original.viewCount;
                  });
                  if (idx > -1) rm.splice(idx, 1);
                  setReceiveList(rm);
                }}
                className="base-erase-btn size-large"
              />
            </div>
          )}
        </>
      );
    },
    Footer: ({ rows, makeOvSum }: any) => {},
  },
];
