import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getOrgList } from "src/api/organization/org-api";
import { MbOrg, OrgListParams } from "src/api/organization/org-types";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInputWithSearch, BaseSelect, BaseTable } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { qsParse } from "src/utils";
import OrgColumns from "./columns/OrganizationColumns";

const searchTypes = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "법인명/상호",
    value: "containsName",
  },
  {
    label: "사업자등록번호",
    value: "containsBizRegNo",
  },
  {
    label: "법인등록번호",
    value: "containsCorRegNo",
  },
];

const classificationOptions = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "일반 사업자",
    value: "GENERAL_BIZ",
  },
  {
    label: "소유주",
    value: "OWNER",
  },
];

const businessOptions = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "법인 사업자",
    value: "CORP",
  },
  {
    label: "개인 사업자",
    value: "SP",
  },
];

type QueryParams = {
  page?: number;
  size?: number;
  searchType?: string;
  keyword?: string;
  sort?: Sort;
  classification?: string;
  bizType?: string;
  containsName?: string;
  containsBizRegNo?: string;
  containsCorRegNo?: string;
  search001?: string;
  partnerName?: string;
};

const OrganizaionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { executeAsync: getOrgListApi } = useApiOperation(getOrgList);
  const [organizations, setOrganizations] = useState<MbOrg[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { isAuthority } = usePartnerAuthority();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qsParse(location.search);
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }

    // qs.parse에 decoder때문에 검색값 입력 검색시 한번더 decoding
    if (_queryParams.keyword) {
      _queryParams.keyword = decodeURIComponent(_queryParams.keyword);
    }

    return _queryParams;
  }, [location]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams) => {
      let newQueryParams = { ...params, ...(passParams || {}) };
      // 검색키워드 하이픈 제거
      if (newQueryParams.keyword && newQueryParams.keyword !== "") {
        newQueryParams = { ...params, ...(passParams || {}), keyword: newQueryParams.keyword.replace(/-/g, "") };
      }
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  useEffect(() => {
    setParams({ ...queryParams });
    async function fetchProducts(orgtListParams: OrgListParams) {
      const { data } = await getOrgListApi(orgtListParams);
      setOrganizations(data?.data?.content || []);
      setPageMeta(data?.meta?.pageMeta);
    }
    // let orgListParams: OrgListParams = {
    //   page: queryParams.page,
    //   size: queryParams.size,
    //   sort: queryParams.sort,
    // };
    if (queryParams.searchType && queryParams.searchType !== "") {
      if (queryParams.searchType === "containsName") {
        queryParams.containsName = queryParams.keyword || "";
      } else if (queryParams.searchType === "containsBizRegNo") {
        queryParams.containsBizRegNo = queryParams.keyword || "";
      } else if (queryParams.searchType === "containsCorRegNo") {
        queryParams.containsCorRegNo = queryParams.keyword || "";
      }
    } else {
      queryParams.search001 = queryParams.keyword || "";
    }

    // if (queryParams.searchType && queryParams.searchType !== "") {
    //   if (queryParams.searchType === "containsName") {
    //     orgListParams.containsName = queryParams.keyword || "";
    //   } else if (queryParams.searchType === "containsBizRegNo") {
    //     orgListParams.containsBizRegNo = queryParams.keyword || "";
    //   } else if (queryParams.searchType === "containsCorRegNo") {
    //     orgListParams.containsCorRegNo = queryParams.keyword || "";
    //   }
    // } else {
    //   orgListParams.search001 = queryParams.keyword || "";
    // }
    fetchProducts(queryParams);
  }, [getOrgListApi, queryParams, location.search]);

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* section 태그가 지정검색, 조건검색, 영역이고 반드시 필요함 */}
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseSelect
                    value={params.classification}
                    stateOptions={classificationOptions}
                    placeholder="구분"
                    setStateValue={(type?: string) => {
                      setParams({ ...params, classification: type });
                      navigateWithQueryParams({ ...params, page: 0, classification: type });
                    }}
                  />
                </div>
                <div className="minmax140">
                  <BaseSelect
                    value={params.bizType}
                    stateOptions={businessOptions}
                    placeholder="사업형태"
                    setStateValue={(type?: string) => {
                      setParams({ ...params, bizType: type });
                      navigateWithQueryParams({ ...params, page: 0, bizType: type });
                    }}
                  />
                </div>

                <BaseInputWithSearch
                  type="text"
                  selectValue={params.searchType}
                  inputValue={params?.keyword || ""}
                  stateOptions={searchTypes}
                  setStateValue={(searchType: string) => {
                    setParams({ ...params, searchType });
                  }}
                  onClearClick={() => navigateWithQueryParams({ ...params, keyword: "" })}
                  onChange={(keyword: string) => setParams({ ...params, keyword })}
                  onKeyUp={() => navigateWithQueryParams({ page: 0 })}
                  onSearchClick={() => navigateWithQueryParams({ page: 0 })}
                />
              </div>
            </section>
          </div>
          {isAuthority("w") && (
            <div className="right-area">
              <BaseButton
                title="+ 사업자 등록"
                onClick={() => {
                  navigate(PagePath.organization.form);
                }}
              />
            </div>
          )}
        </div>
        {organizations && (
          <BaseTable
            data={organizations}
            columns={OrgColumns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page });
            }}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={queryParams.sort?.orders}
            // disabledSortHeaders={["imagePath"]}
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number });
            }}
            currentSize={Number(params.size)}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizaionList;
