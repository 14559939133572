import { objectKeys } from "@toss/utils";
import moment from "moment";
import qs from "qs";
import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { BuildingModel, MgmtOfficerType } from "src/api/building/building-types";
import { MediaFile, Modal } from "src/api/public-types";
import {
  WorkOrderDetailModel,
  WorkOrderFormType,
  WorkOrderPreivew,
  WorkOrderPreviewRequest,
  WorkOrderSheetCost,
  WorkOrderSheetCostType,
} from "src/api/work-order/workorder-types";
import { BaseButton, BaseModal } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useToast } from "src/recoil/toast/hook";
import { YmdFormat } from "src/utils";
import useWorkOrder from "../../hooks/useWorkOrder";
import { UnionRecurrenceType, WORK_TYPES } from "../../workOrder-types";
import WorkInfoSection from "./components/WorkInfoSection";
import WorkOrderEditSection from "./components/WorkOrderEditSection";
import WorkOrderScheduleSection from "./components/WorkOrderScheduleSection";
import { makeRequestEditData } from "../../workOrder-function";

export interface Props {
  workOrderDetail?: WorkOrderDetailModel;
}

const CtrlWorkOrderForm = ({ workOrderDetail }: Props) => {
  const [buttonRef, setButtonRef] = useState<RefObject<HTMLButtonElement>>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 워크오더 ID
  const workOrderId = useMemo(() => {
    return queryParams?.id ? String(queryParams.id) : undefined;
  }, [queryParams.id]);

  // 토스트
  const { openToast } = useToast();

  const [groupPayload, setGroupPayload] = useState<{ isHoliday: boolean; recurrenceType: UnionRecurrenceType; recurrenceDayOfWeekType: string }>({
    isHoliday: false,
    recurrenceType: "",
    recurrenceDayOfWeekType: "",
  });

  const [scheduleOptions, setScheduleOptions] = useState(WORK_TYPES);

  const [allCancel, setAllCancel] = useState(false);
  // ============================
  // 저장 선택 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 경고창 팝업
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 관리처 담당자 리스트
  const [mgmtOfficerList, setMgmtOfficerList] = useState<Array<MgmtOfficerType>>([]);

  // 미리보기 이미지
  const [previewList, setPreviewList] = useState<WorkOrderPreivew[]>([]);
  // API 종류BaseRadio

  // 워크오더 업무내용 이미지
  const [workOrderMedias, setWorkOrderMedias] = useState<Array<MediaFile>>([]);

  // 워크오더 처리내용 이미지
  const [workOrderCompletedMedias, setWorkOrderCompletedMedias] = useState<Array<MediaFile>>([]);

  // 워크오더 처리비용 이미지
  const [workOrderCostMedias, setWorkOrderCostMedias] = useState<Array<MediaFile>>([]);

  const {
    // getWorkOrderDetail, // 워크오더 상세 api
    getBuildingManagement, // 관리처 관리 상세
    getWorkOrderPreview, // 워크오더 반복일정 api
    postWorkOrder, // 워크오더 등록 api
    editWorkOrder, // 워크오더 수정 api
    workOrderCancel, // 워크오더 취소 api
    defaultValues,
    postWorkOrderGroupId,
    getBuilding,
    getProviderDetail,
    moveMedias,
  } = useWorkOrder();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    register,
    setFocus,
    resetField,
    reset,
    getValues,
    formState: { dirtyFields, errors },
  } = useForm<WorkOrderFormType>({
    defaultValues,
    mode: "onChange",
  });

  const formProps = {
    control,
    setValue,
    watch,
    getValues,
    errors,
    reset,
    resetField,
  };

  useEffect(() => {
    const _merged = [...workOrderMedias, ...workOrderCostMedias, ...workOrderCompletedMedias];
    setValue("mediaList", _merged);
  }, [workOrderCompletedMedias, workOrderCostMedias, workOrderMedias]);

  // 날짜 기간
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  const isPast = useMemo(() => {
    const start = watch("workOrderSheetAccessZoneList.0.startDate") || null;
    const end = watch("workOrderSheetAccessZoneList.0.endDate") || null;

    const workDate = [start, end];

    if (workDate.every((date) => date !== null)) {
      const isPastStart = moment(workDate[0]).isBefore(moment().format(YmdFormat.YYYY_MM_DD));
      const isPastEnd = moment(workDate[1]).isBefore(moment().format(YmdFormat.YYYY_MM_DD));

      if (isPastStart || isPastEnd) return true;
    }
    return false;
  }, [dateRange]);

  const editData = makeRequestEditData(workOrderDetail!, watch());

  const getOfficersAndOfficName = useCallback(
    async (mgmtOfficeId: string) => {
      // 관리처 상세 조회 response
      const mgmtBuildingResponse = await getBuildingManagement({ id: mgmtOfficeId });

      if (mgmtBuildingResponse.status >= 200 && mgmtBuildingResponse.status <= 299) {
        const managementBuilding = mgmtBuildingResponse.data.data.content;

        // 관리처 상세에 있는 buildingId
        const buildingId = managementBuilding.buildingId;

        // 관리처 상세에 있는 정산정보 ID
        const providerId = managementBuilding.providerId;

        // buildingId로 건물 상세 조회
        if (buildingId) {
          const buildingResponse = await getBuilding({ buildingId: Number(buildingId) });
          const building: BuildingModel = buildingResponse.data?.data?.content?.building;
          let providerName = "없음";

          // 정산정보 상세에서 providerName 가져와서 표시 (관리처명)
          if (providerId !== null) {
            const { data } = await getProviderDetail({ providerId: String(providerId) });
            if (data) {
              providerName = String(data.data.provider?.providerName);
            }
          }
          // 건물/관리처 input value setState
          const mgmtBuildingName = `${building.buildingName} / ${providerName}`;
          setValue("buildingManagement", mgmtBuildingName);
        }

        //  작업자 목록
        const filteredOfficer = managementBuilding?.officerList?.filter((officer) => officer.isDeleted !== true);

        if (mgmtOfficeId && filteredOfficer?.length === 0) {
          setAlertModal({ isOpen: true, message: "관리처에 담당자 정보가 없습니다.", type: "resetManagement" });
          return;
        }

        // 담당자 팝업에 보여줄 담당자 목록
        setMgmtOfficerList(filteredOfficer || []);
      }
    },
    [getBuildingManagement],
  );

  const managementResetAndClick = () => {
    if (watch("mgmtOfficeId")) {
      resetField("mgmtOfficeId"); //
      setMgmtOfficerList([]);
      buttonRef?.current?.click();
      return;
    }
  };

  // 워크오더 상세 setValue
  const setWorkOrderData = () => {
    if (workOrderDetail) {
      let workOrderSheetCostList: Array<WorkOrderSheetCostType> = workOrderDetail.workOrderSheetCostList || [];

      if (workOrderSheetCostList && workOrderSheetCostList?.length === 0) {
        workOrderSheetCostList = [{ cost: 0, description: "", cmdType: "C" }];
      } else {
        workOrderSheetCostList = workOrderSheetCostList
          ?.sort((a: WorkOrderSheetCostType, b: WorkOrderSheetCostType) => Number(a.id) - Number(b.id))
          .map((item: WorkOrderSheetCost) => {
            return { ...item, apiId: item.id };
          }) as WorkOrderSheetCostType[];
      }

      // setValue
      for (const [name, value] of Object.entries(workOrderDetail)) {
        setValue(name as keyof WorkOrderFormType, value, {
          shouldDirty: true,
        });
        if (name === "workOrderSheetCostList") {
          if ((value as Array<WorkOrderSheetCostType>) && value.length === 0) {
            setValue("workOrderSheetCostList", [{ cost: 0, description: "", cmdType: "C" }]);
          } else {
            const costList = value
              .sort((a: WorkOrderSheetCostType, b: WorkOrderSheetCostType) => Number(a.id) - Number(b.id))
              .map((item: WorkOrderSheetCost) => {
                return { ...item, apiId: item.id };
              });
            setValue("workOrderSheetCostList", costList);
          }
        }
      }

      // 워크오더 이미지들 setState
      if (workOrderDetail.mediaList && workOrderDetail.mediaList?.length > 0) {
        const workMedias = workOrderDetail.mediaList?.filter((media) => media.mediaServiceType === "MEDIA_WORK_ORDER_SHEET") || []; //업무내용 이미지
        const workCompletedMedias = //처리내용 이미지
          workOrderDetail.mediaList?.filter((media) => media.mediaServiceType === "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT") || [];

        const costMedias = workOrderDetail.mediaList?.filter((media) => media.mediaServiceType === "MEDIA_WORK_ORDER_SHEET_COST") || []; //처리비용 이미지

        setWorkOrderMedias(workMedias);
        setWorkOrderCompletedMedias(workCompletedMedias);
        setWorkOrderCostMedias(costMedias);
      }

      getOfficersAndOfficName(String(workOrderDetail.mgmtOfficeId || ""));
    }
  };
  useEffect(() => {
    setWorkOrderData();
  }, [workOrderDetail]);

  // 워크오더 ID가 있으면(수정) 상세 api 호출
  // 유효성 검사

  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";

    // 건물/관리처
    register("mgmtOfficeId", {
      required: { value: true, message: requiredMessage },
    });
    // 업무일정
    register("startDate", {
      required: { value: true, message: requiredMessage },
    });
    register("endDate", {
      required: { value: true, message: requiredMessage },
    });

    // 담당자
    register("mgmtOfficerId", {
      required: { value: true, message: requiredMessage },
    });

    // 워크시트 제목
    register("summary", {
      required: { value: true, message: requiredMessage },
    });

    // 워크시트 내용
    register("description", {
      required: { value: true, message: requiredMessage },
    });
  }, [register, workOrderId, isPast]);

  // 워크오더 취소 api
  const workOrderCancelApi = useCallback(
    async (id: string, type: "single" | "group") => {
      let response;

      // 그룹삭제
      if (id && type === "group") {
        response = await workOrderCancel({ groupIds: String(id) });
        console.log("delete", response);
      }
      // 단건 삭제
      else {
        response = await workOrderCancel({ ids: String(id) });
      }
      if (response && response.status >= 200 && response.status <= 299) {
        navigate(`${PagePath.workOrder.authenticated.detail.replace(":id", String(workOrderId))}`);
      }
    },
    [workOrderCancel, workOrderId],
  );

  const handlePostWorkOrder = async (workOrderSheetList: WorkOrderFormType[]) => {
    const { data, status } = await postWorkOrder({ workOrderSheetList });
    if (status >= 200 && status <= 299) {
      setConfirmModal({ isOpen: false });
      openToast({ content: `정상적으로 등록 되었습니다.` });
      navigate(`${PagePath.workOrder.authenticated.detail.replace(":id", String(data.data.content[0]?.id))}`);
    }
  };
  //
  // 컨펌모달 저장 클릭시 등록/수정
  const onClickConfirmModal = useCallback(
    async (passData: WorkOrderFormType) => {
      //
      // 그룹 워크오더 일괄 취소
      if (allCancel && watch("workOrderSheetGroupId")) {
        await workOrderCancelApi(String(watch("workOrderSheetGroupId")), "group");
        return;
      }

      // 워크오더 수정
      if (workOrderId) {
        const response = await editWorkOrder({ id: String(workOrderId), workOrderSheet: editData });
        if (response.status >= 200 && response.status <= 299) {
          setConfirmModal({ isOpen: false });
          openToast({ content: `정상적으로 수정되었습니다.` });
          navigate(`${PagePath.workOrder.authenticated.detail.replace(":id", String(response.data.data.content[0]?.id))}`);
        }
        return;
      }
      // 신규생성 && 정기업무 일때
      else if (!workOrderId && scheduleOptions[1].checked) {
        let mediaFiles: MediaFile[] = [];
        const { data: groupIdRes } = await postWorkOrderGroupId({
          startDate: passData.startDate,
          recurrenceEndDate: passData.endDate,
          recurrenceType: groupPayload.recurrenceType,
          isHoliday: groupPayload.isHoliday,
          recurrenceDayOfWeekType: groupPayload.recurrenceDayOfWeekType,
        });

        // const [{ data: groupIdRes }, { data: mediaRes }] = await Promise.all([postWorkOrderGroupId(), moveMedias(passData.mediaList)]);

        if (groupIdRes.data) {
          if (passData.mediaList && passData.mediaList.length > 0) {
            const { data } = await moveMedias(passData.mediaList);
            mediaFiles = data.data.content;
          }

          const groupId = groupIdRes.data.content.id;
          const workOrderSheetList = previewList.map((item) => {
            const workOrderSheetAccessZoneList =
              passData?.workOrderSheetAccessZoneList?.map((zone) => ({
                ...zone,
                startTime: moment(item.startTime).format("HH:mm") || "",
                startDate: String(item.startDate || ""),
                endTime: moment(item.endTime).format("HH:mm") || "",
                endDate: String(item.endDate || ""),
              })) || [];

            return {
              ...passData,
              workOrderSheetAccessZoneList,
              startDate: item.startDate,
              mediaList: mediaFiles,
              endDate: moment(item.endDate).endOf("days").format(YmdFormat.WITH_TIME_ZONE),
              workOrderSheetGroupId: groupId,
            };
          });
          if (!workOrderId) {
            await handlePostWorkOrder(workOrderSheetList);
          }
        }
      } else {
        await handlePostWorkOrder([passData]);
      }
    },

    [editWorkOrder, navigate, postWorkOrder, workOrderId, previewList, groupPayload],
  );

  useEffect(() => {
    formValidation();
  }, [formValidation]);
  // 유효성 검사 후 저장 실행

  // 반복설정 -> 반복주기 확인 api
  const getWorkOrderPreviewApi = async (passParams: WorkOrderPreivew) => {
    // const startDate = passParams.startDate; // 시간일
    const startDate = moment(passParams.startDate).startOf("month").date(Number(passParams.monthlyStart)).format(YmdFormat.WITH_TIME_ZONE);
    const recurrenceEndDate = passParams.recurrenceEndDate; // 반복종료일
    const recurrenceType = passParams.recurrenceType; // 반복종료타입 (주/월/일)
    const isHoliday = passParams.isHoliday;

    if (startDate && recurrenceEndDate && recurrenceType) {
      let previewParam: WorkOrderPreviewRequest = {
        recurrenceType,
        startDate: String(startDate),
        recurrenceEndDate: String(recurrenceEndDate),
        isHoliday,
      };

      if (recurrenceType === "WEEKLY") {
        previewParam = { ...previewParam, recurrenceDayOfWeekTypeList: passParams.recurrenceDayOfWeekTypeList };
      }

      const { data, status } = await getWorkOrderPreview(previewParam);
      if (status >= 200 && status <= 299) {
        const result = data.data.content;
        const newList = result.map((date) => {
          const returnDate: WorkOrderPreivew = {
            startDate: date,
            recurrenceEndDate: passParams.recurrenceEndDate,
            startTime: passParams.startTime,
            endTime: passParams.endTime,
            officerName: passParams.officerName,
            recurrenceType: passParams.recurrenceType,
            endDate: passParams.recurrenceType === "DAILY" ? date : moment(date).add(passParams.deadline, "days").format(YmdFormat.WITH_TIME_ZONE),
          };

          return returnDate;
        });

        setPreviewList(
          newList.filter((item) => {
            const newStartDate = moment(item.startDate).format(YmdFormat.YYYY_MM_DD);
            // const passedStartDate = moment(passParams.startDate).format(YmdFormat.YYYY_MM_DD);
            const today = moment().format(YmdFormat.YYYY_MM_DD);
            return moment(newStartDate).isSameOrAfter(moment(today));
          }),
        );
        // setPreviewList(newList.filter((item) => moment(item.startDate).isSameOrAfter(moment(passParams.startDate))));
      }
    }
  };

  const onSubmit = useCallback(
    async (data: WorkOrderFormType, e?: any) => {
      e.preventDefault();

      // let requestData: Partial<WorkOrderFormType> = {};

      // 업무 이미지 / 처리 이미지 / 증빙 이미지 한 배열로 합치기
      let requestData;
      let message = "";

      // const newMediaList = mergedMedias.map((media) => {
      const newMediaList = data.mediaList?.map((media) => {
        if (!media.id) {
          return {
            ...media,
            cmdType: "C",
          };
        } else {
        }
        return media;
      });

      requestData = { ...data, mediaList: newMediaList };

      if (workOrderId) {
        requestData = editData;
      } else {
        // 넘어온 객체 복사
        requestData = {
          ...requestData,
          workOrderSheetAccessZoneList: data.workOrderSheetAccessZoneList?.map((item) => {
            return {
              ...item, //
              startTime: moment(item.startTime).format("HH:mm"),
              endTime: moment(item.endTime).format("HH:mm"),
            };
          }),
        };
      }
      // 정기업무 등록시
      message = !scheduleOptions[0].checked && previewList.length > 0 ? `${previewList.length}개의 업무가 생성됩니다.` : "";

      // 반복설정한 워크시트가 0개 일때는 생성 X -> 경고팝업
      if (!scheduleOptions[0].checked && previewList.length === 0) {
        setAlertModal({ isOpen: true, type: "recurrence" });
        return;
      }
      if (!allCancel && Object.keys(requestData).length === 0) {
        setAlertModal({ isOpen: true, message: "변경사항 없이\n 저장할 수 없습니다." });

        return;
      }
      setConfirmModal({
        isOpen: true,
        message: message,
        payload: requestData,
      });
    },
    [workOrderId, scheduleOptions, previewList.length, allCancel, editData],
  );
  // validation 통과하지 못하고 error 발생시 실행
  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);

    return false;
  }, []);
  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap ">
            <WorkInfoSection
              formProps={formProps}
              getOfficers={getOfficersAndOfficName}
              setButtonRef={setButtonRef}
              mgmtOfficerList={mgmtOfficerList}
              workOrderMedias={workOrderMedias || []}
              setWorkOrderMedias={setWorkOrderMedias}
              workOrderDetail={workOrderDetail}
            />
            <WorkOrderScheduleSection //
              formProps={formProps}
              isPast={isPast}
              mgmtOfficerList={mgmtOfficerList}
              scheduleOptions={scheduleOptions}
              allCancel={allCancel}
              previews={previewList}
              setPreviews={setPreviewList}
              setAllCancel={setAllCancel}
              setDateRange={setDateRange}
              setScheduleOptions={setScheduleOptions}
              getWorkOrderPreview={getWorkOrderPreviewApi}
              setGroupPayload={setGroupPayload}
            />

            {/* 수정 시 노출되는 영역들 - 처리내용 / 처리 비용 / 고객답변 / 각 이미지 */}
            {workOrderId && (
              <WorkOrderEditSection
                formProps={formProps}
                workOrderCompletedMedias={workOrderCompletedMedias}
                setWorkOrderCompletedMedias={setWorkOrderCompletedMedias}
                workOrderCostMedias={workOrderCostMedias}
                setWorkOrderCostMedias={setWorkOrderCostMedias}
                workOrderDetail={watch()}
              />
            )}
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            {workOrderId && (
              <>
                {Object.keys(editData).length !== 0 && (
                  <div className="mr20">
                    <p className="required font14 text-red">수정된 사항이 있으니 다시 한번 확인해주세요.</p>
                  </div>
                )}
                <BaseButton
                  type="button"
                  title="수정취소"
                  className="size-large color-white mr5"
                  onClick={() => navigate(PagePath.workOrder.authenticated.detail.replace(":id", String(workOrderId)))}
                />
              </>
            )}

            {workOrderDetail?.status === "WORK_ORDER_CANCELED" ? (
              <p className="text-red900 font16">취소된 워크오더 입니다.</p>
            ) : workOrderDetail?.status === "WORK_ORDER_CLOSED" ? (
              <p className="text-red900 font16">종료된 워크오더 입니다.</p>
            ) : (
              <BaseButton type="submit" title="저장" className="size-large" />
            )}
          </div>
        </div>
      </form>
      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              onClickConfirmModal(confirmModal.payload as WorkOrderFormType);
              setConfirmModal({ isOpen: false });
            }}
          >
            <div className="font18 text-gray900">
              {workOrderId ? <p>저장하시겠습니까?</p> : <p>워크오더를 생성하시겠습니까?</p>}

              {confirmModal.message && <p className="mt20 font14 text-primary3">{confirmModal.message}</p>}
            </div>
          </BaseModal>
        )}

        {alertModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            onClick={() => {
              if (alertModal.type === "resetManagement") {
                managementResetAndClick();
              }
              setAlertModal({ isOpen: false });
            }}
          >
            {alertModal.message && <div className="font18 mt15 text-gray900 pre-formatted keep-all"> {alertModal.message}</div>}
            {alertModal.type === "recurrence" && (
              <div>
                <p className="font18  text-gray900">
                  <b>스케줄 미리보기를 먼저 해주세요</b>
                </p>
                {/* <p className="font14 mt20">반복 일정을 다시 설정해 주세요.</p> */}
                <p className="font14 mt20">
                  설정한 스케줄을 먼저 확인하신 후에 <br /> 업무를 생성할 수 있습니다.
                </p>
              </div>
            )}
          </BaseModal>
        )}
      </>
    </section>
  );
};

export default CtrlWorkOrderForm;
