import { useState } from "react";
import { ProductModel } from "src/api/product/product-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import useUpdateProduct from "src/hooks/product/useUpdateProduct";
import { SpaceType, SpaceTypeT } from "src/pages/product/product-types";
import { numberToStringWithComma } from "src/utils";
type Props = {
  product: ProductModel;
  spaceType: SpaceTypeT;
  isEditable?: boolean;
};
const ParkingSpace = ({ product, spaceType, isEditable }: Props) => {
  const { mutate } = useUpdateProduct();
  const [isParkingSetting, setIsParkingSetting] = useState<Modal>({ isOpen: false });

  const [descriptionFormData, setDescriptionFormData] = useState({
    isFree: product.parkingLotFreeDescription ? true : false,
    isPay: product.parkingLotPayDescription ? true : false,
    parkingLotFreeDescription: product.parkingLotFreeDescription,
    parkingLotPayDescription: product.parkingLotPayDescription,
  });

  const handleSubmit = async () => {
    mutate(
      {
        id: product.id,
        parkingLotFreeDescription: descriptionFormData.parkingLotFreeDescription,
        parkingLotPayDescription: descriptionFormData.parkingLotPayDescription,
      },
      { onSuccess: () => setIsParkingSetting({ isOpen: false }) },
    );
  };

  // 공간별 사용중 테이블 생성
  const parkingLotList = product.buildingList
    ?.map((data) => {
      if (spaceType === SpaceType.MEETING_ROOM) {
        return data.buildingCommonFacility?.parkingLotList?.map((space) => {
          return { ...space, buildingName: data.buildingName };
        });
      }
    })
    .flat();

  return (
    <>
      <div className="parking_space mt20 ">
        <div className="flex-center-start">
          <div className="w145 flex-center">
            <span className="font15 font-weight-semibold">기본정보</span>
            <BaseTooltip touchIcon={"QUESTION"} className="ml2" size={16} tooltip="건물>공용공간 탭에서 수정할 수 있습니다" />
          </div>
          <div className="build_contents">
            {parkingLotList?.map((parkingLot, index) => (
              <div key={index} className="gray-board">
                <div className="building_name">{parkingLot?.buildingName}</div>
                <div className="building_text">
                  {`자주식${parkingLot?.selfPropelledParkingLotNums || 0} (차량 ${numberToStringWithComma(
                    parkingLot?.selfPropelledParkingLotTotalNums || 0,
                  )}대), 기계식${parkingLot?.mechanicalParkingLotNums || 0} (차량 ${numberToStringWithComma(
                    parkingLot?.mechanicalParkingLotTotalNums || 0,
                  )}대)`}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex-center-start w-100">
          <p className="font15 font-weight-semibold w145">상세 설정</p>
          <div className="flex-center-between setting-warper">
            <div className="setting">
              <p className="required">무료 : {product.parkingLotFreeDescription ?? "-"}</p>
              <p className="required">유료 : {product.parkingLotPayDescription ?? "-"}</p>
            </div>
            <div>
              {isEditable && (
                <button onClick={() => setIsParkingSetting({ isOpen: true })} className="base-btn color-white">
                  수정
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 주차 상세 설정 모달 */}
      {isParkingSetting.isOpen && (
        <BaseAbstractModal isOpen={isParkingSetting.isOpen} size="medium">
          <div className="parking_setting_modal">
            <div className="group">
              <div className="medium">
                <div className="popup-title">
                  <span>주차장 상세 설정</span>
                </div>
                <div className="contents-wrapper">
                  <p className="hd9 py20 px30">무료</p>
                  <div className="input-warp">
                    <BaseCheckbox
                      id={"free"}
                      name={"free"}
                      checked={descriptionFormData.isFree}
                      onChange={(checked) =>
                        setDescriptionFormData({ ...descriptionFormData, isFree: checked, ...(!checked && { parkingLotFreeDescription: "" }) })
                      }
                      label="설정함"
                    ></BaseCheckbox>
                    <BaseInput
                      className="w-100"
                      value={descriptionFormData.parkingLotFreeDescription}
                      onChange={(value: string) => setDescriptionFormData({ ...descriptionFormData, parkingLotFreeDescription: value })}
                      placeholder={descriptionFormData.isFree ? "내용을 입력해 주세요." : ""}
                      disabled={!descriptionFormData.isFree}
                    ></BaseInput>
                  </div>
                </div>
                <div className="contents-wrapper">
                  <p className="hd9 py20 px30">유료</p>
                  <div className="input-warp">
                    <BaseCheckbox
                      id={"pay"}
                      name={"pay"}
                      label="설정함"
                      checked={descriptionFormData.isPay}
                      onChange={(checked) =>
                        setDescriptionFormData({ ...descriptionFormData, isPay: checked, ...(!checked && { parkingLotPayDescription: "" }) })
                      }
                    ></BaseCheckbox>
                    <BaseInput
                      className="w-100"
                      value={descriptionFormData.parkingLotPayDescription}
                      onChange={(value: string) => setDescriptionFormData({ ...descriptionFormData, parkingLotPayDescription: value })}
                      placeholder={descriptionFormData.isPay ? "내용을 입력해 주세요." : ""}
                      disabled={!descriptionFormData.isPay}
                    ></BaseInput>
                  </div>
                </div>
                <div className="medium-button-wrapper">
                  <div className="medium-button">
                    <BaseButton className="color-white" title={"취소"} onClick={() => setIsParkingSetting({ isOpen: false })}></BaseButton>
                    <BaseButton title={"적용"} onClick={() => handleSubmit()}></BaseButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ;
        </BaseAbstractModal>
      )}
    </>
  );
};

export default ParkingSpace;
