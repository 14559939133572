import { useAuth0 } from "@auth0/auth0-react";
import { lowerCase } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PartnerFeature, UnionPartnerFeature } from "src/api/public-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useValidRoutes } from "src/hooks/useValidRoutes";
import pagePath from "src/pagePath.json";
import { partnerMemberState } from "src/recoil/partnerMember/atom";
import { globalPartnerState } from "src/recoil/partners/atom";
import { PartnerPermission, PartnerPrivilegeCode, PrivilegeCodeMenus, UnionPartnerPrivilegeCode } from "src/types/partner";
import { ViewYmdFormat } from "src/utils";

export type Menu = {
  title: string;
  icon?: string;
  url?: string;
  openMenu?: boolean;
  twoDepth?: Menu[];
  divider?: boolean;
  active?: boolean;
  feature?: UnionPartnerFeature;
  privilege?: UnionPartnerPrivilegeCode;
};

export const SideBar = () => {
  const { isAuthenticated } = useAuth0();
  const partner = useRecoilValue(globalPartnerState);
  const partnerMember = useRecoilValue(partnerMemberState);
  const location = useLocation();
  const { partnerPrivileges, fetchAuthorityReadonly } = usePartnerAuthority();

  const navigate = useNavigate();

  const [sideBarMenuList] = useState<Menu[]>([
    {
      title: "대시보드",
      icon: "main",
      url: pagePath.main,
      privilege: PartnerPrivilegeCode.BASIC,
    },
    {
      title: "공간 모니터링",
      icon: "monitoring",
      url: pagePath.monitoring,
      privilege: PartnerPrivilegeCode.BASIC,
    },
    {
      title: "건물",
      icon: "building",
      url: pagePath.building.list,
      privilege: PartnerPrivilegeCode.BUILDING,
    },
    {
      title: "상품",
      icon: "product",
      url: pagePath.product.list,
      privilege: PartnerPrivilegeCode.PRODUCT,
    },
    {
      title: "워크오더",
      icon: "workorder",
      url: pagePath.workOrder.authenticated.list,
      feature: PartnerFeature.WORKORDER,
      privilege: PartnerPrivilegeCode.WORKORDER,
    },
    {
      title: "CS",
      icon: "question",
      url: pagePath.question.list,
      feature: PartnerFeature.CS,
      privilege: PartnerPrivilegeCode.CS,
      divider: true,
    },
    {
      title: "신청/계약",
      icon: "contract",
      url: pagePath.contract.list,
      privilege: PartnerPrivilegeCode.CONTRACT,
    },
    {
      title: "정산정보",
      icon: "provider",
      url: pagePath.provider.list,
      privilege: PartnerPrivilegeCode.BASIC,
    },
    {
      title: "공용공간 예약",
      icon: "reservation",
      url: pagePath.reservation.list,
      feature: PartnerFeature.RESERVATION,
      privilege: PartnerPrivilegeCode.RESERVATION,
    },
    {
      title: "이용자 초대",
      icon: "user",
      url: pagePath.user.list,
      privilege: PartnerPrivilegeCode.CONTRACT,
    },
    {
      title: "방문자 초대",
      icon: "visitor",
      url: pagePath.visitor.list,
      privilege: PartnerPrivilegeCode.VISITOR,
      divider: true,
    },
    {
      title: "공지사항",
      icon: "notice",
      url: pagePath.notice.list,
      privilege: PartnerPrivilegeCode.NOTICE,
      divider: true,
    },
    {
      title: "사업자",
      icon: "org",
      url: pagePath.organization.list,
      privilege: PartnerPrivilegeCode.BASIC,
    },
    {
      title: "빌링 스케줄",
      icon: "billing",
      url: pagePath.billing.list,
      privilege: PartnerPrivilegeCode.CONTRACT,
      divider: true,
    },
    //v.1.19 작업 > Harry 수정
    // {
    //   title: "관리비-부과항목관리",
    //   icon: "cost",
    //   url: pagePath.chargeItems.list,
    //   feature: PartnerFeature.MANAGEMENT_FEE,
    // },
    // {
    //   title: "관리비-부과관리",
    //   icon: "cost",
    //   url: pagePath.chargeMnt.list,
    //   feature: PartnerFeature.MANAGEMENT_FEE,
    //   divider: true,
    // },
    {
      title: "설정",
      icon: "setting",
      twoDepth: [
        {
          title: "이용안내",
          // icon: "voc",
          url: pagePath.guide.list,
          // feature: PartnerFeature.CS,
          privilege: PartnerPrivilegeCode.BASIC,
        },
        {
          title: "VoC 항목",
          icon: "voc",
          url: pagePath.voc.list,
          feature: PartnerFeature.CS,
          privilege: PartnerPrivilegeCode.BASIC,
        },
        {
          title: "공용공간 요금 정책",
          icon: "price",
          url: pagePath.price.list,
          feature: PartnerFeature.RESERVATION,
          privilege: PartnerPrivilegeCode.BASIC,
        },
      ],
    },
  ]);

  // 권한없는 페이지로 url 접근시 block
  useEffect(() => {
    // 현재 page의 path(url)
    const currentPagePath = location.pathname + location.search;
    const currentMenu = location.pathname.split("/")[5];
    //현재 페이지의 권한 코드 > "BASIC" | "BUILDING" | "PRODUCT" |"CS" |"CONTRACT" 등 return
    let currentPagePrivilegeCode = "";

    for (let key in PrivilegeCodeMenus) {
      const menus = PrivilegeCodeMenus[key as UnionPartnerPrivilegeCode];
      if (menus.includes(currentMenu)) {
        currentPagePrivilegeCode = key;
        break;
      }
    }
    // 파트너의 권한 확인 > {code: "BASIC" ,permissions:['d', 'w', 'r']} return
    const partnerAuthority = partnerPrivileges.find((privilege) => privilege.code === currentPagePrivilegeCode);

    let isBlocked = false;

    // read 가능여부
    const readalbe = partnerAuthority && !partnerAuthority.permissions.includes("r");

    // add / edit 가능여부
    const editable = currentPagePath.includes("form") && partnerAuthority && !partnerAuthority.permissions.includes("w");
    if (readalbe) {
      isBlocked = true;
    }
    if (editable) {
      isBlocked = true;
    }
    isBlocked && fetchAuthorityReadonly(true, true);
  }, [partnerPrivileges, location]);

  // v1.11에서 제거 하드코딩
  const updatedSideBarMenuList = sideBarMenuList.map((item) => {
    if (item.title === "설정" && Array.isArray(item.twoDepth)) {
      // 내부 배열을 필터링하여 해당 항목 제거
      const updatedTwoDepth = item.twoDepth.filter((subItem) => {
        if (process.env.REACT_APP_ENVIRONMENT === "prod" && subItem.title === "공용공간 휴무일시") {
          return false;
        } else {
          return true;
        }
      });
      return {
        ...item,
        twoDepth: updatedTwoDepth,
      };
    }
    return item;
  });

  // 파트너 접근허용 메뉴
  const { validRoutes } = useValidRoutes(updatedSideBarMenuList);

  // 유효한 LNB 노출되는 목록 return
  const validSideBarMenuList = useMemo(() => {
    const privilegeMenuList = validRoutes?.filter((item) => {
      const partnerPrivilage = partnerPrivileges.find((privilege) => privilege.code === item.privilege);

      return partnerPrivilage?.permissions.includes(PartnerPermission.READ);
    });

    //menu 에 privileges 데이터가 없는 목록 > 관리비 메뉴 / 설정 메뉴
    const noPrivileges = validRoutes
      .filter((route: Menu) => !route.privilege)
      // 공용공간 휴무일시 메뉴 노출을 위한 map 작업
      .map((item: Menu) => {
        if (item.twoDepth && item.twoDepth.length > 0) {
          item = {
            ...item,
            twoDepth: item.twoDepth.filter((subMenu) => {
              const subMenuHasPrivilege = partnerPrivileges.some(
                (privilege) => privilege.code === subMenu.privilege && privilege.permissions.includes(PartnerPermission.READ),
              );
              return subMenuHasPrivilege || !subMenu.privilege;
            }),
          };
        }
        return item;
      });

    return [...privilegeMenuList, ...noPrivileges];
  }, [partnerPrivileges, validRoutes]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="sidebar">
      <nav>
        <div className="sidebar__logo" onClick={() => navigate(pagePath.main)}></div>
        <ul>
          {validSideBarMenuList &&
            validSideBarMenuList.length > 0 &&
            validSideBarMenuList?.map((menu, idx) => {
              if (menu !== undefined) {
                return (
                  <li key={idx} className={menu.icon}>
                    <>
                      {menu.twoDepth ? (
                        <>
                          <p>
                            <span className="icon"></span>
                            <span>{menu.title}</span>
                          </p>
                          <div className={"two-depth-menu"}>
                            {menu.twoDepth.map((twoDepthMenu: Menu, index: number) => {
                              return (
                                <NavLink
                                  to={twoDepthMenu.url ? twoDepthMenu.url.replace(":partnerId", String(partner?.id)) : "/" + twoDepthMenu.title}
                                  key={index + twoDepthMenu.title}
                                >
                                  <span>- {twoDepthMenu.title}</span>
                                </NavLink>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          <NavLink to={menu.url ? menu.url.replace(":partnerId", String(partner?.id)) : "/" + menu.title} key={idx}>
                            <span className="icon"></span>
                            <span>{menu.title}</span>
                          </NavLink>
                          {menu.divider && <div className="sidebar__divider"></div>}
                        </>
                      )}
                    </>
                  </li>
                );
              }
            })}
        </ul>
        {process.env.REACT_APP_ENVIRONMENT !== "prod" && (
          <div className="sidebar__manual">
            <button>이용 메뉴얼 보기</button>
          </div>
        )}
      </nav>
      <div className="sidebar__footer">
        <p className="mb4">{partner?.name}</p>
        {partnerMember?.lastLoginDate ? (
          <span>마지막 로그인 : {moment(partnerMember?.lastLoginDate).format(ViewYmdFormat.YYYY_MM_DD_HH_MM)}</span>
        ) : (
          <span>마지막 로그인 : 없음</span>
        )}
      </div>
    </div>
  );
};
