import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityListModel, CommonFacilityListParams } from "src/api/building/building-types";
import useApiOperation from "src/api/hooks/api-operation";
import { BaseInputWithSearch, BaseRadio, BaseSelect } from "src/components";
import DetailLink from "src/components/DetailLink";
import TableSelectModal from "src/components/TableSelectModal";
import PagePath from "src/pagePath.json";
import { changeFacilityTypeToText } from "src/pages/contract/contract-util";
import { CommonFacilityType } from "../price-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";

// feature 1 :: 선택 저장 후 부모에 보낼 타입지정
// feature 2 :: TABLE_COLUMNS변경. TABLE_COLUMNS 내 id 명칭 체크 (선택에 영향있음 ex) providerId )
// feature 3 :: checked 영역 확인 , keyword 명칭, 제목 확인
// feature 4 :: fetchApi 변경

type SelectedType = {
  buildingCommonFacilityId: string;
  facilityName: string;
  buildingName: string;
  buildingId: string;
};

interface Props {
  onCanceled: () => void;
  onAdded: (selected: SelectedType[]) => void;
  defaultChecked: SelectedType[];
}

interface CellProps extends Cell<CommonFacilityListModel> {
  checked: boolean;
}

const commonFacilityTypeList = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "회의실",
    value: CommonFacilityType.MEETING_ROOM,
  },
  {
    label: "좌석",
    value: CommonFacilityType.DESK,
  },
  {
    label: "편의시설",
    value: CommonFacilityType.REFRESH_ROOM,
  },
];

const searchType = [
  {
    label: "전체",
    value: "ALL",
  },
  {
    label: "id",
    value: "ID",
  },
  {
    label: "공용공간명",
    value: "FACILITY_NAME",
  },
  {
    label: "건물명",
    value: "BUILDING_NAME",
  },
];

const TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const id = props.row.original.id;
      let disabled = false;
      return (
        <div data-data-id={id} data-checked={props.value} className="checkbox" data-disabled={disabled}>
          <BaseRadio id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: CellProps) => {
      const buildingId = props.row.original.buildingId;
      const detailPath = `${PagePath.building.detail.replace(":id", `${buildingId}`)}?tab=facility`;
      return <BaseNewTabLink path={detailPath} value={props.value || "-"} ellipsis />;
    },
  },
  {
    Header: "그룹명",
    accessor: "buildingCommonFacilityDeskGroupName",
    width: 100,
    Cell: (props: CellProps) => {
      return (
        <div className="w-100 text-left">
          <p>{props.value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "공간타입",
    accessor: "commonFacilityType",
    width: 210,
    Cell: (props: CellProps) => <p>{changeFacilityTypeToText(props.value)}</p>,
  },
  {
    Header: "공용공간 명",
    accessor: "facilityName",
    width: 120,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value || "-"}</p>
      </div>
    ),
  },
  {
    Header: "위치",
    accessor: "floorNum",
    width: 120,
    Cell: (props: CellProps) => {
      const isGround = props.row.original.isGround;
      return (
        <p>
          {" "}
          {isGround ? "지상" : "지하"} {props.value}층
        </p>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 210,
    Cell: (props: CellProps) => (
      <div className="w-100 text-left ellipsis2">
        <p>{props.value || "-"}</p>
      </div>
    ),
  },
];

function FacilitySelectModal({ onCanceled, onAdded, defaultChecked }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [tableDataList, setTableDataList] = useState<any[]>([]);
  const [selectedList, setSelectedList] = useState<SelectedType[]>(defaultChecked);
  const [size, setSize] = useState(20);

  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync);

  const { handleSubmit, getValues, control, setValue, watch } = useForm<{
    keywordList: string;
    searchType: string;
    commonFacilityTypeList: string;
  }>();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const listTableData = tableDataList.map((item) => {
    const selectedIdList = selectedList?.map((item) => item.buildingCommonFacilityId);

    return {
      checked: selectedIdList.includes(item.id),
      ...item,
    };
  });

  const search = ({
    keywordList,
    page,
    size,
    commonFacilityTypeList,
    searchType,
  }: {
    page: number;
    size: number;
    keywordList?: string;
    commonFacilityTypeList?: string;
    searchType?: string;
  }) => {
    setSize(size);
    fetchApi({ page, size, keywordList, searchType, commonFacilityTypeList });
  };

  const onSubmit = ({
    keywordList,
    commonFacilityTypeList,
    searchType,
  }: {
    keywordList: string;
    commonFacilityTypeList: string;
    searchType: string;
  }) => search({ page: 0, size, searchType, keywordList, commonFacilityTypeList });
  const goPage = (nextPage: number) =>
    search({ page: nextPage, size, keywordList: getValues("keywordList"), commonFacilityTypeList: getValues("commonFacilityTypeList") });

  const fetchApi = async ({ page, keywordList, size, commonFacilityTypeList, searchType }: CommonFacilityListParams) => {
    const result = await getCommonFacilityList({
      page,
      keywordList,
      size,
      commonFacilityTypeList,
      searchType,
      isDeleted: false,
      buildingIsDeleted: false,
    });
    setTableDataList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const select = (id: string) => {
    const selected = tableDataList.find((item) => String(item.id) === id);

    setSelectedList([
      // ...selectedList,
      // 멀티셀렉일때 주석해제
      {
        buildingCommonFacilityId: selected?.id || "",
        buildingId: selected?.buildingId || "",
        buildingName: selected?.buildingName || "",
        facilityName: selected?.facilityName || "",
      },
    ]);
  };
  const unSelect = (id: string) => {
    const filteredList = selectedList.filter((object) => String(object.buildingCommonFacilityId) !== id);
    setSelectedList(filteredList);
  };
  const _onAdded = () => onAdded(selectedList);

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchApi({
      page: 0,
      size,
    });
  }, []);

  return (
    <TableSelectModal<any>
      className=""
      onCanceled={onCanceled}
      onAdded={_onAdded}
      goPage={goPage}
      page={page}
      columns={TABLE_COLUMNS}
      items={listTableData}
      title={"공용공간"}
      select={select}
      unSelect={unSelect}
      sizeOption={(size) => {
        setSize(size);
        search({ page: 0, size, keywordList: getValues("keywordList"), commonFacilityTypeList: getValues("commonFacilityTypeList") });
      }}
      currentSize={size}
      titleFormRender={() => {
        return (
          <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef} className="flex-center-center">
            <div className="flex-center">
              <div className="minmax160 mr10">
                <Controller
                  name="commonFacilityTypeList"
                  control={control}
                  render={({ field }) => (
                    <BaseSelect
                      className=""
                      placeholder="공간타입"
                      value={field.value}
                      stateOptions={commonFacilityTypeList}
                      setStateValue={(value: string) => {
                        search({ page: 0, size, commonFacilityTypeList: value, keywordList: getValues("keywordList") });
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="">
                <Controller
                  name="keywordList"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BaseInputWithSearch
                      type="text"
                      selectValue={watch("searchType")}
                      inputValue={value}
                      stateOptions={searchType}
                      setStateValue={(searchType: string) => {
                        setValue("searchType", searchType);
                      }}
                      onClearClick={() => {
                        setValue("keywordList", "");
                        search({
                          page: 0,
                          size,
                          searchType: getValues("searchType"),
                          commonFacilityTypeList: getValues("commonFacilityTypeList"),
                          keywordList: "",
                        });
                      }}
                      onChange={onChange}
                      onKeyUp={() =>
                        search({
                          page: 0,
                          size,
                          searchType: getValues("searchType"),
                          commonFacilityTypeList: getValues("commonFacilityTypeList"),
                          keywordList: value,
                        })
                      }
                      onSearchClick={() =>
                        search({
                          page: 0,
                          size,
                          searchType: getValues("searchType"),
                          commonFacilityTypeList: getValues("commonFacilityTypeList"),
                          keywordList: value,
                        })
                      }
                    />
                  )}
                />
              </div>
            </div>
          </form>
        );
      }}
    />
  );
}
export default FacilitySelectModal;
