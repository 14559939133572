import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BuildingFloorModel, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getBuildingDeviceToRegister, postBuildingIotDeviceAsync } from "src/api/iot/iot-api";
import {
  BuildingDeviceAddModel,
  BuildingLocationCategory,
  IotDeviceQueryParams,
  IoTDeviceRegistration,
  RegisteredDeviceQueryParams,
  UnionBuildingLocation,
} from "src/api/iot/iot-types";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseConfirmModal, BaseInput, BaseRadio } from "src/components";
import Link from "src/components/PartnerLink";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import BuildingFacilityColumns from "../columns/BuildingFacilityColumns";
import BuildingFloorHoColumns from "../columns/BuildingFloorHoColumns";
import FloorColumns from "../columns/FloorColumns";
import RegisteredDeviceColumns from "../columns/RegisteredDeviceColumns";
import IotCommonTableModal from "./IotCommonTableModal";
import { useLocation } from "react-router-dom";

type Props = {
  building?: BuildingModel;
  buildingId: string;
  addedDevice: any[];
  cancelModal: () => void;
  getDevices: (passParams: IotDeviceQueryParams) => Promise<void>;
};

const queryData: RegisteredDeviceQueryParams = { page: 0, size: 20, sort: { orders: [{ direction: "ASC", property: "internalDisplayName" }] } };

const BuildingDeviceForm = ({ building, buildingId, addedDevice, cancelModal, getDevices }: Props) => {
  const location = useLocation();

  const [isSearched, setIsSearched] = useState(false);
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  const [openModal, setOpenModal] = useState<Modal>({ isOpen: false });

  // 추가유형 선택 값
  const [selectedLocaion, setSelectedLocaion] = useState<UnionBuildingLocation>("FLOOR");

  // IoT 기기 목록
  const [device, setDevice] = useState<IoTDeviceRegistration[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [keyword, setKeyword] = useState("");

  const [params, setParams] = useState<RegisteredDeviceQueryParams>(queryData);

  // 이미 등록된 데이터들
  const [isRegistered, setIsRegistered] = useState(false);
  // IoT 기기 목록 (모달 리스트)
  const { executeAsync: getIotDeviceToRegister } = useApiOperation(getBuildingDeviceToRegister);

  const { executeAsync: postIotDevice } = useApiOperation(postBuildingIotDeviceAsync, { noHandleError: true });

  const { openToast } = useToast();

  const { openErrorModal } = useErrorModal();
  //
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm<
    BuildingDeviceAddModel & {
      // displayName: string;
      locationName: string;
      registerDevices: Array<{ displayName: string; registeredDeviceId: string }>;
    }
  >({
    defaultValues: {
      locationName: "",
      registerDevices: [],
      // displayName: "",
      registeredDeviceId: "",
      building: {
        id: String(buildingId),
        location: {
          category: "FLOOR", //건물의 카테고리 위치 정보 - 층/호/공용공간
          identifier: "", //건물 위치 아이디
        },
      },
    },
  });

  // IoT 위치 목록
  const iotLocation = useMemo(() => {
    let locationList: any = [];
    switch (selectedLocaion) {
      case "FLOOR": {
        //층
        locationList = building?.buildingFloorList?.sort((a: BuildingFloorModel, b: BuildingFloorModel) => Number(b.id) - Number(a.id));
        break;
      }
      case "ROOM": {
        //층/호
        const floorList = building?.buildingFloorList
          ?.sort((a: BuildingFloorModel, b: BuildingFloorModel) => Number(b.id) - Number(a.id))
          ?.filter((floor) => floor.buildingRoomList && floor.buildingRoomList?.length > 0); // 층에 호실이 있는 경우만 리턴

        const roomList = floorList?.flatMap((floor: BuildingFloorModel) =>
          floor?.buildingRoomList?.map((room: any) => ({ floorNum: floor.floorNum, floorName: floor.floorName, ...room })),
        );

        locationList = roomList;
        break;
      }
      case "FACILITY": {
        //공용공간
        const meetingRooms = building?.buildingCommonFacility?.meetingRoomList || [];
        const refreshRooms = building?.buildingCommonFacility?.refreshRoomList || [];
        const deskList =
          building?.buildingCommonFacility?.deskSpace?.deskGroupList
            ?.filter((group) => group.deskList && group.deskList.length > 0)
            ?.flatMap((group) => group.deskList?.map((desk) => ({ ...desk, groupName: group.groupName }))) || [];
        const merged = [...meetingRooms, ...refreshRooms, ...deskList];
        locationList = merged.sort((a, b) => {
          if (a?.commonFacilityType && b?.commonFacilityType && a.commonFacilityType!.toUpperCase() < b.commonFacilityType.toUpperCase()) {
            return 1;
          }
          if (a?.commonFacilityType && b?.commonFacilityType && a.commonFacilityType!.toUpperCase() > b.commonFacilityType.toUpperCase()) {
            return -1;
          }
          return 0;
        });
        break;
      }
    }
    return locationList;
  }, [building, selectedLocaion]);

  //
  // 필수 validation
  useEffect(() => {
    const requiredMessage = "필수입력 항목입니다.";
    register("registerDevices", {
      validate: {
        requried: (devices) => {
          const hasDevices = devices.length < 1 ? false : true;
          return hasDevices || requiredMessage;
        },
      },
    });

    register("building.location.identifier", { required: { value: true, message: requiredMessage } });
  }, [register]);

  // IoT 기기 필드 목록 불러오기
  const fetchIotDevice = async (passParams: RegisteredDeviceQueryParams) => {
    const { data, status } = await getIotDeviceToRegister(passParams);
    if (status >= 200 && status <= 299) {
      setPageMeta(data.meta.pageMeta);
      setDevice(data.data.content);
      // iot 기기 모달 > 기기 chekced > 검색으로 페이지 재호출시 기존 체크박스 해제
      setIsSearched(false);
    }
  };

  useEffect(() => {
    if (openModal.isOpen && openModal.type === "iot") {
      fetchIotDevice(params);
    }
  }, [openModal, params]);

  // 저장 api 호출
  const onAddIotDevice = async (formData: BuildingDeviceAddModel) => {
    // iot 기기 팝업에서 선택한 목록
    const selectedDevices = watch("registerDevices");

    //선택한 디바이스를 추가하는 로직 구현 > promise
    const promiseExecute = selectedDevices.map(async (item: { displayName: string; registeredDeviceId: string }, idx: number) => {
      const request = {
        building: formData.building,
        registeredDeviceId: item.registeredDeviceId,
      };
      const { data, status } = await postIotDevice(request);

      return { response: data, status, internalDisplayName: item.displayName };
    });

    const response = await Promise.all(promiseExecute);

    if (response.find((item) => item.status > 399)) {
      await handleError(response);
    } else {
      await getDevices({ buildingId, sort: { orders: [{ property: "internalDisplayName", direction: "ASC" }] } });
      openToast({ content: "정상적으로 저장되었습니다." });
    }
    cancelModal();
  };

  //  관리자 메모 등록/수정
  const onSubmit = async (formData: BuildingDeviceAddModel) => {
    setConfirmModal({
      isOpen: true,
      title: `${isRegistered ? "선택하신 기기는\n 이미 다른 건물에 등록되어 있습니다. \n\n" : ""} 저장하시겠습니까?`,
      payload: formData,
    });
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 기기추가 에러시 팝업 노출
  const handleError = async (result: any) => {
    const allFail = result.every((item: any) => item.status > 399); // 전부 실패

    const partialSuccess = result.some((item: any) => item.status >= 200 && item.status <= 299); // 부분 성공

    const successedDisplay = result.filter((item: any) => item.status < 300).map((item: any) => item.internalDisplayName); // 추가 성공 목록

    const duplicatedDisplay = result.filter((item: any) => item.status >= 400).map((item: any) => item.internalDisplayName); // 추가했던 기기명

    const errorCode = result.filter((item: any) => item.status > 399).map((item: any) => item.response.meta.errorCode);

    const notDuplicatesErrorCode = [...new Set(errorCode)]; // 에러코드 배열 중복제거

    const errorMessage = `${partialSuccess ? "일부 기기 추가를 실패하였습니다." : allFail ? "기기 추가를 실패하였습니다." : ""}  `;

    openErrorModal({
      errorMessage: errorMessage ? errorMessage : String(notDuplicatesErrorCode.join(",")).replace(",", "\n"),
      statusCode: result.find((item: any) => item.status > 299).status,
      errorList: [`성공: ${successedDisplay.length}건`, `실패: ${duplicatedDisplay.length}건`],
      errorSubList: duplicatedDisplay,
    });

    await getDevices({ buildingId, sort: { orders: [{ property: "internalDisplayName", direction: "ASC" }] } });
  };

  return (
    <div className="base-abstract-modal__contents">
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>기기추가</h1>
      </section>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__wrap ">
          <div className="contents-container__wrap-article">
            <div className="contents-container__1200">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">추가 유형</p>
                </div>
                <div className="contents-container__grid-contents flex-center">
                  {BuildingLocationCategory.map((category) => (
                    <div key={category.value} className="mr30">
                      <Controller
                        control={control}
                        name={"building.location.category"}
                        render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                          <BaseRadio
                            id={category.value}
                            name={name}
                            label={category.label}
                            onChange={() => {
                              onChange(category.value);
                              setSelectedLocaion(category.value as UnionBuildingLocation);
                              // reset({
                              //   ...getValues(),
                              //   locationName: "",
                              //   building: {
                              //     ...getValues("building"),
                              //     location: { category: getValues("building.location.category"), identifier: "" },
                              //   },
                              // });

                              setValue("locationName", "");
                              setValue("building", {
                                ...getValues("building"),
                                location: { category: getValues("building.location.category"), identifier: "" },
                              });
                            }}
                            checked={category.value === value}
                          />
                        )}
                      ></Controller>
                    </div>
                  ))}
                </div>
              </section>

              {watch("building.location.category") === "FLOOR" && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">층</p>
                  </div>
                  <div className="contents-container__grid-contents ">
                    <div className="">
                      <Controller
                        control={control}
                        name={"building.location.identifier"}
                        render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                          <>
                            <div className="flex-center">
                              <BaseButton title="선택" className="color-white mr10" onClick={() => setOpenModal({ isOpen: true, type: "FLOOR" })} />
                              {(watch("locationName") || value) && (
                                <div className="flex-center">
                                  <Link
                                    to={location.pathname + location.search.replace("iotDevice", "property")}
                                    target="_blank"
                                    className="text-underline"
                                  >
                                    <span className="">{watch("locationName") || value}</span>
                                    <span className="ic-target-blank"></span>
                                  </Link>
                                  <button
                                    className="base-remove ml5"
                                    onClick={() => {
                                      onChange("");
                                      setValue("locationName", "");
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <p className="validation-text">{error?.message}</p>

                            {openModal.isOpen &&
                              openModal.type === "FLOOR" && ( //
                                <IotCommonTableModal
                                  checkboxType="radio"
                                  // addedList={addedDevice.map((device) => device.identifier)}
                                  columns={FloorColumns}
                                  data={iotLocation}
                                  onClick={(list: BuildingFloorModel[]) => {
                                    onChange(String(list[0].id));
                                    setValue("locationName", list[0].floorName || "");
                                  }}
                                  onClose={() => {
                                    setOpenModal({ isOpen: false });
                                  }}
                                />
                              )}
                          </>
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>
              )}

              {watch("building.location.category") === "ROOM" && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">층/호</p>
                  </div>
                  <div className="contents-container__grid-contents ">
                    <div className="">
                      <Controller
                        control={control}
                        name={"building.location.identifier"}
                        render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                          <>
                            <div className="flex-center">
                              <BaseButton
                                title="선택"
                                className="color-white mr10"
                                onClick={() => setOpenModal({ isOpen: true, type: "FLOOR_HO" })}
                              />
                              {(watch("locationName") || value) && (
                                <div className="flex-center">
                                  <Link
                                    to={location.pathname + location.search.replace("iotDevice", "property")}
                                    target="_blank"
                                    className="text-underline"
                                  >
                                    <span className="">{watch("locationName") || value}</span>
                                    <span className="ic-target-blank"></span>
                                  </Link>
                                  <button
                                    className="base-remove ml5"
                                    onClick={() => {
                                      onChange("");
                                      setValue("locationName", "");
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <p className="validation-text">{error?.message}</p>

                            {openModal.isOpen &&
                              openModal.type === "FLOOR_HO" && ( //
                                <IotCommonTableModal
                                  checkboxType="radio"
                                  // addedList={addedDevice.map((device) => device.identifier)}
                                  columns={BuildingFloorHoColumns}
                                  data={iotLocation}
                                  onClick={(list) => {
                                    onChange(String(list[0].id));
                                    setValue("locationName", list[0].roomName);
                                  }}
                                  onClose={() => setOpenModal({ isOpen: false })}
                                />
                              )}
                          </>
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>
              )}

              {watch("building.location.category") === "FACILITY" && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">공용공간</p>
                  </div>
                  <div className="contents-container__grid-contents ">
                    <div className="">
                      <Controller
                        control={control}
                        name={"building.location.identifier"}
                        render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                          <>
                            <div className="flex-center">
                              <BaseButton
                                title="선택"
                                className="color-white mr10"
                                onClick={() => setOpenModal({ isOpen: true, type: "FACILITY" })}
                              />
                              {(watch("locationName") || value) && (
                                <div className="flex-center">
                                  <Link
                                    to={location.pathname + location.search.replace("iotDevice", "facility")}
                                    target="_blank"
                                    className="text-underline"
                                  >
                                    <span className="">{watch("locationName") || value}</span>
                                    <span className="ic-target-blank"></span>
                                  </Link>
                                  <button
                                    className="base-remove ml5"
                                    onClick={() => {
                                      onChange("");
                                      setValue("locationName", "");
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <p className="validation-text">{error?.message}</p>

                            {openModal.isOpen &&
                              openModal.type === "FACILITY" && ( //
                                <IotCommonTableModal
                                  checkboxType="radio"
                                  // addedList={addedDevice.map((device) => device.identifier)}
                                  columns={BuildingFacilityColumns}
                                  data={iotLocation}
                                  onClick={(list) => {
                                    onChange(String(list[0].id));
                                    setValue("locationName", list[0].facilityName);
                                  }}
                                  onClose={() => setOpenModal({ isOpen: false })}
                                />
                              )}
                          </>
                        )}
                      ></Controller>
                    </div>
                  </div>
                </section>
              )}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">IoT 기기</p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="">
                    <Controller
                      control={control}
                      // name={"registeredDeviceId"}
                      name={"registerDevices"}
                      render={({ field: { onChange, value, name }, fieldState: { error }, formState: { isDirty } }) => (
                        <>
                          <div>
                            <BaseButton title="선택" className="color-white mr10" onClick={() => setOpenModal({ isOpen: true, type: "iot" })} />
                            <ul className={`${(value.length > 0 && "mt10") || ""}`} style={{ listStyle: "none " }}>
                              {value?.map((device) => (
                                <li className="flex-center mb10" key={`${device.displayName}-${device.registeredDeviceId}`}>
                                  <span className="">{device.displayName}</span>
                                  <button
                                    className="base-remove ml5"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      setValue(
                                        "registerDevices",
                                        value.filter((item) => item.registeredDeviceId !== device.registeredDeviceId),
                                      );
                                      if (value.length < 1) setValue("registeredDeviceId", "");
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                            <p className="validation-text">{error?.message}</p>
                          </div>

                          {openModal.isOpen &&
                            openModal.type === "iot" && ( //
                              <IotCommonTableModal
                                title="IoT 기기 선택"
                                checkboxType="checkbox"
                                addedList={addedDevice.map((device) => device.registeredDeviceId)}
                                pageMeta={pageMeta}
                                currentSize={(size: number) => {
                                  setParams({ ...params, size, page: 0 });
                                  fetchIotDevice({ ...params, size, page: 0 });
                                }}
                                isSearched={isSearched}
                                goToPage={(page) => {
                                  console.log("params", params);
                                  setParams({ ...params, page });
                                  fetchIotDevice({ ...params, page });
                                }}
                                columns={RegisteredDeviceColumns}
                                orders={params.sort?.orders}
                                setOrders={(orders: Order[]) => setParams({ ...params, sort: { orders } })}
                                data={device}
                                onClick={(selected: IoTDeviceRegistration[]) => {
                                  console.log("hi");
                                  const prev = value.map((item) => ({
                                    displayName: item.displayName,
                                    registeredDeviceId: String(item.registeredDeviceId || ""),
                                  }));
                                  const register = selected.map((item) => ({
                                    displayName: item.displayName,
                                    registeredDeviceId: String(item.platformDevice.registeredDeviceId || ""),
                                  }));

                                  // 중복된 항목을 제거한다
                                  const unique = [...prev, ...register].filter(
                                    (item, index, self) =>
                                      index ===
                                      self.findIndex((t) => t.displayName === item.displayName && t.registeredDeviceId === item.registeredDeviceId),
                                  );

                                  onChange(selected[0].platformDevice.registeredDeviceId);

                                  // 다른 건물에 선택한 기기가 등록된건지 확인
                                  setIsRegistered(
                                    selected.some((item) => item.buildingDeviceIds && item.buildingDeviceIds.length > 0) ? true : false,
                                  );
                                  setValue("registerDevices", unique);
                                  setIsSearched(false);
                                }}
                                onClose={() => {
                                  setOpenModal({ isOpen: false });
                                  setParams(queryData);
                                  setKeyword("");
                                }}
                              >
                                <div className="right-area">
                                  <div className="d-flex">
                                    <BaseInput
                                      type="text"
                                      value={keyword}
                                      onChange={(keyword: string) => setKeyword(keyword)}
                                      onKeyUp={() => {
                                        setIsSearched(true);
                                        setParams({ ...params, page: 0, search002: keyword });
                                        fetchIotDevice({ ...params, page: 0, search002: keyword });
                                      }}
                                      placeholder="검색어를 입력해주세요"
                                      onSearchClick={() => {
                                        setIsSearched(true);
                                        setParams({ ...params, page: 0, search002: keyword });
                                        fetchIotDevice({ ...params, page: 0, search002: keyword });
                                      }}
                                    />
                                  </div>
                                </div>
                              </IotCommonTableModal>
                            )}
                        </>
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="contents-container__btn-wrap">
          <div className="w-100 flex-center-end">
            <BaseButton title="취소" className="size-medium color-white mr10" onClick={cancelModal} />
            <BaseButton title="저장" className="size-medium" type="submit" />
          </div>
        </div>
        {confirmModal.isOpen && (
          <BaseConfirmModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              onAddIotDevice(confirmModal.payload as BuildingDeviceAddModel);
              setConfirmModal({ isOpen: false });
            }}
            className="px30 pre-formatted"
            title={confirmModal.title}
          ></BaseConfirmModal>
        )}
      </form>
    </div>
  );
};

export default BuildingDeviceForm;
