import { MediaFile } from "src/api/file/file-types";
import { UnionDayOfWeekType, UnionRecurrenceType } from "src/pages/workOrder/workOrder-types";
import { MgmtOfficerType } from "../building/building-types";
import { Sort, Status, UnionCmdType } from "../public-types";

// 업무구분 타입
export const WorkOrderType = {
  SPACE: "WORK_ORDER_SPACE",
  SERVICE: "WORK_ORDER_SERVICE",
} as const;

export type UnionWorkOrderType = (typeof WorkOrderType)[keyof typeof WorkOrderType] | "";

export type ReportType =
  | "REPORTER_GUEST" // 비회원 (default)
  | "REPORTER_MEMBER"; // 회원

// 워크오더 상태 타입
export const WorkOrderStatus = {
  OPEN: "WORK_ORDER_OPEN",
  PROGRESS: "WORK_ORDER_PROGRESS",
  COMPLETED: "WORK_ORDER_COMPLETED",
  CLOSED: "WORK_ORDER_CLOSED",
  WAITING: "WORK_ORDER_WAITING",
  CANCELED: "WORK_ORDER_CANCELED",
} as const;

export const MediaServiceType = {
  MEDIA_WORK_ORDER_SHEET: "MEDIA_WORK_ORDER_SHEET",
  MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT: "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT",
  MEDIA_WORK_ORDER_SHEET_COST: "MEDIA_WORK_ORDER_SHEET_COST",
} as const;

export type CustomWorkOrderStatus = {
  title: string;
  value: string;
};

export const workOrderStatusArray: CustomWorkOrderStatus[] = [
  {
    title: "접수",
    value: "WORK_ORDER_OPEN",
  },
  {
    title: "작업중",
    value: "WORK_ORDER_PROGRESS",
  },
  {
    title: "완료",
    value: "WORK_ORDER_COMPLETED",
  },
  {
    title: "종료",
    value: "WORK_ORDER_CLOSED",
  },
];

export const getWorkOrderStatusText = (status?: string) => {
  switch (status) {
    case "WORK_ORDER_OPEN":
      return "접수";
    case "WORK_ORDER_PROGRESS":
      return "작업중";
    case "WORK_ORDER_COMPLETED":
      return "완료";
    case "WORK_ORDER_CLOSED":
      return "종료";
    case "WORK_ORDER_WAITING":
      return "확인";
    case "WORK_ORDER_CANCELED":
      return "취소";
    default:
      return "";
  }
};

export const getWorkOrderStatusColor = (status?: string) => {
  switch (status) {
    case "접수":
      return "color-blue";
    case "작업중":
      return "color-green";
    case "완료":
      return "color-green";
    case "종료":
      return "color-gray";
    case "취소":
      return "color-gray";
    default:
      return "";
  }
};

export const getWorkOrderPrioryText = (priority?: string | number) => {
  switch (Number(priority)) {
    case 1:
      return ["color-gray", "낮음"];
    case 2:
      return ["color-green", "보통"];
    case 3:
      return ["color-brown", "높음"];
    case 4:
      return ["color-red", "긴급"];
    default:
      return "";
  }
};

// 워크오더 우선순위 옵션
export const workOrderPriorityOptions = [
  { value: "", label: "전체" },
  { value: 1, label: "낮음" },
  { value: 2, label: "보통" },
  { value: 3, label: "높음" },
  { value: 4, label: "긴급" },
];

// 검색 대상
export const searchTypes = [
  { value: "ALL", label: "전체" },
  { value: "SUBJECT", label: "제목" },
  { value: "OFFICER_NAME", label: "작업자" },
  { value: "CREATED_BY", label: "등록자" },
  { value: "NUMBER", label: "업무 번호" },
];

// 처리지연 목록 노출
export const workorderDelayed = [
  { value: "", label: "전체" },
  { value: "true", label: "지연" },
];

// 워크시트 처리비용
export interface WorkOrderSheetCost {
  id?: string; // 워크시트 처리비용 ID
  description?: string; // 비용 발생 사유
  cost?: number | string; // 금액
  isDeleted?: boolean;
  cmdType?: string;
}

export const VisitStatusEnum = {
  VISIT_USE: "VISIT_USE", //사용
  VISIT_PAUSE: "VISIT_PAUSE", //일시정지
  VISIT_DELETE: "VISIT_DELETE", //삭제
  VISIT_CLOSE: "VISIT_CLOSE", //종료
};

type VisitStatus = "VISIT_USE" | "VISIT_PAUSE" | "VISIT_DELETE" | "VISIT_CLOSE";
type VisitType = "INVITE_REGIST" | "SELF_REGIST";
export interface ContractVisitor {
  contractManageId?: string; //계약관리 id
  contractApplyNumber?: string; // 이용신청번호
  contractVisitorId?: string; //방문자 id
  visitApplyNumber?: string; //방문신청번호
  visitorName?: string; //방문자이름
  visitorMobileNumber?: string; //방문자 휴대폰번호
  visitStartTime?: string;
  visitEndTime?: string;
  visitStatus?: VisitStatus;
  visitType: VisitType;
  productName: string;
}

// 워크시트 상세조회 (무인증)
export interface WorkOrderDetail extends WorkOrderSheet {
  id?: number;
  mgmtOfficeId?: string; // 건물/관리처 ID
  buildingId?: string;
  buildingName?: string; // 건물이름
  mgmtOfficeName?: string;
  workOrderSheetNo?: string;
  workOrderType?: UnionWorkOrderType; // 워크오더 타입
  workOrderPriority?: string | number; // 우선순위
  reporterId?: string; // 접수자-회원ID
  reporterName?: string; // 접수자 이름
  reporterEmail?: string; // 접수자 이메일
  reporterPhone?: string; // 접수자 연락처
  reporterBuildingName?: string; // 접수자위치-건물명
  reporterBuildingAddr?: string; // 접수자위치-건물주소
  startDate?: string; // 시작일
  endDate?: string; // 종료일
  summary?: string; // 제목
  createdBy?: string;
  modifiedBy?: string;
  createdDate?: string;
  modifiedDate?: string;
  workOrderSheetGroupId?: number;
  mgmtOfficerName?: string;
  description?: string; // 내용
  isSendAlarm?: boolean; //알림 발송 여부
  isHoliday?: boolean; //주말/공휴일 포함 여부
  accessType?: string; //출입제공방식
  accessTime?: string;
  workOrderSheetAccessZoneList?: WorkOrderAccessZone[];
  contractVisitorList?: ContractVisitor[];
}

// 워크오더 비용발생 처리 타입
export interface WorkOrderSheetCostTypeOriginal {
  id?: string; //워크시트 처리비용 id
  description?: string; //비용 발생 사유
  cost?: number; // 금액
  cmdType?: UnionCmdType; // 요청타입
}
export interface WorkOrderSheetCostType extends WorkOrderSheetCostTypeOriginal {
  apiId?: string;
}

export interface WorkOrderVisitor {
  contractApplyNumber: string;
  contractManageId: number;
  contractVisitorId: number;
  visitApplyNumber: string;
  visitEndTime: string;
  visitStartTime: string;
  visitStatus: string;
  visitType: string;
  visitorMobileNumber: string;
  visitorName: string;
}

/** 워크오더 상세 타입 (인증)*/
export interface WorkOrderDetailModel {
  id?: string;
  workOrderSheetGroupId?: string;
  workOrderSheetNo?: string;
  workOrderType?: UnionWorkOrderType;
  workOrderPriority?: string;
  reporterId?: string;
  reporterName?: string;
  reporterPhone?: string;
  reporterBuildingName?: string;
  reporterBuildingAddr?: string;
  buildingId?: number;
  buildingName?: string;
  mgmtOfficeId?: string;
  mgmtOfficeName?: string;
  mgmtOfficerId?: number;
  mgmtOfficerName?: string;
  startDate?: string;
  endDate?: string;
  summary?: string;
  description?: string;
  completionReport?: string;
  workOrderSheetCostList?: Array<WorkOrderSheetCostType>;
  mediaList?: Array<MediaFile>;
  isReopened?: boolean;
  status?: Status;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  reporterEmail?: string;
  customerNotification?: string;
  isHoliday?: boolean;
  isSendAlarm?: boolean;
  costChangeCount?: number;
  imageChangeCount?: number;
  csTypeId?: string | null;
  workOrderSheetAccessZoneList?: WorkOrderAccessZone[]; //출입구역
  contractVisitorList?: WorkOrderVisitor[];
}

// 워크시트
export interface WorkOrderSheet {
  status: Status | string; // 상태
  mgmtOfficerId?: string; // 담당자ID
  completionReport?: string; // 처리내용
  isReopened?: boolean; //재처리요청여부
  workOrderSheetCostList?: Array<WorkOrderSheetCost>; // 처리비용
  mediaList?: Array<MediaFile>; //이미지
}

// 워크시트 목록 조회 parameter
export interface WorkOrderListParams {
  statusList?: Status[];
  keywordList?: string[] | string;
  page?: number;
  size?: number;
  sort?: Sort;
}

// 워크시트 목록 관리처 parameterzw
export interface PassParam extends WorkOrderListParams {
  mgmtOfficeId?: string;
}

export interface WorkOrderDetailResponse {
  content: WorkOrderDetailModel;
}

/** 워크오더 목록 타입 */
export interface WorkOrderListModel {
  id?: string;
  workOrderSheetGroupId?: string;
  workOrderSheetNo?: string;
  workOrderType?: UnionWorkOrderType;
  summary?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  workOrderPriority?: number;
  officerList?: Array<MgmtOfficerType>;
  buildingName?: string;
  mgmtOfficeName?: string;
  mgmtOfficerName?: string;
  isReopened?: boolean;
  createdDate?: string;
  createdBy?: string;
  partnerId?: string;
  providerId?: number;
  costSum?: string;
  costCount?: string;
  isOverdue?: boolean;
  questionId?: string;
  modifiedBy?: string;
  modifiedDate?: string;
}

/**워크오더 목록 response */
export interface WorkOrderListResponse {
  content: Array<WorkOrderListModel>;
}

// 워크오더 목록 url query parameter 타입
export type WorkOrderQueryParams = {
  page?: number;
  size?: number;
  startDate?: string;
  endDate?: string;
  searchType?: string;
  keywordList?: string;
  statusList?: string[] | string;
  sort?: Sort;
  priority?: number;
  includesDelayed?: string;
  isOverdue?: string;
};

// 워크오더 변경내역
export interface WorkOrderRevisionsModel {
  entity?: WorkOrderDetailModel;
  metaData?: {
    revisionNumber: string; //revision id
    revisionInstant: string; //revision 생성시간
    revisionType: RevisionType; //revision  type “INSERT”, “UPDATE”, “DELETE”
  };
}
export type RevisionType = "INSERT" | "UPDATE" | "DELETE";

export type WorkOrderRevisionParams = {
  page?: number;
  size?: number;
  id?: number;
  sort?: Sort;
};

/**워크오더 출입구역*/
export interface WorkOrderAccessZone {
  id?: string;
  mgmtOfficerId?: string; //	관리처 담당자 ID
  contractManageId: string; //	계약관리ID
  contractId: string; //	신청/계약ID
  startDate: string; //	출입시작일자
  endDate: string; //	출입종료일자
  startTime: string; //	출입시간시간
  endTime: string; //	출입종료시간
  accessPermitTime?: number; //	출입허용시간
  cmdType: UnionCmdType; //	요청타입
}

// 워크오더 생성 type (BE 문서 형식)
export interface WorkOrderFormTypeOriginal {
  id?: string | number; // 워크오더 id
  mgmtOfficeId?: string; //건물/관리처 ID
  mgmtOfficerId?: string; //담당자 ID
  startDate?: string; //시작일
  endDate?: string; //종료일
  summary?: string; //제목
  description?: string; //내용
  partnerId?: string;
  isSendAlarm?: boolean; //알림 발송 여부
  questionId?: string; // 상담관리 id
  csTypeId?: string; // voc 유형 id
  detailedSummary?: string; // 상세 유입 경로 내용
  accessType?: "SELF" | "TIME"; // 출입제공방식 SELF: 담당자 셀프 방식 / TIME: 자동부여
  workOrderSheetAccessZoneList?: WorkOrderAccessZone[]; //출입구역
  mediaList?: MediaFile[]; //이미지
  workOrderSheetGroupId?: string | null;
}

// 워크오더 수정 타입
export interface WorkOrderUpdateModel extends WorkOrderFormTypeOriginal {
  id?: string | number;
  customerNotification?: string; // 고객알림내용
  completionReport?: string; //처리내용
  workOrderSheetCostList?: Array<WorkOrderSheetCostType>;
  workOrderSheetNo?: string;
  status?: string;
  isReopened?: boolean;
}
// Front type
// 워크오더 생성 타입 모델 (Front 용 > payload 외에 필요한 데이터 활용 목적)
export interface WorkOrderFormType extends WorkOrderUpdateModel {
  createdDate?: string;
  buildingManagement?: string;
  // mgmtOfficerList?: MgmtOfficerType[];
  // mgmtOfficerName?: string;
}

export interface WorkOrderRevisionsResponse {
  content: Array<WorkOrderRevisionsModel>;
}

// // 워크오더 생성 타입 모델
// export interface WorkOrderPostType {
//   workOrderSheetList: WorkOrderFormTypeOriginal[];
// }

/**워크오더 반복일정 request 타입 */
export interface WorkOrderPreviewRequest {
  recurrenceType?: UnionRecurrenceType;
  startDate: string;
  recurrenceEndDate: string;
  recurrenceDayOfWeekTypeList?: string;
  isHoliday?: boolean;

  // recurrenceDayOfWeekType?: Array<UnionDayOfWeekType>;
}

export interface WorkOrderPreivew extends WorkOrderPreviewRequest {
  startTime: Date | string;
  endTime: Date | string;
  officerName: string;
  endDate?: string; // 종료일
  deadline?: number; // 처리 기한
  monthlyStart?: number; // 매월 몇일 업무시작
}

/**워크오더 목록 response */
export interface WorkOrderPreviewResponse {
  content: Array<string>;
}

export interface WorkOrderGroupType {
  id: string;
  // startDate: string;
  // recurrenceType: UnionRecurrenceType;
  // recurrenceEndDate: string;
  // recurrenceDayOfWeekType: string
  // isHoliday: string;
  // period: string;
}
