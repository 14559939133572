"use client";

import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

import { BaseModal } from "./components";

export interface OpenModalState {
  isOpen: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode | string;
  btnLeftTitle?: string;
  btnRightTitle?: string;
  type?: string;
  btnRightType?: "button" | "submit" | "reset" | undefined;
  className?: string;
  title?: string;
  btnRightDisable?: boolean;
}

export interface VariousContextValues {
  openModal: OpenModalState;
  setOpenModal: Dispatch<SetStateAction<OpenModalState>>;
  modalContent: null | JSX.Element;
  setModalContent: Dispatch<SetStateAction<null | JSX.Element>>;
}

export const VariousContext = createContext({} as VariousContextValues);

export const VariousProvider = ({ children }: { children: React.ReactNode }) => {
  const [openModal, setOpenModal] = useState<OpenModalState>({ isOpen: false });

  const [modalContent, setModalContent] = useState<null | JSX.Element>(null);

  return (
    <VariousContext.Provider
      value={{
        openModal,
        setOpenModal,
        modalContent,
        setModalContent,
      }}
    >
      {children}

      {openModal.isOpen && (
        <BaseModal
          onClick={() => {
            return openModal.onClick ? openModal.onClick : setOpenModal({ isOpen: false });
          }}
          onClose={() => {
            return openModal.onClose ? openModal.onClose : setOpenModal({ isOpen: false });
          }}
          {...openModal}
        ></BaseModal>
      )}
    </VariousContext.Provider>
  );
};

export const useVariousData = () => {
  return useContext(VariousContext);
};
