import axios from "axios";
const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

export const axiosInstanceNoLoading = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

export const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

export const axiosInstanceCourt = axios.create({
  baseURL: process.env.REACT_APP_COURT_BASSEURL,
});

export const axiosInstanceWithCaptcha = async (action: string) => {
  const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey, { action });
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
    headers: {
      "X-Recaptcha-Token": token,
    },
  });
  return axiosInstance;
};
