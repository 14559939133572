import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { ko } from "date-fns/locale";
import { forwardRef, useRef, useState } from "react";
import { BaseSelect } from "./BaseSelect";

interface Props extends Omit<ReactDatePickerProps<never, true>, "startDate" | "endDate"> {
  dateRange: [Date | null, Date | null];
  disabled?: boolean;
  stateOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
  }>;
  selectWidth?: number;
  selectValue?: string | number | null;
  setStateValue: Function;
}

function RangeDatepickerWithSelect(props: Props) {
  const [startDate, endDate] = props.dateRange;
  const [isFocus, setIsFocus] = useState(false);

  const RangeDatePinkerInput = forwardRef<HTMLInputElement, any>(
    ({ value, ...props }, ref) => {
      const inputRef = useRef<HTMLInputElement>(ref as any);
      const handleClickIcon = () => inputRef.current?.focus();
      return (
        <div
          className={`range-datepicker-input-wrap`}
        >
          <input value={value} {...props} disabled={props.disabled ? true : false} ref={inputRef} />
          <div className="icon" onClick={handleClickIcon} />
        </div>
      );
    },
  );

  return (
    <div className={`range-datepicker-input with-search ${isFocus ? "range-datepicker-input__focus" : ""}`}>
      <div className={props.selectWidth ? `minmax${props.selectWidth}` : "minmax148"}>
        <BaseSelect
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false)}}
          value={props.selectValue}
          placeholder={"기간 옵션"}
          stateOptions={props.stateOptions}
          setStateValue={props.setStateValue}
        />
      </div>
      <ReactDatePicker
        {...props}
        selectsRange={true}
        strictParsing={true}
        calendarClassName="base-datepicker"
        popperPlacement="bottom-end"
        dateFormat={"yyyy.MM.dd"}
        locale={ko}
        startDate={startDate}
        endDate={endDate}
        placeholderText="기간 선택"
      />
    </div>
  );
}
export default RangeDatepickerWithSelect;
