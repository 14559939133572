import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { BaseButton, BaseRadio, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import useContractApi from "../../hooks/useContractApi";
import useProductFacilityApi from "../../hooks/useProductFacilityApi";

interface ContractBasicProp {
  isOpen: boolean;
  saveObject: any;
  setSaveData: any;
  onClose: any;
}

const cols: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    width: 80,
    Cell: ({ row, selectedFc, setSelectedFc }: any) => {
      const changeTrigger = (checked: boolean) => {
        console.log("checked :>> ", checked);
        if (checked) {
          setSelectedFc(row.original);
        }
      };

      return (
        <div>
          <BaseRadio
            id={`selector${row.original.id}`}
            name={"isSelected"}
            checked={selectedFc?.id === row.original.id}
            onChange={changeTrigger}
            value={row.original.id}
          />
        </div>
      );
    },
  },
  {
    Header: "공용공간 유형",
    accessor: "commonFacilityType",
    width: 200,
    Cell: ({ value }: any) => {
      return (
        <>
          {value === "DESK" && "좌석"}
          {value === "MEETING_ROOM" && "회의실"}
          {value === "REFRESH_ROOM" && "편의시설"}
        </>
      );
    },
  },
  {
    Header: "공용공간 명",
    accessor: "facilityName",
    width: 250,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "정원",
    accessor: "facilityMaxPeopleNums",
    width: 80,
    Cell: ({ value }: any) => {
      return <>{value}</>;
    },
  },
  {
    Header: "위치",
    accessor: "locationCode",
    width: 108,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "건물",
    accessor: "buildingName",
    width: 200,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
];

const ProductFacilitySelectModal = ({ isOpen, saveObject, setSaveData, onClose }: ContractBasicProp) => {
  const [productFacilities, setProductFacilies] = useState<any>([]);
  const [selectedFc, setSelectedFc] = useState<any>({});

  const { getProductData } = useContractApi();
  const { makeSpaceList } = useProductFacilityApi();

  const makeViewData = useCallback(async () => {
    if (saveObject.productId) {
      const result = await getProductData(Number(saveObject.productId));
      if (result) {
        const tableData = await makeSpaceList(result);

        setProductFacilies(tableData || []);
      }
    }
  }, [getProductData, makeSpaceList, saveObject]);

  useEffect(() => {
    if (isOpen) {
      makeViewData();
    }
  }, [isOpen]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: cols,
      data: productFacilities,
      saveObject,
      setSaveData,
      setProductFacilies,
      selectedFc,
      setSelectedFc,
      initialState: { pageSize: 1000 },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const makeSaveData = useCallback(() => {
    let selectedFacility = { ...saveObject, selectedFacility: selectedFc };
    setSaveData(selectedFacility);
    onClose();
  }, [saveObject, selectedFc, setSaveData, onClose]);
  return (
    <BaseAbstractModal size="xlarge" isOpen={isOpen}>
      <>
        <section className="base-abstract-modal__title">
          <div className="flex-row flex-center-between w-100">
            <h1>신청 공간 선택</h1>
          </div>
        </section>
        <section className="base-abstract-modal__contents">
          <div {...getTableProps()} className="base-table view-data-table sticky px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__btn-wrap">
          <>
            <BaseButton title={"닫기"} className="color-white" onClick={() => onClose()} />
            <BaseButton title={"저장"} onClick={() => makeSaveData()} />
          </>
        </section>
      </>
    </BaseAbstractModal>
  );
};

export default ProductFacilitySelectModal;
