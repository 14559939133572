import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getCCM23, getCCM32 } from "src/api/chargeMnt/chargeMnt-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { columnFirst, columnLast } from "src/pages/chargeMnt/detail/components/columns/IndividualMntFeeColumns";
import { PagePath } from "src/pages/product/details";

const IndividualMntFee = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { executeAsync: getGroupDetail } = useApiOperation(getCCM23);
  const { executeAsync: getGroupItems } = useApiOperation(getCCM32);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("");
  // const clm: any = [...columnsFirst, ...columnsLast];

  const [columns, setColumns] = useState(columnFirst);
  const [middleHeaders, setMiddleHeaders] = useState([]);

  const [tableData, setTableData] = useState<any>([]);
  const [title, setTitle] = useState("");
  const groupDetail = useCallback(
    async (chartId: number) => {
      const response: any = await getGroupDetail({ chartId });
      if (response.status >= 200 && response.status <= 299) {
        setTitle(response.data.data.ctMntChartBundle.groupName);
        const st =
          response.data.data.ctMntChartBundle.ctMntChart.mntStatus === "READY"
            ? "대기"
            : response.data.data.ctMntChartBundle.ctMntChart.mntStatus === "PROGRESS"
            ? "진행"
            : "완료";
        setStatus(st);
      } else {
      }
    },
    [getGroupDetail],
  );

  const itemList = useCallback(async () => {
    const response: any = await getGroupItems({ chartId: id });
    console.log("response", response);
    const getHeaders = response.data.data.ctMntIndivRoomBundleList[0].ctMntIndivItemBundleList;
    const middle: any = [];
    getHeaders.map((header: any, index: number) => {
      let obj: any = {};
      if (header.isTax) {
        obj = {
          Header: header.itemName,
          accessor: `ctMntIndivItemBundleList[${index}].ctMntIndivItem`,
          columns: [
            {
              Header: "과세",
              accessor: `ctMntIndivItemBundleList[${index}].ctMntIndivItem.supplyAmount`,
              Cell: ({ value, row }: any) => {
                return (
                  <>
                    {!value && <div className="ic_ho"></div>}
                    {value && value}
                  </>
                );
              },
            },
            {
              Header: "부가세",
              accessor: `ctMntIndivItemBundleList[${index}].ctMntIndivItem.taxAmount`,
              Cell: ({ value, row }: any) => {
                // console.log(row.original);
                return (
                  <>
                    {!value && <div className="ic_ho"></div>}
                    {value && value}
                  </>
                );
              },
            },
          ],
          Cell: ({ value, row }: any) => {},
          Footer: ({ row }: any) => {},
          width: 120,
        };
      } else {
        obj = {
          Header: header.itemName,
          accessor: `ctMntIndivItemBundleList[${index}].ctMntIndivItem`,
          sticky: "",
          columns: [
            {
              Header: "비과세",
              accessor: `ctMntIndivItemBundleList[${index}].ctMntIndivItem.supplyAmount`,
              Cell: ({ value, row }: any) => {
                // console.log(index, row.original.ctMntIndivItemBundleList[index]);
                return (
                  <>
                    {!value && <div className="ic_ho"></div>}
                    {value && value}
                  </>
                );
              },
            },
          ],
          Cell: ({ value, row }: any) => {},
          Footer: ({ row }: any) => {},
          width: 120,
        };
      }
      middle.push(obj);
    });
    // console.log("colmns", columnFirst);
    // console.log("middle", middle);
    setMiddleHeaders(middle);

    setTableData(response.data.data.ctMntIndivRoomBundleList || []);
  }, [getGroupItems, id]);

  useEffect(() => {
    const all = [...columnFirst, ...middleHeaders, ...columnLast];
    setColumns(all);
  }, [middleHeaders]);
  useEffect(() => {
    if (id) {
      itemList();
      groupDetail(Number(id));
    }
  }, [groupDetail, id, itemList]);

  const goEditDetail = useCallback(() => {
    const path = PagePath.chargeMnt.form + "?id=" + id + "&tab=overAll";
    navigate(path);
  }, [id, navigate]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 1000 },
      setTableData,
    },
    useBlockLayout,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  const footerGroups = headerGroups.slice().reverse();

  return (
    <div className="contents-container__wrap">
      <article className="contents-container__wrap-article">
        {/* 세부 부과항목 */}
        <section className="">
          {/* 첫째줄 폼 */}

          {/* 2depth 테이블 */}
          <div className="inner-tab-table two-depth-header">
            <div {...getTableProps()} className="base-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header: any) => {
                      console.log(header);
                      // sticky 기준을 맞추기 위해 rowspan 처럼 보이는 헤더는 무조건 columns를 1개 가지고 있어야함.
                      // 스티키가 아닌 rowspan 헤더 케이스
                      if (!header.parent && header.depth === 0 && header.columns?.length === 1 && !header.sticky) {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            <div className="w-100 no-parent" style={{ zIndex: 2 }}>
                              {header.render("Header")}
                            </div>
                          </div>
                        );
                      } else if (header.sticky === "left" || header.sticky === "right") {
                        // 스티키 상태인 rowspan 케이스
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th" style={{ ...header.getHeaderProps().style, zIndex: 4 }}>
                            <div className="w-100 sticky-parent" style={{ zIndex: 2 }}>
                              {header.render("Header")}
                            </div>
                            {/* <div className="ic_sort"></div> */}
                          </div>
                        );
                      } else {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            <div className=" w-100 ">{header.render("Header")}</div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {page.map((row: any) => {
                  prepareRow(row);

                  return (
                    <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                      {row.cells.map((cell: any) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div className="footer">
                {footerGroups.map((footerGroup: any, index: number) => {
                  return (
                    <div {...footerGroup.getFooterGroupProps()} className="base-table__tr">
                      {footerGroup.headers.map((column: any) => (
                        <div {...column.getFooterProps()} className="sticky base-table__td base-table-footer">
                          {column.render("Footer")}
                        </div>
                      ))}
                    </div>
                  );
                  // }
                })}
              </div>
            </div>
          </div>
        </section>
      </article>
      <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => {}} onClose={() => setIsOpen(false)}>
        <p>저장 하시겠습니까?</p>
      </BaseModal>
      <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => {}} onClose={() => setIsOpen(false)}>
        <p>삭제 하시겠습니까?</p>
      </BaseModal>
      <BaseModal isOpen={isOpen} btnLeftTitle="취소" btnRightTitle="확인" onClick={(e?: any) => {}} onClose={() => setIsOpen(false)}>
        <p>목록으로 이동합니다. 미 저장 시 정보가 초기화 되어 저장됩니다.</p>
      </BaseModal>

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton title="목록으로" className="color-white size-large" onClick={() => navigate(PagePath.chargeMnt.list)} />
        </div>
        <div className="right-area">
          <BaseButton title="수정" className=" size-large" onClick={() => goEditDetail()} />
        </div>
      </div>
    </div>
  );
};
export default IndividualMntFee;
