import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { paymentCancelRequest } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";

import { BaseButton, BaseInput, BaseModal, BaseTooltip } from "src/components";
import Link from "src/components/PartnerLink";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, numberToStringWithComma } from "src/utils";

export const columns_first: any = [
  {
    Header: "id",
    accessor: "paymentId",
    Cell: ({ value }: any) => {
      return (
        <div className="text-center">
          <p>{value}</p>
        </div>
      );
    },
    width: 80,
  },
];
export const cardInfo: any = [
  {
    Header: "카드번호",
    accessor: "cardInfo",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        <div className="">
          <p>{value}</p>
        </div>
      );
    },
    Footer: ({ rows }: any) => {
      return <p className="text-primary3">결제금액 합계</p>;
    },
  },
];
export const virtualAccountInfo: any = [
  {
    Header: "은행명",
    accessor: "virtualAccount.bankName",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <div className="">
          <p>{value}</p>
        </div>
      );
    },
  },
  {
    Header: "계좌 번호",
    accessor: "virtualAccount.accountNo",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        <div className="">
          <p>{value}</p>
        </div>
      );
    },
    Footer: ({ rows }: any) => {
      return <p className="text-primary3">결제금액 합계</p>;
    },
  },
];
export const column_Last: any = [
  {
    Header: "결제금액",
    accessor: "totalAmount",
    width: 160,
    Cell: ({ value, row }: any) => {
      return (
        <div className="w-100 text-right pr10">
          <p>{numberToStringWithComma(value) + " 원"}</p>
        </div>
      );
    },
    Footer: ({ rows, row }: any) => {
      let [checkSumPrice, setCheckSumPrice] = useState(false);
      const sum = useMemo(() => {
        let value = 0;
        rows.forEach((r1: any) => {
          value = Number(r1.original.totalAmount);
          if (r1.original.paymentCancel) {
            value = value - Number(r1.original.paymentCancel.cancelAmount);
          }
          if (r1.original.transactionDate !== "") {
            setCheckSumPrice(true);
          }
        });
        return value;
      }, [rows]);

      return <p className="text-primary3">{checkSumPrice ? numberToStringWithComma(sum) + " 원" : "0 원"}</p>;
    },
  },
  {
    Header: "결제일자",
    accessor: "transactionDate",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-center pr10">
          <p>{value ? moment(value).format(ViewYmdFormat.FULL) : "-"}</p>
        </div>
      );
    },
  },
];
export const ctid = [
  // {
  //   Header: "cid",
  //   accessor: "cid",
  //   width: 100,
  //   Cell: ({ value }: any) => {
  //     return (
  //       <div className="w-100 text-center pr10">
  //         <p>{value}</p>
  //       </div>
  //     );
  //   },
  // },
  {
    Header: "tid",
    accessor: "tid",
    width: 180,
    Cell: ({ value }: any) => {
      return (
        // <div className="w-100 text-left pr10">
        <p>{value ? value : "-"}</p>
        // </div>
      );
    },
  },
];
export const column_edit_view: any = [
  {
    Header: "결제 상태",
    accessor: "editView",
    width: 180,
    Cell: ({ row, data, value }: any) => {
      let text = "완료";
      let ori = Number(row.original.totalAmount);
      if (row.original.paymentCancel) {
        let can = Number(row.original.paymentCancel.cancelAmount);
        if (ori > can) {
          text = "부분취소";
        }
        if (ori === can) {
          text = "취소";
        }
      }

      return <p>{text}</p>;
    },
  },
];

export const column_editable: any = [
  {
    Header: () => {
      return (
        <div className="flex-center font14">
          결제취소 요청
          <BaseTooltip
            contents={""}
            tooltip={"취소 금액을 입력 하면 입력한 금액만큼만 취소요청되고, 취소 금액을 입력 하지 않으면 결제금액 전부를 취소요청 합니다."}
            type={"normal"}
            className={"tooltip__trigger-icon"}
          />
        </div>
      );
    },
    accessor: "editable",
    width: 380,
    Cell: ({ row, editable, callList, callPaymentList, firstSettings, scheduleId }: any) => {
      const { openToast } = useToast();
      const { id } = useParams();
      const { executeAsync: paymentCancel } = useApiOperation(paymentCancelRequest);
      const [edit, setEdit] = useState(false);
      const [isCancel, setIsCancel] = useState(false);
      const [refundPrice, setRefundPrice] = useState("0");

      const sendCancelRequest = useCallback(
        async (cancelAmount: string) => {
          let sendData: any = {
            paymentId: row.original.paymentId,
          };
          if (Number(cancelAmount) > 0) {
            sendData.cancelAmount = cancelAmount;
          }
          // console.log("sendData", sendData);
          const cancelResponse = await paymentCancel(sendData);
          if (cancelResponse.data.data.content.result) {
            // console.log("cancelResponse.data.data.content.result", cancelResponse.data.data.content);

            if (cancelResponse.status >= 200 && cancelResponse.status <= 299) {
              setEdit(false);
              openToast({ content: "취소 요청 되었습니다.", isSystemToast: true });
            }
          }
          await callList();
          await callPaymentList();
        },
        [callList, callPaymentList, openToast, paymentCancel, row.original.paymentId],
      );
      const status = useMemo(() => {
        let text = "완료";
        let ori = Number(row.original.totalAmount);

        if (row.original.paymentCancel !== null) {
          let can = Number(row.original.paymentCancel.cancelAmount);

          if (ori > can) {
            text = "부분취소";
          }
          if (ori === can) {
            text = "취소";
          }
        }

        return text;
      }, [row]);

      return (
        <>
          {row.original.paymentCancel !== null ? (
            <p>{status}</p>
          ) : (
            <>
              {edit && editable ? (
                <div className="flex-center-start">
                  <BaseInput
                    placeholder="취소 금액을 입력하세요"
                    className="mr5"
                    name="refundPrice"
                    value={Number(refundPrice)}
                    onChange={(value: string) => {
                      if (Number(value) <= Number(row.original.totalAmount)) {
                        setRefundPrice(value);
                      }
                    }}
                  />
                  <BaseButton
                    title="취소"
                    className="base-btn color-white small-size mr5"
                    onClick={(e: any) => {
                      setEdit(false);
                    }}
                  />
                  <BaseButton title="요청" className="base-btn small-size" onClick={() => setIsCancel(true)} />
                  {isCancel && (
                    <BaseModal
                      isOpen={isCancel}
                      btnLeftTitle="취소"
                      btnRightTitle="확인"
                      onClose={() => setIsCancel(false)}
                      onClick={() => sendCancelRequest(refundPrice)}
                      title={"결제취소요청"}
                    >
                      <p>결제 취소하시겠습니까?</p>
                    </BaseModal>
                  )}
                </div>
              ) : (
                <Link
                  target={"_self"}
                  to={""}
                  className="text-underline"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setEdit(true);
                  }}
                >
                  결제 취소 요청
                </Link>
              )}
            </>
          )}
        </>
      );
    },
  },
];
