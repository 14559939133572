import moment from "moment";
import { ESignRecipient, SaveEsignDatas } from "src/api/contract/contract-types";
import { BaseButton, BaseCheckbox } from "src/components";
import { formatPhoneNumber, ViewYmdFormat } from "src/utils";

interface Props {
  workflow: SaveEsignDatas;
  sendUser: any;
  writeUrl: string;
  setIsPassed: any;
  isPassed: boolean;
}

const EsignStepThree = ({ workflow, writeUrl, setIsPassed, isPassed }: Props) => {
  return (
    <>
      <section className="base-abstract-modal__contents-subtitle mt0">
        <h2>문서 정보</h2>
      </section>
      <div className="contents-container">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>문서명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <p className="mr10">{workflow.workflowName}</p>
              <BaseButton
                title={"미리보기"}
                className={"color-white size-small"}
                onClick={() => {
                  window.open(writeUrl, "_blank");
                }}
              />
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>작성기한</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{moment(workflow?.expiryDate).format(ViewYmdFormat.YYYY_MM_DD)}</p>
          </div>
        </section>
      </div>
      <section className="base-abstract-modal__contents-subtitle mt0">
        <h2>받는 사람 정보</h2>
      </section>
      <div className="contents-container">
        {workflow.recipientList &&
          workflow.recipientList.length > 0 &&
          workflow.recipientList.map((rec: ESignRecipient, key: number) => {
            return (
              <section className="contents-container__grid" key={key}>
                <div className="contents-container__grid-index">
                  <p>{key + 1} 번째 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="minmax315">
                      <p>
                        {rec.name}
                        {rec.email && ` (${rec.email})`}
                      </p>
                    </div>
                    <div className="minmax180">
                      <ul className="base-list">
                        <li className="font14">휴대폰 : {formatPhoneNumber(rec.mobile!)?.replaceAll("-", "") || "-"}</li>
                      </ul>
                    </div>
                    <div className="minmax180">
                      <ul className="base-list">
                        <li className="font14">비밀번호 : {rec.password || "-"}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        {/* <section className="contents-container__grid">
          <div className="contents-container__grid-index"></div>
          <div className="contents-container__grid-contents">
            <BaseCheckbox
              label={"문자 전송 시 이용 중인 요금제에서 건수 차감에 동의합니다."}
              id={"isPass"}
              name={"isPass"}
              checked={isPassed}
              onChange={(value: boolean) => {
                setIsPassed(value);
              }}
            />
          </div>
        </section> */}
      </div>
    </>
  );
};
export default EsignStepThree;
