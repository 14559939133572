import { atom, selector, DefaultValue } from "recoil";
import { PartnerLoginListModel } from "src/api/partnerLogin/partnerlist-type";

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: { setSelf: (newValue: any) => void; onSet: (newValue: any) => void }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

type Partner = PartnerLoginListModel & {
  selected?: boolean;
};

export const partnersState = atom<Partner[] | undefined>({
  key: "partnersState",
  default: undefined,
  // effects: [localStorageEffect("partnersState")],
});

/**
 * 파트너 정보를 관리하는 atom입니다.
 * 파트너 리스트에서 파트너를 선택하면, 해당 파트너의 정보를 atom에 저장합니다.
 */
export const globalPartnerState = selector<Partner | undefined>({
  key: "globalPartnerState",
  get: ({ get }) => {
    const partners = get(partnersState);
    const selectedPartner = partners?.find((partner) => partner.selected);
    return selectedPartner!;
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }

    set(partnersState, (partners) => {
      const newPartners = partners?.map((partner) => {
        if (partner?.id === newValue?.id) {
          return { ...partner, selected: true };
        }
        return { ...partner, selected: false };
      });
      return newPartners;
    });
  },
});
