import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, PasswordResponses, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import { uploadFilesAsync } from "src/api/file/file-api";
import useApiOperation from "src/api/hooks/api-operation";
import { MediaFile } from "src/api/public-types";
import onErrorImage from "src/assets/images/onerror_preview.svg";
import { BaseButton } from "src/components/BaseButton";
import { BaseAbstractModal } from "../BaseAbstractModal";
import { BaseModal } from "../BaseModal";
import clsx from "clsx";
// import workerSrc from "../../../build/static/media/pdf.worker.min.7149cfc1274c38029805.mjs";

// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface Prop {
  fileList: any;
  isOnlyView?: boolean;
  setFiles?: any;
  errorText?: string;
  limit?: number;
  maxSize?: number;
  disabled?: boolean;
  serviceTag?: string;
  type?: "PROOF" | "LOGO";
  maxWidth?: number;

  setRef?: (ref: React.RefObject<HTMLInputElement>) => void; // 관리자 메모필수
  mode?: "memo"; // 관리자 메모필수
  containerClass?: string;
}
const s3Url = process.env.REACT_APP_S3_BASEURL;
interface Message {
  title: string;
  message: string;
}

const FileUpload = ({
  setFiles,
  fileList,
  errorText,
  isOnlyView,
  limit = 10,
  maxSize = 10,
  disabled,
  serviceTag,
  type = "PROOF",
  maxWidth = 1070,
  setRef,
  mode,
  containerClass,
}: Prop) => {
  const [isOpen, setIsOpen] = useState(false);
  const mb = 1024 * 1024;
  const fileRef = useRef<HTMLInputElement>(null);
  const [token, setToken] = useState("");
  const [isFullScreenImageOpen, setIsFullScreenImageOpen] = useState(false);
  const [focusFullScreenImageIndex, setFocusFullScreenImageIndex] = useState(0);
  const [previewImage, setPreviewImage] = useState(fileList);
  const [numPages, setNumPages] = useState<number>(0);
  const [message, setMessage] = useState<Message>({ title: "", message: "" });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  // const { executeAsync: postImageFiles } = useApiOperation(uploadOrgPrivateFilesAsync);

  const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync);

  useEffect(() => {
    if (setRef) {
      setRef(fileRef);
    }
  }, [fileRef, setRef]);

  useEffect(() => {
    const escapeKeyHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        setIsFullScreenImageOpen(false);
      }
    };

    window.addEventListener("keyup", escapeKeyHandler);

    return () => {
      window.removeEventListener("keyup", escapeKeyHandler);
    };
  }, []);

  // 드래그로 변경될 내용들을 담아줄 객체
  // const [dragAndDrop, setDragAndDrop] = useState<DragDropState>({
  //   draggedFrom: null, //드래그가 시작하는 index
  //   draggedTo: null, // 변경될 드래그 index
  //   isDragging: false,
  //   originalOrder: [],
  //   updatedOrder: [],
  // });

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

  // pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.7149cfc1274c38029805.mjs";

  const sizeNlimitText = `한 파일당 업로드 용량은 ${maxSize}MB, 최대 ${limit}개까지만 가능합니다.`;
  const sizeText = `파일은 최대 ${limit}개까지만 업로드 가능합니다.`;

  const errorPopup = (title: string, message: string) => {
    setMessage({ title, message });
    setIsOpen(true);
  };

  const sizeCheck = (files: any, sizeLimit: number) => {
    let checker = true;
    for (let i = 0; i < files.length; i++) {
      // console.log(e.target.files[i].size, sizeLimit);
      if (files[i].size > sizeLimit) {
        checker = false;
        break;
      }
    }
    return checker;
  };

  const limitCheck = (files: any, fileList: any, limit: number) => {
    let checker = true;
    const isDeletedTrue = fileList.some((file: any) => file.isDeleted === true);
    const nd: any = fileList.filter((obj: any) => obj.isDeleted === false);
    let totalFiles;

    if (isDeletedTrue) {
      totalFiles = files.length + nd.length;
    } else {
      totalFiles = files.length + fileList.length;
    }

    if (limit < totalFiles) {
      checker = false;
    }

    return checker;
  };

  const isLimiedFileValidatiaon = (files: any, fileList: any, maxSize?: number, limit?: number) => {
    // console.log("maxSize, limit", maxSize, limit);
    let checker = true;
    let sizeLimit = mb;

    //파일의 사이즈 체크
    if (maxSize !== undefined && limit === undefined) {
      // console.log("사이즈");
      sizeLimit = maxSize * mb;
      if (sizeCheck(files, sizeLimit)) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeText);
        checker = false;
        return;
      }
    }
    if (limit !== undefined && maxSize === undefined) {
      if (!limitCheck(files, fileList, limit)) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeText);
        checker = false;
        return;
      }
    }

    if (limit !== undefined && maxSize !== undefined) {
      // console.log("둘다 있다.");
      sizeLimit = maxSize * mb;
      if (sizeCheck(files, sizeLimit) === false || limitCheck(files, fileList, limit) === false) {
        errorPopup("첨부파일 업로드가 실패했습니다.", sizeNlimitText);
        checker = false;
        return;
      }
    }
    return checker;
  };

  const onChange = async (e: any, fileList: any, maxSize?: number, limit?: number) => {
    const uploaded: any = [];
    if (isLimiedFileValidatiaon(e.target.files, fileList, maxSize, limit) && e.target.files && e.target.files.length) {
      const arrFiles = Array.from(e.target.files);

      const files: any = arrFiles.map((file, index) => {
        const src = window.URL.createObjectURL(file as any);
        return { file, id: index, src };
      });

      for (const i in files) {
        const formData = new FormData();
        formData.append("file", files[i].file);
        const res = await postImageFile({ file: formData, type: type === "LOGO" ? "public" : "private" });

        if (res && res.status >= 200 && res.status <= 299) {
          const mediaFile = JSON.parse(JSON.stringify({ ...res.data.data.media, ...{ type, serviceTag, isDeleted: false } }));
          const file = e.target.files[i];
          const readAsDataURL = (file: Blob) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          };

          try {
            const blobUrl = await readAsDataURL(file);
            uploaded.push({ ...mediaFile, blobUrl });
          } catch (error) {
            console.error("Error reading file:", error);
          }
        }
      }
      e.target.value = "";
    }

    setFiles([...fileList, ...uploaded]);
  };

  const getToken = useCallback(async () => {
    await getAccessTokenSilently();
    const response = await getIdTokenClaims();
    const idToken = response?.__raw;
    setToken(idToken!);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  const handleOpenFullImage = (idx: number) => {
    setFocusFullScreenImageIndex(idx);
    setIsFullScreenImageOpen((prev) => !prev);
  };

  const handleFullScreenImage = (position: "left" | "right") => {
    if (position === "left") {
      setFocusFullScreenImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
      setPageNumber(1);
    }
    if (position === "right") {
      setFocusFullScreenImageIndex((prev) => (prev < previewImage.length - 1 ? prev + 1 : prev));
      setPageNumber(1);
    }
  };

  const handleDeleteFile = (e: any, passFile: MediaFile) => {
    e.preventDefault();

    let newArr = [];

    if (!passFile.id) {
      newArr = fileList.filter((item: MediaFile) => item.key !== passFile.key);
    } else {
      newArr = fileList.map((file: MediaFile) => {
        if (file.id === passFile.id) {
          file = { ...file, isRemoved: true };
        }
        return file;
      });
    }
    setFiles(newArr);
  };

  const findImageURL = (file: any) => {
    if (file.blobUrl) {
      return file.blobUrl;
    } else if (file.type === "LOGO") {
      return file.url;
    } else if (file.type === "PROOF") {
      return file.url + "?access_token=" + token;
    } else if (!file.type && file.url) {
      return file.url + "?access_token=" + token;
    } else if (file.key?.includes("http")) {
      return file.key;
    } else if (!file.key?.includes("http") && file.key) {
      return s3Url + file?.key!;
    } else return "";
  };

  useEffect(() => {
    if (token === "") {
      getToken();
    }
  }, [getToken, fileList, setFiles, getAccessTokenSilently, getIdTokenClaims, token]);

  useEffect(() => {
    setPreviewImage(fileList);
  }, [fileList, previewImage]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePdfLeft = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const handlePdfRight = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleImageDownload = async (filename: string, url: string) => {
    try {
      const response = await axios.get(url + "?access_token=" + token, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = filename;
      link.click();
    } catch (error) {
      console.log("다운로드실패", error);
    }
  };

  function onPassword(callback: (arg0: any) => void, reason: any) {
    function callbackProxy(password: string | null) {
      // Cancel button handler
      if (password === null) {
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
        return setIsFullScreenImageOpen(false);
      }

      callback(password);
    }
    console.log("reason :>> ", reason);

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt("비밀번호를 입력해주세요.");
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt("비밀번호가 맞지 않습니다 다시 입력해주세요.");
        callbackProxy(password);
        break;
      }
      default:
        callbackProxy(null);
    }
  }

  const renderFileView = (file: any) => {
    const fileUrlWithToken = file.blobUrl
      ? file.blobUrl
      : file.url
      ? file.url + "?access_token=" + token
      : s3Url + file.key + "?access_token=" + token;
    const fileName = file.filename;
    const fileExtension = file.filename.slice(file.filename.lastIndexOf(".") + 1);
    const availableFileExtensionArray = ["jpg", "jpeg", "gif", "png", "svg", "bmp"];

    // 이미지
    if (availableFileExtensionArray?.includes(fileExtension.toLowerCase())) {
      return (
        <>
          <img
            src={findImageURL(file)}
            alt={fileName || "이미지를 불러오는데 실패했습니다."}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = onErrorImage;
            }}
          />
          <p className="file-name">{fileName}</p>
        </>
      );
    }

    // 이미지가 아닐경우
    switch (fileExtension) {
      case "pdf":
        return (
          <div className="file-viewer-pdf">
            <Document
              className="file-viewer-pdf__document"
              file={fileUrlWithToken ? fileUrlWithToken : ""}
              onLoadSuccess={onDocumentLoadSuccess}
              onPassword={onPassword}
            >
              <Page pageNumber={pageNumber} renderAnnotationLayer={false} loading={"loading..."} onLoadError={console.error} />
            </Document>
            <div className="button-wrap">
              <button className="left" disabled={pageNumber === 1} onClick={handlePdfLeft}></button>
              <button className="right" disabled={pageNumber === numPages} onClick={handlePdfRight}></button>
            </div>
          </div>
        );
      default:
        return (
          <div className="no-preview-available">
            <p>미리보기를 제공하지 않는 파일 형식입니다.</p>
            <p>다운로드 후 확인해 주세요.</p>
            <p className="file-name">{fileName}</p>
          </div>
        );
    }
  };

  const sliceTitle = (fileName: string) => {
    const dotIndex = fileName.lastIndexOf(".");
    const title = fileName.slice(0, dotIndex);
    const extension = fileName.slice(dotIndex + 1);
    return { title, extension };
  };

  // TODO: 드래그로 파일 순서 변경은 추후 적용 - Drag&Drop 코드 삭제하지 말것
  // ============================= 삭제 NO ===========================
  // 드래그하려고 시작할 때
  // const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.style.opacity = "0.4";
  //   const initialPosition = Number(event.currentTarget.dataset.position);

  //   setDragAndDrop({
  //     ...dragAndDrop,
  //     draggedFrom: initialPosition,
  //     originalOrder: previewImage,
  //   });
  // };

  // const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();

  //   let newList = dragAndDrop.originalOrder;
  //   const draggedFrom = dragAndDrop.draggedFrom; // 드래그 되는 항목의 인덱스(시작)
  //   const draggedTo = Number(event.currentTarget.dataset.position); // 놓을 수 있는 영역의 인덱스(끝)
  //   const itemDragged = newList[draggedFrom || 0]; //

  //   const remainingItems = newList.filter((item, idx) => idx !== draggedFrom); //draggedFrom(시작) 항목 제외한 배열 목록

  //   newList = [...remainingItems.slice(0, draggedTo), itemDragged, ...remainingItems.slice(draggedTo)];
  //   // 놓을 수 있는 영역이 변경 되면 객체를 변경해줌
  //   if (draggedTo !== dragAndDrop.draggedTo) {
  //     setDragAndDrop({
  //       ...dragAndDrop,
  //       updatedOrder: newList,
  //       draggedTo: draggedTo,
  //     });
  //   }
  // };

  // // onDrop 잡은 Item을 적절한 곳에 놓았을 때 발생하는 이벤트 (다른 item이랑 겹쳐졌을 때)
  // const onDrop = () => {
  //   setPreviewImage(dragAndDrop.updatedOrder);
  //   setFiles(dragAndDrop.updatedOrder);
  //   setDragAndDrop({
  //     ...dragAndDrop,
  //     draggedFrom: null,
  //     draggedTo: null,
  //   });
  // };

  // // onDragLeave 범위를 벗어나면 발생하는 이벤트
  // const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.classList.remove("over");
  //   setDragAndDrop({
  //     ...dragAndDrop,
  //     draggedTo: null,
  //   });
  // };

  // // 다른 item이랑 겹쳐졌을 때 발생하는 이벤트 (1번만)
  // const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.classList.add("over");
  // };

  // // 잡은 Item을 놓았을 때 발생하는 이벤트 (다른 item이랑 겹치지 않아도 발생함)
  // const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.currentTarget.style.opacity = "1";

  //   const files = [...previewImage];

  //   const listItems = document.querySelectorAll(".draggable");
  //   listItems.forEach((item) => {
  //     item.classList.remove("over");
  //   });

  //   const newFiles = files.map((file, idx) => {
  //     return { ...file, orderNums: String(idx), cmdType: file.id ? "U" : "C" };
  //   });
  //   setFiles(newFiles);
  //   setPreviewImage(files);
  // };
  // ============================= 삭제 NO ===========================
  return (
    <div
      // className="base-image-wrap"
      className={`contents-container__grid-contents ${containerClass}`}
    >
      <div className={`base-file`} style={{ width: maxWidth }}>
        <input
          type="file"
          name="img-loader-input"
          multiple
          ref={fileRef}
          onChange={(e: any) => onChange(e, fileList, maxSize, limit)}
          style={{ display: "none" }}
        />
        {!isOnlyView && (
          <BaseButton
            disabled={disabled}
            title="파일찾기"
            className={`color-white ${fileList?.length > 0 ? "mb10" : ""} ${clsx({ "d-none": mode === "memo" })}`}
            onClick={() => fileRef.current?.click()}
          />
        )}
        <>
          {/* <div className={`${Array.isArray(fileList) && fileList.length > 1 ? "fit-start-line" : ""}`}> */}
          <div className="flex-center" style={{ overflowX: "auto" }}>
            {fileList
              ?.filter((file: MediaFile) => !file.isRemoved)
              .sort((a: MediaFile, b: MediaFile) => Number(a.orderNums) - Number(b.orderNums))
              .map((file: any, index: number) => (
                <div
                  className={`base-image__wrap ${isOnlyView ? "detail" : "form"}`}
                  key={index}
                  draggable={true}
                  data-position={index}
                  // onDragStart={(e) => !isOnlyView && onDragStart(e)}
                  // onDragOver={(e) => !isOnlyView && onDragOver(e)}
                  // onDragLeave={(e) => !isOnlyView && onDragLeave(e)}
                  // onDrop={(e) => !isOnlyView && onDrop()}
                  // onDragEnter={(e) => !isOnlyView && onDragEnter(e)}
                  // onDragEnd={(e) => !isOnlyView && onDragEnd(e)}
                  onClick={() => handleOpenFullImage(index)}
                >
                  <img
                    src={findImageURL(file)}
                    alt="preview-images"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = onErrorImage;
                    }}
                  />
                  {!isOnlyView && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFile(e, file);
                      }}
                    />
                  )}

                  <div className="title-area">
                    <div className="not-hover">
                      <p>{sliceTitle(file.filename).title}</p>
                      <span>.{sliceTitle(file.filename).extension}</span>
                    </div>
                    <div className="active-hover">
                      <p>{sliceTitle(file.filename).title}</p>
                      <span>.{sliceTitle(file.filename).extension}</span>
                      {isOnlyView && (
                        <button
                          type="button"
                          className="detail-download"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageDownload(file.filename, file.url);
                          }}
                        ></button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {isFullScreenImageOpen && (
            <BaseAbstractModal isNotUseWhiteBoard isOpen={isFullScreenImageOpen} opacity={0.75}>
              <div className="base-image__full-screen">
                {renderFileView(previewImage[focusFullScreenImageIndex])}
                <div className="img-button-wrap">
                  <div className="flicker">
                    <button className="left" onClick={() => handleFullScreenImage("left")}></button>
                    <p>
                      {focusFullScreenImageIndex + 1} / {previewImage.length}
                    </p>
                    <button className="right" onClick={() => handleFullScreenImage("right")}></button>
                  </div>

                  {previewImage[focusFullScreenImageIndex].id && (
                    <button
                      className="download"
                      onClick={(e) =>
                        handleImageDownload(previewImage[focusFullScreenImageIndex].filename, previewImage[focusFullScreenImageIndex].url)
                      }
                    ></button>
                  )}

                  <button className="close" onClick={() => setIsFullScreenImageOpen(false)}>
                    닫기
                  </button>
                </div>
              </div>
            </BaseAbstractModal>
          )}
        </>
        {isOpen && (
          <BaseModal isOpen={isOpen} btnRightTitle={"확인"} onClick={() => setIsOpen(false)} title={message.title}>
            <p>{message.message}</p>
          </BaseModal>
        )}
        {errorText && <p className={clsx("validation-text", { "d-none": mode === "memo" })}>{errorText}</p>}
        {!isOnlyView && (previewImage.length === 0 || previewImage.filter((image: any) => !image.isRemoved).length === 0) && (
          <ul className={clsx("base-list mt10", { "d-none": mode === "memo" })}>
            <li>문서를 카메라로 찍거나 스캔하여 업로드해주세요.</li>
            <li>업로드 용량은 개당 10MB까지 가능합니다.</li>
          </ul>
        )}
      </div>
    </div>
  );
};
export default FileUpload;
