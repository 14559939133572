import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { getReservationUsagesAsync } from "src/api/reservation/reservation-api";
import { useApiOperation } from "src/api/hooks";
import { ReservationListModel, ReservationUsageResponse } from "src/api/reservation/reservation-types";
import Link from "src/components/PartnerLink";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import { useParams } from "react-router-dom";
import PhoneNumberColumn from "src/components/PhoneNumberColumn";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  reservation: ReservationListModel | null;
};

/* 
  공용공간 예약 상세 > 예약 내역 컴포넌트
 */
const ReservationHistorySection = ({ reservation }: Props) => {
  const [usage, setUsage] = useState<ReservationUsageResponse>();
  const { executeAsync: getReservationUsages } = useApiOperation(getReservationUsagesAsync); // 소진시간
  const reservationData = useCallback(
    (type: string) => {
      switch (type) {
        // 예약경로
        case "inboundChannel":
          return reservation?.inboundChannel || "-";

        // 건물명, 위치
        case "buildingName":
          const buildingName = reservation?.facility.building.name;
          if (buildingName) {
            return (
              <div className="no-wrap">
                <BaseNewTabLink
                  path={`${PagePath.building.detail.replace(":id", String(reservation?.facility.building.id))}?tab=facility`}
                  value={buildingName}
                  className="w-100 text-left"
                />
              </div>
            );
          }
          return "-";
        // 건물명, 위치
        case "location":
          let isGround = "";
          const floorNum = reservation?.facility.floor;

          if (floorNum && floorNum > 0) {
            isGround = "지상";
          } else {
            isGround = "지하";
          }

          if (floorNum) {
            return (
              <>
                {isGround}
                {floorNum}층
              </>
            );
          }
          return "-";
        // 공간타입, 공간명

        case "spaceType":
          let spaceType = "";

          if (reservation?.facility.type === "MEETING_ROOM") {
            spaceType = "회의실";
          } else if (reservation?.facility.type === "DESK") {
            spaceType = "좌석";
          } else if (reservation?.facility.type === "REFRESH_ROOM") {
            spaceType = "편의시설";
          } else {
            spaceType = "-";
          }

          if (spaceType) {
            return `${spaceType}`;
          }

          return "-";
        // 공용공간명

        case "spaceName":
          if (reservation?.facility) {
            return `${reservation?.facility.name}`;
          }

          return "-";

        // 예약일
        case "reserveDate":
          const date = moment(reservation?.start).format(ViewYmdFormat.YYYY_MM_DD);
          if (reservation?.start && reservation?.end) {
            return `${date}`;
          }

          return "-";

        // 예약시간
        case "reserveTime":
          const startDate = moment(reservation?.start).format("HH:mm");
          const endDate = moment(reservation?.end).format("HH:mm");

          if (reservation?.start && reservation?.end) {
            return `${startDate} ~ ${endDate === "00:00" ? "24:00" : endDate}`;
          }

          return "-";
        // 예약자 ID
        case "memberNo":
          if (reservation?.organizer.memberNo) {
            return <>{`${reservation?.organizer.memberNo}`}</>;
          }
          return "-";

        // 이메일
        case "email":
          if (reservation?.organizer.userEmail) {
            return <>{`${reservation?.organizer.userEmail}`}</>;
          }
          return "-";

        // 닉네임
        case "displayName":
          if (reservation?.organizer.displayName) {
            return <>{`${reservation?.organizer.displayName}`}</>;
          }
          return "-";

        // 생성일
        case "createdDate":
          if (reservation?.createdDate) {
            return <>{moment(reservation?.createdDate).format(ViewYmdFormat.FULL)}</>;
          }
          return "-";

        // 휴대폰번호
        case "phone":
          if (reservation?.organizer.phoneNumber) {
            return <>{PhoneNumberColumn({ phoneNumber: reservation?.organizer.phoneNumber })}</>;
          }
          return "-";
      }
    },
    [reservation],
  );
  const { id } = useParams();

  useEffect(() => {
    const fetchUsage = async (id: string) => {
      const { data } = await getReservationUsages({ id });
      if (data.data) {
        setUsage(data.data);
      }
    };
    if (id && reservation) {
      fetchUsage(id);
    }
  }, [getReservationUsages, id, reservation]);
  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title={"예약 내역"} />
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">예약경로</p>
        </div>

        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <div className="font14">{reservationData("inboundChannel")}</div>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">건물명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <div className="font14">{reservationData("buildingName")}</div>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">위치</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <p className="font14">{reservationData("location")}</p>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">공간타입</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("spaceType")}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">공간명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("spaceName")}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">예약일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("reserveDate")}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">예약시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("reserveTime")}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">소진시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{usage && Number(usage.duration) >= 0 ? `${String(usage.duration)} 분` : "-"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">회원번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("memberNo")}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">휴대폰번호</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("phone")}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">닉네임</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservationData("displayName")}</span>
          </div>
        </div>
      </section>
    </article>
  );
};

export default ReservationHistorySection;
