import { Dispatch, SetStateAction, useCallback } from "react";
import { Control, Controller, UseFormRegister, useFieldArray } from "react-hook-form";
import { ProviderContactModel } from "src/api/provider/provider-types";
import { BaseInput } from "src/components";
import { ProviderContactFormType } from "src/pages/provider/provider-types";
import { onlyNumber } from "src/utils";

type Props = {
  control: Control<ProviderContactFormType>;
  register: UseFormRegister<ProviderContactFormType>;
  errors?: any;
  deletedContact: Array<ProviderContactModel>;
  setDeletedContact: Dispatch<SetStateAction<Array<ProviderContactModel>>>;
};

const ContractContactForm = ({ control, deletedContact, setDeletedContact, errors, register }: Props) => {
  const {
    fields: contractContactList,
    append: addContract,
    remove,
  } = useFieldArray({
    control,
    name: `contractContactList`,
  });

  const errorText = useCallback((errorsData: any) => {
    if (errorsData.contractContactList) {
      const error = errorsData.contractContactList.filter((item: any) => item !== "empty");

      for (let i = 0; i < error.length; i++) {
        const errorType = error[i];
        if (errorType?.name) {
          return <p className="validation-text">{"필수입력 항목입니다"}</p>;
        }

        if (errorType?.email) {
          return <p className="validation-text">{errorType?.email?.message}</p>;
        }

        if (errorType?.contact) {
          return <p className="validation-text">{errorType?.contact?.message}</p>;
        }
      }
    }
  }, []);

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title">
        <h2>계약 진행 담당</h2>
      </div>
      <section className="contents-container__1200">
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>
                <span>No</span>
              </th>
              <th>
                <span className="required">이름</span>
              </th>
              <th>
                <span className="required">연락처</span>
              </th>
              <th>
                <span className="required">이메일</span>
              </th>
              <th>
                <span>비고</span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {contractContactList &&
              contractContactList.map((item: ProviderContactModel & { id: string }, contactIdx: number) => {
                return (
                  <tr key={item.id}>
                    <td width="100">
                      <div className="flex-center-center">
                        <span>{contactIdx + 1}</span>
                      </div>
                    </td>
                    <td width="100">
                      <Controller
                        control={control}
                        name={`contractContactList.${contactIdx}.name`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return <BaseInput onChange={onChange} value={value} name={name} />;
                        }}
                      ></Controller>{" "}
                    </td>
                    <td width="150">
                      <div>
                        <Controller
                          control={control}
                          name={`contractContactList.${contactIdx}.contact`}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return <BaseInput type="phone" onChange={(phone: string) => onChange(phone)} value={value} name={name} />;
                          }}
                        ></Controller>
                      </div>
                    </td>
                    <td width="200">
                      <div>
                        <Controller
                          control={control}
                          name={`contractContactList.${contactIdx}.email`}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return <BaseInput onChange={onChange} value={value} name={name} />;
                          }}
                        ></Controller>
                      </div>
                    </td>
                    <td width="auto">
                      <div>
                        <Controller
                          control={control}
                          name={`contractContactList.${contactIdx}.description`}
                          render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                            return <BaseInput onChange={onChange} value={value} name={name} />;
                          }}
                        ></Controller>
                      </div>
                    </td>
                    <td width="60">
                      <div className="flex-center-center">
                        {contactIdx < 1 ? (
                          <button
                            className="base-add-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              addContract({
                                name: "",
                                contact: "",
                                email: "",
                                assignedWorkType: "NORMAL_PROVIDE",
                                description: "",
                                isDeleted: false,
                              });

                              const requiredMessage = "필수입력 항목입니다";
                            }}
                            disabled={contractContactList.length === 3}
                          ></button>
                        ) : (
                          <button
                            className="base-erase-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              contractContactList[contactIdx].isDeleted = true;
                              setDeletedContact([...deletedContact, contractContactList[contactIdx]]);
                              remove(contactIdx);
                            }}
                          ></button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {errorText(errors)}
      </section>
    </article>
  );
};

export default ContractContactForm;
