import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { DetailTotalData } from "src/api/contract/contract-types";
import { ArticleSection } from "src/components/layout/ArticleSection";
import pagePath from "src/pagePath.json";
import BillingScheduleDetail from "./detailComponent/BillingScheduleDetail";
import BillingScheduleEdit from "./editComponent/BillingScheduleEdit";

interface BasicProps {
  detailData: DetailTotalData;
  setData: Function;
}

const ContractBillingSchedule = ({ detailData, setData }: BasicProps) => {
  const { partnerId } = useParams();
  const R = "RENTAL";
  const [editable, setEditable] = useState(false);
  const [saveFlags, setSaveFlags] = useState(false);

  const { contract, contractManage, scheduleList, provider } = detailData;

  const [textGrey] = useState("contents-container__grid ");
  const [rentalSchedule, setRentalSchedule] = useState<BillingDetail>();
  const [mntSchedule, setMntSchedule] = useState<BillingDetail[]>();
  const [popupState, setPopupState] = useState(false);
  const [editConfrim, setEditConfrim] = useState(false);
  const [commonModals, setCommonModals] = useState<any>({
    message: "",
    title: "",
  });

  const gotoBillingScheduleDetailList = () => {
    let path = pagePath.billing.detail.replace(":partnerId", String(partnerId)).replace(":id", String(contract?.contractId)) + "?tab=schedule";
    window.open(path, "_blank", "noreferrer");
  };

  useEffect(() => {
    if (detailData) {
      const schedule = scheduleList?.find((sd: BillingDetail) => sd.supplyType === R);
      const mntList = scheduleList?.filter((sd: BillingDetail) => sd.supplyType !== R);

      setRentalSchedule(schedule);

      setMntSchedule(mntList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <ArticleSection
      title={detailData?.product.productType === "TIME_COURT" ? "결제 정보" : "이용료 결제 정보"}
      isEditable={editable}
      setIsEditable={setEditable}
      onClickSave={() => setSaveFlags(true)}
      popupState={popupState}
      setPopupState={setPopupState}
      editConfrim={editConfrim}
      setEditConfrim={setEditConfrim}
      commonModals={commonModals}
      disabledState={[String(detailData?.contract?.contractStep), String(detailData?.product.productType)]}
      children={
        editable === false ? (
          <BillingScheduleDetail
            detailData={detailData}
            rentalSchedule={rentalSchedule!}
            contract={contract!}
            gotoBillingScheduleDetailList={gotoBillingScheduleDetailList}
            textGrey={textGrey}
          />
        ) : (
          <BillingScheduleEdit
            detailData={detailData}
            saveFlags={saveFlags}
            setSaveFlags={setSaveFlags}
            setEditable={setEditable}
            contract={contract!}
            textGrey={textGrey}
            contractManage={contractManage!}
            rentalSchedule={rentalSchedule!}
            gotoBillingScheduleDetailList={gotoBillingScheduleDetailList}
            provider={provider!}
            setData={setData}
            popupState={popupState}
            setPopupState={setPopupState}
            setCommonModals={setCommonModals}
            setEditConfrim={setEditConfrim}
          />
        )
      }
    />
  );
};
export default ContractBillingSchedule;
