import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { ko } from "date-fns/locale";
import { forwardRef, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface Props extends Omit<ReactDatePickerProps<never, true>, "startDate" | "endDate"> {
  dateRange: [Date | null, Date | null];
  disabled?: boolean;
  type?: "month" | "";
  dateFormat?: "yyyy.MM" | "";
}

function RangeDatepicker(props: Props) {
  const [startDate, endDate] = props.dateRange;

  const RangeDatePinkerInput = forwardRef<HTMLInputElement, any>(({ value, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(ref as any);
    const handleClickIcon = () => inputRef.current?.focus();

    return (
      <div className="range-datepicker-input">
        <input value={value} {...props} disabled={props.disabled ? true : false} ref={inputRef} />
        <div className="icon" onClick={handleClickIcon} />
      </div>
    );
  });

  // 포탈로 오픈
  const CalendarPortal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return createPortal(<div className="calendar-portal">{children}</div>, document.body);
  };

  return (
    <ReactDatePicker
      {...props}
      selectsRange={true}
      calendarClassName="base-datepicker"
      dateFormat={props.dateFormat ? props.dateFormat : "yyyy.MM.dd"}
      locale={ko}
      startDate={startDate}
      endDate={endDate}
      customInput={<RangeDatePinkerInput disabled={props.disabled} />}
      placeholderText="기간 선택"
      showMonthYearPicker={props.type === "month" ? true : false}
      popperContainer={CalendarPortal}
    />
  );
}
export default RangeDatepicker;
