
const TableHeader = ({title}: any) =>{
  return (
    <>
       <thead>
          <tr>
            <th colSpan={2}>
              <div className="text-center">{title}</div>
            </th>
          </tr>
        </thead>
    </>
  )
}
export default TableHeader;