import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { ContractDetail, diabledOverdueFee } from "src/api/contract/contract-types";
import { BaseButton, BaseTooltip } from "src/components";
import { calculateDate, findPgCode, numberToStringWithComma, ViewYmdFormat } from "src/utils";
import BaseSection from "../../../../components/layout/BaseSection";
import OverdueFeePopup from "../modal/OverdueFeePopup";
import useContractApi from "../../hooks/useContractApi";
import PaymentStatusView from "src/pages/billing/components/PaymentStatusView";

interface BasicProps {
  detailData: any;
  textGrey: string;
  contract: ContractDetail;
  rentalSchedule: BillingDetail;
  gotoBillingScheduleDetailList: Function;
}
const BillingScheduleDetail = ({ detailData, contract, rentalSchedule, gotoBillingScheduleDetailList }: BasicProps) => {
  const { getSchedulesRounds } = useContractApi();
  const [isOverdueFeeOpen, setIsOverdueFeeOpen] = useState(false);
  const [calDate, setCalDate] = useState("");
  const [s2Rounds, setS2Rounds] = useState<any>(null);
  const findRounds = useCallback(
    async (scheduleId: string) => {
      const getRounds: any = await getSchedulesRounds(scheduleId);
      if (Array.isArray(getRounds) && getRounds.length > 0) {
        setS2Rounds(getRounds[0]);
      }
    },
    [getSchedulesRounds],
  );
  useEffect(() => {
    if (rentalSchedule && rentalSchedule.scheduleStartTime && rentalSchedule.scheduleEndTime) {
      let returnText = calculateDate(rentalSchedule?.scheduleStartTime!, rentalSchedule?.scheduleEndTime!);
      if (returnText !== "") {
        setCalDate(returnText);
      }
    }
    if (rentalSchedule && detailData?.product?.productType === "TIME_COURT") {
      findRounds(String(rentalSchedule?.scheduleId!));
    }
  }, [detailData?.product?.productType, findRounds, rentalSchedule]);
  return detailData?.product?.productType !== "TIME_COURT" ? (
    <div>
      <div className="flex-center">
        <BaseSection
          title={"빌링 기간"}
          children={
            <p>
              {moment(rentalSchedule?.scheduleStartTime).format(ViewYmdFormat.YYYY_MM_DD)} ~{" "}
              {moment(rentalSchedule?.scheduleEndTime).format(ViewYmdFormat.YYYY_MM_DD)}
              {calDate ? " (" + calDate + ")" : ""}
            </p>
          }
        />
      </div>
      <div className="flex-center">
        <BaseSection
          title={"빌링스케줄"}
          children={<BaseButton onClick={() => gotoBillingScheduleDetailList()} title="이용료 빌링/연체 현황" className="color-white" />}
        />
        <BaseSection
          titleChildren={
            <div className="flex-center font14">
              연체 요율(%)
              <BaseTooltip
                contents={""}
                tooltip={"기본 연 18% 일할 적용입니다. ‘계약체결’ 전까지 변경할 수 있습니다."}
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          }
          children={
            <>
              <BaseButton
                title="연체 요율 관리"
                className="color-white"
                disabled={diabledOverdueFee.includes(String(contract?.contractStep))}
                onClick={() => setIsOverdueFeeOpen(true)}
              />
              {isOverdueFeeOpen && (
                <OverdueFeePopup
                  isOpen={isOverdueFeeOpen}
                  overdueChargeStandard={String(rentalSchedule?.overdueChargeStandard)}
                  overdueList={rentalSchedule?.scheduleOverdueList!}
                  onClick={(editedData: any) => {
                    setIsOverdueFeeOpen(false);
                  }}
                  onClose={() => setIsOverdueFeeOpen(false)}
                  viewOnly={true}
                />
              )}
            </>
          }
        />
      </div>
      <div className="flex-center">
        <BaseSection
          title={"자동 결제/수납"}
          children={
            <div className="flex-row flex-center-start">
              <div className={rentalSchedule?.isAutoBilling ? `chip green` : `chip red`}>
                {rentalSchedule?.isAutoBilling !== undefined ? (rentalSchedule?.isAutoBilling ? "이용중" : "이용안함") : "-"}
              </div>
            </div>
          }
        />
      </div>
      <div className="flex-center">
        <BaseSection
          titleChildren={
            <div className="flex-center font14">
              정기 청구일
              <BaseTooltip
                contents={""}
                tooltip={"정기 청구일에 맞춰 이용료 납부 요청이 발송됩니다. 청구일이 주말이나 공휴일인 경우, 가장 빨리 도래하는 영업일에 청구됩니다."}
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          }
          children={
            <p>
              {rentalSchedule?.payRegularDay ? rentalSchedule?.payRegularDay + "일" : "-"}{" "}
              {rentalSchedule?.payRegularMonth === "PREV_MONTH" && "(전월 기준)"}
              {rentalSchedule?.payRegularMonth === "CURR_MONTH" && "(당월 기준)"}
              {rentalSchedule?.payRegularMonth === "NEXT_MONTH" && "(익월 기준)"}
            </p>
          }
        />
      </div>
      <div className="flex-center">
        <>
          <BaseSection title={"결제 방법"} value={rentalSchedule?.regularPgcode ? findPgCode(rentalSchedule?.regularPgcode) : "-"} />
          <BaseSection
            titleChildren={
              <div className="flex-center font14">
                납부 기한
                <BaseTooltip
                  contents={""}
                  tooltip={
                    "기본값은 14일이며, 필요에 따라 변경할 수 있습니다. 납부 기한이 주말이나 공휴일인 경우, 가장 빨리 도래하는 영업일에 청구됩니다."
                  }
                  type={"normal"}
                  className={"tooltip__trigger-icon"}
                />
              </div>
            }
            value={rentalSchedule?.payDueDays ? "청구일 기준 +" + rentalSchedule?.payDueDays + "일" : "-"}
          />
        </>
      </div>

      <div className="flex-center">
        <BaseSection title={"입금자명"} value={rentalSchedule?.accountInName ? rentalSchedule?.accountInName : "-"} />
      </div>
    </div>
  ) : (
    <div>
      <div className="flex-center">
        <BaseSection
          title={"결제 상태"}
          children={
            <div className="flex-row flex-center-start">
              <PaymentStatusView value={s2Rounds !== null ? s2Rounds.bill!.billPayStatus! : ""} />{" "}
              <BaseButton onClick={() => gotoBillingScheduleDetailList()} title="이용료 빌링/연체 현황" className="ml10 color-white" />
            </div>
          }
        />
        <BaseSection
          title={"결제 일시"}
          children={
            <p>
              {s2Rounds && s2Rounds !== null
                ? s2Rounds.receiptBundle!.lastTrDateTime
                  ? moment(s2Rounds.receiptBundle!.lastTrDateTime).format(ViewYmdFormat.FULL)
                  : "-"
                : "-"}
            </p>
          }
        />
      </div>
      <div className="flex-center">
        <BaseSection
          title={"결제 방법"}
          children={<p>{s2Rounds !== null ? (s2Rounds.bill!.pgcode === "creditcard" ? "신용카드" : "가상계좌") : "-"}</p>}
        />
        <BaseSection
          title={"결제 기한"}
          children={
            <p>{s2Rounds !== null ? (s2Rounds.bill!.billDueDate ? moment(s2Rounds.bill!.billDueDate).format(ViewYmdFormat.FULL) : "-") : "-"}</p>
          }
        />
      </div>
      <div className="flex-center">
        {s2Rounds !== null &&
          s2Rounds!.bill!.pgcode !== "creditcard" &&
          s2Rounds.bill!.paymentList?.map((data: any) => {
            return <BaseSection title={"ㄴ 부가 정보"} children={<p>{data.virtualAccount.bankName + " " + data.virtualAccount.accountNo}</p>} />;
          })}
        <BaseSection
          title={"결제 금액"}
          children={
            <p>
              {s2Rounds !== null
                ? s2Rounds.receiptBundle!.receiptTotalAmount
                  ? numberToStringWithComma(s2Rounds.receiptBundle!.receiptTotalAmount) + " 원"
                  : "-"
                : "-"}
            </p>
          }
        />
        {s2Rounds !== null && s2Rounds!.bill!.pgcode !== "creditcard" && (
          <BaseSection
            title={"계산서 작성일자"}
            children={<p>{s2Rounds.bill!.taxInvoiceIssueDate ? moment(s2Rounds.bill!.taxInvoiceIssueDate).format(ViewYmdFormat.FULL) : "-"}</p>}
          />
        )}
      </div>
    </div>
  );
};
export default BillingScheduleDetail;
