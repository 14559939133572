import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getScheduleDetail_RCB01, getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, BaseSelect, BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useApiLoading from "src/hooks/useApiLoading";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import { useToast } from "src/recoil/toast/hook";
import MntEditColumns from "../../../columns/MntColumns";
import OverdueInfo from "./OverdueInfo";
import OverdueFeePopup from "src/pages/contract/commonComponents/modal/OverdueFeePopup";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const MntBillingDetail = ({ basicInfo, callList, contractBaseInfo, stopObj }: any) => {
  const params = useParams();
  const location = useLocation();
  const { openToast } = useToast();
  const navigate = useNavigate();
  const { isApiLoading } = useApiLoading();
  const { executeAsync: getScheduleList } = useApiOperation(getScheduleDetail_RCB01);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);
  //  프로바이더 목록 api
  const { executeAsync: getProviders } = useApiOperation(getProviderList);
  const [cols, setCols] = useState<any>([]);
  const [selectedBuildingId, setSelectedBuildingId] = useState<any>("" || null);
  const [selectedScheduleId, setSelectedScheduleId] = useState<any>("" || null);
  const [selectedSchedule, setSelectedSchedule] = useState<any>({});
  const [buildingScheduleList, setBuildingScheduleList] = useState<any>([]);
  const [firstSelector, setFirstSelector] = useState<any>([]);
  const [secondSelector, setSecondSelector] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [overdueList, setOverdueList] = useState<any>([]);
  const [isOverdueFeeOpen, setIsOverdueFeeOpen] = useState(false);
  const [messageModal, setMessageModal] = useState<Modal>({
    isOpen: false,
  });
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (value) => value,
      }),
    [location],
  );
  /** 수정가능 여부 */
  const showBtn = useMemo(() => {
    let flag = true;

    if (location.pathname.indexOf("/detail/") > -1) {
      flag = false;
    }

    return flag;
  }, [location]);
  /**
   * 선택한 건물의 스케줄에 등록 된 프로바이더 목록 조회
   * ,로 구분된 스트링 값
   */
  const getProvidersName = useCallback(
    async (providerIds: string) => {
      const getPds = await getProviders({ providerId: String(providerIds) });
      // console.log("getProviders", getPds);
      return getPds.data.data.content;
    },
    [getProviders],
  );

  /**
   * 선택한 건물 스케줄의 청구서 목록을 조회
   */
  const getRounds = useCallback(
    async (buildingId: string, scheduleId: string) => {
      setSelectedScheduleId(String(scheduleId));

      const rounds = await getRoundList({ buildingId: buildingId, scheduleId: Number(scheduleId) });
      if (rounds && rounds.status >= 200 && rounds.status <= 299) {
        const sorted: any = rounds.data.data.content;

        const normal = sorted.filter((sort: any) => {
          if (sort.bill.isOverdueBill === false) {
            return sort;
          }
        });
        const overdues = sorted.filter((sort: any) => {
          if (sort.bill.isOverdueBill) {
            return sort;
          }
        });
        await setTableData(normal);
        await setOverdueList(overdues);
      }

      await setSelectedBuildingId(buildingId);
      await setSelectedScheduleId(scheduleId);
    },
    [getRoundList, setSelectedBuildingId, setSelectedScheduleId],
  );
  const getBuildingsSchedules = useCallback(
    async (buildingId: string) => {
      const params = {
        contractId: Number(contractBaseInfo!.contract!.contractId),
        buildingId: Number(buildingId),
        supplyType: "MNT",
      };
      setBuildingScheduleList([]);
      const list = await getScheduleList(params);
      console.log("find buildings schedules", list);
      setBuildingScheduleList(list.data.data.scheduleList);
      return list;
    },
    [contractBaseInfo, getScheduleList],
  );

  /**
   * 선택한 건물의 관리비 스케줄 목록 조회
   */
  const findMntSchedule = useCallback(
    async (buildingId: string) => {
      if (contractBaseInfo) {
        const list = await getBuildingsSchedules(buildingId);
        // setTableData(list.data.data.scheduleList.content);
        // console.log("list", list.data.data.scheduleList);
        if (list.data.data.scheduleList && list.data.data.scheduleList.length > 0) {
          let pvIds = list.data.data.scheduleList.map((schedule: any) => schedule.providerId).join(",");

          //받아온 목록에서 프로바이더 명 매핑처리
          const providers = await getProvidersName(pvIds);
          if (providers.length > 0) {
            let mntScheduleList = list.data.data.scheduleList;
            const second: any = [];
            mntScheduleList.forEach((msl: any) => {
              const pd = providers.find((pv: any) => msl.providerId === pv.providerId);
              const opt = {
                label: pd?.providerName + "(" + pd?.providerId + ")",
                value: msl.scheduleId,
                providerId: pd?.providerId,
              };
              second.push(opt);
            });
            setSecondSelector(second);
            let providerId = "";
            let scheduleId = "";
            //0번째로 조회
            if (queryParams.scheduleId) {
              scheduleId = String(queryParams.scheduleId);
              providerId = second.find((opt: any) => opt.value === String(queryParams.scheduleId)).providerId;
            } else {
              providerId = second[0]!.providerId;
              scheduleId = second[0]!.value;
            }

            setSelectedScheduleId(scheduleId);
            const findSchedule = list.data.data.scheduleList.find((schedule: any) => schedule.scheduleId === scheduleId);
            setSelectedSchedule(findSchedule);
            console.log("list", list.data.data);
            await getRounds(buildingId, findSchedule.scheduleId);
          }
        }
      }
    },
    [contractBaseInfo, getBuildingsSchedules, getProvidersName, queryParams, getRounds],
  );
  useEffect(() => {
    let list = _.cloneDeep(MntEditColumns);
    setCols([...list]);

    if (firstSelector.length === 0 && contractBaseInfo.product) {
      setTableData([]);
      const buildingSelectData: any = [];

      //상품 ID로 상품안의 건물 데이터
      const snapshotProduct = contractBaseInfo.product;

      snapshotProduct!.buildings!.map((building: any) => {
        // console.log("building", building);
        const option = {
          label: building.initValue + "(" + building.domainId + ")",
          value: building.domainId,
          providerId: 0,
        };
        buildingSelectData.push(option);
      });
      setFirstSelector(buildingSelectData);
      // console.log("buildingSelectData[0]!.value", buildingSelectD{ata[0]!.value);
      if ((selectedBuildingId === null || selectedBuildingId === "") && queryParams.buildingId === undefined) {
        setSelectedBuildingId(buildingSelectData[0]!.value!);
        findMntSchedule(buildingSelectData[0]!.value!);
      }
      if (queryParams.buildingId && queryParams.scheduleId) {
        setSelectedBuildingId(queryParams.buildingId);
        findMntSchedule(String(queryParams.buildingId));
        setSelectedScheduleId(queryParams.scheduleId);
      }
    }
  }, [basicInfo, contractBaseInfo, findMntSchedule, firstSelector.length, selectedBuildingId, queryParams]);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, page, prepareRow } = useTable(
    {
      columns: cols,
      data: tableData,
      initialState: { pageSize: 1000 },
      contractBaseInfo,
      callList,
      basicInfo,
      stopObj,
      buildingId: selectedBuildingId,
      scheduleId: selectedScheduleId,
      showBtn,
      getRounds,
      selectedScheduleId,
      messageModal,
      setMessageModal,
      openToast,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  // columns width 의 총 합 (table width 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article" style={{ maxWidth: "unset" }}>
            <div>
              <div className="contents-container__sub-title flex-start-center">
                <BaseSectionTitle title={"관리비 빌링 현황"} />
              </div>
              <div>
                <div className="flex-center-between mb10">
                  <div className="left-area">
                    <div className="flex-center">
                      <BaseSelect
                        value={selectedBuildingId || null}
                        setStateValue={async (buildingId: string) => {
                          await findMntSchedule(buildingId);
                        }}
                        stateOptions={firstSelector}
                        className="mr8 minmax230"
                      />
                      <BaseSelect
                        value={selectedScheduleId || null}
                        setStateValue={async (scheduleId: string) => {
                          const findSchedule = buildingScheduleList.find((schedule: any) => schedule.scheduleId === scheduleId);
                          setSelectedSchedule(findSchedule);
                          await getRounds(selectedBuildingId, scheduleId);
                        }}
                        stateOptions={secondSelector}
                        className="minmax230"
                      />

                      <BaseButton title="연체 요율 확인" className="ml8 color-white" onClick={() => setIsOverdueFeeOpen(true)} />
                      <BaseTooltip
                        contents={""}
                        tooltip={"관리처 별로 연체 요율을 확인할 수 있습니다."}
                        type={"normal"}
                        className={"ml8 tooltip__trigger-icon"}
                      />
                      {isOverdueFeeOpen && (
                        <OverdueFeePopup
                          isOpen={isOverdueFeeOpen}
                          overdueChargeStandard={String(selectedSchedule?.overdueChargeStandard)}
                          overdueList={selectedSchedule?.scheduleOverdueList!}
                          onClick={(editedData: any) => {
                            setIsOverdueFeeOpen(false);
                          }}
                          onClose={() => setIsOverdueFeeOpen(false)}
                          viewOnly={!showBtn}
                          scheduleType={"mnt"}
                        />
                      )}
                    </div>
                  </div>
                  <div className="right-area">{/* <BaseButton title="청구서 생성" /> */}</div>
                </div>
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                              {/* <div className="ic_sort"></div> */}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {page.map((row: any) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                    {page.length === 0 && (
                      <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="footer">
                    {footerGroups.map((footerGroup) => (
                      <div {...footerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {footerGroup.headers.map((column) => (
                          <div {...column.getHeaderProps()} className="base-table__td base-table-footer">
                            {column.render("Footer")}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        {basicInfo?.spaceProductType !== "TIME_COURT" && (
          <OverdueInfo
            basicInfo={basicInfo}
            callList={getRounds}
            contractBaseInfo={contractBaseInfo}
            roundList={tableData}
            overdueList={overdueList}
            stopObj={stopObj}
            editable={showBtn}
            scheduleId={selectedScheduleId}
            buildingId={selectedBuildingId}
            showBtn={showBtn}
          />
        )}
        {messageModal.isOpen && (
          <BaseModal
            isOpen={messageModal.isOpen}
            btnRightTitle={"확인"}
            onClick={() => {
              setMessageModal({ ...messageModal, isOpen: false });
            }}
            title={messageModal.message}
          ></BaseModal>
        )}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton
            title="수정"
            className="size-large ml8"
            onClick={() => {
              navigate(
                pagePath.billing.form.replace(
                  ":id",
                  String(params.id) + "?tab=mnt&buildingId=" + selectedBuildingId + "&scheduleId=" + selectedScheduleId,
                ),
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MntBillingDetail;
