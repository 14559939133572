import { Control, Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { BaseInput, BaseSelect, BaseTextarea, BaseToggle } from "src/components";
import { BasicInfoFormData, SelectOption } from "src/pages/building/building-types";

type Props = {
  control: Control<BasicInfoFormData>;
  getValues: UseFormGetValues<BasicInfoFormData>;
  setValue: UseFormSetValue<BasicInfoFormData>;
  heatingTypeOptions: Array<SelectOption>;
  isHeatingOptions: Array<SelectOption>;
  heatingFacilityTypeOptions: Array<SelectOption>;
};

// 냉난방 컴포넌트
const AirConditioning = ({
  control,
  getValues,
  setValue,
  heatingTypeOptions,
  isHeatingOptions,
  heatingFacilityTypeOptions,
}: Props) => {
  return (
    <Controller
      control={control}
      name="isUsedAirConditioning"
      render={({ field: { onChange, value, name } }) => (
        <>
          <div>
            <BaseToggle checked={value} onChange={onChange} />
          </div>
          {getValues("isUsedAirConditioning") && (
            <>
              <div className="minmax500 flex-center mb10">
                <div className="minmax50 font14 no-wrap mr10">타입</div>
                <Controller
                  control={control}
                  name="heatingType"
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <BaseSelect
                        stateOptions={heatingTypeOptions}
                        setStateValue={(value: string) => {
                          onChange(value);
                          // 초기화
                          setValue("heatingTypeDesc", "");
                        }}
                        value={value}
                        name={name}
                      />
                      {getValues("heatingType") === "DIRECT_HEATING_AIR_CONDITIONER" && (
                        <Controller
                          control={control}
                          name="heatingTypeDesc"
                          render={({ field: { onChange, value, name } }) => (
                            <BaseInput
                              placeholder="입력해 주세요"
                              className="ml10"
                              type="text"
                              onChange={onChange}
                              value={value}
                              name={name}
                            />
                          )}
                        ></Controller>
                      )}
                    </>
                  )}
                ></Controller>
              </div>
              <div className="minmax500 flex-center mb10">
                <div className="minmax50 font14 no-wrap mr10">장비</div>
                <Controller
                  control={control}
                  name="isHeating"
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <BaseSelect
                        stateOptions={isHeatingOptions}
                        setStateValue={(value: string) => {
                          onChange(value);
                          // 초기화
                          setValue("heatingFacilityType", heatingFacilityTypeOptions[0].value);
                          setValue("heatingFacilityTypeDesc", "");
                        }}
                        value={value}
                        name={name}
                      />
                      {getValues("isHeating") === "true" && (
                        <Controller
                          control={control}
                          name="heatingFacilityType"
                          render={({ field: { onChange, value, name } }) => (
                            <>
                              <BaseSelect
                                className="ml10"
                                stateOptions={heatingFacilityTypeOptions}
                                setStateValue={(value: string) => {
                                  onChange(value);
                                  // 초기화
                                  setValue("heatingFacilityTypeDesc", "");
                                }}
                                value={value}
                                name={name}
                              />
                              {getValues("heatingFacilityType") ===
                                "DIRECT_FACILITY_AIR_CONDITIONER" && (
                                <Controller
                                  control={control}
                                  name="heatingFacilityTypeDesc"
                                  render={({ field: { onChange, value, name } }) => (
                                    <BaseInput
                                      placeholder="입력해 주세요"
                                      className="ml10"
                                      type="text"
                                      onChange={onChange}
                                      value={value}
                                      name={name}
                                    />
                                  )}
                                ></Controller>
                              )}
                            </>
                          )}
                        ></Controller>
                      )}
                    </>
                  )}
                ></Controller>
              </div>
              <div className="minmax500 flex-center mb10">
                <div className="minmax50 font14 no-wrap mr10">설명</div>
                <Controller
                  control={control}
                  name="heatingFacilityTypeAddDesc"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseTextarea
                      placeholder="추가 설명을 입력해 주세요"
                      maxLength={1000}
                      onChange={onChange}
                      value={value}
                      name={name}
                    />
                  )}
                ></Controller>
              </div>
            </>
          )}
        </>
      )}
    ></Controller>
  );
};

export default AirConditioning;
