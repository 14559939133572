import { DetailTotalData } from "src/api/contract/contract-types";
import ContractorInfoDetail from "./detailComponent/ContractorInfoDetail";
import ContractorInfoEdit from "./editComponent/ContractorInfoEdit";
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
  detailFlag: boolean;
  productDetail?: any;
  saveData?: any;
  setSaveData?: any;
}
const ContractorInfo = ({ detailData, detailFlag, saveData, setSaveData, productDetail }: ContractBasicProp) => {
  if (detailFlag) {
    return <ContractorInfoDetail detailData={detailData!} />;
  } else {
    return <ContractorInfoEdit detailData={detailData!} saveData={saveData} setSaveData={setSaveData} productDetail={productDetail} />;
  }
};

export default ContractorInfo;
