import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { qsParse } from "src/utils";
import useNavigate from "./usePartnerNavigate";
import qs from "qs";

/**
 * useQueryParams hook
 * queryParams 리턴에 useParams().id 를 같이 리턴합니다.
 */
export function useQueryParams<T extends { [key: string]: any }>(defaultQueryParams?: { [key: string]: any }) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<T>({ ...defaultQueryParams, ...qsParse(location.search) } as T);

  /** queryParams을 인자로 보내면 네이게이트 실행 */
  const queryParamsNavigate = (queryParams: T) =>
    navigate(location.pathname + "?" + decodeURIComponent(qs.stringify(queryParams, { allowDots: true })));

  useEffect(() => {
    setQueryParams((prev) => ({ ...prev, ...(params.id && { id: params.id }), ...qsParse(location.search) }));
  }, [location.search]);

  // setQueryParams 리턴해서 사용x

  return { queryParams, location, queryParamsNavigate };
}
