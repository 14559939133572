import _ from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { getScheduleDetail_RCB01, getScheduleRoundNOverdueList, patchScheduleDetail_RC002 } from "src/api/billingSchedule/billingSchedule-api";
import {
  getContractMagage_RCA25,
  getContractProductSnapshotDetailPopup,
  getContract_RCA27,
  getExpenseProofRequest,
  makeQuotation_TIQ02,
  newMakeContract_RCA02,
  patchContractManage_RCA29,
  patchContract_RCA28,
  setExpenseProofRequest,
} from "src/api/contract/contract-api";
import { ContractDetail, ContractManagaDetail, DetailTotalData, NewContractSaveModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getOrgDetail } from "src/api/organization/org-api";
import { MbOrgDetail, MbOrgMedia } from "src/api/organization/org-types";
import { getProductDetailAsync } from "src/api/product/product-api";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ViewYmdFormat, YmdFormat } from "src/utils";

export const objDiff = (obj1: any, obj2: any) => {
  let diff: Array<string> = [];
  if (obj1 && obj2) {
    diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isEqual(JSON.stringify(obj1[key]), JSON.stringify(obj2[key]))) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
  }
  return diff;
};

const timeCheckNChange = (key: string, value: Date) => {
  let changeValues: any = value;

  if (key.indexOf("StartTime") > -1 || key.indexOf("Date") > -1) {
    changeValues = moment(value).format(YmdFormat.WITH_TIME_ZONE);
  }

  if (key.indexOf("EndTime") > -1) {
    let useEndTime = moment(value, "YYYY-MM-DD").format(YmdFormat.YYYY_MM_DD) + " 23:59:59.999";
    changeValues = moment(useEndTime).format(YmdFormat.WITH_TIME_ZONE);
  }

  return changeValues;
};

const arrayCmdTypeChecker = (value: Array<any>) => {
  let rtnArr: any = [];
  // console.log("recieveArr", value);
  rtnArr = value!.filter((obj: any) => obj.cmdType !== undefined);
  // console.log("rtnArr", rtnArr);
  return rtnArr;
};

export const checkDataNMake = (rtnData: any, original: any, changeData: any, makeData: any) => {
  if (changeData !== undefined && _.isEqual(original, changeData)) {
    rtnData.validationPass = false;
    rtnData.message = "변경사항이 없습니다.";
  } else {
    rtnData.validationPass = true;
    rtnData.message = "변경사항이 있습니다.";
    const diffKeys: Array<string> = objDiff(original, changeData);
    // console.log("diffKeys", diffKeys);
    // console.log("original", original, "changeData", changeData);

    if (diffKeys.length > 0) {
      for (let i = 0; i < diffKeys.length; i++) {
        const key: string = diffKeys[i];
        let getDatas;
        if (Array.isArray(changeData[key])) {
          getDatas = arrayCmdTypeChecker(changeData[key]);
        } else {
          getDatas = timeCheckNChange(key, changeData[key]);
        }
        makeData[key] = getDatas;
        // console.log("getDatas", getDatas);
      }
    }
    // console.log("makeData", makeData);
  }
  return { rtnData, makeData };
};

const useContactApi = () => {
  const { executeAsync: getContractDetailAll } = useApiOperation(getContract_RCA27);
  const { executeAsync: getContractManageDetailAll } = useApiOperation(getContractMagage_RCA25);
  const { executeAsync: getScheduleListAll } = useApiOperation(getScheduleDetail_RCB01);
  const { executeAsync: getSpaceProduct } = useApiOperation(getProductDetailAsync, { noHandleError: true });
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);
  const { executeAsync: getSnapshot } = useApiOperation(getContractProductSnapshotDetailPopup);
  const { executeAsync: getOrganization } = useApiOperation(getOrgDetail);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList, { noLoading: true });
  const { executeAsync: editSchedule } = useApiOperation(patchScheduleDetail_RC002);
  const { executeAsync: editContract } = useApiOperation(patchContract_RCA28);
  const { executeAsync: editContractManage } = useApiOperation(patchContractManage_RCA29);
  const { executeAsync: saveContractAll } = useApiOperation(newMakeContract_RCA02);
  const { executeAsync: setExpenseProofData } = useApiOperation(setExpenseProofRequest);
  const { executeAsync: getExpenseProofData } = useApiOperation(getExpenseProofRequest);
  const { executeAsync: saveQuotation } = useApiOperation(makeQuotation_TIQ02);

  const getSchedulesRounds = useCallback(
    async (scheduleId: string) => {
      const findRounds = await getRoundList({ scheduleId: Number(scheduleId) });
      if (findRounds.status > 199 && findRounds.status < 400) {
        return findRounds.data.data.content || [];
      } else {
        return [];
      }
    },
    [getRoundList],
  );

  const sendQuoTation = useCallback(
    async (quotation: any) => {
      const response = await saveQuotation(quotation);
      // console.log("response", response);
      if (response.data.data.content) {
        return response.data.data.content;
      }
    },
    [saveQuotation],
  );

  const getProductData = useCallback(
    async (productId: number) => {
      let productDetail;
      const productResponse: any = await getSpaceProduct({ productId: Number(productId) });

      if (productResponse.data.data && productResponse.data.data.content) {
        productDetail = productResponse?.data?.data?.content;
      }

      return productDetail;
    },
    [getSpaceProduct],
  );

  const getProviderData = useCallback(
    async (providerId: number) => {
      //프로바이더 정보 조회
      const response: any = await getProviderDetail({ providerId: String(providerId) });
      let provider;
      if (response.status >= 200 || response.status <= 299) {
        provider = response.data.data;
      }
      return provider;
    },
    [getProviderDetail],
  );

  const getSnapShotData = useCallback(
    async (contractId: number) => {
      let productData: any = {};
      let rentalCostList: any = [];
      const snapShotResponse: any = await getSnapshot({ id: Number(contractId) });

      const filteredProduct = snapShotResponse.data.data.content.filter((product: any) => product.domain === "product");
      const filteredBuildings = snapShotResponse.data.data.content.filter((product: any) => product.domain === "building") || [];
      const rentalCost = snapShotResponse.data.data.content.find((product: any) => product.domain === "rentalCost");

      console.log("filteredProduct :>> ", filteredProduct);

      filteredProduct.forEach((pr: any) => {
        productData[pr.attrName] = pr.attrValue;
        productData.id = pr.domainId;
      });
      if (filteredBuildings) {
        productData.buildings = filteredBuildings;
      }
      if (rentalCost) {
        rentalCostList.push({ value1: rentalCost.attrValue });
        productData.rentalCostList = rentalCostList;
      }

      return productData;
    },
    [getSnapshot],
  );

  const getContractDetailData = useCallback(
    async (contractId: string) => {
      return await getContractDetailAll({ id: Number(contractId) });
    },
    [getContractDetailAll],
  );

  const callDetails = useCallback(
    async (contractId: string) => {
      // const callProductSteps: Array<string> = [ContractStep.APPLY_RECEIVED, ContractStep.APPLY_CONFIRM, ContractStep.CONTRACT_PLAN];
      // console.log("makeDetailDatas  start");
      let makeDetailDatas: DetailTotalData = {};
      let contractDetail: ContractDetail = {};
      let contractManage: ContractManagaDetail = {};
      let scheduleList: Array<BillingDetail> = [];

      const contractDetailResponse = await getContractDetailData(contractId);

      if (contractDetailResponse.status >= 200 && contractDetailResponse.status <= 299) {
        contractDetail = { ...contractDetailResponse.data.data.contract };
        makeDetailDatas.contract = contractDetail;

        const contractManageDetailResponse: any = await getContractManageDetailAll({ id: Number(contractDetail.contractManageId) });
        if (contractManageDetailResponse.status >= 200 && contractManageDetailResponse.status <= 299) {
          contractManage = { ...contractManageDetailResponse.data.data.contractManage };
        }
        makeDetailDatas.contract = contractDetail;
        makeDetailDatas.contractManage = contractManage;

        //사업자 정보가 있는 경우 조회한다.
        if (Number(makeDetailDatas.contractManage.mbOrganizationId) > 0 && Number(makeDetailDatas.contractManage.mbOrganizationId) < 99999) {
          const response: any = await getOrganization({ id: Number(makeDetailDatas.contractManage.mbOrganizationId) });

          if (response.status >= 200 && response.status <= 299) {
            makeDetailDatas.contractOrganization = response.data.data;
          }
        }
        const findExpenseProofData = await getExpenseProofData({ contractId });
        if (findExpenseProofData.status >= 200 && findExpenseProofData.status <= 299) {
          if (findExpenseProofData.data.data.expenseProofRequest.contractId === null) {
            makeDetailDatas.expenseProofData = null;
          } else {
            makeDetailDatas.expenseProofData = findExpenseProofData.data.data.expenseProofRequest;
          }
        }
        const schedules: any = await getScheduleListAll({ contractId: Number(contractId) });
        scheduleList = scheduleList.concat(schedules.data.data.scheduleList);
        makeDetailDatas.scheduleList = scheduleList;
        makeDetailDatas.provider = await getProviderData(Number(makeDetailDatas.contractManage.providerId));

        //계약의 스냅샷 정보 조회
        makeDetailDatas.product = await getSnapShotData(Number(makeDetailDatas.contract.contractId));
      }
      console.log("makeDetailDatas", makeDetailDatas);
      return makeDetailDatas;
    },
    [getContractDetailData, getContractManageDetailAll, getExpenseProofData, getScheduleListAll, getProviderData, getSnapShotData, getOrganization],
  );

  const getOrganizationData = useCallback(
    async (mbOrganizationId: Number) => {
      if (mbOrganizationId) {
        const response: any = await getOrganization({ id: Number(mbOrganizationId) });
        if (response.status >= 200 && response.status <= 299) {
          const mbOrgDetail: MbOrgDetail = response.data.data;

          const proofs = mbOrgDetail?.mediaList!.filter((media: MbOrgMedia) => media.type === "PROOF");
          const bis = mbOrgDetail?.mediaList!.filter((media: MbOrgMedia) => media.type !== "PROOF");

          // organization?.name;

          return { organization: mbOrgDetail.organization, proofs, bis };
        }
      }
    },
    [getOrganization],
  );

  const checkOverdueList = useCallback(
    async (scheduleId: number) => {
      let checkOverdueBill = [];
      let payCheck = [];
      const res: any = await getRoundList({ scheduleId });
      if (res.status >= 200 && res.status <= 299) {
        const billRounds = res.data.data.content;
        const today = moment().format(ViewYmdFormat.YYYY_MM_DD);

        if (billRounds) {
          checkOverdueBill = billRounds.filter((round: any) => round.bill.isOverdueBill);
          payCheck = billRounds.filter(
            (round: any) =>
              round.bill.isOverdueBill === false && moment(moment(round.bill.billIssueDate).format(ViewYmdFormat.YYYY_MM_DD)).isBefore(today),
          );
        }
      }

      let pay = false;
      payCheck.forEach((bill: any) => {
        if (bill.bill.billPayStatus !== "PAYMENT_SUCCESS" && bill.bill.billPayStatus !== "PAYMENT_FREE") {
          pay = true;
        }
      });
      let overdue = false;
      checkOverdueBill.forEach((bill: any) => {
        if (bill.bill.billPayStatus !== "PAYMENT_SUCCESS" && bill.bill.billPayStatus !== "PAYMENT_FREE") {
          overdue = true;
        }
      });

      //TODO 계약일시 입력 상태인지 체크
      if (pay !== false || overdue !== false) {
        return false;
      }
      return true;
    },
    [getRoundList],
  );

  const saveContractBillData = useCallback(
    async (original: BillingDetail, changeData: BillingDetail) => {
      // console.log("_.isEqual(original, changeData)", original, changeData);
      let rtnData = { validationPass: false, message: "", response: {} };
      let makeSaveSchedule: BillingDetail = {};

      const check: any = checkDataNMake(rtnData, original, changeData, makeSaveSchedule);
      rtnData = { ...check.rtnData };
      makeSaveSchedule = check.makeData;
      // console.log("makeSaveSchedule", makeSaveSchedule);
      //API 전송
      if (rtnData.validationPass && Object.keys(makeSaveSchedule).length > 0) {
        makeSaveSchedule.scheduleId = changeData.scheduleId;
        rtnData.response = await editSchedule({ contractId: Number(original.contractId), scheduleList: [makeSaveSchedule] });
      }

      return rtnData;
    },
    [editSchedule],
  );

  const editContractData = useCallback(
    async (original: ContractDetail, changeData: ContractDetail) => {
      // console.log("_.isEqual(original, changeData)", original, changeData);
      let rtnData = { validationPass: false, message: "", response: {} };
      let makeSaveContract: ContractDetail = {};

      const check: any = checkDataNMake(rtnData, original, changeData, makeSaveContract);
      rtnData = { ...check.rtnData };
      makeSaveContract = check.makeData;

      //API 전송
      if (rtnData.validationPass && Object.keys(makeSaveContract).length > 0) {
        makeSaveContract.contractId = original.contractId;
        rtnData.response = await editContract({ id: Number(original.contractId), contract: makeSaveContract });
      }

      return rtnData;
    },
    [editContract],
  );

  const editContractManageData = useCallback(
    async (original: ContractManagaDetail, changeData: ContractManagaDetail) => {
      // console.log("_.isEqual(original, changeData)", original, changeData);
      let rtnData = { validationPass: false, message: "", response: {} };
      let makeSaveContractManage: ContractManagaDetail = {};

      const check: any = checkDataNMake(rtnData, original, changeData, makeSaveContractManage);
      rtnData = { ...check.rtnData };
      makeSaveContractManage = check.makeData;

      //API 전송
      if ((rtnData.validationPass, Object.keys(makeSaveContractManage).length > 0)) {
        makeSaveContractManage.contractManageId = original.contractManageId;
        rtnData.response = await editContractManage({ id: Number(original.contractManageId), contractManage: makeSaveContractManage });
      }

      return rtnData;
    },
    [editContractManage],
  );

  const saveFirstContractData = useCallback(
    async (saveData: NewContractSaveModel) => {
      let rtnData = { validationPass: false, message: "", response: {} };
      if (saveData) {
        rtnData.response = await saveContractAll({ saveModel: saveData });
      }
      return rtnData;
    },
    [saveContractAll],
  );

  return {
    getContractDetailData,
    callDetails,
    checkOverdueList,
    editContractData,
    saveContractBillData,
    editContractManageData,
    getOrganizationData,
    getProviderData,
    getSnapShotData,
    getProductData,
    saveFirstContractData,
    getContractManageDetailAll,
    sendQuoTation,
    getSchedulesRounds,
  };
};
export default useContactApi;
