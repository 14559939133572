import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "../../pagePath.json";
const RootPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PagePath.login);
  }, []);
  return <div></div>;
};

export default RootPage;
