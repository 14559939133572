import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { HeaderGroup, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { Order } from "src/api/public-types";
import { WorkOrderListModel } from "src/api/work-order/workorder-types";

type Props = {
  data: WorkOrderListModel[];
  setOrders: Function;
  orders?: Array<Order>;
  workOrderId?: string;
  disabledSortHeaders?: Array<string>; // 정렬이 불가능한 컬럼이 있을 경우 header accesor 값 배열로 전달
  columns: any;
};

const WorkOrderGroupTable = ({ data, columns, workOrderId, disabledSortHeaders, orders, setOrders }: Props) => {
  const locatoin = useLocation();
  const rowRef = useRef<HTMLDivElement>(null); //포커스를 위한 ref

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowsById } = useTable(
    {
      columns: columns as any,
      data,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  // 정렬이 불가능한 header 인지 여부
  const isDisabledSortHeader = useCallback(
    (header: HeaderGroup): boolean => {
      let isDisabledSortHeader = false;
      if (disabledSortHeaders && disabledSortHeaders.length > 0) {
        isDisabledSortHeader = disabledSortHeaders.includes(header.id);
      }
      return isDisabledSortHeader;
    },
    [disabledSortHeaders],
  );

  // header sort component
  const renderHeaderSortComponent = (header: HeaderGroup) => {
    if (isDisabledSortHeader(header)) return null;

    if (orders && orders && orders.length > 0) {
      const finedHeader = orders.find((order: { property: string; direction: string }) => order.property === header.id);
      if (finedHeader) {
        if (finedHeader.direction === "DESC") {
          return <div className="ic_sort down"></div>;
        } else if (finedHeader.direction === "ASC") {
          return <div className="ic_sort up"></div>;
        }
      }
    }
    return <div className="ic_sort"></div>;
  };

  // 정렬 버튼 클릭
  const clickHeaderSort = (header: HeaderGroup) => {
    // 새로운 정렬
    let newOrders = [...(orders || [])];

    if (orders && orders && orders.length > 0) {
      // 현재 정렬되어있는 프로퍼티일 경우 존재함
      const finedHeader = orders.find((order: { property: string; direction: string }) => order.property === header.id);
      if (finedHeader) {
        // 정렬되어있는 프로퍼티를 클릭했을 경우, 해당 프로퍼티 삭제
        // newOrders = filter(orders, function (currentObject) {
        //   return currentObject.property !== finedHeader.property;
        // });
        newOrders = orders.filter((currentOrder: Order) => currentOrder.property !== finedHeader.property);
      }

      // 해당 프로퍼티 정렬 다시 재할당
      let newDirection = null;

      console.log("finedHeader", finedHeader);

      if (finedHeader?.direction) {
        // 기존 정렬 방향이 있으면
        if (finedHeader?.direction === "DESC") {
          // 내림차순이면 오름차순으로 변경
          newDirection = "ASC";
        } else {
          // 오름차순이면 정렬 해제이기 때문에 기존 정렬삭제되서 처리할게 없음
          // newDirection = "DESC";
        }
      } else {
        // 기존 정렬 방향이 없으면 내림차순으로 변경
        newDirection = "DESC";
      }
      console.log("newDirection", newDirection);
      if (newDirection) {
        // newOrders = [...newOrders, { property: header.id, direction: newDirection }]; // 멀티 정렬시
        newOrders = [{ property: header.id, direction: newDirection }]; // 단일 정렬시
      }
    } else {
      // 기존에 정렬되어 있는게 없으면 클릭한 프로퍼티를 내림차순으로 변경
      newOrders = [{ property: header.id, direction: "DESC" }];
    }
    setOrders(newOrders);
  };

  useEffect(() => {
    if (rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: "auto", block: "nearest" }); // 스크롤 부드럽게 처리
    }
  }, [data]);

  return (
    <section className="inner-tab-table mb20 contents-container__1200">
      <div {...getTableProps()} className="base-table sticky">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    // {...header.getHeaderProps()}
                    {...header.getHeaderProps(header.id !== "rowChecked" ? header.getSortByToggleProps() : undefined)}
                    className="base-table__th"
                    onClick={() => {
                      if (!isDisabledSortHeader(header)) {
                        clickHeaderSort(header);
                      }
                    }}
                  >
                    {header.render("Header")}
                    <span>{renderHeaderSortComponent(header)}</span>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body max-height500">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className={`base-table__tr`}
                ref={(workOrderId === row.original.id && locatoin.pathname.includes("detail") && rowRef) || null}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <div {...cell.getCellProps()} className={`base-table__td ${workOrderId === String(row.original.id) ? "bg-yellow" : ""}`}>
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {rows.length === 0 && (
          <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
            <div className="base-table__td w-100 text-center">
              <div className="w-100">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default WorkOrderGroupTable;
