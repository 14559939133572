import { DefaultOptions, useQuery } from "@tanstack/react-query";

import { getReservationPolicyClosedPeriodAsync, ReservationPolicyClosedPeriodListParams } from "src/api/product/product-api";
import { axiosInstance } from "src/utils";

/**
 * //예약 가능 기간
 * @param param defaultOptions:DefaultOptions["queries"]
 * @returns ReservationPolicyClosedPeriod[]
 */
const useGetReservationPolicyClosedPeriod = ({
  reservationPolicyClosedPeriodListParams,
  defaultOptions,
}: {
  reservationPolicyClosedPeriodListParams: ReservationPolicyClosedPeriodListParams;
  defaultOptions?: DefaultOptions["queries"];
}) => {
  const _defaultOptions = defaultOptions as unknown as Object;
  const query = useQuery({
    queryKey: ["getReservationPolicyClosedPeriodAsync", reservationPolicyClosedPeriodListParams],
    queryFn: () =>
      getReservationPolicyClosedPeriodAsync(axiosInstance, reservationPolicyClosedPeriodListParams).then((res) => res?.data?.data?.content),

    enabled: !!reservationPolicyClosedPeriodListParams,
    // retryOnMount: false,
    // staleTime: 1000,
    // refetchOnMount: false,
    ...(_defaultOptions && _defaultOptions),
  });

  return { ...query };
};

export default useGetReservationPolicyClosedPeriod;
