import { useEffect, useState } from 'react';

// ref를 넘기면 해당 ref의 height 를 리턴합니다.

function useDynamicHeight(ref:any) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        const newHeight = ref.current.offsetHeight;
        setHeight(newHeight);
      }
    };

    // 초기 높이 설정
    updateHeight();

    // 컴포넌트가 마운트되었을 때와 리렌더링될 때마다 이벤트 리스너 추가
    window.addEventListener('resize', updateHeight);

      // DOM 변경을 감지하는 MutationObserver 추가
      const observer = new MutationObserver(updateHeight);
      observer.observe(ref.current, { subtree: true, childList: true });
  
      // 컴포넌트가 언마운트되면 이벤트 리스너와 Observer를 제거
      return () => {
        window.removeEventListener('resize', updateHeight);
        observer.disconnect();
      };
  }, [ref]);
  return height;
}

export default useDynamicHeight;