import { atom } from "recoil";
import { PaPartnerMember } from "src/types/partner";

/**
 * 파트너 정보를 관리하는 atom입니다.
 */
export const partnerMemberState = atom<PaPartnerMember>({
  key: "partnerMemberState",
  default: undefined,
});
