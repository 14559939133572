import moment from "moment";
import { reservationColumns } from "./list/columns";

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

// 공용공간 예약 status 한글 변환
export const getStatusText = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

// 공용공간 예약 status 한글 변환
export const getReservationStatusDetail = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

// 예약 경로 타입
// export const ReservationStatus = {
//   ENABLED: "RESERVATION_ENABLED", // 공개
//   CANCELED: "RESERVATION_CANCELED", // 관리자가 취소
//   EXPIRED: "RESERVATION_EXPIRED", // 예약취소
//   INCOMPLETED_FACILITY: "RESERVATION_INCOMPLETED_FACILITY", // 불완전예약-회의실사용불가
//   INCOMPLETED_QUOTA_DAYLY: "RESERVATION_INCOMPLETED_QUOTA_DAYLY", //불완전예약-일사용시간초과
//   INCOMPLETED_QUOTA_MONTHLY: "RESERVATION_INCOMPLETED_QUOTA_MONTHLY", //불완전예약-월사용시간초과
// } as const;

export const ReservationState = {
  ACCEPTED: "ACCEPTED", // 접수
  ACKNOWLEDGED: "ACKNOWLEDGED", // 점유
  INCOMPLETE: "INCOMPLETE", // 불완전
  CANCELLED: "CANCELLED", // 취소
} as const;

export type UnionReservationStatus = (typeof ReservationState)[keyof typeof ReservationState];

// 공간예약 목록 조회하는 모든 상태
export const SEARCH_STATUSES = [ReservationState.ACCEPTED, ReservationState.ACKNOWLEDGED, ReservationState.INCOMPLETE, ReservationState.CANCELLED];

export type ReservationExternalTypes = "TAAP" | "GC" | "OC";

// 예약 경로 타입
export const ExternalServiceType = {
  EXTERNALSERVICE_UNRECOGNIZED: "EXTERNALSERVICE_UNRECOGNIZED", //정의되지 않은 타입
  TAAP: "TAAP",
  GC: "GC", // Google Calendar
  OC: "OC", //Outlook Calendar
} as const;

export type UnionExternalServiceType = (typeof ExternalServiceType)[keyof typeof ExternalServiceType];

//  예약초대 상태
export const ReservationAttendeeResponseStatus = {
  NONE: 'NONE', //응답없음
  DECLINED: 'DECLINED', //거부
  TENTATIVE: 'TENTATIVE', // 임시적 수락
  ACCEPTED: 'ACCEPTED' //수락
};


// 공용공간예약 목록 엑셀 다운로드 헤더
export const reservationHeaders = [
  { label: "id", key: "id" },
  { label: "group id", key: "reservationGroupId" },
  { label: "상품 id", key: "productId" },
  { label: "신청/계약 번호", key: "contractApplyNumber" },
  { label: "예약경로", key: "externalServiceType" },
  { label: "건물명", key: "buildingName" },
  { label: "위치 (층)", key: "buildingCommonFacilityFloorNum" },
  { label: "위치 (지상여부)", key: "buildingCommonFacilityIsGround" },
  { label: "위치 (id)", key: "buildingCommonFacilityId" },
  { label: "위치 (LocationCode)", key: "buildingCommonFacilityLocationCode" },
  { label: "공간타입", key: "commonFacilityType" },
  { label: "공간명", key: "buildingCommonFacilityName" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "memberNo" },
  { label: "예약자 (이메일)", key: "memberEmail" },
  { label: "예약 제목", key: "summary" },
  { label: "생성일", key: "createdDate" },
  { label: "상태", key: "status" },
];

// 공용공간예약 소진시간 엑셀 다운로드 헤더
export const reservationUsageHeaders = [
  { label: "id", key: "reservationId" },
  { label: "신청/계약 번호", key: "contract.applyNumber" },
  { label: "건물명", key: "facility.building.name" },
  { label: "공간타입", key: "facility.type" },
  { label: "공간id", key: "facility.id" },
  { label: "공간명", key: "facility.name" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "소진시간", key: "duration" },
  { label: "예약자 (회원번호)", key: "organizer.memberNo" },
  { label: "예약자 (휴대폰번호)", key: "organizer.phoneNumber" },
  { label: "예약자 (이메일)", key: "organizer.userEmail" },
];
