import { useCallback, useState } from "react";
import {
  cancelEsign_RCS23,
  confirmEsign_RCS21,
  getEsignonDetail_RCS16,
  getEsignonList_RCS30,
  getEsignonTemplateDetail_RCS11,
  getEsignonTemplateList_RCS10,
  getEsingMember,
  getWriteDataPreview_RCS22,
  partnerEsign_RCS02,
} from "src/api/contract/contract-api";
import { TemplateInfo } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
const rtn: any = { validationPass: false, message: "", response: {} };
const useEsignApi = () => {
  const { executeAsync: getEsignList } = useApiOperation(getEsignonList_RCS30);
  const { executeAsync: getEsignDetail } = useApiOperation(getEsignonDetail_RCS16, { noLoading: true });

  const { executeAsync: getEsignTemplateList } = useApiOperation(getEsignonTemplateList_RCS10);
  const { executeAsync: getEsignTemplateDetail } = useApiOperation(getEsignonTemplateDetail_RCS11, { noLoading: true });
  const { executeAsync: getEsignMemeberInfo } = useApiOperation(getEsingMember);
  const { executeAsync: getWriteDataPreview } = useApiOperation(getWriteDataPreview_RCS22);
  const { executeAsync: confirmSign } = useApiOperation(confirmEsign_RCS21);
  const { executeAsync: cancelSign } = useApiOperation(cancelEsign_RCS23);
  const { executeAsync: partnerSign } = useApiOperation(partnerEsign_RCS02);

  const getDetail = useCallback(
    async (contractId: number, workflowId: number) => {
      const sendData = { contractId: Number(contractId)!, workflowId: Number(workflowId)! };
      const response2 = await getEsignDetail(sendData!);
      if (response2.data.data.workflowInfo) {
        return response2.data.data.workflowInfo;
      }
      return {};
    },
    [getEsignDetail],
  );
  const getList = useCallback(
    async (contractId: number) => {
      const response = await getEsignList({ contractId });
      // console.log("getList", response);
      if (response.data.data.contractESign) {
        return response.data.data.contractESign;
      }

      return response;
    },
    [getDetail, getEsignList],
  );

  const getTemplateDetail = useCallback(
    async (contractId: number, templateId: number) => {
      const sendData = { contractId: Number(contractId)!, templateId: Number(templateId)! };
      const response2 = await getEsignTemplateDetail(sendData!);
      if (response2.data.data.templateInfo) {
        return response2.data.data.templateInfo;
      }
      return {};
    },
    [getEsignTemplateDetail],
  );
  const getTemplateList = useCallback(
    async (contractId: number) => {
      let returnArr: Array<TemplateInfo> = [];
      const response = await getEsignTemplateList({ contractId });

      if (response.data.data.templateList) {
        returnArr = response.data.data.templateList.template;
      }

      return returnArr;
    },
    [getEsignTemplateList],
  );

  const getMemberInfo = useCallback(async () => {
    return await getEsignMemeberInfo();
  }, [getEsignMemeberInfo]);

  const getWritedPreviewData = useCallback(
    async (params: any) => {
      const response: any = await getWriteDataPreview(params);
      if (response.data.data.previewWorkflow) {
        return response.data.data.previewWorkflow.previewUrl;
      }
    },
    [getWriteDataPreview],
  );

  const confirmEsignData = useCallback(
    async (params: any) => {
      const response: any = await confirmSign(params);
      if (response.data.data) {
        return response.data.data.startWorkflow;
      }
    },
    [confirmSign],
  );

  const cancelEsignData = useCallback(
    async (params: any) => {
      return await cancelSign(params);
    },
    [cancelSign],
  );

  const getPartnerEsignData = useCallback(async () => {
    return await partnerSign();
  }, [partnerSign]);

  return {
    getList,
    getDetail,
    getTemplateDetail,
    getTemplateList,
    getMemberInfo,
    getWritedPreviewData,
    confirmEsignData,
    cancelEsignData,
    getPartnerEsignData,
  };
};
export default useEsignApi;
