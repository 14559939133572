interface Prop {
  className?: string;
  stepList: string[];
  selectedStepIndex: number;
}
// 사용 예시
// const [step, setStep] = useState(0);
// ...
// <BaseStep stepList={["서식선택", "문서 설정", "미리보기", "확인 및 전송"]} selectedStepIndex={step}/>

export const BaseStep = ({ className, stepList, selectedStepIndex }: Prop) => {
  return (
    <div className={`base-step ${className ? className : ""}`}>
      {stepList.map((step: string, index) => (
        <div key={step + index} className={`base-step__step ${selectedStepIndex >= index ? "--active" : ""} ${selectedStepIndex > index ? '--pass' : ''}`}>
          <div className="base-step__step-circle">{index + 1}</div>
          <p className="base-step__step-name">{step}</p>
        </div>
      ))}
    </div>
  );
};
