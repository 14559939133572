import { useEffect, useMemo, useState } from "react";
import { useTable, useBlockLayout, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect } from "react-table";
import { useSticky } from "react-table-sticky";
import { BaseInput, BaseTooltip } from "src/components";
import { numberToStringWithComma } from "src/utils";
const totalCols: any = [
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center font14">
          공급가 총합
          <BaseTooltip contents={""} tooltip={"항목별 공금가의 총 합입니다."} type={"normal"} className={"tooltip__trigger-icon"} />
        </div>
      );
    },
    accessor: "supplyTotal",

    width: 305,
    Cell: ({ value }: any) => {
      return (
        <div className="flex-row flex-center-start">
          <BaseInput type="text" className="text-right" value={numberToStringWithComma(value)} disabled={true} />
          <p className="ml10">원</p>
        </div>
      );
    },
  },
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center font14">
          부가세 총합
          <BaseTooltip contents={""} tooltip={"항목별 부가세의 총 합입니다."} type={"normal"} className={"tooltip__trigger-icon"} />
        </div>
      );
    },
    accessor: "taxTotal",

    width: 305,
    Cell: ({ value, row }: any) => {
      return (
        <div className="flex-row flex-center-start">
          <BaseInput type="text" className="text-right" value={numberToStringWithComma(value)} disabled={true} />
          <p className="ml10">원</p>
        </div>
      );
    },
  },
  {
    Header: ({ showBtn, data, setDetailList, bill }: any) => {
      return (
        <div className="flex-center font14">
          소계 총합
          <BaseTooltip contents={""} tooltip={"항목별 소계의 총 합입니다."} type={"normal"} className={"tooltip__trigger-icon"} />
        </div>
      );
    },
    accessor: "priceTotal",

    width: 310,
    Cell: ({ value, row }: any) => {
      return (
        <div className="flex-row flex-center-start">
          <BaseInput type="text" className="text-right" value={numberToStringWithComma(value)} disabled={true} />
          <p className="ml10">원</p>
        </div>
      );
    },
  },
];

const BillTotalList = ({ supplyTotal, taxTotal, priceTotal }: any) => {
  const [list, setList] = useState<Array<any>>([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: totalCols,
      data: list,
      initialState: { pageSize: 1000 },
      supplyTotal,
      taxTotal,
      priceTotal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  useEffect(() => {
    const total = {
      supplyTotal: supplyTotal,
      taxTotal: taxTotal,
      priceTotal: priceTotal,
    };
    setList([total]);
  }, [supplyTotal, taxTotal, priceTotal]);

  return (
    <>
      {list !== undefined && (
        <div {...getTableProps()} className="base-table sticky mb30">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                      {/* <div className="ic_sort"></div> */}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {rows.length === 0 && (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">{<span>데이터가 없습니다.</span>}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default BillTotalList;
