import { useEffect, useMemo, useState } from "react";
import { getContractManage } from "src/api/contract/contract-api";
import { ContractManage } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { getContractVisitors } from "src/api/visitor/visitor-api";
import { ContractVisitor } from "src/api/visitor/visitor-types";
import { InvitationInfo, VisitorInfo } from "../visitor-types";

function useVisitor({ visitApplyNumber, contractApplyNumber }: { visitApplyNumber?: string; contractApplyNumber?: string }) {
  const [visitors, setVisitors] = useState<ContractVisitor[]>();
  const [product, setProduct] = useState<ProductModel>();
  const [contractManage, setContractManage] = useState<ContractManage>();

  const COURT_BASE_URL = process.env.REACT_APP_COURT_BASSEURL;

  const { executeAsync: getContractVisitorsAsync } = useApiOperation(getContractVisitors);
  const { executeAsync: getContractManageAsync } = useApiOperation(getContractManage);
  const { executeAsync: getProductAsync } = useApiOperation(getProductDetailAsync);

  const undeletedVisitors = useMemo(
    () => visitors?.filter((visitor) => !visitor.isDeleted).sort((a, b) => a.contractVisitorId - b.contractVisitorId),
    [visitors],
  );

  const LeadVisitor = useMemo(() => undeletedVisitors?.[0], [undeletedVisitors]);

  const primaryBuildingAddress = useMemo(() => {
    if (!product) return "";
    if (!product.buildingList) return "";

    const building = product.buildingList.filter((building: any) => {
      return building.buildingFloorList?.filter((buildingRoomList: any) => {
        return buildingRoomList.buildingRoomList?.filter((buildingRoom: any) => {
          return String(buildingRoom.isPrimary) === "true";
        });
      });
    });

    const primaryBuilding: any = building[0];

    if (!primaryBuilding.addressList) return;

    const primaryAddress = primaryBuilding.addressList[0];

    const { zonecode, address, addressDetail } = primaryAddress;

    return `(${zonecode}) ${address} ${addressDetail}`;
  }, [product]);

  const invitationInfo: InvitationInfo = useMemo(() => {
    return {
      productId: String(product?.id || ""),
      productName: contractManage?.spaceProductName || "",
      buildingAddress: primaryBuildingAddress || "",
      contractId: contractManage?.contractId || "",
      contractManageId: contractManage?.contractManageId || "",
      contractApplyNumber: contractManage?.contractApplyNumber || "",

      visitStartTime: LeadVisitor?.visitStartTime || "",
      visitEndTime: LeadVisitor?.visitEndTime || "",
      meetingPlace: LeadVisitor?.meetingPlace || "",
      reservationId: LeadVisitor?.reservationId ? String(LeadVisitor?.reservationId) : "",
      inviteeMemberId: LeadVisitor?.inviteeMemberId ? String(LeadVisitor?.inviteeMemberId) : "",
      inviteeMemberNo: LeadVisitor?.inviteeMemberNo ? String(LeadVisitor?.inviteeMemberNo) : "",
      createdDate: LeadVisitor?.createdDate || "",
      createdBy: LeadVisitor?.createdBy || "",
      modifiedDate: LeadVisitor?.modifiedDate || "",
      modifiedBy: LeadVisitor?.modifiedBy || "",
      visitStatus: LeadVisitor?.visitStatus || "",
      visitPurpose: LeadVisitor?.visitPurpose || "",
      visitType: LeadVisitor?.visitType || "",
    };
  }, [product, contractManage, LeadVisitor]);

  const visitorInfoList: VisitorInfo[] = useMemo(() => {
    if (undeletedVisitors === undefined) return [];

    return undeletedVisitors.map<VisitorInfo>((visitor) => {
      return {
        contractVisitorId: visitor.contractVisitorId,
        name: visitor.visitorName,
        phoneNumber: visitor.visitorMobileNumber,
        visitorEmail: visitor.visitorEmail,
        visitorEmailLang: visitor.visitorEmailLang,
        carNumber: visitor.visitorCarNumber,
        visitApplyNumber: visitor?.visitApplyNumber || "",
        QRLink: `${COURT_BASE_URL}/front/court/visitor/detail/${visitor.visitApplyNumber}${visitor.contractVisitorId}`,
        inviteTime: visitor.visitStartTime,
        createdBy: visitor.createdBy,
        isDeleted: visitor.isDeleted,
        createdAt: visitor.createdDate,
        contractManageId: contractManage?.contractManageId || "",
        contractApplyNumber: contractManage?.contractApplyNumber || "",
        preDiscountId: visitor?.preDiscountId,
      };
    });
  }, [COURT_BASE_URL, contractManage, undeletedVisitors]);

  const fetchGetContractVisitors = async (visitApplyNumber: string) => {
    const { data } = await getContractVisitorsAsync({ visitApplyNumber: visitApplyNumber });

    const _visitors = data.data.contractVisitor.map((visitor) => {
      return {
        ...visitor,
        isDeleted: visitor.isDeleted === "true" ? true : false,
      };
    });

    return _visitors;
  };

  const fetchGetContractManageAsync = async (contractApplyNumber: string) => {
    const { data } = await getContractManageAsync({ contractApplyNumber: contractApplyNumber });
    return data.data;
  };

  const fetchGetProductAsync = async (productId: number) => {
    const { data } = await getProductAsync({ productId });
    return data.data.content;
  };

  useEffect(() => {
    (async () => {
      if (visitApplyNumber) {
        const visitors = await fetchGetContractVisitors(visitApplyNumber);
        // console.log("visitors", visitors);
        setVisitors(visitors);
      }

      if (contractApplyNumber) {
        const { contractId, contractManage }: any = await fetchGetContractManageAsync(contractApplyNumber);
        contractManage.contractId = String(contractId);
        // console.log("contractManage", contractManage);
        setContractManage(contractManage);

        const product = await fetchGetProductAsync(Number(contractManage.spaceProductId));
        setProduct(product);
      }
    })();
  }, [visitApplyNumber, contractApplyNumber]);

  return {
    visitors,
    product,
    contractManage,
    invitationInfo,
    visitorInfoList,
  };
}
export default useVisitor;
