import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseButton, BaseInput, BaseModal, BaseSelect } from "src/components";
import { floorHoTypeOptions, Modal, Property, SelectOption } from "src/pages/building/building-types";

type Props = {
  property?: Property;
  saveProperty: Function;
  setProperty: Function;
  properties?: Array<Property>;
  rangeFloorOptions: Array<SelectOption>;
};

// 층 저장 테이블 컴포넌트
const SaveFloor = ({ property, setProperty, saveProperty, properties, rangeFloorOptions }: Props) => {
  // useForm defaultValues
  const defaultValues: Property = useMemo(() => {
    return {
      type: "FLOOR",
      id: undefined,
      floor: "1",
      floorName: "",
      hoId: undefined,
      ho: 0,
      hoName: "",
      dedicatedArea: undefined,
      dedicatedAreaPyeong: undefined,
      contractArea: undefined,
      contractAreaPyeong: undefined,
      rent: undefined,
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<Property>({
    defaultValues,
  });

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    // 등록일
    register("floor", {
      required: "층은 필수입력 항목 입니다.",
      validate: {
        // 중복인 층을 등록하는지 확인
        isExistFloor: (v) => {
          if (!!getValues("id")) return true; // 수정 모드에서는 중복체크 하지 않는다.
          let isExist = false;
          if (properties && properties.length > 0) {
            isExist = !!properties.find((p: Property) => p.floor === v);
          }
          return !isExist || "이미 존재하는 층 입니다.";
        },
      },
    });

    register("floorName", { required: { value: true, message: "층 명칭은 필수입력 항목 입니다." } });
  }, [register, properties, property, getValues]);

  // 수정시 api 에서 받아온 property 정보로 setValue 처리
  useEffect(() => {
    if (property) {
      Object.entries(property).forEach(([name, value]: any) => {
        if (name === "floor") {
          // 규모 범위 안에 존재하지 않는 층이면 null 로 바인딩되서 선택되어있지 않게 처리
          const isFindFloorOption = !!rangeFloorOptions.find((option: SelectOption) => option.value === value);
          setValue(name, isFindFloorOption ? value : null);
        } else {
          setValue(name, value);
        }
      });
    }
  }, [setValue, property, rangeFloorOptions]);

  useEffect(() => {
    // 수정 버튼으로 propterty 변경시 validation error clear
    clearErrors();
  }, [property, clearErrors]);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });

    if (!confirmModal?.payload) throw Error("confirmModal?.payload is not found.");

    await saveProperty(confirmModal.payload);
  }, [confirmModal, saveProperty]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: any, e?: any) => {
    e.preventDefault();
    setConfirmModal({ title: "저장하시겠습니까?", isOpen: true, payload: data });
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // 취소 버튼클릭시 층 form 리셋
  const resetForm = () => {
    setProperty({ type: "FLOOR" });

    clearErrors();
    for (const [name, value] of Object.entries(defaultValues)) {
      setValue(name as keyof Property, value);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <table className={errors && Object.keys(errors).length > 0 ? "inner-table" : "inner-table mb20"} width="1200">
          <thead>
            <tr>
              <th>
                <div>구분</div>
              </th>
              <th>
                <div>층</div>
              </th>
              <th>
                <div>층 명칭</div>
              </th>
              <th>
                <div className="minmax100"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="100px ">
                <BaseSelect
                  className="flex-center"
                  value={property?.type}
                  stateOptions={floorHoTypeOptions}
                  setStateValue={(type: string) => {
                    setProperty({ type });
                  }}
                />
              </td>
              <td width="500px">
                <Controller
                  control={control}
                  name="floor"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      value={value}
                      name={name}
                      stateOptions={rangeFloorOptions}
                      setStateValue={onChange}
                      isDisabled={!!property?.id} // 수정이면 disabled
                      className="flex-center"
                    />
                  )}
                ></Controller>
              </td>
              <td width="540">
                <Controller
                  control={control}
                  name="floorName"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseInput type="text" name={name} value={value} onChange={onChange} className="flex-center" />
                  )}
                ></Controller>
              </td>
              <td>
                <div className="flex-center">
                  <BaseButton type="submit" title="저장" className="mx2" />
                  <BaseButton title="취소" className="color-white" onClick={() => resetForm()} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {errors && Object.keys(errors).length > 0 && <p className="validation-text mb20">{Object.entries(errors)[0][1]?.message}</p>}
      </form>
      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        title={confirmModal.title}
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        {/* <p>{confirmModal.message}</p> */}
      </BaseModal>
    </>
  );
};

export default SaveFloor;
