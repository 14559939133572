import { DefaultOptions, useQuery } from "@tanstack/react-query";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityListParams } from "src/api/building/building-types";

import { axiosInstance } from "src/utils";

/**
 *
 * @param param defaultOptions:DefaultOptions["queries"]
 * @returns ProductModel
 */
const useGetCommonFacilityList = ({
  commonFacilityListParams,
  defaultOptions,
}: {
  commonFacilityListParams?: CommonFacilityListParams;
  defaultOptions?: DefaultOptions["queries"];
}) => {
  const _defaultOptions = defaultOptions as unknown as Object;
  const query = useQuery({
    queryKey: ["getCommonFacilityListAsync", commonFacilityListParams],
    queryFn: async () =>
      commonFacilityListParams ? (await getCommonFacilityListAsync(axiosInstance, commonFacilityListParams))?.data?.data?.content : undefined,
    enabled: !!commonFacilityListParams,
    retryOnMount: false,
    staleTime: 5000,
    refetchOnMount: false,
    ...(_defaultOptions && _defaultOptions),
  });

  return { ...query };
};

export default useGetCommonFacilityList;
