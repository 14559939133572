import { atom } from "recoil";

interface ModalObserver {
  id: number;
  handler: {
    [key: string]: () => void;
  };
}

/**
 * 모달 옵저버
 * 모달에서 실행할 함수를 관리하는 atom입니다.
 */
export const modalObserverState = atom<ModalObserver[]>({
  key: "modalObserverState",
  default: [],
});
