import { useAuth0 } from "@auth0/auth0-react";
import React, { ComponentType, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AuthInitializer from "src/components/AuthInitializer";
import ParnterInitializer from "src/components/ParnterInitializer";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { partnersState } from "src/recoil/partners/atom";

const ReturnToLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    localStorage.setItem("returnTo", JSON.stringify(location.pathname + location.search));
    navigate(PagePath.login);
  }, []);
  return <></>;
};

const ReturnToInit = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(PagePath.init);
  }, []);
  return <></>;
};

interface ProtectedRouteProps {
  component: ComponentType<any>;
  isWithPartner?: boolean;
}

/* 
    인증이 필요한 라우트 가드입니다.
    파트너 선택 여부에따라 라우트를 분기합니다.
    사용자가 인증되지 않은 경우 로그인 페이지로 리디렉션됩니다.
    사용자가 인증되었지만 파트너가 선택되지 않은 경우 파트너 선택 페이지로 리디렉션됩니다.
    사용자가 인증되고 파트너가 선택된 경우 자식 컴포넌트가 렌더링됩니다.
*/
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Element, isWithPartner }) => {
  const { isAuthenticated } = useAuth0();
  const globalPartnerList = useRecoilValue(partnersState);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
  }, []);

  if (isFirstRender.current) return <></>;
  if (!isAuthenticated) return <ReturnToLogin />;
  if (isWithPartner && !globalPartnerList) return <></>;

  if (isWithPartner) {
    return (
      <AuthInitializer>
        <ParnterInitializer>
          <Element />
        </ParnterInitializer>
      </AuthInitializer>
    );
  }

  return (
    <AuthInitializer>
      <Element />
    </AuthInitializer>
  );
};
