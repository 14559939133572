import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { BaseInput } from "src/components";

type Props = {
  filter: string;
  setFilter: (value: string) => void;
  placeHolder?: string;
};

export default function GlobalFilter({ filter, setFilter, placeHolder }: Props) {
  const [value, setValue] = useState(filter);

  const onSearchList = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  });

  return (
    <div className="minmax260">
      <BaseInput
        type="text"
        placeholder={placeHolder ? placeHolder : ""}
        onSearchClick={() => onSearchList(value)}
        value={value || ""}
        onChange={(value: string) => {
          setValue(value);
        }}
        onKeyUp={() => onSearchList(value)}
      />
    </div>
  );
}
