import { useCallback, useEffect, useState } from "react";
import { getAccessGroupAsync } from "src/api/access/ac-api";
import { AccessGroupModel, AccessLevelModel, AccessUserModel } from "src/api/access/ac-types";
import { useApiOperation } from "src/api/hooks";
import { BaseModal } from "src/components";

type Props = {
  accessGroupId: number;
  apiServerId: number;
  showModal: boolean;
  setShowModal: Function;
};

const AccessGroupDetailPopup = ({ accessGroupId, apiServerId, showModal, setShowModal }: Props) => {
  const [accessGroup, setAccessGroup] = useState<AccessGroupModel>();

  // 액세스그룹 상세 조회
  const { executeAsync: getAccessGroup } = useApiOperation(getAccessGroupAsync);
  const getAccessGroupDetail = useCallback(
    async (accessGroupId: number, apiServerId: number) => {
      const { data } = await getAccessGroup({ accessGroupId, apiServerId });
      setAccessGroup(data?.data.accessGroup || null);
    },
    [getAccessGroup],
  );

  useEffect(() => {
    if (showModal) {
      getAccessGroupDetail(accessGroupId, apiServerId);
    }
  }, [accessGroupId, apiServerId, getAccessGroup, getAccessGroupDetail, showModal]);

  return (
    <div>
      <BaseModal
        className="dialog-modal small-size"
        isOpen={showModal}
        btnRightTitle="확인"
        onClick={() => {
          setShowModal(false);
        }}
      >
        <>
          <div className="modal-title">
            <div className="left-area">
              <span>출입그룹 상세정보</span>
            </div>
            <div className="right-area">
              <div className="minmax260 text-right">{accessGroup?.accessGroupName}</div>
            </div>
          </div>
          <div className="contents-scroll">
            <table className="inner-table" width="100%">
              <thead>
                <th>
                  <div className="text-center">BS 출입등급</div>
                </th>
              </thead>
              <tbody>
                {accessGroup && accessGroup.accessLevels.length > 0 ? (
                  <>
                    {accessGroup?.accessLevels.map((item: AccessLevelModel, index) => {
                      return (
                        <>
                          <tr>
                            <td className="w-100 flex-center-start">{item.name}</td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="text-gray600">-</td>
                  </tr>
                )}
              </tbody>
            </table>

            <table className="inner-table" width="100%">
              <thead>
                <th>
                  <div className="text-center">BS 사용자</div>
                </th>
              </thead>
              <tbody>
                {accessGroup && accessGroup.users.length > 0 ? (
                  <>
                    {accessGroup?.users.map((item: AccessUserModel, index) => {
                      return (
                        <>
                          <tr>
                            <td className="w-100 flex-center-start">
                              {item.id} {item.name}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="text-gray600">-</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      </BaseModal>
    </div>
  );
};

export default AccessGroupDetailPopup;
