import { CommonDateNUser, Order, Select } from "../public-types";

enum BillPayStep {
  TEMP_SAVE = "TEMP_SAVE",
  PAYMENT_READY = "PAYMENT_READY",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",

  PAYMENT_FREE = "PAYMENT_FREE",
  PAYMENT_STOP = "PAYMENT_STOP",
  PAYMENT_DELAY = "PAYMENT_DELAY",
}

const searchStatusOption: Select[] = [
  { label: "전체", value: "", color: "" },
  { label: "저장", value: BillPayStep.TEMP_SAVE, color: "chip gray" },
  { label: "대기", value: BillPayStep.PAYMENT_READY, color: "chip blue" },
  { label: "성공", value: BillPayStep.PAYMENT_SUCCESS, color: "chip green" },
  { label: "중지", value: BillPayStep.PAYMENT_STOP, color: "chip gray" },
  { label: "무료", value: BillPayStep.PAYMENT_FREE, color: "chip blue" },
  { label: "연체", value: BillPayStep.PAYMENT_DELAY, color: "chip red" },
];

enum ApplicationName {
  //신청/계약(계약) id
  CONTRACT_ID = "CONTRACT_ID",
  //공간상품 id
  SPACE_PRODUCT_ID = "SPACE_PRODUCT_ID",
  //공간상품명
  SPACE_PRODUCT_NAME = "SPACE_PRODUCT_NAME",
  //신청/계약자명
  APPLICANT_NAME = "APPLICANT_NAME",
  //신청/계약자 법인명/상호
  APPLICANT_CORPORATION = "APPLICANT_CORPORATION",
  CONTRACT_APPLY_NUMBER = "CONTRACT_APPLY_NUMBER",
  CONTRACT_NUMBER = "CONTRACT_NUMBER",
  MB_ORGANIZATION_ID = "MB_ORGANIZATION_ID",
}
const searchTypeOption: Select[] = [
  { label: "전체", value: "" },
  { label: "신청/계약 id", value: ApplicationName.CONTRACT_ID },
  { label: "상품id", value: ApplicationName.SPACE_PRODUCT_ID },
  { label: "상품명", value: ApplicationName.SPACE_PRODUCT_NAME },
  // { label: "신청/계약자명", value: ApplicationName.APPLICANT_NAME },
  // { label: "법인/사업자 id", value: ApplicationName.MB_ORGANIZATION_ID },
  { label: "신청 번호", value: ApplicationName.CONTRACT_APPLY_NUMBER },
  // { label: "계약 번호", value: ApplicationName.CONTRACT_NUMBER },
];

//목록 검색
interface SearchParams {
  id?: number;
  billPayStatus?: string;
  searchType?: string;
  searchValue?: string;
  //검색일(from) YYYY-MM-DD
  searchStartTime?: string;
  //검색일(to) YYYY-MM-DD
  searchEndTime?: string;
  partnerName?: string;
  partnerId?: number;
  page?: number;
  size?: number;
  sort?: {
    orders: Array<Order>;
  };
}

export interface BillingDetailWrap {
  scheduleList?: Array<BillingDetail>;
}
export interface BillingDetail extends CommonDateNUser {
  scheduleId?: string | number;
  contractId?: string;
  providerId?: string;
  supplyType?: string;
  contractPayType?: string;
  scheduleStartTime?: string;
  scheduleEndTime?: string;
  regularPgcode?: string;
  payRegularMonth?: string;
  payRegularDay?: number;
  payDueDays?: number;
  taxInvoiceIssueMonth?: string;
  taxInvoiceIssueDay?: number;
  isTaxInvoiceIssue?: boolean;
  cardInfo?: null | string;
  payInfo?: null | string;
  billkey?: null | string;
  accountInName?: string;
  overdueChargeStandard?: string;
  isAutoBilling?: boolean;
  isAutoSend?: boolean;
  isContractCancel?: boolean;
  contractCancelDate?: null | string;
  contractCancelStartOrder?: number;
  contractCancelReason?: string;
  isDeleted?: boolean;
  scheduleMessageList?: Array<ScheduleNoti>;
  scheduleOverdueList?: Array<OverdueFeeDetail>;
  [key: string]: any;
}

export interface ScheduleNoti extends CommonDateNUser {
  messageId?: string | number;
  messageType?: string;
  messageMedia?: string;
  messageLabel?: string;
  isSend?: boolean;
  isDeleted?: boolean;
  cmdType?: string;
  [key: string]: any;
}

export interface OverdueFeeDetail extends CommonDateNUser {
  overdueChargeStandard: string;
  overdueDays: number;
  overdueId: string | number;
  overdueRates: string | number;
}

export type { SearchParams, BillPayStep, ApplicationName };
export { searchStatusOption, searchTypeOption };
