import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse, Sort } from "../public-types";
import {
  ContractDetail,
  ContractDetailBasicInfo,
  ContractDetailWrap,
  ContractManagaDetail,
  ContractManageList,
  CtBuildingManage,
  ExpenseProofRequest,
  GetContractManagementInformationParams,
  GetContractManagementInformationResponse,
  GetContractManageResponse,
  NewContractSaveModel,
  SaveEsignDatas,
  SearchParams,
} from "./contract-types";

/**
 * 신청/계약 목록 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractApply = (axios: AxiosInstance, params?: SearchParams): Promise<AxiosResponse<ApiResponse<{ content: ContractManageList[] }>>> => {
  const path = ApiPath.contract.list;
  return axios.get<ApiResponse<{ content: ContractManageList[] }>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};
/**
 * 기본정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractDetailBasicInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailBasicInfo>>> => {
  const path = ApiPath.contract.detail.basicInfo.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<ContractDetailBasicInfo>>(path, { params });
};

/**
 * 신청정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractApplicationInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.application.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * 계약정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contract.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractDetailPopup = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.snapshot.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};
/**
 * 2023-10-23 스냅샷 데이터 변경
 * @param axios
 * @param params
 * @returns
 */
export const getContractProductSnapshotDetailPopup = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.attributes.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractManageByContractApplyNumber = (
  axios: AxiosInstance,
  params?: {
    contractApplyNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.findContractManageByApplyNumber.replace("{contractApplyNumber}", params!.contractApplyNumber!);
  return axios.get<ApiResponse<any>>(path);
};

const getContractManagementInformation = (
  axios: AxiosInstance,
  params?: GetContractManagementInformationParams,
): Promise<AxiosResponse<ApiResponse<GetContractManagementInformationResponse>>> => {
  const path = ApiPath.contract.findContractManageByApplyNumber.replace("{contractApplyNumber}", params!.contractApplyNumber!);
  return axios.get<ApiResponse<GetContractManagementInformationResponse>>(path);
};

/**
 * 운영정보 조회
 * @param axios
 * @param params
 * @returns
 */

/**
 * RCA31. 계약 - 운영정보 상세 조회
 */
const getContractOperationInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * RCA34. 계약 - 운영정보 공용시설 조회
 */
const getContractFacilityList = (
  axios: AxiosInstance,
  body?: {
    id: number;
    params?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  //console.log("body", body);
  let path = ApiPath.contract.detail.findContractFacilityList.replace("{contractId}", String(body!.id));

  return axios.get<ApiResponse<any>>(path);
};

/** RPB20. 건물 공용공간 상세 조회 목록 */
//searchType = id, keywordList = array<string>
export const getProductFacilityList = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  //console.log("body", body);
  let path = ApiPath.contract.detail.getProductFacilityDetails_RPB23.replace("{productId}", params.productId);

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/** RCA33b. 계약 - 운영정보 공용시설 삭제 */
export const deleteOperationFacility = (
  axios: AxiosInstance,
  params?: {
    id: number;
    facilityId?: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.RCA33b_DeleteOperationFacility.replace("{contractId}", String(params!.id)).replace(
    "{facilityId}",
    String(params!.facilityId),
  );

  return axios.patch<ApiResponse<any>>(path);
};
/**RCA33. 계약 - 운영정보 수정 */
const saveContractOperation = (
  axios: AxiosInstance,
  params?: {
    id: number;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.id));

  return axios.patch<ApiResponse<any>>(path, params?.body);
};

/**RCA32. 계약 - 운영정보 수정 */
export const editContractOperation = (
  axios: AxiosInstance,
  params?: {
    id: number;
    productManage?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.id));
  return axios.post<ApiResponse<any>>(path, { productManage: params?.productManage! });
};

/**RCA37. 계약 - 운영정보 공용시설 등록/수정
 * request body
 * { productManage:{}, productFacility: [{}] }
 */
export const addNUpdateOperationData = (
  axios: AxiosInstance,
  params?: {
    id: number;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.findContractFacilityList.replace("{contractId}", String(params!.id));

  return axios.post<ApiResponse<any>>(path, params?.body);
};
/** 공용공간 월 사용량 조회 */
// RCA92_GetUsageFacilities;
export const getFacilityUsage = (
  axios: AxiosInstance,
  params?: {
    id: number;
    facilityType: string;
    yearMonth: Array<string>;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.RCA92_GetUsageFacilities.replace("{contractId}", String(params!.id));

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true, arrayFormat: "repeat" }),
    },
  });
};

/**
 * RIR01. 요금 룰 목록
 * GET
 * providerId, buildingCommonFacilityId
 * getBaseFacilityPricies_RIR01
 * @param axios
 * @param body
 * @returns
 */
export const getBaseFacilityPricies = (
  axios: AxiosInstance,
  params?: {
    providerId: string;
    // buildingCommonFacilityId: string;
    page: number;
    size: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.getBaseFacilityPricies_RIR01;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RIM02. 건물-공용공간 요금 룰 등록/수정/삭제
 * POST
 * * serviceId = contractId, serviceType = 'SERVICE_CONTRACT, buildingCommonFacilityPriceRuleList cmdType: C, U, D
 * @param axios
 * @param body
 * @returns
 */
// getFacilityPricieSetting_RIM01
export const setFacilityPriceSetting = (
  axios: AxiosInstance,
  params?: {
    buildingCommonFacilityPriceRuleList: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.setFacilityPriceSetting_RIM02;
  return axios.post<ApiResponse<any>>(path, { buildingCommonFacilityPriceRuleList: params?.buildingCommonFacilityPriceRuleList! });
};
/**
 * RIM01. 건물-공용공간 요금 룰 목록
 * GET
 * serviceId = contractId, serviceType = 'SERVICE_CONTRACT
 * @param axios
 * @param body
 * @returns
 */
export const getFacilityPricieSetting = (
  axios: AxiosInstance,
  params?: {
    serviceId: string;
    serviceType: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.getFacilityPricieSetting_RIM01;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

//

/**
 * 계약 생성
 * @param axios
 * @param body
 * @returns
 */
const addContractApplication = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const path = ApiPath.contract.addApplication;
  return axios.post<ApiResponse<any>>(path, body);
};

const editContractDetail = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  console.log("body", body);

  const send: any = {
    contract: body.contract,
    contractMediaList: body.contractMediaList,
    scheduleList: body.scheduleList,
    contractManage: body.contractManage,
    billContactList: body.billContactList,
  };

  if (body.convertContractor) {
    send.convertContractor = body.convertContractor;
  }
  const path = ApiPath.contract.editContract.replace("{contractId}", String(body!.contractId));
  return axios.patch<ApiResponse<any>>(path, send);
};

const changeContractStatus = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const send = {
    contract: {
      contractStep: body?.contractStep,
    },
  };
  const path = ApiPath.contract.changeContractStatus.replace("{contractId}", String(body!.contractId));
  return axios.patch<ApiResponse<any>>(path, send);
};

/**
 * 관리자 메모 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractMemoList = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const changeId = ApiPath.contract.detail.memo.list.replace("{serviceId}", String(params!.id));
  const path = changeId.replace("{serviceType}", "CT_CONTRACT_MANAGE");
  return axios.get<ApiResponse<any>>(path, { params });
};

const addContractMemo = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = { memoContent: body.contractMemo };
  const changeId = ApiPath.contract.detail.memo.add.replace("{serviceId}", String(body!.contractManageId));
  const path = changeId.replace("{serviceType}", "CT_CONTRACT_MANAGE");
  return axios.post<ApiResponse<any>>(path, { contractMemo: { ...send } });
};

const editContractMemo = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = {
    contractMemoId: body.contractMemoId,
    memoContent: body.contractMemo,
    isDeleted: "false",
  };
  const path = ApiPath.contract.detail.memo.edit.replace("{memoId}", String(body!.contractMemoId));
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};

/**
 * 회원의 관리자 메모 삭제
 * @param axios
 * @param body
 * @returns
 */
const deleteContractMemo = async (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const send = {
    id: body.contractMemoId,
    isDeleted: "true",
  };
  const path = ApiPath.contract.detail.memo.edit.replace("{memoId}", String(body!.contractMemoId));
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};

/**
 * 관리자 메모 조회
 * @param axios
 * @param params
 * @returns
 */
const getProviderAccountCredits = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    startDateTime: string;
    endDateTime: string;
    isBillConfirm: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.findProviderAccountCredit.replace("{scheduleId}", String(params!.scheduleId));

  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractNumbers = (
  axios: AxiosInstance,
  params?: {
    searchType?: string;
    searchValue?: string;
    contractManageId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.getContractNumbers.replace("{contractManageId}", String(params!.contractManageId));
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

const getContractBuildingRooms = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.getRoom.replace("{contractId}", String(params!.contractId));
  return axios.get<ApiResponse<any>>(path);
};

// RCA18. 계약관리 정보 조회
const getContractManage = (
  axios: AxiosInstance,
  params?: {
    contractApplyNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<GetContractManageResponse>>> => {
  const contractApplyNumber = params?.contractApplyNumber;
  const path = ApiPath.contract.findContractManageByApplyNumber.replace("{contractApplyNumber}", contractApplyNumber!);
  return axios.get(path);
};

// 출입기록 params
export type LogParams = {
  page: number;
  size: number;
  startDate: string;
  endDate: string;
  contractManageId: string;
  sort: Sort;
};

const getAccessLog = (axios: AxiosInstance, params?: LogParams): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.RAC31.replace("{contractManageId}", String(params!.contractManageId));
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * 2023-09-07 이후 변경된 API대응을 위한 type 정의
 * 상세 정보 조회를 뒤한 API 추가
 * ========================================
 */

/**
 * RCA27. 계약 조회
 * URI : /api/ctrl/partners/{:partnerId}/ct/contract/{contractId}
 * Apipath.json > contract > getContract
 * @param axios
 * @param params
 * @returns
 */
export const getContract_RCA27 = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailWrap>>> => {
  const path = ApiPath.contract.getContract.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<ContractDetailWrap>>(path, { params });
};

/**
 * RCA27. 계약관리 조회
 * URI : /api/ctrl/partners/{:partnerId}/ct/contract/{contractId}
 * Apipath.json > contract > getContract
 * @param axios
 * @param params
 * @returns
 */
export const getContractMagage_RCA25 = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailBasicInfo>>> => {
  const path = ApiPath.contract.getContractManage.replace("{contractManageId}", String(params!.id));
  return axios.get<ApiResponse<ContractDetailBasicInfo>>(path, { params });
};

/**
 * RCA28. 계약 수정
 * URI : /api/ctrl/partners/{:partnerId}/ct/contract/{contractId}
 * Apipath.json > contract > getContract
 * @param axios
 * @param params
 * @returns
 */
export const patchContract_RCA28 = (
  axios: AxiosInstance,
  params?: {
    id: number;
    contract: ContractDetail;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailWrap>>> => {
  const path = ApiPath.contract.getContract.replace("{contractId}", String(params!.id));
  return axios.patch<ApiResponse<ContractDetailWrap>>(path, { contract: params!.contract });
};

/**
 * RCA29. 계약관리 수정
 * URI : /api/ctrl/partners/{:partnerId}/ct/contract/{contractId}
 * Apipath.json > contract > getContract
 * @param axios
 * @param params
 * @returns
 */
export const patchContractManage_RCA29 = (
  axios: AxiosInstance,
  params?: {
    id: number;
    contractManage: ContractManagaDetail;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailBasicInfo>>> => {
  const path = ApiPath.contract.getContractManage.replace("{contractManageId}", String(params!.id));
  return axios.patch<ApiResponse<ContractDetailBasicInfo>>(path, { contractManage: params!.contractManage });
};
/**
 * RCS30. 계약 전자서명 목록 조회
 * URI : /api/ctrl/partners/{partnerId}/ct/esign/contract/{contractId}/workflow/list
 * Apipath.json > contract > esignOn > esignOnList
 * @param axios
 * @param params
 * @returns
 */
export const getEsignonList_RCS30 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.esignOnList.replace("{contractId}", String(params!.contractId));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * RCS16. 전자계약 문서 상세 조회(이싸인온)
 * URI : /api/ctrl/partners/{:partnerId}/ct/contract/{contractId}
 * Apipath.json > contract > esignOn > esignOnDetail
 * @param axios
 * @param params
 * @returns
 */
export const getEsignonDetail_RCS16 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    workflowId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.esignOnDetail
    .replace("{contractId}", String(params!.contractId))
    .replace("{workflowId}", String(params!.workflowId));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * RCS30. 계약 전자서명 목록 조회
 * URI : /api/ctrl/partners/{partnerId}/ct/esign/contract/{contractId}/workflow/list
 * Apipath.json > contract > esignOn > esignOnTemplateList
 * @param axios
 * @param params
 * @returns
 */
export const getEsignonTemplateList_RCS10 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.esignOnTemplateList.replace("{contractId}", String(params!.contractId));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * RCS16. 전자계약 문서 상세 조회(이싸인온)
 * URI : /api/ctrl/partners/{:partnerId}/ct/contract/{contractId}
 * Apipath.json > contract > esignOn > esignOnTemplateDetail
 * @param axios
 * @param params
 * @returns
 */
export const getEsignonTemplateDetail_RCS11 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    templateId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.esignOnTemplateDetail
    .replace("{contractId}", String(params!.contractId))
    .replace("{templateId}", String(params!.templateId));
  return axios.get<ApiResponse<any>>(path, { params });
};

export const getEsingMember = (axios: AxiosInstance): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.getPartnerEsignId;
  return axios.get<ApiResponse<any>>(path);
};

/**
 * RCS22. 전자계약 문서 미리보기(이싸인온)
 * URI : /api/ctrl/partners/{partnerId}/ct/esign/contract/{contractId}/workflow/preview
 * Apipath.json > contract > esignOn > writePreviewUrl
 * @param axios
 * @param params
 * @returns
 */
export const getWriteDataPreview_RCS22 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    workflow: SaveEsignDatas;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.writePreviewUrl.replace("{contractId}", String(params!.contractId));
  return axios.post<ApiResponse<any>>(path, { workflow: params!.workflow! });
};

/**
 * RCS21. 전자계약 문서 서명요청(이싸인온)
 * URI : /api/ctrl/partners/{partnerId}/ct/esign/contract/{contractId}/workflow/start
 * Apipath.json > contract > esignOn > confirmEsign
 * @param axios
 * @param params
 * @returns
 */
export const confirmEsign_RCS21 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    workflow: SaveEsignDatas;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.confirmEsign.replace("{contractId}", String(params!.contractId));
  return axios.post<ApiResponse<any>>(path, { workflow: params!.workflow! });
};

/**
 * RCS23. 전자계약 문서 상태 변경(이싸인온) - 문서를 취소, 삭제, 폐기 처리
 * URI : /api/ctrl/partners/{partnerId}/ct/esign/contract/{contractId}/workflow/{workflowId}/command
 * Apipath.json > contract > esignOn > confirmEsign
 * @param axios
 * @param params
 * @returns
 */
export const cancelEsign_RCS23 = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    workflowId: number;
    commandType: string;
    comment?: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.cancelEsign
    .replace("{contractId}", String(params!.contractId))
    .replace("{workflowId}", String(params!.workflowId));
  const sendData = { commandType: params?.commandType!, comment: params?.comment ? params?.comment : "" };
  return axios.post<ApiResponse<any>>(path, { updateStatusWorkflow: sendData });
};

export const partnerEsign_RCS02 = (axios: AxiosInstance): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.esignOn.partnerEsign;
  return axios.get<ApiResponse<any>>(path);
};

/**
 * RCA02. 계약 신청 (v1.8 이후)
 * POST URI : /api/ctrl/partners/{partnerId}/ct/contract/apply
 * Apipath.json > contract > newSave
 * @param axios
 * @param params
 * @returns
 */
export const newMakeContract_RCA02 = (
  axios: AxiosInstance,
  params?: {
    saveModel: NewContractSaveModel;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.newSave;

  return axios.post<ApiResponse<any>>(path, params!.saveModel!);
};

/**
 * RIQ01 - 견적서 목록 상세
 * URI : GET /api/court/pc/public/quotation
 * Apipath.json > contract > makeQuotation
 * @param axios
 * @param params
 * @returns
 */
export const getQuotation_TIQ01 = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.makeQuotation;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RIQ02 - 견적서 등록
 * URI : POST /api/court/pc/public/quotation
 * Apipath.json > contract > makeQuotation
 * @param axios
 * @param params
 * @returns
 */
export const makeQuotation_TIQ02 = (
  axios: AxiosInstance,
  quotation?: {
    quotationItemList: Array<any>;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.makeQuotation;

  return axios.post<ApiResponse<any>>(path, { quotation });
};

/**
 * RCA23. 지출증빙 기본정보 등록
 * URI : POST /api/ctrl/partners/{partnerId}/ct/contract/{contractId}/expense-proof-request
 * Apipath.json > contract > detail > expenseProofRequest_RCA22_23
 * @param axios
 * @param params
 * @returns
 */
export const setExpenseProofRequest = (
  axios: AxiosInstance,
  params?: {
    contractId: string;
    expenseProofRequest: ExpenseProofRequest;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.expenseProofRequest_RCA22_23.replace("{contractId}", params?.contractId!);

  return axios.post<ApiResponse<any>>(path, { ...params?.expenseProofRequest });
};

/**
 * RCA22. 지출증빙 기본정보 조회
 * URI : GET /api/ctrl/partners/{partnerId}/ct/contract/{contractId}/expense-proof-request
 * Apipath.json > contract > detail > expenseProofRequest_RCA22_23
 * @param axios
 * @param params
 * @returns
 */
export const getExpenseProofRequest = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.expenseProofRequest_RCA22_23.replace("{contractId}", params?.contractId);

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RCN20. 건물 운영정보 조회
 */
export const getManagementBuildingsAsync = (
  axios: AxiosInstance,
  params?: {
    contractManageId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<{ buildingManageList: CtBuildingManage[] }>>> => {
  if (!params?.contractManageId) {
    throw Error("contractManageId not exist");
  }
  const path = ApiPath.contract.detail.managementBuildings.replace("{contractManageId}", String(params?.contractManageId));

  return axios.get<ApiResponse<{ buildingManageList: CtBuildingManage[] }>>(path);
};
/**
 * RCN21.건물 운영정보 등록
 */
export const postManagementBuildingsAsync = (
  axios: AxiosInstance,
  params?: {
    contractManageId: string | number;
    buildingManageList: CtBuildingManage[];
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  if (!params?.contractManageId) {
    throw Error("contractManageId not exist");
  }

  const path = ApiPath.contract.detail.managementBuildings.replace("{contractManageId}", String(params?.contractManageId));

  return axios.post<ApiResponse<any>>(path, {
    buildingManageList: params.buildingManageList,
  });
};

export {
  getContractApply,
  getContractDetailBasicInfo,
  getContractApplicationInfo,
  getContractDetailPopup,
  getContractOperationInfo,
  getContractInfo,
  getContractMemoList,
  addContractApplication,
  editContractDetail,
  changeContractStatus,
  getContractManageByContractApplyNumber,
  getContractFacilityList,
  saveContractOperation,
  addContractMemo,
  editContractMemo,
  deleteContractMemo,
  getProviderAccountCredits,
  getContractNumbers,
  getContractBuildingRooms,
  getContractManagementInformation,
  getContractManage,
  getAccessLog,
};
