import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { ContractStep, DetailTotalData } from "src/api/contract/contract-types";
import { BaseModal, ContentsTitle } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import ContractAccessGroup from "../commonComponents/accessGroupInfo/ContractAccessGroup";
import ContractReservation from "../commonComponents/reservation/ContractReservation";
import useContractStatusChange from "../hooks/useContractStatusChange";

import ContractBasicInfo from "./components/basicInfo/BasicInfo";
import ContractDetailComponent from "./components/contractDetail/ContractDetail";
import useContractApi from "../hooks/useContractApi";
import { ADMIN_MEMO } from "src/api/public-types";
import _ from "lodash";
import ContractIotDevice from "./components/iotDevice/ContractIotDevice";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import GuideTab from "src/pages/guide/components/GuideTab";

interface ViewStringDatas {
  contractApplyNumber: string;
  contractStep: string;
}

// import ContractReservation from "./components/reservation/ContractReservation";
let tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "contract", label: "계약" },
  { value: "accessLevel", label: "출입 관리" },
  { value: "reservation", label: "공용공간" },
  { value: "guide", label: "이용안내" },

  { value: "iotDevice", label: "IoT 기기" },
];

const ContractDetailMain = () => {
  const { isAuthority } = usePartnerAuthority();
  const navigate = useNavigate();
  const location = useLocation();
  const statusBtnDisables: Array<string> = [ContractStep.APPLY_CANCEL, ContractStep.TERMINATE_COMPLETE, ContractStep.USE_COMPLETE];
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
        decoder: (c) => c,
      }),
    [location],
  );
  const { id } = useParams();

  // const [step, setStep] = useState("");
  const { callDetails } = useContractApi();
  const { statusBtnShow, changeStatus } = useContractStatusChange();
  let makeDetailDatas: DetailTotalData = {};
  const [detailData, setDetailData] = useState<DetailTotalData>(makeDetailDatas);
  const [viewData, setVieeData] = useState<ViewStringDatas>({ contractApplyNumber: "", contractStep: "" });
  // const [failStatus, setFailStatus] = useState(false)
  const [message, setMessage] = useState<any>({
    failStatus: false,
    successStatus: false,
    message: "",
    title: "",
    step: "",
  });
  // const [successStatus, setSuccessStatus] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [validations, setValidations] = useState<any>([]);

  const [isOperationCheck, setIsOperationCheck] = useState(false);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    async (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (
        (tab.value === "accessLevel" || tab.value === "reservation") &&
        (makeDetailDatas?.contract?.contractStep === ContractStep.APPLY_RECEIVED ||
          makeDetailDatas?.contract?.contractStep === ContractStep.APPLY_CONFIRM)
      ) {
        setIsOperationCheck(true);
        setMessage("현재 계약 상태에서는 열람할 수 없습니다.");
        return;
      } else {
        if (newQueryParams?.tab) {
          delete newQueryParams.tab;
        }

        if (newQueryParams?.tabType) {
          delete newQueryParams?.tabType;
          delete newQueryParams?.tabId;
        }

        newQueryParams.tab = tab.value;
        // query parameter 에 tab 추가
        const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
        navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
      }
    },
    [queryParams, makeDetailDatas?.contract?.contractStep, navigate, location.pathname],
  );

  const breadCrumbs = [{ value: "contract", label: "신청/계약" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const checkTabs = useMemo(() => {
    let rtnTabs = _.cloneDeep(tabs);
    if (detailData && detailData.contract && detailData.product.productType === "NON_RESIDENT_OFFICE") {
      rtnTabs = [
        { value: "basicInfo", label: "기본정보" },
        { value: "contract", label: "계약" },
        { value: "accessLevel", label: "출입 관리" },
        { value: "iotDevice", label: "IoT 기기" },
      ];
    }
    return rtnTabs;
  }, [detailData]);
  const setData = useCallback(async () => {
    if (id) {
      const rtn = await callDetails(String(id));
      const view: ViewStringDatas = {
        contractApplyNumber: String(rtn?.contractManage?.contractApplyNumber),
        contractStep: String(rtn?.contract?.contractStep),
      };
      setVieeData(view);
      //상태값으 조회해야한다.

      const status = statusBtnShow(String(rtn?.contract?.contractStep), String(rtn?.contractManage?.spaceProductType), rtn, setMessage);
      setOptions(status.rtnOptionArr);
      setValidations(status.validator);

      setDetailData(rtn);
    }
  }, [callDetails, id, statusBtnShow, setOptions, setValidations]);
  const statusChange = useCallback(
    async (status: string) => {
      const response: any = await changeStatus(status, Number(id));

      if (response.status > 199 && response.status < 300) {
        setMessage({ ...{ successStatus: false } });
        setMessage({ ...{ failStatus: true, message: "", title: "저장되었습니다.", step: "" } });
      } else {
        setMessage({ ...{ successStatus: false, message: "", title: "", step: "" } });
      }
      setData();
    },
    [changeStatus, id, setData],
  );
  useEffect(() => {
    setData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData]);

  console.log("detailData :>> ", detailData);
  return (
    <>
      <MetaTag subTitle={viewData.contractApplyNumber} />
      <div>
        <ContentsTitle
          title="신청/계약"
          tabs={checkTabs}
          activeTab={activeTab}
          clickTab={clickTab}
          detailInfo={[
            { title: "id", value: String(id) || "" },
            { title: "신청번호", value: viewData.contractApplyNumber },
          ]}
          selectValue={viewData.contractStep}
          selectOptions={options}
          setSelectStateValue={(value: any) => {
            const finded = validations.find((obj: any) => obj.key === value);

            if (finded && finded.validation) {
              finded.messages();
            }
          }}
          selectDisabled={statusBtnDisables.includes(String(detailData?.contract?.contractStep))}
          colorType={"contract"}
        />
        {/* !isAuthority("CONTRACT", "w") */}
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <ContractBasicInfo detailData={detailData!} />}
        {/* 계약 */}
        {activeTab?.value === "contract" && <ContractDetailComponent detailData={detailData!} setData={setData} />}
        {/* 출입관리 */}
        {activeTab?.value === "accessLevel" && <ContractAccessGroup detailData={detailData!} />}
        {/* 공용공간 */}
        {activeTab?.value === "reservation" && <ContractReservation detailData={detailData!} />}

        {/* 이용안내 */}
        {activeTab?.value === "guide" && (
          <GuideTab serviceId={String(detailData?.contract?.contractManageId)} serviceType={"SERVICE_CONTRACT_MANAGE"} detailData={detailData!} />
        )}

        {/*IoT 기기  */}
        {activeTab?.value === "iotDevice" && <ContractIotDevice detail={detailData!} />}

        {isOperationCheck && (
          <BaseModal isOpen={isOperationCheck} btnRightTitle="확인" onClick={() => setIsOperationCheck(false)} title={message.message}></BaseModal>
        )}
        {message.failStatus && (
          <BaseModal
            isOpen={message.failStatus}
            btnRightTitle="확인"
            onClick={() => setMessage({ ...message, ...{ failStatus: false, message: "", title: "", step: "" } })}
            title={message.title}
          >
            {message.message && <p>{message.message}</p>}
          </BaseModal>
        )}
        {message.successStatus && (
          <BaseModal
            isOpen={message.successStatus}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setMessage({ ...message, ...{ successStatus: false, message: "", title: "", step: "" } })}
            onClick={() => {
              // console.log("clicked!!!");
              statusChange(message.step);
            }}
            title={message.title}
          >
            {message.message && <p>{message.message}</p>}
          </BaseModal>
        )}
      </div>
    </>
  );
};

export default ContractDetailMain;
